import { gql } from "@apollo/client";

export default gql`
fragment RequirementFields on Requirement {
  id
  name
  title
  description
  type
  summary
  requirementHasEmployer {
      id
      workType {
        id
        label
      }
      employer {
        id
        companyName
        city
      }
      jobApplicationRequirements {
        id
        jobApplicationId
        applicationStatus
        updatedAt
        expiryDate
        validTill
        signedDocumentUrl
        childRequirements{
            id
            applicationStatus
            approver
            updatedAt
            isApproved
            rejectionReason
            consent
            documentDate
            documentType
            documentValidity
            signedDocumentUrl
            employerRequirement{
                id
                validityType
                validityValue
                requirement{
                    id
                    type
                    name
                    frenchName
                    title
                    frenchTitle
                    summary
                    frenchSummary
                    description
                    frenchDescription
                    isConsent
                    isDocHaveExpiry
                }
            }
        }
        jobApplications{
           id
           jobId
           job{
              id
           }
        }
      }
      updatedAt
    }
}
`;
