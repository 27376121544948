import { Button, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import floatingImage from 'assets/img/TalentGroupBackground.png'
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        height: `calc(37% - ${theme.spacing(2)})`,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.25),
        overflow: 'hidden'
    },
    absoluteBackground: {
        height: '60%',
        background: 'linear-gradient(180deg, #2175D9 0%, rgba(33, 117, 217, 0.5) 100%)',
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        overflow: 'hidden'
    },
    titleContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        color: 'white'
    },
    chip: {
        background: '#FFF0DA',
        border: '1px solid #F7A62E',
        color: '#F7A62E',
        fontWeight: 900
    },
    card: {
        background: theme.palette.common.white,
        borderRadius: '6px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        position: 'relative'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    status: {
        fontWeight: 600,
        size: 11,
        lineHeight: '12.89px',
        color: '#666666'
    },
    counts: {
        fontWeight: 600,
        size: 14,
        lineHeight: '16.41px',
        color: '#3F454D'
    },
    outlinedButton: {
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        },
        borderRadius: 50,
        boxShadow: 'none'
    },
    floatingImage: {
        float: 'right',
        width: 117,
        height: 121
    }
}));
// TalentGroupBackground
export default function RequirementCountsCard(props) {
    const {
        pendingApplicationCount,
        approvedApplicationCount,
        rejectedApplicationCount,
        worker
    } = props || {};

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.absoluteBackground}>
                <img className={classes.floatingImage} src={floatingImage} alt='...' />
            </div>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>Requirements</Typography>
                <Chip className={classes.chip} size="small" label={`${pendingApplicationCount} in Verifications`} />
            </div>
            <div className={classes.card}>
                <div className={classes.row}>
                    <Typography className={classes.status}>Approved</Typography>
                    <Typography className={classes.counts}>{approvedApplicationCount <= 9 ? '0' + approvedApplicationCount : approvedApplicationCount}</Typography>
                </div>
                <div className={classes.row}>
                    <Typography className={classes.status}>Rejected</Typography>
                    <Typography className={classes.counts}>{rejectedApplicationCount <= 9 ? '0' + rejectedApplicationCount : rejectedApplicationCount}</Typography>
                </div>
                <Button
                    variant="outlined"
                    className={classes.outlinedButton}
                    size="small"
                    onClick={() => history.push(`/workers/requirements/${window.btoa(worker?.id)}`)}
                    color="secondary"
                >
                    View All Requirements
                </Button>
            </div>
        </div>
    )
}
