import React, { useState } from "react";
import FormRow from "./FormRow";
import { useDispatch } from "react-redux";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import PropTypes from "prop-types";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { Grid, TextField, FormControl } from "@mui/material";
import * as tzlookup from 'tz-lookup';
import { postalCodeValidator } from "utils/stringUtils";

function LocationSection(props) {

    const {
        location,
        selectedLocation = {},
        unit,
        floor,
        locationInstruction,
    } = props;
    const [touchedPostalCodeField, setTouchedPostalCodeField] = useState(false);
    const dispatch = useDispatch();

    const onAddressChange = (selectedLocation, loc) => {
        if (loc) {
            const timezone = tzlookup(loc.lat, loc.lng);
            dispatch(PostingActions.setTimeZone(timezone));
            if (location?.postalCode && !loc?.postalCode) {
                loc.postalCode = location.postalCode;
            }
            if (loc.postalCode) loc.postalCode = loc.postalCode.replaceAll(" ", "").trim();
        }
        dispatch(PostingActions.setLocation(selectedLocation, loc));
    }

    const onUnitChange = (event) => {
        if (event.target.value.length <= 50) {
            dispatch(PostingActions.setUnit(event.target.value))
        }
    }

    const onFloorChange = (e) => {
        if (e.target.value.length <= 50) {
            dispatch(PostingActions.setFloor(e.target.value));
        }
    }

    const onPostalCodeChange = (e) => {
        if (e.target.value.length <= 6) {
            const data = { ...location, postalCode: e.target.value };
            dispatch(PostingActions.setLocation(selectedLocation, data));
        }
    }

    const handleChangeInstruction = (e) => {
        if (e.target.value.length <= 100) {
            dispatch(PostingActions.setLocationInstruction(e.target.value));
        }
    };

    const handlePostalCodeBlur = () => {
        setTouchedPostalCodeField(true);
    };

    return (
        <React.Fragment>
            <FormRow >
                <AddressAutocomplete value={selectedLocation} onChange={onAddressChange} menuPortalTarget={document.body} />
            </FormRow>

            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <Grid container item xs={12}>
                        <Grid container item xs={4} style={{ paddingRight: 4, zIndex: 100 }}>
                            <TextField
                                id="postalCode"
                                label="Postal Code"
                                helperText={touchedPostalCodeField && !postalCodeValidator(location?.postalCode || "") ? "Enter Valid Postal Code" : "Required"}
                                value={location?.postalCode?.replace?.(" ", "")?.toUpperCase() || ""}
                                placeholder="Postal Code"
                                onChange={onPostalCodeChange}
                                margin="none"
                                fullWidth
                                error={touchedPostalCodeField && !postalCodeValidator(location?.postalCode || "")}
                                onBlur={handlePostalCodeBlur}
                                onFocus={() => setTouchedPostalCodeField(false)}
                            />
                        </Grid>
                        <Grid container item xs={4} style={{ paddingRight: 4, zIndex: 100 }}>
                            <TextField
                                id="unit"
                                label="Unit"
                                helperText="Optional"
                                value={unit}
                                placeholder="Unit"
                                onChange={onUnitChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid container item xs={4} style={{ paddingRight: 4, zIndex: 100 }}>
                            <TextField
                                id="floor"
                                name="floor"
                                label="Floor"
                                placeholder="Floor"
                                helperText="Optional"
                                value={floor}
                                onChange={onFloorChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>

            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        id="locationInstruction"
                        name="locationInstruction"
                        label="Additional Instructions"
                        placeholder="Location Instructions"
                        value={locationInstruction}
                        onChange={handleChangeInstruction}
                        helperText="Optional"
                        fullWidth
                        margin="none"
                    />
                </FormControl>
            </FormRow>
        </React.Fragment>
    )
}

LocationSection.propTypes = {
    unit: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    locationInstruction: PropTypes.string.isRequired,
    selectedLocation: PropTypes.object,
}

LocationSection.defaultProps = {
    selectedLocation: null,
}

export default LocationSection
