import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { MoreVert } from "@mui/icons-material";
import {
    CustomTableCell,
    CustomTableRow,
    DashIcon,
    formatMoney,
    formatNumber,
    getDeductionType,
    getEarningType,
    useStyles,
    OverTimeIds,
    VacationPayId
} from "./helpers";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset } from "../../../../../../../../../utils/time";
import ResendStubDialog from "../../components/Dialogs/ResendStubDialog";
import { actions as FinanceActions } from "../../../../../../../../../store/modules/finance";
import { deductionTypes } from "../../../../../../../../Finance/helpers";
import { useDispatch } from "react-redux";

function PositionPayrollTable({ original, jobDetails }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const RenderPaymentDetailsCard = ({ data }) => {
        const { payroll } = data;
        let contributionsTotal = 0;
        let earningsTotal = 0;
        const deductionsTotal = payroll[0].employeeDeductions.reduce((a, b) => {
            const deductionType = deductionTypes.find(
                c => c.id === b.payrollDeductionsTypeId
            );
            if (deductionType && deductionType.code === "EE") {
                return a + b.amount
            }
            return a;
        }, 0);
        const [isOpenResendStub, setIsOpenResendStub] = useState(false);
        const handleResendStub = () => {
            dispatch(FinanceActions.createAndSendPaystub(data.id));
        };
        const toggleResendStubDialog = () => {
            setIsOpenResendStub(true);
        }

        const canMergeOTAndWOT = Object.keys(OverTimeIds).reduce((p, c) => {
            const earning = payroll?.[0]?.earnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds[c]);
            return p && Boolean(earning);
        }, true);
        let canShowOTRow = canMergeOTAndWOT;

        const ind = payroll?.[0]?.earnings?.findIndex(a => a?.payrollEarningsTypeId === VacationPayId);
        (payroll?.[0]?.earnings && ind >= 0) && Object.assign(payroll?.[0]?.earnings, { [ind]: payroll?.[0]?.earnings[payroll?.[0]?.earnings?.length - 1], [payroll?.[0]?.earnings.length - 1]: payroll?.[0]?.earnings[ind] })


        return (
            <>
                <ResendStubDialog
                    open={isOpenResendStub}
                    onClose={() => setIsOpenResendStub(false)}
                    onCancel={() => setIsOpenResendStub(false)}
                    onConfirm={handleResendStub}
                    position={data}
                />
                <Grid item md={12} style={{ padding: 8 }}>
                    <Grid container item xs={12} className={classes.tableContainer}>
                        <Grid container item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                            <Typography variant="h6" style={{ width: "100%" }}>
                                {`Payroll Details`}
                                <IconButton onClick={() => toggleResendStubDialog()} style={{ marginRight: 8, float: "right" }} edge="end" size="small">
                                    <MoreVert />
                                </IconButton>
                            </Typography>
                            <Typography
                                variant="caption"
                                style={{ width: "100%" }}>{`Information about the shift`}</Typography>
                        </Grid>
                        <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                            <Table size="small" style={{ marginTop: 8 }}>
                                <TableHead>
                                    <CustomTableRow>
                                        <CustomTableCell>Earnings</CustomTableCell>
                                        <CustomTableCell>Units</CustomTableCell>
                                        <CustomTableCell>Rate</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody>
                                    {payroll &&
                                        payroll[0].earnings.map((earning, i) => {
                                            const earningType = getEarningType(earning?.payrollEarningsTypeId);
                                            earningsTotal = earningsTotal + earning.amount;

                                            if(earning?.payrollEarningsTypeId === VacationPayId) {
                                                if(earning.amount <= 0) {
                                                    return;
                                                }
                                                
                                                return (
                                                    <CustomTableRow key={i}>
                                                        <CustomTableCell>
                                                            {(earningType && earningType.name) || "-"}
                                                        </CustomTableCell>
                                                        <CustomTableCell>{earning.units || "-"}</CustomTableCell>
                                                        <CustomTableCell>
                                                            {earning?.rate ? earning.rate : "-"}
                                                        </CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(earning.amount) || "-"}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                );
                                            };

                                            if (canMergeOTAndWOT && Object.values(OverTimeIds).includes(earning?.payrollEarningsTypeId)) {
                                                const DOT = payroll[0].earnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds.DOT);
                                                const WOT = payroll[0].earnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds.WOT);
                                                const row = (canShowOTRow && (
                                                    <CustomTableRow key={i}>
                                                        <CustomTableCell>
                                                            {"Overtime (1.5x)"}
                                                        </CustomTableCell>
                                                        <CustomTableCell>{(DOT.units || WOT.units) ? (DOT.units || 0) + (WOT.units || 0) : "-"}</CustomTableCell>
                                                        <CustomTableCell>
                                                            {DOT?.rate ? formatMoney(DOT.rate) : "-"}
                                                        </CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(DOT.amount + WOT.amount) || "-"}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                ));
                                                canShowOTRow = false;
                                                return row;
                                            };

                                            return (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {(earningType && earningType.name) || <DashIcon />}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{earning.units || <DashIcon />}</CustomTableCell>
                                                    <CustomTableCell>
                                                        {formatMoney(earning.rate) || <DashIcon />}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(earning.amount) || <DashIcon />}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            );
                                        })}
                                    <CustomTableRow>
                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {formatMoney(earningsTotal)}
                                        </CustomTableCell>
                                    </CustomTableRow>
                                    <CustomTableRow>
                                        <CustomTableCell colSpan={3}>Net Pay</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {formatMoney(earningsTotal - deductionsTotal)}
                                        </CustomTableCell>
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                            <Table size="small" style={{ marginTop: 8 }}>
                                <TableHead>
                                    <CustomTableRow>
                                        <CustomTableCell>Deductions</CustomTableCell>
                                        <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                            Amount
                                        </CustomTableCell>
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody>
                                    {payroll &&
                                        payroll[0].employeeDeductions.map((deduction, i) => {
                                            const deductionType = getDeductionType(deduction.payrollDeductionsTypeId);
                                            return (
                                                deductionType &&
                                                deductionType.code === "EE" && (
                                                    <CustomTableRow key={i}>
                                                        <CustomTableCell>
                                                            {(deductionType && deductionType.longName) || <DashIcon />}
                                                        </CustomTableCell>
                                                        <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                            {formatMoney(deduction.amount)}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                )
                                            );
                                        })}
                                    <CustomTableRow>
                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {formatMoney(deductionsTotal)}
                                        </CustomTableCell>
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                            <Table size="small" style={{ marginTop: 8 }}>
                                <TableHead>
                                    <CustomTableRow>
                                        <CustomTableCell colSpan={3}>Employer Contributions</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody>
                                    {payroll &&
                                        payroll[0].employerDeductions.map((deduction, i) => {
                                            const deductionType = getDeductionType(deduction.payrollDeductionsTypeId);

                                            if (deductionType && deductionType.code === "ER")
                                                contributionsTotal = contributionsTotal + deduction.amount;

                                            return (
                                                deductionType &&
                                                deductionType.code === "ER" && (
                                                    <CustomTableRow key={i}>
                                                        <CustomTableCell colSpan={3}>
                                                            {(deductionType && deductionType.longName) || <DashIcon />}
                                                        </CustomTableCell>
                                                        <CustomTableCell style={{ textAlign: "right" }}>
                                                            {formatMoney(deduction.amount)}
                                                        </CustomTableCell>
                                                    </CustomTableRow>
                                                )
                                            );
                                        })}
                                    <CustomTableRow>
                                        <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {formatMoney(contributionsTotal)}
                                        </CustomTableCell>
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const RenderWorkerDetailsCard = ({ data, jobDetails }) => {
        const { payroll, id, startShiftUtc, endShiftUtc } = data;
        const { startUtc, endUtc, start, end, street, city, region, timezone } = jobDetails;
        return (
            <Grid item md={12} style={{ padding: 8, paddingLeft: 4, paddingRight: 4 }}>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Grid item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                        <Typography variant="h6" style={{ width: "100%" }}>{`Worker Details`}</Typography>
                        <Typography
                            variant="caption"
                            style={{ width: "100%" }}>{`Information about the worker`}</Typography>
                    </Grid>

                    <Grid item xs={12} md={12} style={{ paddingBottom: 4, paddingLeft: 4 }}>
                        <Table size="small">
                            <TableBody>
                                <CustomTableRow>
                                    <CustomTableCell>Position Id</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {id || <DashIcon />}
                                    </CustomTableCell>
                                </CustomTableRow>
                                <CustomTableRow>
                                    <CustomTableCell>Job Id</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {jobDetails.id || <DashIcon />}
                                    </CustomTableCell>
                                </CustomTableRow>

                                <CustomTableRow>
                                    <CustomTableCell>Job Date</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {convertUTCToTimezoneWithOffset(startUtc, timezone, "MMMM Do, YYYY")}
                                    </CustomTableCell>
                                </CustomTableRow>

                                <CustomTableRow>
                                    <CustomTableCell>Scheduled Shift</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {convertUTCToTimezone(startUtc || start, timezone, "h:mm a")}-
                                        {convertUTCToTimezoneWithOffset(endUtc || end, timezone, "h:mm a")}
                                    </CustomTableCell>
                                </CustomTableRow>

                                {/* <CustomTableRow>
                    <CustomTableCell>Employer</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {job.employerId}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                <CustomTableRow>
                                    <CustomTableCell>Location</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {street}, {city}, {region}
                                    </CustomTableCell>
                                </CustomTableRow>

                                <CustomTableRow>
                                    <CustomTableCell>Worked</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {convertUTCToTimezone(startShiftUtc, timezone, "h:mm a")}-
                                        {convertUTCToTimezoneWithOffset(endShiftUtc, timezone, "h:mm a")}
                                    </CustomTableCell>
                                </CustomTableRow>

                                {/* <CustomTableRow>
                    <CustomTableCell>checked Out</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {moment(position.payrolls[0].checkOut).format("h:mm a") ||    <DashIcon />}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                {/* <CustomTableRow>
                    <CustomTableCell>Hourly Rate</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {formatMoney(position.payrolls[0].rate)}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                <CustomTableRow>
                                    <CustomTableCell>Break</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {payroll && payroll.length && payroll[0].breakDeductionMins ? formatNumber(payroll[0].breakDeductionMins) + ' mins' : 'No Break'}
                                    </CustomTableCell>
                                </CustomTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
                {original.payroll && original.payroll.length > 0 && (<RenderWorkerDetailsCard data={original} jobDetails={jobDetails} />)}
            </Grid>
            <Grid item md={9}>
                {original.payroll && original.payroll.length > 0 && (<RenderPaymentDetailsCard data={original} />)}
            </Grid>
        </Grid>
    );
}

export default PositionPayrollTable;
