import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import WorkTypesTable from "./components/WorkTypesTable";
import { Add } from "@mui/icons-material";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import AddWorkTypeDialog from "./components/AddWorkTypeDialog";
import { deleteWorkType } from "store/modules/workTypes/actions";
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";
import EditWorkTypeDialog from "./components/EditWorkTypeDialog";

function WorkTypesOverView() {

    const dispatch = useDispatch()
    const isdeleteDialog = useSelector(workTypesSelectors.getIsDeleteWorkTypeDialog);
    const [deleteWorkTypeId, setDeleteWorkTypeId] = useState(null);
    const [editWorkTypeId, setEditWorkTypeId] = useState(null);
    const [editWorkTypeLabel, setEditWorkTypeLabel] = useState("");
    const [editFrenchName, setEdiFrenchNameLabel] = useState("");
    const [editWorkTypeOrder, setEditWorkTypeOrder] = useState(null);
    const [editWorkTypeStatus, setEditWorkTypeStatus] = useState(true);
    const [editWorkTypeBaseRate, setEditWorkTypeBaseRate] = useState(0);
    const [editWorkTypeBaseBillingRate, setEditWorkTypeBaseBillingRate] = useState(0);
    const [iconUrl, setIconUrl] = useState("");
    const [bannerImageUrl, setBannerImageUrl] = useState("");
    const [bannerImageUrl2x, setBannerImageUrl2x] = useState("");
    const [bannerImageUrl3x, setBannerImageUrl3x] = useState("");

    const workType = {
        id: editWorkTypeId,
        label: editWorkTypeLabel,
        frenchName: editFrenchName,
        order: editWorkTypeOrder,
        isActive: editWorkTypeStatus,
        baseRate: editWorkTypeBaseRate,
        baseBillingRate: editWorkTypeBaseBillingRate,
        iconUrl: iconUrl,
        bannerImageUrl: bannerImageUrl,
        bannerImageUrl2x: bannerImageUrl2x,
        bannerImageUrl3x: bannerImageUrl3x,
    }

    const createWorkType = () => {
        dispatch(workTypesActions.setIsAddWorkTypeDialog(true));
    }

    const showDeleteDialog = (id) => {
        dispatch(workTypesActions.setIsDeleteWorkTypeDialog(true));
        setDeleteWorkTypeId(id);
    }

    const hideDeleteDialog = () => {
        dispatch(workTypesActions.setIsDeleteWorkTypeDialog(false));
        setDeleteWorkTypeId(null);
    }

    const handleDeleteWorkType = () => {
        dispatch(deleteWorkType(deleteWorkTypeId));
        dispatch(workTypesActions.setIsDeleteWorkTypeDialog(false));
        hideDeleteDialog();
    }

    const showEditDialog = (workType) => {
        dispatch(workTypesActions.setIsEditWorkTypeDialog(true));
        setEditWorkTypeId(workType.id);
        setEditWorkTypeLabel(workType.label);
        setEdiFrenchNameLabel(workType.frenchName);
        setEditWorkTypeOrder(workType.order);
        setEditWorkTypeStatus(workType.isActive);
        setEditWorkTypeBaseRate(workType.baseRate);
        setEditWorkTypeBaseBillingRate(workType.baseBillingRate);
        setIconUrl(workType.iconUrl,);
        setBannerImageUrl(workType.bannerImageUrl,);
        setBannerImageUrl2x(workType.bannerImageUrl2x,);
        setBannerImageUrl3x(workType.bannerImageUrl3x,);
    }

    return (
        <>
            <MainContent>
                <Button
                    style={{ alignSelf: "flex-end", marginBottom: 24 }}
                    label="Add"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={createWorkType}
                />
                <WorkTypesTable onDelete={showDeleteDialog} onEdit={showEditDialog} />
            </MainContent>

            <AddWorkTypeDialog />
            <EditWorkTypeDialog workType={workType} />
            <Dialog
                open={isdeleteDialog}
                title="Are you sure?"
                onClose={hideDeleteDialog}
                onConfirm={handleDeleteWorkType}
                onCancel={hideDeleteDialog}
                alertType="warning"
                icon="help_outline"
            />
        </>
    )
}

export default WorkTypesOverView
