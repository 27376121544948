import React, { useEffect, useState } from 'react'
import FilterHeader from './components/FilterHeader'
import CustomizedExpandTableSimple from 'components/ReactTable/CustomizedExpandTable';
import { makeStyles } from '@mui/styles';
import { Avatar, Button, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { CheckCircle, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    title: {
        color: '#3F454D',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
        marginBottom: theme.spacing(0.5)
    },
    subTitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        color: 'rgba(107, 111, 117, 1)',
    },
    tabel: {
        borderSpacing: 0,
        '& tr.data-row td': {
            paddingTop: '15px !important',
            paddingBottom: '15px !important',
            borderTop: '1px solid rgb(150 150 150 / 13%)',
            borderBottom: '1px solid rgb(150 150 150 / 13%)'
        },
        '& tr.data-row:hover': {
            background: '#d2fefb'
        },
        '& tr.empty-row td': {
            paddingBottom: theme.spacing(3),
            borderBottom: '1px solid rgb(150 150 150 / 13%) !important',
        }
    },
    expanderRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(3),
    },
    Consent: {
        background: '#F1EEFF',
        color: '#6B6F75'
    },
    Document: {
        background: '#E5F1FF',
        color: '#6B6F75'
    },
    Validation: {
        background: '#FFE5F6',
        color: '#6B6F75'
    },
    outlinedButton: {
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        },
        borderRadius: 50,
        boxShadow: 'none',
        whiteSpace: 'nowrap',
    },
    actionContainer: {
        display: 'flex',
        gap: theme.spacing(1.5),
        alignItems: 'center'
    },
    employerColumn: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5)
    },
    srNo: {
        fontSize: 9.5,
        fontWeight: 600,
        lineHeight: '13.3px',
        color: '#5D6269',
    },
    requirementName: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        color: '#3F454D',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        lineClamp: 3,
        WebkitBoxOrient: 'vertical'
    },
    description: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        color: '#6B6F75',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        lineClamp: 3,
        WebkitBoxOrient: 'vertical',
        minWidth: 200
    },
    dataRow: {
        transition: '0.5s all',
        '&:hover': {
            background: 'rgba(0, 108, 255, 0.04) !important'
        },
        '& td': {
            borderTop: 'none !important',
            borderBottom: 'none !important'
        }
    },
    highlightRow: {
        background: 'rgba(0, 108, 255, 0.04) !important'
    },
    MainRowClass: {
    },
    verified: {
        background: '#DAF6F6',
        color: `#009E96`,
        '& .MuiAvatar-root': {
            display: 'flex',
            color: '#DAF6F6',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#009E96',
            fontSize: 10
        }
    },
    expiring: {
        background: '#FFF5DA',
        color: `#BC8908`,
    },
    expired: {
        background: '#FFE3E3',
        color: `#E95454`,
    },
    Approved: {
        background: '#F3FFFF',
        color: `#009E96`,
    },
    Rejected: {
        color: `#E95454`,
        background: '#FFF3F3',
        '& $name': {
            color: '#E95454 !important'
        }
    },
    ReSubmission: {
        color: `#BC8908`,
        background: '#FFF7E2',
    },
    AlternateDocument: {
        color: `#BC8908`,
        background: '#FFF7E2',
    },
    NewDocument: {
        color: `#2175D9`,
        background: '#F5F9FF',
    },
    verifyNowContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))

const sharedReactTableProps = {
    showPageSizeOptions: false,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: false,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    minRows: 0,
    hidePagination: true
};

export default function EmployerRequirements() {
    const classes = useStyles();
    const data = useSelector(Selectors.getPerEmployersData);
    const isLoading = useSelector(Selectors.getPerRequirementTabelLoading);
    const dispatch = useDispatch();
    const params = useParams()
    const [expanedRows, setExpandedRows] = useState({});
    const selectedRquirementForHighlightRecord = useSelector(Selectors.getHighlightedRequirement);

    let workerId;
    const columns = [
        {
            Header: "Requirement",
            id: "requirement",
            accessor: ({ requirement }) => requirement,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Description",
            id: "description",
            accessor: ({ description }) => description,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            styles: {
                maxWidth: 'fit-content',
                width: 'fit-content',
                minWidth: 'fit-content',
            }
        },
        {
            Header: "Job Id",
            id: "jobId",
            accessor: ({ jobId }) => jobId,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            styles: {
                maxWidth: 70,
                width: 70,
                minWidth: 70,
            }
        },
        {
            Header: "Work Type",
            id: "workType",
            accessor: ({ workType }) => workType,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Requirement Type",
            id: "requirementType",
            accessor: ({ requirementType }) => requirementType,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            styles: {
                maxWidth: 110,
                width: 110,
                minWidth: 110,
            }
        },
        {
            Header: "Status",
            id: "Status",
            accessor: ({ status }) => status,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Last Action Date",
            id: "lastAction",
            accessor: ({ lastAction }) => lastAction,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            styles: {
                maxWidth: 110,
                width: 110,
                minWidth: 110,
            }
        },
        {
            Header: "Valid till Next",
            id: "validTill",
            accessor: ({ validTill }) => validTill,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            styles: {
                maxWidth: 110,
                width: 110,
                minWidth: 110,
            }
        },
        {
            Header: "Actions",
            id: "action",
            accessor: ({ id }) => id,
            disableFilters: true,
            disableSortBy: true,
            className: "-cursor-pointer",
            headerStyle: {
                textAlign: 'right'
            },
            collapse: true
        },
    ];
    try {
        workerId = params?.id ? window.atob(params.id) : null;
    } catch (er) {
        console.log(er);
    };

    useEffect(() => {
        return (() => {
            setExpandedRows({});
        })
    }, []);

    useEffect(() => {
        if (workerId) {
            dispatch(Actions.fetchPerRequirementsData({ workerId, filterBy: "employer" }));
        };
    }, [workerId]);

    useEffect(() => {
        if (selectedRquirementForHighlightRecord) {
            setTimeout(() => {
                dispatch(Actions.setHighlightedRequirement(null));
            }, 3000);
        };
    }, [selectedRquirementForHighlightRecord]);


    const handleVerify = (data) => {
        dispatch(Actions.setApproveRejectConfirmationDilog(data));
    };

    const handleApproveAll = (data) => {
        dispatch(Actions.setRequirementWiseApproveAllDialog({
            ...data,
            ModalType: 'Employer'
        }));
    };

    const handleUpload = (data) => {
        dispatch(Actions.setUploadApproveDialog(data));
    };

    const ExpanderView = (row) => {
        const data = row.original?.requirements?.flatMap(a => a.jobApplicationRequirements?.map(j => ({ employerRequirement: a, ...j })));
        return (
            <div className={classes.expanderRow}>
                <div>
                    <Typography letterSpacing={0.015} className={classes.title}>{row?.original?.companyName}</Typography>
                </div>
                <div className={classes.actionContainer}>
                    {data?.every(p => p?.applicationStatus === 'Applied') && (
                        <Button
                            variant="outlined"
                            className={classes.outlinedButton}
                            size="small"
                            color="secondary"
                            onClick={() => handleApproveAll(row?.original)}
                        >
                            Verify for All
                        </Button>
                    )}
                    <IconButton {...row.getToggleRowExpandedProps()} onClick={(e) => {
                        row.getToggleRowExpandedProps()?.onClick?.(e)
                        setExpandedRows(p => ({
                            ...p,
                            [row.id]: !row.isExpanded
                        }))
                    }}>
                        {row.isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                    </IconButton>
                </div>
            </div>
        );
    };

    const Render = ({ id, data, row }) => {
        const cases = columns.map(a => a.id);
        const additionalDoc = data?.childRequirements?.[data?.childRequirements?.length - 1];
        switch (id) {
            case cases[0]: {
                return (
                    <div className={classes.employerColumn}>
                        <Typography className={classes.requirementName}>{data?.employerRequirement?.requirement?.name || '-'}</Typography>
                    </div>
                )
            }
            case cases[1]: {
                return (
                    <div>
                        <Typography className={classes.description}>{(data?.employerRequirement?.requirement?.type === "Document" ? data?.employerRequirement?.requirement?.description : data?.employerRequirement?.requirement?.summary) || '-'}</Typography>
                    </div>
                )
            }
            case cases[2]: {
                return (
                    <div>
                        {data?.jobApplications?.job?.id || '-'}
                    </div>
                );
            }
            case cases[3]: {
                return (
                    <div>
                        {data?.employerRequirement?.workType ? <Chip label={data?.employerRequirement?.workType?.label} /> : '-'}
                    </div>
                )
            }
            case cases[4]: {
                return (
                    <div>
                        {<Chip label={data?.employerRequirement?.requirement?.type} className={classes[data?.employerRequirement?.requirement?.type]} />}
                    </div>
                );
            }
            case cases[5]: {
                return (
                    <div>
                        {data ? <Chip label={data?.applicationStatus === "AlternateDocument" ?
                            `Alternate Document ${additionalDoc?.employerRequirement?.requirement ? `: ${additionalDoc?.employerRequirement?.requirement.name}` : ''}`
                            : data?.applicationStatus === "NewDocument" ? "New Document Available" : data?.applicationStatus} className={classes[data?.applicationStatus]} /> : '-'}
                    </div>
                );
            }
            case cases[6]: {
                return (
                    <div>
                        {data?.updatedAt ? moment(data.updatedAt).format("DD MMM 'YY") : '-'}
                    </div>
                );
            }
            case cases[7]: {
                if (data?.applicationStatus === "Approved" || data?.applicationStatus === "Expired")
                    return (
                        <div style={data?.validTill <= 7 ? data?.validTill <= 0 ? { color: '#E95454' } : { color: '#BC8908' } : {}}>
                            <Tooltip title={moment(data?.expiryDate)?.format("DD-MM-YYYY")}>
                                {moment(data?.expiryDate).fromNow()}
                            </Tooltip>
                        </div>
                    );
                return "-";
            }
            case cases[8]: {
                return (
                    <div className={classes.verifyNowContainer}>
                        {(data?.applicationStatus === "Applied" || data?.applicationStatus === "NewDocument") && <Button
                            variant="contained"
                            className={classes.outlinedButton}
                            size="small"
                            color="secondary"
                            onClick={() => handleVerify({
                                ...row?.original,
                                data,
                                ModalType: 'Employer'
                            })}
                        >
                            Verify Now
                        </Button>}
                        {(data?.applicationStatus === "AlternateDocument" || data?.applicationStatus === "ReSubmission") && <Button
                            variant="contained"
                            className={classes.outlinedButton}
                            size="small"
                            color="secondary"
                            onClick={() => handleUpload({
                                ...row?.original,
                                data,
                                additionalDoc,
                                ModalType: 'Employer'
                            })}
                        >
                            Verify Now
                        </Button>}
                    </div>
                )
            }
        }
    }

    const ExpandView = (row) => {
        const data = row.original?.requirements?.flatMap(a => a.jobApplicationRequirements?.map(j => ({ employerRequirement: a, ...j })));
        return (
            <>
                {data?.map((p, rowIndex) => (
                    <tr className={classNames('data-row', classes.dataRow, selectedRquirementForHighlightRecord?.data?.id === p?.id && classes.highlightRow)} key={`${row.index}-${p.id}`}>
                        {row.cells.map((cell, index) => {
                            return (
                                <td
                                    key={index}
                                    {...cell.getCellProps()}
                                    onClick={(e) => {
                                        if (!cell.column.isNotClickable) {
                                        }
                                    }}
                                    className={classNames(cell.column.collapse ? 'collapse' : '', cell.column.className ? cell.column.className : '', !cell.column.isNotClickable ? 'cursor-pointer' : '')}
                                    style={cell.column.styles ? cell.column.styles : {}}>
                                    {Render({ id: cell.column.id, data: p, row, cell, index, rowIndex })}
                                </td>
                            )
                        })}
                    </tr>
                ))}
                <tr className={'empty-row'}>
                    <td colSpan={row?.cells?.length} />
                </tr>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <FilterHeader />
            <CustomizedExpandTableSimple
                style={{ border: 0, minHeight: 110 }}
                rowClassNames={columns.map(() => classes.MainRowClass)}
                {...sharedReactTableProps}
                classes={classNames(classes.tabel)}
                data={data}
                filterable={false}
                expanderRowView={ExpanderView}
                expandView={ExpandView}
                expanded={expanedRows}
                columns={columns}
                loading={isLoading} />
        </div>
    )
}
