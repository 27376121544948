import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as WorkerActions, selectors as WorkerSelections } from 'store/modules/workers';

const ActionColumnCell = ({ row: { original } }) => {
    const dispatch = useDispatch();
    const { isInfoLoading } = useSelector(state => (state?.workers || {}));
    const selectedWorkerId = useSelector(WorkerSelections.getSelectedWorkerIdentityId);
    const [selectedType, setSelectedType] = useState(null);

    useEffect(() => {
        if (!isInfoLoading && selectedType) setSelectedType(null);
    }, [isInfoLoading]);

    const handleApprove = () => {
        setSelectedType("APPROVED");
        dispatch(WorkerActions.updateWorkerIdentityStatus({ workerId: original.id, isActive: true, isVerified: true, isWarning: false }));
    }
    const handleReject = () => {
        dispatch(WorkerActions.setWorkerIdentityRejectDialog(original.id));
        setSelectedType("REJECT");
    };

    return (
        <div style={{ display: "flex" }}>
            <Button
                onClick={handleApprove}
                size={"small"}
                disabled={selectedWorkerId === original.id}
                color="secondary"
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                style={{ marginRight: 8 }}>
                {selectedWorkerId === original.id && selectedType === "APPROVED" ?
                    <CircularProgress size={18} /> :
                    "Approve"
                }
            </Button>
            <Button
                onClick={handleReject}
                size={"small"}
                disabled={selectedWorkerId === original.id}
                color="error"
                variant="outlined"
                startIcon={<HighlightOff />}>
                {selectedWorkerId === original.id && selectedType === "REJECT" ?
                    <CircularProgress size={18} /> :
                    "Reject"
                }
            </Button>
        </div >
    )
}

export default ActionColumnCell;
