import { MenuItem, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch } from 'react-redux';
import { actions as Actions } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        padding: 4,
        height: 36,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        // "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
        //     padding: '4px 15px 4px 8px !important',
        //     minWidth: 74,
        //     background: '#E9F1FB',
        // },
        '& fieldset': {
            border: 'none'
        }
    },
    select: {
        width: 75,
        maxWidth: 75,
        minWidth: 75,
        height: 25,
        padding: '0 !important',
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
            width: '100%',
            height: 25,
            background: '#E9F1FB'
        }
    },
    textBoxContainer: {
        width: 100,
        flex: 1
    }
}));

export default function SelectValidity(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = ({ target: { value } }) => {
        dispatch(Actions.updateAssignedEmployerFormData(props?.row?.index, 'validity', {
            ...props?.row?.original?.validity,
            type: value,
            value: null
        }));
    };

    const handleDaysChange = ({ target: { value } }) => {
        dispatch(Actions.updateAssignedEmployerFormData(props?.row?.index, 'validity', {
            ...props?.row?.original?.validity,
            value: value,
        }));
    };

    const handleDateChange = (selectedDate) => {
        dispatch(Actions.updateAssignedEmployerFormData(props?.row?.index, 'validity', {
            ...props?.row?.original?.validity,
            value: selectedDate,
        }));
    };

    return (
        <div className={classes.root}>
            <TextField
                onChange={handleChange}
                select
                className={classes.select}
                defaultValue={props?.row?.original?.validity?.type}
            >
                <MenuItem value={'days'} >
                    Days
                </MenuItem>
                <MenuItem value={'date'}>
                    Date
                </MenuItem>
            </TextField>
            <div className={classes.textBoxContainer}>
                {props?.row?.original?.validity?.type === 'days' ? (
                    <TextField
                        fullWidth
                        onChange={handleDaysChange}
                        value={props?.row?.original?.validity?.value || ''}
                        placeholder="Enter"
                        type="number"
                        margin="none"
                    />
                ) : null}
                {props?.row?.original?.validity?.type === 'date' ? (
                    <LocalizationProvider dateAdapter={MomentUtils}>
                        <MobileDatePicker
                            disablePast
                            value={props?.row?.original?.validity?.value}
                            onChange={e => handleDateChange(e)}
                            renderInput={props => <TextField {...props} placeholder='Select Date' />}
                            inputFormat="DD/MM/YYYY" />
                    </LocalizationProvider>
                ) : null}
            </div>
        </div>
    )
}
