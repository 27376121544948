import { Button, Dialog, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import ApproveIcon from 'assets/img/CursorChoose.svg';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5),
        minWidth: 400
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5)
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    approveRejectButton: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    note: {
        background: 'rgba(227, 239, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: theme.spacing(1.25, 2, 1.25, 2),
        margin: theme.spacing(1),
        marginTop: theme.spacing(0),
        '& p': {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '20px',
            color: '#000000'
        }
    },
    approveRejectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1.25),
        width: '80%'
    }
}));

export default function ApproveRejectConfiramtion({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const selectedRequirement = useSelector(Selectors.getApproveRejectConfirmationDilog);
    const dispatch = useDispatch();

    const handleApprove = () => {
        if ((selectedRequirement?.type === "Document" || selectedRequirement?.data?.applicationStatus === "NewDocument") || (selectedRequirement?.ModalType === "Employer" && selectedRequirement?.data?.employerRequirement?.requirement?.type === "Document")) {
            dispatch(Actions.setApproveDocumentDilog(selectedRequirement));
            dispatch(Actions.setApproveRejectConfirmationDilog(false));
            return;
        }
        dispatch(Actions.setApproveRequirementDilog(selectedRequirement));
        dispatch(Actions.setApproveRejectConfirmationDilog(false));
    };

    const handleReject = () => {
        if ((selectedRequirement?.type === "Document" || selectedRequirement?.data?.applicationStatus === "NewDocument") || (selectedRequirement?.ModalType === "Employer" && selectedRequirement?.data?.employerRequirement?.requirement?.type === "Document")) {
            dispatch(Actions.setRejectDocumentDilog(selectedRequirement));
            dispatch(Actions.setApproveRejectConfirmationDilog(false));
            return;
        }
        dispatch(Actions.setRejectConsentDilog(selectedRequirement));
        dispatch(Actions.setApproveRejectConfirmationDilog(false));
    };

    return (
        <Dialog maxWidth={'xs'} onClose={(e) => onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={ApproveIcon} className={classes.icon} />}
                    <IconButton
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <Typography className={classes.title}>
                    Approve or Reject<br /> <b>{
                        (selectedRequirement.ModalType === 'Employer' ?
                            selectedRequirement?.data?.employerRequirement?.requirement?.name :
                            selectedRequirement?.name) || '-'}</b> for <b>{
                                selectedRequirement.ModalType === 'Employer' ?
                                    selectedRequirement.companyName :
                                    selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName}</b>?
                </Typography>
                <div className={classes.actionContainer}>
                    <div className={classes.approveRejectContainer}>
                        <Button
                            variant="contained"
                            className={classes.approveRejectButton}
                            color="error"
                            onClick={handleReject}
                        >
                            Reject
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.approveRejectButton}
                            color="secondary"
                            onClick={handleApprove}
                        >
                            Approve
                        </Button>
                    </div>
                    <Button
                        onClick={onClose}
                        variant="text"
                        className={classes.button}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
            {<div className={classes.note}>
                <Typography>
                    <b>Note: </b> This action will auto apply to all other employees
                </Typography>
            </div>}
        </Dialog>
    )
}
