import { Home, Work, RecentActors, Contacts, Settings, AttachMoney, Assessment, FileCopy, Person, Rowing, Build, CheckCircle } from "@mui/icons-material";
import Dashboard from "views/Dashboard/DashboardView.jsx";
import EmployerDetail from "views/Employer/EmployerDetail/EmployerDetail.jsx";
import NewEmployerList from "views/Employer/EmployerList.jsx";
import AddEmployerView from "views/Employer/PostEmployerView/PostEmployerView";
import FinanceOverview from "views/Finance";
import BillingView from "views/Finance/Billing";
import BillingDetailsView from "views/Finance/Billing/BillingDetailsView";
import EmployerBillingDetailsView from "views/Finance/Billing/EmployerBillingDetailsView/index";
import PaymentsView from "views/Finance/FinanceOverview/PaymentsView.jsx";
import JobDetails from "views/Job/JobDetails/JobDetails";
import JobOverview from "views/Job/JobOverview/JobOverview.jsx";
import PostJobView from "views/Job/PostJobView/PostJobView";
import Logout from "views/Login/Logout";
import ProfileView from "views/Profile";
import DCBankReportView from "views/Reports/DCBank";
import SettingsView from "views/Settings/SettingsView.jsx";
import AddWorker from "views/Worker/PostWorkerView/PostWorkerView.jsx";
import WorkerDetail from "views/Worker/WorkerDetail/WorkerDetail.jsx";
import WorkerOverview from "views/Worker/WorkerOverview.jsx";
import ActiveEmployerView from "../views/Reports/Billing";
import WorkerPayrollView from "../views/Reports/Payroll";
import WorkerReportView from "../views/Reports/Worker";
import PrivateJobDetails from "views/Job/PrivateJob/PrivateJobDetails";
import JobTemplateOverview from "views/JobTemplate/JobTemplateOverview";
import AddJobTemplateView from "views/JobTemplate/PostJobTemplateView/PostJobTemplateView";
import RequiredGearOverview from "views/RequiredGear/RequiredGearOverview";
import WorkTypesOverView from "views/WorkTypes/WorkTypesOverview";
import SkillsOverview from "views/Skills/SkillsOverview";

import AdminView from "views/Admin";
import AdminDetail from "views/Admin/AdminDetail";
import ProvinceDocumentsOverview from "views/ProvinceDocuments/ProvinceDocumentsOverview";
import CustomFieldOverview from "views/CustomFields/CustomFieldOverview/CustomFieldOverview";
import AddCustomField from "views/CustomFields/CustomFieldOverview/AddCustomField";
import CustomFieldDetail from "views/CustomFields/CustomFieldDetail/CustomFieldDetail";
import WorkerIdentityVerification from "views/WorkerIdentityVerification/WorkerIdentityVerification";
import HolidaysOverview from "views/Holidays/HolidaysOverview";
import HolidayDetail from "views/Holidays/HolidayDetail";
import WorkOrderReportView from "views/Reports/WorkOrder";
import WeeklyBillingReportView from "views/Reports/WeeklyBilling";
import MultiShiftJobDetails from "views/Job/MultiShiftJob/MultiShiftJobDetails";
import ProvinceWisePay from "views/ProvinceWisePay";
import ProvinceBreakRule from "views/ProvinceBreakRule";
import AdditionalRequirements from "views/AdditionalRequirements/AdditionalRequirements.jsx";
import CreateValidation from "views/AdditionalRequirements/CreateValidation";
import CreateDocument from "views/AdditionalRequirements/CreateDocument";
import RequirementDetail from "views/AdditionalRequirements/RequirementDetail/RequirementDetail";
import RequirementAssignEmployer from "views/AdditionalRequirements/RequirementAssignEmployer";
import AssignRequirement from "views/Employer/AssignRequirement/Index";
import Verifications from "views/Verifications";
import RequirementsOverview from "views/Worker/Requirements/RequirementsOverview";

const newDashRoutes = [
    //   {
    //     path: "/finance/payments/batch/:id",
    //     name: "Batch Details",
    //     component: BatchDetails,
    //     hidden: true,
    //     backButton: true,
    //     permission: "finance:view:base",
    //   },
    {
        path: "/finance/payments",
        name: "Payments",
        component: PaymentsView,
        hidden: true,
        backButton: true,
        permission: "finance:view:base",
    },
    {
        path: "/finance/billing",
        name: "Billing",
        component: BillingView,
        hidden: true,
        backButton: true,
        permission: "finance:view:base",
    },
    {
        path: "/finance/billingdetails/week/:week/:year",
        name: "Billing Week Summary",
        component: BillingDetailsView,
        hidden: true,
        backButton: true,
        permission: "finance:view:base",
    },
    {
        path: "/finance/billingdetails/employer/:employer/:week/:year",
        name: "Employer Invoicing",
        component: EmployerBillingDetailsView,
        hidden: true,
        backButton: true,
        permission: "finance:view:base",
    },
    {
        path: "/dashboard",
        name: "Home",
        icon: Home,
        component: Dashboard,
    },
    {
        path: "/finance",
        name: "Finance",
        state: "finance",
        component: FinanceOverview,
        icon: AttachMoney,
        permission: "finance:view:base",
    },
    {
        path: "/jobs",
        name: "Jobs",
        state: "openJobs",
        icon: Work,
        // component: JobOverview,
        permission: "job:view:base",
        collapse: true,
        views: [
            {
                name: "Gig",
                path: "/jobs/gig/overview",
                state: "openJobs",
                permission: "job:view:base",
                component: JobOverview,
            },
            {
                name: "Multi Shift",
                path: "/jobs/multi-shift/overview",
                state: "openJobs",
                permission: "job:view:base",
                component: JobOverview,
            },
        ],
    },

    {
        path: "/jobs/post",
        name: "Job Posting",
        state: "postJob",
        component: PostJobView,
        hidden: true,
        backButton: true,
        permission: "job:view:post",
    },
    {
        path: "/jobs/:id/dashboard",
        name: "Multi Shift Details",
        state: "viewJobDetails",
        component: MultiShiftJobDetails,
        hidden: true,
        backButton: true,
        permission: "job:view:detail",
    },
    {
        path: "/jobs/:id",
        name: "Job Details",
        state: "viewJobDetails",
        component: JobDetails,
        hidden: true,
        backButton: true,
        permission: "job:view:detail",
    },
    {
        path: "/private-job/:id",
        name: "Private Job Details",
        state: "viewPrivateJobDetails",
        component: PrivateJobDetails,
        hidden: true,
        backButton: true,
        permission: "job:view:detail",
    },
    {
        state: "workers",
        icon: RecentActors,
        path: "/workers/overview",
        name: "Workers",
        component: WorkerOverview,
        permission: "worker:view:base",
    },
    {
        path: "/employers/overview",
        name: "Employers",
        state: "openEmployers",
        component: NewEmployerList,
        icon: Contacts,
        permission: "employer:view:base",
    },
    {
        path: "/reports",
        name: "Reports",
        state: "reports",
        icon: Assessment,
        permission: "report:view:base",
        collapse: true,
        views: [
            {
                name: "Payroll",
                path: "/worker-payroll-report",
                state: "worker-payroll-report",
                permission: "report:view:base",
                component: WorkerPayrollView,
            },
            {
                name: "Employer",
                path: "/employer-report",
                state: "employer-report",
                permission: "report:view:base",
                component: ActiveEmployerView,
            },
            {
                name: "Worker",
                path: "/worker-report",
                state: "worker-report",
                permission: "report:view:base",
                component: WorkerReportView,
            },
            {
                name: "DC Bank",
                path: "/dc-bank-report",
                state: "dc-bank-report",
                permission: "report:view:base",
                component: DCBankReportView,
            },
            {
                name: "Work Order",
                path: "/work-order-report",
                state: "work-order-report",
                permission: "report:view:base",
                component: WorkOrderReportView,
            },
            {
                name: "Weekly Reports",
                path: "/weekly-billing-report",
                state: "weekly-billing-report",
                permission: "report:view:base",
                component: WeeklyBillingReportView,
            },
        ],
    },
    {
        path: "/admins/overview",
        name: "Admins",
        state: "admins",
        component: AdminView,
        icon: Person,
        permission: "admin:read:list",
    },
    // {
    //     path: "/admins/:id",
    //     name: "Admin Details",
    //     state: "viewAdminDetails",
    //     component: AdminDetail,
    //     hidden: true,
    //     backButton: true,
    //     permission: "admin:read:assignpermissions",
    // },
    {
        path: "/profile",
        name: "Profile",
        state: "userProfile",
        component: ProfileView,
        hidden: true,
    },
    {
        path: "/master",
        name: "Master",
        state: "master",
        icon: FileCopy,
        collapse: true,
        permission: "master:view:base",
        views: [
            {
                path: "/job-template/overview",
                name: "Job Template",
                state: "jobTemplate",
                component: JobTemplateOverview,
                // icon: FileCopy,
                permission: "master:view:base",
            },
            {
                path: "/required-gear/overview",
                name: "Required Gear",
                state: "RequiredGear",
                component: RequiredGearOverview,
                // icon: Build,
                permission: "master:view:base",
            },
            {
                path: "/additional-requirements/overview",
                name: "Additional Requirements",
                state: "AdditionalRequirements",
                component: AdditionalRequirements,
                // icon: Build,
                permission: "master:view:base",
            },
            {
                path: "/skills/overview",
                name: "Skills",
                state: "Skills",
                component: SkillsOverview,
                // icon: Build,
                permission: "master:view:base",
            },
            {
                path: "/work-types/overview",
                name: "Work Types",
                state: "workTypes",
                component: WorkTypesOverView,
                // icon: Rowing,
                permission: "master:view:base",
            },
            {
                path: "/province-documents/overview",
                name: "Province Documents",
                state: "provinceDocuments",
                component: ProvinceDocumentsOverview,
                // icon: Rowing,
                permission: "master:view:base",
            },
            {
                path: "/custom-field/overview",
                name: "Custom Fields",
                state: "customFields",
                component: CustomFieldOverview,
                // icon: Rowing,
                permission: "master:view:base",
            },
            {
                path: "/holidays/overview",
                name: "Holidays",
                state: "holidays",
                component: HolidaysOverview,
                // icon: Rowing,
                permission: "master:view:base",
            },
            {
                path: "/province-wise-pay-rule/overview",
                name: "Pay Rules",
                state: "provinceWisePay",
                component: ProvinceWisePay,
                // icon: Rowing,
                permission: "master:view:base",
            },
            // {
            //     path: "/minimum-break-rule/overview",
            //     name: "Break Rules",
            //     state: "provinceBreakRule",
            //     component: ProvinceBreakRule,
            //     // icon: Rowing,
            //     permission: "master:view:base",
            // },
        ]
    },
    {
        path: "/verifications",
        name: "Verifications",
        state: "verifications",
        component: Verifications,
        icon: CheckCircle,
        permission: "verification:view:base",
    },
    {
        path: "/settings",
        name: "Settings",
        state: "settings",
        component: SettingsView,
        icon: Settings,
        permission: "settings:view:base",
    },
    {
        path: "/workers/add",
        name: "Add Worker",
        component: AddWorker,
        hidden: true,
        backButton: true,
        permission: "worker:view:create",
    },
    {
        path: "/workers/requirements/:id",
        name: "Requirements",
        component: RequirementsOverview,
        hidden: true,
        backButton: true,
        permission: "worker:view:detail",
    },
    {
        path: "/workers/:id",
        name: "Worker Detail",
        component: WorkerDetail,
        hidden: true,
        backButton: true,
        permission: "worker:view:detail",
    },
    {
        path: "/employers/add",
        name: "Create Employer",
        component: AddEmployerView,
        hidden: true,
        backButton: true,
        permission: "employer:view:create",
    },
    {
        path: "/employers/assign-requirement/:id",
        name: "Add Requirement",
        component: AssignRequirement,
        hidden: true,
        backButton: true,
        permission: "employer:view:create",
    },
    {
        path: "/employers/:id",
        name: "Employer Detail",
        component: EmployerDetail,
        hidden: true,
        backButton: true,
        permission: "employer:view:detail",
    },
    {
        path: "/job-template/add",
        name: "Create a Job Template",
        component: AddJobTemplateView,
        hidden: true,
        backButton: true,
        // permission: "employer:view:create",
    },
    {
        path: "/job-template/:id",
        name: "Job Template",
        component: AddJobTemplateView,
        hidden: true,
        backButton: true,
        // permission: "worker:view:detail",
    },
    {
        path: "/custom-field/detail/:id",
        name: "Custom field detail",
        component: CustomFieldDetail,
        hidden: true,
        backButton: true,
    },
    {
        path: "/custom-field/add",
        name: "Add new custom field",
        component: AddCustomField,
        hidden: true,
        backButton: true,
    },
    {
        path: "/custom-field/:id",
        name: "Edit custom field",
        component: AddCustomField,
        hidden: true,
        backButton: true,
        // permission: "worker:view:detail",
    },
    {
        path: "/holiday/:id",
        name: "Holiday detail",
        component: HolidayDetail,
        hidden: true,
        backButton: true,
        // permission: "worker:view:detail",
    },
    {
        path: "/additional-requirements/create-validation",
        name: "New Validation Requirement",
        component: CreateValidation,
        hidden: true,
        backButton: true,
        permission: "master:view:base",
    },
    {
        path: "/additional-requirements/create-document",
        name: "New Document Requirement",
        component: CreateDocument,
        hidden: true,
        backButton: true,
        permission: "master:view:base",
    },
    {
        path: "/additional-requirements/assign/:id",
        name: "Assign Employer",
        component: RequirementAssignEmployer,
        hidden: true,
        backButton: true,
        permission: "master:view:base",
    },
    {
        path: "/additional-requirements/:id",
        name: "Details",
        component: RequirementDetail,
        hidden: true,
        backButton: true,
        permission: "master:view:base",
    },
    {
        path: "/logout",
        name: "Logout",
        state: "logout",
        hidden: true,
        component: Logout,
    },
    { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
];

export default newDashRoutes;
