import { deepOrange, green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
const isMobile = false;
export const reversalsTabTableActionsStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    table: {
        minWidth: "100%",
    },
}));

export const subTransfersActionsStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    table: {
        minWidth: "100%",
    },
}));

export const createDcCustomerDialogStyles = makeStyles(({ spacing }) => ({
    inputContainer: {
        height: 50,
        marginTop: spacing(4),
        marginBottom: spacing(4),
        width: "70%",
    },
}));

export const payrollSummaryDialogStyles = makeStyles(({ spacing, typography, customPalette }) => ({
    modal: {
        padding: spacing(3),
        // overflowY: "unset",
        maxWidth: '100%'
    },
    title: {
        marginBottom: spacing(3),
    },
    content: {
        padding: 8,
        overflow: 'initial'
    },
    table: {
        ...typography.body1,
        border: "none",
        "&.table thead": {
            backgroundColor: "transparent !important",
        },
        "&.table thead th": {
            ...typography.caption,
            backgroundColor: "transparent !important",
            fontWeight: "bold",
        },
        "& tbody": {
            overflowY: "auto",
        },
    },
    footer: {
        margin: 0,
    },
    warning: {
        backgroundColor: customPalette.yellow[100],
    },
    warningContainer: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    warningIcon: {
        color: customPalette.yellow[700],
        marginRight: spacing(0.5),
    },
    rateSection: {
        position: 'relative'
    },
    rateText: {
        marginLeft: '20px'
    },
    editIcon: {
        cursor: 'pointer',
        marginRight: spacing(0.5),
        position: 'absolute'
    },
    payIcon: {
        color: customPalette.green[700],
        marginRight: spacing(0),
    },
    totalContainer: {
        marginTop: spacing(3),
        marginBottom: spacing(3),
        padding: spacing(1),
    },
    totalLabel: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        marginRight: spacing(4),
        paddingTop: spacing(2),
    },
    totalLine: {
        width: "100%",
        height: 2,
        marginBottom: 16,
    },
    updateBtn: {
        padding: "0 32px !important"
    }
}));

export const cancelTransferDialogStyles = withStyles(({ spacing }) => ({
    header: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1),
        // alignItems: "flex-start",
    },
    inputContainer: {
        // height: 50,
        marginTop: spacing(4),
        marginBottom: spacing(4),
        width: "70%",
    },
    modal: {
        padding: isMobile ? spacing(2) : spacing(6),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },
    core: {
        paper: {
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            paddingLeft: spacing(0),
            paddingRight: spacing(0),
            marginLeft: spacing(1),
            marginRight: spacing(1),
            // alignItems: "center",
            width: spacing(60),
            overflowY: "auto",
        },
        // scrollPaper: {},
        paperScrollPaper: {
            marginLeft: spacing(1),
            marginRight: spacing(1),
        },
        // paperWidthXs: {},
        // paperWidthMd: {},
        // paperFullWidth: {},
        // paperFullScreen: {},
    },
    leftColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingRight: isMobile ? 0 : 4 },
    rightColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingLeft: isMobile ? 0 : 4 },

    title: {
        padding: `${spacing(4)} 0 ${spacing(0)} 0`,
        textAlign: "center",
        // alignItems: "flex-end",
    },
    content: {
        padding: `${spacing(0)} ${spacing(4)}`,
        overflow: "auto",
        // overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 10,
        marginBottom: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1),
        justifyContent: "space-evenly",
    },
    topActions: {
        margin: 0,
        justifyContent: "flex-end",
    },
}));

export const resendDialogStyles = withStyles(({ breakpoints, spacing, palette }) => ({
    header: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1),
        // alignItems: "flex-start",
    },
    modal: {
        padding: isMobile ? spacing(2) : spacing(6),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },
    core: {
        paper: {
            paddingTop: spacing(1),
            paddingBottom: spacing(1),
            paddingLeft: spacing(0),
            paddingRight: spacing(0),
            marginLeft: spacing(1),
            marginRight: spacing(1),
            // alignItems: "center",
            width: spacing(80),
            overflowY: "auto",
            [breakpoints.up("md")]: {
                minWidth: 680,
            },
            [breakpoints.down("sm")]: {
                minWidth: 480,
            },
        },
        // scrollPaper: {},
        paperScrollPaper: {
            marginLeft: spacing(1),
            marginRight: spacing(1),
            [breakpoints.up("md")]: {
                minWidth: 680,
            },
            [breakpoints.down("sm")]: {
                minWidth: 480,
            },
        },
        // scrollPaper: {},
        paperWidthSm: {
            marginLeft: spacing(1),
            marginRight: spacing(1),
            [breakpoints.up("md")]: {
                minWidth: 680,
            },
            [breakpoints.down("sm")]: {
                minWidth: 480,
            },
        },
        [breakpoints.up("md")]: {
            minWidth: 680,
        },
        [breakpoints.down("sm")]: {
            minWidth: 480,
        },
    },

    timelineContent: {
        padding: "0px 8px 6px 8px",
        // minWidth: spacing(22),
    },
    timelineAltContent: {
        padding: "10px 8px 6px 8px",

        // minWidth: spacing(22),
    },
    paper: {
        padding: "6px 8px",
        minHeight: spacing(9),
    },
    secondaryTail: {
        backgroundColor: palette.secondary.main,
    },
    title: {
        padding: `${spacing(4)} 0 ${spacing(0)} 0`,
        textAlign: "center",
        // alignItems: "flex-end",
    },
    content: {
        padding: `${spacing(0)} ${spacing(2)}`,
        overflow: "auto",
        // overflowY: "unset",
        width: "100%",

        // [breakpoints.up("md")]: {
        //   minWidth: 580,
        // },
        // [breakpoints.down("sm")]: {
        //   minWidth: 480,
        // },
    },
    footer: {
        margin: 10,
        marginBottom: spacing(2),
        marginLeft: spacing(1),
        marginRight: spacing(1),
        justifyContent: "space-evenly",
    },
    topActions: {
        margin: 0,
        justifyContent: "flex-end",
    },
}));

export const financeViewStyles = makeStyles(({ spacing, palette }) => ({
    inputContainer: {
        marginTop: spacing(2),
        marginBottom: spacing(1),
        width: "70%",
    },
    leftColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingRight: isMobile ? 0 : 4 },
    rightColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingLeft: isMobile ? 0 : 4 },
    paper: {
        margin: 8,
        position: "relative",
        overflowY: "auto",
        "@media print": {
            overflowY: "visible",
            boxShadow: "none",
        },
    },
    margin: {
        margin: spacing(1),
    },
    drawerPaper: {
        width: 400,
        backgroundColor: "red",
        height: "-webkit-fill-available",
        borderLeft: `1px solid ${palette.grey[500]}`,
    },
}));

export const transferActionDialogStyles = makeStyles(({ spacing, typography, customPalette }) => ({
    modal: {
        padding: spacing(3),
        // overflowY: "unset",
    },
    title: {
        marginBottom: spacing(3),
    },
    table: {
        ...typography.body1,
        border: "none",
        "&.table thead": {
            background: "transparent",
        },
        "&.table thead .th": {
            ...typography.caption,
            background: "transparent",
            fontWeight: "bold",
        },
        "& tbody": {
            overflowY: "auto",
        },
    },
    footer: {
        margin: 0,
    },
    warning: {
        backgroundColor: customPalette.yellow[100],
    },
    warningContainer: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    warningIcon: {
        color: customPalette.yellow[700],
        marginRight: spacing(0.5),
    },
    payIcon: {
        color: customPalette.green[700],
        marginRight: spacing(0),
    },
    totalContainer: {
        marginTop: spacing(3),
        marginBottom: spacing(3),
        padding: spacing(1),
    },
    totalLabel: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        marginRight: spacing(4),
        paddingTop: spacing(2),
    },
    totalLine: {
        width: "100%",
        height: 2,
        marginBottom: 16,
    },
}));

export const paymentMenuStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export const overviewTableActionsStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    table: {
        minWidth: "100%",
    },
}));

export const individualTransfersActionsStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    table: {
        minWidth: "100%",
    },
}));

export const dcTransfersHeaderStyles = makeStyles(
    ({ spacing, zIndex, transitions, shape, palette }) => ({
        root: {
            backgroundColor: palette.grey[100],
            border: `0.7px solid ${palette.grey[300]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(2),
            paddingTop: spacing(1),
        },
        backdrop: {
            zIndex: zIndex.drawer + 1,
            color: "#fff",
        },

        tableContainer: {
            backgroundColor: palette.grey[100],
            border: `0.8px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(0),
            paddingTop: spacing(1),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing(2),
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        square: {
            color: palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        rounded: {
            color: "#fff",
            backgroundColor: green[500],
        },
        titleContainer: {
            padding: `${spacing(1)} ${spacing(2)}`,
            minHeight: 64,
        },
        transferCount: {
            marginRight: spacing(2),
        },
        leftContainer: {
            marginRight: spacing(1),
        },
        rightContainer: {
            marginLeft: spacing(1),
        },
        centerContainer: {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
        },
        searchInputLabel: {},
        table: {
            flex: 1,
            width: "100%",
            border: "none",
        },
        input: {
            borderRadius: 4,
            position: "relative",
            width: "100%",
            backgroundColor: palette.background.paper,
            border: "1px solid #ced4da",
            fontSize: 13,
            padding: "0px 26px 0px 12px",
            transition: transitions.create(["border-color", "box-shadow"]),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
            },
        },
        paper: {
            border: "1px solid",
            padding: spacing(1),
            backgroundColor: palette.background.paper,
            alignSelf: "flex-end",
            position: "relative !important",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: spacing(1),
        },
    })
);

export const dcReversalsHeaderStyles = makeStyles(
    ({ spacing, zIndex, transitions, shape, palette }) => ({
        root: {
            backgroundColor: palette.grey[100],
            border: `0.7px solid ${palette.grey[300]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(2),
            paddingTop: spacing(1),
        },
        backdrop: {
            zIndex: zIndex.drawer + 1,
            color: "#fff",
        },

        tableContainer: {
            backgroundColor: palette.grey[100],
            border: `0.8px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(0),
            paddingTop: spacing(1),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing(2),
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        square: {
            color: palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        rounded: {
            color: "#fff",
            backgroundColor: green[500],
        },
        titleContainer: {
            padding: `${spacing(1)} ${spacing(2)}`,
            minHeight: 64,
        },
        transferCount: {
            marginRight: spacing(2),
        },
        leftContainer: {
            marginRight: spacing(1),
        },
        rightContainer: {
            marginLeft: spacing(1),
        },
        centerContainer: {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
        },
        searchInputLabel: {},
        table: {
            flex: 1,
            width: "100%",
            border: "none",
        },
        input: {
            borderRadius: 4,
            position: "relative",
            width: "100%",
            backgroundColor: palette.background.paper,
            border: "1px solid #ced4da",
            fontSize: 13,
            padding: "0px 26px 0px 12px",
            transition: transitions.create(["border-color", "box-shadow"]),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
            },
        },
        paper: {
            border: "1px solid",
            padding: spacing(1),
            backgroundColor: palette.background.paper,
            alignSelf: "flex-end",
            position: "relative !important",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: spacing(1),
        },
    })
);

export const dcHeaderStyles = makeStyles(({ spacing, zIndex, transitions, shape, palette }) => ({
    root: {
        backgroundColor: palette.grey[100],
        border: `0.7px solid ${palette.grey[300]}`,
        borderRadius: shape.borderRadius,
        marginTop: spacing(0),
        marginBottom: spacing(2),
        paddingTop: spacing(1),
    },
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: "#fff",
    },

    tableContainer: {
        backgroundColor: palette.grey[100],
        border: `0.8px solid ${palette.grey[400]}`,
        borderRadius: shape.borderRadius,
        marginTop: spacing(0),
        marginBottom: spacing(0),
        paddingTop: spacing(1),
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        paddingBottom: spacing(2),
    },
    badgeRoot: {
        marginTop: spacing(0),
        marginBottom: spacing(0),
    },
    square: {
        color: palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    rounded: {
        color: "#fff",
        backgroundColor: green[500],
    },
    titleContainer: {
        padding: `${spacing(1)} ${spacing(2)}`,
        minHeight: 64,
    },
    transferCount: {
        marginRight: spacing(2),
    },
    leftContainer: {
        marginRight: spacing(1),
    },
    rightContainer: {
        marginLeft: spacing(1),
    },
    centerContainer: {
        marginLeft: spacing(0.5),
        marginRight: spacing(0.5),
    },
    searchInputLabel: {},
    table: {
        flex: 1,
        width: "100%",
        border: "none",
    },
    input: {
        borderRadius: 4,
        position: "relative",
        width: "100%",
        backgroundColor: palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 13,
        padding: "0px 26px 0px 12px",
        transition: transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
        },
    },
    paper: {
        border: "1px solid",
        padding: spacing(1),
        backgroundColor: palette.background.paper,
        alignSelf: "flex-end",
        position: "relative !important",
    },

    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: spacing(1),
    },
}));

export const financeViewStyles2 = makeStyles(({ spacing, palette }) => ({
    inputContainer: {
        marginTop: spacing(2),
        marginBottom: spacing(1),
        width: "70%",
    },
    leftColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingRight: isMobile ? 0 : 4 },
    rightColumn: { marginTop: spacing(2), marginBottom: spacing(1), paddingLeft: isMobile ? 0 : 4 },
    paper: {
        margin: 8,
        position: "relative",
        overflowY: "auto",
        "@media print": {
            overflowY: "visible",
            boxShadow: "none",
        },
    },
    margin: {
        margin: spacing(1),
    },
    drawerPaper: {
        width: 400,
        backgroundColor: "red",
        height: "-webkit-fill-available",
        borderLeft: `1px solid ${palette.grey[500]}`,
    },
}));

export const financeLandingStyles = makeStyles(({ spacing, palette, shape, customPalette }) => ({
    sectionContainer: {
        backgroundColor: `${palette.common.white}`,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        padding: spacing(3),
        marginTop: spacing(2),
        // height: "50vh",
        fontSize: 144,
    },
    header: {
        fontSize: 144,
    },
    chip: {
        backgroundColor: customPalette.blue[100],
        color: customPalette.blue[900],
        padding: `${spacing(1)} ${spacing(0.5)}`,
    },
}));

export const transfersTabStyles = makeStyles(
    ({ spacing, zIndex, transitions, shape, palette }) => ({
        root: {
            backgroundColor: palette.grey[100],
            border: `0.7px solid ${palette.grey[300]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(2),
            paddingTop: spacing(1),
        },
        dateFilter: {
            '& > .rdtPicker': {
                position: 'fixed',
                marginTop: '3px'
            },
            '& > .form-control': {
                marginTop: '0px !important',
            }
        },
        dateInput: {
            "& > div": {
                background: "none !important",
                backgroundColor: "none !important"
            }
        },
        backdrop: {
            zIndex: zIndex.drawer + 1,
            color: "#fff",
        },

        tableContainer: {
            backgroundColor: palette.grey[100],
            border: `0.8px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(0),
            paddingTop: spacing(1),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing(2),
            height: "100%"
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        square: {
            color: palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        rounded: {
            color: "#fff",
            backgroundColor: green[500],
        },
        titleContainer: {
            padding: `${spacing(1)} ${spacing(2)}`,
            minHeight: 64,
        },
        transferCount: {
            marginRight: spacing(2),
        },
        leftContainer: {
            marginRight: spacing(1),
        },
        rightContainer: {
            marginLeft: spacing(1),
        },
        centerContainer: {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
        },
        searchInputLabel: {},
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 1.5,
            border: `0.7px solid ${palette.grey[400]}`,
            width: "100%",
            paddingBottom: spacing(2),
        },
        "rt-td": {
            height: " 25px!important",
        },
        input: {
            borderRadius: 4,
            position: "relative",
            width: "100%",
            backgroundColor: palette.background.paper,
            border: "1px solid #ced4da",
            fontSize: 13,
            padding: "0px 26px 0px 12px",
            transition: transitions.create(["border-color", "box-shadow"]),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
            },
        },
        paper: {
            border: "1px solid",
            padding: spacing(1),
            backgroundColor: palette.background.paper,
            alignSelf: "flex-end",
            position: "relative !important",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: spacing(1),
        },
    })
);

export const holidayTransfersTabStyles = makeStyles(
    ({ spacing, zIndex, transitions, shape, palette }) => ({
        root: {
            backgroundColor: palette.grey[100],
            border: `0.7px solid ${palette.grey[300]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(2),
            paddingTop: spacing(1),
        },
        dateFilter: {
            '& > .rdtPicker': {
                position: 'fixed',
                marginTop: '3px'
            },
            '& > .form-control': {
                marginTop: '0px !important',
            }
        },
        dateInput: {
            "& > div": {
                background: "none !important",
                backgroundColor: "none !important"
            }
        },
        backdrop: {
            zIndex: zIndex.drawer + 1,
            color: "#fff",
        },

        tableContainer: {
            backgroundColor: palette.grey[100],
            border: `0.8px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(0),
            paddingTop: spacing(1),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing(2),
            height: "100%"
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        square: {
            color: palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        rounded: {
            color: "#fff",
            backgroundColor: green[500],
        },
        titleContainer: {
            padding: `${spacing(1)} ${spacing(2)}`,
            minHeight: 64,
        },
        transferCount: {
            marginRight: spacing(2),
        },
        leftContainer: {
            marginRight: spacing(1),
        },
        rightContainer: {
            marginLeft: spacing(1),
        },
        centerContainer: {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
        },
        searchInputLabel: {},
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 1.5,
            border: `0.7px solid ${palette.grey[400]}`,
            width: "100%",
            paddingBottom: spacing(2),
        },
        "rt-td": {
            height: " 25px!important",
        },
        input: {
            borderRadius: 4,
            position: "relative",
            width: "100%",
            backgroundColor: palette.background.paper,
            border: "1px solid #ced4da",
            fontSize: 13,
            padding: "0px 26px 0px 12px",
            transition: transitions.create(["border-color", "box-shadow"]),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
            },
        },
        paper: {
            border: "1px solid",
            padding: spacing(1),
            backgroundColor: palette.background.paper,
            alignSelf: "flex-end",
            position: "relative !important",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: spacing(1),
        },
    })
);

export const PaymentsTrendCardStyles = withStyles(({ palette, spacing, shape }) => ({
    container: {
        height: "35%",
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        height: "100%",
    },
    tooltip: {
        backgroundColor: palette.common.black,
        padding: spacing(1),
        borderRadius: shape.spacing,
    },
    chart: {
        width: "100%",
        height: "100%",
    },
}));

export const reversalsTabStyles = makeStyles(
    ({ spacing, zIndex, transitions, shape, palette }) => ({
        root: {
            backgroundColor: palette.grey[100],
            border: `0.7px solid ${palette.grey[300]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(2),
            paddingTop: spacing(1),
        },
        backdrop: {
            zIndex: zIndex.drawer + 1,
            color: "#fff",
        },

        tableContainer: {
            backgroundColor: palette.grey[100],
            border: `0.8px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            marginTop: spacing(0),
            marginBottom: spacing(0),
            paddingTop: spacing(1),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing(2),
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        square: {
            color: palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        rounded: {
            color: "#fff",
            backgroundColor: green[500],
        },
        titleContainer: {
            padding: `${spacing(1)} ${spacing(2)}`,
            minHeight: 64,
        },
        transferCount: {
            marginRight: spacing(2),
        },
        leftContainer: {
            marginRight: spacing(1),
        },
        rightContainer: {
            marginLeft: spacing(1),
        },
        centerContainer: {
            marginLeft: spacing(0.5),
            marginRight: spacing(0.5),
        },
        searchInputLabel: {},
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 2.5,
            border: `0.7px solid ${palette.grey[400]}`,
            width: "100%",
            paddingBottom: spacing(2),
        },
        "rt-td": {
            height: " 25px!important",
        },
        input: {
            borderRadius: 4,
            position: "relative",
            width: "100%",
            backgroundColor: palette.background.paper,
            border: "1px solid #ced4da",
            fontSize: 13,
            padding: "0px 26px 0px 12px",
            transition: transitions.create(["border-color", "box-shadow"]),
            fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
                borderRadius: 4,
                borderColor: "#80bdff",
                boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
            },
        },
        paper: {
            border: "1px solid",
            padding: spacing(1),
            backgroundColor: palette.background.paper,
            alignSelf: "flex-end",
            position: "relative !important",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: spacing(1),
        },
    })
);

export const incompleteJobsTableStyles = makeStyles(
    ({ breakpoints, spacing, shape, palette, customPalette }) => ({
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 2,
            border: `1px solid ${palette.grey[500]}`,
            width: "100%",
        },
        "rt-td": {
            height: " 25px!important",
        },
        container: {
            height: "100%",
            paddingTop: spacing(2),
            marginRight: spacing(1),
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            height: "100%",
            padding: spacing(2),
        },
        tabbedTableContainer: {
            marginTop: spacing(3),
        },
        tableContainer: {
            width: "100%",
        },
        tabIndicator: {
            display: "none !important",
        },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        tabLabel: {
            fontSize: "1rem",
        },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
            },
        },
        statsContainer: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        container2: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                //   paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        employerContainer: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                //   "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        wrapper: {
            marginTop: spacing(2),
            [breakpoints.down("sm")]: {
                marginTop: spacing(0),
            },
        },
        card2: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        statsCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        upOrDown: {
            [breakpoints.down("sm")]: {
                fontSize: 11,
            },
        },
        mobileH5: {
            width: "100%",
            [breakpoints.down("sm")]: {
                // padding: `${spacing(1)} !important`,
                fontSize: 13,
                // "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        employerCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            paddingTop: `${spacing(1)} !important`,
            paddingBottom: `${spacing(1)} !important`,
            height: "100%",
        },
        star: { color: palette.text.primary },
    })
);

export const jobsWithPendingPayStyles = makeStyles(
    ({ breakpoints, spacing, shape, palette, customPalette }) => ({
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 2,
            border: `1px solid ${palette.grey[500]}`,
            width: "100%",
        },
        "rt-td": {
            height: " 25px!important",
        },
        container: {
            height: "100%",
            paddingTop: spacing(2),
            marginRight: spacing(1),
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            height: "100%",
            padding: spacing(2),
        },
        tabbedTableContainer: {
            marginTop: spacing(3),
        },
        tableContainer: {
            width: "100%",
        },
        tabIndicator: {
            display: "none !important",
        },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        tabLabel: {
            fontSize: "1rem",
        },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
            },
        },
        statsContainer: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        container2: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                //   paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        employerContainer: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                //   "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        wrapper: {
            marginTop: spacing(2),
            [breakpoints.down("sm")]: {
                marginTop: spacing(0),
            },
        },
        card2: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        statsCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        upOrDown: {
            [breakpoints.down("sm")]: {
                fontSize: 11,
            },
        },
        mobileH5: {
            width: "100%",
            [breakpoints.down("sm")]: {
                // padding: `${spacing(1)} !important`,
                fontSize: 13,
                // "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        employerCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            paddingTop: `${spacing(1)} !important`,
            paddingBottom: `${spacing(1)} !important`,
            height: "100%",
        },
        star: { color: palette.text.primary },
    })
);

export const CustomTableCellStyles = withStyles({
    root: {
        border: "none",
        padding: "2px 4px",
        fontSize: 13,
    },
    head: {
        fontSize: 14,
        color: "#404b5a",
    },
    body: {
        fontSize: 14,
        padding: "4px",
    },
});

export const reversalsTableStyles = makeStyles(
    ({ breakpoints, spacing, shape, palette, customPalette }) => ({
        table: {
            flex: 1,
            borderRadius: shape.borderRadius / 2,
            border: `1px solid ${palette.grey[500]}`,
            width: "100%",
        },
        "rt-td": {
            height: " 25px!important",
        },
        container: {
            height: "100%",
            paddingTop: spacing(2),
            marginRight: spacing(1),
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            height: "100%",
            padding: spacing(2),
        },
        tabbedTableContainer: {
            marginTop: spacing(3),
        },
        tableContainer: {
            width: "100%",
        },
        tabIndicator: {
            display: "none !important",
        },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        tabLabel: {
            fontSize: "1rem",
        },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
            },
        },
        statsContainer: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        container2: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                //   paddingRight: spacing(0),
                paddingBottom: spacing(1),
                "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        employerContainer: {
            paddingRight: spacing(2),
            paddingBottom: spacing(1),
            [breakpoints.down("sm")]: {
                paddingRight: spacing(0),
                paddingBottom: spacing(1),
                //   "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
                //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
                //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            },
        },
        wrapper: {
            marginTop: spacing(2),
            [breakpoints.down("sm")]: {
                marginTop: spacing(0),
            },
        },
        card2: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        statsCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        upOrDown: {
            [breakpoints.down("sm")]: {
                fontSize: 11,
            },
        },
        mobileH5: {
            width: "100%",
            [breakpoints.down("sm")]: {
                // padding: `${spacing(1)} !important`,
                fontSize: 13,
                // "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        employerCard: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            paddingTop: `${spacing(1)} !important`,
            paddingBottom: `${spacing(1)} !important`,
            height: "100%",
        },
        star: { color: palette.text.primary },
    })
);
export const invoiceBuilderComponentStyles = makeStyles(() => ({
    bodyMessage: { fontSize: 13, fontWeight: 700, width: "100%" },
    deleteButton: { height: 34, marginRight: 20 },
    leftContainer: { paddingLeft: 15, paddingRight: 5, borderRight: "1px solid lightgrey" },
    dialogTitle: {
        paddingBottom: 0,
        marginBottom: 0,
        fontSize: 16,
        fontWeight: 700,
        width: "100%",
    },
    subTitle: { fontSize: 13, paddingTop: 0, width: "100%" },
    btnContainer: { paddingTop: 30, width: "100%" },
}));

export const billingServiceComponentStyles = makeStyles(() => ({
    container: { marginTop: 0, marginBottom: 8, padding: 12, paddingTop: 0 },
}));

export const baseStyles = makeStyles(() => ({
    container100: { width: "100%", display: "block" },
}));
