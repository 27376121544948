import React from 'react'

export default function HighDefinationImageRenderer({ document, style = {} }) {
    return (
        <div style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflow: "auto"
        }}>
            <img
                style={{ height: "100%", width: "100%", objectFit: "contain", objectPosition: 'top', ...style }}
                src={document?.signedDocumentUrl} />
        </div>
    );
};
