import { AppBar, Chip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SubNav from 'components/Navigation/SubNav';
import SubNavTab from 'components/Navigation/SubNavTab';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "24px 32px 12px",
        background: theme.palette.background.paper,
        borderBottom: 0,
        position: "relative",
        width: "100%",
        zIndex: 1029,
        color: "#555555",
        border: 0,
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        transition: "all 150ms ease 0s",
        minHeight: 80,
        display: "block",
        marginBottom: 0
    },
    groups: {
        display: 'flex',
        gap: theme.spacing(0.5),
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
        maxWidth: '70%',
        flexWrap: 'wrap'
    },
    headerTitle: {
        color: '#3F454D',
    },
    headerSubTitle: {
        color: '#6B6F75',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: theme.spacing(2)
    },
}));

export default function RequirementDetailHeader() {
    const classes = useStyles();
    const requirementDetails = useSelector(Selectors.getRequirementDetails);
    const activeTab = useSelector(Selectors.getActiveTab);
    const params = new URLSearchParams(history.location.search);
    const type = parseInt(params.get("type"), 10);
    const dispatch = useDispatch();

    useEffect(() => {
        if (history.location.search) {
            if (!isNaN(type)) {
                dispatch(Actions.setActiveTab(parseInt(type, 10)));
            }
        } else {
            history.replace(`${window?.location?.pathname}?type=${0}`);
        };
    }, [type]);

    const handleChangeTab = (event, value) => {
        history.replace(`${window?.location?.pathname}?type=${value}`);
        dispatch(Actions.setActiveTab(value));
    };

    return (
        <>
            <AppBar className={classes.container}>
                <div className={classes.header}>
                    <div>
                        <Typography className={classes.headerTitle} variant="h5" letterSpacing={0.015}>{requirementDetails?.name}</Typography>
                        <Typography className={classes.headerSubTitle}>{requirementDetails?.type === 'Document' ? requirementDetails?.description : requirementDetails?.summary}</Typography>
                    </div>
                    <div className={classes.groups}>
                        {requirementDetails?.groups?.map(group => (
                            <Chip key={group.id} label={group.name} />
                        ))}
                    </div>
                </div>
            </AppBar>
            <SubNav value={activeTab} onChange={handleChangeTab} tabs={[
                <SubNavTab key="AssignedEmployer" label="Assigned Employers" />,
                <SubNavTab key="ConsentDetails" label={`${requirementDetails?.type} Details`} />,
            ]} />
        </>
    )
}
