import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import { AccountBalanceWalletTwoTone, CancelScheduleSend, PictureAsPdf, Receipt, SettingsBackupRestore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { downloadFileFromLink } from "utils/file";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export default function HolidayPaymentTransferTableMenu({ payment, worker, props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const isPaystubDownloading = useSelector(FinanceSelectors.getIsDownloadPaystubLoading)
    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };


    const toggleResendStubDialog = () => {
        dispatch(FinanceActions.setIsResendPayStubDialog(payment.holidayWorkerId));
        closeMenu();
    };

    const toggleIsViewingPdf = () => {
        dispatch(FinanceActions.downloadHolidayPaystubSummary(payment.holidayWorkerId)).then(url => {
            if (url) {
                downloadFileFromLink(url);
                setTimeout(() => {
                    closeMenu();
                    dispatch(FinanceActions.setIsDownloadPaystubLoading(false));
                }, 1000);
            } else {
                dispatch(FinanceActions.setIsDownloadPaystubLoading(false));
            }
        });
    };;

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelETransfer = () => {
        dispatch(FinanceActions.setIsCancelETransferDialog({ payment, worker }));
        closeMenu();
    };

    const handleResendETransfer = () => {
        dispatch(FinanceActions.setIsResendETransferDialog({ payment, worker }));
        closeMenu();
    };

    const handleRequestReversal = () => {
        dispatch(FinanceActions.setIsRequestReversalDialog({ payment, worker }));
        closeMenu();
    };

    const handleResolveETransfer = () => {
        dispatch(FinanceActions.setIsResolveETransferDialog({ payment, worker }));
        closeMenu();
    };

    const handleViewProfile = workerId => history.push(`/workers/${workerId}`);

    return (
        <>
            <IconButton
                aria-controls={"menuId"}
                aria-haspopup="true"
                onClick={openMenu}
                disableRipple
                style={{ marginRight: 8, float: "right" }}
                edge="start"
                size="small">
                <MoreVert color="primary" />
            </IconButton>
            <Menu
                id={"menuId"}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}>
                <MenuItem
                    onClick={() => {
                        handleViewProfile(payment.workerId);
                        closeMenu();
                    }}>
                    <Receipt className={classes.icon} color="action" />
                    View Profile
                </MenuItem>
                <MenuItem disabled={
                    !payment?.holidayPayroll ||
                    !payment.holidayPayroll?.holidayPayment ||
                    !payment.holidayPayroll?.holidayPayment.length ||
                    isPaystubDownloading === payment.holidayWorkerId
                } onClick={toggleIsViewingPdf}>
                    {isPaystubDownloading === payment.holidayWorkerId ? <CircularProgress className={classes.icon} size={20} color="action" /> : <PictureAsPdf className={classes.icon} color="action" />}
                    Download Paystub
                </MenuItem>
                <MenuItem disabled={!payment?.holidayPayroll || !payment.holidayPayroll?.holidayPayment || !payment.holidayPayroll?.holidayPayment.length} onClick={toggleResendStubDialog}>
                    <PictureAsPdf className={classes.icon} color="action" />
                    Email Paystub
                </MenuItem>
                <MenuItem
                    disabled={![3, 7, 8].includes(payment.paymentStatusId)}
                    onClick={handleCancelETransfer}>
                    <CancelScheduleSend className={classes.icon} color="action" />
                    Cancel eTransfer
                </MenuItem>

                <MenuItem
                    disabled={![11, 7].includes(payment.paymentStatusId)}
                    onClick={handleResendETransfer}>
                    <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
                    Resend eTransfer
                </MenuItem>

                <MenuItem
                    disabled={![5].includes(payment.paymentStatusId)}
                    onClick={handleRequestReversal}>
                    <SettingsBackupRestore className={classes.icon} color="action" />
                    Request Reversal
                </MenuItem>

                <MenuItem
                    disabled={![5, 3, 1, 14, 11, 7].includes(payment.paymentStatusId)}
                    onClick={handleResolveETransfer}>
                    <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
                    Resolve eTransfer
                </MenuItem>
            </Menu>
        </>
    )
}
