import { gql } from "@apollo/client";
import EmployerFields  from "../fragments/requirementsByEmployer";

export default gql`
query getRequirementsByWorkerId($data: WorkerRequirementInput!){
  getRequirementsByWorkerId(data: $data){
    employer {
      ...EmployerFields
    }
  }
}
  ${EmployerFields }
`;
