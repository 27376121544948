import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { sharedReactTableProps } from "../../../../utils/constant";
import WorkerJobTableDialog from './WorkerJobTableDialog';
import { formatMoney } from "utils/stringUtils";
import Tooltip from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback } from "react";
import ReactTableCustom from "components/ReactTable";
import moment from "moment";
const useStyles = makeStyles(theme => ({
    jobs: {
        display: 'flex',
        justifyContent: 'end',
        '& svg': {
            cursor: 'pointer',
            color: 'gray',
            width: '18px',
            height: '18px',
            marginLeft: '5px'
        }
    },
    table: {
        "&.table tbody .data-row td": {
            padding: theme.spacing(1)
        }
    }
}))
function WorkerWisePayrollTable() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)
    const [worker, setWorker] = useState({})
    const payrollData = useSelector(ReportsSelectors.getWorkerWisePayrollReportData)
    const PaginationData = useSelector(ReportsSelectors.getWorkerWisePayrollReportPagingData)

    const fetchData = useCallback(({ pageIndex, pageSize }) => {
        dispatch(ReportsActions.changeWorkerWisePayrollTablePageSize(pageSize))
        dispatch(ReportsActions.fetchWorkerWisePayrollTableData(pageIndex))
    }, [])

    const onOpenDialog = (worker) => {
        setWorker(worker)
        setOpenDialog(true);
    }
    const onCloseDialog = () => {
        setOpenDialog(false);
    }
    const columns = [
        {
            Header: "Id",
            disableSortBy: true,
            disableFilters: true,
            width: 200,
            accessor: "id",
        },
        {
            Header: "Name",
            disableSortBy: true,
            disableFilters: true,
            width: 150,
            accessor: "FirstName",
            Cell: ({ row: { original } }) => {
                return (
                    <Tooltip title={original.Email} placement="top">
                        <div>
                            {original.FirstName + ' ' + original.LastName}
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            Header: "Talent ID",
            disableSortBy: true,
            disableFilters: true,
            accessor: "TalentID",
            Cell: ({ row: { original } }) => original.TalentID || "-"
        },
        {
            Header: "Birthdate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Birthdate",
            Cell: ({ row: { original } }) => (original.Birthdate ? moment(original.Birthdate).format("MM/DD/YYYY") : "-")
        },
        {
            Header: <div className="text-right">Jobs</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "JobCount",
            Cell: ({ row: { original } }) => <div className={classes.jobs} >
                <div>{original.JobCount}</div>
                <Info onClick={() => onOpenDialog(original)} />
            </div>
        },
        {
            Header: <div className="text-right">Hours Worked</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "HoursWorked",
            Cell: ({ row: { original } }) => original.HoursWorked + 'hr'
        },
        {
            Header: <div className="text-right">Bonus Value</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "BonusValue",
            Cell: ({ row: { original } }) => formatMoney(original.BonusValue)
        },
        {
            Header: <div className="text-right">Gross Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "GrossPay",
            Cell: ({ row: { original } }) => formatMoney(original.GrossPay)
        },
        {
            Header: <div className="text-right">Net Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "NetPay",
            Cell: ({ row: { original } }) => formatMoney(original.NetPay)
        },
        {
            Header: <div className="text-right">Vacation Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "VacationPay",
            Cell: ({ row: { original } }) => formatMoney(original.VacationPay)
        },
        {
            Header: <div className="text-right">EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "EI",
            Cell: ({ row: { original } }) => formatMoney(original.EI)
        },
        {
            Header: <div className="text-right">CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "CPP",
            Cell: ({ row: { original } }) => formatMoney(original.CPP)
        },
        {
            Header: <div className="text-right">Prov. Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "ProvTax",
            Cell: ({ row: { original } }) => formatMoney(original.ProvTax)
        },
        {
            Header: <div className="text-right">Randstad EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadEI",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadEI)
        },
        {
            Header: <div className="text-right">Randstad CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadCPP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadCPP)
        },
        {
            Header: <div className="text-right">Fed Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "FederalTax",
            Cell: ({ row: { original } }) => formatMoney(original.FederalTax)
        },
        {
            Header: <div className="text-right">QPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "QPP",
            Cell: ({ row: { original } }) => formatMoney(original.QPP),
        },
        {
            Header: <div className="text-right">Randstad QPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "RandstadQPP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadQPP),
        },
        {
            Header: <div className="text-right">QPIP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "QPIP",
            Cell: ({ row: { original } }) => formatMoney(original.QPIP),
        },
        {
            Header: <div className="text-right">Randstad QPIP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "RandstadQPIP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadQPIP),
        },
        {
            Header: <div className="text-right">Randstad WCB</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadWCB",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadWCB)
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={payrollData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                classes={classes.table}
                columns={columns}
            />
            {openDialog && <WorkerJobTableDialog
                openDialog={openDialog}
                onCloseDialog={onCloseDialog}
                worker={worker}
            />}
        </React.Fragment>
    );
}

export default WorkerWisePayrollTable;
