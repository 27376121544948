import { makeStyles } from '@mui/styles';
import MainContent from 'components/Containers/MainContent';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions as Actions, selectors as Selectors } from 'store/modules/additionalRequirements';
import RequirementDetailHeader from './components/RequirementDetailHeader';
import SwipeableViews from 'react-swipeable-views';
import history from 'utils/history';
import RequirementDetailTab from './components/RequirementDetailTab';
import AssignedEmployerList from './components/AssignedEmployerList';
import SimpleDialog from 'components/Dialog/SimpleDialog';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0
    },
}));

export default function RequirementDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(Selectors.getIsDetailLoading);
    const params = useParams();
    const URLParams = new URLSearchParams(history.location.search);
    const type = parseInt(URLParams.get("type"), 10);
    const activeTab = useSelector(Selectors.getActiveTab);
    const isRemoveLoading = useSelector(Selectors.getIsRemoveAssignedEmployerLoading);
    const selectedRecordForRemove = useSelector(Selectors.getIsSelectedRecordForRemove);

    useEffect(() => {
        if (params?.id) {
            const id = window.atob(params?.id);
            dispatch(Actions.fetchsRequirementdetails(id));
        };
        return () => {
            dispatch(Actions.clearAdditionalRequirementForm());
        }
    }, [params?.id]);

    const hideDeleteDialog = () => {
        dispatch(Actions.setSelectedRecordForRemove(null));
    };

    const handleRemove = () => {
        dispatch(Actions.removeAssignedEmployer(window.atob(params?.id)));
    };

    return (
        <React.Fragment>
            <MainContent className={classes.container} isLoading={isLoading}>
                <RequirementDetailHeader />
                <MainContent>
                    <SwipeableViews axis="x" index={activeTab}>
                        {type === 0 ? <AssignedEmployerList /> : <></>}
                        {type === 1 ? <RequirementDetailTab /> : <></>}
                    </SwipeableViews>
                </MainContent>
            </MainContent>
            <SimpleDialog
                open={Boolean(selectedRecordForRemove)}
                title="Are you sure you wan't to remove this item?"
                confirmText={"Yes, Remove"}
                onClose={hideDeleteDialog}
                onConfirm={handleRemove}
                onCancel={hideDeleteDialog}
                isLoading={isRemoveLoading}
                disabled={!selectedRecordForRemove || isRemoveLoading}
            />
        </React.Fragment>
    )
}
