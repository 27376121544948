import CreatableGroupsAutocomplete from 'components/Autocomplete/CreatableGroupsAutocomplete'
import React from 'react'

export default function GroupsDropdown(props) {
    return (
        <div>
            <CreatableGroupsAutocomplete menuPortalTarget={document.body} {...props} isMulti />
        </div>
    )
}
