import { gql } from "@apollo/client";

export default gql`
  query getWorkerStats {
    getWorkerStats {
      isActive
      allWorkers
      isSuspended
      isApproved
      isDeactivated
      isVerified
      isUnverified
      isApplied
      isProfileInComplete
    }
  }
`;
