import gql from "graphql-tag";

export default gql`
mutation UpdateProvinceMinimumPay($data: UpdateProvinceMinimumPayInput!) {
    updateProvinceMinimumPay(data: $data) {
      id
      isActive
      provinceId
      minimumPay
      effectiveDateFrom
      effectiveDateTo
      minShiftLength
      maxShiftLength
    }
}
`
