import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementAction } from "store/modules/additionalRequirements";
import ColumnActionMenu from "./ColumnActionMenu";
import { Checkbox, Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactTable from "components/ReactTable";
import moment from "moment";
import GroupFilter from "./GroupFilter";

const useStyles = makeStyles(theme => ({
    ActiveChip: {
        background: "#4caf50",
        color: "#fff"
    },
    InactiveChip: {
        background: theme.customPalette.red[500],
        color: "#fff"
    },
    groups: {
        minWidth: 250,
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'nowrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
        marginRight: theme.spacing(2)
    },
    Consent: {
        backgroundColor: '#F1EEFF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Document: {
        backgroundColor: '#E5F1FF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Validation: {
        backgroundColor: '#FFE5F6',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    masterName: {
        fontSize: 12,
        color: '#3F454D',
        fontWeight: 500,
        lineHeight: '14.06px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        lineClamp: 1,
        WebkitBoxOrient: 'vertical'
    },
    masterDate: {
        color: '#6B6F75',
        fontSize: 10
    },
    summary: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        textAlign: 'left',
        color: '#6B6F75',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        lineClamp: 3,
        WebkitBoxOrient: 'vertical'
    }
}));

function AdditionalRequirementList() {

    const dispatch = useDispatch();
    const data = useSelector((state) => AdditionalRequirementSelector.getAdditionalRequirementsData(state, { part: "data" }));
    const pageFilter = useSelector((state) => AdditionalRequirementSelector.getAdditionalRequirementsData(state, { part: "filter" }));
    const pageSort = useSelector((state) => AdditionalRequirementSelector.getAdditionalRequirementsData(state, { part: "sort" }));
    const PaginationData = useSelector(AdditionalRequirementSelector.getAdditionalRequirementListPagingData);
    const classes = useStyles();

    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        dispatch(AdditionalRequirementAction.setAdditionalRequirementListSort(sortBy));
        dispatch(AdditionalRequirementAction.setAdditionalRequirementListFilter(filters));
        dispatch(AdditionalRequirementAction.changeAdditionalRequirementListPageSize(pageSize));
        dispatch(AdditionalRequirementAction.fetchAdditionalRequirementsTableData(pageIndex));
    }, []);

    const renderChip = useCallback((type, label) => {
        switch (type) {
            case 'Document':
                return <Chip className={classes[type]} label={label} />
            case 'Validation':
                return <Chip className={classes[type]} label={label} />
            case 'Consent':
                return <Chip className={classes[type]} label={label} />
        }
    }, []);

    return (
        <ReactTable
            classes="-highlight"
            columns={[
                {
                    Header: "#",
                    id: "selectedColumns",
                    disableFilters: true,
                    disableSortBy: true,
                    width: 50,
                    collapse: Boolean(data?.length),
                    maxWidth: 50,
                    headerStyle: {
                        textAlign: 'center'
                    },
                    style: {
                        textAlign: 'center'
                    },
                    accessor: ({ id }) => id,
                    Cell: ({ row, state } = {}) => {
                        return (
                            <span>
                                {row?.index + 1 + (state?.pageIndex * state?.pageSize)}
                                {/* <Checkbox /> */}
                            </span>
                        )
                    }
                },
                {
                    Header: "Master Name",
                    id: "name",
                    disableFilters: false,
                    disableSortBy: true,
                    accessor: ({ name }) => name,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Typography className={classes.masterName}>{original.name}</Typography>
                                <Typography className={classes.masterDate} variant="caption">{moment(original.createdAt).format("DD MMM 'YY")}</Typography>
                            </div>
                        )
                    }
                },
                {
                    Header: <div style={{ minWidth: 250 }}>Groups</div>,
                    id: "Group.Id",
                    disableFilters: false,
                    disableSortBy: true,
                    collapse: Boolean(data?.length),
                    accessor: ({ groups }) => groups,
                    Cell: ({ row: { original } }) => {
                        if (!original?.groups?.length) return '-'
                        return (
                            <div className={classes.groups}>
                                {original?.groups?.slice(0, 2).map(group => (
                                    <Chip key={group.id} label={group.name} />
                                ))}
                                {original?.groups?.length > 2 ? <Chip label={"+" + (original?.groups?.length - 2)} /> : null}
                            </div>
                        )
                    },
                    Filter: GroupFilter,
                },
                {
                    Header: "Requirement Summary",
                    id: "summary",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ summary }) => summary,
                    Cell: ({ row: { original } }) => <Typography className={classes.summary}>{original.type === 'Document' ? original.description : original.summary}</Typography>
                },
                {
                    Header: "Type",
                    id: "type",
                    disableFilters: false,
                    disableSortBy: true,
                    width: 100,
                    maxWidth: 100,
                    style: {
                        width: 100,
                        maxWidth: 100,
                    },
                    collapse: Boolean(data?.length),
                    accessor: ({ type }) => type,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {renderChip(original.type, original.type)}
                            </div>
                        );
                    }
                },
                {
                    Header: "Actions",
                    id: "actions",
                    disableSortBy: true,
                    disableFilters: true,
                    collapse: Boolean(data?.length),
                    Cell: ({ row: { original } }) => {
                        const { id } = original;
                        return <ColumnActionMenu id={id} />;
                    },
                },
            ]}
            fetchData={fetchData}
            data={data}
            loading={PaginationData.isLoading}
            pageCount={PaginationData.totalCount}
            currentPage={PaginationData.currentPage}
            defaultPageSize={PaginationData.pageSize}
            defaultFilter={pageFilter}
            defaultSort={pageSort}
        />
    )
}

export default AdditionalRequirementList
