import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Skeleton from '@mui/material/Skeleton';
import Button from "components/Button/Button";
import Line from "components/Placeholders/Line";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, displayLocalDateTime, formatDurationFromMins, formattedTimeWithOffset } from "utils/time";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import EditIcon from '@mui/icons-material/Edit';
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ spacing, typography, customPalette }) => ({
  modal: {
    padding: spacing(3),
    // overflowY: "unset",
    maxWidth: '100%'
  },
  title: {
    padding: '0 8px'
  },
  table: {
    ...typography.body1,
    border: "none",
    "&.table thead": {
      background: "transparent",
    },
    "&.table thead th": {
      ...typography.caption,
      background: "transparent",
      fontWeight: "bold",
    },
    "& tbody": {
      // maxHeight: 250,
      overflowY: "auto",
    },
  },
  footer: {
    margin: 0,
  },
  warning: {
    backgroundColor: customPalette.yellow[100],
  },
  warningContainer: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  warningIcon: {
    color: customPalette.yellow[700],
    marginRight: spacing(0.5),
  },
  rateSection: {
    position: 'relative'
  },
  rateText: {
    marginLeft: '20px'
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: spacing(0.5),
    position: 'absolute'
  },
  payIcon: {
    color: customPalette.green[700],
    marginRight: spacing(0),
  },
  totalContainer: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
    padding: spacing(1),
  },
  totalLabel: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    marginRight: spacing(4),
    paddingTop: spacing(2),
  },
  totalLine: {
    width: "100%",
    height: 2,
    marginBottom: 16,
  },
  content: {
    padding: 8,
    overflow: 'initial'
  },
  updateBtn: {
    padding: "0 32px !important"
  }
}));
function WarningTitle({ values }) {
  return (
    <>
      {values.map((v, i) => (
        <p key={i}>{v}</p>
      ))}
    </>
  );
}
function PayrollSummary({ visible, onCancel, onConfirm }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const jobId = useSelector(JobDetailSelectors.getJobId);
  const companyName = useSelector(JobDetailSelectors.getCompanyName);
  const start = useSelector(JobDetailSelectors.getJobStartUtc);
  const end = useSelector(JobDetailSelectors.getJobEndUtc);
  const positions = useSelector(JobDetailSelectors.getPositions);
  const breakInfoLabel = useSelector(JobDetailSelectors.getJobBreakInfoLabel);
  const isInitializingPayroll = useSelector(JobDetailSelectors.getIsInitializingJobPayroll);
  const isUninitializingPayroll = useSelector(JobDetailSelectors.getIsUninitializingJobPayroll);
  const isPaying = useSelector(JobDetailSelectors.getIsPayingJob);
  const isPayingPosition = useSelector(FinanceSelectors.getIsSendingPay);
  const payrollSummary = useSelector(JobDetailSelectors.getPayrollSummary);
  const [position, setPosition] = useState(false);
  const [newPayRate, setNewPayRate] = useState(0);
  const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);

  useEffect(() => {
    if (visible) {
      dispatch(JobDetailActions.initializeJobPayrollDetail(jobId));
    }
  }, [visible, dispatch, jobId]);
  const showBonusColumn = useMemo(() => {
    return payrollSummary.some(({ bonus }) => bonus && bonus > 0);
  }, [payrollSummary]);
  const netAmount = useMemo(() => {
    return payrollSummary.reduce((acc, { netPay }) => acc + netPay, 0);
  }, [payrollSummary]);

  async function handleCancel() {
    // await dispatch(JobDetailActions.uninitializeJobPayroll());
    onCancel();
  }
  async function payPosition(id) {
    await dispatch(FinanceActions.payPosition(id)).then(() => {
      onCancel();
      dispatch(JobDetailActions.fetchJob(jobId)).then(() => {
      });
    });

    // await onCancel();
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, data) => {
    setPosition(data)
    setNewPayRate(Math.round(data.payRate * 100) / 100);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleEditRates = () => {
    dispatch(JobDetailActions.editWorkerRatesFromDialog(position.id, Number(newPayRate), position.jobId, position.workerId)).then(res => {
      dispatch(JobDetailActions.initializeJobPayrollDetail(jobId)).catch(() => onCancel());
      handleClose();
    });
  };
  const columns = [
    {
      // eslint-disable-next-line react/display-name, react/prop-types
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return null;
      },
      id: "profileImage",
      // eslint-disable-next-line react/display-name, react/prop-types
      accessor: ({ worker }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="circular" height={40} width={40} />;
        }
        return <Avatar src={worker.signedProfileImageUrl} />;
      },
      disableSortBy: true,
      disableFilters: true,
      width: 70,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Worker";
      },
      id: "firstName",
      accessor: ({ worker }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return (worker.firstName ? worker.firstName : '') + ' ' + (worker.lastName ? worker.lastName : '');
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Checked In";
      },
      id: "startShift",
      width: 150,
      accessor: ({ startShiftUtc }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return convertUTCToTimezoneWithOffset(startShiftUtc, '', 'MMM Do h:mm a');;
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Checked Out";
      },
      id: "endShiftUtc",
      width: 150,
      accessor: ({ endShiftUtc }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const end = convertUTCToTimezoneWithOffset(endShiftUtc, '', "MMM Do h:mm a");
        return end;
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Break";
      },
      id: "breakMins",
      width: 80,
      accessor: ({ breakMins }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const breakLabel =
          breakMins === null || breakMins === undefined ? "-" : `${breakMins} mins`;
        return breakLabel;
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Duration";
      },
      id: "durationMins",
      width: 100,
      accessor: ({ durationMins }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const duration = !durationMins
          ? "-"
          : formatDurationFromMins(durationMins, {
            hourLabel: "hrs",
            minuteLabel: "mins",
            separator: "",
          });
        return duration;
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Rate";
      },
      id: "payRate",
      width: 150,
      accessor: (row) => {
        const { payRate } = row;

        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return (
          <div className={classes.rateSection}>
            <EditIcon onClick={(e) => handleClick(e, row)} className={classes.editIcon} fontSize="small" />
            <span className={classes.rateText}>{formatMoney(payRate)}</span>
          </div>
        );
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Bonus";
      },
      id: "bonus",
      width: 80,
      accessor: ({ bonus }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const bonusLabel = !bonus ? "-" : formatMoney(bonus);
        return bonusLabel;
      },
      // show: showBonusColumn,
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Gross";
      },
      id: "grossPay",
      width: 90,
      accessor: ({ grossPay }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return formatMoney(grossPay);
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Net";
      },
      id: "netPay",
      width: 60,
      accessor: ({ netPay }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return formatMoney(netPay);
      },
      disableSortBy: !isInitializingPayroll,
    },
    {
      Header: () => {
        return "";
      },
      id: "id",
      width: 120,
      accessor: ({ id }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return (
          <Fragment>
            <Button
              label="Pay"
              onClick={() => payPosition(id)}
              color="secondary"
              isLoading={isPaying || isPayingPosition}
              buttonClassName={classes.updateBtn}
              size={'small'}
              disabled={isInitializingPayroll}
            />
            {/*<IconButton edge="end" onClick={() => payPosition(id)}>
              <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
                <MonetizationOnTwoTone className={classes.payIcon} />
              </Tooltip>
            </IconButton>*/}
          </Fragment>
        );
      },
      disableSortBy: true,
    },
  ];
  return (
    <Fragment>
      <Dialog fullWidth maxWidth="lg" classes={{ paper: classes.modal }} open={visible}>
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" align="left">
                {`Payroll Summary for Job #${jobId}`}
              </Typography>
              <Typography variant="h4" align="left">
                {companyName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {`${convertUTCToTimezoneWithOffset(start, jobTimezone, "dddd, MMMM Do, YYYY")} ${displayLocalDateTime(start, jobTimezone, "dddd, MMMM Do, YYYY")}`}
              </Typography>
              <Typography variant="body1" align="right">
                {formattedTimeWithOffset(start, end, jobTimezone)}
              </Typography>
              {breakInfoLabel && (
                <Typography variant="body1" align="right">
                  {breakInfoLabel}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <ReactTableSimple
            classes={classNames("-striped", classes.table)}
            defaultPageSize={200}
            data={payrollSummary}
            columns={columns}
          />
        </DialogContent>
        <Grid container justifyContent="flex-end" className={classes.totalContainer}>
          <Grid item className={classes.totalLabel}>
            <Typography variant="subtitle1" align="right">
              {"Total"}
            </Typography>
          </Grid>
          <Grid item>
            <Line className={classes.totalLine} />
            <Typography variant="h4">{formatMoney(netAmount)}</Typography>
          </Grid>
        </Grid>
        <DialogActions className={classes.footer}>
          <Button
            label="Cancel"
            onClick={handleCancel}
            color="transparent"
            isLoading={isUninitializingPayroll}
            disabled={isInitializingPayroll || isPaying}
          />
          <Button
            label="Confirm & Pay"
            onClick={onConfirm}
            color="secondary"
            isLoading={isPaying}
            disabled={isInitializingPayroll || isUninitializingPayroll}
          />
        </DialogActions>
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 10 }}>
          <Typography className={classes.typography}>Payrate</Typography>
          <TextField id="standard-basic" type="number" value={newPayRate} onChange={(e) => setNewPayRate(e.target.value)} />
          <br />
          <Button
            label="Update"
            disabled={!newPayRate}
            onClick={() => handleEditRates()}
            buttonClassName={classes.updateBtn}
            color="secondary"
            size={'small'}
            style={{ paddingTop: 10, paddingLeft: 0 }}
          />
        </div>
      </Popover>
    </Fragment>
  );
}
PayrollSummary.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
PayrollSummary.defaultProps = {
  visible: false,
  onCancel: () => { },
  onConfirm: () => { },
};
export default PayrollSummary;
