export default {
    SET_LOADING_STATE: "workersReports/SET_LOADING_STATE",

    SET_WORKER_PAYROLL_DATA: "workersReports/SET_WORKER_PAYROLL_DATA",
    SET_WORKER_PAYROLL_FILTER: "workersReports/SET_WORKER_PAYROLL_FILTER",
    SET_WORKER_PAYROLL_TOTAL_COUNT: "workersReports/SET_WORKER_PAYROLL_TOTAL_COUNT",

    SET_WORKER_WISE_PAYROLL_DATA: "workersReports/SET_WORKER_WISE_PAYROLL_DATA",
    SET_WORKER_WISE_PAYROLL_FILTER: "workersReports/SET_WORKER_WISE_PAYROLL_FILTER",
    SET_WORKER_WISE_PAYROLL_TOTAL_COUNT: "workersReports/SET_WORKER_WISE_PAYROLL_TOTAL_COUNT",

    SET_WORKER_JOB_DATA: "workersReports/SET_WORKER_JOB_DATA",
    SET_WORKER_JOB_FILTER: "workersReports/SET_WORKER_JOB_FILTER",

    SET_WEEKLY_BILLING_REPORT_DATA: "weeklyBillingReport/SET_WEEKLY_BILLING_REPORT_DATA",
    SET_WEEKLY_BILLING_REPORT_FILTER: "weeklyBillingReport/SET_WEEKLY_BILLING_REPORT_FILTER",
    SET_WEEKLY_BILLING_REPORT_TOTAL_COUNT: "weeklyBillingReport/SET_WEEKLY_BILLING_REPORT_TOTAL_COUNT",

    SET_ACTIVE_EMPLOYER_DATA: "employerReports/SET_ACTIVE_EMPLOYER_DATA",
    SET_ACTIVE_EMPLOYER_FILTER: "workersReports/SET_ACTIVE_EMPLOYER_FILTER",
    SET_ACTIVE_EMPLOYER_TOTAL_COUNT: "workersReports/SET_ACTIVE_EMPLOYER_TOTAL_COUNT",

    SET_WSBC_DATA: "employerReports/SET_WSBC_DATA",
    SET_WSBC_FILTER: "workersReports/SET_WSBC_FILTER",
    SET_WSBC_TOTAL_COUNT: "workersReports/SET_WSBC_TOTAL_COUNT",

    SET_WORKER_JOB_COUNT_DATA: "workerJobCountReports/SET_WORKER_JOB_COUNT_DATA",
    SET_WORKER_JOB_COUNT_FILTER: "workersReports/SET_WORKER_JOB_COUNT_FILTER",
    SET_WORKER_JOB_COUNT_TOTAL_COUNT: "workerJobCountReports/SET_WORKER_JOB_COUNT_TOTAL_COUNT",

    SET_DC_BANK_TRANSACTION_DATA: "workerJobCountReports/SET_DC_BANK_TRANSACTION_DATA",
    SET_DC_BANK_TRANSACTION_FILTER: "workersReports/SET_DC_BANK_TRANSACTION_FILTER",

    SET_WORK_ORDER_DATA: "workOrderReports/SET_WORK_ORDER_DATA",
    SET_WORK_ORDER_FILTER: "workOrderReports/SET_WORK_ORDER_FILTER",
    SET_WORK_ORDER_TOTAL_COUNT: "workOrderReports/SET_WORK_ORDER_TOTAL_COUNT",

    SET_HOLIDAY_PAYROLL_DATA: "workersReports/SET_HOLIDAY_PAYROLL_DATA",
    SET_HOLIDAY_PAYROLL_FILTER: "workersReports/SET_HOLIDAY_PAYROLL_FILTER",
    SET_HOLIDAY_PAYROLL_TOTAL_COUNT: "workersReports/SET_HOLIDAY_PAYROLL_TOTAL_COUNT",
    SET_IS_EDIT_WORK_ORDER_NUMBER: "workersReports/SET_IS_EDIT_WORK_ORDER_NUMBER",
    SET_CHANGE_WORK_ORDER_NUMBER_BY_POSITION_ID: "workersReports/SET_CHANGE_WORK_ORDER_NUMBER_BY_POSITION_ID",
};
