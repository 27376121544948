import { gql } from "@apollo/client";

export const GetJobs = gql`
query jobApplications(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [JobApplicationFilterInput!],
    $order: [JobApplicationOrderInput!],
    ) {
  jobApplications(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        job{
            id
            workType{
                id
                label
            }
            employer{
                id
                companyName
            }
            startUtc
            peopleNeeded
            positionsFilled
        }
      }
    }
  }
}
`;

export const GetIntrestedWorkers = gql`
query getWorkersByJobId($jobId: Int!) {
  getWorkersByJobId(jobId: $jobId) {
    id
    updatedAt
    applicationStatus
    totalCount
    approvedCount
    rejectedCount
    worker {
      id
      fullName
      firstName
      lastName
    }
  }
}
`;

export const GetRequirementByWorkerApplicationId = gql`
query getRequirementsByApplicationId($applicationId: Int!){
  getRequirementsByApplicationId(applicationId: $applicationId) {
    id
    applicationStatus
    approver
    updatedAt
    isApproved
    rejectionReason
    consent
    documentDate
    documentType
    documentValidity
    validTill
    expiryDate
    signedDocumentUrl
    childRequirements{
        id
        applicationStatus
        approver
        updatedAt
        isApproved
        rejectionReason
        consent
        documentDate
        documentType
        documentValidity
        signedDocumentUrl
        employerRequirement{
            id
            validityType
            validityValue
            requirement{
              id
              type
              name
              frenchName
              title
              frenchTitle
              summary
              frenchSummary
              description
              frenchDescription
              isConsent
              isDocHaveExpiry
            }
        }
    }
    employerRequirement {
      id
      validityType
      validityValue
      requirement {
        id
        type
        name
        frenchName
        title
        frenchTitle
        summary
        frenchSummary
        description
        frenchDescription
        isConsent
        isDocHaveExpiry
      }
    }
  }
}
`;
