import { gql } from "@apollo/client";

export const payrollByDcTransferStatusQuery = gql`
  query payrollByDcTransferStatus($type: String!) {
    payrollByDcTransferStatus(type: $type)
  }
`;
export const payrollByJobQuery = gql`
  query payrollByJob($jobId: Int!) {
    payrollByJob(jobId: $jobId) {
      positionId
      payments {
        id
        createdAt
        positionId
        sentAt
        responseAt
        workerId
        payrollId
        retryPaymentId
        paymentStatusId
        dcTransferId
        email
        # amount
        notes
        paymentStatus {
          id
          code
          description
        }
      }
    }
  }
`;
export const payrollByPositionQuery = gql`
  query payrollByPosition($positionId: Int!) {
    payrollByPosition(positionId: $positionId) {
      positionId
      payments {
        id
        createdAt
        positionId
        sentAt
        responseAt
        workerId
        payrollId
        retryPaymentId
        paymentStatusId
        dcTransferId
        email
        # amount
        notes
        paymentStatus {
          id
          code
          description
        }
      }
    }
  }
`;
export const findEtransferTransactionsQuery = gql`
  query findEtransferTransactions(
    $type: String
    $amount: Float
    $customerNumber: String
    $maxExpiryDate: DateTime
    $maxDate: DateTime
    $minDate: DateTime
    $transactionId: Int
    $minExpiryDate: DateTime
  ) {
    findEtransferTransactions(
      type: $type
      amount: $amount
      customerNumber: $customerNumber
      maxExpiryDate: $maxExpiryDate
      maxDate: $maxDate
      minDate: $minDate
      minExpiryDate: $minExpiryDate
      transactionId: $transactionId
    )
  }
`;
export const getDcAccountQuery = gql`
  query getDcAccount {
    getDcAccount {
      ClientPerTransactionLimitCredit
      ClientDailyAvailableOutgoingLimitCredit

      Id
      Balance
      AvailableBalance
      AccountName
      Type
      AccountStatus
    }
  }
`;
export const payrollSummaryQuery = gql`
  query payrollSummary {
    payrollSummary
  }
`;
export const paymentsOverviewQuery = gql`
  query getDcAccount {
    getDcAccount {
      ClientPerTransactionLimitCredit
      ClientDailyAvailableOutgoingLimitCredit

      Id
      Balance
      AvailableBalance
      AccountName
      Type
      AccountStatus
    }
  }
`;
export const findEtransferRequestForFundsQuery = gql`
  query findEtransferRequestForFunds {
    findEtransferRequestForFunds
  }
`;

export const listEtransferTransactionQuery = gql`
  query listEtransferTransaction($type: String) {
    listEtransferTransaction(type: $type) {
      CustomerNumber
      TransactionId
      #   ContactId
      ContactName
      Description
      #   TransactionTypeId
      #   TransactionTypeCode
      #   TransactionTypeDescription
      #   Amount
      #   PriorityTypeId
      #   PriorityTypeCode
      #   PriorityTypeDescription
      MoneyReqExpDate
      DateOfFunds
      #   SecurityQuestionId
      SecurityQuestion
      SecurityQuestionAnswer
      TransactionStatusDescription
      TransactionStatusCode
      #   TransactionStatusId
      #   InteracStatusId
      #   InteracStatusCode
      InteracStatusDescription
      #   InteracReferenceNumber
      #   ParticipantReferenceNumber
      #   TransactionReferenceNumber
      ErrorDescription
    }
  }
`;

export const findTransfersQuery = gql`
  query transfers(
    $status: Int,
    $dcTransferId: Int,
    $customerNumber: String,
    $before: String,
    $after: String,
    $first: Int,
    $last: Int,
    $like: [PayrollFilterInput!],
    $order: [PayrollOrderInput!]
    ) {
    transfers(
        status: $status,
        dcTransferId: $dcTransferId,
        customerNumber: $customerNumber,
        before: $before,
        after: $after,
        first: $first,
        last: $last,
        like: $like,
        order: $order
    )
  }
`;

export const findHolidayTransfersQuery = gql`
query holidayTransfers($before: String, $after: String, $first: Int, $last: Int, $like: [HolidayPayrollFilterInput!], $order: [HolidayPayrollOrderInput!]) {
    holidayTransfers(
      before: $before
      after: $after
      first: $first
      last: $last
      like: $like
      order: $order
    )
  }
`;
export const findHolidayReversalsQuery = gql`
query holidayPaymentReversal($before: String, $after: String, $first: Int, $last: Int, $like: [HolidayPayrollFilterInput!], $order: [HolidayPayrollOrderInput!]) {
    holidayPaymentReversal(
      before: $before
      after: $after
      first: $first
      last: $last
      like: $like
      order: $order
    )
  }
`;
export const findReversalsQuery = gql`
  query reversals(
    $status: Int
    $reversedDcTransferId: String
    $dcTransferId: String
    $customerNumber: String
  ) {
    reversals(
      status: $status
      reversedDcTransferId: $reversedDcTransferId
      dcTransferId: $dcTransferId
      customerNumber: $customerNumber
    )
  }
`;
export const uninitializeAllUnprocessedPayrollQuery = gql`
  query uninitializeAllUnprocessedPayroll {
    uninitializeAllUnprocessedPayroll
  }
`;
export const paymentAndBillingCountQuery = gql`
  query paymentAndBillingCount {
    paymentAndBillingCount
  }
`;
export default listEtransferTransactionQuery;
