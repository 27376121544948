import React, { useEffect, useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Collapse, Grid, IconButton, TextField, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";
import { TransitionGroup } from 'react-transition-group';
import { Close, Description, Visibility } from '@mui/icons-material';
import Dropzone from "components/Dropzone/Dropzone";
import { supportedAvatarFiles } from "utils/constant";
import { actions as MetaActions } from "store/modules/meta";
import { isValidFile } from "utils/file";

const style = (theme) => ({
    container: {
        maxWidth: 350,
        marginBottom: theme.spacing(2)
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: theme.spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
        "& .MuiAlert-action": {
            alignItems: "center",
            marginTop: 0,
            paddingTop: 0
        }
    },
    selectedDocument: {
        marginTop: theme.spacing(1.5),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: theme.spacing(3),
        justifyContent: "center",
        alignItems: "center"
    },
    uploadDocContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&>p": {
            marginTop: "20px !important",
            textAlign: "left"
        },
        "&>div": {
            marginTop: "0 !important",
            minHeight: 130,

            "& svg": {
                height: 50,
                width: 50,
            },
            "& p": {
                fontSize: 12,
                paddingRight: 8,
                paddingLeft: 8,
            }
        }
    }
})

function AddWorkTypeDialog({ classes }) {

    const dispatch = useDispatch();
    const [workTypeLabel, setWorkTypeLabel] = useState("");
    const [frenchName, setFrenchName] = useState("");
    const [icon, setIcon] = useState(undefined);
    const [iconPreview, setIconPreview] = useState("");
    const [bannerImage, setBannerImage] = useState(undefined);
    const [bannerImagePreview, setBannerImagePreview] = useState("");
    const [bannerImage2X, setBannerImage2X] = useState(undefined);
    const [bannerImage2XPreview, setBannerImage2XPreview] = useState("");
    const [bannerImage3X, setBannerImage3X] = useState(undefined);
    const [bannerImage3XPreview, setBannerImage3XPreview] = useState("");
    const isAddGearDialog = useSelector(workTypesSelectors.getIsAddWorkTypeDialog);
    const isLoading = useSelector(workTypesSelectors.getIsLoading);

    useEffect(() => {
        if (!isAddGearDialog) {
            setWorkTypeLabel("");
            setFrenchName("");
            setIcon(undefined);
            setIconPreview("");
            setBannerImage(undefined);
            setBannerImagePreview("");
            setBannerImage2X(undefined);
            setBannerImage2XPreview("");
            setBannerImage3X(undefined);
            setBannerImage3XPreview("");
        };
    }, [isAddGearDialog]);

    const hideAddWorkTypeDialog = () => {
        dispatch(workTypesActions.setIsAddWorkTypeDialog(false));
    }

    const handleWorkType = (event) => {
        setWorkTypeLabel(event.target.value);
    }

    const handleFrenchName = (event) => {
        setFrenchName(event.target.value);
    }

    const AddWorkType = () => {
        dispatch(workTypesActions.createWorkType({ frenchName, label: workTypeLabel, icon, bannerImage, bannerImage2x: bannerImage2X, bannerImage3x: bannerImage3X }));
    }

    const onUploadIconChange = file => {
        const res = file ? isValidFile(file, 0.09765625, "KB") : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Icon_${file.name}`;
                const url = URL.createObjectURL(file);
                setIconPreview(url)
            }
            setIcon(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImageChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImagePreview(url)
            }
            setBannerImage(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImage2XChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImage2XPreview(url)
            }
            setBannerImage2X(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImage3XChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImage3XPreview(url)
            }
            setBannerImage3X(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };

    return (
        <Dialog
            open={isAddGearDialog}
            title="Add Work Type"
            onClose={hideAddWorkTypeDialog}
            onCancel={hideAddWorkTypeDialog}
            onConfirm={AddWorkType}
            disabled={!workTypeLabel?.trim?.() || !frenchName?.trim?.() || isLoading}
            isLoading={isLoading}
            alertType="form"
            confirmText="Add"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="label"
                    margin="normal"
                    disabled={isLoading}
                    label="Label"
                    value={workTypeLabel}
                    onChange={handleWorkType}
                    required
                    fullWidth
                />
                <TextField
                    id="label"
                    margin="normal"
                    label="French Label"
                    value={frenchName}
                    disabled={isLoading}
                    onChange={handleFrenchName}
                    required
                    fullWidth
                />
                <div>
                    <TransitionGroup>
                        {icon ?
                            <Collapse key={icon?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Icon</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={iconPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {icon?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={iconPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadIconChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Icon</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Icon"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadIconChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage ?
                            <Collapse key={bannerImage?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImagePreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImagePreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImageChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImageChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage2X ?
                            <Collapse key={bannerImage2X?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image 2x</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImage2XPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage2X?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImage2XPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImage2XChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image 2x</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image 2x"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImage2XChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage3X ?
                            <Collapse key={bannerImage3X?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image 3x</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImage3XPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage3X?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImage3XPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImage3XChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image 3x</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image 3x"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImage3XChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                </div>
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(AddWorkTypeDialog))
