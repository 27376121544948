import { MenuItem, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch } from 'react-redux';
import { actions as Actions } from 'store/modules/employerDetails';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 250,
        width: 250,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        padding: 4,
        height: 36,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": {
            padding: '4px 15px 4px 8px !important',
            minWidth: 74,
            background: '#E9F1FB',
        },
        '& fieldset': {
            border: 'none'
        }
    }
}));

export default function SelectValidity({ rowIndex, requirement, requirementIndex }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = ({ target: { value } }) => {
        dispatch(Actions.handleChangeRequirement({
            requirementIndex,
            rowIndex,
            data: {
                validity: {
                    ...requirement.validity,
                    type: value,
                    value: null
                }
            }
        }));
    };

    const handleDaysChange = ({ target: { value } }) => {
        dispatch(Actions.handleChangeRequirement({
            requirementIndex,
            rowIndex,
            data: {
                validity: {
                    ...requirement.validity,
                    value
                }
            }
        }));
    };

    const handleDateChange = (selectedDate) => {
        dispatch(Actions.handleChangeRequirement({
            requirementIndex,
            rowIndex,
            data: {
                validity: {
                    ...requirement.validity,
                    value: selectedDate
                }
            }
        }));
    };

    return (
        <div className={classes.root}>
            <TextField
                onChange={handleChange}
                select
                value={requirement?.validity?.type}
            >
                <MenuItem value={'days'} >
                    Days
                </MenuItem>
                <MenuItem value={'date'}>
                    Date
                </MenuItem>
            </TextField>
            {requirement?.validity?.type === 'days' ? (
                <TextField
                    onChange={handleDaysChange}
                    value={requirement?.validity?.value || ''}
                    placeholder="Enter"
                    type="number"
                    margin="none"
                    inputProps={{
                        min: 1,
                    }}
                />
            ) : null}
            {requirement?.validity?.type === 'date' ? (
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <MobileDatePicker
                        disablePast
                        value={requirement?.validity?.value}
                        onChange={e => handleDateChange(e)}
                        renderInput={props => <TextField {...props} placeholder='Select Date' />}
                        inputFormat="DD/MM/YYYY" />
                </LocalizationProvider>
            ) : null}
        </div>
    )
}
