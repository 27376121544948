import React from "react";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import history from "utils/history";
import { useDispatch } from "react-redux";
import { actions as Actions } from "store/modules/additionalRequirements";


const useStyles = makeStyles(theme => ({
    action: {
        display: 'flex',
        gap: theme.spacing(0.5)
    },
    assignButton: {
        borderRadius: 30,
        fontWeight: 600,
        whiteSpace: 'nowrap'
    }
}));

function ColumnActionMenu({ id }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleRemove = () => {
        dispatch(Actions.setSelectedRecordForRemove(id));
    };

    return (
        <div className={classes.action}>
            {/* <IconButton onClick={() => history.push(`/additional-requirements/${window.btoa(id)}?type=1`)}>
                <Edit color="primary" />
            </IconButton> */}
            <Button size="small" onClick={handleRemove} variant="outlined" color="error" className={classes.assignButton}>
                Remove
            </Button>
        </div>
    );
}

export default ColumnActionMenu;
