import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { getAlternateDocDialog, getApproveDialog, getDocumentApproveDialog, getDocumentRejectDialog, getJobApplicationsData, getJobApplicationsPagingData, getRejectDialog, getRejectedAdditionalDocDialog, getRequirementSelection, getResubmissionDialog, getSelectedJob, getSelectedWorker, getSentToAgencyDialog } from "./selectors";
import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import { GetIntrestedWorkers, GetJobs, GetRequirementByWorkerApplicationId } from "./graphql/queries";
import { getRelation } from "utils/redux";
import { flattenGraphQLArray } from "utils/graphql";
import { BulkApproveJobApplicationRequirements, approveRejectRequirement } from "./graphql/mutations";
import { verificationsStatus } from "utils/constant";

export const setInitialState = () => ({
    type: types.SET_INITIAL_STATE,
});


const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

export const setIsLoading = setLoadingState("isLoading");
export const setIsWorkerPositionsLoading = setLoadingState("workerPositions");
export const setIsRequirementsLoading = setLoadingState("requirementDetail");
export const setIsBulkApproveLoading = setLoadingState("bulkApprove");
export const setIsApproveLoading = setLoadingState("approveRequirement");
export const setIsDocumentApproveLoading = setLoadingState("approveDocumentRequirement");
export const setIsDocumentRejectLoading = setLoadingState("rejectDocumentRequirement");
export const setIsRejectLoading = setLoadingState("rejectRequirement");
export const setIsSentAgencyLoading = setLoadingState("sentAgencyLoading");
export const setRejectedAdditionalDocLoading = setLoadingState("rejectedAdditionalDocLoading");
export const setRejectedAlternateLoading = setLoadingState("rejectedAlternateLoading");
export const setRejectedResubmissionLoading = setLoadingState("resubmissionLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setApproveAllDialog = setDialogState("approveAll");
export const setSentToAgencyDialog = setDialogState("sentToAgency");
export const setApproveDialog = setDialogState("approve");
export const setRejectDialog = setDialogState("reject");
export const setRejectedInfoDialog = setDialogState("rejectedInfo");
export const setDocumentRejectedInfoDialog = setDialogState("documentRejectedInfo");
export const setRejectedAdditionalDocDialog = setDialogState("rejectedAdditionalDoc");
export const setResubmissionDialog = setDialogState("resubmission");
export const setAlternateDocDialog = setDialogState("alternateDoc");
export const setDocumentApproveDialog = setDialogState("documentApprove");
export const setDocumentRejectDialog = setDialogState("documentReject");
export const setDocumentPreviewDialog = setDialogState("documentPreview");

export const setSelectedJob = selectedJob => ({
    type: types.SET_SELECTED_JOB,
    payload: { selectedJob },
});

export const setSelectedWorker = selectedWorker => ({
    type: types.SET_SELECTED_WORKER,
    payload: { selectedWorker },
});

export const setJobPositions = workers => ({
    type: types.SET_JOB_POSITIONS,
    payload: { jobPositions: workers },
});

export const setSelectedRequirements = requirements => ({
    type: types.SET_SELECTED_REQUIREMENTS,
    payload: { requirements },
});

export const setRequirementBulkSelection = (requirement, isClear = false) => ({
    type: types.SET_SELECT_REQUIREMENTS_FOR_BULK_ACTION,
    payload: { requirement, isClear },
});

export const setJobApplicationsData = (data, part) => ({ type: types.SET_JOB_APPLICATIONS_DATA, payload: { data, part } });

export const setJobApplicationFilter = filter => dispatch => {
    dispatch(setJobApplicationsData(filter, "filter"));
};

export const fetchJobsApplications = pageIndex => (dispatch, getState) => {
    const frames = {
        0: [verificationsStatus.Applied, verificationsStatus.SentToAgency, verificationsStatus.AlternateDocument, verificationsStatus.NewDocument],
        1: verificationsStatus.SentToAgency,
        2: verificationsStatus.ReSubmission,
        3: [verificationsStatus.Approved, verificationsStatus.PreApproved],
        4: verificationsStatus.Rejected,
    }
    const URLParams = new URLSearchParams(window.location.search);
    const activeTab = parseInt(URLParams.get("tab"), 10);
    const state = getState();
    const pageInfo = getJobApplicationsPagingData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.jobApplications)(pageInfo, pageIndex));
    const relations = {
        "Job": ['id', 'date'],
        "Job_Employer": ['companyName', 'Employer_Id'],
        "Job_Requirement": ["Requirement_Id"],
        "Worker": ["Worker_Id"],
    };
    const filterInfo = JSON.parse(JSON.stringify(getJobApplicationsData(state, { part: "filter" })));

    return ApolloClient.query({
        query: GetJobs,
        variables: {
            ...pagingVars,
            order: getJobApplicationsData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.reduce((p, filter) => {
                if (filter?.id === 'companyName') {
                    if (isNaN(filter?.value)) {
                        return ([
                            ...p,
                            {
                                field: filter?.id,
                                value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                                relation: getRelation(relations, filter?.id),
                            },
                        ]);
                    };
                    return ([
                        ...p,
                        {
                            field: 'id',
                            value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                            relation: getRelation(relations, 'id'),
                        },
                    ]);
                };
                if (filter?.id === "Employer_Id") {
                    return [...p, {
                        field: "id",
                        value: Array.isArray(filter?.value) ? filter?.value.map(a => a.value) : filter?.value,
                        relation: getRelation(relations, filter?.id),
                    }]
                };
                if (filter?.id === "Requirement_Id") {
                    return [...p, {
                        field: "id",
                        value: Array.isArray(filter?.value) ? filter?.value.map(a => a.value?.toString()) : filter?.value,
                        relation: getRelation(relations, filter?.id),
                    }]
                };
                if (filter?.id === "Worker_Id") {
                    return [...p, {
                        field: "id",
                        value: Array.isArray(filter?.value) ? filter?.value.map(a => a.value?.toString()) : filter?.value,
                        relation: getRelation(relations, filter?.id),
                    }]
                };
                if (filter?.id === "date") {
                    return [...p, {
                        field: filter.id,
                        value: filter?.value,
                        relation: getRelation(relations, filter?.id),
                    }]
                };
                return [...p, {
                    field: filter?.id,
                    value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                    relation: getRelation(relations, filter?.id),
                }];
            }, [{
                field: "applicationStatus",
                value: frames[activeTab],
                relation: "JobApplicationRequirement"
            },]),
        },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                dispatch(setJobApplicationsData(flattenGraphQLArray([]), "data"));
                throw Error(errors[0].message);
            } else {
                const { jobApplications } = data;
                const paginationData = {
                    ...jobApplications.pageInfo,
                    totalCount: jobApplications.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.jobApplications, paginationData),
                );
                dispatch(setJobApplicationsData(flattenGraphQLArray(jobApplications), "data"));
                dispatch(pagination.doneLoading(paginationSettings.jobApplications));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.jobApplications));
        });
};

export const getWorkersByJobId = (jobId, isLoading = true) => (dispatch, getState) => {
    isLoading && dispatch(setJobPositions([]));
    isLoading && dispatch(setIsWorkerPositionsLoading(true));
    return ApolloClient.query({
        query: GetIntrestedWorkers,
        variables: {
            jobId
        },
    }).then(({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        } else {
            dispatch(setJobPositions(data.getWorkersByJobId));
            isLoading && dispatch(setIsWorkerPositionsLoading(false));
        }
        return data;
    }).catch(e => {
        isLoading && dispatch(setIsWorkerPositionsLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const getRequirementsByWorkerApplicationId = (applicationId, isLoading = true) => (dispatch) => {
    isLoading && dispatch(setSelectedRequirements([]));
    isLoading && dispatch(setIsRequirementsLoading(true));
    return ApolloClient.query({
        query: GetRequirementByWorkerApplicationId,
        variables: {
            applicationId
        },
    }).then(({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        } else {
            dispatch(setSelectedRequirements(data.getRequirementsByApplicationId));
            isLoading && dispatch(setIsRequirementsLoading(false));
        }
        return data;
    }).catch(e => {
        isLoading && dispatch(setIsRequirementsLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const bulkApproveRequirements = () => (dispatch, getState) => {
    dispatch(setIsBulkApproveLoading(true));
    const selectedRequirements = getRequirementSelection(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: BulkApproveJobApplicationRequirements,
        variables: {
            data: {
                ids: selectedRequirements?.map(a => a.id),
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsBulkApproveLoading(false));
        dispatch(setApproveAllDialog(false));
        dispatch(setRequirementBulkSelection([], true));
        dispatch(MetaActions.successToast(`Selected requirements approved for the ${selectedWorker?.worker?.fullName} worker.`));
        return data;
    }).catch(e => {
        dispatch(setIsBulkApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const approveConsentRequirement = () => (dispatch, getState) => {
    dispatch(setIsApproveLoading(true));
    const selectedRequirement = getApproveDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsApproveLoading(false));
        dispatch(setApproveDialog(null));
        dispatch(MetaActions.successToast(`Requirement approved for the ${selectedWorker?.worker?.fullName} worker.`));
        return data;
    }).catch(e => {
        dispatch(setIsApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const rejectConsentRequirement = (rejectionReason) => (dispatch, getState) => {
    dispatch(setIsRejectLoading(true));
    const selectedRequirement = getRejectDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                rejectionReason
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsRejectLoading(false));
        dispatch(setRejectedInfoDialog(selectedRequirement));
        dispatch(setRejectDialog(null));
        // dispatch(MetaActions.successToast(`Requirement rejected for the ${selectedWorker?.worker?.fullName} worker.`));
        return data;
    }).catch(e => {
        dispatch(setIsRejectLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};


export const approveDocumentRequirement = () => (dispatch, getState) => {
    dispatch(setIsDocumentApproveLoading(true));
    const selectedRequirement = getDocumentApproveDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsDocumentApproveLoading(false));
        dispatch(setDocumentApproveDialog(null));
        dispatch(MetaActions.successToast(`Requirement approved for the ${selectedWorker?.worker?.fullName} worker.`));
        return data;
    }).catch(e => {
        dispatch(setIsDocumentApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const rejectDocumentRequirement = (rejectionReason) => (dispatch, getState) => {
    dispatch(setIsDocumentRejectLoading(true));
    const selectedRequirement = getDocumentRejectDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                rejectionReason
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsDocumentRejectLoading(false));
        dispatch(setDocumentRejectedInfoDialog(selectedRequirement));
        dispatch(setDocumentRejectDialog(null));
        return data;
    }).catch(e => {
        dispatch(setIsDocumentRejectLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};


export const sentToAgencyRequirement = () => (dispatch, getState) => {
    dispatch(setIsSentAgencyLoading(true));
    const selectedRequirement = getSentToAgencyDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                nextAction: "SentToAgency",
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setIsSentAgencyLoading(false));
        dispatch(setSentToAgencyDialog(null));
        dispatch(MetaActions.successToast(`${selectedWorker?.worker?.fullName} worker requirement sent to Agency.`));
        return data;
    }).catch(e => {
        dispatch(setIsSentAgencyLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForAdditionalDocForConsent = (rejectionReason, employerRequirementId) => (dispatch, getState) => {
    dispatch(setRejectedAdditionalDocLoading(true));
    const selectedRequirement = getRejectedAdditionalDocDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                nextAction: "AlternateDocument",
                rejectionReason,
                employerRequirementId
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setRejectedAdditionalDocLoading(false));
        dispatch(setRejectedAdditionalDocDialog(null));
        dispatch(MetaActions.successToast(`Sent notification ${selectedWorker?.worker?.fullName} worker for Alternate document.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedAdditionalDocLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForAlternateDoc = (rejectionReason, employerRequirementId) => (dispatch, getState) => {
    dispatch(setRejectedAlternateLoading(true));
    const selectedRequirement = getAlternateDocDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                nextAction: "AlternateDocument",
                rejectionReason,
                employerRequirementId
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setRejectedAlternateLoading(false));
        dispatch(setAlternateDocDialog(null));
        dispatch(MetaActions.successToast(`Sent notification ${selectedWorker?.worker?.fullName} worker for Alternate document.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedAlternateLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForResubmission = (rejectionReason) => (dispatch, getState) => {
    dispatch(setRejectedResubmissionLoading(true));
    const selectedRequirement = getResubmissionDialog(getState());
    const selectedWorker = getSelectedWorker(getState());
    const selectedJob = getSelectedJob(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.id,
                isApproved: false,
                nextAction: "ReSubmission",
                rejectionReason,
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await dispatch(getWorkersByJobId(selectedJob.id, false));
        await dispatch(getRequirementsByWorkerApplicationId(selectedWorker.id, false));
        dispatch(setRejectedResubmissionLoading(false));
        dispatch(setResubmissionDialog(null));
        dispatch(MetaActions.successToast(`Sent notification ${selectedWorker?.worker?.fullName} worker for Document Resubmission.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedResubmissionLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};
