import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Star from "@mui/icons-material/Star";

function WorkerStatsCard(props) {
  const {
    classes,
    rating,
    availability,
    positionCount,
    preferCount,
    noShowCount,
    banCount,
  } = props;

  const isSelected = (dayNum, timeOfDay) => {
    const isSelected = availability.some(a => a.dayOfWeek === dayNum && a.timeOfDay === timeOfDay);
    return isSelected;
  };

  return (
    <Grid item xs={3} className={classes.container}>
      <Grid container item xs={12} className={classes.card}>
        {/* <Grid container item xs={12} direction="column" alignItems="flex-end">
          <Typography variant="caption" gutterBottom>
            Rating
          </Typography>
          <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
            <Typography variant="h4" gutterBottom>
              {rating ? rating : <i>Not Rated</i>}
            </Typography>
            <Star className={classes.star} />
          </Grid>
        </Grid> */}
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="caption">Availability</Typography>
          </Grid>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>S</th>
                <th>M</th>
                <th>T</th>
                <th>W</th>
                <th>T</th>
                <th>F</th>
                <th>S</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>12am</th>
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(1, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(2, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(3, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(4, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(5, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(6, "Early"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(7, "Early"),
                  })}
                />
              </tr>
              <tr>
                <th>6am</th>
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(1, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(2, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(3, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(4, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(5, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(6, "Morning"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(7, "Morning"),
                  })}
                />
              </tr>
              <tr>
                <th>12pm</th>
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(1, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(2, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(3, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(4, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(5, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(6, "Afternoon"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(7, "Afternoon"),
                  })}
                />
              </tr>
              <tr>
                <th>6pm</th>
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(1, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(2, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(3, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(4, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(5, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(6, "Evening"),
                  })}
                />
                <td
                  className={classNames(classes.availabilityContainer, {
                    [classes.availableCell]: isSelected(7, "Evening"),
                  })}
                />
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end">
            <Typography variant="caption">Jobs Worked</Typography>
            <Typography variant="h6">{positionCount}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end">
            <Typography variant="caption">Preferred by Employers</Typography>
            <Typography variant="h6">{preferCount}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end">
            <Typography variant="caption">Times No Showed</Typography>
            <Typography variant="h6">{noShowCount}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end">
            <Typography variant="caption">Banned by Employers</Typography>
            <Typography variant="h6">{banCount}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

WorkerStatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  rating: PropTypes.number,
  availability: PropTypes.array,
  positionCount: PropTypes.number,
  preferCount: PropTypes.number,
  noShowCount: PropTypes.number,
  banCount: PropTypes.number,
};

WorkerStatsCard.defaultProps = {
  rating: 0,
  availability: [],
  positionCount: 0,
  preferCount: 0,
  noShowCount: 0,
  banCount: 0,
};

export default withStyles(({ palette, spacing, shape }) => ({
  container: {
    paddingRight: spacing(2),
  },
  card: {
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius,
    padding: `${spacing(3)} !important`,
    height: "100%",
  },
  star: {
    color: palette.text.primary,
  },
  availabilityContainer: {
    backgroundColor: palette.grey[500],
    width: 25,
    height: 25,
  },
  availableCell: {
    backgroundColor: palette.primary.main,
  },
}))(WorkerStatsCard);
