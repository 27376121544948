import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { financeLandingStyles } from "views/Finance/helpers";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import { actions as FinanceActions, selectors as FinanceSelectors } from "../../store/modules/finance";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { formatMoney, formatNumberWithComa } from "utils/stringUtils";

function FinanceView() {
  const classes = financeLandingStyles();
  const history = useHistory();
    const dispatch = useDispatch();
  const navigateToPayments = () => history.push(`/finance/payments`);
  const navigateToBilling = () => history.push(`/finance/billing`);
    const paymentAndBillingCount = useSelector(FinanceSelectors.getPaymentAndBillingCount)
    useEffect(() => {
        dispatch(FinanceActions.fetchPaymentAndBillingCount())
    },[dispatch])
  return (
    <MainContent>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container className={classes.sectionContainer} direction="column">
            <Grid container>
              <Typography variant="h4">{"Payments"}</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ flex: 1 }}
              alignItems="center"
              justifyContent="center"
              direction="column">
              <Typography variant="caption" color="textSecondary">
                {"Queued Payments"}
              </Typography>
              <Typography variant="h1">{formatNumberWithComa(paymentAndBillingCount?.paymentQueuedCount ? paymentAndBillingCount?.paymentQueuedCount : 0)}</Typography>

              <Button
                icon={<ChevronRight />}
                color="transparent"
                label="View Payments"
                onClick={navigateToPayments}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container className={classes.sectionContainer} direction="column">
            <Grid container justifyContent="space-between">
              <Typography variant="h4">{"Billing"}</Typography>
              <Chip label={"This week's invoices are ready"} className={classes.chip} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ flex: 1 }}
              alignItems="center"
              justifyContent="center"
              direction="column">
                    <Typography variant="caption" color="textSecondary">
                        {"YTD Earnings"}
                    </Typography>
                <Tooltip title={`(Total working hours - break) * 24.95`} placement="top">
                    <Typography variant="h1">{formatMoney(paymentAndBillingCount?.billingTotal ? paymentAndBillingCount?.billingTotal : 0)}</Typography>
                </Tooltip>
            </Grid>
            <Grid container justifyContent="center">
              <Button
                icon={<ChevronRight />}
                color="transparent"
                label="View Billing"
                onClick={navigateToBilling}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContent>
  );
}

export default FinanceView;
