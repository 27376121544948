export const paginationSettings = {
  preferredWorkers: {
    prefix: "pagination_PreferredWorkers",
    defaultPageSize: 5,
  },
  bannedWorkers: {
    prefix: "pagination_BannedWorkers",
    defaultPageSize: 5,
  },
  employerJobs: {
    prefix: "pagination_EmployerJobs",
    defaultPageSize: 5,
  },
  workerPicker: {
    prefix: "pagination_WorkerPicker",
    defaultPageSize: 5,
  },
  requirementList: {
    prefix: "pagination_RequirementList",
    defaultPageSize: 5,
  },
};
