import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ProvinceWiseTaxes, employerFinanceCardStyles } from "views/Employer/helpers";
import Line from "components/Placeholders/Line";
import { formatMoney } from "utils/stringUtils";
import { History } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

function EmployerFinanceCard(props) {
    const { classes, totalHoursWorked, billingRate, payRate, showBillingRateHistory, employer } = props;
    const totalBilled = totalHoursWorked * billingRate;
    const taxes = ProvinceWiseTaxes?.[employer?.region] || [{ label: "GST", percentage: 5 }];

    return (
        <Grid item xs={6} style={{ paddingLeft: 8 }}>
            <Grid container item xs={12} className={classes.card} justifyContent="space-between">
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Total Hours
                    </Typography>
                    <Typography variant="h6">{totalHoursWorked}</Typography>
                </Grid>
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Billable Rate
                    </Typography>
                    <Typography variant="h6">
                        <Tooltip title={`Billing Rate History`} placement="top">
                            <History onClick={showBillingRateHistory} className={classes.historyIcon} />
                        </Tooltip>
                        {formatMoney(billingRate)}
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Pay Rate
                    </Typography>
                    <Typography variant="h6">{formatMoney(payRate)}</Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    alignItems="flex-end"
                    className={classes.netPayContainer}>
                    <Line className={classes.totalLine} />
                    <Typography variant="caption" gutterBottom>
                        Total Billed
                    </Typography>
                    <Typography variant="h4">{formatMoney(totalBilled)}</Typography>
                    {taxes?.map?.(tax => <Typography mb={0.4} variant="caption" key={tax.label}>{`+ ${tax.label} @ ${tax.percentage}%`}</Typography>)}
                    <Typography variant="caption" color="textSecondary" mt={0.4}>
                        * excl. overtime and timebase premium
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

EmployerFinanceCard.propTypes = {
    classes: PropTypes.object.isRequired,
    totalHoursWorked: PropTypes.number,
    billingRate: PropTypes.number,
    payRate: PropTypes.number,
};

EmployerFinanceCard.defaultProps = {
    rating: 0,
    totalHoursWorked: 0,
    billingRate: 0,
    payRate: 0,
};

export default employerFinanceCardStyles(EmployerFinanceCard);
