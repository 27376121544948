import { Search } from '@mui/icons-material';
import { ButtonBase, Chip, Skeleton, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PerfectScrollWrapper from 'components/Sidebar/PerfectScrollWrapper';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        width: '100% !important',
        height: ({ filters }) => `calc(100vh - ${filters?.length ? 245 : 220}px) !important`,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: 4,
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 4,
            backgroundColor: '#c5ffffba'
        },
        '&::-webkit-scrollbar-track:hover': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-track:active': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            backgroundColor: '#91b4b3',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#009E96'
        },
        '&::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#009E96'
        },
    },
    header: {
        padding: 32,
        paddingRight: 16,
        marginRight: 16,
        paddingBottom: 8,
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 1,
    },
    cardContainer: {
        padding: '32px',
        paddingTop: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    buttonBase: {
        width: '100%',
        '&:focus': {
            '& $card': {
                // borderColor: "black"
            }
        }
    },
    arrow: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '&::before': {
            content: '""',
            width: 18,
            height: 15,
            transform: 'rotate(45deg)',
            background: '#009E96',
            right: -8,
            position: 'absolute',
            borderLeft: '1px solid #009E96',
            borderTop: '1px solid #009E96',
        },
    },
    card: {
        padding: theme.spacing(1.5),
        flex: 1,
        borderRadius: 5,
        border: `1px solid #0000001F`,
        cursor: 'pointer',
        '&:hover': {
            background: '#F3FFFF',
            border: `1px solid #009E96`,
        },
        transition: '0.5s all',
        maxWidth: '100%',

    },
    selectedCard: {
        border: `1px solid #009E96`,
        background: '#F3FFFF',
        position: 'relative',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,

    },
    name: {
        fontWeight: 400,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        WebkitLineClamp: 1,
        whiteSpace: 'nowrap',
        lineClamp: 1,
    },
    date: {
        fontWeight: 400,
        fontSize: 12,
        color: '#666666'
    },
    subTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 4,
        '& p': {
            color: '#666666',
            fontSize: 12,
            fontWeight: 400,
        },
    },
    loading: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chip: {
        marginRight: -5
    },
    'Rejected': {
        color: '#E95454',
        background: '#FFE3E3',
        fontSize: 9,
        fontWeight: 500
    },
    'Expired': {
        color: '#E95454',
        background: '#FFE3E3',
        fontSize: 9,
        fontWeight: 500
    },
    'Sent_to_Agency': {
        color: '#BC8908',
        background: '#FFEDBF',
        fontSize: 9,
        fontWeight: 500
    },
    'Approved': {
        color: '#009E96',
        background: '#DAF6F6',
        fontSize: 9,
        fontWeight: 500
    },
    'Re-submission': {
        color: '#BC8908',
        background: '#FFEDBF',
        fontSize: 9,
        fontWeight: 500
    },
    'Alternate_Document': {
        color: '#BC8908',
        background: '#FFEDBF',
        fontSize: 9,
        fontWeight: 500
    },
    'New_Document': {
        color: '#2175D9',
        background: '#E3EFFF',
        fontSize: 9,
        fontWeight: 500
    },
    search: {
        "& input": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            WebkitLineClamp: 1,
            whiteSpace: 'nowrap',
            lineClamp: 1,
        }
    }
}));

export default function AppliedWorkers() {
    const pageFilter = useSelector((state) => Selectors.getJobApplicationsData(state, { part: "filter" }));
    const employerFilters = pageFilter?.find(a => a?.id === "Employer_Id");
    const requirementFilters = pageFilter?.find(a => a?.id === "Requirement_Id");
    const workerFilters = pageFilter?.find(a => a?.id === "Worker_Id");
    const flatFilters = [...(employerFilters?.value || []), ...(requirementFilters?.value || []), ...(workerFilters?.value || [])];
    const classes = useStyles({ filters: flatFilters });
    const isLoading = useSelector(Selectors.getIsWorkerPositionsLoading)
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const selectedJob = useSelector(Selectors.getSelectedJob);
    const workers = useSelector(Selectors.getSelectedJobPositions);
    const dispatch = useDispatch();
    const [value, setValue] = useState();

    useEffect(() => {
        if (selectedJob && selectedJob?.id) {
            dispatch(Actions.setSelectedWorker(null));
            dispatch(Actions.setSelectedRequirements([]));
            dispatch(Actions.getWorkersByJobId(selectedJob.id));
        }
    }, [selectedJob?.id]);

    const handleChangeWorker = (worker) => {
        dispatch(Actions.setSelectedWorker(worker));
    };

    const filterdWorkers = workers?.filter(a => {
        // Advance Filter Logic Enable if need
        // if (workerFilters?.value?.length) {
        //     return workerFilters?.value?.find(b => {
        //         return b?.label?.toLowerCase()?.includes(a?.worker?.fullName?.toLowerCase()) && (!value?.trim() || a?.worker?.fullName?.toLowerCase()?.includes(value?.trim()?.toLowerCase()));
        //     });
        // }
        return !value?.trim() || a?.worker?.fullName?.toLowerCase()?.includes(value?.trim()?.toLowerCase());
    });

    return (
        <PerfectScrollWrapper id={'Workers-Section'} className={classes.root}>
            <div className={classes.header}>
                <Typography fontWeight={700}>
                    Interested Workers
                </Typography>
                <TextField
                    className={classes.search}
                    value={value}
                    onChange={({ target: { value: val } }) => setValue(val)}
                    style={{ width: '100%', marginTop: 12 }}
                    placeholder="Search via worker name"
                    InputProps={{
                        startAdornment: <Search />
                    }} />
            </div>
            <div className={classes.cardContainer}>
                {filterdWorkers?.map(({ worker, id, updatedAt, applicationStatus, totalCount, approvedCount } = {}) => (
                    <ButtonBase style={{ borderRadius: 5 }} LinkComponent={'div'} key={`${id} - ${worker?.id}`} className={classNames(classes.buttonBase, id === selectedWorker?.id && classes.arrow)} onClick={() => handleChangeWorker({ id, worker })}>
                        <div className={classNames(classes.card, id === selectedWorker?.id && classes.selectedCard)}>
                            <div className={classes.flexBetween}>
                                <Typography className={classes.name}>{worker?.fullName}</Typography>
                                <Typography fontSize={10} whiteSpace={'nowrap'}>{`${(approvedCount || 0)}/${(totalCount || 0)} Approved`}</Typography>
                            </div>
                            <div className={classes.subTitle}>
                                <Typography className={classes.date}>Updated on: {moment(updatedAt).format("DD MMM 'YY")}</Typography>
                                <Chip className={classNames(classes.chip, classes[applicationStatus?.replaceAll(' ', '_')])} size="small" label={applicationStatus === "Re-submission" ? `Asked ${applicationStatus}` : applicationStatus || 'New'} />
                            </div>
                        </div>
                    </ButtonBase>
                ))}
                {(!isLoading && (!workers || !workers?.length || !filterdWorkers || !filterdWorkers?.length)) && <div className={classes.loading}>
                    <Typography>No Workers Found</Typography>
                </div>}
                {(isLoading) && (
                    [...Array(workers?.length ? 1 : 5)].map((a, i) => (
                        <ButtonBase key={`${a} - ${i}`} disabled className={classes.buttonBase}>
                            <div className={classes.card}>
                                <div className={classes.flexBetween}>
                                    <Typography className={classes.jobName} style={{ width: '65%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                    <Typography className={classes.jobDate} style={{ width: '25%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                </div>
                                <div className={classes.flexBetween}>
                                    <Typography className={classes.jobName} style={{ width: '65%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                    <Typography className={classes.jobDate} style={{ width: '15%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                </div>
                            </div>
                        </ButtonBase>
                    ))
                )}
            </div>
        </PerfectScrollWrapper>
    )
}
