// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.provinceWisePay.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsFormActionLoading = getLoadingStateFactory("formDialogLoading");
export const getIsDeleteLoading = getLoadingStateFactory("deleteLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.provinceWisePay.dialog[loader] || false;
};

export const getIsAddProvinceWisePayDialog = getDialogStateFactory("addProvinceWisePayDialog");
export const getIsDeleteProvinceWisePayDialog = getDialogStateFactory("deleteProvinceWisePayDialog");
export const getIsEditProvinceWisePayDialog = getDialogStateFactory("editProvinceWisePayDialog");

// Documents selectors
export const getProvinceWisePayList = state => {
    return state.provinceWisePay.data;
};

export const getProvinceWisePayForm = (state, part) => {
    return part ? state.provinceWisePay?.form[part] : state.provinceWisePay.form;
};

export const canAddProvinceWisePay = (state) => {
    return (
        state.provinceWisePay?.form?.name?.length &&
        state.provinceWisePay?.form?.name?.length <= 50 &&
        state.provinceWisePay?.form?.helpText?.length &&
        state.provinceWisePay?.form?.helpText?.length <= 50 &&
        state.provinceWisePay?.form?.province &&
        state.provinceWisePay?.form?.document
    );
}
