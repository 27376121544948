import gql from "graphql-tag";

export default gql`
mutation UpdateupdateBreakRule($data: UpdateBreakRuleInput!) {
    updateBreakRule(data: $data) {
        id
        provinceId
        minShiftLength
        maxShiftLength
        minBreakMinutes
        breakType
        isActive
    }
}
`
