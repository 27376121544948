import { Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch } from 'react-redux';
import { actions as Actions } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 100,
        maxWidth: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

export default function MandatoryField(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleChange = ({ target: { checked } }) => {
        dispatch(Actions.updateAssignedEmployerFormData(props?.row?.index, 'isMandatory', checked));
    };


    return (
        <div className={classes.root}>
            <span style={{ fontWeight: !props?.row?.original?.isMandatory ? 700 : 400 }}>
                No
            </span>
            <Switch checked={props?.row?.original?.isMandatory} onChange={handleChange} inputProps={{ 'aria-label': 'Switch Mandatory' }} />
            <span style={{ fontWeight: props?.row?.original?.isMandatory ? 700 : 400 }}>
                Yes
            </span>
        </div>
    )
}
