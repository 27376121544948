import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { GetProvinceMinimumPays } from "./graphql/queries/index";
import CreateProvinceMinimumPay from "./graphql/mutations/create-province-minimum-pay";
import DeleteProvinceMinimumPay from "./graphql/mutations/delete-province-minimum-pay";
import UpdateProvinceMinimumPay from "./graphql/mutations/update-province-minimum-pay";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");
const setIsFormActionLoading = setLoadingState("formDialogLoading");
const setIsDeleteLoading = setLoadingState("deleteLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});


export const setIsAddProvinceWisePayDialog = setDialogState("addProvinceWisePayDialog");
export const setIsDeleteProvinceWisePayDialog = setDialogState("deleteProvinceWisePayDialog");
export const setIsEditProvinceWisePayDialog = setDialogState("editProvinceWisePayDialog");

const setProvinceWisePayForm = key => value => ({
    type: types.UPDATE_PROVINCE_WISE_PAY_FORM,
    payload: { key, value },
});

export const setProvinceWisePayFormData = (key, value) => ({
    type: types.UPDATE_PROVINCE_WISE_PAY_FORM,
    payload: { key, value },
});
export const setProvinceWisePayId = setProvinceWisePayForm("id");
export const setProvinceWiseIsActive = setProvinceWisePayForm("isActive");
export const setFormMinimumPayHours = setProvinceWisePayForm("minimumPayHours");
export const setFormMinimumPayMinutes = setProvinceWisePayForm("minimumPayMinutes");
export const setProvinceWiseEffectiveDateFrom = setProvinceWisePayForm("effectiveDateFrom");
export const setProvinceWiseEffectiveDateTo = setProvinceWisePayForm("effectiveDateTo");
export const setProvinceWisePayMinShiftLengthHours = setProvinceWisePayForm("minShiftLengthHours");
export const setProvinceWisePayMaxShiftLengthHours = setProvinceWisePayForm("maxShiftLengthHours");
export const setProvinceWisePayMinShiftLengthMinutes = setProvinceWisePayForm("minShiftLengthMinutes");
export const setProvinceWisePayMaxShiftLengthMinutes = setProvinceWisePayForm("maxShiftLengthMinutes");
export const setFormProvinces = setProvinceWisePayForm("province");

export const resetForm = () => ({
    type: types.RESET_FORM,
    payload: {}
});

const setProvinceWisePay = data => ({
    type: types.SET_PROVINCE_WISE_PAY_DATA,
    payload: { data }
});

export const setEditData = (data) => (dispatch) => {
    dispatch(setIsEditProvinceWisePayDialog(true));
    dispatch(setProvinceWisePayId(data.id));
    dispatch(setProvinceWiseIsActive(data.isActive));
    dispatch(setFormMinimumPayHours(data.minimumPay / 60 | 0));
    dispatch(setFormMinimumPayMinutes(data.minimumPay % 60 | 0));
    dispatch(setProvinceWiseEffectiveDateFrom(data.effectiveDateFrom));
    dispatch(setProvinceWiseEffectiveDateTo(data.effectiveDateTo));
    dispatch(setProvinceWisePayMinShiftLengthHours(data.minShiftLength / 60 | 0));
    dispatch(setProvinceWisePayMinShiftLengthMinutes(data.minShiftLength % 60 | 0));
    dispatch(setProvinceWisePayMaxShiftLengthHours(data.maxShiftLength / 60 | 0));
    dispatch(setProvinceWisePayMaxShiftLengthMinutes(data.maxShiftLength % 60 | 0));
    dispatch(setFormProvinces({ value: data?.province?.id, label: data?.province?.name, data: data?.province }));
};

export const fetchProvinceWisePay = () => (dispatch) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: GetProvinceMinimumPays,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setProvinceWisePay(data.provinceMinimumPays));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const addProvinceWisePay = (formData) => (dispatch) => {
    const query = formData.id ? UpdateProvinceMinimumPay : CreateProvinceMinimumPay;

    const data = {
        ...(formData.id ? { id: formData.id } : {}),
        provinceId: formData.province?.data?.id,
        isActive: formData.isActive,
        minShiftLength: ((+formData.minShiftLengthHours * 60) + +formData.minShiftLengthMinutes),
        maxShiftLength: ((+formData.maxShiftLengthHours * 60) + +formData.maxShiftLengthMinutes),
        minimumPay: ((+formData.minimumPayHours * 60) + +formData.minimumPayMinutes),
        effectiveDateFrom: formData.effectiveDateFrom,
        effectiveDateTo: formData.effectiveDateTo,
    };

    dispatch(setIsFormActionLoading(true));
    return ApolloClient.mutate({
        mutation: query,
        variables: { data },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchProvinceWisePay());
            dispatch(setIsAddProvinceWisePayDialog(false));
            dispatch(setIsEditProvinceWisePayDialog(false));
            dispatch(setIsFormActionLoading(false));
            dispatch(resetForm());
            if (formData.id)
                dispatch(MetaActions.successToast(`Province wise pay rule updated successfully`));
            else
                dispatch(MetaActions.successToast("Province wise pay rule has been created."));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsFormActionLoading(false));
        });
};


export const deleteProvinceWisePay = (id) => (dispatch) => {
    dispatch(setIsDeleteLoading(true))
    return ApolloClient.mutate({
        mutation: DeleteProvinceMinimumPay,
        variables: { id: parseInt(id) }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteLoading(false))
                dispatch(setIsDeleteProvinceWisePayDialog(false))
                dispatch(fetchProvinceWisePay());
                dispatch(MetaActions.successToast("Document has been deleted."));
            }
        })
        .catch(e => {
            // dispatch(setIsDeleteProvinceWisePayDialog(false))
            dispatch(setIsDeleteLoading(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};
