import _moment from "moment";
import ApolloClient from "utils/apollo";
import { convertLocalToUTC, getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import CreateJobMutation from "./graphql/mutations/create-job";
import EditJobMutation from "./graphql/mutations/edit-job";
import FetchEditableJobQuery from "./graphql/queries/fetch-editable-job";
import FetchPrefillEmployerQuery from "./graphql/queries/fetch-prefill-employer";
import FetchWorkTypesQuery from "./graphql/queries/fetch-work-types";
import FetchManagerQuery from "./graphql/queries/fetch-manager";
// import moment from "moment-timezone";
import types from "./types";
import moment from "moment";
import { isEmpty } from "lodash";
import * as tzlookup from 'tz-lookup';
import GetJobBillQuery from "./graphql/queries/get-employer-bill";
import FindBreakRuleByShiftAndRegion from "./graphql/queries/province-wise-break-rules";
import FetchMultiShiftJobPositionQuery from "../jobDetails/graphql/queries/fetch-multi-shift-positions";

let timeId;
export const setIsLoading = isLoading => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const resetForm = () => ({
  type: types.RESET,
});

export const resetFormFields = () => ({
  type: types.RESET_FORM,
});

export const setEmployer = (employer, isEditing = false) => ({
  type: types.SET_EMPLOYER,
  payload: { employer: employer ? employer.data : null, isEditing },
});

export const setDate = date => ({
  type: types.SET_DATE,
  payload: { date },
});

export const setStartTime = time => ({
  type: types.SET_START_TIME,
  payload: { time },
});

export const setEndTime = time => ({
  type: types.SET_END_TIME,
  payload: { time },
});

export const setWorkersNeeded = workersNeeded => ({
  type: types.SET_WORKERS_NEEDED,
  payload: { workersNeeded },
});

export const setBreakType = breakType => ({
  type: types.SET_BREAK_TYPE,
  payload: { breakType },
});

export const setBreakMins = breakMins => ({
  type: types.SET_BREAK_MINS,
  payload: { breakMins },
});

export const setJobType = jobType => ({
  type: types.SET_JOB_TYPE,
  payload: { jobType },
});

export const setLocation = (selectedLocation, location) => ({
  type: types.SET_LOCATION,
  payload: { selectedLocation, location },
});

export const setTimeZone = timezone => ({
  type: types.SET_TIMEZONE,
  payload: { timezone },
});

export const setUnit = unit => ({
  type: types.SET_UNIT,
  payload: { unit },
});

export const setFloor = floor => ({
  type: types.SET_FLOOR,
  payload: { floor },
});

export const setLocationInstruction = locationInstruction => ({
  type: types.SET_LOCATION_INSTRUCTION,
  payload: { locationInstruction },
});

export const setSkills = skills => ({
  type: types.SET_SKILLS,
  payload: { skills },
});

export const setManager = managers => ({
  type: types.SET_MANAGER_LIST,
  payload: { managers },
});

export const setSiteManager = siteManager => ({
  type: types.SET_SITE_MANAGER,
  payload: { siteManager },
});

export const setIsRecurring = (isRecurring) => ({
  type: types.SET_JOB_IS_RECURRING,
  payload: { isRecurring },
});

export const setIsFixedTermJob = (isFixedTerm) => ({
  type: types.SET_JOB_IS_FIXED_TERM,
  payload: { isFixedTerm },
});

export const setCheckInManager = checkInManager => ({
  type: types.SET_CHECK_IN_MANAGER,
  payload: { checkInManager },
});

export const setCheckOutManager = checkOutManager => ({
  type: types.SET_CHECK_OUT_MANAGER,
  payload: { checkOutManager },
});

export const setDescription = description => ({
  type: types.SET_DESCRIPTION,
  payload: { description },
});

export const setJobTypeList = jobTypeList => ({
  type: types.SET_JOB_TYPE_LIST,
  payload: { jobTypeList },
});

export const setIsPrivate = (isPrivate) => ({
  type: types.SET_JOB_IS_PRIVATE,
  payload: { isPrivate },
});

export const setGears = (gears) => ({
  type: types.SET_GEARS,
  payload: { gears },
});

export const setJobTags = (tags) => ({
  type: types.SET_JOB_TAGS,
  payload: { tags },
});

export const setAnnouncement = announcement => ({
  type: types.SET_JOB_ANNOUNCEMENT,
  payload: { announcement },
});

export const setRecurringType = recurringType => ({
  type: types.SET_JOB_RECURRING_TYPE,
  payload: { recurringType },
});

export const setRecurringRepeatEvery = repeatEvery => ({
  type: types.SET_JOB_RECURRING_REPEAT,
  payload: { repeatEvery },
});

export const setRecurringEndDate = recurringEndDate => ({
  type: types.SET_JOB_RECURRING_END_DATE,
  payload: { recurringEndDate },
});

export const setRecurringWeekDays = weekDays => ({
  type: types.SET_JOB_RECURRING_WEEK_DAYS,
  payload: { weekDays },
});

export const setRecurringMonthDate = monthDate => ({
  type: types.SET_JOB_RECURRING_MONTH_DATE,
  payload: { monthDate },
});

export const postJob = (id, editType, isIncludeWorker = false) => (dispatch, getState) => {
  dispatch(setIsLoading(true));
  const {
    employer,
    date,
    startTime,
    jobType,
    workersNeeded,
    location,
    breakType,
    breakMins,
    unit,
    floor,
    locationInstruction,
    gears,
    skills,
    description,
    duration,
    positions,
    requiresBreak,
    isPrivate,
    isRecurring,
    tags,
    recurringType,
    recurringEndDate,
    repeatEvery,
    weekDays,
    monthDate,
    siteManager,
    checkInManager,
    checkOutManager,
    announcement,
    isFixedTerm
  } = getState().posting;

  const { street, city, region, lat, lng, country, postalCode } = location;

  const start = _moment({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: startTime.hour(),
    minute: startTime.minute(),
  });

  const end = start.clone().add({
    hours: duration.hours,
    minutes: duration.minutes,
  });

  const timezone = tzlookup(lat, lng);
  const startDate = moment.tz(moment(start).format("YYYY-MM-DD HH:mm:ss"), timezone);
  const endDate = moment.tz(moment(end).format("YYYY-MM-DD HH:mm:ss"), timezone);

  const data = {
    employerId: employer.id,
    // start: _moment(start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    // end: _moment(end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    startUtc: convertLocalToUTC(startDate),
    endUtc: convertLocalToUTC(endDate),
    timezone: getTimeZone(),
    workTypeId: jobType,
    peopleNeeded: workersNeeded || 1,
    street,
    city: city || "",
    region,
    latitude: lat,
    longitude: lng,
    isBreakPaid: breakType === 0 ? false : true,
    breakMins: requiresBreak ? Number(breakMins) : 0,
    country,
    locationInstruction: locationInstruction || "",
    isRecurring,
    jobManager: [
      ...(!isEmpty(siteManager) ? [{ role: 'SiteManager', managerId: siteManager.id }] : []),
      ...(!isEmpty(checkInManager) ? [{ role: 'CheckInManager', managerId: checkInManager.id }] : []),
      ...(!isEmpty(checkOutManager) ? [{ role: 'CheckOutManager', managerId: checkOutManager.id }] : []),
    ],
    isPrivate,
    isFixedTerm,
    ...(isPrivate ? { tags } : {}),
    postalCode
  };

  // Check if employer eligible to post private job or not
  if (employer.canPostPrivateJob) {
    data.isPrivate = isPrivate;
    if (isPrivate) {
      data.tags = tags
    } else {
      data.tags = []
    }
  } else {
    data.isPrivate = false;
    data.tags = []
  }

  data.gears = gears?.map?.(a => a?.id?.toString()) || gears;
  data.skills = skills?.map?.(a => a?.id?.toString()) || skills;

  if (unit) {
    data.unit = unit;
  }

  if (floor) {
    data.floor = floor;
  }

  if (locationInstruction) {
    data.locationInstruction = locationInstruction;
  }

  if (description) {
    data.description = description;
  }

  if (announcement.length > 0 && announcement[0].title) {
    data.jobAnnouncement = announcement.map(n => ({
      title: n.title,
      description: n.description,
      frequency: n.repeatType,
      dayOrWeekDay: n.days.map(m => m.value),
      sendAt: n.time ? moment(n.time).format('HH:mm') + ':00' : '',
      beforeAfter: 'Before',
      minutes: n.before,
      workers: []
    }))
  }

  if (positions.length > 0 && editType === "duplicate" && isIncludeWorker) {
    data.requestWorkers = positions.map(({ worker: { id } }) => id);
  }

  if (isRecurring === true) {
    data.jobRecurring = {
      frequency: recurringType,
      frequencyEndAt: moment(recurringEndDate).format("YYYY-MM-DDT00:00:00")+".000Z",
      dayOrWeekDay: recurringType === 'Weekly' ? weekDays.map(n => n.value) : monthDate?.sort((a,b) => a-b ),
      frequencyInterval: repeatEvery || 1
    }
  }

  let mutation = CreateJobMutation;
  if (id && editType === "edit") {
    data.id = id;
    delete data.isRecurring;
    delete data.jobAnnouncement;
    delete data.jobRecurring;
    delete data.isFixedTerm;
    delete data.postalCode;
    mutation = EditJobMutation;
  }

  return ApolloClient.mutate({
    mutation,
    variables: { data },
  })
    .then(({ data, errors }) => {
      if(errors && errors.length){
            dispatch(MetaActions.errorToast("Error : " + errors[0].message));
            dispatch(setIsLoading(false));
            return;
      }
      dispatch(resetForm());
      let message = "Job created! Enable the job to search for workers.";
      if (editType === "edit") {
        message = "Update successful! Enable the job to search for workers.";
      }
      dispatch(MetaActions.successToast(message));
      dispatch(setIsLoading(false));
      return data.job;
    })
    .catch(e => {
      dispatch(setIsLoading(false));
      dispatch(
        MetaActions.errorToast(
          `Could not ${editType === "edit" ? "update" : "post"} job. (${e.message})`
        )
      );
      throw e;
    });
};

export const fetchEditableJob = (jobId, editType) => (dispatch, getState) => {
  dispatch(setIsLoading(true));
  return ApolloClient.query({
    query: FetchEditableJobQuery,
    variables: {
      id: jobId,
    },
  })
    .then(async ({ data }) => {
      if(editType === 'duplicate' && data?.job?.isFixedTerm){
        await ApolloClient.query({
            query: FetchMultiShiftJobPositionQuery,
            variables: {
                jobId: +jobId,
            },
        }).then(({ data:res, errors }) => {
            if(!res && errors && errors?.length) throw new Error(errors[0].message)
            data.job.positions = res?.getUniquePositions?.map(a => ({worker:{...a.worker,id: a.workerId}}));
        }).catch(e => {
            dispatch(MetaActions.errorToast(`Could not get position data: ${e.message}`));
        });
      };
      dispatch({
        type: types.SET_EDIT,
        payload: { job: data.job, editType },
      });
      dispatch(setIsLoading(false));
    })
    .catch(() => {
      dispatch(setIsLoading(false));
    });
};

export const fetchManagers = (id) => (dispatch, getState) => {
  return ApolloClient.query({
    query: FetchManagerQuery,
    variables: {
      employerId: id,
      isAll: true
    },
  })
    .then(({ data }) => {
      dispatch(setManager(data.managerByEmployerId));
    })
    .catch((e) => {
      console.log(e)
    });
};

export const fetchPrefillEmployer = (employerId,type) => dispatch => {
  dispatch(setIsLoading(true));
  return ApolloClient.query({
    query: FetchPrefillEmployerQuery,
    variables: {
      id: employerId,
    },
  })
    .then(({ data }) => {
      const { employer } = data
      const employerData = {
        value: employer.id,
        label: employer.companyName,
        data: employer,
      }
      dispatch(setEmployer(employerData, type==="edit"));
      dispatch(setIsLoading(false));
    })
    .catch(() => {
      dispatch(setIsLoading(false));
    });
};

export const fetchWorkTypes = () => async dispatch => {
  dispatch(setIsLoading(true));
  return ApolloClient.query({ query: FetchWorkTypesQuery })
    .then(({ data: { workTypes } }) => {
      dispatch(setJobTypeList(workTypes));
      dispatch(setIsLoading(false));
      // return workTypes;
    })
    .catch(() => {
      dispatch(setIsLoading(false));
    });
};

export const calculateEmployerBill = (employerId,params) => (dispatch) => {
    return ApolloClient.query({
      query: GetJobBillQuery,
      variables: {
        data: {
          employerId: employerId,
          ...params
        }
      },
    })
      .then(({ data }) => {
        dispatch({
          type: types.SET_CALCULATED_JOB_AMOUNT,
          payload: {
            data: data.calculateEmployerBill
          },
        });
      })
      .catch((e) => {
        console.log(e)
      });
  };

export const provinceWiseBreakRules = (params) => (dispatch) => {
    if (timeId) clearTimeout(timeId);
    timeId = setTimeout(() => {
        ApolloClient.query({
            query: FindBreakRuleByShiftAndRegion,
            variables: {
                data: {
                    ...params
                }
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: types.SET_PROVINCE_WISE_BREAK_RULES,
                    payload: {
                        data: data.findBreakRuleByShiftAndRegion
                    },
                });
                if (timeId) clearTimeout(timeId);
            })
            .catch(() => {
                if (timeId) clearTimeout(timeId);
            });
    }, 500);
};
