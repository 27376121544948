import { AppBar, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SubNav from 'components/Navigation/SubNav';
import SubNavTab from 'components/Navigation/SubNavTab';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';
import history from 'utils/history';
import { formatInitials } from "utils/stringUtils";

const useStyles = makeStyles(theme => ({
    container: {
        padding: "24px 32px 24px",
        background: theme.palette.background.paper,
        borderBottom: 0,
        position: "relative",
        width: "100%",
        zIndex: 1029,
        color: "#555555",
        border: 0,
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        transition: "all 150ms ease 0s",
        minHeight: 80,
        display: "block",
        marginBottom: 0
    },
    groups: {
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'nowrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
    },
    headerTitle: {
        color: '#3F454D',
        marginBottom: theme.spacing(0.5)
    },
    headerSubTitle: {
        color: '#6B6F75',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    img: {},
    workerWrapper: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
    }
}));

export default function Header() {
    const worker = useSelector(Selectors.getWorkerBasicInfo);
    const classes = useStyles();
    const activeTab = useSelector(Selectors.getRequirementActiveTab);
    const params = new URLSearchParams(history.location.search);
    const type = parseInt(params.get("type"), 10);
    const dispatch = useDispatch();

    useEffect(() => {
        if (history.location.search) {
            if (!isNaN(type)) {
                dispatch(Actions.setRequirementActiveTab(parseInt(type, 10)));
            }
        } else {
            history.replace(`${window?.location?.pathname}?type=${0}`);
        };
    }, [type]);

    const handleChangeTab = (event, value) => {
        history.replace(`${window?.location?.pathname}?type=${value}`);
        dispatch(Actions.setRequirementActiveTab(value));
    };

    return (
        <>
            <AppBar className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.workerWrapper}>
                        <Avatar sx={{ width: 56, height: 56 }} alt={worker?.fullName} src={worker?.signedProfileImageUrl + 's'}>{formatInitials(worker?.firstName, worker?.lastName, "")}</Avatar>
                        <div>
                            <Typography className={classes.headerTitle} variant="h5" letterSpacing={0.015}>{worker?.fullName}</Typography>
                            <Typography className={classes.headerSubTitle}>Joined {moment(worker?.createdAt).format('MMMM, YYYY')}</Typography>
                        </div>
                    </div>
                    <div className={classes.groups}>
                        <Typography className={classes.headerSubTitle}>{worker?.email}</Typography>
                    </div>
                </div>
            </AppBar>
            <SubNav value={activeTab} onChange={handleChangeTab} tabs={[
                <SubNavTab key="PerRequirements" label="Per Requirements" />,
                <SubNavTab key="PerEmployers" label={"Per Employers"} />,
            ]} />
        </>
    );
};
