/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";
import { emphasize } from "@mui/material/styles";
import { fetchCities } from "./helpers/actions";
import LoadingMessage from "./components/LoadingMessage";

const styles = (theme) => ({
    input: {
        display: "flex",
        padding: "8px 8px",
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
        "& div:last-child": {
            width: (props) => props.isMulti ? "100%" : "auto",
            position: "relative",
        }
    },
    chip: {
        margin: `${theme.spacing(0.25)} ${theme.spacing(0.10)}`,
        fontSize: 10,
        height: 22,
        "& svg": {
            height: 18,
            width: 18
        },
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    singleValue: {
        fontSize: 16,
        lineHeight: 1
    },
    placeholder: {
        position: "absolute",
        left: 10,
        fontSize: 14,
        color: '#a4a7ab'
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(0),
        left: 0,
        right: 0,
    }
})

class CityDropDown extends React.Component {
    state = {
        options: [],
        isLoading: false,
    }
    selectComponents = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        LoadingMessage,
        IndicatorSeparator: () => null,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    ...(this.props.style || {}),
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    height: '17px',
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
        };
    }

    componentDidMount() {
        this.setState(p => ({ ...p, isLoading: true }));
        fetchCities().then(({ data: { getEmployerCities } }) => {
            this.setState(p => ({
                ...p, isLoading: false, options: getEmployerCities.map(a => ({
                    label: a,
                    value: a,
                }))
            }));
        }).catch(er => {
            this.setState(p => ({ ...p, isLoading: false }));
        })
    }

    render() {
        const { label, classes, className, inputProps = {}, ...passProps } = this.props;
        const { isLoading, options } = this.state;

        return (
            <Select
                {...passProps}
                classes={classes}
                className={className}
                styles={{ ...this.selectStyles, ...(this.props.menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 999999 }) } : {}) }}
                isClearable
                value={this.props.value}
                {...(passProps.isMulti ? { value: defaultSelectedOp } : {})}
                placeholder={label}
                isLoading={isLoading}
                options={options}
                onChange={this.props.onChange}
                components={this.selectComponents}
                textFieldProps={{
                    label: label,
                    placeholder: label,
                    ...inputProps,
                }}
            />
        );
    }
}

CityDropDown.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CityDropDown);
