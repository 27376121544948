import React from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { ListItemText, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import AdditionalRequirementList from "./components/AdditionalRequirementList";
import history from "utils/history";
import { actions as Actions } from 'store/modules/additionalRequirements';
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    filterConatiner: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    group: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    },
    selectContainer: {
        width: 160,
        '& .group-select__indicator-separator': {
            display: 'none'
        }
    },
    requirementMenu: {
        '& .MuiListItemText-root': {
            color: '#3F454D',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
            textAlign: 'left',
            paddingLeft: theme.spacing(0)
        },
        '& .MuiButtonBase-root.MuiMenuItem-root': {
            fontSize: 16,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#000000'
        }
    }
}));

function AdditionalRequirements() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const onOpenCreateMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MainContent>
                <div className={classes.filterConatiner}>
                    <div className={classes.group}>
                        <Button
                            label="Create New"
                            icon={<AddCircleOutline />}
                            alignIcon="left"
                            onClick={onOpenCreateMenu}
                            style={{ paddingRight: 0 }}
                        />
                        <Menu
                            id="additional-requirement-menu"
                            className={classes.requirementMenu}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'additional-requirement-menu',
                            }}
                        >
                            <MenuItem disabled>
                                <ListItemText inset>Choose Requirement Type</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                dispatch(Actions.clearAdditionalRequirementForm());
                                history.push('/additional-requirements/create-validation');
                                handleClose()
                            }}>Validation</MenuItem>
                            <MenuItem onClick={() => {
                                dispatch(Actions.clearAdditionalRequirementForm());
                                history.push('/additional-requirements/create-document');
                                handleClose()
                            }}>Document</MenuItem>
                        </Menu>
                    </div>
                </div>
                <AdditionalRequirementList />
            </MainContent>
        </>
    )
}

export default AdditionalRequirements
