/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-find-dom-node */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
// import MaskedInput from "react-text-mask";
import moment from "moment-timezone";
import { Button as Btn } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import _ from "lodash";
import { accountSectionStyles } from "views/Employer/helpers";
import * as yup from "yup";
import { formatPhone } from "utils/stringUtils";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employerDetails";
import { selectors as FinanceSelectors } from "store/modules/finance";
import {
    fetchQbEmployer,
    fetchQuickbooksItems,
    initQbCustomer,
    saveQbCustomer,
} from "store/modules/finance/actions/qbActions";
import CreateCustomRateDialog from "./CreateCustomRateDialog";
import EditCustomRateDialog from "./EditCustomRateDialog";
import ProfileTab from "./Tabs/ProfileTab";
import NotVerifiedIcon from "assets/img/not_verified.svg";
import VerifiedIcon from "assets/img/verified.svg";
import BillingInformation from "./Tabs/BillingInformation";
import TimeBasePremiumTab from "./Tabs/TimeBasePremiumTab";
import DynamicWorkTypeTab from "./Tabs/DynamicWorkType/DynamicWorkTypeTab";
import { Info } from "@mui/icons-material";
const isMobile = false;

function EditEmployerProfileTabs(props) {
    const classes = accountSectionStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [editingRateData, setEditingRateData] = useState();
    const [showCreateRateDialog, setShowCreateRateDialog] = useState(false);
    const [showEditRateDialog, setShowEditRateDialog] = useState(false);
    const valueRef = useRef("");
    const {
        qbId,
        companyName,
        setTab,
        firstName,
        lastName,
        email,
        phone,
        createdAt,
        street,
        city,
        region,
        country,
        postalCode,
        isActive,
        isApproved,
        websiteUrl,
        billingRate,
        payRate,
        isMassCheckinEnabled,
        isAutoConfirmEnabled,
        hideInfo,
        allowPreferredOnly,
        jobNotification,
        allowVaccinatedOnly,
        canPostPrivateJob
    } = props;

    useEffect(() => {
        qbId && dispatch(fetchQbEmployer(qbId));
        dispatch(fetchQuickbooksItems());
    }, [editingRateData, dispatch, qbId]);

    let emp = useSelector(FinanceSelectors.getQbEmployer);
    let {
        SalesTermRef,
        CurrencyRef,
        PaymentMethodRef,
        displayName,
        PrintOnCheckName,
        FullyQualifiedName,
        GivenName,
        FamilyName,
        Title,
        PrimaryPhone,
        AlternatePhone,
        Mobile,
        Notes,
        PreferredDeliveryMethod,
        BillingAddress,
        Taxable,
    } = useSelector(FinanceSelectors.getQbEmployer);
    let qbTerms = useSelector(FinanceSelectors.getQbTerms).terms;
    let qbPaymentMethods = useSelector(FinanceSelectors.getQbPaymentMethods);
    let employerCustomRates = useSelector(EmployerSelectors.getEmployerCustomRates);
    let employer = useSelector(EmployerSelectors.getEmployerDetails).employer;
    let profilePic = employer && employer.signedProfileImageUrl ? `${employer.signedProfileImageUrl}` : "";
    let companyLogo = employer && employer.signedLogoImageUrl ? `${employer.signedLogoImageUrl}` : "";

    useEffect(() => {
        profilePic = employer && employer.signedProfileImageUrl ? employer.signedProfileImageUrl : "";
        companyLogo = employer && employer.signedLogoImageUrl ? employer.signedLogoImageUrl : "";
    }, [companyLogo, profilePic]);

    const controlPadding = {
        leftColumn: { paddingRight: isMobile ? 0 : 4, textAlign: 'left' },
        rightColumn: { paddingLeft: isMobile ? 0 : 4 },
    };

    const a11yProps = index => {
        return {
            id: `scrollable-auto-tab-${index}`,
            "aria-controls": `scrollable-auto-tabpanel-${index}`,
        };
    };

    const tabProps = {
        indicatorColor: "secondary",
        textColor: "secondary",
        variant: isMobile ? "scrollable" : "standard",
        scrollButtons: "auto",
        icon: <Box color="action" />,
        TabIndicatorProps: { style: { float: "right" } },
    };

    const styles = {
        textColorPrimary: { color: "#FFF" },
        textColorSecondary: { color: "#FFF" },
    };

    const TabPanel = props => {
        const { children, value, index, height, ...other } = props;
        return (
            <div
                role="tabpanel"
                style={{ height: height ? height : "100%", paddingTop: 20 }}
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && children}
            </div>
        );
    };

    const TextMaskCustom = props => {
        const { inputRef, ...other } = props;
        return (
            <input
                {...other}
                ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
                mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
                placeholderChar={"\u2000"}
            />
        );
    };

    const handleChange = (event, newValue) => {
        setTab(newValue);
        switch (newValue) {
            case 0:
                setValue(newValue);
                break;
            case 1:
                setValue(newValue);
                break;
            case 2:
                setValue(newValue);
                break;
            case 3:
                setValue(newValue);
                break;
            case 4:
                setValue(newValue);
                break;
            default:
                setValue(newValue);
                break;
        }
    };

    const handleCancelEditRateDialog = () => {
        setShowEditRateDialog(false);
        setEditingRateData();
        dispatch(EmployerActions.clearUpdateEmployerCustomRateData());
        dispatch(EmployerActions.clearCreateEmployerCustomRateData());
    };

    const handleCreateNewEmployerCustomRate = () => {
        setShowCreateRateDialog(false);
        dispatch(EmployerActions.createEmployerCustomRate());
    };

    const toggleShowCreateRateDialog = () => {
        setShowCreateRateDialog(!showCreateRateDialog);
        dispatch(EmployerActions.clearCreateEmployerCustomRateData());
    };

    // const handlInitQbCustomer = () => {
    //   dispatch(initQbCustomer());
    // };

    const handleUpdateQbEmployer = data => dispatch(saveQbCustomer(data));
    const RenderQbProfileInitCard = () => (
        <Grid item xs={12} style={{ paddingLeft: 0, paddingRight: 10 }}>
            <Grid
                container
                item
                xs={12}
                style={{ paddingLeft: 0, paddingRight: 0, alignItems: "flex-start" }}>
                <Grid
                    container
                    item
                    xs={12}
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        alignItems: "flex-start",
                    }}>
                    <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
                        Quickbooks Manager
                    </Typography>
                    <Typography
                        variant="body1"
                        align="left"
                        style={{ width: "100%", marginTop: 1, marginBottom: 10 }}>
                        {`Create Quickbooks profile and settings.`}
                    </Typography>
                </Grid>
                {/*<Grid container item xs={12} md={5} style={controlPadding.leftColumn}>
          <Typography
            variant="body1"
            align="left"
            style={{ width: "100%", marginTop: 1, marginBottom: 10 }}>
            {`Create ${emp.displayName}'s Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings. Quickbooks profile and settings.`}
          </Typography>
        </Grid>*/}
                {/*<Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
          <Button
            color="secondary"
            icon={<Add />}
            label="Create Quickbooks Customer"
            alignIcon="right"
            onClick={handlInitQbCustomer}
          />
        </Grid>*/}
            </Grid>
        </Grid>
    );

    const RenderQbProfileForm = () => (
        <Grid item xs={12} style={{ paddingLeft: 0, paddingRight: 10 }}>
            <Formik
                initialValues={{
                    SalesTermRef,
                    CurrencyRef,
                    PaymentMethodRef,
                    //   id,
                    displayName,
                    companyName,
                    PrintOnCheckName,
                    FullyQualifiedName,
                    GivenName,
                    FamilyName,
                    Title: Title || "Contact",
                    PrimaryPhone: PrimaryPhone || phone || "",
                    AlternatePhone: AlternatePhone || phone || "",
                    Mobile,
                    Notes,
                    email,
                    PreferredDeliveryMethod,
                    Taxable,
                    Line1: BillingAddress.Line1 || "",
                    Line2: BillingAddress.Line2 || "",
                    CountrySubDivisionCode: BillingAddress.CountrySubDivisionCode || "",
                    PostalCode: BillingAddress.PostalCode || "",
                }}
                onSubmit={(values, actions) => {
                    handleUpdateQbEmployer(values);
                    actions.setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required("Required"),
                    SalesTermRef: yup.string().required("Required"),
                    CurrencyRef: yup.string().required("Required"),
                    PaymentMethodRef: yup.string().required("Required"),
                    // id: yup.string().required("Required"),
                    displayName: yup.string().required("Required"),
                    companyName: yup.string().required("Required"),
                    PrintOnCheckName: yup.string().required("Required"),
                    FullyQualifiedName: yup.string().required("Required"),
                    GivenName: yup.string().required("Required"),
                    FamilyName: yup.string().required("Required"),
                    //   contact: yup.string().required("Required"),
                    Title: yup.string(),
                    PrimaryPhone: yup.string().required("Required"),
                    AlternatePhone: yup.string().required("Required"),
                    Mobile: yup.string().required("Required"),
                    //   phone: yup.string().required("Required"),
                    Notes: yup.string().required("Required"),
                    PreferredDeliveryMethod: yup.string().required("Required"),
                    //   Taxable: yup.string().required("Required"),
                })}>
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleReset,
                    } = props;
                    return (
                        <Form>
                            <Grid
                                item
                                container
                                direction="row"
                                xs={12}
                                style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                                    {emp && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            style={{ paddingLeft: 0, paddingRight: 0, alignItems: "flex-start" }}>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    alignItems: "flex-start",
                                                }}>
                                                <Typography
                                                    variant="h6"
                                                    align="left"
                                                    style={{ width: "100%", marginTop: 1 }}>
                                                    Quickbooks Manager
                                                    <small style={{ float: "right" }}>Quickbooks Id: {qbId}</small>
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    align="left"
                                                    style={{ width: "100%", marginTop: 1, marginBottom: 10 }}>
                                                    {`Update ${emp.displayName}'s Quickbooks profile and settings.`}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
                                                <TextField
                                                    name="displayName"
                                                    id="displayName"
                                                    label="Display Name"
                                                    className={classes.textField}
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.companyName && touched.companyName}
                                                    helperText={
                                                        errors.companyName && touched.companyName && errors.companyName
                                                    }
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                md={4}
                                                style={{
                                                    ...controlPadding.rightColumn,
                                                    ...controlPadding.leftColumn,
                                                    width: "100%",
                                                    marginTop: 0,
                                                }}>
                                                <TextField
                                                    name="companyName"
                                                    id="companyName"
                                                    label="Company Name"
                                                    className={classes.textField}
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.companyName && touched.companyName}
                                                    helperText={
                                                        errors.companyName && touched.companyName && errors.companyName
                                                    }
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                                                <TextField
                                                    name="PrintOnCheckName"
                                                    id="PrintOnCheckName"
                                                    label="Print On Check Name"
                                                    className={classes.textField}
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.companyName && touched.companyName}
                                                    helperText={
                                                        errors.companyName && touched.companyName && errors.companyName
                                                    }
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
                                                <TextField
                                                    id="Title"
                                                    label="Title"
                                                    name="Title"
                                                    className={classes.textField}
                                                    value={values.Title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.Title && touched.Title}
                                                    helperText={errors.Title && touched.Title && errors.Title}
                                                    margin="normal"
                                                    inputRef={valueRef}
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                md={4}
                                                style={{
                                                    ...controlPadding.rightColumn,
                                                    ...controlPadding.leftColumn,
                                                    width: "100%",
                                                    marginTop: 0,
                                                }}>
                                                <TextField
                                                    name="GivenName"
                                                    id="GivenName"
                                                    label="First Name"
                                                    required
                                                    inputRef={valueRef}
                                                    className={classes.textField}
                                                    value={values.GivenName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.GivenName && touched.GivenName}
                                                    helperText={errors.GivenName && touched.GivenName && errors.GivenName}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                                                <TextField
                                                    name="FamilyName"
                                                    id="FamilyName"
                                                    label="Last Name"
                                                    className={classes.textField}
                                                    value={values.FamilyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.FamilyName && touched.FamilyName}
                                                    helperText={errors.FamilyName && touched.FamilyName && errors.FamilyName}
                                                    margin="normal"
                                                    required
                                                    inputRef={valueRef}
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label=" Email Address"
                                                    disabled
                                                    className={classes.textField}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.email && touched.email}
                                                    helperText={errors.email && touched.email && errors.email}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                md={4}
                                                style={{
                                                    ...controlPadding.rightColumn,
                                                    ...controlPadding.leftColumn,
                                                    width: "100%",
                                                    marginTop: 0,
                                                }}>
                                                <TextField
                                                    name="Mobile"
                                                    id="phone"
                                                    label="Mobile"
                                                    className={classes.textField}
                                                    value={values.Mobile}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.Mobile && touched.Mobile}
                                                    helperText={errors.Mobile && touched.Mobile && errors.Mobile}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                                                <TextField
                                                    name="AlternatePhone"
                                                    id="AlternatePhone"
                                                    label="Alternate Phone"
                                                    className={classes.textField}
                                                    value={values.AlternatePhone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.AlternatePhone && touched.AlternatePhone}
                                                    helperText={
                                                        errors.AlternatePhone && touched.AlternatePhone && errors.AlternatePhone
                                                    }
                                                    margin="normal"
                                                    required
                                                />
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                                                    <Typography
                                                        variant="h6"
                                                        align="left"
                                                        style={{ width: "100%", marginTop: 1, fontSize: 14 }}>
                                                        Payment Settings
                                                    </Typography>
                                                    <Grid container item md={12}>
                                                        <Grid container item xs={6} md={6} style={controlPadding.leftColumn}>
                                                            <TextField
                                                                select
                                                                name="PreferredDeliveryMethod"
                                                                id="PreferredDeliveryMethod"
                                                                label="Invoice Delivery Method"
                                                                className={classes.textField}
                                                                value={values.PreferredDeliveryMethod}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                error={
                                                                    errors.PreferredDeliveryMethod && touched.PreferredDeliveryMethod
                                                                }
                                                                helperText={
                                                                    errors.PreferredDeliveryMethod &&
                                                                    touched.PreferredDeliveryMethod &&
                                                                    errors.PreferredDeliveryMethod
                                                                }
                                                                margin="normal">
                                                                <MenuItem value="Email">Email</MenuItem>
                                                                <MenuItem value="Print">Print</MenuItem>
                                                                <MenuItem value="None">None</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            md={6}
                                                            style={{
                                                                ...controlPadding.rightColumn,
                                                                ...controlPadding.leftColumn,
                                                                width: "100%",
                                                                marginTop: 0,
                                                            }}>
                                                            <TextField
                                                                select
                                                                name="SalesTermRef"
                                                                id="SalesTermRef"
                                                                label="Payment Term"
                                                                className={classes.textField}
                                                                value={values.SalesTermRef}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                error={errors.SalesTermRef && touched.SalesTermRef}
                                                                helperText={
                                                                    errors.SalesTermRef && touched.SalesTermRef && errors.SalesTermRef
                                                                }
                                                                margin="normal">
                                                                {qbTerms &&
                                                                    qbTerms.map(term => (
                                                                        <MenuItem key={term.Id} value={term.Id}>
                                                                            {term.Name}
                                                                        </MenuItem>
                                                                    ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            md={6}
                                                            style={{
                                                                ...controlPadding.rightColumn,
                                                                ...controlPadding.leftColumn,
                                                                width: "100%",
                                                                marginTop: 0,
                                                            }}>
                                                            <TextField
                                                                select
                                                                name="PaymentMethodRef"
                                                                id="PaymentMethodRef"
                                                                label="Payment Method"
                                                                className={classes.textField}
                                                                value={values.PaymentMethodRef}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                error={errors.PaymentMethodRef && touched.PaymentMethodRef}
                                                                helperText={
                                                                    errors.PaymentMethodRef &&
                                                                    touched.PaymentMethodRef &&
                                                                    errors.PaymentMethodRef
                                                                }
                                                                margin="normal">
                                                                {qbPaymentMethods &&
                                                                    qbPaymentMethods.map(term => (
                                                                        <MenuItem key={term.Id} value={term.Id}>
                                                                            {term.Name}
                                                                        </MenuItem>
                                                                    ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid container item xs={6} md={6} style={controlPadding.rightColumn}>
                                                            <TextField
                                                                select
                                                                name="Taxable"
                                                                id="Taxable"
                                                                label="Taxable"
                                                                className={classes.textField}
                                                                value={values.Taxable}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                fullWidth
                                                                error={errors.Taxable && touched.Taxable}
                                                                helperText={errors.Taxable && touched.Taxable && errors.Taxable}
                                                                margin="normal">
                                                                <MenuItem value={true}>Active</MenuItem>
                                                                <MenuItem value={false}>Non Taxable</MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                    {emp && emp.BillingAddress && (
                                                        <>
                                                            <Typography
                                                                variant="h6"
                                                                align="left"
                                                                style={{ width: "100%", marginTop: 1, fontSize: 14 }}>
                                                                Billing Address
                                                            </Typography>
                                                            <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                                                                <TextField
                                                                    id="Line1"
                                                                    name="Line1"
                                                                    label="Street"
                                                                    className={classes.textField}
                                                                    value={values.Line1}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    fullWidth
                                                                    error={errors.Line1 && touched.Line1}
                                                                    helperText={errors.Line1 && touched.Line1 && errors.Line1}
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                md={6}
                                                                style={controlPadding.rightColumn}>
                                                                <TextField
                                                                    id="Line2"
                                                                    name="Line2"
                                                                    label="City"
                                                                    className={classes.textField}
                                                                    value={values.Line2}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    fullWidth
                                                                    error={errors.Line2 && touched.Line2}
                                                                    helperText={errors.Line2 && touched.Line2 && errors.Line2}
                                                                    margin="normal"
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                md={4}
                                                                direction="column"
                                                                style={controlPadding.leftColumn}>
                                                                <TextField
                                                                    select
                                                                    name="CountrySubDivisionCode"
                                                                    id="CountrySubDivisionCode"
                                                                    label="Region"
                                                                    className={classes.textField}
                                                                    value={values.CountrySubDivisionCode}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    fullWidth
                                                                    error={
                                                                        errors.CountrySubDivisionCode && touched.CountrySubDivisionCode
                                                                    }
                                                                    helperText={
                                                                        errors.CountrySubDivisionCode &&
                                                                        touched.CountrySubDivisionCode &&
                                                                        errors.CountrySubDivisionCode
                                                                    }
                                                                    margin="normal">
                                                                    <MenuItem value="British Columbia">British Columbia</MenuItem>
                                                                    <MenuItem value="Alberta">Alberta</MenuItem>
                                                                    <MenuItem value="Ontario">Ontario</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                md={4}
                                                                direction="column"
                                                                style={{
                                                                    ...controlPadding.rightColumn,
                                                                    ...controlPadding.leftColumn,
                                                                }}>
                                                                <TextField
                                                                    id="PostalCode"
                                                                    name="PostalCode"
                                                                    label="Postal Code"
                                                                    className={classes.textField}
                                                                    value={values.PostalCode}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    fullWidth
                                                                    error={errors.PostalCode && touched.PostalCode}
                                                                    helperText={
                                                                        errors.PostalCode && touched.PostalCode && errors.PostalCode
                                                                    }
                                                                    margin="normal"
                                                                    InputProps={{
                                                                        inputComponent: TextMaskCustom,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                md={4}
                                                                direction="column"
                                                                style={controlPadding.rightColumn}>
                                                                <TextField
                                                                    select
                                                                    name="Country"
                                                                    id="country"
                                                                    label="Country"
                                                                    className={classes.textField}
                                                                    value={"Canada"}
                                                                    fullWidth
                                                                    disabled
                                                                    margin="normal">
                                                                    <MenuItem value="Canada">Canada</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    style={{ ...controlPadding.RightColumn, paddingLeft: 10 }}>
                                                    <Typography
                                                        variant="h6"
                                                        align="left"
                                                        style={{ width: "100%", marginTop: 1, fontSize: 14 }}>
                                                        Notes
                                                    </Typography>
                                                    <TextField
                                                        id="Notes"
                                                        name="Notes"
                                                        placeholder="Add notes for this customer's Quickbooks account"
                                                        required
                                                        multiline
                                                        rows={18}
                                                        style={{ height: "100%" }}
                                                        className={classes.textField}
                                                        value={values.Notes}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        error={errors.Notes && touched.Notes}
                                                        helperText={errors.Notes && touched.Notes && errors.Notes}
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <DialogActions
                                                className={classes.footer}
                                                style={{ width: "100%", textAlign: "center" }}>
                                                <Btn
                                                    type="button"
                                                    className="outline"
                                                    onClick={handleReset}
                                                    disabled={!dirty || isSubmitting}>
                                                    Reset
                                                </Btn>
                                                <Btn
                                                    variant="contained"
                                                    color="secondary"
                                                    type="submit"
                                                    disabled={!_.isEmpty(errors)}>
                                                    Update Quickbooks Profile
                                                </Btn>
                                            </DialogActions>
                                            <pre style={{ textAlign: "left" }}>{JSON.stringify(errors, null, 2)}</pre>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );

    const onSubmit = data => {
        dispatch(EmployerActions.saveEmployerProfile(data));
        // handleSetCanEdit();
    };

    const handleSwitchChange = ({ target: { name, checked } }) =>
        dispatch(EmployerActions.saveEmployerProfile({ [name]: checked }));

    const handleChangeReminders = () => {
        const { id } = props;
        dispatch(EmployerActions.setEmployerJobReminder(id))
    }

    const handleChangeVaccinationStatus = () => {
        const { id } = props;
        dispatch(EmployerActions.setEmployerVaccinationStatus(id, !allowVaccinatedOnly))
    }

    const handleChangePrivateJob = () => {
        const { id } = props;
        dispatch(EmployerActions.setEmployerPrivateJobStatus(id, !canPostPrivateJob))
    }

    const handleChangeHideInfo = ({ target: { checked } }) => {
        const { id } = props;
        dispatch(EmployerActions.setEmployerHideInfo(id, checked))
    }

    const RenderEmployerSettingsForm = () => (
        <TabPanel value={value} index={5}>
            <Grid container direction="row" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
                    Account Settings
                </Typography>
                <Grid container item direction="row">
                    <Grid container item xs={12} md={9} style={controlPadding.leftColumn}>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="isActive"
                                        defaultChecked={isActive}
                                        onChange={handleSwitchChange}
                                        name="isActive"
                                        color="secondary"
                                    />
                                }
                                style={{ color: "rgb(33 42 52)" }}
                                label="Is Active"
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="isApproved"
                                        defaultChecked={isApproved}
                                        onChange={handleSwitchChange}
                                        name="isApproved"
                                        color="secondary"
                                    />
                                }
                                label="Is Approved"
                                style={{ color: "rgb(33 42 52)", fontWeight: 700 }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="isMassCheckinEnabled"
                                        defaultChecked={isMassCheckinEnabled}
                                        onChange={handleSwitchChange}
                                        name="isMassCheckinEnabled"
                                        color="secondary"
                                    />
                                }
                                label="Mass Checkin"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="isAutoConfirmEnabled"
                                        defaultChecked={isAutoConfirmEnabled}
                                        onChange={handleSwitchChange}
                                        name="isAutoConfirmEnabled"
                                        color="secondary"
                                    />
                                }
                                label="Auto Confirm"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="allowPreferredOnly"
                                        defaultChecked={allowPreferredOnly}
                                        onChange={handleSwitchChange}
                                        name="allowPreferredOnly"
                                        color="secondary"
                                    />
                                }
                                label="Allow Preferred Only"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="isAutoConfirmEnabled"
                                        defaultChecked={isAutoConfirmEnabled}
                                        onChange={handleSwitchChange}
                                        name="isAutoConfirmEnabled"
                                        color="secondary"
                                    />
                                }
                                disabled
                                label="Placeholder"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        {/* <Grid item xs={6} md={4} lg={4} style={controlPadding.rightColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="allowVaccinatedOnly"
                                        defaultChecked={allowVaccinatedOnly}
                                        onChange={handleChangeVaccinationStatus}
                                        name="allowVaccinatedOnly"
                                        color="secondary"
                                    />
                                }
                                label="Vaccination Required"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid> */}
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.rightColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="jobNotification"
                                        defaultChecked={jobNotification}
                                        onChange={handleChangeReminders}
                                        name="jobNotification"
                                        color="secondary"
                                    />
                                }
                                label="Check-Out Reminders"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.rightColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="canPostPrivateJob"
                                        defaultChecked={canPostPrivateJob}
                                        onChange={handleChangePrivateJob}
                                        name="canPostPrivateJob"
                                        color="secondary"
                                    />
                                }
                                label="Can Post Private Job"
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} lg={4} style={controlPadding.leftColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id="hideInfo"
                                        defaultChecked={hideInfo}
                                        onChange={handleChangeHideInfo}
                                        name="hideInfo"
                                        color="secondary"
                                    />
                                }

                                label={<div style={{ display: 'flex', alignItems: 'center' }}>Hide Name and Full Address <Tooltip title="This will hide the client name and full address until the talent is applied for the job"><Info style={{ marginLeft: 4 }} /></Tooltip></div>}
                                style={{ color: "rgb(33 42 52)" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} style={controlPadding.leftColumn}>
                        <Formik
                            initialValues={{
                                payRate,
                                billingRate,
                            }}
                            onSubmit={(values, actions) => {
                                onSubmit(values);
                                actions.setSubmitting(false);
                            }}
                            validationSchema={yup.object().shape({
                                payRate: yup.number().min(0.01, "Pay rate is invalid").required("Pay rate is required"),
                                billingRate: yup.number().test("billingRate", "Billing rate is invalid", function (billingRate) {
                                    return billingRate > this.parent.payRate;
                                }).required("Billing rate is required"),
                            })}>
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleReset,
                                } = props;
                                return (
                                    <Form>
                                        <Grid
                                            container
                                            direction="row"
                                            style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    className={classes.textField}
                                                    name="payRate"
                                                    label="Base Hourly Pay Rate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.payRate && touched.payRate}
                                                    helperText={errors.payRate && touched.payRate && errors.payRate}
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                    inputProps={{
                                                        step: 0.05,
                                                        type: "number",
                                                    }}
                                                    value={values.payRate}
                                                />
                                                <TextField
                                                    className={classes.textField}
                                                    id="billingRate"
                                                    label="Base Hourly Billing Rate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.billingRate && touched.billingRate}
                                                    helperText={
                                                        errors.billingRate && touched.billingRate && errors.billingRate
                                                    }
                                                    margin="normal"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                    inputProps={{
                                                        step: 0.05,
                                                        type: "number",
                                                    }}
                                                    value={values.billingRate}
                                                />
                                            </Grid>
                                        </Grid>
                                        <DialogActions className={classes.accountSettingFooter}>
                                            <Btn
                                                type="button"
                                                className="outline"
                                                onClick={handleReset}
                                                disabled={!dirty || isSubmitting}>
                                                Reset
                                            </Btn>
                                            <Btn
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                // onClick={onSubmit}
                                                disabled={!_.isEmpty(errors)}>
                                                Update Rates
                                            </Btn>
                                        </DialogActions>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
            {/* <RenderCustomRates /> */}
        </TabPanel>
    );

    const RenderQuickbooks = () => (
        <TabPanel value={value} index={6}>
            <Grid container direction="row" style={{ paddingLeft: 0, paddingRight: 0 }}>
                {qbId ? <RenderQbProfileForm /> : <RenderQbProfileInitCard />}
            </Grid>
        </TabPanel>
    );

    const RenderOverviewTab = () => (
        <TabPanel value={value} index={0}>
            <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                {/* <RenderUploadTab /> */}
                <Grid container item xs={12}>
                    <Typography style={{ width: "100%", textAlign: "left" }} variant="h6" gutterBottom>
                        {companyName}
                    </Typography>
                </Grid>
                <Grid container item xs={12} md={4} style={{ width: "100%", textAlign: "left" }}>
                    <Tooltip
                        title={`${moment(createdAt).format("h:mm a - DD MMM YYYY z")}`}
                        placement="top-start">
                        <Typography style={{ width: "100%", textAlign: "left" }} variant="body1">
                            {`Joined ${moment(createdAt).format("MMMM, YYYY z")}`}
                        </Typography>
                    </Tooltip>
                    <Typography style={{ width: "100%", textAlign: "left" }} variant="body1">
                        {websiteUrl ? (
                            <Link
                                href={websiteUrl}
                                style={{ width: "100%" }}
                                target="_BLANK"
                                rel="noopener noreferrer"
                                variant="body1"
                                color="secondary">
                                {websiteUrl}
                            </Link>
                        ) : (
                            <i>No website URL</i>
                        )}
                    </Typography>
                    <Typography
                        style={{ width: "100%" }}
                        variant="body1">{`${firstName} ${lastName}`}</Typography>
                    <Typography style={{ width: "100%", textAlign: "left" }} variant="body1">
                        {email}
                    </Typography>
                    <Typography style={{ width: "100%", textAlign: "left" }} variant="body1">
                        {formatPhone(phone)}
                    </Typography>
                    <Typography
                        style={{ width: "100%", textAlign: "left" }}
                        variant="body1">{`${street}, ${city},`}</Typography>
                    <Typography
                        style={{ width: "100%" }}
                        variant="body1">{`${region}, ${country} ${postalCode}`}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        {isApproved ? (
                            <Tooltip title={`${companyName} is verified`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={`${companyName} Needs Verification`} placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                        <Typography className={classes.badgeText}>Approved</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Activated</Typography>
                        {isActive ? (
                            <Tooltip title={`${companyName} is active`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={`${companyName} needs activation`} placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Mass Checkin Enabled</Typography>
                        {isMassCheckinEnabled ? (
                            <Tooltip title={`Mass Checkin Is Enabled`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={`Mass Checkin Is Disabled`} placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Preferred worker Only</Typography>
                        {allowPreferredOnly ? (
                            <Tooltip title={`Allow preferred worker Only`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={`Allow all workers`} placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Custom Pricing Enabled</Typography>
                        {employerCustomRates && employerCustomRates.length ? (
                            <Tooltip title={`Custom Pricing Is Enabled`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Custom Pricing Is Not Enabled" placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Quickbooks Enabled</Typography>
                        {qbId ? (
                            <Tooltip title={`Linked to Quickbooks`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Not linked to Quickbooks" placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={12} className={classes.badgeContainer}>
                        <Typography className={classes.badgeText}>Auto Confirm Enabled</Typography>
                        {isAutoConfirmEnabled ? (
                            <Tooltip title={`Auto Confirm  Is Enabled`} placement="top">
                                <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIconSm} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={`Auto Confirm Is Not Enabled`} placement="top">
                                <img
                                    alt="Needs Verification Badge"
                                    src={NotVerifiedIcon}
                                    className={classes.approvalIconSm}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
    );

    const RenderAppBar = () => (
        <AppBar position="static" color="transparent" className={classes.tabNav}>
            <Tabs {...tabProps} centered value={value} onChange={handleChange} style={styles}>
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Company Profile" {...a11yProps(1)} />
                <Tab label="Time Base Premium" {...a11yProps(2)} />
                <Tab label="Work Type Rate" {...a11yProps(3)} />
                <Tab label="Billing Information" {...a11yProps(4)} />
                <Tab label="Account Settings" {...a11yProps(5)} />
                <Tab label="Quickbooks Settings" {...a11yProps(6)} />
            </Tabs>
        </AppBar>
    );

    const timeBasePremiumTab = useCallback(() => {
        return (<TabPanel value={value} index={2}>
            <TimeBasePremiumTab key={value} {...props} valuee={value} />
        </TabPanel>);
    }, [value])

    const dynamicWorkTypeTab = useCallback(() => {
        return (<TabPanel value={value} index={3}>
            <DynamicWorkTypeTab key={value} {...props} valuee={value} />
        </TabPanel>);
    }, [value])

    return (
        <>
            <RenderAppBar />
            <RenderOverviewTab />
            <TabPanel value={value} index={1}>
                <ProfileTab {...props} valuee={value} />
            </TabPanel>
            {timeBasePremiumTab()}
            {dynamicWorkTypeTab()}
            <TabPanel value={value} index={4}>
                <BillingInformation {...props} value={value} />
            </TabPanel>
            {/* <RenderProfileForm /> */}
            <RenderEmployerSettingsForm />
            <RenderQuickbooks />
            <CreateCustomRateDialog
                title={`Manage Employer Settings`}
                description={null}
                open={showCreateRateDialog}
                confirmText={"Update"}
                disableBackdropClick={true}
                onClose={toggleShowCreateRateDialog}
                onConfirm={handleCreateNewEmployerCustomRate}
                onCancel={toggleShowCreateRateDialog}
                alertType="form"
                modalType="editEmployer"
            />
            <EditCustomRateDialog
                title={`Manage Employer Settings`}
                description={null}
                open={showEditRateDialog}
                confirmText={"Update"}
                disableBackdropClick={true}
                onClose={handleCancelEditRateDialog}
                onConfirm={handleCreateNewEmployerCustomRate}
                onCancel={handleCancelEditRateDialog}
                alertType="form"
                modalType="editEmployer"
                item={editingRateData}
            />
        </>
    );
}
export default EditEmployerProfileTabs;
