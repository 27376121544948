import ApolloClient from "utils/apollo";
import history from "utils/history";
import { verifyEmail } from "utils/stringUtils";
import { actions as MetaActions } from "store/modules/meta";
import FetchMeQuery from "./graphql/queries/fetch-me";
import AdminIsExistsQuery from "./graphql/queries/check-admin-exist";
import FetchGoogleMapApiQuery from "./graphql/queries/fetch-google-map-key";
import FetchTimezoneQuery from "./graphql/queries/fetch-timezone";
import types from "./types";
import Session from 'supertokens-web-js/recipe/session';
import { sendPasswordResetEmail, submitNewPassword } from 'supertokens-web-js/recipe/emailpassword'
import { UserRoleClaim, PermissionClaim } from 'supertokens-web-js/recipe/userroles'
import { verifyEmail as verifySuperTokenEmail } from "supertokens-web-js/recipe/emailverification";
import { consumeCode, createCode } from 'supertokens-web-js/recipe/passwordless';

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const script = document.createElement("script");

const setIsLoginLoading = setLoadingState("login");
const setIsOtpLoading = setLoadingState("otp");

export const resetForm = () => ({ type: types.RESET });
export const forgetUser = () => ({ type: types.FORGET_USER });
export const clearCredentials = () => ({ type: types.CLEAR_CREDENTIALS });
export const setCredentials = auth => ({ type: types.SET_CREDENTIALS, payload: { auth } });
export const setGoogleMapApiKey = googleMapApiKey => ({ type: types.SET_GOOGLE_MAP_API_KEY, payload: { googleMapApiKey } });
export const setMe = me => ({ type: types.SET_ME, payload: { me } });
export const setEmail = email => ({ type: types.SET_LOGIN_EMAIL, payload: { email } });
export const setIsLoggingOut = isLoggingOut => ({ type: types.SET_IS_LOGGING_OUT, payload: { isLoggingOut } });
export const setOTP = otp => ({ type: types.SET_OTP, payload: { otp } });
export const setIsUsernameVerify = isUsernameVerify => ({
    type: types.SET_IS_USERNAME_VERIFY,
    payload: { isUsernameVerify },
});
export const setIsCheckingSession = isCheckingSession => ({
    type: types.IS_CHECKING_SESSION,
    payload: { isCheckingSession },
});

const loadMap = (key,dispatch)=>{
    dispatch(setGoogleMapApiKey(key));
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
    document.head.appendChild(script);
    script.onload = ((e) => {
        document.head.removeChild(script);
    });
};

export const checkSession = () => async dispatch => {
    return new Promise((resolve, reject) => {
        Session.attemptRefreshingSession().then(res => {
            resolve(res);
            return res;
        }).catch(er => reject(er));
    }).then(async res => {
        if (res) {
            const userId = await Session.getUserId();
            const roles = await Session.getClaimValue({ claim: UserRoleClaim });
            const permissions = await Session.getClaimValue({ claim: PermissionClaim });

            if (!roles.includes("SuperAdmin") && !roles.includes("Admin") && !roles.includes("SupportAdmin") && !roles.includes("FinanceAdmin")) {
                history.replace("/");
                return;
                // dispatch(MetaActions.infoToast("Not Authorized!"));
            }
            dispatch(setCredentials({ permissions, roles, userId }));

            return ApolloClient.query({
                query: FetchMeQuery,
                variables: { authId: userId },
            });
        }
    }).then( async (r) => {
        if (r && r?.data && r?.data?.me){
            const me = r?.data;
            if (!script.src) {
                try {
                    const res = await ApolloClient.query({ query: FetchGoogleMapApiQuery, variables: { platFormWeb: "ADMIN" } });
                    if (res && res.data) {
                        loadMap(res.data.getGoogleMapKey,dispatch)
                    }else{
                        loadMap(process.env.REACT_APP_MAP_KEY,dispatch)
                    }
                } catch (er) {
                    console.log(er, "er")
                }
            }
            return dispatch(setMe(me.me));
        }
    }).catch(er => {
        if(er && er.message)
            return dispatch(MetaActions.errorToast(er.message));
    });
};

export const handleAuthentication = () => async dispatch => {
    return new Promise((resolve, reject) => {
        Session.doesSessionExist().then(res => resolve(res)).catch(er => reject(er))
    }).then(async res => {
        if (res) {
            const roles = await Session.getClaimValue({ claim: UserRoleClaim });
            const permissions = await Session.getClaimValue({ claim: PermissionClaim });
            const userId = await Session.getUserId();

            if (!roles.includes("SuperAdmin") && !roles.includes("Admin") && !roles.includes("SupportAdmin") && !roles.includes("FinanceAdmin")) {
                history.replace("/");
                return;
                //  dispatch(MetaActions.infoToast("Not Authorized!"));
            }
            dispatch(setCredentials({ permissions, roles, userId }));

            return ApolloClient.query({
                query: FetchMeQuery,
                variables: { authId: userId },
            });
        } else {
            return;
            //  dispatch(MetaActions.infoToast("Not Authorized!"));
        }
    }).then(async (r) => {
        if (r && r?.data && r?.data?.me) {
            const me = r?.data;
            if (!script.src) {
                try {
                    const res = await ApolloClient.query({ query: FetchGoogleMapApiQuery, variables: { platFormWeb: "ADMIN" } });
                    if (res && res.data) {
                        loadMap(res.data.getGoogleMapKey,dispatch)
                    }else{
                        loadMap(process.env.REACT_APP_MAP_KEY,dispatch)
                    }
                } catch (er) {
                    console.log(er, "er")
                }
            }
            dispatch(setMe(me.me));
            history.replace("/dashboard");
            return;
        }
    }).catch(er => {
        if(er && er.message)
            dispatch(MetaActions.errorToast(er.message));
        history.replace("/");
        return;
    });;
};

export const login = (isResend = false) => (dispatch, getState) => {
    const { email } = getState().login;
    const actionPayload = { email };
    dispatch(setIsLoginLoading(true));
    if (verifyEmail(email)) {
        ApolloClient.query({
            query: AdminIsExistsQuery,
            variables: {
                email,
            },
        }).then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else if (data && !data.adminIsExists) {
                throw new Error("Invalid email");
            }
            else {
                createCode(actionPayload).then(res => {
                    if (res.status === "OK") {
                        dispatch(setIsUsernameVerify(true));
                        if (isResend) {
                            dispatch(MetaActions.successToast("Code resend successfully"));
                        }
                    } else {
                        dispatch(MetaActions.errorToast("Invalid Email"));
                    }
                    dispatch(setIsLoginLoading(false));
                }).catch(err => {
                    console.log(err)
                    if (err.isSuperTokensGeneralError === true) {
                        dispatch(MetaActions.errorToast(err.message));
                    } else {
                        dispatch(MetaActions.errorToast("Oops! Something went wrong."));
                    }
                    dispatch(setIsLoginLoading(false));
                })
            }
        }).catch(er => {
            dispatch(MetaActions.errorToast(er.message == "Failed to fetch" ? "Oops! Something went wrong." : er.message));
            dispatch(setIsLoginLoading(false));
        });
    } else {
        dispatch(MetaActions.errorToast("Invalid Email"));
        dispatch(setIsLoginLoading(false));
        return;
    }

};

export const submitOTP = () => (dispatch, getState) => {
    const { otp } = getState().login;
    dispatch(setIsOtpLoading(true));
    consumeCode({ userInputCode: otp }).then(async response => {
        if (response.status === "OK") {
            window.location.href = "/";
        } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
            dispatch(setOTP(''));
            dispatch(MetaActions.errorToast("Wrong OTP! Please try again. Number of attempts left: " + (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount)));
        } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
            dispatch(setOTP(''));
            dispatch(MetaActions.errorToast("Old OTP entered. Please regenerate a new one and try again"));
        } else if (response.status === "RESTART_FLOW_ERROR") {
            dispatch(setOTP(''));
            dispatch(MetaActions.errorToast("Please regenerate a new OTP and try again"));
        } else {
            dispatch(setOTP(''));
            dispatch(MetaActions.errorToast("Login failed. Please try again"));
        }
        dispatch(setIsOtpLoading(false));
    }).catch(err => {
        if (err.isSuperTokensGeneralError === true) {
            dispatch(MetaActions.errorToast(err.message));
        } else {
            dispatch(MetaActions.errorToast("Oops! Something went wrong."));
        }
        dispatch(setOTP(''));
        dispatch(setIsOtpLoading(false));
    });
};

export const logout = () => async dispatch => {
    dispatch(setIsLoggingOut(true));
    await Session.signOut();
    window.localStorage.setItem("logout", Date.now());
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("persist:finance");
    window.localStorage.removeItem("persist:subscriptions");
    setTimeout(() => {
        dispatch(resetForm());
        dispatch(setIsLoggingOut(false));
    }, 5000);
};

export const sendPasswordReset = () => (dispatch, getState) => {
    const body = {
        formFields: [{
            id: "email",
            value: getState().login.email
        }]
    };
    sendPasswordResetEmail(body).then(response => {
        if (response.status === "FIELD_ERROR") {
            // one of the input formFields failed validaiton
            response.formFields.forEach(formField => {
                if (formField.id === "email") {
                    // Email validation failed (for example incorrect email syntax).
                    dispatch(MetaActions.errorToast(`Error: ${formField.error}`));
                }
            })
        } else {
            // reset password email sent.
            dispatch(MetaActions.successToast(`Please check your email for the password reset link`));
        }
    }).catch(err => {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            dispatch(MetaActions.errorToast(`Error: ${err.message}`));
        } else {
            dispatch(MetaActions.errorToast(`Oops! Something went wrong.`));
        }
    });
};

export const changePassword = (data) => (dispatch) => {
    return submitNewPassword({
        formFields: [{
            id: "password",
            value: data.password
        }]
    }).then(response => {
        if (response.status === "FIELD_ERROR") {
            response.formFields.forEach(formField => {
                if (formField.id === "password") {
                    // New password did not meet password criteria on the backend.
                    dispatch(MetaActions.errorToast(`Error: ${formField.error}`));
                }
            })
        } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
            // the password reset token in the URL is invalid, expired, or already consumed
            dispatch(MetaActions.errorToast(`Password reset failed. Please try again`));
        } else {
            dispatch(MetaActions.successToast(`Password reset successful!`));
        }
    }).catch(err => {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            dispatch(MetaActions.errorToast(`Error: ${err.message}`));
        } else {
            dispatch(MetaActions.errorToast(`Oops! Something went wrong.`));
        }
    })
}

export const setTimezoneList = () => dispatch =>
    ApolloClient.query({ query: FetchTimezoneQuery })
        .then(({ data }) => dispatch({ type: types.SET_TIMEZONE_LIST, payload: data.timezones }))
        .catch(e => console.log(`getTimezoneList error: ${e}`));

export const consumeVerificationCode = () => dispatch => {
    return verifySuperTokenEmail().then(response => {
        if (response.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
            // This can happen if the verification code is expired or invalid.
            // You should ask the user to retry
            dispatch(MetaActions.errorToast(`Oops! Seems like the verification link expired. Please try again`));
        } else {
            // email was verified successfully.
            dispatch(MetaActions.successToast(`Email was verified successfully.`));
        }
    }).catch(err => {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            dispatch(MetaActions.errorToast(`${err.message}`));
        } else {
            dispatch(MetaActions.errorToast(`Oops! Something went wrong.`));
        }
    })
};
