import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Star from "@mui/icons-material/Star";

import { employerStatsCardStyles } from "views/Employer/helpers";

function EmployerStatsCard(props) {
  const { classes, rating, jobCount, positionCount } = props;

  return (
    <Grid item xs={6} style={{ paddingRight: 8 }}>
      <Grid container item xs={12} className={classes.card} >
        {/* <Grid container item xs={12} direction="column" alignItems="flex-end">
          <Typography variant="caption" gutterBottom>
            Rating
          </Typography>
          <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
            <Typography variant="h4" gutterBottom>
              {rating ? rating : <i>Not Rated</i>}
            </Typography>
            <Star className={classes.star} />
          </Grid>
        </Grid> */}
        <Grid container item xs={12} style={{height:"fit-content"}}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end">
            <Typography variant="caption">Jobs</Typography>
            <Typography variant="h6">{jobCount}</Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-end"
            marginTop={1}>
            <Typography variant="caption">Positions</Typography>
            <Typography variant="h6">{positionCount}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

EmployerStatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  rating: PropTypes.number,
  positionCount: PropTypes.number,
  jobCount: PropTypes.number,
};

EmployerStatsCard.defaultProps = {
  rating: 0,
  positionCount: 0,
  jobCount: 0,
};

export default employerStatsCardStyles(EmployerStatsCard);
