import { Grow } from "@mui/material";
import React from "react";
import DownloadFile from "./components/DownloadFile";
import { allowedDocumentsRenderer } from "./helpers/constants";

const DocumentViewer = ({ document, type, style }) => {
    const { Renderer } = allowedDocumentsRenderer?.find(p => p.mime === type) || {};

    if (Renderer) return (
        <Renderer
            document={document}
            style={style}
        />
    );

    return (
        <Grow in={Boolean(document?.signedDocumentUrl)} timeout={1000}>
            <div style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", display: 'flex', flexDirection: "column" }}>
                <p>
                    Document type is not supported
                </p>
                <DownloadFile url={document?.signedDocumentUrl} />
            </div>
        </Grow>
    )
};

export default DocumentViewer;
