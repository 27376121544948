import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import MainContent from 'components/Containers/MainContent';
import HelpForm from './components/HelpForm';
import Stage from './components/Stage';
import SummaryFooter from './components/SummaryFooter';
import ValidationDetailsForm from './components/ValidationDetailsForm';
import { useDispatch } from 'react-redux';
import { actions as AdditionalRequirementsAction } from 'store/modules/additionalRequirements';
export default function CreateValidation() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(AdditionalRequirementsAction.setIsRequiremntAddLoading(false));
        dispatch(AdditionalRequirementsAction.setAdditionalRequirementForm('type', 'Consent'));
        return () => {
            dispatch(AdditionalRequirementsAction.clearAdditionalRequirementForm());
        }
    }, []);

    return (
        <MainContent>
            <Grid>
                <Stage
                    title="Validation Details"
                    description={(
                        <>
                            {/* A Consent or Validation Request can be created here. These can then be enabled to be active for certain customers and locations.
                            <br />
                            <br /> */}
                            Upon requirement submission by the talent, validation/consent from the talent are automatically approved for each employer. As a result, they cannot be rejected or forwarded to agencies.
                        </>
                    )}
                    style={{ borderBottom: "1px solid #aebecd" }}
                    contentSize={6}>
                    <ValidationDetailsForm />
                </Stage>
                <Stage
                    title="Help Text (For Talent)"
                    description={(
                        <>
                            Make it easier for talents to find the information you are looking for and increase their comfort around why you need the info.
                            <br />
                            <br />
                            Structuring this as FAQs makes information digestible
                        </>
                    )}
                    contentSize={6}>
                    <HelpForm />
                </Stage >
                <SummaryFooter />
            </Grid>
        </MainContent>
    )

}
