import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import _debounce from "lodash/debounce";
import { convertUTCToLocal } from "utils/time";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employers";
import { sharedProps } from "../../Worker/WorkerOverview/tables/helpers/tableProps";
import ReactTableCustom from "components/ReactTable";
import { useGeneralTableStyles } from "../helpers";
import classNames from "classnames";


const TableWrapper = (props) => {
    const {
        data,
        totalCount,
        currentPage,
        isLoading,
        onFilteredChange,
        onSortedChange,
        onPageSizeChange,
        onPageChange,
        pageFilter,
        pageSize,
        pageSort
    } = props;
    const fetchData = useCallback(({ pageIndex, filters, pageSize, sortBy }) => {
        onSortedChange(sortBy);
        onFilteredChange(filters);
        onPageSizeChange(pageSize);
        onPageChange(pageIndex);
    }, []);
    const classes = useGeneralTableStyles();

    const customTableProps = (row) => {
        if (row) {
            props.history.push("/employers/" + row.original.id);
        }
    };

    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            style={{ marginTop: "16px" }}
            loading={isLoading}
            data={data}
            defaultFilter={pageFilter}
            defaultPageSize={pageSize}
            pageCount={totalCount}
            currentPage={currentPage}
            defaultSort={pageSort}
            classes={classNames("-highlight", classes.tableStyles)}
            onRowClick={customTableProps}
            columns={[
                {
                    Header: "Company",
                    id: "companyName",
                    accessor: ({ companyName }) => companyName || <i> Empty </i>,
                    className: "-cursor-pointer",
                    width: 300,
                },
                {
                    Header: "First Name",
                    accessor: "firstName",
                    className: "-cursor-pointer",
                },
                {
                    Header: "Last Name",
                    accessor: "lastName",
                    className: "-cursor-pointer",
                },
                {
                    Header: "Email",
                    accessor: "email",
                    className: "-cursor-pointer",
                    width: 250,
                },
                {
                    Header: "City",
                    id: "city",
                    accessor: ({ city }) => city || "-",
                },
                {
                    Header: "Province",
                    id: "region",
                    accessor: ({ region }) => region || "-",
                },
                {
                    Header: "Jobs",
                    accessor: "jobCount",
                    className: "-cursor-pointer",
                    width: 80,
                },

                {
                    Header: "Business Phone",
                    accessor: "businessPhone",
                    className: "-cursor-pointer",
                    disableFilters: true,
                    disableSortBy: true,
                },
                {
                    Header: "Created",
                    id: "createdAtUtc",
                    disableFilters: true,
                    accessor: ({ createdAtUtc }) => convertUTCToLocal(createdAtUtc, "MMM Do, YYYY"),
                },
                {
                    id: "actions",
                    disableFilters: true,
                    disableSortBy: true,
                    collapse: true,
                    style: { textAlign: "right" },
                    Cell: () => (
                        <div style={{ textAlign: "right" }}>
                            <ChevronRight />
                        </div>
                    ),
                },
            ]}
        />
    );
}

class EmployerTable extends React.Component {
    render() {
        return (
            <TableWrapper  {...this.props} />
        );
    }
}

const mapState = state => {
    const { details } = state.employers;
    const setData = details => {
        return Object.keys(details).map(employerId => {
            return {
                ...details[employerId],
            };
        });
    };

    const paginationFunctionList = {
        employerList: EmployerSelectors.getEmployerListPaginationData,
        deactivatedEmployerList: EmployerSelectors.getDeactivatedEmployerListPaginationData,
        unapprovedEmployerList: EmployerSelectors.getUnapprovedEmployerListPaginationData,
    };

    return {
        data: setData(details),
        pageSort: paginationFunctionList.employerList(state).sort,
        pageFilter: paginationFunctionList.employerList(state).filter,
        ...paginationFunctionList.employerList(state).paging,
    };
};

const mapActions = dispatch => {
    return {
        fetchEmployers: (index) => dispatch(EmployerActions.fetchEmployers(index)),
        onPageSizeChange: size => dispatch(EmployerActions.changePageSize(size)),
        onPageChange: idx => dispatch(EmployerActions.fetchEmployers(idx)),
        onSortedChange: sort => dispatch(EmployerActions.changeSort(sort)),
        onFilteredChange: data => dispatch(EmployerActions.changeFilter(data)),
    };
};

export default connect(mapState, mapActions)(withRouter(EmployerTable));
