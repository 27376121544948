import React, { useCallback } from "react";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import { employerPrefersTableStyles } from "views/Employer/helpers";
import Button from "components/Button/Button";
import { formatInitials, formatPhone } from "utils/stringUtils";
import { actions as EmployerDetailActions, selectors as EmployerDetailSelectors } from "store/modules/employerDetails";
import ReactTableCustom from "components/ReactTable";
import { MenuItem, Select } from "@mui/material";


const TableWrapper = ({
    classes,
    isLoading,
    data,
    sort,
    currentPage,
    pageSize,
    onPageChange,
    onSortedChange,
    onFilteredChange,
    onRemoveWorker,
    scrollToEnd,
    totalCount,
    ...props
}) => {
    const fetchData = useCallback(({ pageIndex, filters, sortBy }) => {
        onSortedChange(sortBy);
        onFilteredChange(filters);
        onPageChange(pageIndex);
    }, []);

    return (
        <ReactTableCustom
            fetchData={fetchData}
            classes="-highlight"
            defaultSort={sort}
            data={data}
            loading={isLoading}
            pageCount={totalCount}
            page={currentPage}
            defaultPageSize={pageSize}
            rowsPerPageOptions={{ rowsPerPageOptions: [pageSize] }}
            columns={[
                {
                    Header: "",
                    id: "profileImage",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ signedProfileImageUrl, firstName, lastName }) => {
                        return (
                            <Avatar src={signedProfileImageUrl}>{formatInitials(firstName, lastName, "")}</Avatar>
                        );
                    },

                    disableSortBy: true,
                    disableFilters: true,
                    width: 100,
                },
                {
                    Header: "First Name",
                    id: "firstName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ firstName }) => {
                        if (!firstName) {
                            return <i>Missing first name</i>;
                        }

                        return firstName;
                    },
                },
                {
                    Header: "Last Name",
                    id: "lastName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ lastName }) => {
                        if (!lastName) {
                            return <i>Missing last name</i>;
                        }

                        return lastName;
                    },
                },
                {
                    Header: "Email",
                    id: "email",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ email }) => {
                        if (!email) {
                            return <i>Missing email.</i>;
                        }

                        return email;
                    },
                },
                {
                    Header: "Phone",
                    id: "phone",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ phone }) => {
                        if (!phone) {
                            return <i>Empty.</i>;
                        }

                        return phone;
                    },
                },
                {
                    Header: "Location",
                    id: "city",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ city, region }) => {
                        if (!city || !region) {
                            return <i>Missing location</i>;
                        }

                        return `${city}, ${region}`;
                    },
                },
                // {
                //     // eslint-disable-next-line react/display-name, react/prop-types
                //     Header: "Rating",
                //     id: "rating",
                //     // eslint-disable-next-line react/display-name, react/prop-types
                //     accessor: ({ rating }) => {
                //         if (!rating) {
                //             return <i>Not rated.</i>;
                //         }

                //         return rating;
                //     },
                //     // eslint-disable-next-line react/display-name, react/prop-types
                //     Filter: ({ setFilter, column }) => (
                //         <div
                //             style={{
                //                 textAlign: "center",
                //             }}>
                //             <Select
                //                 disableUnderline={false}
                //                 variant="standard"
                //                 onChange={event => setFilter(column.id, event.target.value.trim())}
                //                 value={column.filterValue ? column.filterValue || " " : " "}>
                //                 <MenuItem value=" ">All</MenuItem>
                //                 <MenuItem value="5">5</MenuItem>
                //                 <MenuItem value="4">4 +</MenuItem>
                //                 <MenuItem value="3">3 +</MenuItem>
                //                 <MenuItem value="2">2 +</MenuItem>
                //             </Select>
                //         </div>
                //     ),


                //     width: 100,
                //     style: { textAlign: "center" },
                //     headerStyle: { textAlign: "center" },
                // },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Site History" placement="top">
                            <span>SH.</span>
                        </Tooltip>
                    ),
                    id: "positionCountByEmployer",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCountByEmployer }) => {
                        if (!positionCountByEmployer && positionCountByEmployer !== 0) {
                            return "-";
                        }

                        return positionCountByEmployer;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Jobs Worked" placement="top">
                            <span>JW.</span>
                        </Tooltip>
                    ),
                    id: "positionCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCount }) => {
                        if (!positionCount && positionCount !== 0) {
                            return "-";
                        }

                        return positionCount;
                    },
                    //
                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Bans" placement="top">
                            <span>B.</span>
                        </Tooltip>
                    ),
                    id: "banCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ banCount }) => {
                        if (!banCount && banCount !== 0) {
                            return "-";
                        }

                        return banCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="No Shows" placement="top">
                            <span>NS.</span>
                        </Tooltip>
                    ),
                    id: "noShowCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ noShowCount }) => {
                        if (!noShowCount && noShowCount !== 0) {
                            return "-";
                        }

                        return noShowCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    Header: () => (
                        <Button
                            buttonClassName={classes.addButton}
                            label="Add"
                            color="transparent"
                            onClick={props.onChooseWorker}
                        />
                    ),
                    id: "actions",
                    collapse: true,
                    style: { textAlign: "right" },
                    disableSortBy: true,
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Cell: ({ row: { original } }) => {
                        return (
                            <Tooltip title="Remove" placement="top">
                                <IconButton
                                    disableRipple
                                    onClick={() => {
                                        onRemoveWorker(original);
                                        scrollToEnd();
                                    }}
                                    size="large">
                                    <RemoveCircle className={classes.remove} />
                                </IconButton>
                            </Tooltip>
                        );
                    },
                },
            ]}
            getTdProps={(row, { column }) => {
                if (row) {
                    if (column.id !== "actions") {
                        // if (
                        //     !rowInfo.original.isSuspended &&
                        //     rowInfo.original.isAvailableForJob &&
                        //     !banned.includes(rowInfo.original.id) &&
                        //     positions.some(
                        //         ({ workerId }) => workerId !== rowInfo.original.id,
                        //     )
                        // ) {
                        //     // onAddWorker(rowInfo.original.id, jobId).then(scrollToEnd);
                        // }
                    }
                }
            }}
        />
    );
}


class EmployerPrefersTable extends React.PureComponent {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const { data, sort, filter } = EmployerDetailSelectors.getPreferredListData(state);
    return {
        data,
        sort,
        filter,
        ...EmployerDetailSelectors.getPreferredWorkersPaginationData(state),
    };
};

const mapActions = {
    fetchPreferredWorkers: EmployerDetailActions.fetchPreferredWorkers,
    onPageChange: EmployerDetailActions.fetchPreferredWorkers,
    onSortedChange: EmployerDetailActions.setPreferredWorkersSort,
    onFilteredChange: EmployerDetailActions.setPreferredWorkersFilter,
};

export default connect(mapState, mapActions)(employerPrefersTableStyles(EmployerPrefersTable));
