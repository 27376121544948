import { Button, ButtonBase, CircularProgress, Dialog, DialogTitle, FormControl, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import RequestNewIcon from 'assets/img/requestNew.svg';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { actions as VerificationsActions } from 'store/modules/verifications';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';
import EmployerRequirementsAutocomplete from 'components/Autocomplete/EmployerRequirementsAutocomplete';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import { allowedDocumentsRenderer } from 'components/DocumentViewer/helpers/constants';
import { useParams } from 'react-router-dom';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 350
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    docContainer: {
        background: '#f7f7fb',
        minWidth: 335,
        width: 335,
        maxWidth: 335,
        height: 214,
        padding: theme.spacing(1.25),
        cursor: 'pointer',
        minHeight: 'min(580px, 90vh)',
        display: 'flex'
    },
    docCard: {
        display: 'flex',
        gap: theme.spacing(5),
        padding: theme.spacing(5),
        position: 'relative'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(3),
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(3)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '100%',
        width: '100%'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    attempt: {
        background: 'rgba(227, 239, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: theme.spacing(0.75, 1.25, 0.75, 1.25),
        marginTop: theme.spacing(1.25),
        '& p': {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '20px',
            color: '#3F454D'
        }
    },
    link: {
        fontSize: 12,
        marginTop: theme.spacing(0.5),
        cursor: 'pointer',
        color: '#2175D9',
        fontWeight: 500,
        '&:hover': {
            color: '#4f96ec',
        }
    }
}));

export default function AlternateDocumentDialog({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const isLoading = useSelector(Selectors.getRejectedAlternateLoading);
    const selectedRequirement = useSelector(Selectors.getAlternateDocDialog);
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const [selectedDoc, setSelectedDoc] = useState(null);
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(selectedRequirement?.data?.signedDocumentUrl);
    const extension = match?.[1];
    const exist = allowedDocumentsRenderer?.find(p => p.mime === extension)
    const params = useParams()
    let workerId;
    const URLParams = new URLSearchParams(history.location.search);
    const type = parseInt(URLParams.get("type"), 10);
    try {
        workerId = params?.id ? window.atob(params.id) : null;
    } catch (er) {
        console.log(er);
    };

    const refreshData = async (param) => {
        return await dispatch(Actions.fetchPerRequirementsData({ workerId, filterBy: type === 0 ? "requirement" : "employer" }, param));
    };
    useEffect(() => {
        if (!open) {
            setReason('');
            setSelectedDoc(null);
        }
    }, [open]);

    const openDoc = () => {
        if (extension === 'pdf') {
            window.open(selectedRequirement?.data?.signedDocumentUrl, '_blank');
            return;
        };
        dispatch(VerificationsActions.setDocumentPreviewDialog(selectedRequirement));
    };

    const handleAlternate = () => {
        dispatch(Actions.requestForAlternateDoc(reason, selectedDoc?.value, refreshData))
    };

    const redirectToAssignPage = () => {
        window.open(`/employers/assign-requirement/${window.btoa(selectedRequirement.ModalType === 'Employer' ?
            selectedRequirement.id :
            selectedRequirement?.data?.requirementHasEmployer?.employer?.id)}`, '_blank')
    }

    return (
        <Dialog maxWidth={'md'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.docCard}>
                <ButtonBase disableRipple={!exist} LinkComponent={'div'} className={classes.docContainer} onClick={() => exist && openDoc()}>
                    <DocumentViewer style={{ objectPosition: 'center' }} type={extension} document={selectedRequirement?.data} />
                </ButtonBase>
                <div className={classes.container}>
                    <div className={classes.modalIconContainer}>
                        {icon || <img src={RequestNewIcon} className={classes.icon} />}
                    </div>
                    <Typography className={classes.title}>
                        Request for an Additional Document for <b>{
                            (selectedRequirement.ModalType === 'Employer' ?
                                selectedRequirement?.data?.employerRequirement?.requirement?.name :
                                selectedRequirement?.name) || '-'}</b> for <b>{
                                    selectedRequirement.ModalType === 'Employer' ?
                                        selectedRequirement.companyName :
                                        selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName
                                }</b>?
                    </Typography>
                    <div>
                        <FormControl fullWidth>
                            <div className={classes.label}>Select document name</div>
                            <EmployerRequirementsAutocomplete
                                extraFilter={[
                                    { field: 'type', value: 'Document', relation: "REQUIREMENT", },
                                    { field: 'id', value: selectedRequirement.ModalType === 'Employer' ? selectedRequirement?.id : selectedRequirement?.data?.requirementHasEmployer?.employer?.id, relation: 'Employer' },
                                    { field: 'label', value: selectedRequirement.ModalType === 'Employer' ? selectedRequirement?.data?.employerRequirement?.workType?.label : selectedRequirement?.data?.requirementHasEmployer?.workType?.label, relation: 'WorkType' },
                                ]}
                                value={selectedDoc}
                                onChange={(val) => setSelectedDoc(val)}
                                defaultOptions={false}
                                menuPortalTarget={document.body}
                                placeholder="Select" />
                            <span onClick={redirectToAssignPage} className={classes.link}>Click here to assign new requiremente for {selectedRequirement.ModalType === 'Employer' ? selectedRequirement?.companyName : selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName}</span>
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: 40 }}>
                            <TextField
                                label="Enter Reason (For Talent)"
                                autoFocus
                                fullWidth
                                placeholder={`Enter reason here`}
                                multiline
                                rows={4}
                                value={reason}
                                onChange={e => {
                                    setReason(e.target.value);
                                }}
                            />
                        </FormControl>
                    </div>
                    {Boolean(selectedRequirement?.data?.childRequirements?.length) && <div className={classes.attempt}>
                        <Typography>{`Alternate attempt left: ${5 - selectedRequirement?.data?.childRequirements?.length}`}</Typography>
                    </div>}
                    <div className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="secondary"
                            disabled={isLoading || !reason?.trim() || !selectedDoc}
                            onClick={handleAlternate}
                        >
                            Submit
                            {isLoading && <CircularProgress size={24} className={classes.progress} />}
                        </Button>
                        <Button
                            onClick={onClose}
                            variant="text"
                            disabled={isLoading}
                            className={classes.button}
                        >
                            Go Back
                        </Button>
                    </div>
                </div>
                <IconButton
                    disabled={isLoading}
                    className={classes.close}
                    onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </div>
        </Dialog>
    )
}
