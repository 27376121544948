import types from "./types";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import * as pagination from "utils/graphql-pagination";

const initialLoadingState = {
    isLoading: false,
    workerPositions: false,
    requirementDetail: false,
    bulkApprove: false,
    approveRequirement: false,
    rejectRequirement: false,
    approveDocumentRequirement: false,
    rejectDocumentRequirement: false,
    sentAgencyLoading: false,
    rejectedAdditionalDocLoading: false,
    rejectedAlternateLoading: false,
    resubmissionLoading: false,
};

const initialDialogState = {
    approveAll: false,
    sentToAgency: false,
    approve: false,
    reject: false,
    rejectedInfo: false,
    documentRejectedInfo: false,
    rejectedAdditionalDoc: false,
    documentApprove: false,
    documentPreview: false,
    documentReject: false,
    alternateDoc: false,
    resubmission: false,
};

const initialListState = {
    sort: [
        { id: "id", desc: true }
    ],
    filter: [],
    data: [],
};


const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    jobs: { ...initialListState },
    selectedJob: null,
    jobPositions: [],
    selectedWorker: null,
    requirements: [],
    requirementsSelection: [],
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_INITIAL_STATE]: () => {
        return {
            ...initialState
        };
    },
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_SELECTED_JOB]: (state, { payload }) => {
        const { selectedJob } = payload;
        return {
            ...state,
            selectedJob,
            selectedWorker: null
        };
    },
    [types.SET_SELECTED_WORKER]: (state, { payload }) => {
        const { selectedWorker } = payload;
        return {
            ...state,
            selectedWorker
        };
    },
    [types.SET_SELECTED_REQUIREMENTS]: (state, { payload }) => {
        const { requirements } = payload;
        return {
            ...state,
            requirements
        };
    },
    [types.SET_SELECT_REQUIREMENTS_FOR_BULK_ACTION]: (state, { payload }) => {
        const { requirement, isClear } = payload;
        if (isClear) {
            return {
                ...state,
                requirementsSelection: []
            };
        };

        const requirementsSelection = [...state.requirementsSelection];
        const ind = requirementsSelection?.findIndex(a => a?.id === requirement?.id);
        if (ind >= 0) {
            requirementsSelection.splice(ind, 1);
        } else {
            requirementsSelection.push(requirement);
        }
        return {
            ...state,
            requirementsSelection
        };
    },
    [types.SET_JOB_APPLICATIONS_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        if (part === 'data') {
            const pageInfo = state[pagination.getPageInfoAttr(paginationSettings.jobApplications)];
            if (pageInfo.currentPage === 0) {
                const selectedJob = data.find(a => a?.job?.id === state?.selectedJob?.id)?.job;
                return ({
                    ...state,
                    jobs: {
                        ...state.jobs,
                        data,
                    },
                    selectedJob,
                    ...(selectedJob ? {} : { selectedWorker: null, requirements: [] })
                });
            }
            const selectedJob = [...(state?.jobs?.data || []), ...(data || [])].find(a => a?.job?.id === state?.selectedJob?.id)?.job;
            return ({
                ...state,
                jobs: {
                    ...state.jobs,
                    data: [...(state?.jobs?.data || []), ...(data || [])],
                },
                selectedJob,
                ...(selectedJob ? {} : { selectedWorker: null, requirements: [] })
            });
        }
        return {
            ...state,
            jobs: {
                ...state.jobs,
                [part]: data
            },
        };
    },
    [types.SET_JOB_POSITIONS]: (state, { payload }) => {
        const { jobPositions } = payload;

        return {
            ...state,
            jobPositions
        };
    },
    ...pagination.createPaginationActions(paginationSettings),
}

export default createReducer(initialState, actionsMap);
