import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { AppBar, Button, Chip, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "24px 32px 12px",
        background: theme.palette.background.paper,
        borderBottom: 0,
        position: "relative",
        width: "100%",
        zIndex: 1029,
        color: "#555555",
        border: 0,
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        transition: "all 150ms ease 0s",
        minHeight: 80,
        display: "block",
        marginBottom: 0
    },
    groups: {
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'wrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
        justifyContent: 'flex-end',
    },
    headerTitle: {
        color: '#3F454D',
    },
    headerSubTitle: {
        color: '#6B6F75',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: theme.spacing(2)
    },
    more: {
        marginLeft: 'auto',
        background: 'transparent !important',
        whiteSpace: 'nowrap'
    }
}));

export default function RequirementDetailHeader() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const requirementDetails = useSelector(Selectors.getRequirementDetails);

    const handleExpandClick = () => {
        setExpanded(p => !p);
    };

    return (
        <>
            <AppBar className={classes.container}>
                <div className={classes.header}>
                    <div>
                        <Typography className={classes.headerTitle} variant="h5" letterSpacing={0.015}>{requirementDetails?.name}</Typography>
                        <Typography className={classes.headerSubTitle}>{requirementDetails?.type === 'Document' ? requirementDetails?.description : requirementDetails?.summary}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '70%', gap: 8 }}>
                        {Boolean(requirementDetails?.groups?.length) && <div className={classes.groups}>
                            {requirementDetails?.groups?.map(group => (
                                <Chip key={group.id} label={group.name} />
                            ))}
                        </div>}
                        {requirementDetails?.type !== 'Document' && (
                            <>
                                <Button
                                    disableRipple
                                    disableFocusRipple
                                    className={classes.more}
                                    onClick={handleExpandClick}
                                    variant="text"
                                    endIcon={expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                                    color='secondary'>
                                    {expanded ? `Hide ${requirementDetails?.type} Description` : `View ${requirementDetails?.type} Description`}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div style={{ marginTop: 16 }}>
                        <Typography paragraph style={{ fontWeight: 500 }}>
                            Description *
                        </Typography>
                        <Typography paragraph>
                            <div dangerouslySetInnerHTML={{ __html: requirementDetails?.description }} />
                        </Typography>
                    </div>
                </Collapse>
            </AppBar>
        </>
    )
}
