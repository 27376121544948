import React, { useState } from 'react'
import classNames from 'classnames';
import { Info } from "@mui/icons-material";
import { Box, ClickAwayListener, Divider, Grid, Grow, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import { formatMoney } from 'utils/stringUtils';


const CustomTableCell = withStyles({
    root: {
        border: "none",
        padding: "2px 4px",
        fontSize: 13,
    },
    head: {
        fontSize: 14,
        color: "#404b5a",
    },
    body: {
        fontSize: 12,
        padding: "4px",
    }
})(TableCell);

const CustomTableRow = withStyles({
    head: {
        height: 24,
        borderBottom: `0.7px solid lightgrey`,
    },
})(TableRow);

const useStyles = makeStyles(({ spacing, palette }) => ({
    popup: {
        position: 'absolute',
        top: 28,
        right: 0,
        zIndex: 1,
        width: 420,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        background: palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: spacing(1),
        color: `${palette.text.primary} !important`,
        '&::before': {
            content: '""',
            width: 18,
            height: 18,
            transform: 'rotate(45deg)',
            background: '#fff',
            position: 'absolute',
            top: -10,
            right: 30,
            borderLeft: '1px solid #ccc',
            borderTop: '1px solid #ccc',
        },
        '& .MuiBox-root': {
            padding: spacing(2),
            position: 'relative',
            background: palette.background.paper,
            borderRadius: spacing(1),
        }
    }
}));

const TotalPayableBreakups = ({ breakups, ...props }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'relative' }}>
                <Typography
                    type="button"
                    variant="caption"
                    color="textSecondary"
                    sx={{
                        marginBottom: 1,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={handleClick}>
                    <Info fontSize='small' />&nbsp;Click here for breakup
                </Typography>
                <Grow
                    in={open}
                    style={{ transformOrigin: '100% 0 0' }}
                >
                    <Box className={classNames(classes.popup, props.className)}>
                        <Box>
                            <Table size="small">
                                <TableHead>
                                    <CustomTableRow>
                                        <CustomTableCell>Type</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>Units</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>Rate</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        breakups?.estimatedWorkerPaymentBreakUp.map((record, i) => {
                                            return (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {record.type}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {record.units} hrs
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(record.rate)}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(record.amount)}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                            <br />
                            <Grid container>
                                <Grid item xs="auto">
                                    <Typography variant='caption'>
                                        *&nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant='caption'>
                                        This amount is an estimate, the actual amount will be determined after payment has been done.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grow>
            </Box>
        </ClickAwayListener>
    )
}

export default TotalPayableBreakups;
