import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.workers.loading[loader] || false;
};

export const getFutureJobLoading = getLoadingStateFactory("futureJob");
export const getIsOpenWorkerIdentityRejectDialog = getLoadingStateFactory("workerIdentityRejectDialog");
export const getSelectedWorkerIdentityId = getLoadingStateFactory("selectedWorkerIdentityId");
export const getIsOpenWorkerWorkPermitRejectDialog = getLoadingStateFactory("workerWorkPermitRejectDialog");
export const getSelectedWorkerWorkPermitId = getLoadingStateFactory("selectedWorkerWorkPermitId");
export const getWorkerIdentityRejectReason = (state) => state?.workers?.workerIdentityRejectReason;


export const getWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.workerList)],
    sort: state.workers.pageSort,
    filter: state.workers.pageFilter,
    params: { isDeleted: false },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.workerList),
  };
};

export const canSubmitForm = state => {
  if (
    state.workers.addWorkerData.firstName &&
    state.workers.addWorkerData.lastName &&
    state.workers.sinIsValid &&
    state.workers.emailIsAvailable &&
    state.workers.emailIsValid
  ) {
    return true;
  }
  return false;
};

export const getVerifiedWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.verifiedWorkerList)],
    sort: state.workers.verifiedPageSort,
    filter: state.workers.verifiedPageFilter,
    params: { isApproved: true },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.verifiedWorkerList),
  };
};

export const getUnverifiedWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.unverifiedWorkerList)],
    sort: state.workers.unverifiedPageSort,
    filter: state.workers.unverifiedPageFilter,
    params: { isApproved: false },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.unverifiedWorkerList),
  };
};

export const getDeactivatedWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.deactivatedWorkerList)],
    sort: state.workers.deactivatedPageSort,
    filter: state.workers.deactivatedPageFilter,
    params: { isActive: false },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.deactivatedWorkerList),
  };
};

export const getSuspendedWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.suspendedWorkerList)],
    sort: state.workers.suspendedPageSort,
    filter: state.workers.suspendedPageFilter,
    params: { isSuspended: true },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.suspendedWorkerList),
  };
};
export const getInCompleteWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.inCompleteWorkerList)],
    sort: state.workers.inCompletePageSort,
    filter: state.workers.inCompletePageFilter,
    params: {  },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.inCompleteWorkerList),
  };
};

export const getAppliedWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.appliedWorkerList)],
    sort: state.workers.appliedPageSort,
    filter: state.workers.appliedPageFilter,
    params: { isApplied: true },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.appliedWorkerList),
  };
};

export const getIdentityWorkerListPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.identityWorkerList)],
    sort: state.workers.identityPageSort,
    filter: state.workers.identityPageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.identityWorkerList),
  };
};

export const workerBannedByPageInfo = state =>
  state.workers[pagination.getPageInfoAttr(paginationSettings.workerBannedBy)];

export const getWorkerBansSortData = state => state.workers.pageSort;

export const getWorkerBansFilterData = state => state.workers.pageFilter;

export const getWorkerPreferredPaginationData = state =>
  state.workers[pagination.getPageInfoAttr(paginationSettings.workerPreferredBy)];

export const getWorkerPreferredSortData = state => state.workers.pageSort;

export const getWorkerPreferredFilterData = state => state.workers.pageFilter;
export const getWorkerFutureJobData = state => state.workers.workerFutureJobsList;

export const getWaitlistTomorrowPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.waitlistTomorrow)],
    sort: state.workers.waitlistTomorrowSort,
    filter: state.workers.waitlistTomorrowFilter,
    params: {
      isSuspended: false,
      isActive: true,
      isApproved: true,
      canDrive: true,
      canWorkMorning: true,
      canWorkEvening: true,
      onWaitlist: "Tomorrow",
    },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.waitlistTomorrow),
  };
};

export const getWaitlistTodayPagingData = state => {
  return {
    paging: state.workers[pagination.getPageInfoAttr(paginationSettings.waitlistToday)],
    sort: state.workers.waitlistTodaySort,
    filter: state.workers.waitlistTodayFilter,
    params: {
      isSuspended: false,
      isActive: true,
      isApproved: true,
      canDrive: true,
      canWorkMorning: true,
      canWorkEvening: true,
      onWaitlist: "Today",
    },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.waitlistToday),
  };
};


export const getWorkPermitWorkerListPagingData = state => {
    return {
      paging: state.workers[pagination.getPageInfoAttr(paginationSettings.workPermitWorkerList)],
      sort: state.workers.workPermitPageSort,
      filter: state.workers.workPermitPageFilter,
      params: { },
      pagingVars: pagination.pagingVarsFactory(paginationSettings.workPermitWorkerList),
    };
  };
