/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import Icon from "@mui/material/Icon";
import { NoteAdd } from "@mui/icons-material";
const isMobile = false;
const styles = ({ spacing }) => ({
    modal: {
        padding: isMobile ? spacing(2) : `${spacing(2)} ${spacing(6)}`,
        // paddingBottom: isMobile ? spacing(2) : spacing(1),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60),
        overflowY: "unset",
    },
    editEmployerModal: {
        padding: isMobile ? spacing(2) : spacing(4),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: 'auto',
    },
    qbConfirmModal: {
        padding: spacing(2),
        alignItems: "center",

        overflowY: "unset",
    },
    title: {
        padding: `${spacing(3)} 0 ${spacing(1)} 0`,
        alignItems: "flex-start",
        width: "100%",
        marginTop: isMobile ? "inherit" : spacing(-2),
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        // overflowY: "auto",
        overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 0,
        zIndex: 0
    },
});

const variantIcon = {
    success: "check_circle",
    warning: "error",
    warningWithTwoConfirmButtons: "error",
    error: "cancel",
    cancelTransfer: "account_balance_wallet_two_tone",
    info: "info_outline",
    question: "help_outline",
    save: "help_outline",
};

function Dialog(props) {
    const {
        size,
        classes,
        cancelText,
        disabled,
        confirmText,
        secondConfirmText,
        title,
        description,
        icon,
        onConfirm,
        onSecondConfirm,
        onCancel,
        onClose,
        open,
        disableBackdropClick,
        alertType,
        modalType,
        children,
        className,
        qbIcon,
        formContent,
        hideActions,
        payload,
        pmt,
        fullWidth,
        showPlaceholderIcon,
        isCancelIconButtonRequired,
        isLoading,
        isSecondLoading
    } = props;

    const variantFooter = {
        success: (
            <Button
                label={confirmText || "Got It"}
                onClick={onConfirm}
                isLoading={isLoading}
                color="secondary"
                disabled={disabled}
            />
        ),
        cancelTransfer: (
            <React.Fragment>
                <Button label={cancelText || "close"} onClick={onCancel} color="transparent" />
                <Button
                    label={confirmText || "Cancel eTransfer"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="error"
                    disabled={disabled}
                />
            </React.Fragment>
        ),
        warning: (
            <React.Fragment>
                <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
                <Button
                    label={confirmText || "Yes"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="error"
                    disabled={disabled}
                />
            </React.Fragment>
        ),
        warningWithTwoConfirmButtons: (
            <React.Fragment>
                <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
                <Button
                    label={confirmText || "Yes"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="error"
                    disabled={disabled}
                />
                <Button
                    label={secondConfirmText || "Yes"}
                    onClick={onSecondConfirm}
                    isLoading={isSecondLoading}
                    color="error"
                    disabled={disabled}
                />
            </React.Fragment>
        ),
        error: <Button label={confirmText || "Ok"} onClick={onConfirm} isLoading={isLoading} color="secondary" />,
        info: (
            <Button
                label={confirmText || "Ok"}
                onClick={onConfirm}
                isLoading={isLoading}
                color="secondary"
                disabled={disabled}
            />
        ),
        question: (
            <React.Fragment>
                <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
                <Button
                    label={confirmText || "Yes"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="secondary"
                    disabled={disabled}
                />
            </React.Fragment>
        ),
        save: (
            <React.Fragment>
                <Button label={cancelText || "Cancel"} onClick={onCancel} color="error" />
                <Button
                    label={confirmText || "Yes"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="secondary"
                    disabled={disabled}
                />
            </React.Fragment>
        ),
        form: (
            <React.Fragment>
                <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
                <Button
                    disabled={disabled}
                    isLoading={isLoading}
                    label={confirmText || "Update"}
                    onClick={onConfirm || ''}
                    color="secondary"
                />
            </React.Fragment>
        ),
    };

    return (
        <MUIDialog
            classes={{
                paper: qbIcon
                    ? classes.qbConfirmModal
                    : modalType === "editEmployer"
                        ? classes.editEmployerModal
                        : classes.modal,
            }}
            className={className}
            open={open}
            fullWidth={fullWidth ? fullWidth : false}
            fullScreen={isMobile}
            disablePortal={true}
            style={{ alignItems: "flex-start" }}
            maxWidth={size ? size : "md"}
            size={size ? size : "sm"}
            disableBackdropClick={disableBackdropClick}
            onClose={onClose}>
            {alertType !== "form" && !qbIcon && (
                <Icon color="disabled" style={{ fontSize: props.iconFontSize ? props.iconFontSize : 96 }}>
                    {icon ? icon : variantIcon[alertType]}
                    {/* {qbIcons.invoice} */}
                </Icon>
            )}

            {formContent && showPlaceholderIcon && <NoteAdd fontSize="large" color="secondary" />}

            {qbIcon && <img src={qbIcon} width={162} style={{ marginRight: 0 }} />}
            {title && <DialogTitle className={classes.title}>
                <Typography variant="h5" align="center">
                    {title}
                    {isCancelIconButtonRequired && <Button
                        style={{ float: "right", marginRight: -30 }}
                        label="X"
                        size="small"
                        onClick={onCancel}
                        color="transparent" />}
                </Typography>
            </DialogTitle>}

            <DialogContent
                className={classes.content}
                style={{ textAlign: "center", maxWidth: isMobile ? "100%" : "100%" }}>
                {description ? (
                    <Typography variant="body1" align="center" justifyContent="center">
                        {description}
                    </Typography>
                ) : null}
                {children ? <div>{children}</div> : null}
                {formContent ? <div>{formContent}</div> : null}
            </DialogContent>

            <DialogActions
                style={{
                    visibility: hideActions ? "hidden" : "visible",
                    height: hideActions ? 0 : "inherit",
                    ...(hideActions ? { padding: 0 } : {}),
                }}
                hidden
                className={classes.footer}>
                {variantFooter[alertType]}
            </DialogActions>
        </MUIDialog>
    );
}

export default withStyles(styles)(Dialog);
