import {gql} from "@apollo/client";

export default gql`
  query calculateEmployerBill($data: CalculateEmployerBillInput!) {
    calculateEmployerBill(data: $data) {
        hourlyPay
        dailyDTRate
        dailyOTRate
        regularPayHours
        overtimePay
        overtimePayHours
        doublePay
        doublePayHours
        breakPay
        gfFee
        gstAmount
        gstPercentage
        total
        timeBasePremium
        timeBasePremiumHours
        taxes{
          label
            percentage
            amount
        }
    }
  }
`;
