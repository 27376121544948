import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";

const useStyles = makeStyles(() => ({
    textField: {
        "& fieldset": {
            borderColor: "#262222"
        }
    }
}));

export default function WorkOrderNumberColumn({ row: { original, ...row } }) {
    const isEditWorkOrderNumber = useSelector(ReportsSelectors.getIsEditableWorkOrderNumber);
    const record = useSelector((state) => ReportsSelectors.getWorkOrderNumberByPositionId(state, original.positionId));
    const classes = useStyles();
    const dispatch = useDispatch();
    const isUpdating = useSelector(ReportsSelectors.getIsUpdateWorkOrderNumberLoading);

    const handleChange = ({ target: { value } }) => {
        dispatch(ReportsActions.changeWorkOrderNumbers({ value, positionId: original.positionId }));
    };


    return (
        <div>
            {
                isEditWorkOrderNumber ?
                    <TextField
                        key={original.positionId}
                        className={classes.textField}
                        color='secondary'
                        disabled={isUpdating}
                        onChange={handleChange}
                        autoFocus={!row.index}
                        value={record?.workOrderNumber}
                        defaultValue={original.WorkOrderNumber}
                    />
                    : original.WorkOrderNumber || "-"
            }
        </div>
    )
}
