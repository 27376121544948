// Define a default UI for filtering
import React, { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import { useParams } from 'react-router-dom';


function DefaultTextFilter() {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const pageInfo = useSelector(Selectors.getAssignedEmployerListPagingData);
    const appliedFilters = useSelector(state => Selectors.getAssignedEmployerData(state, { part: "filter" }));
    const params = useParams();

    const debouncedFunction = useCallback(debounce((e) => {
        const findInd = appliedFilters?.length && appliedFilters.findIndex(a => a.id === 'companyName');
        if (e.target.value)
            dispatch(Actions.setAssignedEmployerListFilter(findInd >= 0 ? Object.assign([...appliedFilters], { [findInd]: { id: 'companyName', value: e.target.value } }) : [...appliedFilters, { id: 'companyName', value: e.target.value }]));
        else
            dispatch(Actions.setAssignedEmployerListFilter(appliedFilters?.filter(a => a.id !== 'companyName')));
        dispatch(Actions.fetchAssignedEmployerTableData(pageInfo?.currentPage, window.atob(params?.id)));
    }, 1200), [appliedFilters, pageInfo?.currentPage, params?.id]);

    return (
        <TextField
            style={{ width: 300, maxWidth: '50%' }}
            placeholder="Search Employer Name"
            value={value || ''}
            onChange={e => {
                setValue(e.target.value)
                debouncedFunction(e);
            }}
            InputProps={{
                startAdornment: <Search />
            }} />
    );
}
export default DefaultTextFilter;
