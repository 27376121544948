import React from 'react'
import { useDispatch } from 'react-redux';
import CustomizedEmployerAutocomplete from 'components/Autocomplete/CustomizedEmployerAutocomplete'
import { actions as Actions } from "store/modules/additionalRequirements";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    autocomplete: {
        maxWidth: 250,
        width: 250,
        '& p.MuiTypography-root': {
            whiteSpace: 'nowrap',
            maxWidth: '95%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}));

export default function SelectEmployer(props) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const onEmployerChange = (emp) => {
        dispatch(Actions.updateAssignedEmployerFormData(props.row.index, 'employer', emp))
    };

    return (
        <div>
            <CustomizedEmployerAutocomplete
                className={classes.autocomplete}
                menuPortalTarget={document.body}
                placeholder="Select or Enter"
                value={props?.row?.original?.employer}
                onChange={onEmployerChange} />
        </div>
    );
};
