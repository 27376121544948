import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";
import moment from "moment";

const initialLoadingState = {
    isAddLoading: false,
    isEditing: false,
    isDetailLoading: false,
};

const initialDialogState = {
    selectedRecordForRemove: null
};

const initialListState = {
    sort: [
        { id: "id", desc: true }
    ],
    filter: [],
    data: [],
};

const initialFormData = {
    type: '',
    name: '',
    frenchName: '',
    summary: '',
    frenchSummary: '',
    description: '',
    frenchDescription: '',
    title: '',
    frenchTitle: '',
    isConsent: false,
    isDocHaveExpiry: false,
    groupNames: [],
    helpTexts: [{
        question: '',
        answer: '',
        frenchQuestion: '',
        frenchAnswer: '',
    }],
};

const initialNewList = { employer: null, workType: null, validity: { type: 'days', value: null }, isMandatory: true };

const initialState = {
    requirementList: { ...initialListState },
    dialog: { ...initialDialogState },
    loading: { ...initialLoadingState },
    form: { ...initialFormData },
    requirementDetails: null,
    activeTab: 0,
    assignNewEmployerList: [{ ...initialNewList }],
    assignedEmployerList: { ...initialListState },
    existingRecords: [],
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_EDIT_FORM_VALUES]: (state, { payload }) => {
        const { form } = payload;
        return {
            ...state,
            form
        };
    },
    [types.SET_FORM_VALUES]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            form: {
                ...state.form,
                [key]: value,
            },
        };
    },
    [types.CLEAR_FORM]: (state) => {
        return {
            ...state,
            form: {
                ...initialFormData
            },
        };
    },
    [types.SET_ADDITIONAL_REQUIREMENTS_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        return {
            ...state,
            requirementList: {
                ...state.requirementList,
                [part]: data
            },
        };
    },
    [types.SET_ADDITIONAL_REQUIREMENTS_FILTER]: (state, { payload }) => {
        const { filter } = payload;
        return {
            ...state,
            requirementList: {
                ...state.requirementList,
                filter
            },
        };
    },
    [types.SET_ADDITIONAL_REQUIREMENT_DETAILS]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            requirementDetails: data
        };
    },
    [types.SET_ACTIVE_TAB]: (state, { payload }) => {
        const { activeTab } = payload;
        return {
            ...state,
            activeTab,
        };
    },
    [types.SET_ASSIGNED_EMPLOYERS_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        return {
            ...state,
            assignedEmployerList: {
                ...state.assignedEmployerList,
                [part]: data
            },
        };
    },
    [types.SET_ADD_NEW_ROW]: (state) => {
        return {
            ...state,
            assignNewEmployerList: [...state.assignNewEmployerList, { ...initialNewList }],
        };
    },
    [types.SET_REMOVE_ROW]: (state, { payload }) => {
        const { index } = payload;
        const data = [...state.assignNewEmployerList];
        data.splice(index, 1);
        return {
            ...state,
            assignNewEmployerList: [...data],
        };
    },
    [types.UPDATE_ASSIGNED_EMPLOYER_FORM]: (state, { payload }) => {
        const { index, key, data } = payload;
        let find;
        if (key === 'employer') {
            find = state.assignNewEmployerList.find((a, i) => index !== i && a?.employer?.value === data?.value && a?.workType?.value === state.assignNewEmployerList[index]?.workType?.value);
        };
        return {
            ...state,
            assignNewEmployerList: Object.assign([...state.assignNewEmployerList], {
                [index]: {
                    ...state.assignNewEmployerList[index],
                    [key]: data,
                    ...(find ? { workType: null } : {})
                }
            })
        };
    },
    [types.CLEAR_ASSIGNED_FORM]: (state) => {
        return {
            ...state,
            assignNewEmployerList: [{ ...initialNewList }],
        };
    },
    [types.SET_EXISTING_RECORDS]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            existingRecords: data,
        };
    },
    [types.SET_ALL_ASSIGNED_EMPLOYERS]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            assignNewEmployerList: data?.length ? data?.map(a => ({
                workType: {
                    value: a.workType?.id,
                    label: a.workType?.label
                },
                validity: {
                    type: a.validityType,
                    value: a.validityType === "date" ? moment(a.validityValue)?.isValid() ? moment(a.validityValue) : moment(a.validityValue, 'DD/MM/YYYY') : a.validityValue
                },
                isMandatory: a.isMandatory,
                id: a?.requirement?.id,
                name: a?.requirement?.name,
                type: a?.requirement?.type,
                employer: {
                    data: a?.employer,
                    label: a?.employer?.companyName,
                    value: a?.employer?.id
                }
            })) : [{ ...initialNewList }]
        };
    },
    ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
