import { gql } from "@apollo/client";

export const GetRequirements = gql`
query getRequirements(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [RequirementFilterInput!],
    $order: [RequirementOrderInput!],
    ) {
  getRequirements(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        name
        frenchName
        summary
        frenchSummary
        title
        frenchTitle
        description
        frenchDescription
        type
        createdAt
        groups{
            id
            name
        }
      }
    }
  }
}
`;

export const GetGroups = gql`
query getGroups(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [FilterInput!],
    $order: [OrderInput!],
    ) {
  getGroups(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        name
        slug
      }
    }
  }
}
`;

export const RequirementDetailsById = gql`
query getRequirement($id:Int!){
    getRequirement(id:$id){
        id
        name
        frenchName
        summary
        frenchSummary
        title
        frenchTitle
        description
        frenchDescription
        isConsent
        isDocHaveExpiry
        createdBy
        type
        groups{
            id
            name
        }
        helpTexts{
            id
            question
            frenchQuestion
            answer
            frenchAnswer
        }
    }
}
`;



export const GetAssignedEmployers = gql`
query getEmployerRequirements(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [AssignmentFilterInput!],
    $order: [AssignmentOrderInput!],
    ) {
  getEmployerRequirements(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        validityType
        validityValue
        isMandatory
        createdAt
        employer{
            firstName
            lastName
            city
            id
            region
            companyName
        }
        workType{
            id
            label
        }
        requirement{
            id
            type
            name
            title
        }
      }
    }
  }
}
`;
