import { makeStyles } from '@mui/styles';
import MainContent from 'components/Containers/MainContent';
import SubNav from 'components/Navigation/SubNav'
import SubNavTab from 'components/Navigation/SubNavTab'
import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views';
import history from 'utils/history';
import NeedAttentions from './NeedAttentions';
import { useDispatch } from 'react-redux';
import { actions as Actions } from 'store/modules/verifications';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#f7f7fb',
        flex: 1,
    },
    badgeCount: {
        border: '2px solid #11b2a8',
        padding: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 'normal',
        borderRadius: 50,
        paddingLeft: 8,
        paddingRight: 8,
        color: '#11b2a8',
        fontWeight: 600
    },
    MainContent: {
        padding: 0
    }
}));

export default function Verifications() {
    const URLParams = new URLSearchParams(history.location.search);
    const tab = parseInt(URLParams.get("tab"), 10);
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.setInitialState());
        }
    }, []);

    useEffect(() => {
        if (history.location.search) {
            if (!isNaN(tab)) {
                setActiveTab(parseInt(tab, 10));
            }
        } else {
            history.replace(`${window?.location?.pathname}?tab=${0}`);
        };
    }, [tab]);

    const handleChangeTab = (_, value) => {
        history.replace(`${window?.location?.pathname}?tab=${value}`);
        setActiveTab(value);
    };

    return (
        <div className={classes.root}>
            <SubNav
                value={activeTab}
                onChange={handleChangeTab}
                tabs={[
                    <SubNavTab key="needAttention" label="Needs Attention" count={null} />,
                    <SubNavTab key="sentToAgency" label="Sent To Agency" count={null} />, //<span className={classes.badgeCount}>02</span>
                    <SubNavTab key="reSubmission" label="Re-submission" count={null} />, //<span className={classes.badgeCount}>02</span>
                    <SubNavTab key="approved" label="Approved" count={null} />,
                    <SubNavTab key="rejected" label="Rejected" count={null} />,
                ]}
            />
            <MainContent className={classes.MainContent}>
                <SwipeableViews axis="x" index={+activeTab}>
                    {tab === 0 ? <NeedAttentions /> : <></>}
                    {tab === 1 ? <NeedAttentions /> : <></>}
                    {tab === 2 ? <NeedAttentions /> : <></>}
                    {tab === 3 ? <NeedAttentions /> : <></>}
                    {tab === 4 ? <NeedAttentions /> : <></>}
                </SwipeableViews>
            </MainContent>
        </div>
    )
}
