import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import { Document, Image, Link, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import Logo from "assets/img/logo.png";
import { invoicePdfDialogStyles, pdfTableStyles, pdfTemplateStyles, viewerStyle } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";

class PdfService extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultDocumentProps: {
        title: "Randstad Weekly Invoice",
        subject: "Weekly Invoice",
        keywords: "Weekly Invoice",
        creator: "Weekly Invoice",
        producer: "Weekly Invoice",
      },
    };
  }

  renderPdfTimesheet = props => {
    const { shifts, employer } = props;

    const {
      table,
      tableHeaderRow,
      tableFooterRow,
      tableRow,
      tableHeaderCell,
      tableCell,
      tableCol1,
      tableCol2,
      tableCol3,
      tableCol4,
      colWidth10,
    } = pdfTableStyles;
    const HeaderComponent = () => (
      <View style={pdfTemplateStyles.content}>
        <View style={pdfTemplateStyles.leftBlock}>
          <Image style={pdfTemplateStyles.logo} src={Logo} />
        </View>
        <View style={pdfTemplateStyles.rightBlock}>
          <Text style={pdfTemplateStyles.billingAddress}>
            {process.env.REACT_APP_ACCOUNT_ADDRESS_STREET}
          </Text>
          <Text style={pdfTemplateStyles.billingAddress}>
            {`${process.env.REACT_APP_ACCOUNT_ADDRESS_CITY}, ${process.env.REACT_APP_ACCOUNT_ADDRESS_REGION}`}
          </Text>
          <Link style={pdfTemplateStyles.billingAddress}>
            {process.env.REACT_APP_ACCOUNT_ADDRESS_email}
          </Link>
        </View>
      </View>
    );
    const EmployerDetailsComponent = () => (
      // <View style={pdfTemplateStyles.content}>
      <View style={pdfTemplateStyles.employerBlock}>
        <Text style={pdfTemplateStyles.employerName}>{employer.companyName}</Text>
        <Text style={pdfTemplateStyles.employerAddress}>{employer.street},</Text>
        <Text style={pdfTemplateStyles.employerAddress}>
          {employer.city}, {employer.region}. {employer.postalCode}
        </Text>
      </View>
    );

    const renderFooter = () => (
      <View style={tableFooterRow}>
        <View style={tableCol1}>
          <Text style={tableCell}> </Text>
        </View>
        <View style={tableCol2} />
        <View style={tableCol3} />
        <View style={tableCol4} />

        <View style={colWidth10} />

        <View style={colWidth10} />
        <View style={colWidth10}>
          {/* <Text style={tableCell}>{shifts.sum("totalUnits")} hrs</Text> */}
        </View>
        <View style={colWidth10}>
          {/* <Text style={tableCell}>{shifts.sum("breakDeductionMins") / 60} hrs </Text> */}
        </View>
        <View style={colWidth10}>
          {/* <Text style={tableCell}>{formatMoney(shifts.sum("total"))} </Text> */}
          <Text style={tableCell}>{formatNumber(shifts.sum("invoicableUnits"))} Hours </Text>
        </View>
      </View>
    );
    const renderTableRows = () =>
      shifts.map((shift, i) => (
        <View key={i} style={tableRow}>
          <View style={tableCol1}>
            <Text style={tableCell}>{shift.jobId}</Text>
          </View>
          <View style={tableCol2}>
            <Text style={tableCell}>{moment(shift.checkIn).format("MMM DD, YYYY")}</Text>
          </View>
          <View style={tableCol3}>
            <Text style={tableCell}>{shift.worker}</Text>
          </View>
          <View style={tableCol4}>
            <Text style={tableCell}>
              {moment.utc(shift.checkIn).format("h:mm a")} -
              {moment.utc(shift.checkOut).format("h:mm a")}
            </Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatNumber(shift.totalUnits)} hrs</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatNumber(shift.breakDeductionMins) || 0} mins</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatNumber(shift.invoicableUnits)} hrs</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatMoney(shift.rate)}</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatMoney(shift.total)} </Text>
          </View>
        </View>
      ));

    const renderHeader = () => (
      <View style={tableHeaderRow}>
        <View style={tableCol1}>
          <Text style={tableHeaderCell}>Job Id</Text>
        </View>
        <View style={tableCol2}>
          <Text style={tableHeaderCell}>Date</Text>
        </View>
        <View style={tableCol3}>
          <Text style={tableHeaderCell}>Worker</Text>
        </View>
        <View style={tableCol4}>
          <Text style={tableHeaderCell}>Shift</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Length</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Break</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Hours</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Rate</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Total</Text>
        </View>
      </View>
    );

    const StaffingSummaryTableComponent = () => {
      return (
        <View style={table}>
          {renderHeader()}
          {renderTableRows()}
          {renderFooter()}
          {/* {renderPST()}
          {renderGST()}
          {renderTotal()} */}
        </View>
      );
    };

    const renderedDoc = (
      <Document {...this.state.defaultDocumentProps}>
        <Page size="LETTER" wrap={true}>
          <HeaderComponent />
          <EmployerDetailsComponent />
          <StaffingSummaryTableComponent />
        </Page>
      </Document>
    );

    return renderedDoc;
  };

  renderPdfInvoice = props => {
    const { shifts, employer } = props;
    const {
      table,
      tableHeaderRow,
      tableFooterRow,
      tableRow,
      tableHeaderCell,
      tableCell,
      tableCol1,
      tableCol2,
      tableCol3,
      tableCol4,
      colWidth10,
    } = pdfTableStyles;

    const HeaderComponent = () => (
      <View style={pdfTemplateStyles.content}>
        <View style={pdfTemplateStyles.leftBlock}>
          <Image style={pdfTemplateStyles.logo} src={Logo} />
        </View>
        <View style={pdfTemplateStyles.rightBlock}>
          <Text style={pdfTemplateStyles.billingAddress}>PO BOX 57875 Postal Station A</Text>
          <Text style={pdfTemplateStyles.billingAddress}> Toronto, ON. M5W 5M5</Text>
          <Link style={pdfTemplateStyles.billingAddress}> accounting@randstad.ca</Link>
        </View>
      </View>
    );
    const EmployerDetailsComponent = () => (
      // <View style={pdfTemplateStyles.content}>
      <View style={pdfTemplateStyles.employerBlock}>
        <Text style={pdfTemplateStyles.employerName}>{employer.companyName}</Text>
        <Text style={pdfTemplateStyles.employerAddress}>{employer.street},</Text>
        <Text style={pdfTemplateStyles.employerAddress}>
          {employer.city}, {employer.region}. {employer.postalCode}
        </Text>
      </View>
    );
    const renderFooter = () => (
      <View style={tableFooterRow}>
        <View style={tableCol1}>
          <Text style={tableCell}> Sub Total </Text>
        </View>
        <View style={tableCol2} />
        <View style={tableCol3} />
        <View style={tableCol4} />
        <View style={colWidth10}>
          <Text style={tableCell}>{shifts.sum("totalUnits")} hrs</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableCell}>{shifts.sum("breakDeductionMins") / 60} hrs </Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableCell}>{formatMoney(shifts.sum("total"))} </Text>
        </View>
      </View>
    );
    const renderTableRows = () =>
      shifts.map((shift, i) => (
        <View key={i} style={tableRow}>
          <View style={tableCol1}>
            <Text style={tableCell}>{shift.jobId}</Text>
          </View>
          <View style={tableCol2}>
            <Text style={tableCell}>{moment(shift.date).format("MMM DD, YYYY")}</Text>
          </View>
          <View style={tableCol3}>
            <Text style={tableCell}>{shift.worker}</Text>
          </View>
          <View style={tableCol4}>
            <Text style={tableCell}>
              {moment.utc(shift.checkIn).format("h:mm a")} -
              {moment.utc(shift.checkOut).format("h:mm a")}
            </Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatNumber(shift.totalUnits)} hrs</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatNumber(shift.breakDeductionMins) || 0} mins</Text>
          </View>
          <View style={colWidth10}>
            <Text style={tableCell}>{formatMoney(shift.total)} </Text>
          </View>
        </View>
      ));

    const renderHeader = () => (
      <View style={tableHeaderRow}>
        <View style={tableCol1}>
          <Text style={tableHeaderCell}>Job Id</Text>
        </View>
        <View style={tableCol2}>
          <Text style={tableHeaderCell}>Date</Text>
        </View>
        <View style={tableCol3}>
          <Text style={tableHeaderCell}>Worker</Text>
        </View>
        <View style={tableCol4}>
          <Text style={tableHeaderCell}>Shift</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Hours</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Break</Text>
        </View>
        <View style={colWidth10}>
          <Text style={tableHeaderCell}>Total</Text>
        </View>
      </View>
    );

    const StaffingSummaryTableComponent = () => {
      return (
        <View style={table}>
          {renderHeader()}
          {renderTableRows()}
          {renderFooter()}
          {/* {renderPST()}
          {renderGST()}
          {renderTotal()} */}
        </View>
      );
    };

    return (
      <Document>
        <Page size="LETTER" wrap={true}>
          <HeaderComponent />
          <EmployerDetailsComponent />
          <StaffingSummaryTableComponent />
        </Page>
      </Document>
    );
  };

  render = () => (
    <PDFViewer style={viewerStyle}>{this.renderPdfTimesheet({ ...this.props })}</PDFViewer>
  );
}

export default invoicePdfDialogStyles(withRouter(PdfService));
