/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import debounce from "debounce-promise";

import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";

import { fetchEmployerRequirements } from "./helpers/actions";
import stylesGroups from "./helpers/stylesGroups";
import LoadingMessage from "./components/LoadingMessage";

class EmployerRequirementsAutocomplete extends React.Component {
    selectComponents = {
        Control,
        Menu,
        MultiValue,
        LoadingMessage,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        // DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    ...(this.props.style || {}),
                    flex: 1
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
        };
    };

    render() {
        const { classes, defaultOptions = true, placeholder = "Requirements", extraFilter = [], ...passProps } = this.props;

        return (
            <AsyncSelect
                {...passProps}
                classes={classes}
                styles={{ ...this.selectStyles, ...(this.props.menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 999999 }) } : {}) }}
                isClearable
                // isMulti
                defaultOptions={defaultOptions}
                value={this.props.value}
                placeholder={placeholder}
                loadOptions={debounce((text) => fetchEmployerRequirements(text, extraFilter), 1200)}
                onChange={this.props.onChange}
                components={this.selectComponents}
                textFieldProps={{
                    placeholder: placeholder
                }}
            />
        );
    }
}

EmployerRequirementsAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(stylesGroups, { withTheme: true })(EmployerRequirementsAutocomplete);
