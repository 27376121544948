import DocRenderer from "../components/DocRenderer";
import HighDefinationImageRenderer from "../components/HighDefinationImageRenderer";
import ImageRenderer from "../components/ImageRenderer";

export const allowedDocumentsRenderer = [
    {
        Renderer: ImageRenderer,
        "mime": "gif"
    },
    {
        Renderer: ImageRenderer,
        "mime": "jpeg"
    },
    {
        Renderer: ImageRenderer,
        "mime": "jpg"
    },
    {
        Renderer: ImageRenderer,
        "mime": "tiff"
    },
    {
        Renderer: ImageRenderer,
        "mime": "png"
    },
    {
        Renderer: ImageRenderer,
        "mime": "svg"
    },
    {
        Renderer: HighDefinationImageRenderer,
        "mime": "heic"
    },
    {
        Renderer: HighDefinationImageRenderer,
        "mime": "heif"
    },
    {
        Renderer: DocRenderer,
        "mime": "pdf"
    },
];
