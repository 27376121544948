import React, { useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import { PictureAsPdf } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { actions as holidayActions, selectors as holidaySelectors } from "store/modules/holidays";
import { CircularProgress } from "@mui/material";
import { downloadFileFromLink } from "utils/file";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function HolidayWorkerActionMenu({ position }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const isPaystubDownloading = useSelector(holidaySelectors.getIsDownloadPaystubLoading)

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const toggleResendStubDialog = () => {
        dispatch(holidayActions.setIsResendPayStubDialog(position.id));
        closeMenu();
    };

    const toggleIsViewingPdf = () => {
        dispatch(holidayActions.downloadPaystubSummary(position.id)).then(url => {
            if (url) {
                downloadFileFromLink(url);
                setTimeout(() => {
                    closeMenu();
                    dispatch(holidayActions.setIsDownloadPaystubLoading(false));
                }, 1000);
            } else {
                dispatch(holidayActions.setIsDownloadPaystubLoading(false));
            }
        });
    };;

    return <>
        <IconButton
            aria-controls={"menuId"}
            aria-haspopup="true"
            onClick={openMenu}
            disableRipple
            size="large">
            <MoreVert color="primary" />
        </IconButton>
        <Menu
            id={"menuId"}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}>

            <MenuItem disabled={
                !position?.holidayPayroll ||
                !position.holidayPayroll?.holidayPayment ||
                !position.holidayPayroll?.holidayPayment.length ||
                isPaystubDownloading === position.id
            } onClick={toggleIsViewingPdf}>
                {isPaystubDownloading === position.id ? <CircularProgress className={classes.icon} size={20} color="action" /> : <PictureAsPdf className={classes.icon} color="action" />}
                Download Paystub
            </MenuItem>
            <MenuItem disabled={!position?.holidayPayroll || !position.holidayPayroll?.holidayPayment || !position.holidayPayroll?.holidayPayment.length} onClick={toggleResendStubDialog}>
                <PictureAsPdf className={classes.icon} color="action" />
                Email Paystub
            </MenuItem>
        </Menu>
    </>;
}


export default HolidayWorkerActionMenu;
