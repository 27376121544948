import { gql } from "@apollo/client";

export default gql`
query getEmployer($id: ID!) {
    employer(id: $id) {
        profileImageUrl
        signedProfileImageUrl
        logoImageUrl
        signedLogoImageUrl
        companyName
        firstName
        lastName
        title
        email
        mobilePhone
        street
        city
        region
        country
        postalCode
    }
}
`;
