import { gql } from "@apollo/client";

export default gql`
mutation createRequirement($data:CreateRequirementInput!){
    createRequirement(data:$data){
        id
        name
        frenchName
        summary
        frenchSummary
        title
        frenchTitle
        description
        frenchDescription
        isConsent
        isDocHaveExpiry
        createdBy
        type
        groups{
            id
            name
        }
        helpTexts{
            id
            question
            frenchQuestion
            answer
            frenchAnswer
        }
    }
}
`;

export const UpdateRequirement = gql`
mutation updateRequirement($data:UpdateRequirementInput!){
    updateRequirement(data:$data){
        id
        name
        frenchName
        summary
        frenchSummary
        title
        frenchTitle
        description
        frenchDescription
        isConsent
        isDocHaveExpiry
        createdBy
        type
        groups{
            id
            name
        }
        helpTexts{
            id
            question
            frenchQuestion
            answer
            frenchAnswer
        }
    }
}
`;

export const CreateOrUpdateEmployerHasRequirement = gql`
mutation createOrUpdateEmployerHasRequirement($data:[CreateEmployerRequirementInput!]!){
    createOrUpdateEmployerHasRequirement(data:$data){
        entities {
            id
            employerId
            workTypeId
            requirementId
            validityType
            validityValue
        }
        existing
    }
}
`;

export const RemoveEmployerHasRequirement = gql`
mutation removeEmployerHasRequirement($id:Int!){
    removeEmployerHasRequirement(id:$id)
}
`;
