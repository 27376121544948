import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import useAppliedDate from "./columns/appliedDate";
import useAvatar from "./columns/avatar";
import useBanCount from "./columns/banCount";
import useJobsWorked from "./columns/jobsWorked";
import useName from "./columns/name";
import useNoShowCount from "./columns/noShowCount";
import usePayRate from "./columns/payRate";
import usePositionMenu from "./columns/positionMenu";
import useRating from "./columns/rating";
import useSiteHistory from "./columns/siteHistory";
import useLocation from "./columns/location";
import useAddedBy from "./columns/addedBy";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ shape, typography, spacing }) => ({
    table: {
        flex: 1,
        border: "none",
        borderRadius: shape.borderRadius / 2,
        width: "100%",
    },
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function DraftTable(props) {
    const classes = useStyles();
    const isLoading = useSelector(JobDetailSelectors.getIsUpdatingPositions);
    const positions = useSelector(JobDetailSelectors.getPositions);

    const batchActionDisable = props.isEditingRatesAll;

    const columns = [
        useAvatar(batchActionDisable),
        useName(batchActionDisable),
        useLocation(batchActionDisable),
        useAppliedDate(batchActionDisable),
        // useRating(false, batchActionDisable),
        useSiteHistory(batchActionDisable),
        useJobsWorked(batchActionDisable),
        useBanCount(batchActionDisable),
        useNoShowCount(batchActionDisable),
        usePayRate(props.isEditingRatesAll),
        // useBillingRate(props.isEditingRatesAll),
        useAddedBy(batchActionDisable),
        usePositionMenu({
            isWorkerPickerOpen: props.isWorkerPickerOpen,
            onChooseWorker: props.onChooseWorker,
            disabled: batchActionDisable,
        }),
    ];

    return (
        <ReactTableSimple
            classes={classes.table}
            data={positions}
            loading={isLoading}
            columns={columns}
            showFooter

        />
    );
}

DraftTable.propTypes = {
    onChooseWorker: PropTypes.func.isRequired,
    isWorkerPickerOpen: PropTypes.bool.isRequired,
    isRatingAll: PropTypes.bool.isRequired,
    isCheckingInAll: PropTypes.bool.isRequired,
    isCheckingOutAll: PropTypes.bool.isRequired,
    isEditingRatesAll: PropTypes.bool.isRequired,
};

export default DraftTable;
