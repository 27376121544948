// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.provinceBreakRule.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsFormActionLoading = getLoadingStateFactory("formDialogLoading");
export const getIsDeleteLoading = getLoadingStateFactory("deleteLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.provinceBreakRule.dialog[loader] || false;
};

export const getIsAddProvinceBreakRuleDialog = getDialogStateFactory("addProvinceBreakRuleDialog");
export const getIsDeleteProvinceBreakRuleDialog = getDialogStateFactory("deleteProvinceBreakRuleDialog");
export const getIsEditProvinceBreakRuleDialog = getDialogStateFactory("editProvinceBreakRuleDialog");

// selectors
export const getProvinceBreakRuleList = state => {
    return state.provinceBreakRule.data;
};

export const getProvinceBreakRuleForm = (state, part) => {
    return part ? state.provinceBreakRule?.form[part] : state.provinceBreakRule.form;
};
