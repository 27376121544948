import ReactTable from 'components/ReactTable'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { holidayTransfersTabStyles } from 'views/Finance/helpers';
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import HolidayPayrollTransferExpandView from './HolidayPayrollTransferExpandView';
import HolidayPaymentTransferTableMenu from '../../Menus/HolidayPaymentTransferTableMenu';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Avatar, IconButton, MenuItem, Select, TextField } from '@mui/material';
import Moment from "react-moment";
import { formatMoney, formatNumber } from "utils/stringUtils";
import Datetime from "react-datetime";
import { RenderTransferAvatar } from '../../helpers/WorkerAvatar';
import { statuses } from "views/Finance/helpers";
import { transferStatuses } from 'utils/constant';
import classNames from 'classnames';
import { convertUTCToLocal } from 'utils/time';
import CancelTransferDialog from 'views/Finance/FinanceOverview/components/Dialogs/CancelTransferDialog';
import { CancelScheduleSend } from '@mui/icons-material';
import ResendDialog from 'views/Finance/FinanceOverview/components/Dialogs/ResendDialog';
import { DcTransferStatusBadge } from 'components/Payments/DcBadge';

export default function HolidayPayrollTransferTab(props) {
    const classes = holidayTransfersTabStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const holidayTransfers = useSelector(FinanceSelectors.getHolidayTransfers);
    const PaginationData = useSelector(FinanceSelectors.getHolidayTransfersPaginationData);
    const Filters = useSelector(FinanceSelectors.getHolidayTransfersFilters);

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        dispatch(FinanceActions.setHolidayTransferPageSize(pageSize));
        dispatch(FinanceActions.setHolidayTransferFilter(filters));
        dispatch(FinanceActions.fetchHolidayPayrollTransfers(pageIndex));
    }, []);

    const columns = [
        {
            Header: "",
            accessor: ({ worker }) => worker.signedProfileImageUrl,
            id: "profileImage",
            Cell: ({ row: { original } }) => {
                return (
                    <RenderTransferAvatar
                        code={statuses.find(c => c.id === original.paymentStatus.id).dcCode}
                        data={original.worker}
                    />
                );
            },
            disableSortBy: true,
            disableFilters: true,
            width: 100,
        },
        {
            Header: "Worker",
            disableSortBy: true,
            id: "firstName",
            width: 100,
            accessor: ({ worker }) => worker.firstName,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { worker } } }) => worker.firstName,
        },
        {
            Header: "Transfer Id",
            disableSortBy: true,
            id: "dcTransferId",
            width: 100,
            style: { cursor: "pointer" },
            accessor: ({ dcTransferId }) => dcTransferId,
            Cell: ({ row: { original: { dcTransferId } } }) => dcTransferId,
        },
        {
            Header: "Status ",
            disableSortBy: true,
            accessor: ({ paymentStatus }) => paymentStatus,
            id: "paymentStatusId",
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { paymentStatus } } }) => paymentStatus.description,
            width: 120,
            Filter: ({ filter, setFilter, column, ...rest }) => {
                return (
                    <div>
                        <Select
                            disableUnderline={false}
                            variant="standard"
                            onChange={event => setFilter(column.id, event.target.value.trim())}
                            value={column.filterValue !== " " || column.filterValue !== undefined ? column.filterValue || " " : " "}
                            style={{ fontSize: 12 }}
                            fullWidth
                        >
                            <MenuItem value={" "}>All</MenuItem>
                            {transferStatuses.map(n => (
                                <MenuItem value={n.id.toString()} key={n.id}>{n.label}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )
            },
        },
        {
            Header: "Date Of Funds",
            disableSortBy: true,
            id: "sentAt",
            accessor: ({ sentAt }) => sentAt,
            width: 100,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { sentAt } } }) =>
                sentAt ? convertUTCToLocal(sentAt, "MMM Do, YYYY") : "-",
            Filter: ({ column, setFilter }) => {
                return (
                    <Datetime
                        timeFormat={false}
                        id="date"
                        input
                        dateFormat="MMM Do, YYYY"
                        onChange={(e) => {
                            const date = moment(e)
                            setFilter(column.id, date.isValid() ? date.format('YYYY-MM-DD') : '')
                        }}
                        renderInput={(rest) => <TextField  {...rest} variant="filled" className={classNames("filter-textfield", classes.dateInput)} />}
                        className={classes.dateFilter}
                        value={column.filterValue ? moment(column.filterValue) : ""}
                    // isValidDate={isValidStartDate}
                    />
                );
            },
        },
        {
            Header: "Amount",
            disableFilters: true,
            disableSortBy: true,
            accessor: ({ amount }) => amount,
            id: "amount",
            width: 100,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { holidayPayroll, amount } } }) =>
                `${formatMoney((holidayPayroll && holidayPayroll.holidayPayment && holidayPayroll.holidayPayment.length && holidayPayroll.holidayPayment[0].amount) || amount)}`,
        },
        {
            Header: "Attempts",
            disableFilters: true,
            disableSortBy: true,
            accessor: ({ holidayPayroll }) => holidayPayroll,
            id: "Attempts",
            headerStyle: { textAlign: "center" },
            width: 100,
            style: { cursor: "pointer", textAlign: "center" },
            Cell: ({ row: { original: { holidayPayroll } } }) => (holidayPayroll && holidayPayroll.holidayPayment && holidayPayroll.holidayPayment.length || 0),
        },
        {
            Header: "Status",
            disableFilters: true,
            disableSortBy: true,
            id: "Status",
            width: 60,
            accessor: ({ paymentStatus }) => paymentStatus.id,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { paymentStatus } } }) => (
                <DcTransferStatusBadge code={statuses.find(c => c.id === paymentStatus.id).dcCode} iconOnly />
            ),
        },
        {
            Header: "",
            expander: true,
            width: 60,
            disableFilters: true,
            id: "payment",
            Expander: ({ isExpanded, original }) => {
                const { holidayPayroll } = original;
                if (holidayPayroll && holidayPayroll.holidayPayment) {
                    return isExpanded ? <ArrowDropDown /> : <ArrowRight />;
                }
                return null;
            },
            Cell: ({ row: { original, isExpanded, ...row }, column }) => {
                const { holidayPayroll } = original;
                return <>
                    {holidayPayroll && holidayPayroll.holidayPayment && <IconButton {...row.getToggleRowExpandedProps()}>
                        {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                    </IconButton>}
                    {/* <IndividualTransfersActions
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        payment={original}
                        dcCode={code}
                    /> */}
                </>;
            },
        },
        {
            Header: "",
            id: "actions",
            accessor: "actions",
            disableSortBy: true,
            disableFilters: true,
            collapse: true,
            style: { cursor: "pointer" },
            Cell: ({ row: { original } }) => {
                return (
                    <HolidayPaymentTransferTableMenu payment={original} worker={original.worker} />
                );
            },
            width: 50,
        },
    ];

    return (
        <React.Fragment>
            <ReactTable
                classes={classes.table}
                columns={columns}
                data={holidayTransfers.data}
                fetchData={fetchData}
                defaultFilter={Filters}
                currentPage={PaginationData.currentPage}
                defaultPageSize={PaginationData.pageSize}
                loading={PaginationData.isLoading}
                pageCount={PaginationData.totalCount}
                expandView={row => <HolidayPayrollTransferExpandView {...row} />}
            />
        </React.Fragment>
    )
}
