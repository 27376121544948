export const paginationSettings = {
    additionalRequirementList: {
        prefix: "additionalRequirementList",
        defaultPageSize: 10,
    },
    assignedEmployerList: {
        prefix: "assignedEmployerList",
        defaultPageSize: 10
    }
};
