import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.verifications.loading[loader] || false;
};

export const getIsLoading = getLoadingStateFactory("isLoading");
export const getIsWorkerPositionsLoading = getLoadingStateFactory("workerPositions");
export const getIsRequirementsLoading = getLoadingStateFactory("requirementDetail");
export const getIsBulkApproveLoading = getLoadingStateFactory("bulkApprove");
export const getIsApproveLoading = getLoadingStateFactory("approveRequirement");
export const getIsRejectLoading = getLoadingStateFactory("rejectRequirement");
export const getIsDocumentApproveLoading = getLoadingStateFactory("approveDocumentRequirement");
export const getIsDocumentRejectLoading = getLoadingStateFactory("rejectDocumentRequirement");
export const getIsSentAgencyLoading = getLoadingStateFactory("sentAgencyLoading");
export const getRejectedAdditionalDocLoading = getLoadingStateFactory("rejectedAdditionalDocLoading");
export const getRejectedAlternateLoading = getLoadingStateFactory("rejectedAlternateLoading");
export const getRejectedResubmissionLoading = getLoadingStateFactory("resubmissionLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.verifications.dialog[loader] || false;
};

export const getApproveAllDialog = getDialogStateFactory("approveAll");
export const getSentToAgencyDialog = getDialogStateFactory("sentToAgency");
export const getApproveDialog = getDialogStateFactory("approve");
export const getRejectDialog = getDialogStateFactory("reject");
export const getRejectedInfoDialog = getDialogStateFactory("rejectedInfo");
export const getDocumentRejectedInfoDialog = getDialogStateFactory("documentRejectedInfo");
export const getRejectedAdditionalDocDialog = getDialogStateFactory("rejectedAdditionalDoc");
export const getResubmissionDialog = getDialogStateFactory("resubmission");
export const getAlternateDocDialog = getDialogStateFactory("alternateDoc");
export const getDocumentApproveDialog = getDialogStateFactory("documentApprove");
export const getDocumentRejectDialog = getDialogStateFactory("documentReject");
export const getDocumentPreviewDialog = getDialogStateFactory("documentPreview");

export const getAllJobs = (state) => {
    return state.verifications.jobs?.data;
};

export const getSelectedJob = (state) => {
    return state.verifications.selectedJob;
};

export const getSelectedJobPositions = (state) => {
    return state.verifications.jobPositions;
};
export const getSelectedWorker = (state) => {
    return state.verifications.selectedWorker;
};

export const getSelectedRequirements = (state) => {
    return state.verifications.requirements;
};

export const getRequirementSelection = (state) => {
    return state.verifications.requirementsSelection;
};


export const getJobApplicationsPagingData = state => {
    return state.verifications[pagination.getPageInfoAttr(paginationSettings.jobApplications)];
};
export const getJobApplicationsData = (state, { part }) => state.verifications.jobs[part];
