import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Close from "@mui/icons-material/Close";
import Dialog from "components/Dialog/Dialog";
import {
    actions as JobDetailActions,
    selectors as JobDetailSelectors,
} from "store/modules/jobDetails";

import WorkersListTable from "./components/WorkersListTable";
import { filter, find } from "lodash";
import { DialogActions } from "@mui/material";
import Button from "components/Button/Button";

class WorkerPicker extends React.Component {
    state = {
        value: 0,
        isShowDialog: false,
        jobList: [],
        payrollList: [],
        isPayrollGenerated: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open && this.props.open === true) {
            this.props.fetchAllWorkers();
            this.props.fetchPreferredWorkers();
            if (this.showWaitlist) {
                this.props.fetchWaitlistWorkers();
            }
        }
    }

    get showWaitlist() {
        const { timeframe } = this.props;
        return timeframe === "Today" || timeframe === "Tomorrow";
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleAddWorker = (workerId, jobId) => {
        return this.props.checkPositionForADay(workerId, jobId).then(res => {
            if(!res) return;
            if (res?.length > 0) {
                this.setState({ jobList: res, workerId, jobId });
                const payrollList = filter(res, n => n.payrolls && n.payrolls.length > 0);
                this.setState({ payrollList: payrollList });

                const payrollGeneratedJob = find(res, n => n.payrolls && n.payrolls.length > 0);
                if (payrollGeneratedJob) {
                    this.setState({ isPayrollGenerated: true });
                }
                this.showDialog();
                return;
            } else {
                return this.addWorker(workerId, jobId);
            }
        });
    };

    addWorker = (workerId, jobId) => {
        return this.props.onAddWorker(workerId, jobId).then(() => {
            if (this.showWaitlist) {
                return this.props.fetchWaitlistWorkers();
            }
            return;
        });
    };

    showDialog = () => {
        this.setState({ isShowDialog: true });
    };
    hideDialog = () => {
        this.setState({ isShowDialog: false });
    };
    continueAdd = () => {
        this.setState({ isShowDialog: false });
        this.addWorker(this.state.workerId, this.state.jobId);
    };

    handelDialog = async () => {
        this.setState({ isShowDialog: false });
        await this.removeWorker();
        this.addWorker(this.state.workerId, this.state.jobId);
    };
    removeWorker = async () => {
        this.state.jobList.forEach(n => {
            this.props.removeWorkerFromOtherJob(n.jobId, n.workerId, n.id);
        });
    };

    render() {
        const {
            classes,
            open,
            canDrive,
            canWorkMorning,
            canWorkEvening,
            onClose,
            allWorkersTableProps,
            preferredWorkersTableProps,
            waitlistWorkersTableProps,
            onCanDriveChange,
            onCanWorkMorningChange,
            onCanWorkEveningChange,
        } = this.props;

        const tabClass = {
            root: classes.tabRoot,
            // labelContainer: classes.tabLabelContainer,
            selected: classes.tabSelected,
        };

        const tables = [
            <WorkersListTable
                key="allWorkersTablePicker"
                scrollToEnd={this.props.scrollToEnd}
                onAddWorker={this.handleAddWorker}
                {...allWorkersTableProps}
            />,
            <WorkersListTable
                key="preferredWorkersTablePicker"
                scrollToEnd={this.props.scrollToEnd}
                onAddWorker={this.handleAddWorker}
                {...preferredWorkersTableProps}
            />,
        ];

        if (this.showWaitlist) {
            tables.push(
                <WorkersListTable
                    key="waitlistWorkersTablePicker"
                    scrollToEnd={this.props.scrollToEnd}
                    onAddWorker={this.handleAddWorker}
                    {...waitlistWorkersTableProps}
                />,
            );
        }

        return (
            <Grid
                container
                className={classNames(classes.wrapper, {
                    [classes.wrapperOpen]: open,
                })}
                alignItems="flex-end"
            >
                <Grid
                    container
                    className={classNames(classes.picker, {
                        [classes.pickerOpen]: open,
                    })}
                    alignContent="flex-start"
                >
                    <Grid container item xs={12} justifyContent="flex-end" className={classes.header}>
                        <IconButton disableRipple onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid container>
                        <Grid container item xs={6}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                classes={{ indicator: classes.tabIndicator }}
                            >
                                <Tab label="All" classes={tabClass} />
                                <Tab label="Preferred" classes={tabClass} />
                                {this.showWaitlist ? <Tab label="Waitlist" classes={tabClass} /> : null}
                            </Tabs>
                        </Grid>
                        {this.state.value === 2 && this.showWaitlist ? (
                            <Grid container item xs={6} justifyContent="flex-end">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={canDrive}
                                            onChange={e => onCanDriveChange(e.target.checked)}
                                            value="canDrive"
                                        />
                                    }
                                    label="Drives"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={canWorkMorning}
                                            onChange={e => onCanWorkMorningChange(e.target.checked)}
                                            value="canWorkMorning"
                                        />
                                    }
                                    label="Mornings"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={canWorkEvening}
                                            onChange={e => onCanWorkEveningChange(e.target.checked)}
                                            value="canWorkEvening"
                                        />
                                    }
                                    label="Evenings"
                                />
                            </Grid>
                        ) : null}
                        <Grid container item xs={12} className={classes.tableContainer}>
                            <SwipeableViews
                                axis="x"
                                index={this.state.value}
                                onChangeIndex={this.handleChangeIndex}
                            >
                                {tables}
                            </SwipeableViews>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.isShowDialog}
                    title={`Are you sure?`}
                    onClose={this.hideDialog}
                    alertType="form"
                    hideActions>
                    <p>
                        {`Worker already added on ${this.state.jobList.map(
                            (n) => ' ' + n.jobId,
                        )} job`}
                    </p>
                    <p>
                        {this.state.isPayrollGenerated && (`Note: Payroll is generated for ${this.state.payrollList.map(
                            (n) => (n.jobId),
                        )} jobs. You can not remove from this job`)}
                    </p>
                    <DialogActions className={classes.footer}>
                        <Button label={"Cancel"} onClick={this.hideDialog} color="transparent" />
                        {/* <Button label={"Continue, Add Worker"} onClick={this.continueAdd} color="primary" /> */}
                        {!this.state.isPayrollGenerated && (
                            <Button
                                label={"Remove from other job and add worker"}
                                onClick={this.handelDialog}
                                color="secondary"
                            />
                        )}
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapState = state => {
    const { id, positions, timeframe, banned } = JobDetailSelectors.getJobDetails(state);
    const allWorkersTableProps = {
        ...JobDetailSelectors.getAllWorkersPaginationData(state),
        data: JobDetailSelectors.getWorkerListData(state, {
            list: "all",
            part: "data",
        }),
        pageFilter: JobDetailSelectors.getWorkerListData(state, {
            list: "all",
            part: "filter",
        }),
        pageSort: JobDetailSelectors.getWorkerListData(state, {
            list: "all",
            part: "sort",
        }),
        jobId: id,
        positions,
        banned,
    };

    const preferredWorkersTableProps = {
        ...JobDetailSelectors.getPreferredWorkersPaginationData(state),
        data: JobDetailSelectors.getWorkerListData(state, {
            list: "preferred",
            part: "data",
        }),
        pageFilter: JobDetailSelectors.getWorkerListData(state, {
            list: "preferred",
            part: "filter",
        }),
        pageSort: JobDetailSelectors.getWorkerListData(state, {
            list: "preferred",
            part: "sort",
        }),
        jobId: id,
        positions,
        banned,
    };

    const waitlistWorkersTableProps = {
        ...JobDetailSelectors.getWaitlistWorkersPaginationData(state),
        data: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "data",
        }),
        pageFilter: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "filter",
        }),
        pageSort: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "sort",
        }),
        jobId: id,
        positions,
        banned,
    };

    return {
        allWorkersTableProps,
        preferredWorkersTableProps,
        waitlistWorkersTableProps,
        timeframe,
        canDrive: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "canDrive",
        }),
        canWorkMorning: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "canWorkMorning",
        }),
        canWorkEvening: JobDetailSelectors.getWorkerListData(state, {
            list: "waitlist",
            part: "canWorkEvening",
        }),
    };
};

const mapActions = {
    fetchAllWorkers: JobDetailActions.fetchAllWorkers,
    onPageChangeAllWorkers: JobDetailActions.fetchAllWorkers,
    onSortedChangeAllWorkers: JobDetailActions.setAllWorkersSort,
    onFilteredChangeAllWorkers: JobDetailActions.setAllWorkersFilter,

    fetchPreferredWorkers: JobDetailActions.fetchPreferredWorkers,
    onPageChangePreferredWorkers: JobDetailActions.fetchPreferredWorkers,
    onSortedChangePreferredWorkers: JobDetailActions.setPreferredWorkersSort,
    onFilteredChangePreferredWorkers: JobDetailActions.setPreferredWorkersFilter,

    fetchWaitlistWorkers: JobDetailActions.fetchWaitlistWorkers,
    onPageChangeWaitlistWorkers: JobDetailActions.fetchWaitlistWorkers,
    onSortedChangeWaitlistWorkers: JobDetailActions.setWaitlistWorkersSort,
    onFilteredChangeWaitlistWorkers: JobDetailActions.setWaitlistWorkersFilter,

    onAddWorker: JobDetailActions.fillPosition,
    checkPositionForADay: JobDetailActions.checkPositionForADay,
    removeWorkerFromOtherJob: JobDetailActions.removeWorkerFromOtherJob,

    onCanDriveChange: JobDetailActions.setCanDrive,
    onCanWorkMorningChange: JobDetailActions.setCanWorkMorning,
    onCanWorkEveningChange: JobDetailActions.setCanWorkEvening,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {
        allWorkersTableProps,
        preferredWorkersTableProps,
        waitlistWorkersTableProps,
    } = stateProps;

    const {
        onPageChangeAllWorkers,
        onSortedChangeAllWorkers,
        onFilteredChangeAllWorkers,
        onPageChangePreferredWorkers,
        onSortedChangePreferredWorkers,
        onFilteredChangePreferredWorkers,
        onPageChangeWaitlistWorkers,
        onSortedChangeWaitlistWorkers,
        onFilteredChangeWaitlistWorkers,
    } = dispatchProps;

    return {
        ...stateProps,
        ...dispatchProps,
        allWorkersTableProps: {
            ...allWorkersTableProps,
            onPageChange: onPageChangeAllWorkers,
            onSortedChange: onSortedChangeAllWorkers,
            onFilteredChange: onFilteredChangeAllWorkers,
        },
        preferredWorkersTableProps: {
            ...preferredWorkersTableProps,
            onPageChange: onPageChangePreferredWorkers,
            onSortedChange: onSortedChangePreferredWorkers,
            onFilteredChange: onFilteredChangePreferredWorkers,
        },
        waitlistWorkersTableProps: {
            ...waitlistWorkersTableProps,
            onPageChange: onPageChangeWaitlistWorkers,
            onSortedChange: onSortedChangeWaitlistWorkers,
            onFilteredChange: onFilteredChangeWaitlistWorkers,
        },
        ...ownProps,
    };
};

export default connect(
    mapState,
    mapActions,
    mergeProps,
)(
    withStyles(({ palette, shape, customPalette, spacing }) => ({
        wrapper: {
            // NOTE: Do not apply height to the wrapper
            alignSelf: "flex-end",
            width: "100%",
            overflow: "hidden",
        },
        wrapperOpen: {
            height: "700px",
        },
        picker: {
            height: 0,
            opacity: 0,
            transition: `all 0.5s ease`,
            backgroundColor: palette.common.white,
            borderTop: `1px solid ${customPalette.primary[800]}`,
            overflow: "hidden",
            padding: `${spacing(1)} ${spacing(4)}`,
        },
        pickerOpen: {
            opacity: 1,
            height: "700px",
        },
        header: {
            height: "50px",
        },
        tabIndicator: {
            display: "none !important",
        },
        tabRoot: {
            marginRight: spacing(1),
            padding: `${spacing(1)} ${spacing(2)}`,
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            minWidth: spacing(15),
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
                padding: `calc(${spacing(1)} - 1px) calc(${spacing(2)} - 1px)`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        tabSelected: {
            padding: `${spacing(1)} calc(${spacing(2)} - 2px)`,
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
                padding: `${spacing(1)} calc(${spacing(2)} - 2px)`,
            },
        },
        tableContainer: {
            marginTop: spacing(3),
        },
        placeholderImage: {
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: palette.grey[200],
        },
        footer: {
            paddingBottom: '0',
            paddingTop: '16px'
        },
    }))(WorkerPicker),
);
