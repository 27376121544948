import * as moment from "moment-timezone";
import { PayrollTypeCode } from "helpers/constants/index";
import uniq from "lodash/uniq";
import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { convertUTCToTimezone, formatDurationFromMins, jobRequiresBreak } from "utils/time";
import { paginationSettings } from "./constants";
import types from "./types";

const initialListState = {
  sort: [],
  filter: [],
  data: [],
};

const initialLoadingState = {
  page: true,
  addingWorker: false,
  activatingJob: false,
  updatingPositions: false,
  jobTimeline: false,
  scheduledJobLoading:false,
  isDeleteJobLoading:false,
};

const initialState = {
  isInitialized: false,
  scheduledJobs:[],
  isCancelJobDialog: false,
  positionUpdates: [],
  jobTimelineData: [],
  banned: [],
  preferred: [],
  recurringJob: [],
  loading: { ...initialLoadingState },
  workerPickers: {
    all: { ...initialListState },
    preferred: { ...initialListState },
    waitlist: {
      ...initialListState,
      canDrive: true,
      canWorkMorning: true,
      canWorkEvening: true,
    },
  },
  payrollSummary: [],
  multiShiftPositions:[],
  multiShiftPositionsFilled:0,
  ...pagination.initialState(paginationSettings),
};

function initPositionUpdatesObj(positions, state) {
  return positions.map(
    ({
      workerId,
      workerRating,
      defaultWorkerRating,
      startShift,
      endShift,
      startShiftUtc,
      endShiftUtc,
      breakMins,
      durationMins,
      payRate,
      billingRate,
      id
    }) => {
      const calculatedStart = startShift ? moment(startShift) : moment(state.start);
      const calculatedEnd = endShift ? moment(endShift) : moment(state.end);
      const calculatedStartUTC = startShiftUtc
        ? convertUTCToTimezone(startShiftUtc, state.timezone)
        : convertUTCToTimezone(state.startUtc, state.timezone);
      const calculatedEndUTC = endShiftUtc
        ? convertUTCToTimezone(endShiftUtc, state.timezone)
        : convertUTCToTimezone(state.endUtc, state.timezone);
      return {
        id,
        workerId,
        rating: workerRating || defaultWorkerRating,
        startShift: calculatedStart,
        endShift: calculatedEnd,
        startShiftUtc: calculatedStartUTC,
        endShiftUtc: calculatedEndUTC,
        breakMins,
        durationMins,
        payRate,
        billingRate,
        requiresBreak: jobRequiresBreak(calculatedStartUTC, calculatedEndUTC),
      };
    },
  );
}

function calculatePositionData(position) {
  if (position.startShiftUtc && position.endShiftUtc) {
    const startMoment = position.startShiftUtc;
    const endMoment = position.endShiftUtc;
    const durationMins = endMoment.diff(startMoment, "minutes");
    if (durationMins <= 300) {
      position.breakMins = 0;
    }
  }

  return position;
}

const actionsMap = {
  [types.RESET]: () => {
    return { ...initialState };
  },
  [types.SET_SCHEDULED_JOB]: (state, { payload }) => {
    const { jobs } = payload;
    return {
      ...state,
        scheduledJobs:jobs
     };
  },
  [types.SET_JOB]: (state, { payload }) => {
    const { job } = payload;

    if (!job.positions) {
      job.positions = [];
    }

    return {
      ...state,
      ...job,
      banned: job.banned.map(({ workerId }) => workerId),
      preferred: job.preferred.map(({ workerId }) => workerId),
      positionUpdates: initPositionUpdatesObj(job.positions, job),
      isInitialized: true,
    };
  },
  [types.SET_JOB_WORKERS]: (state, { payload }) => {
    const { workers } = payload;

    return {
      ...state,
      positions: state.positions.map(position => {
        const worker = workers.find(({ workerId }) => workerId === position.workerId);
        return {
          isPaid: false,
          isPayPending: false,
          ...position,
          ...worker,
        };
      }),
    };
  },
  [types.UNINITIALIZE_PAYROLL]: state => {
    return {
      ...state,
      positions: state.positions.map(position => {
        return {
          ...position,
          payrolls: position.payrolls
            ? position.payrolls.filter(({ payments }) => payments.length > 1)
            : position.payroll
              ? position.payroll.filter(({ payments }) => payments.length > 1)
              : [],
        };
      }),
    };
  },
  [types.SET_PAYABLE_PAYROLLS]: (state, { payload }) => {
    const { payrolls } = payload;
    return {
      ...state,
      payrollSummary: payrolls.data.initializeJobPayrollDetail || [],
    };
  },
  [types.UPDATE_PAYROLL]: (state, { payload }) => {
    const { payrolls } = payload;

    const updatedPositionFields = payrolls.reduce((acc, { positionId, position }) => {
      acc[positionId] = position || {};
      return acc;
    }, {});

    const updatedPositions = state.positions.map(position => {
      return {
        ...position,
        ...updatedPositionFields[position.id],
        payrolls: payrolls.filter(({ positionId }) => positionId === position.id),
      };
    });

    return {
      ...state,
      positions: updatedPositions,
      positionUpdates: initPositionUpdatesObj(updatedPositions, state),
    };
  },
  [types.UPDATE_POSITIONS]: (state, { payload }) => {
    const { positions } = payload;

    const flattenedPositions = (positions || []).map(({ worker, ...position }) => ({
      ...position,
      ...worker,
    }));

    return {
      ...state,
      positions: flattenedPositions,
      positionUpdates: initPositionUpdatesObj(flattenedPositions, state),
      positionsFilled: flattenedPositions.length,
    };
  },
  [types.UPDATE_POSITION]: (state, { payload }) => {
    const { worker, ...position } = payload.position;

    const positions = state.positions.map(info => {
      if (info.id === position.id) {
        return {
          ...position,
          ...worker,
        };
      }

      return info;
    });
    return {
      ...state,
      positions,
      positionUpdates: initPositionUpdatesObj(positions, state),
      positionsFilled: positions.length,
    };
  },
  [types.REMOVE_POSITION_BY_POSITION_ID]: (state, { payload }) => {
    const { positionId } = payload;
    const positions = state.positions.filter(info => info.id !== positionId);
    const multiShiftPositions = state.multiShiftPositions.filter(info => info.id !== positionId);
    return {
      ...state,
      positions,
      multiShiftPositions,
      multiShiftPositionsFilled:multiShiftPositions?.length,
      positionUpdates: initPositionUpdatesObj(positions, state),
      positionsFilled: positions.length,
    };
  },
  [types.REMOVE_POSITION]: (state, { payload }) => {
    const { workerId } = payload;
    const positions = state.positions.filter(info => info.workerId !== workerId);
    const multiShiftPositions = state.multiShiftPositions.filter(info => info.workerId !== workerId);
    return {
      ...state,
      positions,
      multiShiftPositions,
      multiShiftPositionsFilled:multiShiftPositions?.length,
      positionUpdates: initPositionUpdatesObj(positions, state),
      positionsFilled: positions.length,
    };
  },
  [types.SET_WORKER_PICKER_LIST_DATA]: (state, { payload }) => {
    const { data, list, part } = payload;

    return {
      ...state,
      workerPickers: {
        ...state.workerPickers,
        [list]: {
          ...state.workerPickers[list],
          [part]: data,
        },
      },
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_IS_CANCEL_JOB_DIALOG_STATE]: (state, { payload }) => {
    const { value } = payload;
    return {
      ...state,
      isCancelJobDialog: value
    };
  },
  [types.UPDATE_POSITION_DATA]: (state, { payload }) => {
    const { positionId, field, value } = payload;

    return {
      ...state,
      positionUpdates: [...state.positionUpdates].map(info => {
        if (info.id === positionId) {
          return calculatePositionData({
            ...info,
            [field]: value,
          });
        }
        return info;
      }),
    };
  },
  [types.BATCH_UPDATE_POSITION_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      positionUpdates: [...state.positionUpdates].map(info => {
        return calculatePositionData({
          ...info,
          [field]: value,
        });
      }),
    };
  },
  [types.RESET_POSITION_UPDATES]: state => {
    return {
      ...state,
      positionUpdates: initPositionUpdatesObj(state.positions, state),
    };
  },
  [types.UPDATE_BAN_LIST]: (state, { payload }) => {
    const { workerId } = payload;
    return {
      ...state,
      banned: uniq([...state.banned, workerId]),
      preferred: state.preferred.filter(id => id !== workerId),
    };
  },
  [types.UPDATE_PREFER_LIST]: (state, { payload }) => {
    const { workerId } = payload;
    return {
      ...state,
      preferred: uniq([...state.preferred, workerId]),
      banned: state.banned.filter(id => id !== workerId),
    };
  },
  [types.REMOVE_PREFER_BAN]: (state, { payload }) => {
    const { workerId } = payload;
    return {
      ...state,
      preferred: state.preferred.filter(id => id !== workerId),
      banned: state.banned.filter(id => id !== workerId),
    };
  },
  [types.UPDATE_WAITLIST_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      workerPickers: {
        ...state.workerPickers,
        waitlist: {
          ...state.workerPickers.waitlist,
          [field]: value,
        },
      },
    };
  },
  [types.SET_JOB_TIMELINE_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      jobTimelineData: data,
    };
  },
  [types.SET_RECURRING_JOB_LIST]: (state, { payload }) => {
    return {
      ...state,
      recurringJob: payload,
    };
  },
  [types.SET_MULTI_SHIFT_JOB_POSITIONS]: (state, { payload }) => {
    const { getUniquePositions } = payload;
    let multiShiftPositions = getUniquePositions;
    if (!getUniquePositions) {
        multiShiftPositions = [];
    }

    return {
        ...state,
        multiShiftPositions: multiShiftPositions.map(position => ({
            ...position,
            ...(position.worker ? position.worker : {})
        })),
        multiShiftPositionsFilled:multiShiftPositions?.length,
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
