import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { DateRange, Event, History, Schedule, VisibilityOff } from "@mui/icons-material";
import { JobStatusesLabel, JobStatusesMap, TimeframeLabel, TimeframeMap } from "helpers/constants/index";
import _debounce from "lodash/debounce";
import Dialog from "components/Dialog/Dialog";
import Line from "components/Placeholders/Line";
import { formatMoney } from "utils/stringUtils";

import { convertUTCToTimezone, convertUTCToTimezoneWithOffset, formatDurationFromMins } from "utils/time";
import { actions as EmployerDetailActions, selectors as EmployerDetailSelectors } from "store/modules/employerDetails";
import EmployerJobActionMenu from "./EmployerJobActionMenu";
import ReactTableCustom from "components/ReactTable";

const TableWrapper = ({
    onSortedChange,
    onFilteredChange,
    onPageChange,
    sort,
    data,
    isLoading,
    totalCount,
    currentPage,
    pageSize,
    handleDeleteJob,
    handleCancelJob,
    ...props
}) => {
    const fetchData = useCallback(({ pageIndex, filters, sortBy }) => {
        onSortedChange(sortBy);
        onFilteredChange(filters);
        onPageChange(pageIndex);
    }, []);

    return (
        <ReactTableCustom
            fetchData={fetchData}
            classes="-highlight"
            defaultSort={sort}
            data={data}
            loading={isLoading}
            pageCount={totalCount}
            page={currentPage}
            defaultPageSize={pageSize}
            rowsPerPageOptions={{ rowsPerPageOptions: [pageSize] }}
            columns={[
                {
                    Header: "",
                    width: 60,
                    id: "timeframe",

                    accessor: ({ isActive, timeframe }) => {
                        let Icon;
                        let title = TimeframeLabel[timeframe];
                        if (!isActive) {
                            Icon = VisibilityOff;
                            title = `Draft (${TimeframeLabel[timeframe]})`;
                        } else if (timeframe === TimeframeMap.Today) {
                            Icon = Schedule;
                        } else if (timeframe === TimeframeMap.Tomorrow) {
                            Icon = Event;
                        } else if (timeframe === TimeframeMap.Upcoming) {
                            Icon = DateRange;
                        } else if (timeframe === TimeframeMap.Past) {
                            Icon = History;
                        } else {
                            return "Unknown timeframe";
                        }
                        return (
                            <Tooltip title={title} placement="top">
                                <Icon color="action" />
                            </Tooltip>
                        );
                    },
                    disableSortBy: true,
                    disableFilters: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "ID",
                    id: "id",
                    accessor: ({ id }) => id,
                    width: 100,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Date",
                    id: "start",
                    accessor: ({ startUtc, endUtc, timezone }) =>
                        `${convertUTCToTimezone(
                            startUtc,
                            timezone,
                            "MMM Do, YYYY"
                        )} at ${convertUTCToTimezone(
                            startUtc,
                            timezone,
                            "h:mma"
                        )} - ${convertUTCToTimezoneWithOffset(endUtc, timezone, "h:mma")}`,
                    disableFilters: true,
                    className: "-cursor-pointer",
                },
                // {
                //     Header: "Avg. Rating",
                //     id: "rating",
                //     accessor: ({ employerRating, status }) => {
                //         if (status !== JobStatusesMap.Completed) {
                //             return <Line />;
                //         }
                //         if (!employerRating) {
                //             return <i>Not rated</i>;
                //         }
                //         return employerRating;
                //     },
                //     width: 80,
                //     disableFilters: true,
                //     disableSortBy: true,
                //     className: "-cursor-pointer",
                // },
                {
                    Header: "Workers",
                    id: "workerCount",
                    accessor: ({ positionsFilled }) => positionsFilled,
                    width: 80,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Total Duration",
                    id: "hoursWorked",
                    accessor: ({ workedDurationMins, status }) => {
                        if (status !== JobStatusesMap.Completed) {
                            return <Line />;
                        }
                        if (!workedDurationMins) {
                            return <Line />;
                        }
                        return formatDurationFromMins(workedDurationMins, {
                            hourLabel: "hrs",
                            minuteLabel: "mins",
                        });
                    },
                    width: 150,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Breaks Deducted",
                    id: "totalBreaks",
                    accessor: ({ breakDeductionMins, status }) => {
                        if (status !== JobStatusesMap.Completed) {
                            return <Line />;
                        }
                        if (!breakDeductionMins) {
                            return <Line />;
                        }
                        return formatDurationFromMins(breakDeductionMins, {
                            hourLabel: "hrs",
                            minuteLabel: "mins",
                        });
                    },
                    width: 100,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Billable Time",
                    id: "billableTime",
                    accessor: ({ billableDurationMins, status }) => {
                        if (status !== JobStatusesMap.Completed) {
                            return <Line />;
                        }
                        if (!billableDurationMins) {
                            return <Line />;
                        }
                        return formatDurationFromMins(billableDurationMins, {
                            hourLabel: "hrs",
                            minuteLabel: "mins",
                        });
                    },
                    width: 150,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Billable Rate",
                    id: "billableRate",
                    accessor: ({ hourlyRate }) => {
                        return formatMoney(hourlyRate);
                    },
                    width: 100,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    Header: "Total Billed",
                    id: "totalBilled",
                    accessor: ({ hourlyRate, billableDurationMins, status, isActive, calculateEmployerBillDetail }) => {
                        if (!isActive) {
                            return <Line />;
                        }
                        if (status !== JobStatusesMap.Completed) {
                            return JobStatusesLabel[status];
                        }
                        // return formatMoney((((billableDurationMins / 60) * 100) / 100) * hourlyRate);
                        return formatMoney(billableDurationMins ? calculateEmployerBillDetail?.total : 0);
                    },
                    width: 150,
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    disableFilters: true,
                    collapse: true,
                    style: { textAlign: "right" },
                    Cell: ({ row: { original } }) => {
                        const { id, isActive, status } = original;
                        return (
                            <EmployerJobActionMenu
                                jobId={id}
                                canEdit={!isActive}
                                canDelete={!isActive}
                                canCancel={isActive && status === JobStatusesMap.NotStarted}
                                canAdjustFinance={isActive && status === JobStatusesMap.Completed}
                                onDelete={handleDeleteJob}
                                onCancel={handleCancelJob}
                            />
                        );
                    },
                },
            ]}
            onRowClick={(row, { column }, e) => {
                if (row) {
                    if (column.id !== "actions" && e.target.tagName !== "A") {
                        if (e.metaKey || e.ctrlKey) {
                            const win = window.open(
                                `${window.location.origin}/jobs/${row.original.id}${row.original?.isFixedTerm ? "/dashboard" : ""}`,
                                "_blank"
                            );
                            win.focus();
                            return;
                        }
                        props.history.push(`/jobs/${row.original.id}${row.original?.isFixedTerm ? "/dashboard" : ""}`);
                    }
                }
            }}
        />
    );
}

class EmployerJobsTable extends React.PureComponent {
    state = {
        cancelJobId: null,
        deleteJobId: null,
        showEnableDialog: false,
        showDeleteDialog: false,
        showCancelDialog: false,
    };
    // componentDidMount = () => this.props.fetchEmployerJobs();

    handleDeleteJob = deleteJobId => this.setState({ showDeleteDialog: true, deleteJobId });

    hideDeleteDialog = () => this.setState({ deleteJobId: null, showDeleteDialog: false });

    handleConfirmDelete = () => {
        const { deleteJobId } = this.state;
        this.setState({ deleteJobId: null, showDeleteDialog: false }, () =>
            this.props.onDeleteJob(deleteJobId)
        );
    };

    handleCancelJob = cancelJobId => this.setState({ showCancelDialog: true, cancelJobId });

    hideCancelDialog = () => this.setState({ showCancelDialog: false, cancelJobId: null });

    handleConfirmCancel = () => {
        const { cancelJobId } = this.state;
        this.setState({ showCancelDialog: false, cancelJobId: null }, () =>
            this.props.onCancelJob(cancelJobId)
        );
    };

    render() {
        return (
            <React.Fragment>
                <TableWrapper {...this.props} handleDeleteJob={this.handleDeleteJob} handleCancelJob={this.handleCancelJob} />
                <Dialog
                    open={this.state.showDeleteDialog}
                    title="Are You Sure?"
                    description="This job and all of its details will be deleted."
                    confirmText="Yes"
                    onClose={this.hideDeleteDialog}
                    onConfirm={this.handleConfirmDelete}
                    onCancel={this.hideDeleteDialog}
                    alertType="warning"
                />
                <Dialog
                    open={this.state.showCancelDialog}
                    title="Are You Sure?"
                    description="This job will be cancelled and deleted. All workers will be notified."
                    confirmText="Yes"
                    onClose={this.hideCancelDialog}
                    onConfirm={this.handleConfirmCancel}
                    onCancel={this.hideCancelDialog}
                    alertType="warning"
                />
            </React.Fragment>
        );
    }
}
const mapState = state => {
    const { data, sort, filter } = EmployerDetailSelectors.getJobsListData(state);
    return {
        data,
        sort,
        filter,
        ...EmployerDetailSelectors.getJobsPaginationData(state),
    };
};
const mapActions = {
    fetchEmployerJobs: EmployerDetailActions.fetchEmployerJobs,
    onPageChange: EmployerDetailActions.fetchEmployerJobs,
    onSortedChange: EmployerDetailActions.setEmployerJobSort,
    onFilteredChange: EmployerDetailActions.setEmployerJobFilter,
    onDeleteJob: EmployerDetailActions.deleteJob,
    onCancelJob: EmployerDetailActions.cancelJob,
};
export default connect(mapState, mapActions)(withRouter(EmployerJobsTable));
