import React from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormRow from './FormRow';
import GroupsDropdown from './GroupsDropdown';
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementAction } from 'store/modules/additionalRequirements';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: '65%',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: '#FFF',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75),
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    }
}));

const DocumentDetailsForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        name, frenchName, groupNames, description, frenchDescription
    } = useSelector((state) => AdditionalRequirementSelector.getFormData(state));

    const handleChange = (event) => {
        const { target: { value, name } } = event;
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm(name, value?.slice(0,250)));
    };

    const handleGroupChange = (value) => {
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm('groupNames', value));
    };

    return (
        <>
            <FormRow style={{ marginTop: 24 }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} item xs={12}>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                className={classes.fullWidth}
                                label="Document Name - English (For Talent)*"
                                placeholder="Document name - English"
                                margin="none"
                                fullWidth
                                id="name"
                                name="name"
                                value={name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                className={classes.fullWidth}
                                label="Document Name - French (For Talent)*"
                                placeholder="Document name - French"
                                margin="none"
                                fullWidth
                                id="frenchName"
                                name="frenchName"
                                value={frenchName}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 12, marginBottom: 8, zIndex: 1051 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Add this Document in the Group (For Internal use)</div>
                    <GroupsDropdown
                        fullWidth
                        variant="outlined"
                        id="groupNames"
                        name="groupNames"
                        placeholder="Select or Type & enter to add new"
                        value={groupNames}
                        onChange={handleGroupChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.fullWidth}
                        id="description"
                        name="description"
                        value={description}
                        label="Document Description - English (For Talent)*"
                        placeholder="Document Description - English"
                        fullWidth
                        onChange={handleChange}
                        margin="none"
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.fullWidth}
                        id="frenchDescription"
                        name="frenchDescription"
                        value={frenchDescription}
                        label="Document Description - French (For Talent)*"
                        placeholder="Document Description - French"
                        fullWidth
                        onChange={handleChange}
                        margin="none"
                    />
                </FormControl>
            </FormRow>
        </>
    )
};

export default DocumentDetailsForm;
