import ReactTable from 'components/ReactTable'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { holidayTransfersTabStyles } from 'views/Finance/helpers';
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import HolidayPaymentResolverTableMenu from '../../Menus/HolidayPaymentResolverTableMenu';
import { ArrowDropDown, ArrowRight, Notes, Remove } from '@mui/icons-material';
import { Avatar, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import Moment from "react-moment";
import { formatMoney } from "utils/stringUtils";
import Datetime from "react-datetime";
import { RenderTransferAvatar } from '../../helpers/WorkerAvatar';
import { statuses } from "views/Finance/helpers";
import { transferStatuses } from 'utils/constant';
import classNames from 'classnames';
import { convertUTCToLocal } from 'utils/time';
import { DcTransferStatusBadge } from 'components/Payments/DcBadge';
import "moment-timezone";
import moment from "moment-timezone";

export default function HolidayPayrollReversalsTab(props) {
    const classes = holidayTransfersTabStyles();
    const dispatch = useDispatch();
    const holidayReversals = useSelector(FinanceSelectors.getHolidayReversals);
    const PaginationData = useSelector(FinanceSelectors.getHolidayReversalsPaginationData);
    const Filters = useSelector(FinanceSelectors.getHolidayReversalsFilters);

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        dispatch(FinanceActions.setHolidayReversalPageSize(pageSize));
        dispatch(FinanceActions.setHolidayReversalFilter(filters));
        dispatch(FinanceActions.fetchHolidayPayrollReversals(pageIndex));
    }, []);


    const columns = [
        {
            Header: "",
            id: "profileImage",
            Cell: ({ row: { original } }) => (
                <RenderTransferAvatar code={statuses.find(c => c.id === original?.paymentStatus.id).dcCode} data={original?.worker} />
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
        },
        {
            Header: "Worker",
            disableSortBy: true,
            minWidth: 150,
            id: "firstName",
            accessor: ({ worker }) => worker?.firstName,
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { worker } } }) => (
                <Typography variant="body2" color={"inherit"}>
                    {worker?.firstName || ""}
                </Typography>
            ),
        },
        {
            Header: " Status ",
            disableSortBy: true,
            minWidth: 100,
            id: "paymentStatusId",
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            accessor: ({ paymentStatusId }) => paymentStatusId,
            Cell: ({ row: { original: { paymentStatus } } }) => (
                <Tooltip title={`${paymentStatus?.description}`}>
                    <Typography variant="body2" color={"inherit"}>
                        {paymentStatus?.description}
                    </Typography>
                </Tooltip>
            ),
            Filter: ({ filter, setFilter, column, ...rest }) => {
                return (
                    <div>
                        <Select
                            disableUnderline={false}
                            variant="standard"
                            onChange={event => setFilter(column.id, event.target.value.trim())}
                            value={column.filterValue !== " " || column.filterValue !== undefined ? column.filterValue || " " : " "}
                            style={{ fontSize: 12 }}
                            fullWidth
                        >
                            <MenuItem value={" "}>All</MenuItem>
                            {transferStatuses.map(n => (
                                <MenuItem value={n.id.toString()} key={n.id}>{n.label}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )
            },
        },

        {
            Header: "Sent",
            disableSortBy: true,
            disableFilters: true,
            id: "sentAt",
            minWidth: 100,
            accessor: ({ sentAt }) => sentAt,
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { sentAt } } }) => (
                <Tooltip title={`Payment sent ${moment(sentAt).format("MMM DD, YYYY h:mm a")}`}>
                    <Typography
                        style={{ width: "100%", paddingLeft: sentAt ? 0 : 20 }}
                        variant="body2"
                        color={"inherit"}>
                        {sentAt ? moment(sentAt).format("MMM DD, YYYY h:mm a") : <Remove />}
                    </Typography>
                </Tooltip>
            ),
        },
        {
            Header: "Updated",
            disableFilters: true,
            disableSortBy: true,
            id: "responseAt",
            minWidth: 100,
            accessor: ({ responseAt }) => responseAt,
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { responseAt } } }) => (
                <Typography
                    variant="body2"
                    color={"inherit"}
                    style={{ width: "100%", paddingLeft: responseAt ? 0 : 30 }}>
                    {responseAt ? moment(responseAt).format("MMM DD, YYYY h:mm a") : <Remove />}
                </Typography>
            ),
        },
        {
            Header: "Amount",
            disableFilters: true,
            minWidth: 100,
            accessor: ({ amount }) => amount,
            disableSortBy: true,
            id: "amount",
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { amount } } }) => (
                <Typography variant="body2" color={"inherit"}>
                    {formatMoney(amount)}
                </Typography>
            ),
        },
        {
            Header: "Reversal Id",
            minWidth: 100,
            accessor: ({ dcTransferId }) => dcTransferId,
            disableSortBy: true,
            id: "dcTransferId",
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { dcTransferId } } }) => (
                <Typography variant="body2" color={"inherit"}>
                    {dcTransferId}
                </Typography>
            ),
        },
        {
            Header: "Effected Transfer",
            minWidth: 150,
            accessor: ({ reversedDcTransferId }) => reversedDcTransferId,
            disableSortBy: true,
            id: "reversedDcTransferId",
            headerStyle: { cursor: "pointer", textAlign: "left" },
            style: { cursor: "pointer", textAlign: "left" },
            Cell: ({ row: { original: { reversedDcTransferId } } }) => (
                <Typography variant="body2" color={"inherit"}>
                    {reversedDcTransferId}
                </Typography>
            ),
        },
        {
            Header: " Status ",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 80,
            id: "paymentStatusIdIcon",
            headerStyle: { cursor: "pointer", textAlign: "center" },
            style: { cursor: "pointer", textAlign: "center" },
            accessor: ({ paymentStatusId }) => paymentStatusId,
            Cell: ({ row: { original: { paymentStatus } } }) => <DcTransferStatusBadge code={statuses.find(c => c.id === paymentStatus.id).dcCode} iconOnly />,
        },
        {
            Header: "Notes",
            id: "notes",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 50,
            accessor: ({ notes }) => notes,
            headerStyle: { cursor: "pointer", textAlign: "center" },
            style: {
                cursor: "pointer",
                textAlign: "center",
                flexDirection: "inherit",
                alignItems: "center",
            },
            Cell: ({ row: { original: { notes } } }) => (
                <Tooltip
                    title={`${notes}`}
                    style={{ justifyItems: "center", flexDirection: "inherit", textAlign: "center" }}>
                    <Notes color="secondary" />
                </Tooltip>
            ),
        },
        {
            id: "actions",
            disableSortBy: true,
            disableFilters: true,
            collapse: true,
            style: { cursor: "pointer", textAlign: "right" },
            Cell: ({ row: { original } }) => <HolidayPaymentResolverTableMenu payment={original} worker={original.worker}/>,
        },
    ];


    return (
        <React.Fragment>
            <ReactTable
                classes={classes.table}
                columns={columns}
                data={holidayReversals.data}
                fetchData={fetchData}
                defaultFilter={Filters}
                currentPage={PaginationData.currentPage}
                defaultPageSize={PaginationData.pageSize}
                loading={PaginationData.isLoading}
                pageCount={PaginationData.totalCount}
            />
        </React.Fragment>
    )
}
