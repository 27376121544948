import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { deepOrange, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { CustomTableCell, CustomTableRow, DashIcon } from 'views/Holidays/helpers/helpers';
import { convertUTCToLocal } from 'utils/time';
import { MoreVert, Notes } from '@mui/icons-material';
import { formatMoney } from 'utils/stringUtils';
import { statuses } from 'views/Finance/helpers';
import { DcTransferStatusBadge } from 'components/Payments/DcBadge';
import PaymentTransferTableMenu from './Menu/PaymentTransferTableMenu';
import PaymentResolverTableMenu from './Menu/PaymentResolverTableMenu';
import moment from 'moment';

export default function HolidayWorkerPayrollTable(props) {
    const classes = useStyles();
    const holidayPayroll = props?.original?.holidayPayroll;
    const holidayPayment = props?.original?.holidayPayroll?.holidayPayment?.sort((a, b) => b.id - a.id);
    const holidayPaymentReversal = props?.original?.holidayPayroll?.holidayPaymentReversal?.sort((a, b) => b.id - a.id);
    const provinceDetails = props.original.province;
    const holidayDetails = props.original.holiday;
    const totalDeduction = (holidayPayroll.ei || 0) + (holidayPayroll.cpp || 0) + (holidayPayroll.provTax || 0) + (holidayPayroll.fedTax || 0) + (holidayPayroll.qpp || 0) + (holidayPayroll.qpip || 0);
    const totalContributions = (holidayPayroll.grizzlyEI || 0) + (holidayPayroll.grizzlyCPP || 0) + (holidayPayroll.eht || 0) + (holidayPayroll.grizzlyQPP || 0) + (holidayPayroll.grizzlyQPIP || 0);
    return (
        <>
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    {/* Transfer Details */}
                    {Boolean(holidayPayment && holidayPayment.length) && <Grid item xs={12} md={6}>
                        <Grid item md={12} style={{ padding: 8, paddingLeft: 4, paddingRight: 4, height: "100%" }}>
                            <Grid item xs={12} className={classes.tableContainer}>
                                <Grid item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                                    <Typography variant="h6" style={{ width: "100%" }}>
                                        {`Transfer History`}
                                        <PaymentTransferTableMenu worker={props?.original?.worker} payment={holidayPayment[0]} />
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        style={{ width: "100%" }}>{`Provider: DC Bank`}</Typography>
                                </Grid>

                                <Grid item xs={12} md={12} style={{ paddingBottom: 4, paddingLeft: 4 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <CustomTableRow>
                                                <CustomTableCell>Transfer Id</CustomTableCell>
                                                <CustomTableCell>Sent</CustomTableCell>
                                                <CustomTableCell>Reason</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "left" }}>Amount</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "center" }}>Status</CustomTableCell>
                                            </CustomTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                holidayPayment.map((record, i) => {
                                                    const code = record.paymentStatusId
                                                        ? statuses.find(c => c.id === record.paymentStatusId)
                                                        : null;
                                                    return (
                                                        code && (
                                                            <CustomTableRow key={i}>
                                                                <CustomTableCell>
                                                                    {record.dcTransferId || record.paymentProviderReferenceCode}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {convertUTCToLocal(record.sentAt, "MMM Do, YYYY h:mm a")}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {record.interacStatus && record.dcStatus ? "Adjustment" : "Payment"}
                                                                </CustomTableCell>
                                                                <CustomTableCell style={{ textAlign: "left" }}>
                                                                    {formatMoney(record.amount)}
                                                                </CustomTableCell>
                                                                <CustomTableCell style={{ textAlign: "center" }}>
                                                                    <DcTransferStatusBadge code={code.dcCode} iconOnly />
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        )
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {/* Reversals Details */}
                    {Boolean(holidayPaymentReversal && holidayPaymentReversal.length) && <Grid item xs={12} md={6}>
                        <Grid item md={12} style={{ padding: 8, paddingLeft: 4, paddingRight: 4, height: "100%" }}>
                            <Grid item xs={12} className={classes.tableContainer}>
                                <Grid item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                                    <Typography variant="h6" style={{ width: "100%" }}>
                                        {`Reversals History`}
                                        <PaymentResolverTableMenu worker={props?.original?.worker} payment={holidayPaymentReversal[0]} />
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        style={{ width: "100%" }}>{`Provider: DC Bank`}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={12} style={{ paddingBottom: 4, paddingLeft: 4 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <CustomTableRow>
                                                <CustomTableCell>Reversal Id</CustomTableCell>
                                                <CustomTableCell>Effected Transfer</CustomTableCell>
                                                <CustomTableCell>Sent</CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "left" }}>Amount</CustomTableCell>
                                                {/* <CustomTableCell>Notes</CustomTableCell> */}
                                                <CustomTableCell style={{ textAlign: "center" }}>Status</CustomTableCell>
                                            </CustomTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                holidayPaymentReversal.map((record, i) => {
                                                    const code = record.paymentStatusId
                                                        ? statuses.find(c => c.id === record.paymentStatusId)
                                                        : null;
                                                    return (
                                                        code && (
                                                            <CustomTableRow key={i}>
                                                                <CustomTableCell>
                                                                    {record.dcTransferId || record.paymentProviderReferenceCode}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {record.reversedDcTransferId}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {convertUTCToLocal(record.sentAt, "MMM Do, YYYY h:mm a")}
                                                                </CustomTableCell>
                                                                <CustomTableCell style={{ textAlign: "left" }}>
                                                                    {formatMoney(record.amount)}
                                                                </CustomTableCell>
                                                                {/* <CustomTableCell>
                                                                    <Tooltip
                                                                        title={`${record.notes}`}
                                                                        style={{ justifyItems: "center", flexDirection: "inherit", textAlign: "center" }}>
                                                                        <Notes color="secondary" />
                                                                    </Tooltip>
                                                                </CustomTableCell> */}
                                                                <CustomTableCell style={{ textAlign: "center" }}>
                                                                    <DcTransferStatusBadge code={code.dcCode} iconOnly />
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        )
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>

                {/* Render Holiday Details */}
                <Grid item xs={12} md={3}>
                    <Grid item md={12} style={{ padding: 8, paddingLeft: 4, paddingRight: 4, height: "100%" }}>
                        <Grid item xs={12} className={classes.tableContainer}>
                            <Grid item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                                <Typography variant="h6" style={{ width: "100%" }}>{`Holiday Details`}</Typography>
                                <Typography
                                    variant="caption"
                                    style={{ width: "100%" }}>{`Information about the holiday`}</Typography>
                            </Grid>

                            <Grid item xs={12} md={12} style={{ paddingBottom: 4, paddingLeft: 4 }}>
                                <Table size="small">
                                    <TableBody>
                                        <CustomTableRow>
                                            <CustomTableCell>Holiday Id</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {holidayDetails.id || <DashIcon />}
                                            </CustomTableCell>
                                        </CustomTableRow>

                                        <CustomTableRow>
                                            <CustomTableCell>Holiday Name</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {holidayDetails.name || <DashIcon />}
                                            </CustomTableCell>
                                        </CustomTableRow>

                                        <CustomTableRow>
                                            <CustomTableCell>Holiday Date</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {moment(holidayDetails.holidayDate).format("MMMM Do, YYYY")}
                                            </CustomTableCell>
                                        </CustomTableRow>

                                        <CustomTableRow>
                                            <CustomTableCell>Province Id</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {provinceDetails.id || <DashIcon />}
                                            </CustomTableCell>
                                        </CustomTableRow>

                                        <CustomTableRow>
                                            <CustomTableCell>Province Name</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {provinceDetails.name || <DashIcon />}
                                            </CustomTableCell>
                                        </CustomTableRow>

                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Render Payroll details */}
                <Grid item md={9}>
                    <Grid item md={12} style={{ padding: 8, height: "100%" }}>
                        <Grid container item xs={12} className={classes.tableContainer}>
                            <Grid container item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                                <Typography variant="h6" style={{ width: "100%" }}>
                                    {`Holiday Payroll Details`}
                                    {/* <IconButton style={{ marginRight: 8, float: "right" }} edge="end" size="small">
                                        <MoreVert />
                                    </IconButton> */}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{ width: "100%" }}>{`Information about the holiday payroll`}</Typography>
                            </Grid>

                            {/*Earnings  */}
                            <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                                <Table size="small" style={{ marginTop: 8 }}>
                                    <TableHead>
                                        <CustomTableRow>
                                            <CustomTableCell>Earnings</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                        </CustomTableRow>
                                    </TableHead>
                                    <TableBody>

                                        <CustomTableRow>
                                            <CustomTableCell>
                                                {"Standard" || <DashIcon />}
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grossPay - holidayPayroll.vacationPay) || <DashIcon />}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {(holidayPayroll.vacationPay > 0) ? <CustomTableRow>
                                            <CustomTableCell>Vacation Pay</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.vacationPay)}
                                            </CustomTableCell>
                                        </CustomTableRow>:""}
                                        <CustomTableRow>
                                            <CustomTableCell>Total</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grossPay)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        <CustomTableRow>
                                            <CustomTableCell>Net Pay</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.netPay)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                            {/*Deductions  */}
                            <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                                <Table size="small" style={{ marginTop: 8 }}>
                                    <TableHead>
                                        <CustomTableRow>
                                            <CustomTableCell>Deductions</CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                Amount
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableHead>
                                    <TableBody>

                                        {/* EI */}
                                        <CustomTableRow>
                                            <CustomTableCell>
                                                Employment Insurance
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.ei)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* CPP */}
                                        <CustomTableRow>
                                            <CustomTableCell>
                                                Canadian Pension Plan
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.cpp)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* provTax */}
                                        <CustomTableRow>
                                            <CustomTableCell>
                                                {`${provinceDetails.name} Provincial Tax`}
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.provTax)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* provTax */}
                                        <CustomTableRow>
                                            <CustomTableCell>
                                                Federal Tax
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.fedTax)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* QPP */}
                                        {holidayPayroll.qpp > 0 ? <CustomTableRow>
                                            <CustomTableCell>
                                                Quebec Pension Plan
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.qpp)}
                                            </CustomTableCell>
                                        </CustomTableRow> : ""}
                                        {/* QPIP */}
                                        {holidayPayroll.qpip > 0 ? <CustomTableRow>
                                            <CustomTableCell>
                                                Quebec Parental Insurance Plan
                                            </CustomTableCell>
                                            <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.qpip)}
                                            </CustomTableCell>
                                        </CustomTableRow> : ""}

                                        <CustomTableRow>
                                            <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(totalDeduction)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                            {/* Employer Contributions */}
                            <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                                <Table size="small" style={{ marginTop: 8 }}>
                                    <TableHead>
                                        <CustomTableRow>
                                            <CustomTableCell colSpan={3}>Employer Contributions</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                        </CustomTableRow>
                                    </TableHead>
                                    <TableBody>

                                        {/* grizzlyEI */}
                                        <CustomTableRow >
                                            <CustomTableCell colSpan={3}>
                                                Employer Employment Insurance Contribution
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grizzlyEI)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* grizzlyCPP */}
                                        <CustomTableRow >
                                            <CustomTableCell colSpan={3}>
                                                Employer Canadian Pension Plan Contribution
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grizzlyCPP)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* eht */}
                                        <CustomTableRow >
                                            <CustomTableCell colSpan={3}>
                                                Employer Health Tax
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.eht)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                        {/* Employer QPP */}
                                        {holidayPayroll.grizzlyQPP > 0 ? <CustomTableRow >
                                            <CustomTableCell colSpan={3}>
                                                Employer Quebec Pension Plan
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grizzlyQPP)}
                                            </CustomTableCell>
                                        </CustomTableRow> : ""}
                                        {/* Employer QPIP */}
                                        {holidayPayroll.grizzlyQPIP > 0 ? <CustomTableRow >
                                            <CustomTableCell colSpan={3}>
                                                Employer Quebec Parental Insurance Plan
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(holidayPayroll.grizzlyQPIP)}
                                            </CustomTableCell>
                                        </CustomTableRow> : ""}

                                        <CustomTableRow>
                                            <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {formatMoney(totalContributions)}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </>
    )
};



export const useStyles = makeStyles(({ spacing, zIndex, transitions, shape, palette }) => ({
    root: {
        backgroundColor: palette.grey[100],
        border: `0.7px solid ${palette.grey[300]}`,
        borderRadius: shape.borderRadius,
        marginTop: spacing(0),
        marginBottom: spacing(2),
        paddingTop: spacing(1),
    },
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: "#fff",
    },

    tableContainer: {
        backgroundColor: palette.grey[100],
        border: `0.8px solid ${palette.grey[400]}`,
        borderRadius: shape.borderRadius,
        marginTop: spacing(0),
        marginBottom: spacing(0),
        paddingTop: spacing(1),
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        paddingBottom: spacing(2),
        height: "100%"
    },
    badgeRoot: {
        marginTop: spacing(0),
        marginBottom: spacing(0),
    },
    square: {
        color: palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    rounded: {
        color: "#fff",
        backgroundColor: green[500],
    },
    titleContainer: {
        padding: `${spacing(1)} ${spacing(2)}`,
        minHeight: 64,
    },
    transferCount: {
        marginRight: spacing(2),
    },
    leftContainer: {
        marginRight: spacing(1),
    },
    rightContainer: {
        marginLeft: spacing(1),
    },
    centerContainer: {
        marginLeft: spacing(0.5),
        marginRight: spacing(0.5),
    },
    searchInputLabel: {},
    table: {
        flex: 1,
        borderRadius: shape.borderRadius / 1.5,
        border: `0.7px solid ${palette.grey[400]}`,
        width: "100%",
        paddingBottom: spacing(2),
    },
    "rt-td": {
        height: " 25px!important",
    },
    input: {
        borderRadius: 4,
        position: "relative",
        width: "100%",
        backgroundColor: palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 13,
        padding: "0px 26px 0px 12px",
        transition: transitions.create(["border-color", "box-shadow"]),
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
        },
    },
    paper: {
        border: "1px solid",
        padding: spacing(1),
        backgroundColor: palette.background.paper,
        alignSelf: "flex-end",
        position: "relative !important",
    },

    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: spacing(1),
    },
}));
