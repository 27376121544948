import DisableCellOverlay from 'components/DisableCellOverlay/DisableCellOverlay';
import React from 'react'
import { useSelector } from 'react-redux';
import { selectors as ReportsSelectors } from "store/modules/reports";

export default function DisableCell(props) {
    const isEditWorkOrderNumber = useSelector(ReportsSelectors.getIsEditableWorkOrderNumber);
    return (
        <div style={{ position: "relative" }}>
            {props.children}
            <DisableCellOverlay visible={isEditWorkOrderNumber}/>
        </div>
    )
};
