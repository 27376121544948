import React from "react";
import TagsInput from "components/TagsInput";
import { Grid, TextField, MenuItem, IconButton, Typography } from "@mui/material";
import Button from "components/Button/Button";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import GearsDropdown from "./GearsDropdown";
import SkillsDropdown from "./SkillsDropdown";

const useStyles = makeStyles(() => ({
    selectedSection: {
        '& p': {
            textTransform: 'uppercase'
        },
        '& .MuiChip-root': {
            marginRight: '10px'
        }
    },
    addedSkillList: {
        border: '1px solid #e3e3ec',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '10px',
        '& .content': {
            width: 'calc(100% - 20px)',
        },
        '& .closeButton': {
            position: 'absolute',
            padding: '8px',
            right: '0',
            top: '3px'
        }
    },
    address: {
        marginTop: '0px'
    },
    gears: {
        color: '#212a34',
        display: 'block',
        fontSize: '14px',
        paddingBottom: '3px'
    }
}));

const breakTypes = [
    {
        value: true,
        label: "Paid"
    },
    {
        value: false,
        label: "Not paid"
    }
]

function AddJobTemplateView(props) {

    const {
        formik,
        jobType,
        breakMins,
        breakType,
        jobTypeList,
        selectedLocation,
        skillArray,
        JobTemplateId,
        skills,
        gears,
        onAddTagsChange,
        onAddSkillChange,
        onAddressChange,
        onJobTypeChange,
        onbreakMinsChange,
        onBreakTypeChange,
        handleChangeSkill,
        addSkill,
        handleDeleteSkill
    } = props

    const classes = useStyles();

    return (
        <Grid container item xs={12} direction="column" spacing={2}>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    placeholder="Template Name"
                    id="templateName"
                    name="templateName"
                    label="Template Name"
                    margin="none"
                    value={formik.values.templateName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.templateName && formik.errors.templateName}
                    helperText={formik.touched.templateName && formik.errors.templateName}
                    required
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <TextField
                    select
                    label="Work Type"
                    value={jobType}
                    onChange={onJobTypeChange}
                    margin="none"
                    required
                    fullWidth
                >
                    {jobTypeList.map(({ id, label }) => (
                        <MenuItem key={`jobType-${id}`} value={id}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <TextField
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    multiline
                    minRows={3}
                    margin="none"
                    placeholder="Write here..."
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description}
                    helperText={formik.touched.description && formik.errors.description}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <AddressAutocomplete
                    id="address"
                    name="address"
                    margin="none"
                    className={classes.address}
                    value={JobTemplateId ? selectedLocation : undefined}
                    placeholder="Search an address..."
                    onChange={onAddressChange}
                    error={formik.touched.address && formik.errors.address}
                    helperText={formik.errors.address && formik.touched.address && formik.errors.address}
                    required
                />
            </Grid>

            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: '20px' }}
            >
                <Grid item xs={4} style={{ paddingRight: 4 }} >
                    <TextField
                        fullWidth
                        placeholder="Postal Code"
                        id="postalCode"
                        name="postalCode"
                        label="Postal Code"
                        margin="none"
                        value={formik.values?.postalCode?.replace?.(" ", "")?.toUpperCase()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.postalCode && formik.errors.postalCode}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                    />
                </Grid>
                <Grid item xs={4} style={{ paddingRight: 4 }} >
                    <TextField
                        fullWidth
                        placeholder="Unit"
                        id="unit"
                        name="unit"
                        label="Unit"
                        margin="none"
                        value={formik.values.unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.unit && formik.errors.unit}
                        helperText={formik.touched.unit && formik.errors.unit}
                    />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 4 }}>
                    <TextField
                        fullWidth
                        placeholder="Floor"
                        id="floor"
                        name="floor"
                        label="Floor"
                        margin="none"
                        value={formik.values.floor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.floor && formik.errors.floor}
                        helperText={formik.touched.floor && formik.errors.floor}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <TextField
                    fullWidth
                    placeholder="Location Instruction"
                    id="locationInstruction"
                    name="locationInstruction"
                    label="Location Instruction"
                    margin="none"
                    value={formik.values.locationInstruction}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.locationInstruction && formik.errors.locationInstruction}
                    helperText={formik.touched.locationInstruction && formik.errors.locationInstruction}
                />
            </Grid>

            {/* <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                style={{ marginTop: '20px' }}
            >
                <Grid container item xs={6} style={{ paddingRight: 4 }} >
                    <TextField
                        fullWidth
                        id="breakMin"
                        label="Break Min"
                        value={breakMins}
                        onChange={onbreakMinsChange}
                        type="number"
                        margin="none"
                        inputProps={{
                            min: 0,
                        }}
                    />
                </Grid>
                <Grid container item xs={6} style={{ paddingLeft: 4 }} >
                    <TextField
                        select
                        label="Break Type"
                        value={breakType}
                        onChange={onBreakTypeChange}
                        margin="none"
                        fullWidth
                        required
                    >
                        {breakTypes.map(({ value, label }) => (
                            <MenuItem key={`breakType-${value}`} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid> */}

            <Grid item xs={12} style={{ marginTop: '20px' }} >
                <TextField
                    fullWidth
                    id="peopleNeeded"
                    name="peopleNeeded"
                    label="People Needed"
                    placeholder="People Needed"
                    type="number"
                    margin="none"
                    value={formik.values.peopleNeeded}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.peopleNeeded && formik.errors.peopleNeeded}
                    helperText={formik.touched.peopleNeeded && formik.errors.peopleNeeded}
                    required
                />
            </Grid>


            <Grid item xs={12} style={{ marginTop: "10px",position: "relative" }}>
                <div className={classes.gears}>Gears *</div>
                <GearsDropdown
                    selectedTags={onAddTagsChange}
                    fullWidth
                    variant="outlined"
                    id="gears"
                    name="gears"
                    placeholder="Add Gear"
                    JobTemplateId={JobTemplateId}
                    defaultValue={JobTemplateId ? gears : formik.values.gears}
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px",position: "relative" }}>
                <div className={classes.gears}>Skills *</div>
                <SkillsDropdown
                    selectedTags={onAddSkillChange}
                    fullWidth
                    variant="outlined"
                    id="skills"
                    name="skills"
                    placeholder="Add Skills"
                    JobTemplateId={JobTemplateId}
                    defaultValue={JobTemplateId ? skills : formik.values.skills}
                />
            </Grid>

        </Grid>
    );
}

export default AddJobTemplateView
