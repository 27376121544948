import { BrokenImage } from '@mui/icons-material'
import { Avatar, Dialog, Slide } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useLoaderStyels } from './styles'
import ReactViewer from 'react-viewer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

export default React.memo(function ImageColumn({ src }) {
    const classes = useLoaderStyels();
    const [open, setOpen] = useState(false);
    const [transformRef, setTransformRef] = useState({ current: null });

    const transformRefNode = useCallback(node => {
        if (node !== null) {
            setTimeout(() => {
                setTransformRef({ current: node });
            }, 100);
        }
    }, [open]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ImageViewer = () => {
        return transformRef.current ? <ReactViewer
            container={transformRef.current}
            className={classes.imageViewer}
            scalable={false}
            downloadable={false}
            loop={false}
            attribute={false}
            noImgDetails
            noNavbar
            noResetZoomAfterChange
            changeable={false}
            visible={open}
            customToolbar={() => {
                return [
                    { actionType: 1, key: "zoomIn" },
                    { actionType: 2, key: "zoomOut" },
                    { actionType: 5, key: "rotateLeft" },
                    { actionType: 6, key: "rotateRight" },
                    { actionType: 7, key: "reset" }
                ];
            }}
            onClose={() => setOpen(false)}
            images={[{ src: src }]}
        /> : '';
    };

    return (
        <>
            <Avatar
                aria-describedby={src}
                onClick={handleClick}
                key={src}
                className={classes.root}
                src={src}
                variant="rounded">
                <div className={classes.fallback} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <BrokenImage />
                </div>
            </Avatar>
            <Dialog
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                className={classes.imageViewer}
                classes={{ paper: classes.dialogPaper }}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div ref={transformRefNode} className='image-viewer-wrapper'>
                    <ImageViewer />
                </div>
            </Dialog>
        </>
    )
}, (p, c) => p.src && c.src && p.src === c.src);
