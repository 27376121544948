import { BrokenImage } from '@mui/icons-material'
import { alpha, Avatar, Dialog, Slide } from '@mui/material'
import React, { useState, useCallback } from 'react'
import Loader from "assets/img/CircullerProgress.svg";
import { makeStyles } from '@mui/styles';
import ReactViewer from 'react-viewer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

export default React.memo(function ImageWithPreview({ src, style, height, width }) {
    const classes = useStyles({ height, width });
    const [open, setOpen] = useState(false);
    const [transformRef, setTransformRef] = useState({ current: null });

    const transformRefNode = useCallback(node => {
        if (node !== null) {
            setTimeout(() => {
                setTransformRef({ current: node });
            }, 100);
        }
    }, [open]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ImageViewer = () => {
        return transformRef.current ? <ReactViewer
            container={transformRef.current}
            className={classes.imageViewer}
            scalable={false}
            downloadable={false}
            loop={false}
            attribute={false}
            noImgDetails
            noNavbar
            noResetZoomAfterChange
            changeable={false}
            visible={open}
            customToolbar={(a) => {
                return [
                    { actionType: 1, key: "zoomIn" },
                    { actionType: 2, key: "zoomOut" },
                    { actionType: 5, key: "rotateLeft" },
                    { actionType: 6, key: "rotateRight" },
                    { actionType: 7, key: "reset" }
                ];
            }}
            onClose={() => setOpen(false)}
            images={[{ src: src }]}
        /> : '';
    };

    return (
        <>
            <Avatar
                aria-describedby={src}
                onClick={handleClick}
                key={src}
                className={classes.root}
                style={style || {}}
                src={src}
                variant="rounded">
                <div className={classes.fallback} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <BrokenImage />
                </div>
            </Avatar>
            <Dialog
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                className={classes.imageViewer}
                classes={{ paper: classes.dialogPaper }}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div className="image-viewer-wrapper" ref={transformRefNode}>
                    <ImageViewer />
                </div>
            </Dialog>
        </>
    )
}, (p, c) => (
    (p.src && c.src && p.src === c.src) &&
    (p.style && c.style && p.style === c.style) &&
    (p.width && c.width && p.width === c.width) &&
    (p.height && c.height && p.height === c.height)
));




export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[400],
        backgroundImage: `url(${Loader})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: ({ height }) => height || 44,
        width: ({ width }) => width || 44,
        cursor: "pointer",
        "& img": {
            objectPosition: "right",
            backgroundColor: theme.palette.grey[400],
        }
    },
    fallback: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        cursor: "default",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: "90%",
            width: "90%",
        }
    },
    fallbackPreview: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: 300,
            width: 300,
        }
    },
    closePreviewButton: {
        position: "absolute",
        zIndex: 1,
        background: alpha(theme.palette.common.white, 0.6),
        right: -30,
        top: -27
    },
    imageViewer: {
        "& .MuiPaper-root": {
            // overflow: "visible",
            "& .MuiAvatar-root": {
                borderRadius: 4,
                backgroundColor: theme.palette.grey[400],
                backgroundImage: `url(${Loader})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }
        },
        "& .react-transform-wrapper": {
            maxWidth: "100%",
            maxHeight: "calc(100% - 64px)",
            height: "calc(100% - 64px)",
            width: "100%",
            // width:500,
            // height:350
        },
        "& .MuiToolbar-root": {
            paddingLeft: "8px !important",
            paddingRight: "8px !important",
        },
        "& .image-viewer-wrapper": {
            height: "100%",
            width: "100%",
        },
        "& .react-viewer-mask": {
            // backgroundColor: "white !important"
        },
        "& .react-viewer-footer": {
            top: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            bottom: "unset",
            "& > div > ul": {
                display: "flex",
                alignItems: "center",
                marginBottom: "0 !important",
                justifyContent: "left"
            }
        },
        "& .react-viewer-canvas": {
            top: "60px !important",
            maxWidth: "100%",
            maxHeight: "calc(100% - 60px)",
            height: "calc(100% - 60px)",
            width: "100%",
        },
        "& .react-viewer-btn": {
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
            }
        },
        "&  .react-viewer-close": {
            borderRadius: "50%",
            height: 28,
            width: 28,
            top: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 16,
            "& > i": {
                top: "unset",
                left: "unset",
            }
        },
    },
    preview: {
        margin: 0,
        minHeight: 56,
        minWidth: 56,
        height: "auto",
        width: "auto",
        maxHeight: "auto",
        maxWidth: "auto",
        "& img": {
            backgroundColor: theme.palette.grey[400],
        }
    },
    dialogPaper: {
        height: "100%",
        maxHeight: "100%",
        width: "80%",
        maxWidth: "80%",
        margin: "0px !important",
        "& > div": {
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
        }
    },
    loader: {
        position: "absolute",
        top: 64,
        left: 0,
        height: "calc(100% - 64px)",
        width: "100%",
        background: "#c9c6c687",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));
