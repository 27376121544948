import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

export const getPositionsPaginationData = state => {
    return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.positions)];
};

export const getWorkerNotesPaginationData = state => {
    return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.workerNotes)];
};

export const getWorkerBannedFromPaginationData = state => {
    return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.workerBannedFrom)];
};

export const getTransportationTypes = state => {
    return state.workerDetails.transportationTypes || [];
};

export const getWorkerDetails = state => {
    return state.workerDetails;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.workerDetails.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsPositionsLoading = getLoadingStateFactory("positions");
export const getIsUpdatingWorkPermit = getLoadingStateFactory("updatingWorkPermit");

export const getIsWorkerNotesLoading = getLoadingStateFactory("getIsWorkerNotesLoading");
export const getIsWorkerBannedFromLoading = getLoadingStateFactory("getIsWorkerBannedFromLoading");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsDCTransactionLoading = getLoadingStateFactory("DCTransaction");
export const getRequirementWiseApproveAllLoading = getLoadingStateFactory("requirementWiseApproveAll");
export const getRequirementWiseApproveLoading = getLoadingStateFactory("requirementWiseApprove");
export const getRequirementWiseRejectLoading = getLoadingStateFactory("requirementWiseReject");
export const getRejectedAdditionalDocLoading = getLoadingStateFactory("rejectedAdditionalDocConsentLoading");
export const getIsDocumentApproveLoading = getLoadingStateFactory("documentApproveLoading");
export const getIsDocumentRejectLoading = getLoadingStateFactory("documentRejectLoading");
export const getRejectedAlternateLoading = getLoadingStateFactory("alternateDocLoading");
export const getRejectedResubmissionLoading = getLoadingStateFactory("resubmissionDocLoading");
export const getUploadApproveLoading = getLoadingStateFactory("uploadApproveDocumentLoading");

export const getDialogStateFactory = loader => state => {
    return state.workerDetails.dialog[loader] || false;
};

export const getRequirementWiseApproveAllDialog = getDialogStateFactory("requirementWiseApproveAll");
export const getApproveRejectConfirmationDilog = getDialogStateFactory("approveRejectDialog");
export const getApproveRequirementDilog = getDialogStateFactory("approveRequirementDialog");
export const getRejectConsentDilog = getDialogStateFactory("rejectConsentDialog");
export const getRejectedInfoDialog = getDialogStateFactory("rejectedDialog");
export const getRejectedAdditionalDocDialog = getDialogStateFactory("rejectedAdditionalDocConsentDialog");
export const getApproveDocumentDilog = getDialogStateFactory("approveDocumentDialog");
export const getRejectDocumentDilog = getDialogStateFactory("rejectDocumentDialog");
export const getDocumentRejectedInfoDialog = getDialogStateFactory("rejectedDocumentInfoDialog");
export const getAlternateDocDialog = getDialogStateFactory("alternateDocumentDialog");
export const getResubmissionDialog = getDialogStateFactory("resubmissionDocumentDialog");
export const getUploadApproveDialog = getDialogStateFactory("uploadApproveDocumentDialog");


// Requirements
export const getWorkerBasicInfo = state => state?.workerDetails?.workerBasicDetails;
export const getRequirementFilters = state => state?.workerDetails?.requirementsFilters;
export const getRequirementActiveTab = (state) => state.workerDetails.requirementActiveTab;
export const getPerRequirementsData = (state) => state.workerDetails.perRequirements || [];
export const getPerEmployersData = (state) => state.workerDetails.perEmployers || [];
export const getHighlightedRequirement = (state) => state.workerDetails.selectedRequirementForHighlight;
export const getPerRequirementTabelLoading = getLoadingStateFactory("perRequirement");
