import { Button, CircularProgress, Dialog, DialogTitle, FormControl, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import RequestNewIcon from 'assets/img/requestNew.svg';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';
import EmployerRequirementsAutocomplete from 'components/Autocomplete/EmployerRequirementsAutocomplete';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5)
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(6),
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    link: {
        fontSize: 12,
        marginTop: theme.spacing(0.5),
        cursor: 'pointer',
        color: '#2175D9',
        fontWeight: 500,
        '&:hover': {
            color: '#4f96ec',
        }
    }
}));

export default function RejectedAdditionalDocument({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const isLoading = useSelector(Selectors.getRejectedAdditionalDocLoading);
    const selectedRequirement = useSelector(Selectors.getRejectedAdditionalDocDialog);
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const [selectedDoc, setSelectedDoc] = useState(null);
    const selectedJob = useSelector(Selectors.getSelectedJob);

    useEffect(() => {
        if (!open) {
            setReason('');
            setSelectedDoc(null);
        }
    }, [open]);

    const handleSubmit = () => {
        dispatch(Actions.requestForAdditionalDocForConsent(reason, selectedDoc?.value))
    };

    const redirectToAssignPage = () => {
        window.open(`/employers/assign-requirement/${window.btoa(selectedJob?.employer?.id)}`, '_blank')
    }

    return (
        <Dialog maxWidth={'xs'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={RequestNewIcon} className={classes.icon} />}
                    <IconButton
                        disabled={isLoading}
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <Typography className={classes.title}>
                    Request for an Additional Document for <b>{selectedRequirement?.employerRequirement?.requirement?.name || '-'}</b> for <b>{selectedWorker?.worker?.fullName || '-'}</b>?
                </Typography>
                <div>
                    <FormControl fullWidth>
                        <div className={classes.label} style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center' }}>
                            Select document name
                        </div>
                        <EmployerRequirementsAutocomplete
                            extraFilter={[
                                { field: 'type', value: 'Document', relation: "REQUIREMENT", },
                                { field: 'id', value: selectedJob?.employer?.id, relation: 'Employer' },
                                { field: 'label', value: selectedJob?.workType?.label, relation: 'WorkType' },
                            ]}
                            value={selectedDoc}
                            onChange={(val) => setSelectedDoc(val)}
                            defaultOptions={false}
                            menuPortalTarget={document.body}
                            placeholder="Type..." />
                        <span onClick={redirectToAssignPage} className={classes.link}>Click here to assign new requiremente for {selectedJob?.employer?.companyName}</span>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: 40 }}>
                        <TextField
                            label="Enter Reason (For Talent)"
                            autoFocus
                            fullWidth
                            placeholder={`Enter reason here`}
                            multiline
                            rows={4}
                            value={reason}
                            onChange={e => {
                                setReason(e.target.value);
                            }}
                        />
                    </FormControl>
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        disabled={isLoading || !reason?.trim() || !selectedDoc}
                        onClick={handleSubmit}
                    >
                        Submit
                        {isLoading && <CircularProgress size={24} className={classes.progress} />}
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="text"
                        disabled={isLoading}
                        className={classes.button}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
