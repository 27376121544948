import React, { useEffect } from 'react'
import Filters from './components/Filters'
import Jobs from './components/Jobs';
import { Grid, Paper } from '@mui/material';
import AppliedWorkers from './components/AppliedWorkers';
import RequirementDetailSection from './components/RequirementDetailSection';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';


export default function NeedAttentions() {
    const selectedJob = useSelector(Selectors.getSelectedJob);
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.setSelectedJob(null));
            dispatch(Actions.setJobApplicationsData([], 'data'));
            dispatch(Actions.setSelectedWorker(null));
            dispatch(Actions.setSelectedRequirements([]));
            dispatch(Actions.setJobApplicationFilter([]));
        };
    }, []);

    return (
        <>
            <Filters />
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Jobs />
                </Grid>
                <Grid item xs={3.5} style={{ position: 'relative', }}>
                    <Slide key={selectedJob?.id} direction="right" in={Boolean(selectedJob)} mountOnEnter unmountOnExit>
                        <Paper elevation={0}>
                            <AppliedWorkers />
                        </Paper>
                    </Slide>
                </Grid>
                <Grid item xs={5.5} style={{ overflowX: 'hidden' }}>
                    <Slide key={selectedWorker?.id} direction="left" in={Boolean(selectedWorker)} mountOnEnter unmountOnExit>
                        <Paper elevation={0} style={{ background: 'transparent', position: 'relative' }}>
                            <RequirementDetailSection />
                        </Paper>
                    </Slide>
                </Grid>
            </Grid>
        </>
    )
}
