export default {
    SET_LOADING_STATE: "AdditionalRequiremnts/SET_LOADING_STATE",
    SET_DIALOG_STATE: "AdditionalRequiremnts/SET_DIALOG_STATE",
    SET_ADDITIONAL_REQUIREMENTS_DATA: "AdditionalRequiremnts/SET_ADDITIONAL_REQUIREMENTS_DATA",
    SET_ADDITIONAL_REQUIREMENTS_FILTER: "AdditionalRequiremnts/SET_ADDITIONAL_REQUIREMENTS_FILTER",
    SET_ADDITIONAL_REQUIREMENT_DETAILS: "AdditionalRequiremnts/SET_ADDITIONAL_REQUIREMENT_DETAILS",
    SET_FORM_VALUES: "AdditionalRequiremnts/SET_FORM_VALUES",
    SET_EDIT_FORM_VALUES: "AdditionalRequiremnts/SET_EDIT_FORM_VALUES",
    CLEAR_FORM: "AdditionalRequiremnts/CLEAR_FORM",
    SET_ACTIVE_TAB: "AdditionalRequiremnts/SET_ACTIVE_TAB",
    SET_ASSIGNED_EMPLOYERS_DATA: 'SET_ASSIGNED_EMPLOYERS_DATA',
    SET_ADD_NEW_ROW: 'SET_ADD_NEW_ROW',
    SET_REMOVE_ROW: 'SET_REMOVE_ROW',
    UPDATE_ASSIGNED_EMPLOYER_FORM: 'UPDATE_ASSIGNED_EMPLOYER_FORM',
    CLEAR_ASSIGNED_FORM: 'CLEAR_ASSIGNED_FORM',
    SET_EXISTING_RECORDS: 'SET_EXISTING_RECORDS',
    SET_ALL_ASSIGNED_EMPLOYERS: 'SET_ALL_ASSIGNED_EMPLOYERS',
};
