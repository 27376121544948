import gql from "graphql-tag";

export const GetProvinceBreakRule = gql`
query GetProvinceBreakRule {
    BreakRules {
      id
      provinceId
      minShiftLength
      maxShiftLength
      minBreakMinutes
      breakType
      isActive
      province{
        id
        name
        code
        isActive
        countryId
      }
  }
}`;
