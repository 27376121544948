import { Edit } from '@mui/icons-material';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import Line from 'components/Placeholders/Line';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import moment from 'moment';
import React, { useMemo } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as HolidayActions, selectors as HolidaySelectors } from "store/modules/holidays";
import { formatMoney } from 'utils/stringUtils';
import { convertUTCToTimezoneWithOffset, formatDurationFromMins } from 'utils/time';

export default function PayrollSummary({ province, holiday, ...props }) {
    const classes = useStyles();
    const isOpen = useSelector(HolidaySelectors.getIsPayrollSummaryDialog);
    const payrollSummary = useSelector(HolidaySelectors.getHolidayPayrollSummaryWorkers);
    const isInitializingPayroll = useSelector(HolidaySelectors.getIsInitializingHolidayPayroll);
    const isPayingPosition = useSelector(HolidaySelectors.getIsPayPosition);
    const dispatch = useDispatch();

    const grossAmount = useMemo(() => {
        return payrollSummary.reduce((acc, { grossPay }) => acc + grossPay, 0);
    }, [payrollSummary]);

    useEffect(() => {
        if (isOpen && holiday?.id && province?.provinceId) {
            dispatch(HolidayActions.initializeHolidayPayrollDetail(province?.provinceId, holiday?.id));
        };
    }, [isOpen, holiday?.id, province?.provinceId]);

    const payPosition = (workerId) => {
        dispatch(HolidayActions.payPosition({ workerId, provinceId: province?.provinceId, holidayId: holiday?.id }, handleCancel));
    };

    const columns = [
        {
            // eslint-disable-next-line react/display-name, react/prop-types
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return null;
            },
            id: "profileImage",
            headerStyle: { minWidth: 80 },
            collapse: true,
            // eslint-disable-next-line react/display-name, react/prop-types
            accessor: ({ worker }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="circular" height={40} width={40} />;
                }
                return <Avatar src={worker.signedProfileImageUrl} />;
            },
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Worker";
            },
            id: "firstName",
            accessor: ({ worker }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return (worker.firstName ? worker.firstName : '') + ' ' + (worker.lastName ? worker.lastName : '');
            },
            disableSortBy: true,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Email";
            },
            id: "email",
            accessor: ({ worker }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return worker.email
            },
            disableSortBy: true,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Gross";
            },
            id: "grossPay",
            width: 90,
            accessor: ({ grossPay }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return formatMoney(grossPay);
            },
            disableSortBy: true,
        },
        {
            Header: () => {
                return "";
            },
            id: "id",
            headerStyle: { minWidth: 120 },
            collapse: true,
            accessor: ({ worker: { id } }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return (
                    <>
                        <Button
                            label="Pay"
                            onClick={() => payPosition(id)}
                            color="secondary"
                            isLoading={isPayingPosition === id}
                            buttonClassName={classes.updateBtn}
                            size={'small'}
                            disabled={isInitializingPayroll || isPayingPosition}
                        />
                    </>
                );
            },
            disableSortBy: true,
        },
    ];


    const onConfirm = () => {
        dispatch(HolidayActions.payHolidayPayment({ provinceId: province?.provinceId, holidayId: holiday?.id }, handleCancel));
    };

    const handleCancel = () => {
        dispatch(HolidayActions.setIsPayrollSummaryDialog(false));
        dispatch(HolidayActions.setHolidayWorkersPayrollSummary([]));
    };

    return (
        <>
            <Dialog fullWidth maxWidth="lg" classes={{ paper: classes.modal }} open={isOpen}>
                <DialogTitle className={classes.title}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5" align="left">
                                {`Payroll Summary for ${holiday?.name} holiday`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" align="right">
                                {moment(holiday?.holidayDate).format("dddd, MMMM Do, YYYY")}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <ReactTableSimple
                        style={{ boxShadow: "none" }}
                        loading={isInitializingPayroll}
                        classes={classNames("-striped", classes.table)}
                        rowsPerPageOptions={{ rowsPerPageOptions: [10] }}
                        data={payrollSummary}
                        columns={columns}
                    />
                </DialogContent>

                <Grid container justifyContent="flex-end" className={classes.totalContainer}>
                    <Grid item className={classes.totalLabel}>
                        <Typography variant="subtitle1" align="right">
                            {"Total"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Line className={classes.totalLine} />
                        {/* netAmount */}
                        <Typography variant="h4">{formatMoney(grossAmount)}</Typography>
                    </Grid>
                </Grid>

                <DialogActions className={classes.footer}>
                    <Button
                        label="Cancel"
                        onClick={handleCancel}
                        color="transparent"
                        disabled={isInitializingPayroll || isPayingPosition}
                    />
                    <Button
                        label="Confirm & Pay"
                        onClick={onConfirm}
                        color="secondary"
                        isLoading={isPayingPosition === true}
                        disabled={(!payrollSummary || !payrollSummary?.length) || isInitializingPayroll || isPayingPosition}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
};

const useStyles = makeStyles(({ spacing, typography, customPalette }) => ({
    modal: {
        padding: spacing(3),
        // overflowY: "unset",
        maxWidth: '100%'
    },
    title: {
        padding: '0 8px'
    },
    table: {
        ...typography.body1,
        border: "none",
        "&.table thead": {
            background: "transparent !important",
            backgroundColor: "transparent !important",
        },
        "&.table thead th": {
            ...typography.caption,
            background: "transparent !important",
            backgroundColor: "transparent !important",
            fontWeight: "bold",
        },
        "& tbody": {
            // maxHeight: 250,
            overflowY: "auto",
        },
    },
    footer: {
        margin: 0,
    },
    warning: {
        backgroundColor: customPalette.yellow[100],
    },
    warningContainer: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    warningIcon: {
        color: customPalette.yellow[700],
        marginRight: spacing(0.5),
    },
    rateSection: {
        position: 'relative'
    },
    rateText: {
    },
    editIcon: {
        cursor: 'pointer',
        marginRight: spacing(0.5),
        position: 'absolute'
    },
    payIcon: {
        color: customPalette.green[700],
        marginRight: spacing(0),
    },
    totalContainer: {
        marginTop: spacing(3),
        marginBottom: spacing(3),
        padding: spacing(1),
    },
    totalLabel: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-end",
        alignItems: "flex-end",
        marginRight: spacing(4),
        paddingTop: spacing(2),
    },
    totalLine: {
        width: "100%",
        height: 2,
        marginBottom: 16,
    },
    content: {
        padding: 8,
        overflow: 'initial',
        marginTop: spacing(2)
    },
    updateBtn: {
        padding: "0 32px !important"
    }
}));
