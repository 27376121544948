import { Button, ButtonBase, CircularProgress, Dialog, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import RejectIcon from 'assets/img/reject.svg';
import { CloseOutlined } from '@mui/icons-material';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as Actions, selectors as Selectors } from 'store/modules/verifications';
import { allowedDocumentsRenderer } from 'components/DocumentViewer/helpers/constants';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 350
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(8),
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '100%',
        width: '100%'
    },
    docContainer: {
        background: '#f7f7fb',
        minWidth: 335,
        width: 335,
        maxWidth: 335,
        height: 214,
        padding: theme.spacing(1.25),
        cursor: 'pointer',
        minHeight: 'min(580px, 90vh)',
        display: 'flex'
    },
    docCard: {
        display: 'flex',
        gap: theme.spacing(5),
        padding: theme.spacing(5),
        position: 'relative'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function DocumentRejectDialog({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const isLoading = useSelector(Selectors.getIsDocumentRejectLoading);
    const requirement = useSelector(Selectors.getDocumentRejectDialog);
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(requirement?.signedDocumentUrl);
    const extension = match?.[1];
    const [reason, setReason] = useState('');
    const exist = allowedDocumentsRenderer?.find(p => p.mime === extension)


    const openDoc = () => {
        if (extension === 'pdf') {
            window.open(requirement?.signedDocumentUrl, '_blank');
            return;
        };
        dispatch(Actions.setDocumentPreviewDialog(requirement));
    };

    useEffect(() => {
        if (!open) setReason('');
    }, [open]);

    const handleReject = () => {
        dispatch(Actions.rejectDocumentRequirement(reason));
    };

    return (
        <Dialog maxWidth={'md'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.docCard}>
                <ButtonBase disableRipple={!exist} LinkComponent={'div'} className={classes.docContainer} onClick={() => exist && openDoc()}>
                    <DocumentViewer style={{ objectPosition: 'center' }} type={extension} document={requirement} />
                </ButtonBase>
                <div className={classes.container}>
                    <div className={classes.modalIconContainer}>
                        {icon || <img src={RejectIcon} className={classes.icon} />}
                    </div>
                    <Typography className={classes.title}>
                        Are you sure you want to reject  <b>{requirement?.employerRequirement?.requirement?.name}</b> for <b>{selectedWorker?.worker?.fullName}</b>?
                    </Typography>
                    <div >
                        <TextField
                            label="Enter Reason (For internal use)"
                            autoFocus
                            fullWidth
                            placeholder={`Enter reason here`}
                            multiline
                            rows={4}
                            value={reason}
                            onChange={e => {
                                setReason(e.target.value);
                            }}
                        />
                    </div>
                    <div className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="error"
                            disabled={isLoading || !reason?.trim()}
                            onClick={handleReject}
                        >
                            Yes, Reject
                            {isLoading && <CircularProgress size={24} className={classes.progress} />}
                        </Button>
                        <Button
                            onClick={onClose}
                            variant="text"
                            className={classes.button}
                            disabled={isLoading}
                        >
                            Go Back
                        </Button>
                    </div>
                </div>
                <IconButton className={classes.close} onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </div>
        </Dialog>
    )
}
