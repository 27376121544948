import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { formatMoney } from "../../../../utils/stringUtils";
import { sharedReactTableProps } from "../../../../utils/constant";
import ReactTableCustom from "components/ReactTable";
import { useStyles } from "views/Reports/styles";

function WorkerPayrollTable() {
    const dispatch = useDispatch();
    const payrollData = useSelector(ReportsSelectors.getWorkerPayrollReportData)
    const PaginationData = useSelector(ReportsSelectors.getWorkerPayrollReportPagingData)
    const classes=useStyles();
    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        dispatch(ReportsActions.changePageSize(pageSize))
        dispatch(ReportsActions.fetchWorkerPayrollTableData(pageIndex))
    }, []);

    const columns = [
        {
            Header: <div className="text-center">Week</div>,
            disableSortBy: true,
            disableFilters: true,
            width: 150,
            className: 'text-center',
            accessor: "Week",
        },
        {
            Header: <div className="text-right"># Workers</div>,
            disableSortBy: true,
            disableFilters: true,
            width: 80,
            className: 'text-right',
            accessor: "WorkersCount",
        },
        {
            Header: <div className="text-right">Hours Worked</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "HoursWorked",
            Cell: ({ row: { original } }) => original.HoursWorked + ' hr'
        },
        {
            Header: <div className="text-right">Bonus Value</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "BonusValue",
            Cell: ({ row: { original } }) => formatMoney(original.BonusValue)
        },
        {
            Header: <div className="text-right">Gross Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "GrossPay",
            Cell: ({ row: { original } }) => formatMoney(original.GrossPay)
        },
        {
            Header: <div className="text-right">Net Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "NetPay",
            Cell: ({ row: { original } }) => formatMoney(original.NetPay)
        },
        {
            Header: <div className="text-right">Vacation Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "VacationPay",
            Cell: ({ row: { original } }) => formatMoney(original.VacationPay)
        },
        {
            Header: <div className="text-right">EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "EI",
            Cell: ({ row: { original } }) => formatMoney(original.EI)
        },
        {
            Header: <div className="text-right">CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "CPP",
            Cell: ({ row: { original } }) => formatMoney(original.CPP)
        },
        {
            Header: <div className="text-right">Prov. Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "ProvTax",
            Cell: ({ row: { original } }) => formatMoney(original.ProvTax)
        },
        {
            Header: <div className="text-right">Randstad EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadEI",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadEI)
        },
        {
            Header: <div className="text-right">Randstad CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadCPP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadCPP)
        },
        {
            Header: <div className="text-right">Fed Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "FederalTax",
            Cell: ({ row: { original } }) => formatMoney(original.FederalTax)
        },
        {
            Header: <div className="text-right">QPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "QPP",
            Cell: ({ row: { original } }) => formatMoney(original.QPP),
        },
        {
            Header: <div className="text-right">Randstad QPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "RandstadQPP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadQPP),
        },
        {
            Header: <div className="text-right">QPIP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "QPIP",
            Cell: ({ row: { original } }) => formatMoney(original.QPIP),
        },
        {
            Header: <div className="text-right">Randstad QPIP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "RandstadQPIP",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadQPIP),
        },
        {
            Header: <div className="text-right">Randstad WCB</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "RandstadWCB",
            Cell: ({ row: { original } }) => formatMoney(original.RandstadWCB)
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                fetchData={fetchData}
                {...sharedReactTableProps}
                data={payrollData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                classes={classes.table}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WorkerPayrollTable;
