import { InfoOutlined } from '@mui/icons-material';
import { Button, ButtonBase, Checkbox, Chip, CircularProgress, Dialog, Divider, Skeleton, Slide, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import PerfectScrollWrapper from 'components/Sidebar/PerfectScrollWrapper';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactViewer from 'react-viewer';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';
import BulkApprove from './BulkApprove';
import SentToAgencyDialog from 'views/Verifications/Dialogs/SentToAgency';
import ApproveDialog from 'views/Verifications/Dialogs/Approve';
import DocumentApproveDialog from 'views/Verifications/Dialogs/DocumentApprove';
import RejectDialog from 'views/Verifications/Dialogs/Reject';
import RejectedInfo from 'views/Verifications/Dialogs/RejectedInfo';
import RejectedAdditionalDocument from 'views/Verifications/Dialogs/RejectedAdditionalDocument';
import DocumentRejectDialog from 'views/Verifications/Dialogs/DocumentReject';
import DocumentRejectedInfo from 'views/Verifications/Dialogs/DocumentRejectedInfo';
import AlternateDocument from 'views/Verifications/Dialogs/AlternateDocument';
import ReSubmitDocumentDialog from 'views/Verifications/Dialogs/ReSubmitDocumentDialog';
import { allowedDocumentsRenderer } from 'components/DocumentViewer/helpers/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100% !important',
        height: ({ filters }) => `calc(100vh - ${filters?.length ? 245 : 220}px) !important`,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: 4,
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 4,
            backgroundColor: '#c5ffffba'
        },
        '&::-webkit-scrollbar-track:hover': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-track:active': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            backgroundColor: '#91b4b3',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#009E96'
        },
        '&::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#009E96'
        },
    },
    header: {
        padding: 32,
        paddingRight: 16,
        marginRight: 16,
        paddingBottom: 8,
        position: 'sticky',
        top: 0,
        background: '#f7f7fb',
        zIndex: 1,
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'space-between'
    },
    container: {
        padding: '32px',
        paddingTop: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    outlinedButton: {
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        },
        borderRadius: 50,
        boxShadow: 'none'
    },
    loading: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    CardRoot: {
        display: 'flex',
        maxWidth: '100%'
    },
    dummyWidth: {
        padding: 0,
        marginTop: 2,
        marginRight: theme.spacing(1),
        borderRadius: 4,
        minWidth: 16,
        height: 16,
    },
    checkBox: {
        padding: 0,
        marginTop: 2,
        marginRight: theme.spacing(1),
        borderRadius: 4,
        width: 16,
        height: 16,
        color: '#1C1C1C',
        backgroundColor: 'white',
        '.Mui-focusVisible &': {
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: 'white',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'white',
        },
        '&.Mui-disabled': {
            color: '#0b938b66'
        },
        '&.Mui-disabled.Rejected': {
            color: '#cecece'
        },
        '&.Mui-disabled.AlternateDocument': {
            color: '#cecece'
        },
        '&.Mui-disabled.ReSubmission': {
            color: '#cecece'
        },
        '&.Mui-disabled.Expired': {
            color: '#cecece'
        },
    },
    card: {
        padding: theme.spacing(2),
        flex: 1,
        borderRadius: 5,
        border: `1px solid #0000001F`,
        transition: '0.5s all',
        background: 'white',
        width: 'calc(100% - 21px)',
        overflow: 'hidden',
    },
    Approved: {
        border: `1px solid #009E96`,
        background: '#F3FFFF',
    },
    Rejected: {
        border: `1px solid #E95454`,
        background: '#FFF3F3',
        '& $name': {
            color: '#E95454 !important'
        }
    },
    Expired: {
        border: `1px solid #E95454`,
        background: '#FFF3F3',
        '& $name': {
            color: '#E95454 !important'
        }
    },
    ReSubmission: {
        border: `1px solid #BC8908`,
        background: '#FFF7E2',
    },
    AlternateDocument: {
        border: `1px solid #BC8908`,
        background: '#FFF7E2',
    },
    NewDocument: {
        border: `1px solid #2175D9`,
        background: '#F5F9FF',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    name: {
        fontWeight: 500,
        fontSize: 16,
    },
    info: {
        fontWeight: 400,
        fontSize: 16,
        color: '#3F454D',
        marginTop: 5
    },
    summary: {
        color: '#6B6F75',
        fontSize: 12,
        fontWeight: 500,
        marginTop: 4,
        wordBreak: "break-word",
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    actionContainer: {
        display: 'flex',
        gap: theme.spacing(1.25),
    },
    docActionContainer: {
        display: 'flex',
        gap: theme.spacing(1.25),
        justifyContent: 'flex-end'
    },
    docCard: {
        display: 'flex',
        gap: theme.spacing(1.75),
        flexWrap: 'wrap',
        height: 'fit-content'
    },
    docCardContent: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        '& hr': {
            marginTop: 'auto'
        }
    },
    docContainer: {
        background: '#f7f7fb',
        minWidth: 164,
        width: 164,
        minHeight: 214,
        padding: theme.spacing(1.25),
        cursor: 'pointer',
        flex: 1,
        '& img': {
            maxHeight: 214,
        }
    },
    imageViewer: {
        "& .MuiPaper-root": {
            // overflow:"visible",
            "& .MuiAvatar-root": {
                borderRadius: 4
            }
        },
        "& .react-transform-wrapper": {
            maxWidth: "100%",
            maxHeight: "calc(100% - 64px)",
            height: "calc(600px - 64px)",
            width: "100%",
            // height:350
        },
        "& .MuiToolbar-root": {
            paddingLeft: "8px !important",
            paddingRight: "8px !important",
        },
        "& .image-viewer-wrapper": {
            height: "100%",
            width: "100%",
        },
        "& .react-viewer-mask": {
            // backgroundColor: "white !important"
        },
        "& .react-viewer-footer": {
            top: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            bottom: "unset",
            "& > div > ul": {
                display: "flex",
                alignItems: "center",
                marginBottom: "0 !important",
                justifyContent: "left"
            }
        },
        "& .react-viewer-canvas": {
            top: "60px !important",
            maxWidth: "100%",
            maxHeight: "calc(100% - 60px)",
            height: "calc(100% - 60px)",
            width: "100%",
            '& > div': {
                height: "100% !important",
            }
        },
        "& .react-viewer-btn": {
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
            }
        },
        "&  .react-viewer-close": {
            borderRadius: "50%",
            height: 28,
            width: 28,
            top: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 16,
            "& > i": {
                top: "unset",
                left: "unset",
            }
        },
    },
    dialogPaper: {
        height: 867,
        maxHeight: "min(867px, 90%)",
        width: 1200,
        maxWidth: "min(1200px, 90%)",
        "& > div": {
            maxHeight: "100%",
            maxWidth: "100%",
        }
    },
    approvedChip: {
        color: '#009E96',
        background: '#DAF6F6',
        fontSize: 9,
        fontWeight: 500
    },
    expiredChip: {
        color: '#E95454',
        background: '#FFE3E3',
    },
    rejectedChip: {
        color: '#E95454',
        background: '#FFE3E3',
        fontSize: 9,
        fontWeight: 500
    },
    ReSubmissionChip: {
        color: '#BC8908',
        background: '#FFEDBF',
        fontSize: 9,
        fontWeight: 500
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        alignItems: 'flex-start',
        '& $summary': {
            fontWeight: 400,
            fontSize: 12,
            color: '#6B6F75'
        }
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    rejectionReasonContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    rejectTitle: {
        fontWeight: 500,
        fontSize: 14,
        color: '#292C35'
    },
    NewDocumentAvailable: {
        background: '#E3EFFF',
        fontSize: 9,
        fontWeight: 500,
        color: '#2175D9',
        marginTop: theme.spacing(1),
    },
    newDocContainer: {
        marginTop: theme.spacing(0.5),
        background: 'white',
        padding: theme.spacing(1.5),
        border: '1px solid rgba(0, 0, 0, 0.12)',
        width: '100%',
        borderRadius: 5,
        display: 'flex',
        flexWrap: 'wrap',
        '& $docContainer': {
            // minWidth: '60%',
            // width: '60%',
            minHeight: 150,
            '& img': {
                maxHeight: 150,
            }
        },
        '& $actionContainer': {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            padding: theme.spacing(2.75),
            '& $outlinedButton': {
                width: '100%'
            }
        }
    },
    note: {
        background: 'rgba(227, 239, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        borderRadius: 5,
        padding: theme.spacing(1.25, 2, 1.25, 2),
        marginTop: theme.spacing(0),
        border: `1px solid #0000001F`,
        '& p': {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '20px',
            color: '#000000'
        }
    },
}));


export default function RequirementDetailSection() {
    const pageFilter = useSelector((state) => Selectors.getJobApplicationsData(state, { part: "filter" }));
    const employerFilters = pageFilter?.find(a => a?.id === "Employer_Id");
    const requirementFilters = pageFilter?.find(a => a?.id === "Requirement_Id");
    const workerFilters = pageFilter?.find(a => a?.id === "Worker_Id");
    const flatFilters = [...(employerFilters?.value || []), ...(requirementFilters?.value || []), ...(workerFilters?.value || [])];
    const classes = useStyles({ filters: flatFilters });
    const isLoading = useSelector(Selectors.getIsRequirementsLoading);
    const [transformRef, setTransformRef] = useState({ current: null });
    const requirements = useSelector(Selectors.getSelectedRequirements);
    const selectedRecords = useSelector(Selectors.getRequirementSelection);
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const isOpenSentAgency = useSelector(Selectors.getSentToAgencyDialog);
    const isOpenApprove = useSelector(Selectors.getApproveDialog);
    const isOpenReject = useSelector(Selectors.getRejectDialog);
    const isOpenRejectedInfo = useSelector(Selectors.getRejectedInfoDialog);
    const isOpenDocumentRejectedInfo = useSelector(Selectors.getDocumentRejectedInfoDialog);
    const isOpenRejectedAdditionalDocDialog = useSelector(Selectors.getRejectedAdditionalDocDialog);
    const isOpenResubmissionDialog = useSelector(Selectors.getResubmissionDialog);
    const isOpenAlternateDocDialog = useSelector(Selectors.getAlternateDocDialog);
    const isOpenDocumentApprove = useSelector(Selectors.getDocumentApproveDialog);
    const isOpenDocumentReject = useSelector(Selectors.getDocumentRejectDialog);
    const openPreview = useSelector(Selectors.getDocumentPreviewDialog);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(Actions.setRequirementBulkSelection([], true));
        };
    }, [selectedWorker?.id]);

    useEffect(() => {
        if (selectedWorker && selectedWorker?.id) {
            dispatch(Actions.setSelectedRequirements([]));
            dispatch(Actions.getRequirementsByWorkerApplicationId(selectedWorker.id));
        }
    }, [selectedWorker?.id]);

    const transformRefNode = useCallback(node => {
        if (node !== null) {
            setTimeout(() => {
                setTransformRef({ current: node });
            }, 100);
        }
    }, [Boolean(openPreview)]);

    const openDocumentView = (doc) => {
        const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
        const match = regex.exec(doc?.signedDocumentUrl);
        const extension = match?.[1];
        const exist = allowedDocumentsRenderer?.find(p => p.mime === extension)
        if (exist) {
            if (extension === 'pdf') {
                window.open(doc.signedDocumentUrl, '_blank');
                return;
            };
            dispatch(Actions.setDocumentPreviewDialog(doc));
        }
    };

    const handleClose = () => {
        dispatch(Actions.setDocumentPreviewDialog(false));
    };

    const handleCloseSentAgencyDialog = () => {
        dispatch(Actions.setSentToAgencyDialog(false));
    };

    const handleCloseAprroveDialog = () => {
        dispatch(Actions.setApproveDialog(false));
    };

    const handleCloseRejectDialog = () => {
        dispatch(Actions.setRejectDialog(false));
    };

    const handleCloseRejectedInfoDialog = () => {
        dispatch(Actions.setRejectedInfoDialog(false));
    };

    const handleCloseDocumentRejectedInfoDialog = () => {
        dispatch(Actions.setDocumentRejectedInfoDialog(false));
    };

    const handleCloseRejectedAdditionalDocDialog = () => {
        dispatch(Actions.setRejectedAdditionalDocDialog(false));
    };

    const handleCloseReSubmissionDialog = () => {
        dispatch(Actions.setResubmissionDialog(false));
    };

    const handleCloseAlternateDocDialog = () => {
        dispatch(Actions.setAlternateDocDialog(false));
    };

    const handleCloseDocumentAprroveDialog = () => {
        dispatch(Actions.setDocumentApproveDialog(false));
    };

    const handleCloseDocumentRejecteDialog = () => {
        dispatch(Actions.setDocumentRejectDialog(false));
    };

    const handleSelectRequirement = (requirement) => {
        dispatch(Actions.setRequirementBulkSelection(requirement));
    };

    const ImageViewer = () => {
        return transformRef.current ? <ReactViewer
            container={transformRef.current}
            className={classes.imageViewer}
            scalable={false}
            downloadable={false}
            loop={false}
            attribute={false}
            noImgDetails
            noNavbar
            noResetZoomAfterChange
            changeable={false}
            visible={Boolean(openPreview)}
            customToolbar={() => {
                return [
                    { actionType: 1, key: "zoomIn" },
                    { actionType: 2, key: "zoomOut" },
                    { actionType: 5, key: "rotateLeft" },
                    { actionType: 6, key: "rotateRight" },
                    { actionType: 7, key: "reset" }
                ];
            }}
            onClose={handleClose}
            images={[{ src: openPreview?.signedDocumentUrl }]}
        /> : '';
    };

    return (
        <>
            <PerfectScrollWrapper id={'Requirement-Detail-Section'} className={classes.root}>
                <div className={classes.header}>
                    <Typography fontWeight={700}>
                        Requirement Submissions
                    </Typography>
                    <Button
                        variant="outlined"
                        className={classes.outlinedButton}
                        size="small"
                        onClick={() => window.open(`/workers/${selectedWorker?.worker?.id}`, '_blank')}
                        color="secondary"
                    >
                        Go to Worker Profile
                    </Button>
                </div>
                <div className={classes.container}>
                    {requirements?.map(requirement => {
                        return (
                            <div className={classes.CardRoot} key={requirement?.id}>
                                <Checkbox
                                    onChange={() => handleSelectRequirement(requirement)}
                                    checked={requirement?.applicationStatus === 'Approved' || Boolean(selectedRecords?.find(a => a?.id === requirement?.id))}
                                    disabled={requirement?.applicationStatus === 'Approved' || requirement?.applicationStatus === "Rejected" || requirement?.applicationStatus === "AlternateDocument" || requirement?.applicationStatus === "ReSubmission" || requirement?.applicationStatus === 'Expired'}
                                    disableRipple
                                    className={classNames(classes.checkBox, requirement?.applicationStatus)}
                                    color='secondary' />
                                {requirement?.employerRequirement?.requirement?.type !== 'Document' ? (
                                    <ConsentCard openDoc={openDocumentView} requirement={requirement} />
                                ) : (
                                    <DocumentCard openDoc={openDocumentView} requirement={requirement} />
                                )}
                            </div>
                        )
                    })}
                    {(isLoading) && ([...Array(2)].map(() => (
                        <>
                            <div className={classNames(classes.card, classes.docCard)} style={{ width: '100%' }}>
                                <ButtonBase LinkComponent={'div'} className={classes.docContainer} style={{ padding: 0 }} disabled>
                                    <Skeleton variant="rectangular" style={{ width: '100%', height: '100%' }} />
                                </ButtonBase>
                                <div className={classes.docCardContent}>
                                    <div className={classes.flexBetween}>
                                        <Typography className={classes.name} style={{ width: '60%' }} >
                                            <Skeleton variant="text" />
                                        </Typography>
                                    </div>
                                    <Typography className={classes.summary}>
                                        <Skeleton variant="text" style={{ width: '100%' }} />
                                        <Skeleton variant="text" style={{ width: '100%' }} />
                                        <Skeleton variant="text" style={{ width: '100%' }} />
                                    </Typography>
                                    <Typography className={classes.summary}>
                                        <Skeleton variant="text" style={{ width: '30%' }} />
                                    </Typography>
                                    <Skeleton variant="text" style={{ marginTop: 'auto', width: '100%', height: 5 }} />
                                    <div style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
                                        <Skeleton variant="text" style={{ width: '30%', height: 40 }} />
                                        <Skeleton variant="text" style={{ width: '30%', height: 40 }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )))}

                    {!isLoading &&
                        <div className={classes.CardRoot}>
                            <div className={classes.dummyWidth} />
                            <div className={classes.note}>
                                <Typography>
                                    <b>Note: </b>
                                </Typography>
                                <Typography>
                                    If any of these requirements remain unapproved by the job start time, the system will automatically reject them, allowing the talent to apply for the next job.
                                </Typography>
                            </div>
                        </div>
                    }
                </div>
            </PerfectScrollWrapper>
            {Boolean(selectedRecords?.length) && <BulkApprove />}
            <Dialog
                maxWidth="lg"
                TransitionComponent={Transition}
                open={Boolean(openPreview)}
                onClose={handleClose}
                className={classes.imageViewer}
                classes={{ paper: classes.dialogPaper }}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div ref={transformRefNode} className='image-viewer-wrapper'>
                    <ImageViewer />
                </div>
            </Dialog>
            <SentToAgencyDialog onClose={handleCloseSentAgencyDialog} open={Boolean(isOpenSentAgency)} />
            <ApproveDialog onClose={handleCloseAprroveDialog} open={Boolean(isOpenApprove)} />
            <RejectDialog onClose={handleCloseRejectDialog} open={Boolean(isOpenReject)} />
            <RejectedInfo onClose={handleCloseRejectedInfoDialog} open={Boolean(isOpenRejectedInfo)} />
            <RejectedAdditionalDocument onClose={handleCloseRejectedAdditionalDocDialog} open={Boolean(isOpenRejectedAdditionalDocDialog)} />
            <DocumentApproveDialog onClose={handleCloseDocumentAprroveDialog} open={Boolean(isOpenDocumentApprove)} />
            <DocumentRejectDialog onClose={handleCloseDocumentRejecteDialog} open={Boolean(isOpenDocumentReject)} />
            <DocumentRejectedInfo onClose={handleCloseDocumentRejectedInfoDialog} open={Boolean(isOpenDocumentRejectedInfo)} />
            <AlternateDocument onClose={handleCloseAlternateDocDialog} open={Boolean(isOpenAlternateDocDialog)} />
            <ReSubmitDocumentDialog onClose={handleCloseReSubmissionDialog} open={Boolean(isOpenResubmissionDialog)} />
        </>
    )
};

const ConsentCard = ({ requirement, openDoc }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const additionalDoc = requirement?.childRequirements?.[requirement?.childRequirements?.length - 1];
    // const isApproveLoading = useSelector(Selectors.getIsApproveLoading);
    // const isRejectLoading = useSelector(Selectors.getIsRejectLoading);


    const handleOpenSentToAgencyDialog = () => {
        dispatch(Actions.setSentToAgencyDialog(requirement));
    };

    const openApproveDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setApproveDialog(requirement));
    };

    const openRejectDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setRejectDialog(requirement));
    };

    return (
        <div className={classNames(classes.card, classes[requirement?.applicationStatus])}>
            <div className={classes.flexBetween}>
                <Typography className={classes.name}>{requirement?.employerRequirement?.requirement?.name}</Typography>
                {/* <Tooltip title="Lorem ipsum">
                    <InfoOutlined className={classes.info} />
                </Tooltip> */}
            </div>
            <Typography className={classes.summary}>{requirement?.employerRequirement?.requirement?.summary}</Typography>
            <Typography className={classes.summary}>{requirement?.applicationStatus === "Applied" ? requirement?.documentValidity : ''}</Typography>
            {Boolean(requirement?.applicationStatus === "Rejected") && <div className={classes.rejectionReasonContainer}>
                <Typography className={classes.rejectTitle}>Rejection Reason</Typography>
                <Typography className={classes.summary}>{requirement?.rejectionReason || '-'}</Typography>
            </div>}
            {requirement?.applicationStatus !== "NewDocument" && <Divider className={classes.divider} />}
            {requirement?.applicationStatus === "SentToAgency" && <Typography marginBottom={1} className={classes.summary}>{`Last action by: ${requirement?.approver} on ${moment(requirement?.updatedAt).format("DD MMM 'YY")}`}</Typography>}
            {requirement?.applicationStatus !== "NewDocument" && <div className={classes.flexBetween}>
                {requirement?.applicationStatus === "SentToAgency" ?
                    <Chip size="small" label={"Sent To Agency"} className={classes.ReSubmissionChip} />
                    : requirement?.applicationStatus === "Applied" && <Button
                        variant="outlined"
                        className={classes.outlinedButton}
                        size="small"
                        color="secondary"
                        onClick={handleOpenSentToAgencyDialog}
                    >
                        Send to Agency
                    </Button>}
                {requirement?.applicationStatus !== "Applied" && requirement?.applicationStatus !== "SentToAgency" && requirement?.applicationStatus !== "Expired" ? (
                    <div className={classes.flexColumn}>
                        {requirement?.approver && <Typography className={classes.summary}>{`Last action by: ${requirement?.approver} on ${moment(requirement?.updatedAt).format("DD MMM 'YY")}`}</Typography>}
                        {requirement?.applicationStatus === "Rejected" && <Chip size="small" label={requirement?.applicationStatus} className={classes.rejectedChip} />}
                        {requirement?.applicationStatus === "AlternateDocument" && <Chip size="small" label={`Requested for Alternate Document ${additionalDoc?.employerRequirement?.requirement ? `: ${additionalDoc?.employerRequirement?.requirement.name}` : ''}`} className={classes.ReSubmissionChip} />}
                        {requirement?.applicationStatus === "ReSubmission" && <Chip size="small" label={"Requested for Re-submission"} className={classes.ReSubmissionChip} />}
                        {(requirement?.applicationStatus === 'Approved' || requirement?.applicationStatus === 'Expired') && <Chip size="small" label={+requirement?.validTill <= 0 ? `Expired : ${moment(requirement?.expiryDate).fromNow()}` : requirement?.documentValidity} className={classNames(classes.approvedChip, +requirement?.validTill <= 0 && classes.expiredChip)} />}
                    </div>
                ) : (
                    <div className={classes.actionContainer} style={{ marginLeft: 'auto' }}>
                        <Button
                            variant="contained"
                            className={classes.outlinedButton}
                            size="small"
                            color="error"
                            onClick={openRejectDialog}
                        // disabled={isRejectLoading}
                        >
                            Reject
                            {/* {isRejectLoading && <CircularProgress size={24} className={classes.progress} />} */}
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.outlinedButton}
                            size="small"
                            color="secondary"
                            onClick={openApproveDialog}
                        // disabled={isApproveLoading}
                        >
                            Approve
                            {/* {isApproveLoading && <CircularProgress size={24} className={classes.progress} />} */}
                        </Button>
                    </div>
                )}
            </div>}
            {requirement?.applicationStatus === "NewDocument" && <div className={classes.flexColumn}>
                <Chip size="small" label={`New Document Available`} className={classes.NewDocumentAvailable} />
                <PreviewNewDocument openDoc={openDoc} parentRequirement={requirement} requirements={requirement?.childRequirements} />
            </div>}
            {additionalDoc && (requirement?.applicationStatus === "Approved" || requirement?.applicationStatus === "Rejected") && <div className={classes.flexColumn}>
                <div style={{ marginTop: 8 }} />
                {/* <Chip size="small" label={`Recently Upload`} className={classes.NewDocumentAvailable} /> */}
                <RecentlyProvidedDocument openDoc={openDoc} parentRequirement={requirement} requirements={requirement?.childRequirements} />
            </div>}
        </div>
    )
};

const DocumentCard = ({ openDoc, requirement }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(requirement?.signedDocumentUrl);
    const extension = match?.[1];
    const exist = allowedDocumentsRenderer?.find(p => p.mime === extension)
    const additionalDoc = requirement?.childRequirements?.[requirement?.childRequirements?.length - 1];

    const openApproveDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setDocumentApproveDialog(requirement));
    };

    const openRejectDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setDocumentRejectDialog(requirement));
    };

    return (
        <>
            <div className={classNames(classes.card, classes.docCard, classes[requirement?.applicationStatus])}>
                {requirement?.applicationStatus !== "NewDocument" && <ButtonBase disableRipple={!exist} LinkComponent={'div'} className={classes.docContainer} onClick={() => openDoc(requirement)}>
                    <DocumentViewer type={extension} document={requirement} />
                </ButtonBase>}
                <div className={classes.docCardContent}>
                    <div className={classes.flexBetween}>
                        <Typography className={classes.name}>{requirement?.employerRequirement?.requirement?.name}</Typography>
                        {/* <Tooltip title="Lorem ipsum">
                            <InfoOutlined className={classes.info} />
                        </Tooltip> */}
                    </div>
                    <Typography className={classes.summary}>{requirement?.employerRequirement?.requirement?.description}</Typography>
                    <Typography className={classes.summary}>{requirement?.applicationStatus === "Applied" ? requirement?.documentValidity : ''}</Typography>

                    {Boolean(requirement?.applicationStatus === "Rejected") && <div className={classes.rejectionReasonContainer}>
                        <Typography className={classes.rejectTitle}>Rejection Reason</Typography>
                        <Typography className={classes.summary}>{requirement?.rejectionReason || '-'}</Typography>
                    </div>}

                    {Boolean(requirement?.applicationStatus === "ReSubmission") && <div className={classes.rejectionReasonContainer}>
                        <Typography className={classes.rejectTitle}>Re-submission Reason</Typography>
                        <Typography className={classes.summary}>{requirement?.rejectionReason || '-'}</Typography>
                    </div>}

                    {Boolean(requirement?.applicationStatus === "AlternateDocument") && <div className={classes.rejectionReasonContainer}>
                        <Typography className={classes.rejectTitle}>Reason</Typography>
                        <Typography className={classes.summary}>{requirement?.rejectionReason || '-'}</Typography>
                    </div>}
                    {
                        requirement?.applicationStatus === "NewDocument" ?
                            <div className={classes.flexColumn}>
                                <Chip size="small" label={`New Document Available`} className={classes.NewDocumentAvailable} />
                                <PreviewNewDocument parentRequirement={requirement} openDoc={openDoc} requirements={requirement?.childRequirements} />
                            </div> : <>
                                <Divider className={classes.divider} />

                                {requirement?.applicationStatus !== "Applied" ? (
                                    <div className={classes.flexColumn}>
                                        {requirement?.approver && <Typography className={classes.summary}>{`Last action by: ${requirement?.approver} on ${moment(requirement?.updatedAt).format("DD MMM 'YY")}`}</Typography>}
                                        {requirement?.applicationStatus === "Rejected" && <Chip size="small" label={requirement?.applicationStatus} className={classes.rejectedChip} />}
                                        {(requirement?.applicationStatus === 'Approved' || requirement?.applicationStatus === 'Expired') && <Chip size="small" label={+requirement?.validTill <= 0 ? `Expired : ${moment(requirement?.expiryDate).fromNow()}` : requirement?.documentValidity} className={classNames(classes.approvedChip, +requirement?.validTill <= 0 && classes.expiredChip)} />}
                                        {requirement?.applicationStatus === "AlternateDocument" && <Chip size="small" label={"Requested for Alternate Document"} className={classes.ReSubmissionChip} />}
                                        {requirement?.applicationStatus === "ReSubmission" && <Chip size="small" label={"Requested for Re-submission"} className={classes.ReSubmissionChip} />}
                                    </div>
                                ) : (
                                    <div className={classes.docActionContainer}>
                                        <Button
                                            variant="contained"
                                            className={classes.outlinedButton}
                                            size="small"
                                            color="error"
                                            onClick={openRejectDialog}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={classes.outlinedButton}
                                            size="small"
                                            color="secondary"
                                            onClick={openApproveDialog}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                )}
                            </>
                    }
                </div>
                {additionalDoc && (requirement?.applicationStatus === "Approved" || requirement?.applicationStatus === "Rejected") && <div className={classes.flexColumn}>
                    <Divider className={classes.divider} style={{ width: '100%', marginBottom: 8 }} />
                    {/* <Chip size="small" label={`Recently Upload`} className={classes.NewDocumentAvailable} /> */}
                    <RecentlyProvidedDocument openDoc={openDoc} parentRequirement={requirement} requirements={requirement?.childRequirements} />
                </div>}
            </div>
        </>
    )
};


const PreviewNewDocument = ({ openDoc, requirements, parentRequirement }) => {
    const requirement = requirements?.[requirements?.length - 1];
    const classes = useStyles();
    const dispatch = useDispatch();
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(requirement?.signedDocumentUrl);
    const extension = match?.[1];
    const exist = allowedDocumentsRenderer?.find(p => p.mime === extension);

    const openApproveDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setDocumentApproveDialog({
            ...requirement, employerRequirement: {
                ...requirement.employerRequirement,
                requirement: {
                    ...requirement.employerRequirement.requirement,
                    name: parentRequirement.employerRequirement.requirement.name
                }
            },
            childRequirements: parentRequirement.childRequirements
        }));
    };

    const openRejectDialog = () => {
        dispatch(Actions.setRequirementBulkSelection([], true));
        dispatch(Actions.setDocumentRejectDialog({
            ...requirement, employerRequirement: {
                ...requirement.employerRequirement,
                requirement: {
                    ...requirement.employerRequirement.requirement,
                    name: parentRequirement.employerRequirement.requirement.name
                }
            },
            childRequirements: parentRequirement.childRequirements
        }));
    };

    return (
        <div className={classes.newDocContainer}>
            <ButtonBase disableRipple={!exist} LinkComponent={'div'} className={classes.docContainer} onClick={() => openDoc(requirement)}>
                <DocumentViewer type={extension} document={requirement} />
            </ButtonBase>
            <div className={classes.actionContainer}>
                <Button
                    variant="contained"
                    className={classes.outlinedButton}
                    size="small"
                    color="secondary"
                    onClick={openApproveDialog}
                >
                    Approve
                </Button>
                <Button
                    variant="contained"
                    className={classes.outlinedButton}
                    size="small"
                    color="error"
                    onClick={openRejectDialog}
                >
                    Reject
                </Button>
            </div>
        </div>
    )
}

const RecentlyProvidedDocument = ({ openDoc, requirements }) => {
    const requirement = requirements?.[requirements?.length - 1];
    const classes = useStyles();
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(requirement?.signedDocumentUrl);
    const extension = match?.[1];
    const exist = allowedDocumentsRenderer?.find(p => p.mime === extension);

    return (
        <>
            <Typography className={classes.rejectTitle}>Recently Uploaded Document</Typography>
            <div className={classes.newDocContainer}>
                <ButtonBase disableRipple={!exist} LinkComponent={'div'} className={classes.docContainer} onClick={() => openDoc(requirement)}>
                    <DocumentViewer type={extension} document={requirement} />
                </ButtonBase>
                <div className={classes.actionContainer} style={{ paddingTop: 0 }}>
                    <div className={classes.docCardContent}>
                        <div className={classes.flexBetween}>
                            <Typography className={classes.name}>{requirement?.employerRequirement?.requirement?.name}</Typography>
                        </div>
                        <Typography className={classes.summary}>{requirement?.employerRequirement?.requirement?.description}</Typography>
                        {Boolean(requirement?.applicationStatus === "Rejected") && <div className={classes.rejectionReasonContainer}>
                            <Typography className={classes.rejectTitle}>Rejection Reason</Typography>
                            <Typography className={classes.summary}>{requirement?.rejectionReason || '-'}</Typography>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
