import { gql } from "@apollo/client";

export default gql`
fragment EmployerFields on Employer {
  id
    companyName
    city
    requirements {
      id
      workType {
        id
        label
      }
      requirement {
        id
        name
        title
        description
        type
        summary
      }
      jobApplicationRequirements {
        id
        jobApplicationId
        applicationStatus
        signedDocumentUrl
        updatedAt
        expiryDate
        validTill
        childRequirements{
            id
            applicationStatus
            approver
            updatedAt
            isApproved
            rejectionReason
            consent
            documentDate
            documentType
            documentValidity
            signedDocumentUrl
            employerRequirement{
                id
                validityType
                validityValue
                requirement{
                    id
                    type
                    name
                    frenchName
                    title
                    frenchTitle
                    summary
                    frenchSummary
                    description
                    frenchDescription
                    isConsent
                    isDocHaveExpiry
                }
            }
        }
        jobApplications{
           id
           jobId
        }
      }
      updatedAt
    }
}
`;
