import { gql } from "@apollo/client";

export default gql`
  query getWorkers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $isSuspended: Boolean
    $workerIdVerification: Boolean
    $isActive: Boolean
    $isDeleted: Boolean
    $isApplied: Boolean
    $isSin9: Boolean
    $vaccinationStatus: VaccinationStatus
    $isApproved: Boolean
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
    $onWaitlist: WaitlistTimeframes
    $canDrive: Boolean
    $canWorkMorning: Boolean
    $canWorkEvening: Boolean,
    $tags: [String!]
  ) {
    workers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      isSuspended: $isSuspended
      workerIdVerification: $workerIdVerification
      isActive: $isActive
      isApproved: $isApproved
      isDeleted: $isDeleted
      isApplied: $isApplied
      isSin9: $isSin9
      vaccinationStatus: $vaccinationStatus
      like: $like
      onWaitlist: $onWaitlist
      canDrive: $canDrive
      canWorkMorning: $canWorkMorning
      canWorkEvening: $canWorkEvening
      tags: $tags
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          createdAt
          createdAtUtc
          updatedAtUtc
          firstName
          fullName
          lastName
          email
          phone
          isActive
          isApproved
          isSuspended
          positionCount
          futureJobCount
          rating
          city
          region
          sin
          talentID
          profileImageUrl
          signedProfileImageUrl
          vaccinationStatus
          signedWorkPermitImageUrl
          tags
          identificationImageUrl
          signedCompareImageUrl
          signedIdentificationImageUrl
          pendingApplicationCount
        }
      }
    }
  }
`;
