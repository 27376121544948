// HelperMessage
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import Button from "components/Button/Button";
import TotalBreakUps from "components/TotalBreakups/TotalBreakUps";
import { formatMoney } from "utils/stringUtils";
import { convertLocalToUTC } from "utils/time";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting"

const styles = theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        background: '#f0ffff',
        padding: theme.spacing(1),
    },
    button: {
        marginTop: "8px",
    },
    popup: {
        bottom: 0,
        top: 'auto !important'
    }
});

function SummaryFooter({ onNext, canPost, classes }) {
    const calculatedJobBill = useSelector(PostingSelectors.getCalculatedJobBill);
    const {
        breakMins,
        workersNeeded,
        startTime,
        endTime,
        breakType,
        location,
        employer,
        duration,
        jobType,
        timezone
    } = useSelector(state => state.posting);
    const dispatch = useDispatch();

    const handleCalculateBill = useCallback(() => {
        const { hours = 0, minutes = 0 } = duration;
        const startUTCTime = convertLocalToUTC(moment.tz(moment(startTime).format("YYYY-MM-DD HH:mm:ss"), timezone));
        const endUTCTime = convertLocalToUTC(moment.tz(moment(startTime).add((hours * 60) + minutes, 'minutes').format("YYYY-MM-DD HH:mm:ss"), timezone));
        if (location && location.region && (workersNeeded == 0 || workersNeeded > 0) && jobType && timezone)
            dispatch(
                PostingActions.calculateEmployerBill(employer.id, {
                    breakMins: +breakMins,
                    peopleNeeded: Number(workersNeeded),
                    jobStartTime: startUTCTime,
                    jobEndTime: endUTCTime,
                    isBreakPaid: breakType === 1,
                    region: location?.region,
                    workTypeId: +jobType,
                    timezone
                }),
            );
    }, [employer?.id, breakMins, workersNeeded, startTime, endTime, breakType, location?.region, jobType, timezone]);

    // const findBreakRuleByShiftAndRegion = useCallback(() => {
    //     const { hours = 0, minutes = 0 } = duration;
    //     const startUTCTime = moment(startTime);
    //     const endUTCTime = moment(startTime).add((hours * 60) + minutes, 'minutes')
    //     if (location && location.region && startUTCTime && endUTCTime)
    //         dispatch(
    //             PostingActions.provinceWiseBreakRules({
    //                 jobStartTime: startUTCTime,
    //                 jobEndTime: endUTCTime,
    //                 region: location?.region,
    //             }),
    //         );
    // }, [startTime, endTime, location?.region]);

    useEffect(() => {
        handleCalculateBill();
    }, [handleCalculateBill]);

    // useEffect(() => {
    //     findBreakRuleByShiftAndRegion();
    // }, [findBreakRuleByShiftAndRegion]);

    return (
        <div className={classes.footer}>
            <Typography variant="caption" color="textSecondary">
                Total
            </Typography>
            <Typography variant="h4">{formatMoney(calculatedJobBill?.total)}</Typography>
            {+calculatedJobBill?.total > 0 && calculatedJobBill?.taxes?.map(tax => (
                <Typography variant="caption" color="textSecondary" key={tax.label} mb={0.4}>
                    {`+ ${formatMoney(tax.amount)} (${tax.percentage}% ${tax.label})`}
                </Typography>
            ))}
            {+calculatedJobBill?.total > 0 && (
                <TotalBreakUps
                    canShowTotal
                    className={classes.popup}
                    breakups={calculatedJobBill}
                    peopleNeeded={workersNeeded} />
            )}
            <Button
                className={classes.button}
                icon={<ChevronRight />}
                label="Next"
                alignIcon="right"
                onClick={onNext}
                disabled={!canPost}
            />
        </div>
    );
}

SummaryFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onNext: PropTypes.func,
    canPost: PropTypes.bool,
};

SummaryFooter.defaultProps = {
    totalCost: 0,
    onNext: () => { },
    canPost: false,
};

export default withStyles(styles)(SummaryFooter);
