// This is a custom filter UI for selecting
// a unique option from a list
import React, { useState } from 'react';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import classNames from 'classnames';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';
const styles = () => ({
    customClass: {
        "& input": {
            cursor: "pointer",
        },
        '& > .MuiFormHelperText-root': {
            display: 'none'
        }
    }
});
function DatePickerComponents({
    // eslint-disable-next-line react/prop-types
    column: {
        filterValue, setFilter
    },
    // eslint-disable-next-line react/prop-types
    classes,
    inputFormat,
    payloadFormat
}) {
    const [open, setOpen] = useState(false);
    return (
        <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                className={classNames(classes.customClass, 'filter-textfield')}
                inputFormat={inputFormat || "dddd Do MMM, YYYY"}
                inputVariant="standard"
                renderInput={props => <TextField {...props}
                    variant="standard"
                    InputProps={{
                        ...props.InputProps,
                        readOnly: true,
                        disableUnderline: false,
                        placeholder: "Select Date",
                        endAdornment: (
                            <React.Fragment>
                                {filterValue ? <IconButton onClick={() => setFilter(undefined)} size="small"><Close size="small" /></IconButton> : null}
                                {props.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputProps={{
                        ...props.inputProps,
                        placeholder: "Select Date",
                        readOnly: true,
                    }}
                    placeholder={"Select Date"}
                    onClick={() => setOpen(true)}
                />}
                size="small"
                value={filterValue || null}
                onChange={newValue => {
                    if (newValue) {
                        setFilter(moment({
                            year: newValue.year(),
                            month: newValue.month(),
                            date: newValue.date(),
                            hour: 0,
                            minute: 0
                        }).format(payloadFormat || "YYYY-MM-DD"));
                    } else {
                        setFilter(undefined);
                    }
                }}
            />
        </LocalizationProvider>
    );
}

export default withStyles(styles)(DatePickerComponents);
