import { gql } from "@apollo/client";
import RequirementFields from "../fragments/requirements";

export default gql`
query getRequirementsByWorkerId($data: WorkerRequirementInput!){
  getRequirementsByWorkerId(data: $data){
    requirement {
      ...RequirementFields
    }
  }
}
  ${RequirementFields}
`;
