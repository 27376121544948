import { gql } from "@apollo/client";

export const OvertimeSpecs = gql`
query OvertimeSpecs{
    overtimeSpecs{
        id
        createdAt
        updatedAt
        region
        isDailyOT
        gst
        minimumWage
        minDailyOTMinutes
        maxDailyOTMinutes
        dailyOTRate
        isDailyDT
        minDailyDTMinutes
        maxDailyDTMinutes
        dailyDTRate
        isWeeklyOT
        minWeeklyOTMinutes
        maxWeeklyOTMinutes
        weeklyOTRate
        firstDayOfWeek
    }
  }
`;
