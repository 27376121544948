import React, { useEffect } from "react";
import classNames from "classnames";
import Chip from "@mui/material/Chip";
import { Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        '& .MuiButtonBase-root.MuiChip-root': {
            justifyContent: 'space-between'
        }
    }
}));

export default function MultiValueWithMore(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (props.children[0]?.length <= 1) setAnchorEl(null);
    }, [props.children[0]?.length])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <div className={props.selectProps.classes.valueContainer}>
        {props.children?.map(c => {
            if (Array.isArray(c)) {
                return <>
                    {c.slice(0, 1).map(p => (p))}
                    {c.length > 1 && <div style={{ cursor: 'pointer' }} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClick(e)
                    }}>
                        <Chip
                            aria-describedby={id} variant="contained"
                            tabIndex={-1}
                            label={`+${props.children[0]?.length - 1}`}
                            className={classNames(props.selectProps.classes.chip, {
                                [props.selectProps.classes.chipFocused]: props.isFocused,
                            })}
                        /></div>}
                </>;
            };
            return c;
        })}
        {props.children[0]?.length > 1 && <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className={classes.root}>
                {props.children?.reduce((p, c) => {
                    if (Array.isArray(c)) {
                        return c.slice(1)
                    }
                    return p;
                }, [])}
            </div>
        </Popover>}
    </div>;
}
