import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelect from 'components/Autocomplete/SimpleSelect';
import { actions as Actions } from "store/modules/additionalRequirements";
import { selectors as PostingSelectors } from 'store/modules/posting';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    autocomplete: {
        maxWidth: 250,
        width: 250,
        '& p.MuiTypography-root': {
            whiteSpace: 'nowrap',
            maxWidth: '95%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}));

export default function SelectWorkType(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const jobTypeList = useSelector(PostingSelectors.getJobTypeList);
    const unique = props?.data?.filter(d => d?.employer?.value === props?.row?.original?.employer?.value)?.reduce((p, record) => ((record?.workType?.value && record?.employer?.value) ? [...p, record?.workType?.value] : p), []);
    const onChange = (data) => {
        dispatch(Actions.updateAssignedEmployerFormData(props.row.index, 'workType', data));
    };

    return (
        <div>
            <SimpleSelect
                value={props?.row?.original?.workType}
                className={classes.autocomplete}
                onChange={onChange}
                options={jobTypeList?.filter(type => !unique?.includes(type?.value))}
                label="Select or Enter"
                inputProps={{ label: null }}
                menuPortalTarget={document.body} />
        </div>
    );
};
