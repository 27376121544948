// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import { summaryFooterStyles } from "views/Employer/helpers";
import { CircularProgress, Button as MUIButton } from "@mui/material";
import Button from "components/Button/Button";

function SummaryFooter({ canSubmitNewEmployerForm, registerEmployer, classes, isInfoLoading, withRequirement }) {
    return (
        <div className={classes.footer}>
            <MUIButton
                variant="outlined"
                className={classes.button}
                onClick={() => registerEmployer(true)}
                disabled={isInfoLoading || !canSubmitNewEmployerForm}
                style={{ borderRadius: 50 }}
                color="secondary"
            >
                Create & Add Requirement
                {(isInfoLoading && withRequirement) && <CircularProgress size={24} className={classes.progress} />}
            </MUIButton>
            <Button
                className={classes.button}
                // icon={<ChevronRight />}
                label="Create"
                alignIcon="right"
                onClick={() => registerEmployer(false)}
                disabled={!canSubmitNewEmployerForm || isInfoLoading}
                isLoading={isInfoLoading && !withRequirement}
            />
        </div>
    );
}

SummaryFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    registerEmployer: PropTypes.func,
    totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    registerWorker: PropTypes.func,
    canSubmitNewEmployerForm: PropTypes.bool,
    withRequirement: PropTypes.bool,
};

SummaryFooter.defaultProps = {
    totalCost: 0,
    registerWorker: () => { },
    canSubmitNew: false,
    withRequirement: false
};

export default summaryFooterStyles(SummaryFooter);
