import { CircularProgress, DialogActions, FormControlLabel, Grid, IconButton, Switch, TextField, Button, InputAdornment } from '@mui/material'
import Dialog from 'components/Dialog/Dialog'
import React, { useRef } from 'react'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProvinceWisePayActions, selectors as ProvinceWisePaySelectors } from "store/modules/provinceWisePay";
import ProvinceAutocomplete from 'components/Autocomplete/ProvinceAutocomplete';
import { Form, Formik } from 'formik';
import NumberFormat from 'react-number-format';
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Event } from '@mui/icons-material';
import moment from 'moment';
import Danger from 'components/Typography/Danger';
import { convertHrAndMinIntoTotalMinutes } from 'utils/time';

const useStyles = makeStyles(({ spacing, ...theme }) => ({
    container: {
        width: 450,
        maxWidth: "100%",
        marginTop: spacing(2)
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
        "& .MuiAlert-action": {
            alignItems: "center",
            marginTop: 0,
            paddingTop: 0
        }
    },
    selectedDocument: {
        marginTop: spacing(1.5),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: spacing(3),
        justifyContent: "center",
        alignItems: "center"
    },
    modal: {
        padding: spacing(6),
        paddingBottom: spacing(1),
        margin: spacing(2),

        alignItems: "center",
    },
    textField: {
        marginBottom: spacing(0),
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    durationInput: {
        marginTop: spacing(0),
    },
    durationInputGrid: {
        paddingTop: `${spacing(0)} !important`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "end",
        paddingTop: spacing(4)
    },
    switch: {
        padding: 0,
        width: 50,
        height: 26,
        marginLeft: spacing(1),
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(25px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    row: {
        textAlign: "left"
    },
    gridContainer: {
        marginTop: spacing(1)
    },
    formControlLabel: {
        marginLeft: spacing(0),
        "& .MuiTypography-root": {
            color: theme.palette.text.primary,
            fontWeight: "bold"
        }
    },
    divider: {
        marginTop: spacing(2)
    }
}));

export default function AddProvinceWisePayDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAdd = useSelector(ProvinceWisePaySelectors.getIsAddProvinceWisePayDialog);
    const isEdit = useSelector(ProvinceWisePaySelectors.getIsEditProvinceWisePayDialog);
    const openDialog = isAdd || isEdit;
    const form = useSelector(ProvinceWisePaySelectors.getProvinceWisePayForm);
    const isLoading = useSelector(ProvinceWisePaySelectors.getIsFormActionLoading);
    const ref = useRef();

    const handleClose = () => {
        dispatch(ProvinceWisePayActions.setIsAddProvinceWisePayDialog(false));
        dispatch(ProvinceWisePayActions.setIsEditProvinceWisePayDialog(false));
        dispatch(ProvinceWisePayActions.resetForm());
    };

    const handleSubmit = (values) => {
        dispatch(ProvinceWisePayActions.addProvinceWisePay(values));
    };

    const handleHoursChange = async (e) => {
        const { currentTarget: { value, name } } = e;
        ref?.current?.handleChange?.(e);
        if (name === "minShiftLengthHours" && value >= 23) await ref?.current?.setFieldValue(name.replace("Hours", "Minutes"), 0, true);
        if (value >= 24) await ref?.current?.setFieldValue(name.replace("Hours", "Minutes"), 0, true);
    };

    const handleMinuteChange = async (e) => {
        ref?.current?.handleChange?.(e);
    };


    const FieldErrors = ({ label, customError, customMaxError }) => (
        <>
            {(customError || customMaxError) && <Grid item xs={12} textAlign="left">
                <Danger>{`Maximum ${label} time must be ${customMaxError ? "greater" : "less"} than Minimum ${label} time`}</Danger>
            </Grid>}
        </>
    )

    return (
        <Dialog
            open={openDialog}
            title={`${isEdit ? "Update" : "Add"} Province Wise Minimum Pay Rule`}
            // description={isEdit ? "Update existing Province Wise Pay" : `Add a new Province Wise Pay.`}
            confirmText={isEdit ? "Update" : "Add"}
            disabled={isLoading}
            onClose={handleClose}
            alertType="form"
            hideActions
            modalType="editEmployer">
            <div className={classes.container}>
                <Formik
                    innerRef={ref}
                    initialValues={{
                        id: form.id || null,
                        isActive: form.isActive || false,
                        province: form.province || null,
                        minShiftLengthHours: form.minShiftLengthHours || 0,
                        minShiftLengthMinutes: form.minShiftLengthMinutes || 0,
                        maxShiftLengthHours: form.maxShiftLengthHours || 0,
                        maxShiftLengthMinutes: form.maxShiftLengthMinutes || 0,
                        minimumPayHours: form.minimumPayHours || 0,
                        minimumPayMinutes: form.minimumPayMinutes || 0,
                        effectiveDateFrom: form.effectiveDateFrom || moment(),
                        effectiveDateTo: form.effectiveDateTo || moment().add(1, "day"),
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            handleChange,
                            handleBlur,
                            setFieldValue
                        } = props;
                        return (
                            <Form style={{ width: "100%" }}>
                                <Grid item container direction="row" xs={12} className={classes.content}>
                                    <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                        <Grid item xs={12} className={classes.row}>
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                checked={values.isActive}
                                                control={<Switch className={classes.switch} />}
                                                label="Is Active"
                                                labelPlacement="start"
                                                name='isActive'
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ position: "relative", zIndex: 9999, marginTop: 28 }}>
                                                <ProvinceAutocomplete
                                                    value={values?.province}
                                                    menuPortalTarget={document.body}
                                                    name='province'
                                                    onChange={(val) => setFieldValue("province", val)}
                                                    inputProps={{
                                                        error: errors.province && touched.province && errors.province,
                                                        helperText: errors.province && touched.province && errors.province,
                                                        onBlur: handleBlur
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="minimumPayHours"
                                                label="Min Payable Hours"
                                                value={values.minimumPayHours}
                                                onChange={handleHoursChange}
                                                onBlur={handleBlur}
                                                error={errors.minimumPayHours && touched.minimumPayHours && errors.minimumPayHours}
                                                helperText={errors.minimumPayHours && touched.minimumPayHours && errors.minimumPayHours}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="minimumPayMinutes"
                                                label="Min Payable Minutes"
                                                disabled={values.minimumPayHours >= 24}
                                                value={values.minimumPayMinutes}
                                                onChange={handleMinuteChange}
                                                onBlur={handleBlur}
                                                error={errors.minimumPayMinutes && touched.minimumPayMinutes && errors.minimumPayMinutes}
                                                helperText={errors.minimumPayMinutes && touched.minimumPayMinutes && errors.minimumPayMinutes}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="minShiftLengthHours"
                                                label="Min Shift Hours"
                                                value={values.minShiftLengthHours}
                                                onChange={handleHoursChange}
                                                onBlur={handleBlur}
                                                error={errors.minShiftLengthHours && touched.minShiftLengthHours && errors.minShiftLengthHours}
                                                helperText={errors.minShiftLengthHours && touched.minShiftLengthHours && errors.minShiftLengthHours}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="minShiftLengthMinutes"
                                                label="Min Shift Minutes"
                                                disabled={values.minShiftLengthHours >= 23}
                                                value={values.minShiftLengthMinutes}
                                                onChange={handleMinuteChange}
                                                onBlur={handleBlur}
                                                error={errors.minShiftLengthMinutes && touched.minShiftLengthMinutes && errors.minShiftLengthMinutes}
                                                helperText={errors.minShiftLengthMinutes && touched.minShiftLengthMinutes && errors.minShiftLengthMinutes}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="maxShiftLengthHours"
                                                label="Max Shift Hours"
                                                value={values.maxShiftLengthHours}
                                                onChange={handleHoursChange}
                                                onBlur={handleBlur}
                                                error={errors.maxShiftLengthHours && touched.maxShiftLengthHours && errors.maxShiftLengthHours}
                                                helperText={errors.maxShiftLengthHours && touched.maxShiftLengthHours && errors.maxShiftLengthHours}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <NumberFormat
                                                customInput={TextField}
                                                format="##"
                                                mask=""
                                                className={classes.textField}
                                                fullWidth
                                                margin="normal"
                                                name="maxShiftLengthMinutes"
                                                label="Max Shift Minutes"
                                                disabled={values.maxShiftLengthHours >= 24}
                                                value={values.maxShiftLengthMinutes}
                                                onChange={handleMinuteChange}
                                                onBlur={handleBlur}
                                                error={errors.maxShiftLengthMinutes && touched.maxShiftLengthMinutes && errors.maxShiftLengthMinutes}
                                                helperText={errors.maxShiftLengthMinutes && touched.maxShiftLengthMinutes && errors.maxShiftLengthMinutes}
                                            />
                                        </Grid>
                                        <FieldErrors
                                            customMaxError={convertHrAndMinIntoTotalMinutes(values.minShiftLengthHours, values.minShiftLengthMinutes) >= convertHrAndMinIntoTotalMinutes(values.maxShiftLengthHours, values.maxShiftLengthMinutes)}
                                            maxDurationErrorKey={"maxShiftLength"}
                                            minDurationErrorKey={"minShiftLength"}
                                            label="Shift" />
                                        <Grid item xs={12} marginTop={3.5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6} >
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <MobileDatePicker
                                                            className={classes.datePicker}
                                                            label="Effective Date From"
                                                            name="effectiveDateFrom"
                                                            inputFormat="DD/MM/YYYY"
                                                            showToolbar={false}
                                                            value={values.effectiveDateFrom}
                                                            onBlur={handleBlur}
                                                            onChange={(val) => setFieldValue("effectiveDateFrom", val, true)}
                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                name="effectiveDateFrom"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="effectiveDateFrom"
                                                                                size="small">
                                                                                <Event />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "",
                                                                    helperText: errors.effectiveDateFrom && touched.effectiveDateFrom && errors.effectiveDateFrom,
                                                                    error: errors.effectiveDateFrom && touched.effectiveDateFrom && errors.effectiveDateFrom
                                                                }}
                                                            />}

                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <MobileDatePicker
                                                            className={classes.datePicker}
                                                            label="Effective Date To"
                                                            minDate={moment(values.effectiveDateFrom).add(1, "day")}
                                                            name="effectiveDateTo"
                                                            inputFormat="DD/MM/YYYY"
                                                            showToolbar={false}
                                                            value={values.effectiveDateTo}
                                                            onBlur={handleBlur}
                                                            onChange={(val) => setFieldValue("effectiveDateTo", val, true)}
                                                            renderInput={(params) => <TextField
                                                                {...params}
                                                                name="effectiveDateTo"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="effectiveDateTo"
                                                                                size="small">
                                                                                <Event />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    placeholder: "",
                                                                    helperText: errors.effectiveDateTo && touched.effectiveDateTo && errors.effectiveDateTo,
                                                                    error: errors.effectiveDateTo && touched.effectiveDateTo && errors.effectiveDateTo
                                                                }}
                                                            />}

                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions className={classes.footer}>
                                    <Button disabled={isLoading} color="error" type="button" className="outline" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!dirty || isLoading || convertHrAndMinIntoTotalMinutes(values.minShiftLengthHours, values.minShiftLengthMinutes) >= convertHrAndMinIntoTotalMinutes(values.maxShiftLengthHours, values.maxShiftLengthMinutes)}
                                    >
                                        {isLoading ? <CircularProgress size={20} /> : isEdit ? "Update" : "Add"}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Dialog >
    )
};


const validationSchema = yup.object().shape({
    isActive: yup.boolean(),
    province: yup.object().nullable().required("Province is required field"),
    minimumPayHours: yup
        .number()
        .typeError('Min Payable Hours is Required field')
        .min(0, "Invalid min Payable hours")
        .max(24, "Min Payable hours must be less then 24 hours")
        .required("Min Payable Hours is Required field"),
    minimumPayMinutes: yup
        .number()
        .typeError('Min Payable Minutes is Required field')
        .min(0, "Invalid Min Payable minutes")
        .max(60, "Min Payable minutes must be less then 60 minutes")
        .required("Min Payable Minutes is Required field"),
    minShiftLengthHours: yup
        .number()
        .min(0, "Enter valid hours")
        .max(23, "Enter valid hours")
        .required("Min Shift Hours is required field"),
    minShiftLengthMinutes: yup
        .number()
        .typeError('Min Shift Minutes is Required field')
        .min(0, "Invalid Min Shift minutes")
        .max(60, "Min Shift minutes must be less then 60 minutes")
        .required("Min Shift Minutes is Required field"),
    maxShiftLengthHours: yup
        .number()
        .min(0, "Enter valid hours")
        .max(24, "Enter valid hours")
        .required("Max Shift Hours is required field"),
    maxShiftLengthMinutes: yup
        .number()
        .typeError('Max Shift Minutes is Required field')
        .min(0, "Invalid Max Shift minutes")
        .max(60, "Max Shift minutes must be less then 60 minutes")
        .required("Max Shift Minutes is Required field"),
    effectiveDateFrom: yup.date().required("Effective Date From field is required"),
    effectiveDateTo: yup.date().required("Effective Date To field is required").when(['effectiveDateFrom'], {
        is: (effectiveDateFrom) => {
            return effectiveDateFrom;
        },
        then: (schema) =>
            schema.test("effectiveDateFrom", "Effective Date From must be greater than Effective Date To", function (effectiveDateTo) {
                if (!this.parent.effectiveDateFrom) return true;
                return moment(this.parent.effectiveDateFrom).isBefore(moment(effectiveDateTo));
            }),
    }),
});
