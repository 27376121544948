import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { GetProvinceBreakRule } from "./graphql/queries/index";
import CreateProvinceMinimumBreakRule from "./graphql/mutations/create-province-minimum-break-rule";
import DeleteProvinceMinimumBreakRule from "./graphql/mutations/delete-province-minimum-break-rule";
import UpdateProvinceMinimumBreak from "./graphql/mutations/update-province-minimum-break-rule";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");
const setIsFormActionLoading = setLoadingState("formDialogLoading");
const setIsDeleteLoading = setLoadingState("deleteLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});


export const setIsAddProvinceBreakRuleDialog = setDialogState("addProvinceBreakRuleDialog");
export const setIsDeleteProvinceBreakRuleDialog = setDialogState("deleteProvinceBreakRuleDialog");
export const setIsEditProvinceBreakRuleDialog = setDialogState("editProvinceBreakRuleDialog");

const setProvinceBreakRuleForm = key => value => ({
    type: types.UPDATE_PROVINCE_BREAK_RULE_FORM,
    payload: { key, value },
});

export const setProvinceBreakRuleFormData = (key, value) => ({
    type: types.UPDATE_PROVINCE_BREAK_RULE_FORM,
    payload: { key, value },
});
export const setProvinceBreakRuleId = setProvinceBreakRuleForm("id");
export const setProvinceWiseIsActive = setProvinceBreakRuleForm("isActive");
export const setFormMinBreakMinutes = setProvinceBreakRuleForm("minBreakMinutes");
export const setProvinceBreakTypeFrom = setProvinceBreakRuleForm("breakType");
export const setProvinceBreakRuleMinShiftLength = setProvinceBreakRuleForm("minShiftLength");
export const setProvinceBreakRuleMaxShiftLength = setProvinceBreakRuleForm("maxShiftLength");
export const setFormProvinces = setProvinceBreakRuleForm("province");

export const resetForm = () => ({
    type: types.RESET_FORM,
    payload: {}
});

const setProvinceBreakRule = data => ({
    type: types.SET_PROVINCE_BREAK_RULE_DATA,
    payload: { data }
});

export const setEditData = (data) => (dispatch) => {
    dispatch(setIsEditProvinceBreakRuleDialog(true));
    dispatch(setProvinceBreakRuleId(data.id));
    dispatch(setProvinceWiseIsActive(data.isActive));
    dispatch(setFormMinBreakMinutes(data.minBreakMinutes));
    dispatch(setProvinceBreakTypeFrom({ value: data.breakType, label: data.breakType || "None" }));
    dispatch(setProvinceBreakRuleMinShiftLength(data.minShiftLength));
    dispatch(setProvinceBreakRuleMaxShiftLength(data.maxShiftLength));
    dispatch(setFormProvinces({ value: data?.province?.id, label: data?.province?.name, data: data?.province }));
};

export const fetchProvinceBreakRule = () => (dispatch) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: GetProvinceBreakRule,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setProvinceBreakRule(data.BreakRules || []));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const addProvinceBreakRule = (formData) => (dispatch) => {
    const query = formData.id ? UpdateProvinceMinimumBreak : CreateProvinceMinimumBreakRule;

    const data = {
        ...(formData.id ? { id: formData.id } : {}),
        provinceId: formData.province?.data?.id,
        isActive: formData.isActive,
        minShiftLength: +formData.minShiftLength,
        maxShiftLength: +formData.maxShiftLength,
        minBreakMinutes: +formData.minBreakMinutes,
        breakType: formData.breakType?.value || undefined,
    };

    dispatch(setIsFormActionLoading(true));
    return ApolloClient.mutate({
        mutation: query,
        variables: { data },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchProvinceBreakRule());
            dispatch(setIsAddProvinceBreakRuleDialog(false));
            dispatch(setIsEditProvinceBreakRuleDialog(false));
            dispatch(setIsFormActionLoading(false));
            dispatch(resetForm());
            if (formData.id)
                dispatch(MetaActions.successToast(`Province wise break rule updated successfully`));
            else
                dispatch(MetaActions.successToast("Province wise break rule has been created."));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsFormActionLoading(false));
        });
};


export const deleteProvinceBreakRule = (id) => (dispatch) => {
    dispatch(setIsDeleteLoading(true))
    return ApolloClient.mutate({
        mutation: DeleteProvinceMinimumBreakRule,
        variables: { id: parseInt(id) }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteLoading(false))
                dispatch(setIsDeleteProvinceBreakRuleDialog(false))
                dispatch(fetchProvinceBreakRule());
                dispatch(MetaActions.successToast("Break rule has been deleted."));
            }
        })
        .catch(e => {
            dispatch(setIsDeleteLoading(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};
