import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WorkOff from "@mui/icons-material/WorkOff";
import { employerProfileCardStyles } from "views/Employer/helpers";
import { formatMapUrl } from "utils/map";
import { formatPhone } from "utils/stringUtils";
import { convertUTCToLocal } from "utils/time";
import NotVerifiedIcon from "assets/img/not_verified.svg";
import VerifiedIcon from "assets/img/verified.svg";


function EmployerProfileCard(props) {
    const {
        classes,
        companyName,
        firstName,
        lastName,
        phone,
        email,
        street,
        city,
        region,
        country,
        postalCode,
        isActive,
        isApproved,
        createdAtUtc,
        approvedAtUtc,
        websiteUrl,
    } = props;
    const profileCardStyles = classNames(classes.card, {
        [classes.deactivatedCard]: !isActive,
    });

    const approvalIcon = isApproved ? (
        <Tooltip
            title={`Verified - ${convertUTCToLocal(approvedAtUtc, "h:mm a - DD MMM YYYY")}`}
            placement="top">
            <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIcon} />
        </Tooltip>
    ) : (
        <Tooltip title="Needs Verification" placement="top">
            <img alt="Needs Verification Badge" src={NotVerifiedIcon} className={classes.approvalIcon} />
        </Tooltip>
    );

    let chip = null;
    if (!isActive) {
        chip = (
            <Chip
                icon={<WorkOff className={classes.deactivatedIcon} />}
                label="Deactivated"
                className={classes.deactivatedChip}
            />
        );
    }

    return (
        <Grid item xs={6} className={classes.container}>
            <Grid container item xs={12} className={profileCardStyles}>
                <Grid container item xs={12}>
                    <Grid container item xs={9} direction="row">
                        <Typography variant="h4">{companyName}</Typography>
                        {approvalIcon}
                    </Grid>
                    <Grid container item xs={3} justifyContent="flex-end">
                        {chip}
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction="column">
                    <Tooltip
                        title={`${convertUTCToLocal(createdAtUtc, "h:mm a - DD MMM YYYY")}`}
                        placement="top-start">
                        <Typography variant="body1" gutterBottom>
                            {`Joined ${convertUTCToLocal(createdAtUtc, "MMMM, YYYY")}`}
                        </Typography>
                    </Tooltip>
                    <Typography variant="body1" gutterBottom>
                        {websiteUrl ? (
                            <Link
                                href={websiteUrl}
                                target="_BLANK"
                                rel="noopener noreferrer"
                                variant="body1"
                                color="secondary">
                                {websiteUrl}
                            </Link>
                        ) : (
                            <i>No website URL</i>
                        )}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {email}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {phone}
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom>{(street && city && region && country) ? `${street}, ${city}, ${region}, ${country}` : <i>No address added yet</i>}</Typography>
                    <Typography variant="body1">{postalCode}</Typography>
                </Grid>
                {<Grid item xs={12} style={{ visibility: (street && city && region) ? "visible" : "hidden" }}>
                    <img
                        alt="Employer Location Map"
                        className={classes.map}
                        src={formatMapUrl(street, city, region, "500x160")}
                    />
                </Grid>}
            </Grid>
        </Grid>
    );
}

EmployerProfileCard.propTypes = {
    classes: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    isApproved: PropTypes.bool.isRequired,
    fullName: PropTypes.string,
    signedProfileImageUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sin: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
};

EmployerProfileCard.defaultProps = {
    fullName: "",
    signedProfileImageUrl: "",
    firstName: "",
    lastName: "",
    sin: "",
    phone: "",
    email: "",
    street: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
};

export default employerProfileCardStyles(EmployerProfileCard);
