import { Button, CircularProgress, Dialog, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import RejectIcon from 'assets/img/reject.svg';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5)
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(8),
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function RejectDialog({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const selectedWorker = useSelector(Selectors.getSelectedWorker);
    const isLoading = useSelector(Selectors.getIsRejectLoading);
    const selectedRequirement = useSelector(Selectors.getRejectDialog);
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (!open) setReason('');
    }, [open]);

    const handleApprove = () => {
        dispatch(Actions.rejectConsentRequirement(reason));
    };

    return (
        <Dialog maxWidth={'xs'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={RejectIcon} className={classes.icon} />}
                    <IconButton
                        disabled={isLoading}
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <Typography className={classes.title}>
                    Are you sure you want to reject <b>{selectedRequirement?.employerRequirement?.requirement?.name || '-'}</b> for <b>{selectedWorker?.worker?.fullName || '-'}</b>?
                </Typography>
                <div >
                    <TextField
                        label="Enter Reason (For internal use)"
                        autoFocus
                        fullWidth
                        placeholder={`Enter reason here`}
                        multiline
                        rows={4}
                        value={reason}
                        onChange={e => {
                            setReason(e.target.value);
                        }}
                    />
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="error"
                        disabled={isLoading || !reason?.trim()}
                        onClick={handleApprove}
                    >
                        Yes, Reject
                        {isLoading && <CircularProgress size={24} className={classes.progress} />}
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="text"
                        disabled={isLoading}
                        className={classes.button}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
