import React from 'react'
import GroupsAutocomplete from "components/Autocomplete/GroupsAutocomplete";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    autocomplete: {
        '& fieldset': {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: 0,
            borderColor: 'rgba(0, 0, 0, 0.42)'
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '0 !important',
        },
        '& .MuiButtonBase-root.MuiChip-root': {
            margin: '0px 2px',
            marginBottom: 4,
            height: 25,
        },
        '& .MuiChip-root': {
            margin: '0px 2px',
            marginBottom: 4,
            height: 25,
        }
    }
}));

export default function GroupFilter({
    column: {
        filterValue, setFilter
    },
}) {
    const classes = useStyles();

    return (
        <GroupsAutocomplete
            menuPortalTarget={document.body}
            value={filterValue || ''}
            className={classes.autocomplete}
            isMulti
            onChange={e => {
                setFilter(e?.length ? e : null);
            }}
            placeholder=''
            isFilter />
    );
};
