import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { convertUTCToLocal } from "utils/time";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employers";
import ReactTableCustom from "components/ReactTable";
import { useGeneralTableStyles } from "../helpers";
import classNames from "classnames";


const TableWrapper = (props) => {
    const {
        data,
        totalCount,
        currentPage,
        isLoading,
        onFilteredChange,
        onSortedChange,
        onPageSizeChange,
        onPageChange,
        pageFilter,
        pageSize,
        pageSort
    } = props;
    const fetchData = useCallback(({ pageIndex, filters, pageSize, sortBy }) => {
        onSortedChange(sortBy);
        onFilteredChange(filters);
        onPageSizeChange(pageSize);
        onPageChange(pageIndex);
    }, []);
    const classes = useGeneralTableStyles();
    const customTableProps = (row) => {
        if (row) {
            props.history.push("/employers/" + row.original.id);
        }
    };

    return (
        <ReactTableCustom
            fetchData={fetchData}
            style={{ marginTop: "16px" }}
            loading={isLoading}
            data={data}
            defaultFilter={pageFilter}
            defaultPageSize={pageSize}
            pageCount={totalCount}
            currentPage={currentPage}
            defaultSort={pageSort}
            classes={classNames("-highlight", classes.tableStyles)}
            onRowClick={customTableProps}
            rowsPerPageOptions={{ rowsPerPageOptions: [10, 20, 40] }}
            columns={[
                {
                    Header: "Company",
                    id: "companyName",
                    accessor: ({ companyName }) => companyName || <i> Empty </i>,
                    className: "-cursor-pointer",
                    width: 300,
                },
                {
                    Header: "First Name",
                    accessor: "firstName",
                    className: "-cursor-pointer",
                },
                {
                    Header: "Last Name",
                    accessor: "lastName",
                    className: "-cursor-pointer",
                },
                {
                    Header: "Email",
                    accessor: "email",
                    className: "-cursor-pointer",
                    width: 250,
                },
                {
                    Header: "Location",
                    id: "city",
                    accessor: ({ city, region }) => city ? city + ", " + region : "-",
                    disableSortBy: true,
                },

                {
                    Header: "Jobs",
                    accessor: "jobCount",
                    disableFilters: true,
                    disableSortBy: true,
                    className: "-cursor-pointer",
                    width: 80,
                },

                {
                    Header: "Business Phone",
                    accessor: "businessPhone",
                    className: "-cursor-pointer",
                    disableSortBy: true,
                },
                {
                    Header: "Created",
                    id: "createdAtUtc",
                    disableFilters: true,
                    accessor: ({ createdAtUtc }) => convertUTCToLocal(createdAtUtc, "MMM Do, YYYY"),
                    // width: 60,
                },
                {
                    id: "actions",
                    disableFilters: true,
                    disableSortBy: true,
                    collapse: true,
                    style: { textAlign: "right" },
                    Cell: () => (
                        <div style={{ textAlign: "right" }}>
                            <ChevronRight />
                        </div>
                    ),
                },
            ]}
        />
    );
}

class EmployerTable extends React.Component {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const { deactivatedEmployerDetails } = state.employers;
    const setData = details => {
        return Object.keys(details).map(employerId => {
            return {
                ...details[employerId],
            };
        });
    };

    const paginationData = EmployerSelectors.getDeactivatedEmployerListPaginationData(state);

    return {
        data: setData(deactivatedEmployerDetails),
        pageSort: paginationData.sort,
        pageFilter: paginationData.filter,
        ...paginationData.paging,
    };
};

const mapActions = dispatch => {
    return {
        fetchDeactivatedEmployers: (index) => dispatch(EmployerActions.fetchDeactivatedEmployers(index)),
        onPageSizeChange: size => dispatch(EmployerActions.changeDeactivatedPageSize(size)),
        onPageChange: idx => dispatch(EmployerActions.fetchDeactivatedEmployers(idx)),
        onSortedChange: sort => dispatch(EmployerActions.changeDeactivatedSort(sort)),
        onFilteredChange: data => dispatch(EmployerActions.changeDeactivatedFilter(data)),
    };
};

export default connect(mapState, mapActions)(withRouter(EmployerTable));
