import { makeStyles } from '@mui/styles';
import MainContent from 'components/Containers/MainContent';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions as Actions, selectors as Selectors } from 'store/modules/additionalRequirements';
import { actions as PostingActions, selectors as PostingSelectors } from 'store/modules/posting';
import RequirementDetailHeader from './RequirementDetailHeader';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import SelectEmployer from './Columns/SelectEmployer';
import SelectWorkType from './Columns/SelectWorkType';
import SelectValidity from './Columns/SelectValidity';
import { Button, IconButton } from '@mui/material';
import { AddOutlined, Delete } from '@mui/icons-material';
import SummaryFooter from './SummaryFooter';
import MandatoryField from './Columns/MandatoryField';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0
    },
    addMore: {
        borderRadius: 30,
        background: theme.palette.background.paper,
        padding: '7px 15px'
    },
}));


export const sharedReactTableProps = {
    showPageSizeOptions: true,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    rowsPerPageOptions: { rowsPerPageOptions: [10, 20, 40, 80, 100] }, // No more than this
};

export default function RequirementAssignEmployer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(Selectors.getIsDetailLoading);
    const employerList = useSelector(Selectors.getAssignNewEmployerList);
    const existingRecords = useSelector(Selectors.getExistingRecords);
    // const requirementDetails = useSelector(Selectors.getRequirementDetails);
    const params = useParams();

    useEffect(() => {
        dispatch(PostingActions.fetchWorkTypes());
        return () => {
            dispatch(Actions.clearAssignedEmployersForm());
        }
    }, []);

    const callback = async (id) => {
        await dispatch(Actions.fetchAllAssignedEmployersByRequirementId(id));
    }

    useEffect(() => {
        if (params?.id) {
            const id = window.atob(params?.id);
            dispatch(Actions.fetchsRequirementdetails(id, true, callback));
        };
        return () => {
            // dispatch(Actions.clearAdditionalRequirementForm());
        }
    }, [params?.id]);

    useEffect(() => {
        if (existingRecords?.length) {
            setTimeout(() => {
                dispatch(Actions.setExistingRecords([]));
            }, 5000);
        };
    }, [existingRecords?.length]);

    const handleAddMore = () => {
        dispatch(Actions.addNewRow());
    };

    const handleRemoveRow = ({ row: { index } }) => {
        dispatch(Actions.removeRow(index));
    };

    return (
        <React.Fragment>
            <MainContent className={classes.container} isLoading={isLoading}>
                <RequirementDetailHeader />
                <MainContent>
                    <ReactTableSimple
                        {...sharedReactTableProps}
                        classes="-highlight"
                        data={employerList}
                        rowClassNames={employerList?.map(a => {
                            if (existingRecords.find(e => e.workTypeId === a?.workType?.value && e.employerId === a?.employer?.value))
                                return '-exist-highlight';
                            return;
                        })}
                        filterable={false}
                        columns={[
                            {
                                Header: "Select Employer",
                                id: "employer",
                                accessor: ({ employer }) => employer,
                                disableFilters: true,
                                Cell: SelectEmployer,
                                disableSortBy: true,
                                maxWidth: 310,
                                headerStyle: {
                                    maxWidth: 310,
                                },
                                collapse: true,
                                className: "-cursor-pointer",
                            },
                            {
                                Header: "Select Work Type",
                                id: "workType",
                                accessor: ({ workType }) => workType,
                                disableFilters: true,
                                Cell: SelectWorkType,
                                disableSortBy: true,
                                maxWidth: 310,
                                headerStyle: {
                                    maxWidth: 310,
                                },
                                collapse: true,
                                className: "-cursor-pointer",
                            },
                            {
                                Header: "Validity till days/date",
                                id: "validity",
                                accessor: ({ validity }) => validity,
                                disableFilters: true,
                                Cell: SelectValidity,
                                disableSortBy: true,
                                maxWidth: 200,
                                className: "-cursor-pointer",
                            },
                            {
                                Header: "Is Mandatory",
                                id: "isMandatory",
                                accessor: ({ isMandatory }) => isMandatory,
                                disableFilters: true,
                                Cell: MandatoryField,
                                disableSortBy: true,
                                maxWidth: 100,
                                className: "-cursor-pointer",
                            },
                            {
                                Header: "Location",
                                id: "location",
                                accessor: ({ employer }) => employer?.data?.city,
                                disableFilters: true,
                                Cell: ({ row: { original } }) => {
                                    return (
                                        <div>
                                            {original?.employer?.data?.city ? `${original?.employer?.data?.city}, ${original?.employer?.data?.region}` : '-'}
                                        </div>
                                    )

                                },
                                disableSortBy: true,
                                maxWidth: 200,
                                className: "-cursor-pointer",
                            },
                            {
                                Header: "Actions",
                                id: "action",
                                accessor: ({ id }) => id,
                                disableFilters: true,
                                Cell: (cell) => {
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 4 }}>
                                            <Button onClick={handleAddMore} size='small' className={classes.addMore} color="secondary" variant="outlined" startIcon={<AddOutlined />}>
                                                Add New
                                            </Button>
                                            {employerList?.length > 1 ? (<IconButton size='small' onClick={() => handleRemoveRow(cell)}>
                                                <Delete />
                                            </IconButton>) : null}
                                        </div>
                                    )

                                },
                                disableSortBy: true,
                                className: "-cursor-pointer",
                                collapse: true,
                                headerStyle: {
                                    minWidth: 160,
                                    textAlign: 'right'
                                }
                            },
                        ]} />
                    <SummaryFooter />
                </MainContent>
            </MainContent>
        </React.Fragment>
    )
}
