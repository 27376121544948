import { Search } from '@mui/icons-material';
import { ButtonBase, CircularProgress, LinearProgress, Skeleton, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PerfectScrollWrapper from 'components/Sidebar/PerfectScrollWrapper';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        width: '100% !important',
        height: ({ filters }) => `calc(100vh - ${filters?.length ? 245 : 220}px) !important`,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: 4,
            width: 4,
        },
        '&::-webkit-scrollbar-track': {
            borderRadius: 4,
            backgroundColor: '#c5ffffba'
        },
        '&::-webkit-scrollbar-track:hover': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-track:active': {
            backgroundColor: '#EEF9F9'
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            backgroundColor: '#91b4b3',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#009E96'
        },
        '&::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#009E96'
        },
    },
    header: {
        padding: 32,
        paddingRight: 16,
        marginRight: 16,
        paddingBottom: 8,
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: 1,
    },
    cardContainer: {
        padding: '32px',
        paddingTop: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    arrow: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        '&::before': {
            content: '""',
            width: 18,
            height: 15,
            transform: 'rotate(45deg)',
            background: '#009E96',
            right: -8,
            position: 'absolute',
            borderLeft: '1px solid #009E96',
            borderTop: '1px solid #009E96',
            // zIndex: 1,
        },
    },
    card: {
        padding: theme.spacing(1.5),
        flex: 1,
        borderRadius: 5,
        border: `1px solid #0000001F`,
        cursor: 'pointer',
        maxWidth: '100%',
        '&:hover': {
            background: '#F3FFFF',
            border: `1px solid #009E96`,
        },
        transition: '0.5s all'
    },
    selectedCard: {
        border: `1px solid #009E96`,
        background: '#F3FFFF',
        position: 'relative',
        // zIndex: 2,
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },
    jobName: {
        fontWeight: 400,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        WebkitLineClamp: 1,
        whiteSpace: 'nowrap',
        lineClamp: 1,
    },
    jobDate: {
        fontWeight: 400,
        fontSize: 12,
        whiteSpace: 'nowrap',
        color: '#666666'
    },
    workTypeLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        WebkitLineClamp: 1,
        whiteSpace: 'nowrap',
        lineClamp: 1,
    },
    subTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        marginTop: 4,
        '& p': {
            color: '#666666',
            fontSize: 12,
            fontWeight: 400,
        },
        '& .divider': {
            height: 4,
            width: 4,
            minWidth: 4,
            background: '#666666',
            borderRadius: '50%'
        }
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        marginTop: theme.spacing(1.25),
        justifyContent: 'space-between',
        '& p': {
            whiteSpace: 'nowrap'
        }
    },
    progressBar: {
        flex: 1,
        height: 8,
        borderRadius: 50,
        background: '#c9f8eaa6'
    },
    loading: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonBase: {
        width: '100%',
        '&:focus': {
            '& $card': {
                // borderColor: "black"
            }
        }
    },
    search: {
        "& input": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            WebkitLineClamp: 1,
            whiteSpace: 'nowrap',
            lineClamp: 1,
        }
    }
}));

export default function Jobs() {
    const pageFilter = useSelector((state) => Selectors.getJobApplicationsData(state, { part: "filter" }));
    const employerFilters = pageFilter?.find(a => a?.id === "Employer_Id");
    const requirementFilters = pageFilter?.find(a => a?.id === "Requirement_Id");
    const workerFilters = pageFilter?.find(a => a?.id === "Worker_Id");
    const flatFilters = [...(employerFilters?.value || []), ...(requirementFilters?.value || []), ...(workerFilters?.value || [])];
    const classes = useStyles({ filters: flatFilters });
    const selectedJob = useSelector(Selectors.getSelectedJob);
    const jobs = useSelector(Selectors.getAllJobs);
    const dispatch = useDispatch();
    const PaginationData = useSelector(Selectors.getJobApplicationsPagingData);
    const isLoading = PaginationData?.isLoading;
    const [value, setValue] = useState('');

    useEffect(() => {
        dispatch(Actions.fetchJobsApplications(0));
    }, []);

    const debouncedFunction = useCallback(debounce((e) => {
        const findInd1 = pageFilter?.length && pageFilter.findIndex(a => a.id === 'companyName');
        if (e.target.value)
            dispatch(Actions.setJobApplicationFilter(findInd1 >= 0 ? Object.assign([...pageFilter], { [findInd1]: { id: 'companyName', value: e.target.value } }) : [...pageFilter, { id: 'companyName', value: e.target.value }]));
        else
            dispatch(Actions.setJobApplicationFilter(pageFilter?.filter(a => a.id !== 'companyName')));
        dispatch(Actions.fetchJobsApplications(0));
    }, 1200), [pageFilter]);

    const onScroll = (e, listInnerRef) => {
        if (listInnerRef) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef;
            if (!isLoading && (PaginationData?.totalCount > ((PaginationData?.currentPage + 1) * PaginationData?.pageSize)) && (+(scrollTop + clientHeight).toFixed() + 50) >= scrollHeight) {
                dispatch(Actions.fetchJobsApplications(PaginationData?.currentPage + 1));
            }
        }
    };

    const handleChangeJob = (job) => {
        dispatch(Actions.setSelectedJob(job))
    };


    return (
        <PerfectScrollWrapper onScroll={onScroll} id={'Job-Section'} className={classes.root}>
            <div className={classes.header}>
                <Typography fontWeight={700}>
                    Jobs ({jobs?.length}/{PaginationData?.totalCount})
                </Typography>
                <TextField
                    value={value || ''}
                    onChange={e => {
                        setValue(e.target.value)
                        debouncedFunction(e);
                    }}
                    className={classes.search}
                    style={{ width: '100%', marginTop: 12 }}
                    placeholder="Search via ID or Company Name"
                    InputProps={{
                        startAdornment: <Search />,
                        endAdornment: (isLoading && !PaginationData?.currentPage) ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                            <CircularProgress size={20} color='secondary' />
                        </div> : null
                    }} />
            </div>
            <div className={classes.cardContainer}>
                {jobs?.map(({ job, id } = {}) => (
                    <ButtonBase key={`${id} - ${job.id}`} style={{ borderRadius: 5 }} LinkComponent={'div'} id={`SelectedJob-${job?.id}`} className={classNames(classes.buttonBase, selectedJob?.id === job?.id && classes.arrow)} onClick={() => handleChangeJob(job)}>
                        <div className={classNames(classes.card, selectedJob?.id === job?.id && classes.selectedCard)}>
                            <div className={classes.flexBetween}>
                                <Typography className={classes.jobName}>{job?.employer?.companyName}</Typography>
                                <Typography className={classes.jobDate}>{moment(job.startUtc).format("DD MMM 'YY")}</Typography>
                            </div>
                            <div className={classes.subTitle}>
                                <Typography>{job.id}</Typography>
                                <div className='divider' />
                                <Typography className={classes.workTypeLabel}>{job?.workType?.label}</Typography>
                            </div>
                            <div className={classes.progressContainer}>
                                <LinearProgress className={classes.progressBar} color="secondary" variant="determinate" value={(job.positionsFilled / job.peopleNeeded) * 100} />
                                <div>
                                    <Typography fontSize={10}>{`${job.positionsFilled}/${job.peopleNeeded} workers`}</Typography>
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                ))}
                {(!isLoading && (!jobs || !jobs?.length)) && <div className={classes.loading}>
                    <Typography>No Jobs Found</Typography>
                </div>}
                {(isLoading) && (
                    [...Array(jobs?.length ? 1 : 5)].map((a, i) => (
                        <ButtonBase key={`${a} - ${i}`} disabled className={classes.buttonBase}>
                            <div className={classes.card}>
                                <div className={classes.flexBetween}>
                                    <Typography className={classes.jobName} style={{ width: '70%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                    <Typography className={classes.jobDate} style={{ width: '20%' }} >
                                        <Skeleton variant="text" />
                                    </Typography>
                                </div>
                                <div className={classes.subTitle}>
                                    <Typography style={{ width: '20%' }}>
                                        <Skeleton variant="text" />
                                    </Typography>
                                    <Skeleton variant="text" style={{ width: '4%' }} />
                                    <Typography className={classes.workTypeLabel} style={{ width: '76%' }}>
                                        <Skeleton variant="text" />
                                    </Typography>
                                </div>
                                <div className={classes.progressContainer}>
                                    <Skeleton variant="text" style={{ width: '70%' }} />
                                    <Typography fontSize={10} style={{ width: '20%' }}>
                                        <Skeleton variant="text" />
                                    </Typography>
                                </div>
                            </div>
                        </ButtonBase>
                    ))
                )}
            </div>
        </PerfectScrollWrapper>
    )
}
