import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";

export default gql`
  query GetFixedTermJobs($id: Int!, $timezone: String!) {
    getFixedTermJobs(jobId: $id) {
        id
        payrollStatus
        createdAt
        createdAtUtc
        employerId
        start
        end
        startUtc
        checkedInCount
        checkedOutCount
        endUtc
        timezone
        workTypeId
        timeframe(timezone: $timezone)
        peopleNeeded
        hourlyRate
        street
        unit
        floor
        locationInstruction
        city
        region
        description
        status
        durationMins
        positionsFilled
        durationMins
        estimatedCost
        isActive
        isFixedTerm
        referenceJobId
        isQueued
        breakMins
        paidBreakMins
        visibility
        notifyEmployer
        isBreakRequired
        isPrivate
        tags
        skills
        gears
        isRecurring
        jobRecurring{
          id
          frequency
          frequencyEndAt
          dayOrWeekDay
          frequencyInterval
        }
        jobManager{
          id
          role
          managerId
          manager{
            id
            firstName
            lastName
            signedProfileImageUrl
          }
        }
        jobAnnouncement{
          id
          title
          employerId
          managerId
          description
          frequency
          dayOrWeekDay
          jobAnnouncementTime{
            sendAt
          }
          workers{
            workerId
          }
          employer{
            id
            fullName
          }
          manager{
            id
            firstName
            lastName
          }
        }
        workType {
          id
          label
        }
        banned {
          workerId
        }
        preferred {
          workerId
        }
        notificationLog {
          id
          workerRating
          totalSuccess
          totalFailed
          sentOn
          sentFrom
        }
      employer {
        ...EmployerFields
      }
      positions {
        id
        isEditable
        isPayable
        isDeletable
        checkedInBy
        checkedOutBy
        addedBy
        payrollStatus
        premiumPayEarnings{
            id
            positionId
            amount
            rate,
            unit
        }
        payroll {
          id
          createdAt
          payrollTypeId
          positionId
          taxRegionId
          employmentStandardsRegionId
          rate
          checkIn
          checkOut
          breakDeductionMins
          bonus
          totalUnits
          payableUnits
          payrollType {
            id
            name
            code
          }
          payments {
            paymentStatus {
              id
              code
              description
            }
            id
            createdAt
            sentAt
            responseAt
            workerId
            payrollId
            positionId
            retryPaymentId
            paymentStatusId
            # dcTransferId
            paymentProviderReferenceCode
            email
            securityQuestion
            securityAnswer
            # amount
            errorCode
            errorDescription
            notes
          }
          earnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          wageEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          additionalEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          employeeDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          employerDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          totalEmployeeDeductions
          totalEmployerDeductions
          grossWages
          grossEarnings
          netEarnings
        }
        createdAt
        billingRate
        payRate
        createdAtUtc
        employerRating
        workerRating
        startShift
        endShift
        startShiftUtc
        endShiftUtc
        workerId
        defaultWorkerRating
        breakMins
        durationMins
        bonus
        confirmedAt
        confirmedAtUtc
        isReconfirmed
        isConfirmed
        reconfirmedAt
        reconfirmedAtUtc
      }
    }
  }
  ${EmployerFields}
`;
