import React, { useState } from 'react'
import FormRow from './FormRow'
import { Button, FormControl, Grid, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Add, Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementActions } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: 32
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    formRow: {
        position: 'relative'
    },
    removeAction: {
        background: '#F1EEFF',
        position: 'absolute',
        right: -51
    }
}));


export default function HelpForm() {
    const classes = useStyles();
    const { helpTexts } = useSelector((state) => AdditionalRequirementSelector.getFormData(state));
    const dispatch = useDispatch();

    const handleRemove = (ind) => {
        const data = [...helpTexts];
        if (helpTexts.length === 1) {
            const newData = Object.assign([...helpTexts], {
                [ind]: {
                    question: '', frenchQuestion: '', answer: '', frenchAnswer: '',
                }
            });
            dispatch(AdditionalRequirementActions.setAdditionalRequirementForm('helpTexts', newData));
            return;
        }
        data.splice(ind, 1);
        dispatch(AdditionalRequirementActions.decreaseHelpText(data));
    };

    const addHelpText = () => {
        dispatch(AdditionalRequirementActions.increaseHelpText([...helpTexts].concat({
            question: '', frenchQuestion: '', answer: '', frenchAnswer: '',
        })));
    };

    const handleChange = (ind, key, value) => {
        const data = Object.assign([...helpTexts], {
            [ind]: {
                ...helpTexts[ind],
                [key]: value?.slice(0, 250)
            }
        });
        dispatch(AdditionalRequirementActions.setAdditionalRequirementForm('helpTexts', data));
    };

    return (
        <>
            {helpTexts.map((help, ind) => (
                <FormRow key={ind} style={{ marginTop: !ind ? 24 : 64 }}>
                    <FormControl fullWidth className={classes.formRow}>
                        <Grid container spacing={2} item xs={12}>
                            <Grid container item xs={6} style={{ zIndex: 100 }}>
                                <div>
                                    <TextField
                                        label={`Question ${ind + 1} - English`}
                                        placeholder="Ex: Where can I find this Info?"
                                        margin="none"
                                        fullWidth
                                        name='question'
                                        id='question'
                                        onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                        value={help.question}
                                    />
                                    <TextField
                                        className={classes.marginTop}
                                        label={`Question ${ind + 1} - French`}
                                        placeholder="Ex: Où puis-je trouver cette information?"
                                        margin="none"
                                        fullWidth
                                        name='frenchQuestion'
                                        id='frenchQuestion'
                                        onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                        value={help.frenchQuestion}
                                    />
                                </div>
                            </Grid>
                            <Grid container item xs={6} style={{ zIndex: 100 }}>
                                <div>
                                    <TextField
                                        multiline
                                        rows="3"
                                        label={`Answer ${ind + 1} - English `}
                                        placeholder="Ex: To find the ID number look at the bottom left of your card"
                                        margin="none"
                                        fullWidth
                                        name='answer'
                                        id='answer'
                                        onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                        value={help.answer}
                                    />
                                    <TextField
                                        multiline
                                        rows="3"
                                        className={classes.marginTop}
                                        label={`Answer ${ind + 1} - French`}
                                        placeholder="Ex : Pour trouver le numéro d'identification regardez en bas à gauche de votre carte"
                                        margin="none"
                                        fullWidth
                                        name='frenchAnswer'
                                        id='frenchAnswer'
                                        onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                        value={help.frenchAnswer}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {(helpTexts?.length > 1 || (helpTexts?.length === 1 && (help.question || help.frenchQuestion || help.answer || help.frenchAnswer))) && <IconButton
                            onClick={() => handleRemove(ind)}
                            className={classes.removeAction}>
                            <Close />
                        </IconButton>}
                    </FormControl>
                </FormRow>
            ))}
            <FormRow style={{ marginTop: 24 }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} item xs={12} justifyContent={'flex-end'}>
                        <Button color='secondary' variant="text" startIcon={<Add />} onClick={addHelpText}>
                            Add More
                        </Button>
                    </Grid>
                </FormControl>
            </FormRow>
        </>
    )
}
