// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.workTypes.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsLoading = getLoadingStateFactory("isLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.workTypes.dialog[loader] || false;
};

export const getIsAddWorkTypeDialog = getDialogStateFactory("addWorkTypeDialog");
export const getIsDeleteWorkTypeDialog = getDialogStateFactory("deleteWorkTypeDialog");
export const getIsEditWorkTypeDialog = getDialogStateFactory("editWorkTypeDialog");

// WorkTypes selectors
export const getWorkTypesList = state => {
    return state.workTypes.workTypes;
};
