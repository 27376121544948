import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.additionalRequiremnts.loading[loader] || false;
};

export const getDialogStateFactory = loader => state => {
    return state.additionalRequiremnts.dialog[loader] || false;
};

export const getIsRequiremntAddLoading = getLoadingStateFactory("isAddLoading");
export const getIsDetailLoading = getLoadingStateFactory("isDetailLoading");
export const getIsEditing = getLoadingStateFactory("isEditing");
export const getIsRequiremntUpdateLoading = getLoadingStateFactory("isRequiremntUpdateLoading");
export const getIsRemoveAssignedEmployerLoading = getLoadingStateFactory("isRemoveAssignedEmployerLoading");

export const getIsSelectedRecordForRemove = getDialogStateFactory("selectedRecordForRemove");

export const getAdditionalRequirementListPagingData = state => {
    return state.additionalRequiremnts[pagination.getPageInfoAttr(paginationSettings.additionalRequirementList)];
};

export const getAdditionalRequirementsData = (state, { part }) => state.additionalRequiremnts.requirementList[part];
export const getRequirementDetails = (state) => state.additionalRequiremnts.requirementDetails || {};
export const getActiveTab = (state) => state.additionalRequiremnts.activeTab;

export const getFormData = (state, part) => part ? state.additionalRequiremnts.form[part] : state.additionalRequiremnts.form || {};

export const canPostValidation = (state) => {
    if (state.additionalRequiremnts.form?.type === "Document") {
        return (
            state.additionalRequiremnts.form?.name?.trim() &&
            state.additionalRequiremnts.form?.frenchName?.trim() &&
            // state.additionalRequiremnts.form?.groupNames?.length &&
            state.additionalRequiremnts.form?.description?.trim() &&
            state.additionalRequiremnts.form?.frenchDescription?.trim() &&
            (!state.additionalRequiremnts.form?.helpTexts?.length || state.additionalRequiremnts.form?.helpTexts?.every(help => {
                if (!help?.question?.trim() && !help?.answer?.trim() && !help?.frenchQuestion?.trim() && !help?.frenchAnswer?.trim())
                    return true
                else
                    return (help?.question?.trim() && help?.answer?.trim() && help?.frenchQuestion?.trim() && help?.frenchAnswer?.trim())
            }))
        )
    }
    return (
        state.additionalRequiremnts.form?.name?.trim() &&
        state.additionalRequiremnts.form?.summary?.trim() &&
        state.additionalRequiremnts.form?.frenchSummary?.trim() &&
        state.additionalRequiremnts.form?.description?.replace(/<[^>]+>/g, '')?.trim() &&
        state.additionalRequiremnts.form?.frenchDescription?.replace(/<[^>]+>/g, '')?.trim() &&
        state.additionalRequiremnts.form?.title?.trim() &&
        state.additionalRequiremnts.form?.frenchTitle?.trim() &&
        // state.additionalRequiremnts.form?.groupNames?.length &&
        (!state.additionalRequiremnts.form?.helpTexts?.length || state.additionalRequiremnts.form?.helpTexts?.every(help => {
            if (!help?.question?.trim() && !help?.answer?.trim() && !help?.frenchQuestion?.trim() && !help?.frenchAnswer?.trim())
                return true
            else
                return (help?.question?.trim() && help?.answer?.trim() && help?.frenchQuestion?.trim() && help?.frenchAnswer?.trim())
        }))
    );
};


// Assigend Employers
export const getAssignNewEmployerList = (state) => state.additionalRequiremnts.assignNewEmployerList || [];
export const getAssignedEmployerData = (state, { part }) => state.additionalRequiremnts.assignedEmployerList[part];
export const getAssignedEmployerListPagingData = state => {
    return state.additionalRequiremnts[pagination.getPageInfoAttr(paginationSettings.assignedEmployerList)];
};
export const getExistingRecords = (state) => state.additionalRequiremnts.existingRecords || [];

export const canAssignedEmployers = (state) => {
    return state.additionalRequiremnts.assignNewEmployerList?.every(record => {
        return (
            record?.employer &&
            record?.workType &&
            record?.validity &&
            (record.validity.type === 'days' ? record.validity.value > 0 : record.validity.value)
        )
    });
};
