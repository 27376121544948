import React from 'react'
import { CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import Icon from 'assets/img/warningred.svg';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5),
        minWidth: 400
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5)
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    approveRejectButton: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    note: {
        background: 'rgba(227, 239, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: theme.spacing(1.25, 2, 1.25, 2),
        margin: theme.spacing(1),
        marginTop: theme.spacing(0),
        '& p': {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '20px',
            color: '#000000'
        }
    },
    approveRejectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1.25),
        width: '80%'
    }
}));

export default function SimpleDialog({
    disabled,
    cancelText,
    confirmText,
    title,
    description,
    icon,
    onConfirm,
    onCancel,
    onClose,
    open,
    isLoading,
}) {
    const classes = useStyles();

    return (
        <Dialog maxWidth={'xs'} onClose={(e) => !isLoading && onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={Icon} className={classes.icon} />}
                    <IconButton
                        disabled={isLoading}
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                {description}
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        className={classes.approveRejectButton}
                        color="secondary"
                        onClick={onConfirm}
                        disabled={isLoading || disabled}
                    >
                        {confirmText || 'Approve'}
                    </Button>
                    <Button
                        onClick={onCancel}
                        variant="text"
                        className={classes.button}
                        disabled={isLoading}
                    >
                        {cancelText || 'Go Back'}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
