import { AccountBalance, AccountCircle, Comment, EmailRounded, MonetizationOn } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { financeViewStyles } from 'views/Finance/helpers';
import Dialog from 'components/Dialog/Dialog';


export default function RequestReversalDialog({  ...props }) {
    const dispatch = useDispatch();
    const requestReversal = useSelector(FinanceSelectors.getIsRequestReversalDialog);
    const isDialogActionLoading = useSelector(FinanceSelectors.getIsDialogActionLoading);
    const [reversal, setReversal] = useState({ reason: "", amount: 0 });
    const classes = financeViewStyles();

    useEffect(() => {
        return () => {
            setReversal({ reason: "", amount: 0 })
        };
    }, []);

    const handleToggle = () => dispatch(FinanceActions.setIsRequestReversalDialog(false))

    const handleCreateRequestForFunds = () => dispatch(FinanceActions.requestReversal({ request: reversal }))

    return (
        <>
            <Dialog
                open={Boolean(requestReversal)}
                title={`Request eTransfer Reversal`}
                description={`   \n\n`}
                onClose={handleToggle}
                onConfirm={handleCreateRequestForFunds}
                onCancel={handleToggle}
                confirmText="Send Request"
                alertType="form"
                maxWidth="sm"
                disabled={isDialogActionLoading || !reversal.reason}
                size="sm"
                isLoading={isDialogActionLoading}
                disableEscapeKeyDown
                disableRestoreFocus
                fullWidth={false}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography align="center" variant="body1">
                            Request An eTransfer Reversal
                        </Typography>
                        <Typography align="center" variant="body1" />
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.leftColumn}>
                        <TextField
                            label="Worker"
                            name="name"
                            fullWidth
                            value={requestReversal?.worker?.firstName + ' ' + requestReversal?.worker?.lastName || ""}
                            disabled
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.rightColumn}>
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            fullWidth
                            value={(requestReversal && requestReversal?.payment?.email) || ""}
                            disabled
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailRounded style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.leftColumn}>
                        <TextField
                            label="dc Transfer Id"
                            name="dcTransferId"
                            type="number"
                            fullWidth
                            value={requestReversal?.payment?.dcTransferId || 0}
                            disabled
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.rightColumn}>
                        <TextField
                            label="Amount"
                            name="amount"
                            type="number"
                            fullWidth
                            step="0.5"
                            min={0}
                            disabled
                            // max={(requestReversal?.payment && requestReversal?.payment?.amount) || 300}
                            value={requestReversal?.payment?.amount}
                            // onChange={e => setReversal(p => ({ ...p, [e.target.name]: e.target.value }))}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MonetizationOn style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                            // error={(!reversal.amount && reversal.amount !== 0) || reversal.amount < 0 || reversal.amount > (requestReversal?.payment?.amount || 300)}
                            // helperText={(!reversal.amount && reversal.amount !== 0) || reversal.amount < 0 ? "Enter valid amount" : (reversal.amount > (requestReversal?.payment?.amount || 300)) ? `Request amount should be less ${requestReversal?.payment?.amount || 300}` : ""}
                        />
                    </Grid>
                    {/* {results} */}
                    <Grid item xs={12} className={classes.inputContainer}>
                        <TextField
                            label="Message"
                            name="reason"
                            fullWidth
                            rows={3}
                            value={reversal.reason || ""}
                            multiline
                            onChange={e => setReversal(p => ({ ...p, [e.target.name]: e.target.value }))}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Comment style={{ color: "green" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}
