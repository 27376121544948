import gql from "graphql-tag";

export const GetProvinceMinimumPays = gql`
query GetProvinceMinimumPays {
    provinceMinimumPays {
      id
      provinceId
      minShiftLength
      maxShiftLength
      minimumPay
      effectiveDateFrom
      effectiveDateTo
      isActive
      updatedBy
      deletedBy
      createdAt
      updatedAt
      deletedAt
      province{
        id
        name
        code
        isActive
        countryId
      }
  }
}`;
