import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles"
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import { isValidFile } from "utils/file";
import { actions as MetaActions } from "store/modules/meta";
import { supportedAvatarFiles } from "utils/constant";

const useStyles = makeStyles(({ spacing }) => ({
  modal: {
    padding: spacing(6),
    paddingBottom: spacing(1),
    margin: spacing(2),

    alignItems: "center",
    // width: spacing(60
    // overflowY: "unset",
  },
  title: {
    padding: `${spacing(2)} 0 ${spacing(1)} 0`,
  },
  content: {
    padding: `${spacing(1)} 0 ${spacing(1)} 0`,
    // overflowY: "auto",
    // overflowY: "unset",
    width: "100%",
  },
  footer: {
    margin: 0,
    textAlign: "center",
    justifyContent: "center",
  },
  avatarImg: {
    margin: "10px auto",
    width: "100px",
    position: "relative",
    "& img": {
      borderRadius: "100px",
      border: "2px solid #c4c4c4",
    },
    "& label": {
      position: "absolute",
      right: "6px",
      background: "#ffffff",
      bottom: "0",
      padding: "0px",
      "&:hover": {
        background: "#FFF",
      },
      "& img": {
        padding: "2px",
      },
    },
  },
}));
function ProfileForm({ profileDetail }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [profileURL, setProfileURL] = useState();
  const [fileObject, setFileObject] = useState();
  const [data, setData] = useState({});
  const isLoading = useSelector(ProfileSelectors.getIsSaveProfileLoading);
  const controlPadding = {
    leftColumn: { paddingRight: 4, textAlign: "left" },
    rightColumn: { paddingLeft: 4 },
  };
  const handleProfileFile = file => {
    const item = new Blob([file], { type: file.type });
    item.name = `profile_${file.name}`;
    setProfileURL(URL.createObjectURL(item));
    setFileObject(item);
    // dispatch(ProfileActions.uploadEmployerProfileImage(item));
  };
  useEffect(() => {
    setData(profileDetail)
  }, [profileDetail])
  useEffect(
    () => {
      setProfileURL(profileDetail.signedProfileImageUrl);
    },
    [profileDetail.signedProfileImageUrl],
  );

  const onSubmit = values => {
    if (fileObject) {
      values.profileImage = fileObject;
    }
    dispatch(ProfileActions.updateProfileData({ ...values, id: profileDetail.id }));

  };

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Profile
          </Typography>
          {data && (
            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={{
                  firstName: data.firstName,
                  lastName: data.lastName,
                  title: data.title || "123",
                  email: data.email
                }}
                onSubmit={(values, actions) => {
                  onSubmit(values);
                  actions.setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                  firstName: yup
                    .string()
                    .max(50, "Length must be less then 50 character")
                    .required("Required field"),
                  lastName: yup
                    .string()
                    .max(50, "Length must be less then 50 character")
                    .required("Required field"),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleReset,
                    setFieldValue,
                  } = props;

                  return (
                    <Form style={{width:"100%"}}>
                      <Grid container item xs={12} md={12} spacing={1}>
                        <div className={classes.avatarImg}>
                          <img
                            width={"100"}
                            height={"100"}
                            src={profileURL || avatar}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = avatar;
                            }}
                            alt="..."
                          />
                          <IconButton className="edit-icon" disableRipple component="label">
                            <img src={iconEdit} />
                            <input
                              type="file"
                              hidden
                              accept={supportedAvatarFiles.map(a => a.mime).join(",")}
                              onChange={files =>{
                                const file = files.target.files[0];
                                const response = isValidFile(file);
                                if (response.isValid) {
                                    handleProfileFile(file)
                                } else {
                                  dispatch(MetaActions.errorToast(response.message));
                                }
                            }}/>
                          </IconButton>
                        </div>
                      </Grid>
                      <Grid item container direction="row" xs={12}>
                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                          <TextField
                            name="firstName"
                            label="First Name"
                            type="text"
                            error={errors.firstName && touched.firstName}
                            className={classes.textField}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            helperText={errors.firstName && touched.firstName && errors.firstName}
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                          <TextField
                            name="lastName"
                            label="Last Name"
                            type="text"
                            error={errors.lastName && touched.lastName}
                            className={classes.textField}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            helperText={errors.lastName && touched.lastName && errors.lastName}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                          <TextField
                            name="email"
                            label="Email"
                            type="email"
                            error={errors.email && touched.email}
                            className={classes.textField}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            disabled={profileDetail.id}
                            helperText={errors.email && touched.email && errors.email}
                            margin="normal"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                          <TextField
                            name="title"
                            label="Title"
                            type="text"
                            error={errors.title && touched.title}
                            className={classes.textField}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            helperText={errors.title && touched.title}
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={(!dirty && !fileObject) || !isEmpty(errors) || isLoading}
                      >
                        Save
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ProfileForm;
