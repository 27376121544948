/* eslint-disable no-constant-condition */
import React from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, Grid, Switch, TextField, Tooltip } from '@mui/material'
import WYSIWYGEditor from 'views/AdditionalRequirements/components/Editor';
import FormRow from 'views/AdditionalRequirements/components/FormRow';
import GroupsDropdown from 'views/AdditionalRequirements/components/GroupsDropdown';
import { makeStyles } from '@mui/styles';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: '65%',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: ({ isEditing }) => !isEditing ? '#F5F5F5' : '#FFF',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75),
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    },
    root: {
        '& .MuiInputBase-input.Mui-disabled': {
            background: '#F5F5F5',
        },
        '& label': {
            color: '#000000 !important',
        },
        '& input': {
            '&::placeholder': {
                color: '#000000 !important',
                webkitTextFillColor: '#000000 !important',
            },
            WebkitTextFillColor: '#000000 !important',
            color: '#000000 !important',
            textOverflow: 'ellipsis'
        }
    },
    GroupsDropdown: {
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
            background: ({ isEditing }) => !isEditing ? '#F5F5F5' : null,
        }
    }
}));

export default function ConsentDetails() {
    const isEditing = useSelector(Selectors.getIsEditing);
    const classes = useStyles({ isEditing });
    const formData = useSelector(Selectors.getFormData);
    const requirementDetails = useSelector(Selectors.getRequirementDetails);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { target: { value, name } } = event;
        if (name === 'isConsent') {
            const { target: { checked } } = event;
            dispatch(Actions.setAdditionalRequirementForm(name, checked));
            dispatch(Actions.setAdditionalRequirementForm('type', checked ? 'Consent' : 'Validation'));
            return;
        }
        dispatch(Actions.setAdditionalRequirementForm(name, value?.slice(0, 250)));
    };

    const handleChangeDesription = (value, name) => {
        dispatch(Actions.setAdditionalRequirementForm(name, value));
    };

    const handleGroupChange = (value) => {
        dispatch(Actions.setAdditionalRequirementForm('groupNames', value));
    };

    return (
        <div className={classes.root}>
            <FormRow style={{ marginTop: 48 }}>
                <FormControl fullWidth>
                    <TextField
                        disabled={!isEditing}
                        className={classes.fullWidth}
                        id="name"
                        name="name"
                        label="Validation Master Name (For Internal use)*"
                        placeholder="Validation Master Name"
                        fullWidth
                        margin="none"
                        value={formData?.name}
                        onChange={handleChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} item xs={12}>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                disabled={!isEditing}
                                className={classes.fullWidth}
                                id="title"
                                name="title"
                                label="Validation Title - English (For Talent)*"
                                placeholder="Validation Title - English"
                                margin="none"
                                fullWidth
                                value={formData?.title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                disabled={!isEditing}
                                className={classes.fullWidth}
                                id="frenchTitle"
                                name="frenchTitle"
                                label="Validation Title - French (For Talent)*"
                                placeholder="Validation Title - French"
                                margin="none"
                                fullWidth
                                value={formData?.frenchTitle}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20, marginBottom: 8, zIndex: 1051, position: 'relative' }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Add this Validation in the Group (For Internal use)</div>
                    <GroupsDropdown
                        fullWidth
                        variant="outlined"
                        disabled={!isEditing}
                        id="groupNames"
                        className={classes.GroupsDropdown}
                        name="groupNames"
                        placeholder="Select or Type & enter to add new"
                        value={formData?.groupNames}
                        onChange={handleGroupChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <TextField
                        disabled={!isEditing}
                        className={classes.fullWidth}
                        id="summary"
                        name="summary"
                        label="Validation Summary  - English (For Talent)*"
                        placeholder="Validation Summary  - English"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={formData?.summary}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <TextField
                        disabled={!isEditing}
                        className={classes.fullWidth}
                        id="frenchSummary"
                        name="frenchSummary"
                        label="Validation Summary - French (For Talent)*"
                        placeholder="Validation Summary - French"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={formData?.frenchSummary}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Description - English (For Talent)*</div>
                    <WYSIWYGEditor
                        disabled={!isEditing}
                        onChange={handleChangeDesription}
                        name="description" value={(!isEditing ? formData?.description : requirementDetails?.description) || ''} />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Description - French (For Talent)*</div>
                    <WYSIWYGEditor
                        disabled={!isEditing}
                        onChange={handleChangeDesription}
                        name="frenchDescription" value={(!isEditing ? formData?.frenchDescription : requirementDetails?.frenchDescription) || ''} />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20 }}>
                <FormControl fullWidth>
                    <div className={classes.box}>
                        <div className={classes.label}>
                            Is this is a consent validation?
                            <Tooltip title={'Lorem ipsum'} >
                                <InfoOutlined />
                            </Tooltip>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} className={classes.label}>
                            <span style={{ fontWeight: !formData?.isConsent ? 'bold' : 'normal' }}>No</span>
                            <Switch
                                name={'isConsent'}
                                id={'isConsent'}
                                onChange={handleChange}
                                disabled={!isEditing}
                                checked={formData?.isConsent}
                                inputProps={{ 'aria-label': 'Switch demo' }} />
                            <span style={{ fontWeight: formData?.isConsent ? 'bold' : 'normal' }}>Yes</span>
                        </div>
                    </div>
                </FormControl>
            </FormRow>
        </div>
    )
}
