import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import history from "utils/history";
import { getRelation } from "utils/redux";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import CreateRequirement, { UpdateRequirement, CreateOrUpdateEmployerHasRequirement, RemoveEmployerHasRequirement } from "./graphql/mutations";
import { GetAssignedEmployers, GetRequirements, RequirementDetailsById } from "./graphql/queries";
import {
    getAdditionalRequirementListPagingData, getAdditionalRequirementsData,
    getAssignedEmployerData,
    getAssignedEmployerListPagingData,
    getIsSelectedRecordForRemove
} from "./selectors";
import types from "./types";
import moment from "moment";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsRequiremntAddLoading = setLoadingState("isAddLoading");
export const setIsDetailLoading = setLoadingState("isDetailLoading");
export const setIsEditing = setLoadingState("isEditing");
export const setIsRequiremntUpdateLoading = setLoadingState("isRequiremntUpdateLoading");
export const setIsRemoveAssignedEmployerLoading = setLoadingState("isRemoveAssignedEmployerLoading");

export const setSelectedRecordForRemove = setDialogState("selectedRecordForRemove");

export const setEditFormData = form => ({
    type: types.SET_EDIT_FORM_VALUES,
    payload: { form },
});

const setFormDataFactory = key => value => ({
    type: types.SET_FORM_VALUES,
    payload: { key, value },
});
export const increaseHelpText = setFormDataFactory('helpTexts');
export const decreaseHelpText = setFormDataFactory('helpTexts');

export const setAdditionalRequirementForm = (key, value) => ({
    type: types.SET_FORM_VALUES,
    payload: { key, value },
});

export const clearAdditionalRequirementForm = () => ({
    type: types.CLEAR_FORM,
    payload: {}
});


export const setAdditionalRequirementListData = (data, part) => ({ type: types.SET_ADDITIONAL_REQUIREMENTS_DATA, payload: { data, part } });

export const fetchAdditionalRequirementsTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getAdditionalRequirementListPagingData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.additionalRequirementList)(pageInfo, pageIndex));
    const relations = {
        'Group': 'Group.Id'
    };
    const filterInfo = JSON.parse(JSON.stringify(getAdditionalRequirementsData(state, { part: "filter" })));
    const findRelationId = (rel, id) => {
        if (Object.values(rel).find(key => key === id)) {
            return 'name';
        }
        return id;
    };
    return ApolloClient.query({
        query: GetRequirements,
        variables: {
            ...pagingVars,
            order: getAdditionalRequirementsData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: findRelationId(relations, filter.id),
                    value: Array.isArray(filter.value) ? filter.value.map(a => a.label) : filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setAdditionalRequirementListData(flattenGraphQLArray([]), "data"));
                throw Error(errors[0].message);
            } else {
                const { getRequirements } = data;
                const paginationData = {
                    ...getRequirements.pageInfo,
                    totalCount: getRequirements.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.additionalRequirementList, paginationData),
                );
                dispatch(setAdditionalRequirementListData(flattenGraphQLArray(getRequirements), "data"));
                dispatch(pagination.doneLoading(paginationSettings.additionalRequirementList));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.additionalRequirementList));
        });
};

export const setAdditionalRequirementListSort = sort => dispatch => {
    dispatch(setAdditionalRequirementListData(sort, "sort"));
};

export const setAdditionalRequirementListFilter = filter => dispatch => {
    dispatch(setAdditionalRequirementListData(filter, "filter"));
};

export const changeAdditionalRequirementListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.additionalRequirementList, { pageSize }));
};


export const createRequirement = () => (dispatch, getState) => {
    dispatch(setIsRequiremntAddLoading(true));
    const {
        type,
        name,
        summary,
        frenchSummary,
        description,
        frenchDescription,
        title,
        frenchTitle,
        isConsent,
        groupNames,
        helpTexts,
        isDocHaveExpiry,
        frenchName
    } = getState().additionalRequiremnts.form;
    let data = {
        type,
        name,
        description,
        frenchDescription,
        groupNames: groupNames.map(a => a.label),
        helpTexts: helpTexts.filter(help => help?.question?.trim() && help?.answer?.trim() && help?.frenchQuestion?.trim() && help?.frenchAnswer?.trim()),
    };

    if (type === "Consent") {
        data = {
            ...data,
            summary,
            frenchSummary,
            title,
            frenchTitle,
            isConsent,
        }
    } else {
        data = {
            ...data,
            isDocHaveExpiry,
            frenchName
        }
    }

    return ApolloClient.mutate({
        mutation: CreateRequirement,
        variables: { data },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                dispatch(MetaActions.errorToast("Error : " + errors[0].message));
                dispatch(setIsRequiremntAddLoading(false));
                return;
            }
            dispatch(clearAdditionalRequirementForm());
            let message = `${data.createRequirement.type === "Document" ? data.createRequirement.type : !data.createRequirement.isConsent ? 'Validation' : 'Consent'} Created! You can add it to more employer also`;
            dispatch(MetaActions.successToast(message));
            dispatch(setIsRequiremntAddLoading(false));
            history.push('/additional-requirements/overview');
            return data;
        })
        .catch(e => {
            dispatch(setIsRequiremntAddLoading(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not create requirement. (${e.message})`
                )
            );
            throw e;
        });
};



// Details actions

const setRequirementDetails = (data) => ({ type: types.SET_ADDITIONAL_REQUIREMENT_DETAILS, payload: { data } });

export const fetchsRequirementdetails = (id, isLoading = true, callback) => (dispatch) => {
    dispatch(setIsDetailLoading(isLoading));
    return ApolloClient.query({
        query: RequirementDetailsById,
        variables: {
            id: +id
        }
    })
        .then(async ({ data, errors }) => {
            if (!data && errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                if (callback) await callback(id);
                dispatch(setIsDetailLoading(false));
                dispatch(setRequirementDetails(data.getRequirement));
                dispatch(setEditFormData({ ...data.getRequirement, groupNames: data?.getRequirement?.groups?.map(a => ({ label: a.name, value: a.id, data: a })) }));
                dispatch(setIsEditing(false));
            }
        })
        .catch(e => {
            dispatch(setIsDetailLoading(false));
            dispatch(setIsEditing(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const setActiveTab = (activeTab) => ({
    type: types.SET_ACTIVE_TAB,
    payload: { activeTab }
});

export const updateRequirement = () => (dispatch, getState) => {
    dispatch(setIsRequiremntUpdateLoading(true));
    const {
        type,
        name,
        summary,
        frenchSummary,
        description,
        frenchDescription,
        title,
        frenchTitle,
        isConsent,
        groupNames,
        helpTexts,
        isDocHaveExpiry,
        frenchName,
        id
    } = getState().additionalRequiremnts.form;
    let data = {
        type,
        id,
        name,
        description,
        frenchDescription,
        groupNames: groupNames.map(a => a.label),
        helpTexts: helpTexts?.map(a => {
            delete a?.id;
            delete a?.__typename;
            return a;
        }).filter(help => help?.question?.trim() && help?.answer?.trim() && help?.frenchQuestion?.trim() && help?.frenchAnswer?.trim()),
    };

    if (type === "Consent" || type === "Validation") {
        data = {
            ...data,
            summary,
            frenchSummary,
            title,
            frenchTitle,
            isConsent
        }
    } else {
        data = {
            ...data,
            isDocHaveExpiry,
            frenchName
        }
    }

    return ApolloClient.mutate({
        mutation: UpdateRequirement,
        variables: { data },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                dispatch(MetaActions.errorToast("Error : " + errors[0].message));
                dispatch(setIsRequiremntUpdateLoading(false));
                return;
            }
            dispatch(fetchsRequirementdetails(data.updateRequirement.id, false));
            let message = `${data.updateRequirement.type === "Document" ? data.updateRequirement.type : !data.updateRequirement.isConsent ? 'Validation' : 'Consent'} Updated!`;
            dispatch(MetaActions.successToast(message));
            dispatch(setIsRequiremntUpdateLoading(false));
            return data;
        })
        .catch(e => {
            dispatch(setIsRequiremntUpdateLoading(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not update requirement. (${e.message})`
                )
            );
            throw e;
        });
};


// Assigned Employers

export const addNewRow = () => ({ type: types.SET_ADD_NEW_ROW });
export const removeRow = (index) => ({ type: types.SET_REMOVE_ROW, payload: { index } });
export const updateAssignedEmployerFormData = (index, key, data) => ({ type: types.UPDATE_ASSIGNED_EMPLOYER_FORM, payload: { data, key, index } });
export const setExistingRecords = (data) => ({ type: types.SET_EXISTING_RECORDS, payload: { data } });

export const setAssignedEmployerListData = (data, part) => ({ type: types.SET_ASSIGNED_EMPLOYERS_DATA, payload: { data, part } });
export const setAllAssignedEmployersByRequirementId = (data) => ({ type: types.SET_ALL_ASSIGNED_EMPLOYERS, payload: { data } });

export const setAssignedEmployerListFilter = filter => dispatch => {
    dispatch(setAssignedEmployerListData(filter, "filter"));
};

export const setAssignedEmployerListSort = sort => dispatch => {
    dispatch(setAssignedEmployerListData(sort, "sort"));
};

export const changeAssignedEmployerListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.assignedEmployerList, { pageSize }));
};

export const fetchAssignedEmployerTableData = (pageIndex, id) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getAssignedEmployerListPagingData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.assignedEmployerList)(pageInfo, pageIndex));
    const relations = {
        'Employer': ['companyName', 'city'],
        'WorkType': ['label'],
    };
    const filterInfo = JSON.parse(JSON.stringify(getAssignedEmployerData(state, { part: "filter" })));

    return ApolloClient.query({
        query: GetAssignedEmployers,
        variables: {
            ...pagingVars,
            order: getAssignedEmployerData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: [{ field: 'id', value: id, relation: 'REQUIREMENT' }, ...filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: Array.isArray(filter.value) ? filter.value.map(a => a.label) : filter.value,
                    relation: getRelation(relations, filter.id),
                };
            })],
        },
    }).then(({ data, errors }) => {
        if (!data) {
            dispatch(setAssignedEmployerListData(flattenGraphQLArray([]), "data"));
            throw Error(errors[0].message);
        } else {
            const { getEmployerRequirements } = data;
            const paginationData = {
                ...getEmployerRequirements.pageInfo,
                totalCount: getEmployerRequirements.totalCount,
            };

            dispatch(
                pagination.updatePageInfo(paginationSettings.assignedEmployerList, paginationData),
            );
            dispatch(setAssignedEmployerListData(flattenGraphQLArray(getEmployerRequirements), "data"));
            dispatch(pagination.doneLoading(paginationSettings.assignedEmployerList));
        }
    })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.assignedEmployerList));
        });
};


export const assignEmployers = (id) => (dispatch, getState) => {
    dispatch(setIsRequiremntUpdateLoading(true));
    const list = getState().additionalRequiremnts.assignNewEmployerList;

    return ApolloClient.mutate({
        mutation: CreateOrUpdateEmployerHasRequirement,
        variables: {
            data: list.map(a => ({
                employerId: a.employer.value,
                requirementId: +id,
                workTypeId: a.workType.value,
                validityType: a.validity.type,
                validityValue: a.validity.type === 'date' ? moment(a.validity.value).format("YYYY-MM-DD") : a.validity.value,
                isMandatory: a.isMandatory
            }))
        },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                dispatch(MetaActions.errorToast("Error : " + errors[0].message));
                dispatch(setIsRequiremntUpdateLoading(false));
                return;
            }
            if (data?.createOrUpdateEmployerHasRequirement?.existing) {
                dispatch(MetaActions.errorToast("The highlighted records already exists in our system. Please enter a different work type value for this."));
                dispatch(setExistingRecords(data?.createOrUpdateEmployerHasRequirement?.entities));
                dispatch(setIsRequiremntUpdateLoading(false));
                return;
            }
            let message = `Employer Added! You can add it to more employers also`;
            dispatch(MetaActions.successToast(message));
            dispatch(setIsRequiremntUpdateLoading(false));
            history.replace(`/additional-requirements/${window.btoa(id)}`);
            return data;
        })
        .catch(e => {
            dispatch(setIsRequiremntUpdateLoading(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not assign employers. (${e.message})`
                )
            );
            throw e;
        });
};

export const removeAssignedEmployer = (requirementId) => (dispatch, getState) => {
    dispatch(setIsRemoveAssignedEmployerLoading(true));
    const id = getIsSelectedRecordForRemove(getState());

    return ApolloClient.mutate({
        mutation: RemoveEmployerHasRequirement,
        variables: {
            id
        },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                dispatch(MetaActions.errorToast("Error : " + errors[0].message));
                dispatch(setIsRemoveAssignedEmployerLoading(false));
                return;
            }
            dispatch(MetaActions.successToast('Employer Removed!'));
            dispatch(setIsRemoveAssignedEmployerLoading(false));
            dispatch(setSelectedRecordForRemove(null));
            dispatch(fetchAssignedEmployerTableData(0, requirementId));
            return data;
        })
        .catch(e => {
            dispatch(setIsRemoveAssignedEmployerLoading(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not remove employers. (${e.message})`
                )
            );
            throw e;
        });
};

export const clearAssignedEmployersForm = () => ({
    type: types.CLEAR_ASSIGNED_FORM,
    payload: {}
});


export const fetchAllAssignedEmployersByRequirementId = (id) => (dispatch) => {
    return ApolloClient.query({
        query: GetAssignedEmployers,
        variables: {
            like: [{ field: 'id', value: id, relation: 'REQUIREMENT' }],
        },
    }).then(({ data, errors }) => {
        if (!data) {
            dispatch(setAllAssignedEmployersByRequirementId(flattenGraphQLArray([])));
            throw Error(errors[0].message);
        } else {
            const { getEmployerRequirements } = data;
            dispatch(setAllAssignedEmployersByRequirementId(flattenGraphQLArray(getEmployerRequirements)));
        }
    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
    });
};
