import { supportedAvatarFiles } from "./constant";

export const allowedProvinceDocumentsTypes = [
    {
        "extension": ".doc",
        "mime": "application/msword"
    },
    {
        "extension": ".docx",
        "mime": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    },
    {
        "extension": ".gif",
        "mime": "image/gif"
    },
    {
        "extension": ".jpg",
        "mime": "image/jpeg"
    },
    {
        "extension": ".jpeg",
        "mime": "image/jpeg"
    },
    {
        "extension": ".pdf",
        "mime": "application/pdf"
    },
    {
        "extension": ".png",
        "mime": "image/png"
    },
    {
        "extension": ".svg",
        "mime": "image/svg+xml"
    },
    {
        "extension": ".jfif",
        "mime": "image/jpeg"
    },
    {
        "extension": ".tiff",
        "mime": "image/tiff"
    },
    {
        "extension": ".tif",
        "mime": "image/tiff"
    },
];

export const isValidWorkerUploadDocuments = (files) => files.every(p => allowedProvinceDocumentsTypes.find(f => f.mime === p.type))


// Validate file type and size [default size is 5MB]
export const isValidFile = (file, size = 5, type = "MB") => {
    let isValid = supportedAvatarFiles.find(f => f.mime === file.type);
    if (isValid) {
        isValid = isValid && (file.size < size * 1024 * 1024);
        if (isValid) {
            return ({
                isValid: true,
                message: "Valid File"
            });
        } else
            return ({
                isValid: false,
                message: `File larger than ${type === "MB" ? size : size * 1024}${type} are not allowed.`
            });
    }
    else
        return ({
            isValid: false,
            message: "This file type is not supported"
        });
};


export const downloadFileFromLink = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('rel', "noopener noreferrer");
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
}
