import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApproveAll from 'views/Verifications/Dialogs/ApproveAll';
import { actions as Actions, selectors as Selectors } from "store/modules/verifications";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        background: 'linear-gradient(0deg, #f7f7fb, #f7f7fb, #f7f7fb, #f7f7fb00)',
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        zIndex: 999,
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        width: 200
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function BulkApprove() {
    const classes = useStyles();
    const isOpen = useSelector(Selectors.getApproveAllDialog);
    const isLoading = useSelector(Selectors.getIsBulkApproveLoading);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(Actions.setApproveAllDialog(false));
    };

    const handleOpen = () => {
        dispatch(Actions.setApproveAllDialog(true));
    };

    return (
        <>
            <div className={classes.root}>
                <Button
                    onClick={handleOpen}
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    disabled={isLoading}
                >
                    Approve All
                    {isLoading && <CircularProgress size={24} className={classes.progress} />}
                </Button>
            </div>
            <ApproveAll onClose={handleClose} open={isOpen} />
        </>
    )
}
