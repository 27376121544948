import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import SubNav from "../../../components/Navigation/SubNav";
import SubNavTab from "../../../components/Navigation/SubNavTab";
import { Assessment, PermContactCalendar } from "@mui/icons-material";
import WorkerPayrollTable from "./components/WorkerPayrollTable";
import WorkerPayrollFilter from "./components/WorkerPayrollFilter";
import WorkerWisePayrollTable from "./components/WorkerWisePayrollTable";
import WorkerWisePayrollFilter from "./components/WorkerWisePayrollFilter";
import HolidayPayrollFilter from "./components/HolidayPayrollFilter";
import HolidayPayrollTable from "./components/HolidayPayrollTable";

function WorkerPayrollView() {
    const [tavValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    }
    return (
        <React.Fragment>
            <SubNav
                value={tavValue}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab key="workerPayroll" label="Worker Payroll By Period" icon={<Assessment />} count={null} />,
                    <SubNavTab key="workerWisePayroll" label="Worker Payroll Summary" icon={<PermContactCalendar />} count={null} />,
                    <SubNavTab key="holidayPayroll" label="Holiday Payroll Summary" icon={<Assessment />} count={null} />,
                ]}
            />
            {tavValue === 0 && (
                <MainContent>
                    <WorkerPayrollFilter />
                    <WorkerPayrollTable />
                </MainContent>
            )}
            {tavValue === 1 && (
                <MainContent>
                    <WorkerWisePayrollFilter />
                    <WorkerWisePayrollTable />
                </MainContent>
            )}

            {tavValue === 2 && (
                <MainContent>
                    <HolidayPayrollFilter />
                    <HolidayPayrollTable />
                </MainContent>
            )}
        </React.Fragment>
    );
}

export default WorkerPayrollView;
