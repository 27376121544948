/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";

import { fetchProvinces } from "./helpers/actions";
import { emphasize } from "@mui/material/styles";
import LoadingMessage from "./components/LoadingMessage";
// import styles from "./helpers/styles";

const styles = (theme) => ({
    input: {
        display: "flex",
        padding: "8px 8px",
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
        "& div:last-child": {
            width: (props) => props.isMulti ? "100%" : "auto",
            position: "relative",
        }
    },
    chip: {
        margin: `${theme.spacing(0.25)} ${theme.spacing(0.10)}`,
        fontSize: 10,
        height: 22,
        "& svg": {
            height: 18,
            width: 18
        },
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    singleValue: {
        fontSize: 16,
        lineHeight: 1
    },
    placeholder: {
        position: "absolute",
        left: 10,
        fontSize: 14,
        color: '#a4a7ab'
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(0),
        left: 0,
        right: 0,
    }
})

class ProvinceAutocomplete extends React.Component {
    state = {
        isLoading: false,
        options: []
    }
    selectComponents = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingMessage,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    ...(this.props.style || {}),
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    height: '17px',
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetchProvinces().then(({ data: { regions } }) => {
            this.setState({
                isLoading: false,
                options: regions.map(a => ({
                    value: a.id,
                    label:a.name,
                    data: a
                }))
            });
        }).catch(er => {
            this.setState({ isLoading: false });
        })
    };

    render() {
        const { classes, selectedProvinceIds = [], inputProps = {}, ...passProps } = this.props;
        const options = selectedProvinceIds?.length ? this.state?.options?.filter(o => !selectedProvinceIds?.includes(o.value)) : this.state.options;
        const defaultSelectedOp = selectedProvinceIds?.length ? this.state?.options?.filter(o => selectedProvinceIds?.includes(o.value)) : [];

        return (
            <Select
                {...passProps}
                classes={classes}
                styles={{ ...this.selectStyles, ...(this.props.menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 999999 }) } : {}) }}
                isClearable
                value={this.props.value}
                {...(passProps.isMulti ? { value: defaultSelectedOp } : {})}
                placeholder="Select"
                isLoading={this.state.isLoading}
                options={options}
                onChange={this.props.onChange}
                components={this.selectComponents}
                textFieldProps={{
                    label: "Select Province",
                    placeholder: "Select",
                    ...inputProps,
                }}
            />
        );
    }
}

ProvinceAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProvinceAutocomplete);
