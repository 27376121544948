import React, { useEffect } from "react";
import FormRow from "./FormRow";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import HelperMessage from "./HelperMessage";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";
import { find, isEqual, remove } from "lodash";
import moment from "moment-timezone";
import { weekDaysList, monthDaysList } from "utils/constant";
import { Grid, TextField, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { useState } from "react";
import { getDaysInTwoDates, getWeeksInTwoDates, getMonthsInTwoDates, getDaysOfMonthsBetweenDates, getDaysOfWeekBetweenDates, convertUTCToTimezone, getTimeZone } from "utils/time";

const styles = {
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '5px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '300',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    date: {
        padding: '5px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '300',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    addAnother: {
        fontWeight: 'bold',
        cursor: 'pointer',
        width: 'fit-content'
    },
    disable: {
        '& input': {
            color: '#8e8e93 !important'
        }
    }
};

function DateTimeSection(props) {

    const {
        classes,
        date,
        startTime,
        endTime,
        requiresBreak,
        breakTypeList,
        breakType,
        breakMins,
        recurringType,
        repeatEvery,
        recurringEndDate,
        weekDays,
        monthDate,
        formattedDuration,
        isEditing,
        jobType,
        isRecurring,
        isLessThanFour,
        isGreaterThanTen,
        isValidStartTime,
        isValidEndTime,
        isValidDate,
        isValidRecurringEndDate,
        announcement
    } = props;

    const dispatch = useDispatch();
    const [selectedRecurringType, setSelectedRecurringType] = useState(recurringType);
    const [RecurringEndDate, setRecurringEndDate] = useState(recurringEndDate);
    const [totalRepeatEvery, setTotalRepeatEvery] = useState(1);
    const params = new URLSearchParams(window.location.search);
    const type = params.get("type");
    const isFixedTermJob = useSelector(PostingSelectors.getIsFixedTermJob);
    const provinceWiseBreakRule = useSelector(PostingSelectors.getProvinceWiseBreakRule);


    // useEffect(() => {
    //     const diffDays = Math.ceil(moment.duration(recurringEndDate.diff(date)).asDays());
    //     console.log(diffDays, recurringType, 'aaaa');
    //     if (isRecurring === true) {
    //         if (recurringType === "Weekly" && diffDays < 7) {
    //             setRecurringEndDate(moment(date).add(1, 'weeks'));
    //             dispatch(PostingActions.setRecurringEndDate(moment(date).add(1, 'weeks')));
    //         }
    //         else if (recurringType === "Monthly" && diffDays < 30) {
    //             setRecurringEndDate(moment(date).add(1, 'months'));
    //             dispatch(PostingActions.setRecurringEndDate(moment(date).add(1, 'months')));
    //         }
    //         else if (recurringType === "Daily" && diffDays < 1) {
    //             setRecurringEndDate(moment(date).add(1, 'days'));
    //             dispatch(PostingActions.setRecurringEndDate(moment(date).add(1, 'days')));
    //         }
    //     }
    // }, [recurringType, date, recurringEndDate]);

    useEffect(() => {
        if (isRecurring === true) {
            let repeat = 1;
            switch (recurringType) {
                case "Daily": {
                    repeat = getDaysInTwoDates(startTime, RecurringEndDate);
                    setTotalRepeatEvery(repeat);
                    break;
                }
                case "Weekly": {
                    repeat = getWeeksInTwoDates(startTime, RecurringEndDate);
                    setTotalRepeatEvery(repeat);
                    break;
                }
                case "Monthly": {
                    repeat = getMonthsInTwoDates(startTime, RecurringEndDate);
                    setTotalRepeatEvery(repeat)
                    break;
                }
            }
        }
    }, [recurringType, isRecurring, RecurringEndDate]);

    useEffect(() => {
        if (!requiresBreak) {
            dispatch(PostingActions.setBreakType(0));
            dispatch(PostingActions.setBreakMins(0));
        } else {
            if (breakMins === 0) {
                dispatch(PostingActions.setBreakMins(30));
            }
        }
    }, [requiresBreak])

    const dateHelper = !isValidDate ? (
        <HelperMessage icon={<ErrorIcon color="error" />} message="Job date is not a valid date." />
    ) : null;

    const recurringEnddateHelper = !isValidRecurringEndDate ? (
        <HelperMessage icon={<ErrorIcon color="error" />} message="Recurring end date is not a valid." />
    ) : null;

    const helperProps = {
        icon: <InfoIcon color="disabled" />,
        message: formattedDuration,
    };

    if (!isValidStartTime) {
        helperProps.icon = <ErrorIcon color="error" />;
        helperProps.message = "Job start time is not a valid date.";
    } else if (!isValidEndTime) {
        helperProps.icon = <ErrorIcon color="error" />;
        helperProps.message = "Job end time is not a valid date.";
        // } else if (isPast) {
        //   helperProps.icon = <ErrorIcon color="error" />;
        //   helperProps.message = "Job start must be in the future.";
    } else if (isLessThanFour) {
        helperProps.message = `Job is ${formattedDuration}.`;
        helperProps.caption = "Worker(s) will be paid a minimum of 4 hours.";
    } else if (isGreaterThanTen) {
        helperProps.message = `Job is ${formattedDuration}.`;
        helperProps.caption = "Worker(s) may be entitled to overtime.";
    }

    const onDateChange = (date) => {
        dispatch(PostingActions.setDate(date));
    }

    const onStartTimeChange = (time) => {
        dispatch(PostingActions.setStartTime(time));
        if (announcement.length) dispatch(PostingActions.setAnnouncement(announcement.map((n) => {
            n.time = moment(time);
            return n
        })));
    }

    const onBreakTypeChange = (event) => {
        dispatch(PostingActions.setBreakType(event.target.value));
    }

    const onBreakMinsChange = (event) => {
        if (event.target.value <= 60) {
            dispatch(PostingActions.setBreakMins(event.target.value));
        }
    }

    const onEndTimeChange = (time) => {
        dispatch(PostingActions.setEndTime(time))
    }

    const handleChangeIsRecurring = (e) => {
        dispatch(PostingActions.setIsRecurring(e.target.checked));
    }

    const handleChangeRecurringType = (e) => {
        setSelectedRecurringType(e.target.value)
        dispatch(PostingActions.setRecurringType(e.target.value));
        handleChangeRecurringEndDate(moment(startTime).add(1, getMinRecurringEndDate(e.target.value)));
    }

    const handleRepeatEvery = (e) => {
        if (e.target.value <= totalRepeatEvery) {
            dispatch(PostingActions.setRecurringRepeatEvery(parseInt(e.target.value)));
        }
    }

    const handleChangeRecurringEndDate = (date) => {
        setRecurringEndDate(date);
        dispatch(PostingActions.setRecurringEndDate(date));
    }

    const handleChangeWeekDays = (day) => {
        if (find(weekDays, n => isEqual(n, day))) {
            remove(weekDays, n => isEqual(n, day));
        } else {
            weekDays.push(day);
        }
        dispatch(PostingActions.setRecurringWeekDays([...weekDays]));
    }

    const handleChangeMonthDate = (e) => {
        if (find(monthDate, n => n === e)) {
            remove(monthDate, n => n === e);
            dispatch(PostingActions.setRecurringMonthDate([...monthDate]));
        } else {
            monthDate.push(e);
            dispatch(PostingActions.setRecurringMonthDate([...monthDate]));
        }
    }

    //Get minimum recurring end date by recurring type
    const getMinRecurringEndDate = (type) => {
        if (!type || type === "Daily") return 'days';
        else if (type === "Weekly") return 'weeks';
        else if (type === "Monthly") return 'months';
    }

    // const disableDates = current => {
    //     return current.isAfter(moment(date).subtract(1, 'days').add(1, getMinRecurringEndDate(recurringType)));
    // }

    React.useEffect(() => {
        if (isRecurring === true) handleChangeRecurringEndDate(moment(recurringEndDate));
    }, [startTime, isRecurring, recurringType])

    return (
        <React.Fragment>
            <FormRow helper={dateHelper} style={{ marginTop: 12, zIndex: 1111 }}>
                <FormControl fullWidth style={{ zIndex: 110 }} {...(jobType === "edit" && isEditing ? { disabled: true, className: classes.disable } : {})}>
                    <InputLabel htmlFor="date">Job Date</InputLabel>
                    <Datetime
                        timeFormat={false}
                        id="date"
                        input
                        dateFormat="MMMM Do YYYY"
                        onChange={onDateChange}
                        value={date}
                        inputProps={{
                            disabled: jobType === "edit" && isEditing
                        }}
                    // isValidDate={isValidStartDate}
                    />
                </FormControl>
            </FormRow>

            <Grid container item xs={12} style={{ marginTop: 32 }}>
                <Grid container item xs={4} direction="column" style={{ paddingRight: 4, zIndex: 100 }}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="start">Start Time</InputLabel>
                        <Datetime
                            input
                            dateFormat={false}
                            id="start"
                            timeConstraints={{ minutes: { step: 15 } }}
                            timeFormat="h:mm a"
                            onChange={onStartTimeChange}
                            value={startTime}
                        />
                    </FormControl>
                </Grid>

                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4, zIndex: 200 }}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="end">End Time</InputLabel>
                        <Datetime
                            input
                            dateFormat={false}
                            id="end"
                            timeConstraints={{ minutes: { step: 15 } }}
                            timeFormat="h:mm a"
                            onChange={onEndTimeChange}
                            value={endTime}
                        />
                    </FormControl>
                </Grid>

                <Grid container item xs={4}>
                    <HelperMessage {...helperProps} />
                </Grid>
            </Grid>

            {(provinceWiseBreakRule || requiresBreak) && (
                <FormRow
                    helper={
                        <HelperMessage
                            icon={<InfoIcon color="disabled" />}
                            message={breakTypeList[breakType].message}
                        />
                    }
                    style={{ marginTop: 32 }}
                >
                    <FormControl fullWidth>
                        <Grid container item xs={12}>
                            <Grid container item xs={6} direction="column" style={{ paddingRight: 4 }}>
                                <TextField
                                    id="breakMin"
                                    label="Break Min"
                                    value={breakMins}
                                    onChange={onBreakMinsChange}
                                    onBlur={onBreakMinsChange}
                                    type="number"
                                    margin="none"
                                    inputProps={{
                                        min: 0,
                                    }}
                                />
                            </Grid>

                            <Grid container item xs={6} direction="column" style={{ paddingLeft: 4 }}>
                                <TextField
                                    select
                                    label="Break Type"
                                    value={breakType}
                                    onChange={onBreakTypeChange}
                                    margin="none"
                                >
                                    {breakTypeList.map(({ label, value }) => (
                                        <MenuItem key={`breakType-${value}`} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </FormControl>
                </FormRow>
            )}

            {
                <>
                    <FormRow style={{ marginTop: "10px" }}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Switch
                                        id="isRecurring"
                                        checked={isRecurring}
                                        onChange={handleChangeIsRecurring}
                                        name="isRecurring"
                                    />
                                }
                                disabled={isFixedTermJob || (jobType === "edit" && isEditing)}
                                style={{ color: "rgb(33 42 52)", width: "fit-content", justifyContent: 'flex-end', marginLeft: '0' }}
                                label="Is Recurring Job"
                            />
                        </FormControl>
                    </FormRow>

                    {isRecurring === true && (
                        <>
                            <FormRow
                                helper={recurringEnddateHelper}
                                style={{ marginTop: 32 }}>
                                <Grid container item xs={12}>
                                    <Grid container item xs={4} style={{ paddingRight: 4 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                label="Recurring Type"
                                                value={recurringType}
                                                onChange={handleChangeRecurringType}
                                                margin="none"
                                                fullWidth
                                                disabled={jobType === "edit" && isEditing}
                                            >
                                                <MenuItem value={'Daily'}>Daily</MenuItem>
                                                <MenuItem value={'Weekly'}>Weekly</MenuItem>
                                                {!isFixedTermJob && <MenuItem value={'Monthly'}>Monthly</MenuItem>}
                                            </TextField>
                                        </FormControl>
                                    </Grid>

                                    <Grid container item xs={4} style={{ paddingRight: 4 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="Repeat"
                                                label={`Repeat Every ${recurringType === "Monthly" ? "Month" : recurringType === "Weekly" ? "Week" : "Day"}`}
                                                value={repeatEvery}
                                                onChange={handleRepeatEvery}
                                                onBlur={handleRepeatEvery}
                                                type="number"
                                                margin="none"
                                                inputProps={{
                                                    min: 1,
                                                }}
                                                disabled={jobType === "edit" && isEditing}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid container item xs={4} style={{ paddingRight: 4, zIndex: 100, marginTop: "-1px" }}>
                                        <FormControl fullWidth {...(jobType === "edit" && isEditing ? { disabled: true, className: classes.disable } : {})}>
                                            <InputLabel htmlFor="end">Recurring Ends</InputLabel>
                                            <Datetime

                                                timeFormat={false}
                                                id="date"
                                                input
                                                inputProps={{
                                                    disabled: jobType === "edit" && isEditing
                                                }}
                                                // isValidDate={disableDates}
                                                dateFormat="MMMM Do YYYY"
                                                onChange={handleChangeRecurringEndDate}
                                                value={RecurringEndDate}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </FormRow>

                            <FormRow>
                                {recurringType === "Weekly" && (
                                    <div className={classes.weekDays} style={{ marginTop: "20px" }}>
                                        {weekDaysList.map(day => (
                                            <div key={day.value}
                                                onClick={() => (jobType !== "edit") && handleChangeWeekDays(day)}
                                                className={`${classes.days} ${weekDays.indexOf(day) > -1 ? classes.selectedDays : ''}`}>
                                                {day.label}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FormRow>

                            <FormRow>
                                {recurringType === "Monthly" && (
                                    <div className={classes.weekDays} style={{ marginTop: "20px" }}>
                                        {monthDaysList.map(n => (
                                            <div key={n}
                                                onClick={() => (jobType !== "edit") && handleChangeMonthDate(n)}
                                                className={`${classes.date} ${monthDate.indexOf(n) > -1 ? classes.selectedDays : ''}`}>
                                                {n}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </FormRow>
                        </>
                    )}
                </>
            }

        </React.Fragment>
    )
}

DateTimeSection.propTypes = {
    formattedDuration: PropTypes.string,
    isLessThanFour: PropTypes.bool.isRequired,
    isGreaterThanTen: PropTypes.bool.isRequired,
    isValidDate: PropTypes.bool.isRequired,
    isValidStartTime: PropTypes.bool.isRequired,
    isValidEndTime: PropTypes.bool.isRequired,
    requiresBreak: PropTypes.bool.isRequired,
    breakTypeList: PropTypes.array.isRequired,
    date: PropTypes.instanceOf(moment).isRequired,
    startTime: PropTypes.instanceOf(moment).isRequired,
    endTime: PropTypes.instanceOf(moment).isRequired,
    breakType: PropTypes.number.isRequired,
};

DateTimeSection.defaultProps = {
    isValidDate: true,
};

export default withStyles(styles)(DateTimeSection);
