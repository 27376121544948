import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import query, { ProvincesQuery, groupQuery, requirementsQuery, workerListQuery, GetAssignedEmployers, CitiesQuery } from "../graphql/queries";

const formattedSuggestion = structured_formatting => ({
    mainText: structured_formatting.main_text,
    secondaryText: structured_formatting.secondary_text,
});

export const fetchEmployers = pageFilter => {
    return ApolloClient.query({
        query: query,
        variables: {
            first: 5,
            order: [
                {
                    field: "companyName",
                    direction: "ASC",
                },
            ],
            like: [
                {
                    field: "companyName",
                    value: pageFilter,
                },
            ],
            canPost: true,
        },
    })
        .then(({ data }) => {
            const employers = flattenGraphQLArray(data.employers).map(employer => ({
                value: employer.id,
                label: employer.companyName,
                data: employer,
            }));
            return employers;
        })
        .catch(console.log);
};

export const fetchWorkers = pageFilter => {
    return ApolloClient.query({
        query: workerListQuery,
        variables: {
            first: 10,
            order: [
                {
                    field: "firstName",
                    direction: "ASC",
                },
            ],
            workerName: pageFilter.workerName,
            ...(pageFilter.jobTags ? { jobTags: pageFilter.jobTags } : {})
        },
    })
        .then(({ data }) => {
            const workers = flattenGraphQLArray(data.workers).map(worker => ({
                value: worker.id,
                label: worker.firstName + ' ' + worker.lastName,
                data: worker,
            }));
            return workers;
        })
        .catch(console.log);
};

export const fetchPlaceDetails = placeId => {
    const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
    return new Promise((resolve, reject) => {
        placesService.getDetails(
            { fields: ["address_components", "geometry", "utc_offset_minutes"], placeId },
            (result, status) => {
                if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                    reject();
                    return;
                }

                const addressParts = result.address_components.reduce((acc, { types, ...rest }) => {
                    if (types.includes("street_number") && rest.short_name) {
                        acc.streetNumber = rest.short_name;
                    }

                    if (types.includes("route") && rest.long_name) {
                        acc.streetName = rest.long_name;
                    }

                    if (types.includes("locality") && rest.long_name) {
                        acc.city = rest.long_name;
                    }

                    if (types.includes("administrative_area_level_1") && rest.short_name) {
                        acc.region = rest.short_name;
                    }

                    if (types.includes("country") && rest.long_name) {
                        acc.country = rest.long_name;
                    }

                    if (types.includes("postal_code") && rest.long_name) {
                        acc.postalCode = rest.long_name;
                    }
                    return acc;
                }, {});

                resolve({
                    street: `${addressParts.streetNumber ? addressParts.streetNumber + " " : ""}${addressParts.streetName
                        }`,
                    city: addressParts.city,
                    region: addressParts.region,
                    country: addressParts.country,
                    postalCode: addressParts.postalCode,
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                    utc_offset_minutes: result.utc_offset_minutes,
                });
            }
        );
    });
};

export const fetchPlacePredictions = filter => {
    if (filter.length) {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        return new Promise((resolve, reject) => {
            autocompleteService.getPlacePredictions(
                {
                    componentRestrictions: {
                        country: "ca",
                    },
                    types: ["address"],
                    language: "en",
                    input: filter,
                },
                (predictions, status) => {
                    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                        reject();
                        return;
                    }

                    const suggestions = predictions.map((p, idx) => ({
                        value: p.id,
                        label: p.description,
                        data: {
                            id: p.id,
                            description: p.description,
                            placeId: p.place_id,
                            index: idx,
                            formattedSuggestion: formattedSuggestion(p.structured_formatting),
                            matchedSubstrings: p.matched_substrings,
                            terms: p.terms,
                            types: p.types,
                        },
                    }));

                    resolve(suggestions);
                }
            );
        });
    }

    return Promise.resolve([]);
};

export const fetchProvinces = () => {
    return ApolloClient.query({
        query: ProvincesQuery
    });
}

export const fetchCities = () => {
    return ApolloClient.query({
        query: CitiesQuery
    });
}

export const fetchGroups = pageFilter => {
    return ApolloClient.query({
        query: groupQuery,
        variables: {
            first: 10,
            order: [
                {
                    field: "name",
                    direction: "ASC",
                },
            ],
            like: pageFilter ? [
                {
                    field: "name",
                    value: pageFilter,
                },
            ] : [],
        },
    })
        .then(({ data }) => {
            const groups = flattenGraphQLArray(data.getGroups).map(group => ({
                value: group.id,
                label: group.name,
                data: group,
            }));
            return groups;
        })
        .catch(console.log);
};

export const fetchAssignedLocationsForRequirements = (pageFilter, requirementId) => {
    return ApolloClient.query({
        query: GetAssignedEmployers,
        variables: {
            first: 10,
            order: [
                {
                    field: "city",
                    relation: 'Employer',
                    direction: "ASC",
                },
            ],
            like: (pageFilter ? [
                {
                    field: "city",
                    relation: 'Employer',
                    value: pageFilter,
                },
            ] : []).concat({
                field: 'id',
                relation: "REQUIREMENT",
                value: requirementId
            }),
        },
    }).then(({ data }) => {
        const locations = flattenGraphQLArray(data.getEmployerRequirements).map(loc => ({
            value: loc.id,
            label: loc?.employer?.city,
            data: loc,
        }));
        return locations?.reduce((p, c) => {
            if (!c.label) return p;
            if (p?.find(a => a.label === c.label)) return p;
            return [...p, c];
        }, []);
    }).catch(console.log);
};

export const fetchRequirements = (pageFilter, extraFilter = []) => {
    return ApolloClient.query({
        query: requirementsQuery,
        variables: {
            first: 10,
            order: [
                {
                    field: "name",
                    direction: "ASC",
                },
            ],
            like: [...(pageFilter ? [
                {
                    field: "name",
                    value: pageFilter,
                },
            ] : []), ...extraFilter],
        },
    }).then(({ data }) => {
        const requirements = flattenGraphQLArray(data.getRequirements).map(req => ({
            value: req.id,
            label: req.name,
            data: req,
        }));
        return requirements;
    }).catch(console.log);
};

export const fetchEmployerRequirements = (pageFilter, extraFilter = []) => {
    return ApolloClient.query({
        query: GetAssignedEmployers,
        variables: {
            first: 10,
            order: [
                {
                    field: "name",
                    direction: "ASC",
                    relation: "REQUIREMENT",
                },
            ],
            like: [...(pageFilter ? [
                {
                    field: "name",
                    value: pageFilter,
                    relation: "REQUIREMENT",
                },
            ] : []), ...extraFilter],
        },
    }).then(({ data }) => {
        const requirements = flattenGraphQLArray(data.getEmployerRequirements).map(req => ({
            value: req.id,
            label: req?.requirement?.name,
            data: req,
        }));
        return requirements;
    }).catch(console.log);
};
