import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FormRow from './FormRow';
import { actions as JobPostActions, selectors as JobPostSelectors } from "store/modules/posting";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    label: {
        width: 'fit-content',
        marginTop: 8,
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary
        }
    }
}));

export default function JobTaypeSelection({ type }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isFixedTermJob = useSelector(JobPostSelectors.getIsFixedTermJob);

    const handleChange = ({ target: { value } }) => {
        dispatch(JobPostActions.setIsFixedTermJob(Boolean(+value)));
        dispatch(JobPostActions.setRecurringType("Daily"));
    };

    return (
        <React.Fragment>
            <FormRow style={{ marginTop: -16 }}>
                <FormControl fullWidth>
                    <RadioGroup
                        row
                        aria-labelledby="job-type-radio"
                        name="isFixedTerm"
                        value={+isFixedTermJob}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            disabled={isFixedTermJob && (type === "edit")}
                            classes={{ root: classes.label }}
                            value={0}
                            control={<Radio />}
                            label="Gig" />
                        <FormControlLabel
                            disabled={!isFixedTermJob && (type === "edit")}
                            classes={{ root: classes.label }}
                            value={1}
                            control={<Radio />}
                            label="Multi Shift" />
                    </RadioGroup>
                </FormControl>
            </FormRow>
        </React.Fragment>
    )
}
