import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Timeline from "@mui/icons-material/Timeline";
import ResendStubDialog from "../Dialogs/ResendStubDialog";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as FinanceActions } from "store/modules/finance";
import PaymentTimelineDialog from "../Dialogs/PaymentTimelineDialog";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function PositionPayrollMenu({ position }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuId = `action-menu-${position.id}`;
    const [openTransferTimelineDialog, setOpenTransferTimelineDialog] = useState(false);
    const [isResendingStub, setIsResendingStub] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };


    const canViewStub = position && position.payroll.length;

    const handleOpenTransferTimelineDialog = () => {
        closeMenu();
        setOpenTransferTimelineDialog(true);
    };

    const handleCloseTransferTimelineDialog = () => {
        setOpenTransferTimelineDialog(false);
    };

    const handleResendStub = () => {
        dispatch(FinanceActions.createAndSendPaystub(position.id));
        setIsResendingStub(false);
    };

    const toggleResendStubDialog = () => {
        closeMenu();
        setIsResendingStub(v => !v);
    };

    return <>
        <IconButton
            style={{ marginRight: 8, float: "right" }}
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={openMenu}
            disableRipple
            edge="end"
            size="small">
            <MoreVert color="primary" />
        </IconButton>
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}>
            {canViewStub ? (
                <MenuItem onClick={handleOpenTransferTimelineDialog}>
                    <Timeline className={classes.icon} color="action" />
                    Payment Timeline
                </MenuItem>
            ) : null}
            {canViewStub ? (
                <MenuItem onClick={toggleResendStubDialog}>
                    <PictureAsPdf className={classes.icon} color="action" />
                    Email Paystub
                </MenuItem>
            ) : null}
        </Menu>
        <PaymentTimelineDialog
            open={openTransferTimelineDialog}
            onClose={() => handleCloseTransferTimelineDialog()}
            maxWidth="sm"
            fullWidth={true}
            data={position.paymentTimeline}
            size="sm"
        />
        <ResendStubDialog
            open={isResendingStub}
            onClose={toggleResendStubDialog}
            onCancel={toggleResendStubDialog}
            onConfirm={handleResendStub}
            position={position}
        />
    </>;
}

PositionPayrollMenu.propTypes = {
    position: PropTypes.object.isRequired,
    isWorkerPickerOpen: PropTypes.bool.isRequired,
};

export default PositionPayrollMenu;
