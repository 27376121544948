import React, { useState, useEffect } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Collapse, Grid, IconButton, TextField, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";
import { TransitionGroup } from 'react-transition-group';
import { Close, Description, Visibility } from '@mui/icons-material';
import Dropzone from "components/Dropzone/Dropzone";
import { supportedAvatarFiles } from "utils/constant";
import { actions as MetaActions } from "store/modules/meta";
import { isValidFile } from "utils/file";

const style = (theme) => ({
    container: {
        marginBottom: theme.spacing(2),
        maxWidth: 350
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: theme.spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
        "& .MuiAlert-action": {
            alignItems: "center",
            marginTop: 0,
            paddingTop: 0
        }
    },
    selectedDocument: {
        marginTop: theme.spacing(1.5),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: theme.spacing(3),
        justifyContent: "center",
        alignItems: "center"
    },
    uploadDocContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&>p": {
            marginTop: "20px !important",
            textAlign: "left"
        },
        "&>div": {
            marginTop: "0 !important",
            minHeight: 130,

            "& svg": {
                height: 50,
                width: 50,
            },
            "& p": {
                fontSize: 12,
                paddingRight: 8,
                paddingLeft: 8,
            }
        }
    }
})

function EditWorkTypeDialog({ classes, workType }) {

    const dispatch = useDispatch();
    const [workTypeLabel, setWorkTypeLabel] = useState("");
    const [frenchName, setFrenchName] = useState("");
    const isEditGearDialog = useSelector(workTypesSelectors.getIsEditWorkTypeDialog);
    const [icon, setIcon] = useState(undefined);
    const [iconPreview, setIconPreview] = useState("");
    const [bannerImage, setBannerImage] = useState(undefined);
    const [bannerImagePreview, setBannerImagePreview] = useState("");
    const [bannerImage2X, setBannerImage2X] = useState(undefined);
    const [bannerImage2XPreview, setBannerImage2XPreview] = useState("");
    const [bannerImage3X, setBannerImage3X] = useState(undefined);
    const [bannerImage3XPreview, setBannerImage3XPreview] = useState("");
    const isLoading = useSelector(workTypesSelectors.getIsLoading);

    useEffect(() => {
        if (isEditGearDialog) {
            setWorkTypeLabel(workType.label);
            setFrenchName(workType.frenchName);
            if (workType?.iconUrl) {
                setIcon(workType?.iconUrl);
                setIconPreview(workType?.iconUrl);
            }
            if (workType?.bannerImageUrl) {
                setBannerImage(workType?.bannerImageUrl);
                setBannerImagePreview(workType?.bannerImageUrl);
            }
            if (workType?.bannerImageUrl2x) {
                setBannerImage2X(workType?.bannerImageUrl2x);
                setBannerImage2XPreview(workType?.bannerImageUrl2x);
            }
            if (workType?.bannerImageUrl3x) {
                setBannerImage3X(workType?.bannerImageUrl3x);
                setBannerImage3XPreview(workType?.bannerImageUrl3x);
            }
        }
    }, [isEditGearDialog, workType.label, workType.frenchName, workType?.iconUrl, workType?.bannerImageUrl, workType?.bannerImageUrl2x, workType?.bannerImageUrl3x])


    useEffect(() => {
        if (!isEditGearDialog) {
            setWorkTypeLabel("");
            setFrenchName("");
            setIcon(undefined);
            setIconPreview("");
            setBannerImage(undefined);
            setBannerImagePreview("");
            setBannerImage2X(undefined);
            setBannerImage2XPreview("");
            setBannerImage3X(undefined);
            setBannerImage3XPreview("");
        };
    }, [isEditGearDialog]);

    const handleWorkType = (event) => {
        setWorkTypeLabel(event.target.value);
    }

    const handleFrenchName = (event) => {
        setFrenchName(event.target.value);
    }

    const hideEditWorkTypeDialog = () => {
        dispatch(workTypesActions.setIsEditWorkTypeDialog(false));
    }

    const updateWorkType = () => {
        dispatch(workTypesActions.updateWorkType({
            id: workType.id,
            label: workTypeLabel,
            frenchName,
            order: workType.order,
            isActive: workType.isActive,
            baseRate: workType.baseRate,
            baseBillingRate: workType.baseBillingRate,
            icon: typeof (icon) === "object" ? icon : undefined,
            bannerImage: typeof (bannerImage) === "object" ? bannerImage : undefined,
            bannerImage2x: typeof (bannerImage2X) === "object" ? bannerImage2X : undefined,
            bannerImage3x: typeof (bannerImage3X) === "object" ? bannerImage3X : undefined
        }));
        hideEditWorkTypeDialog();
    }

    const onUploadIconChange = file => {
        const res = file ? isValidFile(file, 0.09765625, "KB") : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Icon_${file.name}`;
                const url = URL.createObjectURL(file);
                setIconPreview(url)
            }
            setIcon(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImageChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImagePreview(url)
            }
            setBannerImage(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImage2XChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImage2XPreview(url)
            }
            setBannerImage2X(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };
    const onUploadBannerImage3XChange = file => {
        const res = file ? isValidFile(file) : { isValid: true };
        if (!file || (file && res.isValid)) {
            let item = file;
            if (file) {
                item = new Blob([file], { type: file.type });
                item.name = `Banner_${file.name}`;
                const url = URL.createObjectURL(file);
                setBannerImage3XPreview(url)
            }
            setBannerImage3X(item)
        } else {
            dispatch(MetaActions.errorToast(res.message));
        }
    };

    return (
        <Dialog
            open={isEditGearDialog}
            title="Edit Work Type"
            onClose={hideEditWorkTypeDialog}
            onCancel={hideEditWorkTypeDialog}
            onConfirm={updateWorkType}
            disabled={!workTypeLabel?.trim?.() || !frenchName?.trim?.() || isLoading}
            isLoading={isLoading}

            alertType="form"
            confirmText="Update"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="label"
                    margin="normal"
                    label="Label"
                    value={workTypeLabel}
                    onChange={handleWorkType}
                    disabled={isLoading}
                    required
                    fullWidth
                />
                <TextField
                    id="label"
                    margin="normal"
                    label="French Label"
                    value={frenchName}
                    onChange={handleFrenchName}
                    disabled={isLoading}
                    required
                    fullWidth
                />
                <div>
                    <TransitionGroup>
                        {icon ?
                            <Collapse key={icon?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Icon</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={iconPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {icon?.name || iconPreview}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={iconPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadIconChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Icon</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Icon"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadIconChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage ?
                            <Collapse key={bannerImage?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImagePreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage?.name || bannerImagePreview}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImagePreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImageChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImageChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage2X ?
                            <Collapse key={bannerImage2X?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image 2x</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImage2XPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage2X?.name || bannerImage2XPreview}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImage2XPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImage2XChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image 2x</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image 2x"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImage2XChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                    <TransitionGroup>
                        {bannerImage3X ?
                            <Collapse key={bannerImage3X?.name}>
                                <div className={classes.selectedDocument}>
                                    <Typography>Selected Banner Image 3x</Typography>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={bannerImage3XPreview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {bannerImage3X?.name || bannerImage3XPreview}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="small" href={bannerImage3XPreview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1} paddingRight={1}>
                                            <IconButton disabled={isLoading} size="small" onClick={() => onUploadBannerImage3XChange(null)}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                            :
                            <Collapse key={"upload-document"}>
                                <div className={classes.uploadDocContainer}>
                                    <Typography>Select Banner Image 3x</Typography>
                                    <Dropzone
                                        disabled={isLoading}
                                        onlyButton
                                        label={"Select Banner Image 3x"}
                                        supportedFiles={"Supported files images"}
                                        accept={supportedAvatarFiles?.map?.(a => a.mime)?.join(",")}
                                        onFilesAdded={(files) => onUploadBannerImage3XChange(files[0])}
                                    />
                                </div>
                            </Collapse>
                        }
                    </TransitionGroup>
                </div>
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(EditWorkTypeDialog))
