import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
const isMobile = false;
export const reversalsTabTableActionsStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    table: {
        minWidth: "100%",
    },
}));

export const employerDetailStyles = withStyles(({ palette, shape, spacing, customPalette }) => ({
    container: {
        maxHeight: "65%",
        paddingTop: spacing(2),
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        height: "100%",
        padding: spacing(2),
    },
    tabbedTableContainer: {
        marginTop: spacing(3),
    },
    tableContainer: {
        width: "100%",
    },
    tabIndicator: {
        display: "none !important",
    },
    tabRoot: {
        marginRight: spacing(1),
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "1rem",
    },
    tabSelected: {
        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
        },
    },
}));

export const helperMessageStyles = withStyles(theme => ({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        margin: `0 ${theme.spacing(2)}`,
        alignItems: "center",
    },
    message: {
        marginLeft: theme.spacing(1),
    },
}));

export const stageStyles = withStyles({
    stage: {
        padding: "24px 0px",
    },
});

export const summaryFooterStyles = withStyles(theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 200,
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(0.5),
        alignItems: "flex-end",
    },
    button: {
        marginTop: "8px",
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        }
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export const summaryFooterStyles3 = withStyles(theme => ({
    footer: {
        position: "inherit",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 200,
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyItems: "flex-end",
    },
    button: {
        marginTop: "8px",
        justifyItems: "flex-end",
        // display: "flex",
    },
    cancelButton: {
        marginTop: "8px",
        justifyItems: "flex-start",
        // display: "flex",
    },
}));

export const employerActionMenuStyles = withStyles(({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    button: {
        marginBottom: spacing(0.5),
        marginTop: spacing(1),
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        }
    }
}));

export const employerPrefersTableStyles = withStyles(({ palette, customPalette }) => ({
    addButton: {
        color: palette.secondary.light,
    },
    remove: {
        color: customPalette.red[500],
    },
}));

export const employerFinanceCardStyles = withStyles(({ palette, spacing, shape }) => ({
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        height: "100%",
    },
    netPayContainer: {
        justifyContent: "flex-end",
    },
    totalLine: {
        height: 3,
        width: spacing(10),
        marginBottom: spacing(3),
    },
    historyIcon: {
        position: 'relative',
        top: '4px',
        color: '#0b938b',
        fontSize: '1.6rem',
        right: '5px',
        cursor: 'pointer'
    }
}));

export const employerJobActionMenuStyles = withStyles(({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export const employerPopoverStyles = makeStyles(({ spacing, palette, shape }) => ({
    card: {
        padding: spacing(1),
        marginLeft: spacing(1),
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[400]}`,
        borderRadius: shape.borderRadius,
        boxShadow: "none",
        minWidth: 250,
        height: "auto",
        zIndex: 9999,
    },
    row: {
        display: "flex",
        alignItems: "center",
        padding: spacing(1),
    },
    icon: {
        marginRight: spacing(2),
        color: palette.grey[500],
    },
    label: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
    footer: {
        position: "inherit",
        bottom: spacing(4),
        right: spacing(4),
        zIndex: 200,
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyItems: "flex-end",
    },
    button: {
        marginTop: "8px",
        justifyItems: "flex-end",
        // display: "flex",
    },
    cancelButton: {
        marginTop: "8px",
        justifyItems: "flex-start",
        // display: "flex",
    },
}));

export const employerActionMenuStyles2 = withStyles(({ palette, customPalette }) => ({
    addButton: {
        color: palette.secondary.light,
    },
    remove: {
        color: customPalette.red[500],
    },
}));

export const employerProfileCardStyles = withStyles(
    ({ palette, spacing, shape, customPalette, typography }) => ({
        container: {
            paddingRight: spacing(2),
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(3)} !important`,
            height: "100%",
        },
        deactivatedCard: {
            borderColor: customPalette.red[500],
        },
        deactivatedIcon: {
            ...typography.body1,
            color: customPalette.red[700],
            marginRight: -spacing(0.5),
        },
        deactivatedChip: {
            backgroundColor: customPalette.red[100],
            ...typography.body1,
            color: customPalette.red[900],
            padding: `${spacing(1)} ${spacing(0.5)}`,
            marginTop: spacing(1) - 2,
        },
        name: {
            marginTop: spacing(1),
        },
        approvalIcon: {
            width: 21,
            height: 24,
            marginLeft: spacing(2),
            marginTop: spacing(1),
        },
        map: {
            width: "100%",
            height: "160px",
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: `${shape.borderRadius / 2}px`,
            marginTop: spacing(2),
        },
    })
);

export const employerStatsCardStyles = withStyles(({ palette, spacing, shape }) => ({
    container: {
        paddingRight: spacing(2),
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        height: "100%",
    },
    star: {
        color: palette.text.primary,
    },
}));

export const profileTabStyles = withStyles(({ palette, spacing, shape }) => ({
    container: {
        height: "35%",
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        height: "100%",
    },
    tooltip: {
        backgroundColor: palette.common.black,
        padding: spacing(1),
        borderRadius: shape.spacing,
    },
    chart: {
        width: "100%",
        height: "100%",
    },
    formControlLabel: {
        width: "100%",
        marginRight: 0,
        paddingRight: spacing(1),
        "& span": {
            color: palette.text.primary,
            whiteSpace: "nowrap"
        },
        "& .MuiFormControlLabel-label": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }
    },
    textField: {
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
            display: "block",
            paddingRight: `${spacing(4)} !important`
        },
        "& input": {
            textOverflow: "ellipsis"
        },
        "& label": {
            overflow: "hidden",
            width: "100%"
        }
    }

}));

export const employerTrendCardStyles = withStyles(({ palette, spacing, shape }) => ({
    container: {
        height: ({ hoursTrendData } = {}) => hoursTrendData?.length ? "35%" : 'auto',
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        height: "100%",
    },
    tooltip: {
        backgroundColor: palette.common.black,
        padding: spacing(1),
        borderRadius: shape.spacing,
    },
    chart: {
        width: "100%",
        height: "100%",
    },
}));

export const tabStyles = ({ spacing, shape, palette, customPalette }) => ({
    tabWrapper: {
        display: "flex",
        width: "70%",
        float: "left",
    },
    createWorkerWrapper: {
        width: "30%",
        float: "right",
        textAlign: "right",
    },
    tabHeader: {},
    tabLabel: {
        lineHeight: 1.5,
        marginRight: spacing(2),
        "& + span": {
            fontWeight: 500,
        },
    },
    tabRoot: {
        height: spacing(12),
        marginRight: spacing(3),
        padding: `${spacing(2)} ${spacing(3)}`,
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        minWidth: spacing(24),
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
            padding: `calc(calc(${spacing(2)} - 1px) ${spacing(3)} - 1px)`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabSelected: {
        height: spacing(12),
        padding: `calc(${spacing(2)} ${spacing(3)} - 2px)`,

        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
            padding: `calc(${spacing(2)} ${spacing(3)} - 2px)`,
        },
        "& $tabHeader, & $tabLabel": {
            color: palette.secondary.main,
        },
    },
    tabTotalWrapper: {
        padding: `${spacing(2)} ${spacing(3)}`,
        borderLeft: `2px solid ${palette.action.disabled}`,
        maxWidth: spacing(26),
        "& span": {
            color: palette.text.primary,
        },
    },
    tabIndicator: {
        display: "none !important",
    },

    contentContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignitems: "flex-end",
        width: "99%",
        paddingTop: 32,
        paddingLeft: 0,
    },
    progress: {
        color: palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    tabFilterWrapper: {
        borderLeft: `0px solid ${palette.action.disabled}`,
        alignItems: "flex-end",
        float: "right",
        textAlign: "right",
    },
    profileImage: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    profileImageHide: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        visibility: "hidden",
    },
    checkboxLabel: { marginTop: -10 },
    checkbox: {
        paddingTop: 0,
    },
});

export const customRateStyles = makeStyles(
    ({ palette, shape, transitions, breakpoints, customPalette, spacing, typography }) => ({
        tableStyles: {
            /* Styles applied to the root element. */
            root: {
                ...typography.body2,
                display: "table-cell",
                verticalAlign: "inherit",
                // Workaround for a rendering bug with spanned columns in Chrome 62.0.
                // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
                borderBottom: `1px solid lightgrey`,
                textAlign: "left",
                padding: 16,
            },
            /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
            head: {
                color: palette.text.primary,
                lineHeight: typography.pxToRem(24),
                fontWeight: typography.fontWeightMedium,
            },
            /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
            body: {
                color: palette.text.primary,
            },
            /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
            footer: {
                color: palette.text.secondary,
                lineHeight: typography.pxToRem(21),
                fontSize: typography.pxToRem(12),
            },
            /* Styles applied to the root element if `size="small"`. */
            sizeSmall: {
                padding: "6px 12px 6px 8px",
                "&:last-child": {
                    paddingRight: 8,
                },
                "&$paddingCheckbox": {
                    width: 24, // prevent the checkbox column from growing
                    padding: "0 12px 0 16px",
                    "&:last-child": {
                        paddingLeft: 12,
                        paddingRight: 16,
                    },
                    "& > *": {
                        padding: 0,
                    },
                },
            },
            /* Styles applied to the root element if `padding="checkbox"`. */
            paddingCheckbox: {
                width: 48, // prevent the checkbox column from growing
                padding: "0 0 0 4px",
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 4,
                },
            },
            /* Styles applied to the root element if `padding="none"`. */
            paddingNone: {
                padding: 0,
                "&:last-child": {
                    padding: 0,
                },
            },
            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: "left",
            },
            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: "center",
            },
            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: "right",
                flexDirection: "row-reverse",
            },
            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: "justify",
            },
            /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
            stickyHeader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: palette.background.default,
            },
        },
        qbPreferences: {
            listItem: { padding: 0, paddingBottom: 0 },
        },
        deactivatedCard: {
            borderColor: customPalette.red[500],
        },
        deactivatedIcon: {
            ...typography.body1,
            color: customPalette.red[700],
            marginRight: -spacing(0.5),
        },
        deactivatedChip: {
            backgroundColor: customPalette.red[100],
            ...typography.body1,
            color: customPalette.red[900],
            padding: `${spacing(1)} ${spacing(0.5)}`,
            marginTop: spacing(1) - 2,
        },
        name: {
            marginTop: spacing(1),
        },
        approvalIcon: {
            width: 21,
            height: 24,
            marginLeft: spacing(2),
            marginTop: spacing(1),
        },
        approvalIconSm: {
            width: 21,
            height: 24,
            marginLeft: spacing(2),
            marginTop: spacing(-0.2),
            float: "right",
        },
        map: {
            width: "100%",
            height: "160px",
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: `${shape.borderRadius / 2}px`,
            marginTop: spacing(2),
        },
        icon: {
            fontSize: typography.pxToRem(20),
            marginRight: spacing(0),
            root: {
                fontSize: 62,
            },
        },
        qbCommitInvoiceButton: {
            margin: spacing(1),
            float: "inline-end",
        },
        invoiceStatusIcon: {
            fontSize: typography.pxToRem(16),
            marginRight: spacing(0),
            root: {
                fontSize: 24,
                marginRight: spacing(0),
            },
            check: {
                fontSize: 18,
                color: "green",
                marginRight: spacing(0),
                root: {
                    fontSize: 18,
                    width: 18,
                    height: 18,
                    color: "green",
                    marginRight: spacing(0),
                },
            },
        },

        qbPaymentItem: {
            margin: spacing(0),
            padding: spacing(0),
            color: "darkgrey",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        badgeText: {
            margin: spacing(0),
            padding: spacing(0),
            paddingBottom: spacing(0.5),
            color: "darkgrey",
            fontWeight: 600,
            fontSize: 16,
            float: "left",
        },
        badgeContainer: {
            paddingTop: spacing(0),
            width: "100%",
            float: "left",
            paddingRight: spacing(2),
        },
        qbPaymentAmount: {
            margin: spacing(0),
            padding: spacing(0),
            color: "green",
            fontWeight: 600,
            fontSize: 14,
            width: "100%",
        },
        qbPaymentTitle: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        pieChart: {
            root: {
                flexGrow: 1,
                width: "100%",
                color: "#FFF",
                //   height: 400,
                backgroundColor: palette.background.paper,
            },
        },
        tabNav: {
            colorSecondary: {
                color: "#FFF",
            },
            boxShadow: "none",
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                    // height: 400,
                    boxShadow: "none",
                },
                boxShadow: "none",
            },
        },
        root: {
            padding: `${spacing(0)} !important`,
            width: "100%",
            "& > *": {
                margin: spacing(0),
                padding: spacing(0),
                width: "100%",
                //   height: 400,
            },
        },
        vertTabRoot: {
            root: {
                flexGrow: 1,
                backgroundColor: palette.background.paper,
                display: "flex",
                height: 224,
            },
        },
        tabs: {
            borderRight: `1px solid ${palette.divider}`,
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                    // height: 400,
                },
            },
            textColorPrimary: { color: "#FFF" },
            textColorSecondary: { color: "#FFF" },
        },
        action: {
            margin: spacing(0),
            padding: spacing(0),
            top: "25%",
            fontSize: 42,
        },
        statsDialogTitle: { marginLeft: -20, marginTop: -20, paddingBottom: 8 },
        statsDialogTitleCopy: {
            fontWeight: 500,
            fontSize: 28,
            fontFamily: "Roboto",
            color: "rgb(110 122 138)",
        },
        copy: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
        },
        card: {
            backgroundColor: palette.common.white,
            border: `0.7px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            boxShadow: "none",
            //   width: "100%",
            padding: `${spacing(0)} !important`,
            paddingRight: `${spacing(0)} !important`,
            marginRight: `${spacing(0.5)} !important`,
            marginLeft: `${spacing(0.5)} !important`,
            marginBottom: `${spacing(1)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        invoiceListItem: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        dialogContent: {
            margin: spacing(0),
            padding: spacing(0),

            width: "100%",
            color: "darkgrey",
        },
        title: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        actionIcon: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            fontSize: 42,
            root: {
                fontSize: 42,
            },
        },
        placeholderContainer: {
            paddingRight: spacing(0),
            boxShadow: "none",
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
        },
        container: {
            paddingRight: spacing(2),
            boxShadow: "none",
            paddingBottom: spacing(0.7),
            // "&:last-child": { paddingRight: 0 },
            "&:nth-child(3n)": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: {
            boxShadow: "none",
            width: "100%",
            padding: `${spacing(1)} ${spacing(0)} !important`,
        },
        cardStyle: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",
            boxShadow: "none",
        },
        placeholderCard: {
            backgroundColor: palette.common.white,
            border: `0px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
        //   root: {
        //     boxShadow: "none",
        //     padding: `${spacing(0)} !important`,
        //     width: "100%",
        //   },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
            boxShadow: "none",
        },
        table: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },

        subHeader: {
            fontSize: 13,
            boxShadow: "none",
            fontWeight: 500,
        },
        customRateListItem: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "100%",
        },
        customRateListItemLeft: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            paddingRight: isMobile ? spacing(0) : spacing(0.4),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "50%",
        },
        customRateListItemRight: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            paddingLeft: isMobile ? spacing(0) : spacing(0.4),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "50%",
            justifySelf: "flex-end",
        },
        customRateIconContainer: {
            margin: spacing(0),
            padding: spacing(0),
            top: spacing(2),
            right: spacing(1),
            fontSize: 56,
        },
        customRateIcon: {
            fontSize: 32,
        },
        addCustomRateIcon: {
            fontSize: 24,
            float: "right",
            marginTop: 10,
            color: "green",
        },
        button: {
            marginTop: "8px",
            justifyItems: "flex-end",
            // display: "flex",
        },
        cancelButton: {
            marginTop: "8px",
            justifyItems: "flex-start",
            // display: "flex",
        },
        footer: {
            margin: 0,
            textAlign: "center",
            justifyContent: "center",
        },
    })
);

export const useGeneralTableStyles = makeStyles(
    ({ palette, shape, transitions, breakpoints, customPalette, spacing, typography }) => ({
        tableStyles: {
            "&.table tbody .data-row td": {
                padding: spacing(1)
            }
        }
    }));
export const accountSectionStyles = makeStyles(
    ({ palette, shape, transitions, breakpoints, customPalette, spacing, typography }) => ({
        tableStyles: {
            /* Styles applied to the root element. */
            root: {
                ...typography.body2,
                display: "table-cell",
                verticalAlign: "inherit",
                // Workaround for a rendering bug with spanned columns in Chrome 62.0.
                // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
                borderBottom: `1px solid lightgrey`,
                textAlign: "left",
                padding: 16,
            },
            /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
            head: {
                color: palette.text.primary,
                lineHeight: typography.pxToRem(24),
                fontWeight: typography.fontWeightMedium,
            },
            /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
            body: {
                color: palette.text.primary,
            },
            /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
            footer: {
                color: palette.text.secondary,
                lineHeight: typography.pxToRem(21),
                fontSize: typography.pxToRem(12),
            },
            /* Styles applied to the root element if `size="small"`. */
            sizeSmall: {
                padding: "6px 12px 6px 8px",
                "&:last-child": {
                    paddingRight: 8,
                },
                "&$paddingCheckbox": {
                    width: 24, // prevent the checkbox column from growing
                    padding: "0 12px 0 16px",
                    "&:last-child": {
                        paddingLeft: 12,
                        paddingRight: 16,
                    },
                    "& > *": {
                        padding: 0,
                    },
                },
            },
            /* Styles applied to the root element if `padding="checkbox"`. */
            paddingCheckbox: {
                width: 48, // prevent the checkbox column from growing
                padding: "0 0 0 4px",
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 4,
                },
            },
            /* Styles applied to the root element if `padding="none"`. */
            paddingNone: {
                padding: 0,
                "&:last-child": {
                    padding: 0,
                },
            },
            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: "left",
            },
            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: "center",
            },
            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: "right",
                flexDirection: "row-reverse",
            },
            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: "justify",
            },
            /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
            stickyHeader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: palette.background.default,
            },
        },
        qbPreferences: {
            listItem: { padding: 0, paddingBottom: 0 },
        },
        deactivatedCard: {
            borderColor: customPalette.red[500],
        },
        deactivatedIcon: {
            ...typography.body1,
            color: customPalette.red[700],
            marginRight: -spacing(0.5),
        },
        deactivatedChip: {
            backgroundColor: customPalette.red[100],
            ...typography.body1,
            color: customPalette.red[900],
            padding: `${spacing(1)} ${spacing(0.5)}`,
            marginTop: spacing(1) - 2,
        },
        name: {
            marginTop: spacing(1),
        },
        approvalIcon: {
            width: 21,
            height: 24,
            marginLeft: spacing(2),
            marginTop: spacing(1),
        },
        approvalIconSm: {
            width: 21,
            height: 24,
            marginLeft: spacing(2),
            marginTop: spacing(-0.2),
            float: "right",
        },
        map: {
            width: "100%",
            height: "160px",
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: `${shape.borderRadius / 2}px`,
            marginTop: spacing(2),
        },
        icon: {
            fontSize: typography.pxToRem(20),
            marginRight: spacing(0),
            root: {
                fontSize: 62,
            },
        },
        qbCommitInvoiceButton: {
            margin: spacing(1),
            float: "inline-end",
        },
        invoiceStatusIcon: {
            fontSize: typography.pxToRem(16),
            marginRight: spacing(0),
            root: {
                fontSize: 24,
                marginRight: spacing(0),
            },
            check: {
                fontSize: 18,
                color: "green",
                marginRight: spacing(0),
                root: {
                    fontSize: 18,
                    width: 18,
                    height: 18,
                    color: "green",
                    marginRight: spacing(0),
                },
            },
        },

        qbPaymentItem: {
            margin: spacing(0),
            padding: spacing(0),
            color: "darkgrey",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        badgeText: {
            margin: spacing(0),
            padding: spacing(0),
            paddingBottom: spacing(0.5),
            color: "darkgrey",
            fontWeight: 600,
            fontSize: 16,
            float: "left",
        },
        badgeContainer: {
            paddingTop: spacing(0),
            width: "100%",
            float: "left",
            paddingRight: spacing(2),
        },
        qbPaymentAmount: {
            margin: spacing(0),
            padding: spacing(0),
            color: "green",
            fontWeight: 600,
            fontSize: 14,
            width: "100%",
        },
        qbPaymentTitle: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        pieChart: {
            root: {
                flexGrow: 1,
                width: "100%",
                color: "#FFF",
                backgroundColor: palette.background.paper,
            },
        },
        tabNav: {
            colorSecondary: {
                color: "#FFF",
            },
            boxShadow: "none",
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                    boxShadow: "none",
                },
                boxShadow: "none",
            },
        },
        root: {
            padding: `${spacing(0)} !important`,
            width: "100%",
            "& > *": {
                margin: spacing(0),
                padding: spacing(0),
                width: "100%",
            },
        },
        vertTabRoot: {
            root: {
                flexGrow: 1,
                backgroundColor: palette.background.paper,
                display: "flex",
                height: 224,
            },
        },
        tabs: {
            borderRight: `1px solid ${palette.divider}`,
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                },
            },
            textColorPrimary: { color: "#FFF" },
            textColorSecondary: { color: "#FFF" },
        },
        action: {
            margin: spacing(0),
            padding: spacing(0),
            top: "25%",
            fontSize: 42,
        },
        statsDialogTitle: { marginLeft: -20, marginTop: -20, paddingBottom: 8 },
        statsDialogTitleCopy: {
            fontWeight: 500,
            fontSize: 28,
            fontFamily: "Roboto",
            color: "rgb(110 122 138)",
        },
        copy: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
        },
        card: {
            backgroundColor: palette.common.white,
            border: `0.7px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            boxShadow: "none",

            padding: `${spacing(0)} !important`,
            paddingRight: `${spacing(0)} !important`,
            marginRight: `${spacing(0.5)} !important`,
            marginLeft: `${spacing(0.5)} !important`,
            marginBottom: `${spacing(1)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
            },
        },
        invoiceListItem: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        dialogContent: {
            margin: spacing(0),
            padding: spacing(0),

            width: "100%",
            color: "darkgrey",
        },
        title: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        actionIcon: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            fontSize: 42,
            root: {
                fontSize: 42,
            },
        },
        placeholderContainer: {
            paddingRight: spacing(0),
            boxShadow: "none",
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
        },
        container: {
            paddingRight: spacing(2),
            boxShadow: "none",
            paddingBottom: spacing(0.7),
            "&:nth-child(3n)": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: {
            boxShadow: "none",
            width: "100%",
            padding: `${spacing(1)} ${spacing(0)} !important`,
        },
        cardStyle: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",
            boxShadow: "none",
        },
        placeholderCard: {
            backgroundColor: palette.common.white,
            border: `0px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
            boxShadow: "none",
        },
        table: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },

        subHeader: {
            fontSize: 13,
            boxShadow: "none",
            fontWeight: 500,
        },
        customRateListItem: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "100%",
        },
        customRateListItemLeft: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            paddingRight: isMobile ? spacing(0) : spacing(0.4),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "50%",
        },
        customRateListItemRight: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            paddingLeft: isMobile ? spacing(0) : spacing(0.4),
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 13,
            width: "50%",
            justifySelf: "flex-end",
        },
        customRateIconContainer: {
            margin: spacing(0),
            padding: spacing(0),
            top: spacing(2),
            right: spacing(1),
            fontSize: 56,
        },
        customRateIcon: {
            fontSize: 32,
        },
        addCustomRateIcon: {
            fontSize: 24,
            float: "right",
            marginTop: 10,
            color: "green",
        },
        button: {
            marginTop: "8px",
            justifyItems: "flex-end",
            // display: "flex",
        },
        cancelButton: {
            marginTop: "8px",
            justifyItems: "flex-start",
            // display: "flex",
        },
        footer: {
            margin: 0,
            textAlign: "center",
            justifyContent: "center",
        },
        accountSettingFooter: {
            paddingRight: 0,
            justifyContent: 'right'
        }
    })
);

// export const summaryFooterStyles =
