import gql from "graphql-tag";

export default gql`
mutation CreateBreakRule($data: CreateBreakRuleInput!) {
    createBreakRule(data: $data) {
        id
        provinceId
        minShiftLength
        maxShiftLength
        minBreakMinutes
        breakType
        isActive
    }
}
`
