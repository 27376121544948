import React, { useEffect, useState } from "react";
import Dialog from "components/Dialog/Dialog";
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import { useDispatch, useSelector } from "react-redux";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { fetchJobRecurringJob } from "store/modules/jobDetails/actions";
import { convertUTCToTimezoneWithOffset, formattedStartEndTimeWithOffset } from "utils/time";
import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(({ spacing }) => ({
    editRecurringDialog: {
        "& .MuiPaper-root": {
            padding: spacing(3),
        }
    }
}))

function JobCancelDialog({ isFixedTerm, cancelJob, jobId, cancelMessage }) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const isOpenCancelJobDialog = useSelector(JobDetailSelectors.getCancelJobDialogState);
    const data = useSelector(JobDetailSelectors.getRecurringJobList);
    const [selectedList, setselectedList] = useState([]);
    const [selectedId, setselectedId] = useState({});
    const [allSelectedId, setAllSelectedId] = useState(0);
    const isLoading = useSelector(JobDetailSelectors.getIsDeleteJobLoading);

    useEffect(() => {
        dispatch(fetchJobRecurringJob(jobId));
    }, []);

    useEffect(() => {
        const keys = Object.keys(selectedId);
        const filteredObj = keys.filter((key) => {
            return selectedId[key];
        });
        const filteredArray = filteredObj.map(str => {
            return Number(str);
        });
        setselectedList(filteredArray);
    }, [selectedId]);

    useEffect(() => {
        if (data.length === selectedList.length) {
            if (selectedList.length === 0) {
                setAllSelectedId(0);
            }
            else {
                setAllSelectedId(1);
            }
        }
        else if (data.length >= selectedList.length) {
            if (selectedList.length === 0) {
                setAllSelectedId(0);
            }
            else {
                setAllSelectedId(2);
            }
        }
        else {
            setAllSelectedId(0);
        }
    }, [selectedList]);

    const onCheckAll = () => {
        let newSelected = {};
        if (allSelectedId === 0) {
            data.forEach(x => {
                newSelected[x.id] = true;
            });
        }
        setselectedId(newSelected);
        setAllSelectedId(allSelectedId === 0 ? 1 : 0);
    }

    const onSelectJob = (id) => {
        const newSelected = Object.assign({}, selectedId);
        newSelected[id] = !selectedId[id];
        setselectedId(newSelected);
    }

    const hideCancelDialog = () => {
        setselectedId({});
        setselectedList([]);
        dispatch(JobDetailActions.setIsCancelJobDialogState(false));
    };

    const cancelRecurringJob = () => {
        cancelJob(selectedList, jobId);
    };

    const columns = [
        {
            Header: () => {
                return (
                    <span>
                        <Checkbox
                            id="allCheck"
                            disabled={Boolean(isLoading)}
                            checked={allSelectedId === 1}
                            indeterminate={allSelectedId === 2}
                            onChange={() => onCheckAll()}
                        />
                    </span>
                )
            },
            id: "checkbox",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <span>
                        <Checkbox
                            disabled={Boolean(isLoading)}
                            checked={selectedId[original.id] === true}
                            onChange={() => onSelectJob(original.id)}
                        />
                    </span>
                )
            },
            className: "-cursor-pointer",
            styles: {
                textAlign: 'left',
                width: '80px',
                minWidth: '80px'
            },
        },
        {
            Header: "Job Id",
            id: "id",
            accessor: "id",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <span>
                        {original.id}
                    </span>
                )
            },
            className: "-cursor-pointer",
            styles: {
                textAlign: 'left',
                width: '50px',
                minWidth: '50px'
            },
        },
        {
            Header: "Job Date",
            id: "startUtc",
            accessor: "startUtc",
            disableFilters: true,
            disableSortBy: true,
            styles: {
                textAlign: 'left',
                width: '120px',
                minWidth: '120px'
            },
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
                    </div>
                );
            },
            className: "-cursor-pointer",
        },
        {
            Header: "Shift",
            id: "Shift",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return formattedStartEndTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
            },
            accessor: ({ startUtc, endUtc }) => {
                const startTime = moment(startUtc).format("h:mm a");
                const endTime = moment(endUtc).format("h:mm a");
                return `${startTime} - ${endTime}`;
            },
            className: "-cursor-pointer",
            styles: {
                width: 'auto',
                minWidth: '160px'
            },
        },
    ]

    return (
        <Dialog
            open={isOpenCancelJobDialog}
            title="Are you sure to cancel job?"
            description={cancelMessage}
            confirmText="Yes"
            onClose={hideCancelDialog}
            onConfirm={cancelRecurringJob}
            onCancel={hideCancelDialog}
            alertType="warning"
            modalType="editEmployer"
            className={classes.editRecurringDialog}
            isLoading={Boolean(isLoading)}
            disabled={Boolean(isLoading)}
        >
            {!isFixedTerm && data.length > 0 &&
                <ReactTableSimple
                    columns={columns}
                    data={data}
                    style={{
                        marginTop: "20px",
                        padding: 0
                    }}
                />
            }
        </Dialog>
    )
}

export default JobCancelDialog
