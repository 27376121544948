import React from "react";
import withStyles from '@mui/styles/withStyles';
import { tabStyles } from "./helpers/tabStyles";
import WorkerTab from "./Components/WorkerTab";

const styles = {
    ...tabStyles,
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
};

class WorkerOverview extends React.Component {

    render() {

        return (
            <React.Fragment>
                <WorkerTab />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WorkerOverview);
