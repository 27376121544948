import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import { setActiveEmployerFilter } from "./actions";
// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.reports.loading[loader] || false;
};

export const getIsDCReportLoading = getLoadingStateFactory("dcReport");
export const getIsDCReportDownloadLoading = getLoadingStateFactory("dcReportDownload");
export const getIsUpdateWorkOrderNumberLoading= getLoadingStateFactory("updateWorkOrderNumber");
/*
Worker payroll report
*/
export const getWorkerPayrollReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerPayrollReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.workerPayrollReport),
    };
};

export const getWorkerPayrollReportData = state => state.reports.workerPayrollReportData;
export const getWorkerPayrollReportFilter = state => state.reports.workerPayrollReportFilter;

/*
Worker Wise payroll report
*/
export const getWorkerWisePayrollReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerWisePayrollReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.workerWisePayrollReport),
    };
};

export const getWorkerWisePayrollReportData = state => state.reports.workerWisePayrollReportData;
export const getWorkerWisePayrollReportFilter = state => state.reports.workerWisePayrollReportFilter;

export const getWorkerJobReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerJobReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.workerJobReport),
    };
};

export const getWorkerJobReportData = state => state.reports.workerJobReportData;
export const getWorkerJobReportFilter = state => state.reports.workerJobReportFilter;

/*
Weekly billing report
*/
export const getWeeklyBillingReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.WeeklyBillingReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.WeeklyBillingReport),
  };
};

export const getWeeklyBillingReportData = state => state.reports.weeklyBillingReportData;
export const getWeeklyBillingReportFilter = state => state.reports.weeklyBillingReportFilter;

/*
Active Employer report
*/
export const getActiveEmployerReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.activeEmployerReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.activeEmployerReport),
    };
};

export const getActiveEmployerReportData = state => state.reports.activeEmployerReportData;
export const getActiveEmployerReportFilter = state => state.reports.activeEmployerReportFilter;

/*
WSBC report
*/
export const getWSBCReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.WSBCReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.WSBCReport),
    };
};

export const getWSBCReportData = state => state.reports.WSBCReportData;
export const getWSBCReportFilter = state => state.reports.WSBCReportFilter;

/*
Worker job count report
*/
export const getWorkerJobCountReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerJobCountReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.workerJobCountReport),
    };
};

export const getWorkerJobCountReportData = state => state.reports.workerJobCountReportData;
export const getWorkerJobCountReportFilter = state => state.reports.workerJobCountReportFilter;

/*
DC Bank Transaction report
*/
export const getDCBankTransactionReportData = state => state.reports.DCBankTransactionReportData;
export const getDCBankTransactionReportFilter = state => state.reports.DCBankTransactionReportFilter;

/*
WorkOrder report
*/
export const getWorkOrderReportPagingData = state => {
    return {
      paging: state.reports[pagination.getPageInfoAttr(paginationSettings.WorkOrderReport)],
      sort: state.reports.pageSort,
      filter: state.reports.pageFilter,
      params: { },
      pagingVars: pagination.pagingVarsFactory(paginationSettings.WorkOrderReport),
    };
  };

  export const getWorkOrderReportData = state => state.reports.WorkOrderReportData;
  export const getWorkOrderReportFilter = state => state.reports.WorkOrderReportFilter;
  export const getIsEditableWorkOrderNumber = state => state.reports.isEditWorkWorderNumber;
  export const getWorkOrderNumberByPositionId = (state,positionId) => state.reports.updatedWorkOrderNumbersByPositionId.find(a=>a.positionId === positionId);
  export const getUpdatedWorkOrderNumberData = (state) => state.reports.updatedWorkOrderNumbersByPositionId;
  export const getCanUpdateWorkOrderNumber = (state) => !state.reports?.updatedWorkOrderNumbersByPositionId?.length;

  /*
Holiday payroll report
*/
export const getHolidayPayrollReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.holidayPayrollReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: {},
        pagingVars: pagination.pagingVarsFactory(paginationSettings.holidayPayrollReport),
    };
};

export const getHolidayPayrollReportData = state => state.reports.holidayPayrollReportData;
export const getHolidayPayrollReportFilter = state => state.reports.holidayPayrollReportFilter;
