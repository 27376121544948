import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { sharedReactTableProps, weekDaysList } from "../../../../utils/constant";
import { formatMoney } from "utils/stringUtils";
import ReactTableCustom from "components/ReactTable";
import { useStyles } from "views/Reports/styles";
import classNames from "classnames";
import moment from "moment";
import DisableCell from "./DisableCell";
import WorkOrderNumberColumn from "./WorkOrderNumberColumn";
import DateColumnFilter from "components/ReactTable/filterFunctions/DateColumnFilter";
import { Chip } from "@mui/material";

function WorkOrderTable() {
    const dispatch = useDispatch();
    const employerData = useSelector(ReportsSelectors.getWorkOrderReportData);
    const PaginationData = useSelector(ReportsSelectors.getWorkOrderReportPagingData);
    const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
        dispatch(ReportsActions.changeWorkOrderPageSize(pageSize));
        dispatch(ReportsActions.changeWorkOrderFilter(filters))
        dispatch(ReportsActions.fetchWorkOrderTableData(pageIndex));
    }, []);
    const classes = useStyles();

    const columns = [
        {
            Header: "Worker ID",
            disableSortBy: true,
            disableFilters: true,
            accessor: "WorkerID",
            Cell: ({ row: { original } }) => <DisableCell>{original?.WorkerID || "-"}</DisableCell>,
        },
        {
            Header: "Talent ID",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "TalentID",
            Cell: ({ row: { original } }) => <DisableCell>{original.TalentID || "-"}</DisableCell>
        },
        {
            Header: "Work Order Number",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "WorkOrderNumber",
            Cell: WorkOrderNumberColumn
        },
        {
            Header: "External Entity ID",
            disableSortBy: true,
            disableFilters: true,
            accessor: "ExternalEntityId",
            Cell: ({ row: { original } }) => <DisableCell>{original.ExternalEntityId || "-"}</DisableCell>
        },
        {
            Header: "First Name",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "FirstName",
            Cell: ({ row: { original } }) => <DisableCell>{original?.FirstName || "-"}</DisableCell>
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "LastName",
            Cell: ({ row: { original } }) => <DisableCell>{original?.LastName || "-"}</DisableCell>
        },
        {
            Header: "Date of Birth",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row: { original } }) => <DisableCell>{moment(original.BirthDate).format("MM/DD/YYYY")}</DisableCell>,
            accessor: "BirthDate",
        },
        {
            Header: "SIN (Tax ID)",
            disableSortBy: true,
            disableFilters: true,
            accessor: "SIN",
            Cell: ({ row: { original } }) => <DisableCell>{original?.SIN || "-"}</DisableCell>,
        },
        {
            Header: "Worker Email",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "workerEmail",
            Cell: ({ row: { original } }) => <DisableCell>{original?.workerEmail || "-"}</DisableCell>,
        },
        {
            Header: "Work Type",
            disableSortBy: true,
            disableFilters: true,
            accessor: "workType",
            Cell: ({ row: { original } }) => <DisableCell>{original?.workType || "-"}</DisableCell>,
        },
        {
            Header: "Company Name",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "CompanyName",
            Cell: ({ row: { original } }) => <DisableCell>{original?.CompanyName || "-"}</DisableCell>,
        },
        {
            Header: "Job Street",
            disableSortBy: true,
            disableFilters: true,
            accessor: "jobLocStreet",
            Cell: ({ row: { original } }) => <DisableCell>{original?.jobLocStreet || "-"}</DisableCell>,
        },
        {
            Header: "Job City",
            disableSortBy: true,
            disableFilters: true,
            accessor: "jobLocCity",
            Cell: ({ row: { original } }) => <DisableCell>{original?.jobLocCity || "-"}</DisableCell>,
        },
        {
            Header: "Job Postal Code",
            disableSortBy: true,
            disableFilters: true,
            accessor: "jobLocPostalCode",
            Cell: ({ row: { original } }) => <DisableCell>{original?.jobLocPostalCode || "-"}</DisableCell>,
        },
        {
            Header: "Province of Work",
            disableSortBy: true,
            // disableFilters: true,
            accessor: "JobRegion",
            Cell: ({ row: { original } }) => <DisableCell>{original?.JobRegion || "-"}</DisableCell>,
        },
        // {
        //     Header: "WSIB",
        //     disableSortBy: true,
        //     disableFilters: true,
        //     accessor: "wsib",
        // },
        {
            Header: "Hiring Manager",
            disableSortBy: true,
            disableFilters: true,
            accessor: "employerFullName",
            Cell: ({ row: { original } }) => <DisableCell>{(original?.employerFirstName + " " + original?.employerLastName) || "-"}</DisableCell>,
        },
        {
            Header: "Company ID",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CFCompanyId",
            Cell: ({ row: { original } }) => <DisableCell>{original?.CFCompanyId || "-"}</DisableCell>,
        },
        {
            Header: "Company JDE Number",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyJDENumber",
            Cell: ({ row: { original } }) => <DisableCell>{original?.CompanyJDENumber || "-"}</DisableCell>,
        },
        {
            Header: "Pay Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "PaymentRate",
            Cell: ({ row: { original } }) => <DisableCell>{original.PaymentRate === '-' ? '-' : formatMoney(original.PaymentRate)}</DisableCell>,
        },
        {
            Header: "Bill Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "BillingRate",
            Cell: ({ row: { original } }) => <DisableCell>{original.BillingRate === '-' ? '-' : formatMoney(original.BillingRate)}</DisableCell>,
        },
        {
            Header: "Billing Information",
            disableSortBy: true,
            disableFilters: true,
            accessor: "billingFullName",
            Cell: ({ row: { original } }) => <DisableCell>{original?.billingFirstName ? original?.billingFirstName + " " + original?.billingLastName : "-"}</DisableCell>,
        },
        {
            Header: "Billing Job Role",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobRole",
            Cell: ({ row: { original } }) => <DisableCell>{original?.JobRole || "-"}</DisableCell>,
        },
        {
            Header: "Billing Email Address",
            disableSortBy: true,
            disableFilters: true,
            accessor: "BillingEmail",
            Cell: ({ row: { original } }) => <DisableCell>{original?.BillingEmail || "-"}</DisableCell>,
        },
        {
            Header: "Additonal Billing emails",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CFAdditionalBillingEmail",
            Cell: ({ row: { original } }) => <DisableCell>{original?.CFAdditionalBillingEmail || "-"}</DisableCell>,
        },
        {
            Header: "Invoicing JDE Number",
            disableSortBy: true,
            disableFilters: true,
            accessor: "InvoicingJDENumber",
            Cell: ({ row: { original } }) => <DisableCell>{original?.InvoicingJDENumber || "-"}</DisableCell>,
        },
        {
            Header: "Job Start Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobStartDate",
            Cell: ({ row: { original } }) => <DisableCell>{moment.utc(original.JobStartDate).format("MM/DD/YYYY")}</DisableCell>,
        },
        {
            Header: "Job End Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobEndDate",
            Cell: ({ row: { original } }) => <DisableCell>{moment.utc(original.JobEndDate).format("MM/DD/YYYY")}</DisableCell>,
        },
        {
            Header: "Job Payment Date",
            disableSortBy: true,
            // disableFilters: true,
            Filter: args => <DateColumnFilter {...args} inputFormat={"MM/DD/YYYY"} />,
            accessor: "PaymentDate",
            Cell: ({ row: { original } }) => <DisableCell>{original.PaymentDate ? moment.utc(original.PaymentDate).format("MM/DD/YYYY") : "-"}</DisableCell>,
        },
        {
            Header: <div className="text-right">Hours Worked</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "HoursWorked",
            Cell: ({ row: { original } }) => <DisableCell>{original.HoursWorked === '-' ? '-' : Number(original.HoursWorked).toFixed(2)}</DisableCell>
        },
        {
            Header: <div className="text-right">Gross Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "GrossPay",
            Cell: ({ row: { original } }) => <DisableCell>{formatMoney(original.GrossPay)}</DisableCell>
        },
        {
            Header: "Pay Type",
            disableSortBy: true,
            // disableFilters: true,
            // style: { textAlign: "center" },
            // headerStyle: { textAlign: "center" },
            accessor: "PayType",
            Cell: ({ row: { original } }) => <DisableCell>{original.PayType}</DisableCell>
        },
        {
            Header: "Billable",
            disableSortBy: true,
            disableFilters: true,
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            accessor: "Billable",
            Cell: ({ row: { original } }) => <DisableCell>{original.Billable}</DisableCell>
        },
        {
            Header: "Job Type",
            disableSortBy: true,
            accessor: "JobType",
            Cell: ({ row: { original } }) => <DisableCell>{original.JobType || '-'}</DisableCell>
        },
        {
            Header: "Workers Needed",
            disableSortBy: true,
            disableFilters: true,
            accessor: "WorkersNeeded",
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center" },
            Cell: ({ row: { original } }) => <DisableCell>{original.WorkersNeeded || '-'}</DisableCell>
        },
        {
            Header: "RD Job ID",
            disableSortBy: true,
            accessor: "RDJobId",
            Cell: ({ row: { original } }) => <DisableCell>{original.RDJobId || '-'}</DisableCell>
        },
        {
            Header: "Requested By",
            disableSortBy: true,
            accessor: "RequestedBy",
            Cell: ({ row: { original } }) => <DisableCell>{original.RequestedBy || '-'}</DisableCell>
        },
        {
            Header: "Gig/Shift Type",
            disableSortBy: true,
            accessor: "GigType",
            Cell: ({ row: { original } }) => <DisableCell><span style={{ textTransform: 'capitalize' }}>{original.GigType || '-'}</span></DisableCell>
        },
        {
            Header: "Recurring End Date",
            disableSortBy: true,
            accessor: "RecurringEndDate",
            Filter: args => <DateColumnFilter {...args} inputFormat={"MM/DD/YYYY"} />,
            Cell: ({ row: { original } }) => <DisableCell>{original.RecurringEndDate ? moment.utc(original.RecurringEndDate).format("MM/DD/YYYY") : "-"}</DisableCell>,
        },
        {
            Header: "Recurring Type",
            disableSortBy: true,
            accessor: "RecurringType",
            Cell: ({ row: { original } }) => <DisableCell><span style={{ textTransform: 'capitalize' }}>{original.RecurringType || '-'}</span></DisableCell>
        },
        {
            Header: "Repeat Every",
            disableSortBy: true,
            disableFilters: true,
            accessor: "RepeatEvery",
            Cell: ({ row: { original } }) => <DisableCell><span style={{ textTransform: 'capitalize' }}>{original.RepeatEvery ? original.RepeatEvery + (original.RecurringType === "daily" ? ' day' : original.RecurringType === "monthly" ? ' month' : ' week') : '-'}</span></DisableCell>
        },
        {
            Header: "Day(s) Of The Week",
            disableSortBy: true,
            disableFilters: true,
            accessor: "DayOfWeek",
            Cell: ({ row: { original } }) => {
                const days = original?.DayOfWeek?.split?.(',');
                if (original.RecurringType === "monthly") {
                    return (
                        <div className={classes.weekDayList}>
                            {days?.sort((a, b) => +a - +b)?.map((day) => {
                                if (day)
                                    return <Chip size="small" key={day} label={day} />
                                return '-';
                            }) || '-'}
                        </div>
                    )
                }
                return (

                    <DisableCell>
                        <div className={classes.weekDayList}>
                            {days?.map((day) => {
                                const weekDay = weekDaysList.find(w => w.value === +day);
                                if (day)
                                    return <Chip size="small" key={weekDay?.value} label={weekDay?.label} />
                                return '-';
                            }) || '-'}
                        </div>
                    </DisableCell>
                )
            }
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={employerData}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                classes={classNames(classes.table)}
                defaultPageSize={PaginationData.paging.pageSize}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WorkOrderTable;
