import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Datetime from "react-datetime";
import { GetApp, Search } from '@mui/icons-material';
import { FormControl, InputLabel } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginTop: 10,
        marginLeft: 5
    }
}))
function HolidayPayrollFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const aTag = React.useRef();
  const [csvURL, setCsvURL] = React.useState('');
  const { startDate, endDate } = useSelector(ReportsSelectors.getHolidayPayrollReportFilter);
  const  updateFormData = (field, value) => {
    const date = moment(value).tz('UTC').set({ hour:0, minute:0, second:0, millisecond:0 })
    dispatch(ReportsActions.setHolidayPayrollFilter(field, date))
  };
  const onSearch = () => {
      dispatch(ReportsActions.fetchHolidayPayrollTableData(0));
  }
  const onDownload = () => {
      dispatch(ReportsActions.downloadHolidayPayrollTableData()).then(data => {
          if(data && data.url) {
              setCsvURL(data.url);
              aTag.current.click();
          }
      });
  }
  return (
      <React.Fragment>
          <Grid container>
              <Grid item xs={12} md={12}>
                  <FormControl margin="normal" style={{ zIndex: 110 }}>
                      <InputLabel htmlFor="startdate">Start Date</InputLabel>
                      <Datetime
                          timeFormat={false}
                          id="startdate"
                          input
                          disabled
                          dateFormat="MMMM Do YYYY"
                          closeOnSelect
                          onChange={(value) => updateFormData('startDate', value)}
                          value={startDate}
                      />
                  </FormControl>
                  &nbsp;&nbsp;
                  <FormControl margin="normal" style={{ zIndex: 110 }}>
                      <InputLabel htmlFor="date">End Date</InputLabel>
                      <Datetime
                          timeFormat={false}
                          id="date"
                          input
                          dateFormat="MMMM Do YYYY"
                          closeOnSelect
                          onChange={(value) => updateFormData('endDate', value)}
                          value={endDate}
                      />
                  </FormControl>
                  &nbsp;&nbsp;
                  <FormControl margin="normal" style={{ marginTop: 30 }}>
                      <Button
                          icon={<Search className={classes.buttonIcon} />}
                          alignIcon="left"
                          label="Search"
                          onClick={onSearch}
                      />
                  </FormControl>
                  <FormControl margin="normal" style={{ marginTop: 30 }}>
                      <Button
                          icon={<GetApp className={classes.buttonIcon} />}
                          label="Download CSV"
                          alignIcon="left"
                          onClick={onDownload}
                      />
                  </FormControl>
              </Grid>
          </Grid>
          <a ref={aTag} href={csvURL} target="_blank" rel="noopener noreferrer" />
      </React.Fragment>
  );
}

export default HolidayPayrollFilter;
