import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@mui/styles";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const useStyle = makeStyles(theme => ({
    container: {
        minHeight: 200,
        '& .DraftEditor-editorContainer': {
            padding: theme.spacing(1)
        },
        '& .wrapperClassName': {
            minHeight: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '& .editorClassName': {
                flex: 1,
                '& .public-DraftStyleDefault-block': {
                    margin: '0 0 !important'
                }
            },
            background: ({ disabled }) => disabled ? '#F5F5F5' : 'inherit'
        },
        '& .toolbarClassName': {
            background: '#F2F4F7',
            borderRadius: 8,
            '& .rdw-inline-wrapper': {
                borderLeft: '1px solid #EAECF0',
                borderRight: '1px solid #EAECF0',
            },
            '& .rdw-list-wrapper': {
                borderLeft: '1px solid #EAECF0',
                borderRight: '1px solid #EAECF0',
            },
            '& .rdw-option-wrapper': {
                margin: `0px 2px`,
                padding: 3,
                border: 'none',
                background: '#F2F4F7'
            },
            '& .rdw-dropdown-selectedtext': {
                background: '#F2F4F7'
            }
        },
        '& .display-hidden': {
            display: 'none'
        }
    }
}));

const WYSIWYGEditor = ({ value, name, onChange, disabled }) => {
    const classes = useStyle({ disabled });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const content = htmlToDraft(value)?.contentBlocks;
        if(content){
            const data = EditorState.createWithContent(ContentState.createFromBlockArray(content));
            setEditorState(data);
        }
    }, [value, disabled]);

    const onEditorStateChange = e => {
        if (!disabled) {
            setEditorState(e);
            const request = draftToHtml(convertToRaw(e.getCurrentContent()));
            onChange(request, name);
        }
    };


    return (
        <div className={classes.container}>
            <Editor
                readOnly={disabled}
                wrapperStyle={{
                    background: disabled ? '#F5F5F5' : "#FFF",
                    ...(disabled ? { cursor: 'default' } : {}),
                    borderRadius: 8,
                    border: "1px solid #d5dde5",
                }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['fontSize', 'inline', 'textAlign', 'list', 'image', 'link'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    list: {
                        options: ['ordered', 'unordered']
                    },
                    link: {
                        options: ['link']
                    },
                }}
            />
        </div>
    );
};

export default WYSIWYGEditor;
