import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Clear, Close, FilterAltOutlined, KeyboardArrowDown, Search } from '@mui/icons-material';
import { Box, Button, Chip, Drawer, FormControl, IconButton, Tabs, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { debounce } from 'lodash';
import moment from 'moment';
import CityDropDown from 'components/Autocomplete/CityDropDown';
import CustomizedEmployerAutocomplete from 'components/Autocomplete/CustomizedEmployerAutocomplete';
import SimpleSelect from 'components/Autocomplete/SimpleSelect';
import history from 'utils/history';
import { selectors as PostingSelectors, actions as PostingActions } from 'store/modules/posting';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    outlinedButton: {
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        },
        borderRadius: 50,
        boxShadow: 'none'
    },
    applyFilterButton: {
        borderRadius: 50,
        boxShadow: 'none',
        width: 'fit-content'
    },
    drawer: {
        padding: theme.spacing(3),
        minWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    drawerTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    title: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '24px',
        color: '#3F454D'
    },
    closeButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#E9F1FB'
    },
    label: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#000000',
    },
    datePicker: {
        width: '100%',
        background: 'white',
        color: '#a4a7ab',
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: 4,
        height: '100%',
        textTransform: 'capitalize',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': {
            background: 'white',
            border: "1px solid black",
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& fieldset': {
            border: 'none !important'
        }
    },
    tabsContainer: {
        '& .MuiTabs-root': {
            display: 'flex',
            alignItems: 'center',
            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                'display': 'none'
            },
            '& .MuiTabs-flexContainer': {
                display: 'flex',
                gap: theme.spacing(1)
            }
        }
    },
}));

export default function FilterHeader() {
    const activeTab = useSelector(Selectors.getRequirementActiveTab);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const params = useParams();
    const appliedFilters = useSelector(state => Selectors.getRequirementFilters(state));
    const jobTypeList = useSelector(PostingSelectors.getJobTypeList);
    const [filters, setFilters] = useState({ expiryDate: null, employer: null, status: null, location: null, workType: null });
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const urlParams = new URLSearchParams(history.location.search);
    const type = parseInt(urlParams.get("type"), 10);

    const flatFilters = appliedFilters?.filter(a => a?.id !== "searchTerm")?.flatMap(a => {
        if (a?.id === "expiryDate") {
            return {
                ...a,
                label: "Valid Till: " + moment(a?.value).format("YYYY-MM-DD")
            }
        }
        return a?.value
    });

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    const debouncedFunction = useCallback(debounce((e) => {
        const findInd = appliedFilters?.length && appliedFilters.findIndex(a => a.id === 'searchTerm');
        if (e.target.value)
            dispatch(Actions.setRequirementFilters(findInd >= 0 ? Object.assign([...appliedFilters], { [findInd]: { id: 'searchTerm', value: e.target.value } }) : [...appliedFilters, { id: 'searchTerm', value: e.target.value }]));
        else
            dispatch(Actions.setRequirementFilters(appliedFilters?.filter(a => a.id !== 'searchTerm')));
        dispatch(Actions.fetchPerRequirementsData({ workerId: window.atob(params?.id), filterBy: type === 0 ? 'requirement' : 'employer' }));
    }, 1200), [appliedFilters, params?.id]);

    useEffect(() => {
        dispatch(PostingActions.fetchWorkTypes());
        return () => {
            setValue('');
            setFilters({ expiryDate: null, employer: null, status: null, location: null, workType: null });
            dispatch(Actions.setRequirementFilters([]));
        };
    }, []);

    const handleChange = (name) => (data) => {
        setFilters(a => ({ ...a, [name]: data }));
    };

    const handleApply = () => {
        const keys = Object.keys(filters)?.filter(key => filters[key]);
        let fill = JSON.parse((JSON.stringify([...(appliedFilters || [])])));
        for (const key of keys) {
            const findInd = fill?.length && fill.findIndex?.(a => a.id === key);
            if (key !== 'expiryDate') {
                if (findInd >= 0) {
                    const ind = fill[findInd]?.value?.findIndex?.(a => a.value === filters[key]?.value);
                    if (ind >= 0)
                        fill = Object.assign([...fill], {
                            [findInd]: {
                                id: key,
                                value: Object.assign([...(fill[findInd]?.value || [])], {
                                    [ind]: { ...filters[key], createdAt: new Date(), type: key }
                                })
                            }
                        });
                    else
                        fill = Object.assign([...fill], { [findInd]: { id: key, value: [...(fill[findInd]?.value || []), { ...filters[key], createdAt: new Date(), type: key }] } });
                } else {
                    fill = [...fill, { id: key, value: [{ ...filters[key], createdAt: new Date(), type: key }] }];
                };
            } else {
                if (findInd >= 0) {
                    fill = Object.assign([...fill], {
                        [findInd]: {
                            id: key,
                            value: filters[key],
                            createdAt: new Date()
                        }
                    });
                } else {
                    fill = [...fill, { id: key, value: filters[key], createdAt: new Date() }];
                };
            }
        }
        dispatch(Actions.setRequirementFilters(fill));
        dispatch(Actions.fetchPerRequirementsData({ workerId: window.atob(params?.id), filterBy: type === 0 ? 'requirement' : 'employer' }));
        setOpenDrawer(false);
        setFilters({ expiryDate: null, employer: null, status: null, location: null, workType: null });
    };

    const onDelete = (filter) => {
        let data = JSON.parse(JSON.stringify(appliedFilters || []));
        let findInd1;
        if (filter?.id !== "expiryDate") {
            switch (filter?.type) {
                case 'employer': {
                    findInd1 = appliedFilters?.findIndex?.(a => a?.id === 'employer');
                    break;
                }
                case 'location': {
                    findInd1 = appliedFilters?.findIndex?.(a => a?.id === 'location');
                    break;
                }
                case 'status': {
                    findInd1 = appliedFilters?.findIndex?.(a => a?.id === 'status');
                    break;
                }
                case 'workType': {
                    findInd1 = appliedFilters?.findIndex?.(a => a?.id === 'workType');
                    break;
                }
            };
            data = Object.assign([...data], {
                [findInd1]: {
                    ...appliedFilters[findInd1],
                    value: appliedFilters[findInd1].value.filter(a => a.value !== filter.value)
                }
            });
            if (!data[findInd1]?.value?.length)
                delete data[findInd1];
        } else {
            findInd1 = appliedFilters?.findIndex?.(a => a?.id === 'expiryDate');
            delete data[findInd1];
        }
        dispatch(Actions.setRequirementFilters(data?.filter(a => a)));
        dispatch(Actions.fetchPerRequirementsData({ workerId: window.atob(params?.id), filterBy: type === 0 ? 'requirement' : 'employer' }));
    };

    return (
        <>
            <div className={classes.root}>
                <TextField
                    style={{ width: 300, maxWidth: '50%' }}
                    placeholder={activeTab === 0 ? "Search Requirement" : "Search Employer"}
                    value={value || ''}
                    onChange={e => {
                        setValue(e.target.value)
                        debouncedFunction(e);
                    }}
                    InputProps={{
                        startAdornment: <Search />
                    }} />
                <Button
                    variant="outlined"
                    className={classes.outlinedButton}
                    size="medium"
                    startIcon={<FilterAltOutlined />}
                    onClick={toggleDrawer(true)}
                    color="secondary"
                >
                    Advance Filter
                </Button>
                <Drawer anchor={'right'} open={openDrawer} onClose={toggleDrawer(false)}>
                    <Box role="presentation" className={classes.drawer}>
                        <div className={classes.drawerTitle}>
                            <Typography className={classes.title}>Filter by</Typography>
                            <IconButton className={classes.closeButton} onClick={toggleDrawer(false)}>
                                <Close />
                            </IconButton>
                        </div>
                        <div>
                            <Typography className={classes.label}>Employers</Typography>
                            <CustomizedEmployerAutocomplete
                                name="employer"
                                menuPortalTarget={document.body}
                                placeholder="Select"
                                onChange={handleChange('employer')}
                            />
                        </div>
                        <div>
                            <Typography className={classes.label}>Requirement Status</Typography>
                            <SimpleSelect
                                options={[
                                    { label: 'Applied', value: 'Applied' },
                                    { label: 'Approved', value: 'Approved' },
                                    { label: 'Pre Approved', value: 'PreApproved' },
                                    { label: 'Rejected', value: 'Rejected' },
                                    { label: 'Re-submission', value: 'ReSubmission' },
                                    { label: 'New Document', value: 'NewDocument' },
                                    { label: 'Alternate Document', value: 'AlternateDocument' },
                                    { label: 'Sent to Agency', value: 'SentToAgency' },
                                    { label: 'Expired', value: 'Expired' },
                                ]}
                                menuPortalTarget={document.body}
                                placeholder="Select"
                                onChange={handleChange('status')}
                                name="status"
                            />
                        </div>
                        <div>
                            <Typography className={classes.label}>Locations</Typography>
                            <CityDropDown
                                menuPortalTarget={document.body}
                                placeholder="Select"
                                onChange={handleChange('location')}
                                name="location"
                            />
                        </div>
                        <div>
                            <Typography className={classes.label}>Work Types</Typography>
                            <SimpleSelect
                                options={jobTypeList}
                                label="Select"
                                inputProps={{ label: null }}
                                menuPortalTarget={document.body}
                                onChange={handleChange('workType')}
                                name="workType"
                            />
                        </div>
                        <div>
                            <Typography className={classes.label}>Valid Till</Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <FormControl fullWidth>
                                    <MobileDatePicker
                                        inputFormat='DD-MM-YYYY'
                                        value={filters.expiryDate}
                                        onChange={(expiryDate) => setFilters(p => ({ ...p, expiryDate }))}
                                        renderInput={(params) => (
                                            <div style={{ position: "relative", display: "inline-block" }} className={classes.datePicker}>
                                                <TextField {...params} placeholder='Select' />
                                                {filters.expiryDate &&
                                                    <IconButton size="small" disableRipple style={{ position: "absolute", right: 28, top: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setFilters((p) => ({ ...p, expiryDate: null }))}>
                                                        <Clear style={{ opacity: 0.3, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18 }} />
                                                    </IconButton>
                                                }
                                                <IconButton size="small" disableRipple style={{ position: "absolute", right: 4, top: 0, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <KeyboardArrowDown style={{ opacity: 0.3, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 23 }} />
                                                </IconButton>
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </div>
                        <Button
                            variant="contained"
                            className={classes.applyFilterButton}
                            size="medium"
                            // onClick={toggleDrawer(true)}
                            disabled={Object.keys(filters).every(key => !filters[key])}
                            color="secondary"
                            onClick={handleApply}
                        >
                            Apply Filters
                        </Button>
                    </Box>
                </Drawer>
            </div>
            {Boolean(flatFilters && flatFilters?.length) && <div className={classes.tabsContainer}>
                <Tabs
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {flatFilters?.sort((a, b) => a?.createdAt - b?.createdAt)?.map(filter => (
                        <Chip key={filter?.value} label={filter?.label} onDelete={() => onDelete(filter)} />
                    ))}
                </Tabs>
            </div>}
        </>
    )
}
