import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: "0.7",
        backgroundColor:"white",
        top: 0,
        left: 0,
    },
});

function DisableCellOverlay(props) {
    const classes = useStyles();
    if (!props.visible) {
        return false;
    }
    return <div className={classes.root} style={props.style} />;
}

DisableCellOverlay.propTypes = {
    visible: PropTypes.bool,
    style: PropTypes.object
};

DisableCellOverlay.defaultProps = {
    visible: false,
    style: {},
};

export default DisableCellOverlay;
