/* eslint-disable import-helpers/order-imports */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { actions as overTimeSpecActions, selectors as overTimeSpecSelectors } from "store/modules/overtimeSpecs";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { Cancel, CheckCircle, Edit } from "@mui/icons-material";
import EditDialog from "./Components/EditDialog";
import { convertMinutesIntoHoures } from "utils/time";
import { weekdays } from "utils/constant";

const columnHeaderStyle = {
    styles: { textAlign: "center" },
    headerStyle: { textAlign: "center", fontWeight: "bold" }
};

function OvertimeSpecs({ ...props }) {
    const dispatch = useDispatch();
    const overtimeSpecs = useSelector(overTimeSpecSelectors.getOvertimeSpecs);
    const isLoading = useSelector(overTimeSpecSelectors.getIsOvertimeSpecsLoading);

    useEffect(() => {
        dispatch(overTimeSpecActions.fetchOvertimeSpecsData());
    }, []);

    const handleOpenEditDailog = (data) => {
        dispatch(overTimeSpecActions.setAndOpenEditDialog(data));
    };

    const column = [
        {
            Header: "Province",
            id: "region",
            accessor: ({ region }) => region,
            headerStyle: { fontWeight: "bold" },
            width: 90
        },
        {
            Header: "First weekday",
            id: "firstDayOfWeek",
            accessor: ({ firstDayOfWeek }) => firstDayOfWeek,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original: { firstDayOfWeek } } }) => {
                return weekdays[firstDayOfWeek]
            },
            width: 100
        },
        {
            Header: "GST",
            id: "gst",
            accessor: ({ gst }) => gst,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (original.gst||0) + "%"
            },
            width: 60
        },
        {
            Header: "Min Wage",
            id: "minimumWage",
            accessor: ({ minimumWage }) => minimumWage,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (original.minimumWage||0)
            },
            width: 80
        },
        {
            Header: "Daily OT",
            id: "isDailyOT",
            accessor: ({ isDailyOT }) => isDailyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return original.isDailyOT ? <CheckCircle color="success" /> : <Cancel color="error" />
            },
            width: 60
        },
        {
            Header: "Min Daily OT",
            id: "minDailyOT",
            accessor: ({ minDailyOT }) => minDailyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 80,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.minDailyOTMinutes),
        },
        {
            Header: "Max Daily OT",
            id: "maxDailyOT",
            accessor: ({ maxDailyOT }) => maxDailyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 85,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.maxDailyOTMinutes),
        },
        {
            Header: "Daily OT Rate",
            id: "dailyOTRate",
            accessor: ({ dailyOTRate }) => dailyOTRate,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 85,
            Cell: ({ row: { original } }) => `${original.dailyOTRate}x`,
        },
        {
            Header: "Daily DT",
            id: "isDailyDT",
            accessor: ({ isDailyDT }) => isDailyDT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return original.isDailyDT ? <CheckCircle color="success" /> : <Cancel color="error" />
            },
            width: 65
        },
        {
            Header: "Min Daily DT",
            id: "minDailyDT",
            ...columnHeaderStyle,
            disableSortBy: true,
            accessor: ({ minDailyDT }) => minDailyDT,
            className: "-cursor-pointer",
            width: 80,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.minDailyDTMinutes),
        },
        {
            Header: "Max Daily DT",
            id: "maxDailyDT",
            accessor: ({ maxDailyDT }) => maxDailyDT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 80,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.maxDailyDTMinutes),
        },
        {
            Header: "Daily DT Rate",
            id: "dailyDTRate",
            accessor: ({ dailyDTRate }) => dailyDTRate,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 85,
            Cell: ({ row: { original } }) => `${original.dailyDTRate}x`,
        },
        {
            Header: "Weekly OT",
            id: "isWeeklyOT",
            accessor: ({ isWeeklyOT }) => isWeeklyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return original.isWeeklyOT ? <CheckCircle color="success" /> : <Cancel color="error" />
            },
            width: 70
        },
        {
            Header: "Min Weekly OT",
            id: "minWeeklyOT",
            accessor: ({ minWeeklyOT }) => minWeeklyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 95,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.minWeeklyOTMinutes),
        },
        {
            Header: "Max Weekly OT",
            id: "maxWeeklyOT",
            accessor: ({ maxWeeklyOT }) => maxWeeklyOT,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 95,
            Cell: ({ row: { original } }) => convertMinutesIntoHoures(original.maxWeeklyOTMinutes),
        },
        {
            Header: "Weekly OT Rate",
            id: "weeklyOTRate",
            accessor: ({ weeklyOTRate }) => weeklyOTRate,
            className: "-cursor-pointer",
            ...columnHeaderStyle,
            disableSortBy: true,
            width: 95,
            Cell: ({ row: { original } }) => `${original.weeklyOTRate}x`,
        },
        {
            id: "actions",
            disableSortBy: true,
            disableFilters: true,
            collapse: true,
            className: "text-right",
            Cell: ({ row: { original } }) => {
                return <IconButton onClick={() => handleOpenEditDailog(original)}>
                    <Edit />
                </IconButton>;
            },
        },
    ];

    return (
        <React.Fragment>
            <ReactTableSimple
                classes="-highlight"
                data={overtimeSpecs}
                loading={isLoading}
                filterable={false}
                columns={column}
            />
            <EditDialog />
        </React.Fragment>
    );
}

export default OvertimeSpecs;
