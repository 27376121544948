import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ProvinceWisePayActions, selectors as ProvinceWisePaySelectors } from "store/modules/provinceWisePay";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { Cancel, CheckCircle } from "@mui/icons-material";
import ProvinceWisePayActionMenu from "./ProvinceWisePayActionMenu";
import moment from "moment";

function DocumentsTable(props) {
    const dispatch = useDispatch();
    const data = useSelector(ProvinceWisePaySelectors.getProvinceWisePayList);
    const isLoading = useSelector(ProvinceWisePaySelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(ProvinceWisePayActions.fetchProvinceWisePay());
    }, []);

    const minutesToHours = useCallback((minutes) => {
        const h = minutes / 60 | 0;
        const m = minutes % 60 | 0;
        return `${h} ${h > 1 ? 'hours' : 'hour'} ${m} ${m > 1 ? 'minutes' : 'minute'}`;
    }, []);

    return (
        <ReactTableSimple
            classes="-highlight"
            loading={isLoading}
            data={data}
            hasFilter
            initialSort={[{ id: 'province', desc: false }]}
            columns={[
                {
                    Header: "Id",
                    id: "id",
                    disableFilters: true,
                    collapse: true,
                    headerStyle: { minWidth: 70 },
                    accessor: ({ id }) => id,
                },
                {
                    Header: "Province",
                    id: "province",
                    accessor: ({ province }) => province.name,
                },
                {
                    Header: "Min Shift Hours",
                    id: "minShiftLength",
                    styles: { textAlign: "right" },
                    accessor: ({ minShiftLength }) => minutesToHours(minShiftLength),
                },
                {
                    Header: "Max Shift Hours",
                    id: "maxShiftLength",
                    styles: { textAlign: "right" },
                    accessor: ({ maxShiftLength }) => minutesToHours(maxShiftLength),
                },
                {
                    Header: "Min Payable Hours",
                    id: "minimumPay",
                    accessor: ({ minimumPay }) => minimumPay,
                    styles: { textAlign: "right" },
                    Cell: ({ row: { original } }) => minutesToHours(original.minimumPay),
                },
                {
                    Header: "Effective Date From",
                    id: "effectiveDateFrom",
                    styles: { textAlign: "center" },
                    accessor: ({ effectiveDateFrom }) => effectiveDateFrom,
                    Cell: ({ row: { original } }) => moment(original.effectiveDateFrom).format("DD/MM/YYYY"),
                },
                {
                    Header: "Effective Date To",
                    id: "effectiveDateTo",
                    accessor: ({ effectiveDateTo }) => effectiveDateTo,
                    styles: { textAlign: "center" },
                    Cell: ({ row: { original } }) => moment(original.effectiveDateTo).format("DD/MM/YYYY"),
                },
                {
                    Header: "Status",
                    id: "isActive",
                    disableFilters: true,
                    styles: { textAlign: "center" },
                    accessor: ({ isActive }) => isActive,
                    Cell: ({ row: { original } }) => original.isActive ? <CheckCircle fontSize={"medium"} color="secondary" /> : <Cancel fontSize={"medium"} color="error" />,
                },
                {
                    Header: "Created Date",
                    id: "createdAt",
                    accessor: ({ createdAt }) => createdAt,
                    disableSortBy: true,
                    disableFilters: true,
                    Cell: ({ row: { original } }) => moment(original.createdAt).format("DD/MM/YYYY hh:mm:ss A"),
                },
                {
                    Header: "Modified Date",
                    id: "updatedAt",
                    accessor: ({ updatedAt }) => updatedAt,
                    disableSortBy: true,
                    disableFilters: true,
                    Cell: ({ row: { original } }) => moment(original.updatedAt).format("DD/MM/YYYY hh:mm:ss A"),
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    collapse: true,
                    disableFilters: true,
                    styles: { textAlign: "right" },
                    Cell: ({ row: { original } }) => {
                        return (
                            <ProvinceWisePayActionMenu document={original} />
                        );
                    },
                },
            ]}
        />
    );
};

export default DocumentsTable;
