import { deepOrange, green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Remove } from "@mui/icons-material";

export const DashIcon = withStyles({ root: { fontSize: 12 } })(Remove);
export const CustomTableCell = withStyles({
  root: {
    border: "none",
  },
  head: {
    fontSize: 14,
    color: "#404b5a",
    borderBottom: "1px solid lightgrey",
  },
  body: {
    fontSize: 12,
  },
})(TableCell);

export const CustomTableRow = withStyles({
  head: {
    height: 24,
    backgroundColor: "inherit",
    borderBottom: "0.7px solid lightgrey",
  },
})(TableRow);

export const earningTypes = [
  {
    id: 1,
    name: "Standard",
    rateModifier: "1.000",
    code: "STD",
  },
  {
    id: 2,
    name: "Stat Standard",
    rateModifier: "1.500",
    code: "STATSTD",
  },
  {
    id: 3,
    name: "Overtime (1.5x)",
    rateModifier: "1.500",
    code: "TAAH",
  },
  {
    id: 4,
    name: "Double Time (2x)",
    rateModifier: "2.000",
    code: "DT",
  },
  {
    id: 5,
    name: "Vacation",
    rateModifier: "0.040",
    code: "STDVAC",
  },
  {
    id: 6,
    name: "Bonus",
    rateModifier: "1.000",
    code: "BNS",
  },
  {
    id: 7,
    name: "Overtime (1.5x)",
    rateModifier: "1.000",
    code: "WOT",
  },
];
export const statuses = [
  {
    id: 1,
    dcCode: "P",
    code: "QUEUE",
    description: "Queued",
  },

  {
    id: 3,
    dcCode: "S",
    code: "WAIT_RESP",
    description: "Awaiting Response",
  },

  {
    id: 5,
    dcCode: "C",
    code: "DELIVERED",
    description: "Delivered",
  },

  {
    id: 7,
    dcCode: "RJ",
    code: "DECLINED",
    description: "Declined",
  },
  {
    id: 8,
    dcCode: "E",
    code: "FAILED",
    description: "Failed",
  },

  {
    id: 11,
    dcCode: "V",
    code: "CANCELLED",
    description: "Cancelled",
  },

  {
    id: 14,
    dcCode: "DF",
    code: "DEFERRED",
    description: "Deferred",
  },
  {
    id: 13,
    code: "MANUAL_RESOLVED",
    dcCode: "MR",
    description: "Manually Resolved",
  },
];
export const deductionTypes = [
  {
    id: 1,
    countryId: "1",
    regionId: "NULL",
    shortName: "EI",
    longName: "Employment Insurance",
    code: "EE",
  },
  {
    id: 2,
    countryId: "1",
    regionId: "NULL",
    shortName: "Employer EI",
    longName: "Employer Employment Insurance Contribution",
    code: "ER",
  },
  {
    id: 3,
    countryId: "1",
    regionId: "NULL",
    shortName: "CPP",
    longName: "Canadian Pension Plan",
    code: "EE",
  },
  {
    id: 4,
    countryId: "1",
    regionId: "NULL",
    shortName: "Employer CPP",
    longName: "Employer Canadian Pension Plan Contribution",
    code: "ER",
  },
  {
    id: 5,
    countryId: "1",
    regionId: "NULL",
    shortName: "Federal Tax",
    longName: "Federal Tax",
    code: "EE",
  },
  {
    id: 6,
    countryId: "1",
    regionId: "2",
    shortName: "BC Provincial Tax",
    longName: "British Columbia Provincial Tax",
    code: "EE",
  },
  {
    id: 7,
    countryId: "1",
    regionId: "2",
    shortName: "EHT",
    longName: "Employer Health Tax",
    code: "ER",
  },
  {
    id: 8,
    countryId: "1",
    regionId: "2",
    shortName: "WCB",
    longName: "WorkSafeBC",
    code: "ER",
  },
];
export const getDeductionType = id => deductionTypes.find(c => c.id === id);
export const getEarningType = id => earningTypes.find(c => c.id === id);

export const useStyles = makeStyles(({ spacing, zIndex, transitions, shape, palette }) => ({
  root: {
    backgroundColor: palette.grey[100],
    border: `0.7px solid ${palette.grey[300]}`,
    borderRadius: shape.borderRadius,
    marginTop: spacing(0),
    marginBottom: spacing(2),
    paddingTop: spacing(1),
  },
  backdrop: {
    zIndex: zIndex.drawer + 1,
    color: "#fff",
  },

  tableContainer: {
    backgroundColor: palette.grey[100],
    border: `0.8px solid ${palette.grey[400]}`,
    borderRadius: shape.borderRadius,
    marginTop: spacing(0),
    marginBottom: spacing(0),
    paddingTop: spacing(1),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    paddingBottom: spacing(2),
  },
  badgeRoot: {
    marginTop: spacing(0),
    marginBottom: spacing(0),
  },
  square: {
    color: palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  rounded: {
    color: "#fff",
    backgroundColor: green[500],
  },
  titleContainer: {
    padding: `${spacing(1)} ${spacing(2)}`,
    minHeight: 64,
  },
  transferCount: {
    marginRight: spacing(2),
  },
  leftContainer: {
    marginRight: spacing(1),
  },
  rightContainer: {
    marginLeft: spacing(1),
  },
  centerContainer: {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
  },
  searchInputLabel: {},
  table: {
    flex: 1,
    borderRadius: shape.borderRadius / 1.5,
    border: `0.7px solid ${palette.grey[400]}`,
    width: "100%",
    paddingBottom: spacing(2),
  },
  "rt-td": {
    height: " 25px!important",
  },
  input: {
    borderRadius: 4,
    position: "relative",
    width: "100%",
    backgroundColor: palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 13,
    padding: "0px 26px 0px 12px",
    transition: transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.55)",
    },
  },
  paper: {
    border: "1px solid",
    padding: spacing(1),
    backgroundColor: palette.background.paper,
    alignSelf: "flex-end",
    position: "relative !important",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: spacing(1),
  },
}));

export const OverTimeIds = {
    DOT: 3,
    WOT: 7
};

export const VacationPayId = 5;
export const TimeBasePremiumId = 9;
export { formatNumber, formatMoney } from "utils/stringUtils";
