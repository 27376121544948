/* eslint-disable no-constant-condition */
import React from 'react'
import { Add, Close, InfoOutlined } from '@mui/icons-material'
import { Button, Divider, FormControl, Grid, IconButton, Switch, TextField, Typography } from '@mui/material'
import WYSIWYGEditor from 'views/AdditionalRequirements/components/Editor';
import FormRow from 'views/AdditionalRequirements/components/FormRow';
import GroupsDropdown from 'views/AdditionalRequirements/components/GroupsDropdown';
import { makeStyles } from '@mui/styles';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: '65%',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: ({ }) => '#F5F5F5' || '#FFF',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75),
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    },
    root: {
        '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-multiline': {
            background: '#F5F5F5',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            background: '#F5F5F5',
        },
        '& label': {
            color: '#000000 !important',
        },
        '& input': {
            '&::placeholder': {
                color: '#000000 !important',
                webkitTextFillColor: '#000000 !important',
            },
            WebkitTextFillColor: '#000000 !important',
            color: '#000000 !important',
            textOverflow: 'ellipsis'
        },
        '& textarea': {
            '&::placeholder': {
                color: '#000000 !important',
                webkitTextFillColor: '#000000 !important',
            },
            WebkitTextFillColor: '#000000 !important',
            color: '#000000 !important',
            textOverflow: 'ellipsis'
        }
    },
    GroupsDropdown: {
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
            background: '#F5F5F5',
        }
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        color: '#3F454D'
    },
    helpTextTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeButton: {
        background: '#E9F1FB',
        height: 28,
        width: 28,
        '& svg': {
            height: 15,
            width: 15,
        }
    },
    addButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2)
    },
    notFound: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notFoundText: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        color: '#3F454D'
    }
}));

export default function HelpTextDetails() {
    const classes = useStyles();
    const formData = useSelector(Selectors.getFormData);
    const isEditing = useSelector(Selectors.getIsEditing);
    const dispatch = useDispatch();

    const handleChange = (ind, key, value) => {
        const data = Object.assign([...formData.helpTexts], {
            [ind]: {
                ...formData.helpTexts[ind],
                [key]: value
            }
        });
        dispatch(Actions.setAdditionalRequirementForm('helpTexts', data));
    };

    const handleRemove = (ind) => {
        const data = [...(formData?.helpTexts || [])];
        data.splice(ind, 1);
        dispatch(Actions.decreaseHelpText(data));
    };

    const addHelpText = () => {
        dispatch(Actions.increaseHelpText([...(formData?.helpTexts || [])].concat({
            question: '', frenchQuestion: '', answer: '', frenchAnswer: '',
        })));
        setTimeout(() => {
            const doc = document.getElementById(`HelpText-${formData?.helpTexts?.length + 1}`);
            doc?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }, 500);
    };

    return (
        <div className={classes.root}>
            {formData?.helpTexts?.map((help, ind) => (
                <div id={`HelpText-${ind + 1}`} key={ind}>
                    <div className={classes.helpTextTitle}>
                        <Typography className={classes.title}>{`Help Text ${ind + 1}`}</Typography>
                        {isEditing && <IconButton onClick={() => handleRemove(ind)} className={classes.closeButton} size='small'>
                            <Close />
                        </IconButton>}
                    </div>
                    <FormRow style={{ marginTop: 38 }}>
                        <FormControl fullWidth className={classes.formRow}>
                            <TextField
                                disabled={!isEditing}
                                label={`Question ${ind + 1} - English`}
                                placeholder="Ex: Where can I find this Info?"
                                margin="none"
                                fullWidth
                                name='question'
                                id='question'
                                onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                value={help.question}
                            />
                        </FormControl>
                    </FormRow>
                    <FormRow style={{ marginTop: 38 }}>
                        <FormControl fullWidth className={classes.formRow}>
                            <TextField
                                disabled={!isEditing}
                                className={classes.marginTop}
                                label={`Question ${ind + 1} - French`}
                                placeholder="Ex: Où puis-je trouver cette information?"
                                margin="none"
                                fullWidth
                                name='frenchQuestion'
                                id='frenchQuestion'
                                onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                value={help.frenchQuestion}
                            />
                        </FormControl>
                    </FormRow>
                    <FormRow style={{ marginTop: 38 }}>
                        <FormControl fullWidth className={classes.formRow}>
                            <TextField
                                disabled={!isEditing}
                                multiline
                                rows="3"
                                label={`Answer ${ind + 1} - English `}
                                placeholder="Ex: To find the ID number look at the bottom left of your card"
                                margin="none"
                                fullWidth
                                name='answer'
                                id='answer'
                                onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                value={help.answer}
                            />
                        </FormControl>
                    </FormRow>
                    <FormRow style={{ marginTop: 38 }}>
                        <FormControl fullWidth className={classes.formRow}>
                            <TextField
                                disabled={!isEditing}
                                multiline
                                rows="3"
                                className={classes.marginTop}
                                label={`Answer ${ind + 1} - French`}
                                placeholder="Ex : Pour trouver le numéro d'identification regardez en bas à gauche de votre carte"
                                margin="none"
                                fullWidth
                                name='frenchAnswer'
                                id='frenchAnswer'
                                onChange={({ target: { name, value } }) => handleChange(ind, name, value)}
                                value={help.frenchAnswer}
                            />
                        </FormControl>
                    </FormRow>
                    {ind !== (formData?.helpTexts?.length - 1) ? <Divider style={{ marginTop: 24, marginBottom: 24 }} /> : null}
                </div>
            ))}
            {formData?.helpTexts?.length ? <div className={classes.addButtonContainer}>
                {isEditing && <Button onClick={addHelpText} size="small" color='secondary' variant="text" startIcon={<Add />}>{`Add More`}</Button>}
            </div> : (
                <div className={classes.notFound}>
                    <Typography className={classes.notFoundText}>No Help Text Found.</Typography>
                </div>
            )}

        </div>
    )
};
