import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const styles = {
    container: {
        position: "relative",
        top: "-1px",
        zIndex: "1030",
        paddingLeft: "32px",
        paddingRight: "32px",
        backgroundColor: "#FFF",
        borderBottom: "1px solid #d5dde5",
    },
    appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
    indicator: {
        backgroundColor: "#11b2a8",
    },
    tabRroot: {
        textTransform: "none",
        marginRight: "22px",
        fontSize: "10px",
    },
    scrollButtons: {
        opacity: `1 !important`,
        "& svg": {
            height: 30,
            width: 30,
            fill:"#000000",
        },
        "&.Mui-disabled": {
            "& svg": {
                fill: "#cccccc",
            }
        }
    }
};

function SubNav({ classes, value, onChange, tabs, tabProps = {} }) {
    return (
        <div className={classes.container}>
            <AppBar position="static" classes={{ root: classes.appBar }}>
                <Tabs
                    value={value}
                    onChange={onChange}
                    TabIndicatorProps={{ className: classes.indicator }}
                    TabScrollButtonProps={{ className: classes.scrollButtons }}
                    textColor="primary"
                    variant="scrollable"
                    {...tabProps}
                >
                    {tabs.map((el, i) => {
                        if (el.props && el.props.type === "Button") {
                            return;
                        }
                        return (
                            <Tab
                                key={i}
                                label={el}
                                classes={{
                                    root: classes.tabRroot,
                                }}
                            />
                        );
                    })}
                    <div className={classes.buttonSection}>
                        {tabs.map((el) => {
                            if (el.props && el.props.type === "Button") {
                                return el;
                            }
                        })}
                    </div>
                </Tabs>
            </AppBar>
        </div>
    );
}

SubNav.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    tabProps: PropTypes.object,
    tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default withStyles(styles)(SubNav);
