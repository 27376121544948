import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import MainContent from 'components/Containers/MainContent';
import HelpForm from './components/HelpForm';
import Stage from './components/Stage';
import SummaryFooter from './components/SummaryFooter';
import DocumentDetailsForm from './components/DocumentDetailsForm';
import DocumentExpiryStage from './components/DocumentExpiryStage';
import { useDispatch } from 'react-redux';
import { actions as AdditionalRequirementsAction } from 'store/modules/additionalRequirements';

export default function CreateDocument() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(AdditionalRequirementsAction.setIsRequiremntAddLoading(false));
        dispatch(AdditionalRequirementsAction.setAdditionalRequirementForm('type', 'Document'));
        return () => {
            dispatch(AdditionalRequirementsAction.clearAdditionalRequirementForm());
        }
    }, []);

    return (
        <MainContent>
            <Grid>
                <Stage
                    title="Document Details"
                    description="Gives a brief description of the document."
                    style={{ borderBottom: "1px solid #aebecd" }}
                    contentSize={6}>
                    <DocumentDetailsForm />
                </Stage>
                <Stage
                    title="Help Text (For Talent)"
                    style={{ borderBottom: "1px solid #aebecd" }}
                    description={(
                        <>
                            Make it easier for talents to find the information you are looking for and increase their comfort around why you need the info.
                            <br />
                            <br />
                            Structuring this as FAQs makes information digestible
                        </>
                    )}
                    contentSize={6}>
                    <HelpForm />
                </Stage >
                <Stage
                    title="Document Validity/Expiry"
                    description={'Manage Validity and Expiry of documents here. Validity is the duration in which the the employer considers the particular document for auto approval. Expiry is the date of Expiry of the document.'}
                    contentSize={6}>
                    <DocumentExpiryStage />
                </Stage >
                <SummaryFooter />
            </Grid>
        </MainContent>
    )

}
