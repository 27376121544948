import React, { useState } from 'react'
import classNames from 'classnames';
import { Info } from "@mui/icons-material";
import { Box, ClickAwayListener, Divider, Grid, Grow, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { formatMoney } from 'utils/stringUtils';
import { SplitTime } from 'utils/time';


const useStyles = makeStyles(({ spacing, palette }) => ({
    popup: {
        position: 'absolute',
        top: 28,
        right: 0,
        zIndex: 1,
        width: 450,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        background: palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: spacing(1),
        color: `${palette.text.primary} !important`,
        '&::before': {
            content: ({ showArrow }) => showArrow ? '""' : undefined,
            visibility: ({ showArrow }) => showArrow ? 'visible' : 'hidden',
            width: 15,
            height: 15,
            transform: 'rotate(45deg)',
            background: '#fff',
            position: 'absolute',
            top: -8,
            right: 30,
            borderLeft: '1px solid #ccc',
            borderTop: '1px solid #ccc',
        },
        '& .MuiBox-root': {
            padding: spacing(2),
            position: 'relative',
            background: palette.background.paper,
            borderRadius: spacing(1),
        }
    }
}));

const TotalBreakUps = ({ breakups, hideInfo = false, peopleNeeded, canShowTotal = false, ...props }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles({ showArrow: !canShowTotal });
    const RegularPayHours = (SplitTime(breakups?.regularPayHours));
    const OvertimePayHours = (SplitTime(breakups?.overtimePayHours));
    const DoublePayHours = (SplitTime(breakups?.doublePayHours));
    const timeBasePremiumHours = (SplitTime(breakups?.timeBasePremiumHours));

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: 'relative' }}>
                <Typography
                    type="button"
                    variant="caption"
                    color="textSecondary"
                    sx={{
                        marginTop: 0.5,
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={handleClick}>
                    <Info fontSize='small' />&nbsp;Click here for breakup
                </Typography>
                <Grow
                    in={open}
                    style={{ transformOrigin: '100% 0 0' }}
                >
                    <Box className={classNames(classes.popup, props.className)}>
                        <Box>
                            <Grid container xs={12}>
                                <Grid item xs={9}>
                                    {`Hourly pay - ${RegularPayHours?.Hours} hrs ${RegularPayHours?.Minutes} mins ${hideInfo ? '' : `x ${peopleNeeded} worker`}`}
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    {formatMoney(breakups?.hourlyPay)}
                                </Grid>
                            </Grid>
                            {breakups?.overtimePay > 0 && (
                                <Grid container xs={12}>
                                    <Grid item xs={9}>
                                        {`Overtime pay - ${OvertimePayHours?.Hours} hrs ${OvertimePayHours?.Minutes} mins ${hideInfo ? '' : `x ${peopleNeeded} worker`}`}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                                        {formatMoney(breakups?.overtimePay)}
                                    </Grid>
                                </Grid>
                            )}
                            {breakups?.doublePay > 0 && (
                                <Grid container xs={12}>
                                    <Grid item xs={9}>
                                        {`Doubletime pay - ${DoublePayHours?.Hours} hrs ${DoublePayHours?.Minutes} mins ${hideInfo ? '' : `x ${peopleNeeded} worker`}`}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                                        {formatMoney(breakups?.doublePay)}
                                    </Grid>
                                </Grid>
                            )}
                            {breakups?.timeBasePremium > 0 && (
                                <Grid container xs={12}>
                                    <Grid item xs={9}>
                                        {`Time Base Premium - ${timeBasePremiumHours?.Hours} hrs ${timeBasePremiumHours?.Minutes} mins ${hideInfo ? '' : `x ${peopleNeeded} worker`}`}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                                        {formatMoney(breakups?.timeBasePremium)}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container xs={12}>
                                <Grid item xs={9}>
                                    Randstad Direct Fee
                                </Grid>
                                <Grid item xs={3} sx={{ textAlign: "right" }}>
                                    {formatMoney(breakups?.gfFee)}
                                </Grid>
                            </Grid>
                            {breakups?.taxes?.map(tx => (
                                <Grid key={tx.label} container xs={12}>
                                    <Grid item xs={9}>
                                        {`${tx.label} ${tx.percentage}%`}
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                                        {formatMoney(tx.amount)}
                                    </Grid>
                                </Grid>
                            ))}
                            {canShowTotal && <>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Grid container xs={12}>
                                    <Grid item xs={6}>
                                        <Typography variant='h6'>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                                        <Typography variant='h6'>
                                            {formatMoney(breakups?.total)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>}
                            {!hideInfo && (
                                <>
                                    <br />
                                    <Grid container>
                                        <Grid item xs="auto">
                                            <Typography variant='caption'>
                                                *&nbsp;
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant='caption'>
                                                This amount is an estimate, the actual amount will be determined after payment has been done.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </Box>
                </Grow>
            </Box>
        </ClickAwayListener>
    )
}

export default TotalBreakUps;
