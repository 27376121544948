import { FormControl, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import GroupsAutocomplete from 'components/Autocomplete/GroupsAutocomplete';
import Button from 'components/Button/Button';
import React from 'react'
import FormRow from 'views/AdditionalRequirements/components/FormRow'
import {  actions as MetaActions } from 'store/modules/meta';
import { selectors as Selectors, actions as Actions } from 'store/modules/employerDetails';
import { useDispatch, useSelector } from 'react-redux';
import RequirementsAutocomplete from 'components/Autocomplete/RequirementsAutocomplete';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: '65%',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: '#FFF',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75),
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    },
    autocomplete: {
        '& p.MuiTypography-root': {
            whiteSpace: 'nowrap',
            maxWidth: '95%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}));

export default function RequirementSelection({ assignedData }) {
    const classes = useStyles();
    const selected = useSelector(Selectors.getSelectedGroupOrRequirement);
    const isLoading = useSelector(Selectors.getRequirementByGroup);
    const dispatch = useDispatch();

    const handleChange = (val) => {
        dispatch(Actions.setSelectedGroupOrRequirement(val));
    };

    const handleSubmit = () => {
        if (selected && selected.selectedType === "group") {
            const groups = assignedData ? assignedData.flatMap(a => a?.group?.id) : [];
            if (groups.includes(selected?.data?.id))
                dispatch(MetaActions.errorToast('Selected group is already exist!'));
            else
                dispatch(Actions.fetchRequirementsByGroup());
            return;
        };
        dispatch(Actions.setAssignedGroupOrRequirement({ selected }));
    };

    return (
        <>
            <FormRow style={{}}>
                <Grid container spacing={2} item xs={12} style={{ alignItems: 'flex-end' }}>
                    <Grid container item xs={4} style={{ zIndex: 100 }}>
                        <FormControl fullWidth>
                            <div className={classes.label}>Group</div>
                            <GroupsAutocomplete
                                value={selected?.selectedType === "group" ? selected : null}
                                onChange={(val) => handleChange(val ? { ...val, selectedType: 'group' } : null)}
                                // disabled={selected && selected.selectedType !== "group"}
                                className={classes.autocomplete}
                                placeholder="Select or Type" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display={'flex'} style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                        <Typography fontSize={14} marginBottom={0.8} fontWeight={500}>OR</Typography>
                    </Grid>
                    <Grid container item xs={4} style={{ zIndex: 100 }}>
                        <FormControl fullWidth style={{ height: 'fit-content' }}>
                            <div className={classes.label}>Requirement</div>
                            <RequirementsAutocomplete
                                value={selected?.selectedType === "requirement" ? selected : null}
                                // disabled={selected && selected.selectedType !== "requirement"}
                                onChange={(val) => handleChange(val ? { ...val, selectedType: 'requirement' } : null)}
                                className={classes.autocomplete}
                                placeholder="Select or Type" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display={'flex'} style={{ height: 'fit-content', alignItems: 'flex-end' }}>
                        <Button
                            onClick={handleSubmit}
                            disabled={!selected || isLoading}
                            isLoading={isLoading}
                            label="Submit" />
                    </Grid>
                </Grid>
            </FormRow>
        </>
    )
}
