import React from "react";
import IconButton from "@mui/material/IconButton";
import { Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import history from "utils/history";


const useStyles = makeStyles(theme => ({
    action: {
        display: 'flex',
        gap: theme.spacing(0.5)
    },
    assignButton: {
        borderRadius: 30,
        fontWeight: 600,
        whiteSpace: 'nowrap'
    }
}));

function ColumnActionMenu({ id }) {
    const classes = useStyles();

    const handleRedirectToNewAssign = () => {
        history.push(`/additional-requirements/assign/${window.btoa(id)}`)
    };

    return (
        <div className={classes.action}>
            <IconButton onClick={() => history.push(`/additional-requirements/${window.btoa(id)}`)}>
                <RemoveRedEyeOutlined color="primary" />
            </IconButton>
            <IconButton onClick={() => history.push(`/additional-requirements/${window.btoa(id)}?type=1`)}>
                <Edit color="primary" />
            </IconButton>
            <Button onClick={handleRedirectToNewAssign} variant="outlined" color="secondary" className={classes.assignButton}>
                Assign Employer
            </Button>
        </div>
    );
}

export default ColumnActionMenu
