import { gql } from "@apollo/client";

export const UpdateEmployerCustomRateMutation = gql`
  mutation editCustomRate($id: Int!, $data: EditBillingRateInput!) {
    editCustomRate(id: $id, data: $data) {
      id
      createdAt
      #   updatedAt
      employerId
      name
      billingLabel
      rateType
      description
      isActive
      baseRate
      baseBillingRate
      wageMultiplier
      billingRateMultiplier
    }
  }
`;

export const CreateEmployerCustomRateMutation = gql`
  mutation createCustomRate($data: BillingRateInput!) {
    createCustomRate(data: $data) {
      id
      createdAt
      updatedAt
      employerId
      name
      billingLabel
      rateType
      description

      isActive
      baseRate
      baseBillingRate
      wageMultiplier
      billingRateMultiplier
    }
  }
`;

export const uploadProfileImageMutation = gql`
  mutation uploadProfileImage($type: String!, $id: String!, $file: Upload!) {
    uploadProfileImage(file: $file, id: $id, type: $type) {
      id
      path
      filename
      mimetype
    }
  }
`;

export const uploadEmployerFileToS3Mutation = gql`
  mutation uploadEmployerFileToS3($data: EmployerInput!, $isProfile: Boolean!, $file: Upload!) {
    uploadEmployerFileToS3(data: $data, isProfile: $isProfile, file: $file) {
      id
      profileImageUrl
      logoImageUrl
    }
  }
`;

export const uploadCustomFieldFileToS3Mutation = gql`
  mutation employerCustomFileUpload($employerId: String!, $file: Upload!) {
    employerCustomFileUpload(employerId: $employerId, file: $file){
        filepath
        fileSignedurl
    }
  }
`;

export const uploadLogoMutation = gql`
  mutation uploadLogoImage($type: String!, $id: String!, $file: Upload!) {
    uploadLogoImage(file: $file, id: $id, type: $type) {
      id
      path
      filename
      mimetype
    }
  }
`;

export const CreateOrUpdateEmployerHasRequirement = gql`
mutation createOrUpdateEmployerHasRequirement($data:[CreateEmployerRequirementInput!]!){
    createOrUpdateEmployerHasRequirement(data:$data){
        entities {
            id
            employerId
            workTypeId
            requirementId
            validityType
            validityValue
        }
        existing
    }
}
`;

export default UpdateEmployerCustomRateMutation;
