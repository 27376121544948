import React from "react";
import { CircularProgress, DialogTitle } from "@mui/material";
import MUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { makeStyles } from "@mui/styles";
import { convertUTCToTimezoneWithOffset } from "utils/time";
import Button from "components/Button/Button";
import { History } from "@mui/icons-material";

const useStyle = makeStyles(({ palette, customPalette }) => {
    return {
        oppContent: {
            flex: "none",
            padding: "0px 10px 6px 0px",
            width: "110px",
            alignItems: "center",
            display: "flex",
        },
        content: {
            minHeight: "80px",
            textAlign: "center"
        },
        progress: {
            color: palette.secondary.light,
            position: "absolute",
            top: "50%",
            left: "45%",
        },
        error: {
            borderColor: customPalette.red[600],
        },
        action: {
            borderColor: customPalette.grey[700],
        },
        yellow: {
            borderColor: customPalette.yellow[700],
            color: customPalette.yellow[700],
        },
        blue: {
            borderColor: customPalette.blue[400],
            color: customPalette.blue[400],
        },
        profileImage: {
            width: "25px",
            height: "25px",
            padding: "2px",
        },
        timeline: {
            alignItems: "center"
        }
    };
});

function PaymentTimelineDialog(props) {
    const classes = useStyle();
    const { onClose, open, data } = props;

    return (
        <MUIDialog
            open={open}
            size="sm"
            maxWidth="sm"
            fullWidth
            disableBackdropClick={false}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">
                {"Payment Timeline"}
                <Button
                    style={{ float: "right", marginRight: -15 }}
                    label="X"
                    size="small"
                    onClick={onClose}
                    color="transparent"
                />
            </DialogTitle>
            <DialogContent className={classes.content}>
                {(!data || data.length == 0) && <p>No data found</p>}
                {data &&
                    data.length > 0 && (
                        <Timeline className={classes.timeline}>
                            {data.map(n => (
                                <TimelineItem key={n.id}>
                                    <TimelineOppositeContent className={classes.oppContent} color="text.secondary">
                                        <div>
                                            {convertUTCToTimezoneWithOffset(n.createdAt, "", "MMM Do YYYY h:mm a")}
                                        </div>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot color="primary" variant="outlined">
                                            <History color="primary" className={classes.profileImage} />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="subtitle1" component="span">
                                            {n.action}
                                        </Typography>
                                        <Typography>{n.notes}</Typography>
                                        <Typography>
                                            <b>Payment Id: </b> {n.paymentId}
                                            <div>
                                                <b>Activity By: </b>
                                                {n.activityBy}
                                            </div>
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    )}
            </DialogContent>
        </MUIDialog>
    );
}

export default PaymentTimelineDialog;
