import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import HelperMessage from "./HelperMessage";
import { Button as Btn } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { actions as MetaActions } from "store/modules/meta";
import { supportedAvatarFiles } from "utils/constant";
import { isValidFile } from "utils/file";
import { useDispatch } from "react-redux";

function WorkerDetailsSection(props) {
    const {
        updateFormData,
        setEmail,
        emailIsAvailable,
        emailIsValid,
        onAddressChange,
        setPhone,
        selectedLocation,
        phone,
        payRate,
        payRateIsValid,
        billingRateIsValid
    } = props;
    const [logoImage, setLogoImage] = React.useState('')
    const dispatch = useDispatch();

    const handleLogoFile = useCallback(file => {
        updateFormData({ target: { id: 'logo', value: file } })
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setLogoImage(reader.result);
        }
    }, []);
    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                    <TextField
                        id="companyName"
                        label="Company Name"
                        onChange={updateFormData}
                        type="text"
                        margin="normal"
                        required
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={4} direction="column" style={{ paddingRight: 4 }}>
                    <TextField
                        id="firstName"
                        label="First Name"
                        onChange={updateFormData}
                        type="text"
                        margin="normal"
                        required
                    />
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    <TextField
                        id="lastName"
                        label="Last Name"
                        onChange={updateFormData}
                        type="text"
                        margin="normal"
                        required
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12}>
                <Grid container item xs={8} direction="column">
                    <TextField
                        id="title"
                        label="Title"
                        onChange={updateFormData}
                        type="text"
                        margin="normal"
                        required
                    />
                </Grid>
                <Grid container item xs={8} direction="column">
                    <TextField
                        id="email"
                        label=" Email Address"
                        onChange={setEmail}
                        type="email"
                        margin="normal"
                        required
                    />
                </Grid>

                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    {emailIsValid === false && (
                        <HelperMessage icon={<InfoIcon color="error" />} message="Email is invalid." />
                    )}
                    {emailIsAvailable === false && (
                        <HelperMessage icon={<InfoIcon color="error" />} message="Email is already taken." />
                    )}
                </Grid>

                <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                    <NumberFormat
                        id="phone"
                        label="Phone"
                        onChange={setPhone}
                        margin="normal"
                        fullWidth
                        customInput={TextField}
                        format="(###) ###-####"
                        mask="#"
                        required
                    />
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    {phone && phone.length < 10 && (
                        <HelperMessage icon={<InfoIcon color="error" />} message="Phone number is invalid." />
                    )}
                </Grid>

                {/*<Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
          <TextField
            id="unit"
            label="Unit"
            onChange={updateFormData}
            type="text"
            margin="normal"
            inputProps={{
              min: 1,
            }}
          />
        </Grid>*/}
                <Grid container item xs={12}>
                    <Grid container item xs={8} direction="column" style={{ paddingRight: 4, marginBottom: 10 }}>
                        <AddressAutocomplete value={selectedLocation} onChange={onAddressChange} required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={4} style={{ paddingRight: 4 }}>

                    <TextField
                        name="payRate"
                        label="Base Hourly Pay Rate"
                        id="payRate"
                        onChange={updateFormData}
                        fullWidth
                        margin="normal"
                        required
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        inputProps={{
                            step: 0.05,
                            type: "number",
                            min: 0
                        }}
                        error={payRateIsValid === false}
                    />
                </Grid>
                <Grid container item xs={4} style={{ paddingLeft: 4 }}>
                    <TextField
                        id="billingRate"
                        name="billingRate"
                        label="Base Hourly Billing Rate"
                        onChange={updateFormData}
                        fullWidth
                        margin="normal"
                        required
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        inputProps={{
                            step: 0.05,
                            type: "number",
                            min: Number(payRate)+1
                        }}
                        error={billingRateIsValid === false}
                    // value={values.billingRate}
                    />
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    {(payRateIsValid === false) && (
                        <HelperMessage icon={<InfoIcon color="error" />} message={"Pay rate is invalid"} />
                    )}
                    {(billingRateIsValid === false ) && (
                        <HelperMessage icon={<InfoIcon color="error" />} message={"Billing rate is invalid"} />
                    )}
                </Grid>
            </Grid>

            <Grid container item xs={12}>
                <Grid container item xs={4} style={{ paddingRight: 4 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="isApproved"
                                defaultChecked={true}
                                onChange={updateFormData}
                                name="isApproved"
                            />
                        }
                        label="Is Approved"
                        style={{ color: "rgb(33 42 52)" }}
                    />
                </Grid>
                <Grid container item xs={4} style={{ paddingLeft: 4 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                id="isActive"
                                defaultChecked={true}
                                onChange={updateFormData}
                                name="isActive"
                                color="secondary"
                            />
                        }
                        style={{ color: "rgb(33 42 52)" }}
                        label="Is Active"
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container item xs={12} style={{ marginTop: 20 }}>
                <Grid item xs={8}>
                    {/*<FormControlLabel label="Is Approved" style={{ color: "rgb(33 42 52)" }} />*/}
                    <Btn color="secondary" icon={<CloudUpload />} variant="outlined" component="label">
                        Select Company Logo
                        <input type="file" hidden
                         onChange={files =>{
                          const file = files.target.files[0];
                          const response = isValidFile(file);
                          if (response.isValid) {
                            handleLogoFile(file)
                          } else {
                            dispatch(MetaActions.errorToast(response.message));
                          }
                         }} accept={supportedAvatarFiles.map(a => a.mime).join(",")}
                        />
                    </Btn>
                    {logoImage && (<img src={logoImage} className="respo" style={{ margin: 10, width: "200px" }} />)}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

WorkerDetailsSection.defaultProps = {
    selectedLocation: null,
};

export default WorkerDetailsSection;
