import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, displayLocalDateTime, formattedTimeWithOffset } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import PaymentStatus from "./PaymentStatus";
import { find, isEmpty } from "lodash";
import { weekDaysList } from "utils/constant";
import { ManagerRoleLabel } from "helpers/constants";
import moment from "moment";
import { Chip } from "@mui/material";
import TotalBreakUps from "components/TotalBreakups/TotalBreakUps";
import TotalPayableBreakups from "components/TotalBreakups/TotalPayableBreakups";

const useStyles = makeStyles(({ spacing, palette }) => ({
    container: {
        paddingLeft: spacing(3),
        "@media (max-width: 991px)": {
            paddingLeft: 0,
            paddingTop: spacing(3)
        },
    },
    totalLine: {
        background: palette.grey[500],
        marginBottom: spacing(2),
        width: "50%",
        height: "1px",
    },
    billing: {
        "@media (max-width: 600px)": {
            marginTop: spacing(2)
        },
    },
    paymentStatusContainer: {
        paddingTop: spacing(2),
    },
    tagChip: {
        marginRight: '5px',
        marginBottom: '5px'
    },
    RecurringSection: {
        display: 'flex',
        '& .button': {
            display: 'flex',
            marginTop: '5px',
            marginBottom: '5px',
            '& .break': {
                borderRadius: '10px',
                padding: '2px 8px',
                border: '1px solid',
                color: '#00a25b',
                background: 'rgb(0 162 91 / 10%)',
                marginRight: '10px'
            },
            '& .recurring': {
                borderRadius: '10px',
                padding: '2px 8px',
                border: '1px solid',
                color: '#0074c8',
                background: 'rgb(0 116 200 / 10%)',
            }
        }
    },
}));

function JobInfo() {
    const classes = useStyles();
    const isActive = useSelector(JobDetailSelectors.getIsActive);
    const isPaid = useSelector(JobDetailSelectors.getIsPaid);
    const isMissingShiftInfo = useSelector(JobDetailSelectors.getIsMissingShiftInfo);
    const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
    const jobEnd = useSelector(JobDetailSelectors.getJobEndUtc);
    const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);
    const isBreakRequiredForJob = useSelector(JobDetailSelectors.getIsBreakRequiredForJob);
    const breakInfo = useSelector(JobDetailSelectors.getJobBreakInfoLabel);
    const breakMins = useSelector(JobDetailSelectors.getJobBreakMins);
    const jobWorkTypeLabel = useSelector(JobDetailSelectors.getJobWorkTypeLabel);
    const jobDescription = useSelector(JobDetailSelectors.getJobDescription);
    const peopleNeeded = useSelector(JobDetailSelectors.getPeopleNeeded);
    const positionsFilled = useSelector(JobDetailSelectors.getPositionsFilled);
    const jobTotalDuration = useSelector(JobDetailSelectors.getJobDurationMins);
    const estimatedBillableMins = useSelector(JobDetailSelectors.getEstimatedBillableMins);
    const estimatedPayable = useSelector(JobDetailSelectors.getEstimatedPayable);
    const estimatedBillable = useSelector(JobDetailSelectors.getEstimatedBillable);
    const actualBillableMins = useSelector(JobDetailSelectors.getActualBillableMins);
    const actualPayable = useSelector(JobDetailSelectors.getActualPayable);
    const actualBillable = useSelector(JobDetailSelectors.getActualBillable);
    const {
        isRecurring,
        jobRecurring,
        jobManager,
        isPrivate,
        tags,
        gears,
        skills,
        isFixedTerm,
        jobSkill,
        jobGear,
        estimatedWorkerPayment,
        totalAmoutPaid,
        ...rest
    } = useSelector(JobDetailSelectors.getJobDetails);

    const duration = Math.round((jobTotalDuration / 60) * 100) / 100;
    const estimatedBillableHrs = Math.round((estimatedBillableMins / 60) * 100) / 100;
    const actualBillableHrs = Math.round((actualBillableMins / 60) * 100) / 100;
    let billableHoursLabel = "Est. Hours";
    let payableLabel = "Est. Payable";
    let billableLabel = "Est. Billable";
    let tooltipLabel = "Est. Margin";
    let billableHours = estimatedBillableHrs;
    let payable = estimatedPayable;
    let billable = estimatedBillable;

    if (isActive && !isMissingShiftInfo && !isPaid) {
        billableHoursLabel = "Total Hours *";
        payableLabel = "Total Payable *";
        billableLabel = "Total Billable *";
        tooltipLabel = "* Margin";
        billableHours = actualBillableHrs;
        payable = actualPayable;
        billable = actualBillable;
    }

    if (isActive && !isMissingShiftInfo && isPaid) {
        billableHoursLabel = "Total Hours";
        payableLabel = "Total Payable";
        billableLabel = "Total Billable";
        tooltipLabel = "Margin";
        // TODO: Change to real payroll / invoice data
        billableHours = actualBillableHrs;
        payable = actualPayable;
        billable = actualBillable;
    }

    const marginDollars = billable - payable;
    const marginPercentage = billable ? marginDollars / billable : 0;
    const marginTooltip = `${tooltipLabel}: ${formatMoney(marginDollars)} (${(
        marginPercentage * 100
    ).toFixed(2)}%)`;

    return (
        <Grid container item xs={12} md={6} className={classes.container}>
            <Grid container item xs={12} sm={7} direction="column">
                <Typography variant="subtitle1" gutterBottom>
                    {convertUTCToTimezoneWithOffset(jobStart, jobTimezone, "dddd, MMMM Do, YYYY")} <br />
                    {displayLocalDateTime(jobStart, jobTimezone, "dddd, MMMM Do, YYYY")}
                </Typography>
                <Typography variant="body1">
                    {formattedTimeWithOffset(jobStart, jobEnd, jobTimezone)}
                </Typography>
                <Typography variant="body1">
                    {duration} Hours Duration
                </Typography>
                <Typography variant="body1">
                    {jobWorkTypeLabel}
                </Typography>
                <div>
                    <div className={classes.RecurringSection}>
                        <div className="button">
                            {isBreakRequiredForJob && <div className="break">{breakInfo}</div>}
                            <div className="recurring">{isRecurring === false ? "One Time" : isFixedTerm ? "Multi Shift" : "Recurring"}</div>
                        </div>
                    </div>
                    <Typography variant="body1">
                        {isRecurring && jobRecurring && (
                            <>
                                {jobRecurring.frequency === "Daily" && (
                                    <div>Every {jobRecurring.frequencyInterval} Day </div>
                                )}
                                {jobRecurring.frequency === "Weekly" && (
                                    <div>
                                        Every{" "}
                                        {jobRecurring.frequencyInterval > 1
                                            ? jobRecurring.frequencyInterval + " "
                                            : ""}
                                        Week
                                        <div>
                                            <b>Days:</b> &nbsp;
                                            {jobRecurring.dayOrWeekDay.map((m, ind) => {
                                                const day = find(weekDaysList, { value: m });
                                                if (day) {
                                                    return <span key={ind}>{(ind != 0 ? ", " : "") + day.label}</span>;
                                                }
                                            })}
                                        </div>
                                    </div>
                                )}
                                {jobRecurring.frequency === "Monthly" && (
                                    <div>
                                        Every{" "}
                                        {jobRecurring.frequencyInterval > 1
                                            ? jobRecurring.frequencyInterval + " "
                                            : ""}
                                        Month
                                        <div>
                                            <b>Date:</b> &nbsp;
                                            {jobRecurring.dayOrWeekDay.map((m, ind) => (
                                                <span key={ind}>{(ind != 0 ? ", " : "") + m}</span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <Typography variant="body1">
                                    <b>{isFixedTerm ? "Multi Shift End" : "Recurring End"}:</b>{" "}
                                    {moment(jobRecurring.frequencyEndAt).format("dddd, MMMM Do, YYYY")}{" "}
                                </Typography>
                            </>
                        )}
                    </Typography>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Typography variant="caption" color="textSecondary" gutterBottom>
                        Description
                    </Typography>
                    <Typography variant="body1">
                        {jobDescription ? jobDescription : <i>No description</i>}
                    </Typography>
                </div>
                {((jobGear && jobGear.length > 0) || (gears && gears.length > 0)) && (
                    <div style={{ marginTop: 10 }}>
                        <Typography variant="caption" color="textSecondary" gutterBottom>
                            Required gears
                        </Typography>
                        <div style={{ marginTop: "5px" }}>
                            {(jobGear && jobGear.length) ? jobGear.map((n) => <Chip key={n.id} size="small" label={n?.gear?.gearName} className={classes.tagChip} />) : (gears && gears.map(n => <Chip key={n} size="small" label={n} className={classes.tagChip} />))}
                        </div>
                    </div>
                )
                }
                {((jobSkill && jobSkill.length > 0) || (skills && skills.length > 0)) && (
                    <div style={{ marginTop: 10 }}>
                        <Typography variant="caption" color="textSecondary" gutterBottom>
                            Suggested skills {tags && tags.length > 0 && "and tags"}
                        </Typography>
                        <div style={{ marginTop: "-10px" }}>
                            <ul style={{ paddingLeft: "20px" }}>
                                {(jobSkill && jobSkill.length) ? jobSkill.map((n) => <li key={n.id}>{n?.skill?.skillName}</li>) : (skills && skills.map((n, index) => <li key={index}>{n}</li>))}
                            </ul>
                        </div>
                        {isPrivate && (
                            <div style={{ marginTop: "-8px" }}>
                                {tags && tags.map(n => <Chip key={n} size="small" label={n} className={classes.tagChip} />)}
                            </div>
                        )}
                    </div>
                )
                }
                {jobManager &&
                    jobManager.length > 0 && (
                        <div style={{ marginTop: 10 }}>
                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                Project Roles
                            </Typography>
                            <div style={{ marginTop: "2px" }}>
                                {jobManager.map(n => (
                                    <div key={n.id}>
                                        <div>
                                            <b>{ManagerRoleLabel[n.role]}: </b>
                                            <span>
                                                {!isEmpty(n) &&
                                                    n.manager &&
                                                    `${n.manager.firstName} ${n.manager.lastName}`
                                                }
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
            </Grid>
            <Grid container item xs={12} sm={5} direction="column" alignItems="flex-end" justifyContent="space-between" className={classes.billing}>
                <Grid style={{ flex: 1 }} container item xs={5} direction="column" alignItems="flex-end">
                    <Typography variant="caption" color="textSecondary">
                        Workers
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {`${positionsFilled}/${peopleNeeded}`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {billableHoursLabel}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {`${billableHours} hrs`}
                    </Typography>
                </Grid>

                <Grid container item direction="column" justifyContent="flex-end" alignItems="flex-end">
                    <Tooltip title={marginTooltip} placement="top-end">
                        <Grid container item direction="column" justifyContent="flex-end" alignItems="flex-end">
                            <Typography variant="caption" color="textSecondary">
                                {payableLabel}
                                {!totalAmoutPaid && <span> (per worker) </span>}
                            </Typography>
                            <Typography variant="h6">
                                {formatMoney(totalAmoutPaid || estimatedWorkerPayment?.grossAmount || 0)}
                            </Typography>
                        </Grid>
                    </Tooltip>
                    {!totalAmoutPaid && <TotalPayableBreakups breakups={estimatedWorkerPayment} />}
                    <div className={classes.totalLine} />
                    <Tooltip title={marginTooltip} placement="top-end">
                        <Grid container item direction="column" justifyContent="flex-end" alignItems="flex-end">
                            <Typography variant="caption" color="textSecondary">
                                {billableLabel}
                            </Typography>
                            <Typography variant="h4">{formatMoney(rest.calculateEmployerBillDetail?.total)}</Typography>
                            <Typography variant="caption" color="textSecondary">
                                * excl. regional calculations
                            </Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
                <TotalBreakUps hideInfo={Boolean(totalAmoutPaid)} breakups={rest.calculateEmployerBillDetail} peopleNeeded={peopleNeeded} />
                <PaymentStatus className={classes.paymentStatusContainer} />
            </Grid>
        </Grid>
    );
}

export default JobInfo;
