import React from "react";
import MainContent from "components/Containers/MainContent";
import { Add } from "@mui/icons-material";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import ProvinceWisePayTable from "./components/ProvinceWisePayTable";
import { actions as ProvinceWisePayActions, selectors as ProvinceWisePaySelectors } from "store/modules/provinceWisePay";
import AddProvinceWisePayDialog from "./components/AddProvinceWisePayDialog";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    loader: {
        "& svg": {
            margin: `0px !important`,
            marginTop: `0px !important`
        }
    }
}));

function ProvinceWisePay() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectedDocumentId = useSelector(ProvinceWisePaySelectors.getIsDeleteProvinceWisePayDialog);
    const isDeleting = useSelector(ProvinceWisePaySelectors.getIsDeleteLoading);

    const handleAddDocument = () => {
        dispatch(ProvinceWisePayActions.setIsAddProvinceWisePayDialog(true));
    };

    const hideDeleteDialog = () => {
        dispatch(ProvinceWisePayActions.setIsDeleteProvinceWisePayDialog(false));
    };

    const handleDeleteDocument = () => {
        dispatch(ProvinceWisePayActions.deleteProvinceWisePay(selectedDocumentId));
    };

    return (
        <>
            <MainContent>
                <Button
                    style={{ alignSelf: "flex-end", marginBottom: 24 }}
                    label="Add"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={handleAddDocument}
                />
                <ProvinceWisePayTable />
            </MainContent>
            <AddProvinceWisePayDialog />
            <Dialog
                open={Boolean(selectedDocumentId)}
                title="Are you sure you want to delete this Rule?"
                onClose={hideDeleteDialog}
                onConfirm={handleDeleteDocument}
                onCancel={hideDeleteDialog}
                alertType="warning"
                disabled={isDeleting}
                icon="help_outline"
                confirmText={isDeleting ? <CircularProgress className={classes.loader} size={18} /> : "Yes"}
            />
        </>
    )
}

export default ProvinceWisePay;
