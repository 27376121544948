import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(({ palette, shape, transitions, breakpoints, spacing, typography }) => ({
    table: {
        "&.table .data-row td": {
            padding: spacing(1)
        }
    },
    weekDayList: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing(0.25),
        maxWidth: 150,
        width: 150,
        flexWrap: 'wrap'
    }
}));
