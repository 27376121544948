import { AddCircleOutline, FilterAltOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles'
import { Chip, Button as MuiButton, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import DefaultTextFilter from './DefaultTextFilter';
import Button from 'components/Button/Button';
import ReactTable from 'components/ReactTable';
import { useDispatch, useSelector } from "react-redux";
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementAction } from "store/modules/additionalRequirements";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import history from 'utils/history';
import ColumnActionMenu from './ColumnActionMenu';
import LocationFilter from './LocationFilter';

const useStyles = makeStyles(theme => ({
    filterConatiner: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    group: {
        display: 'flex',
        alignItems: 'center',
    },
    advanceFilter: {
        borderRadius: 30,
        background: theme.palette.background.paper,
        padding: '7px 15px'
    },
    ActiveChip: {
        background: "#4caf50",
        color: "#fff"
    },
    InactiveChip: {
        background: theme.customPalette.red[500],
        color: "#fff"
    },
    groups: {
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'nowrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
        marginRight: theme.spacing(2)
    },
    Consent: {
        backgroundColor: '#F1EEFF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Document: {
        backgroundColor: '#E5F1FF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Validation: {
        backgroundColor: '#FFE5F6',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    masterName: {
        fontSize: 12,
        color: '#3F454D',
        fontWeight: 500,
        lineHeight: '14.06px'
    },
    masterDate: {
        color: '#6B6F75',
        fontSize: 10
    },
    summary: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        textAlign: 'left',
        color: '#6B6F75'
    }
}));

export default function AssignedEmployerList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const data = useSelector((state) => AdditionalRequirementSelector.getAssignedEmployerData(state, { part: "data" }));
    const pageFilter = useSelector((state) => AdditionalRequirementSelector.getAssignedEmployerData(state, { part: "filter" }));
    const pageSort = useSelector((state) => AdditionalRequirementSelector.getAssignedEmployerData(state, { part: "sort" }));
    const PaginationData = useSelector(AdditionalRequirementSelector.getAssignedEmployerListPagingData);
    const params = useParams();

    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        dispatch(AdditionalRequirementAction.setAssignedEmployerListSort(sortBy));
        dispatch(AdditionalRequirementAction.setAssignedEmployerListFilter(filters));
        dispatch(AdditionalRequirementAction.changeAssignedEmployerListPageSize(pageSize));
        dispatch(AdditionalRequirementAction.fetchAssignedEmployerTableData(pageIndex, window.atob(params?.id)));
    }, [params?.id]);

    const handleRedirectToNewAssign = () => {
        history.push(`/additional-requirements/assign/${params?.id}`)
    };

    return (
        <div>
            <div className={classes.filterConatiner}>
                {/* <DefaultTextFilter /> */}
                <div className={classes.group}>
                    {/* <MuiButton className={classes.advanceFilter} color="secondary" variant="outlined" startIcon={<FilterAltOutlined />}>
                        Advanced Filter
                    </MuiButton> */}
                    <Button
                        onClick={handleRedirectToNewAssign}
                        label="Add New Employer"
                        icon={<AddCircleOutline />}
                        alignIcon="left"
                        style={{ paddingRight: 0 }}
                    />
                </div>
            </div>
            <ReactTable
                classes="-highlight"
                columns={[
                    {
                        Header: "#",
                        id: "selectedColumns",
                        disableFilters: true,
                        disableSortBy: true,
                        collapse: true,
                        headerStyle: {
                            textAlign: 'center'
                        },
                        style: {
                            textAlign: 'center'
                        },
                        accessor: ({ id }) => id,
                        Cell: ({ row: { index }, state: { pageIndex, pageSize } } = { row: {}, state: {} }) => {
                            return (<span>{(index + 1) + ((pageIndex) * pageSize)}</span>)
                        }
                    },
                    {
                        Header: "Employer",
                        id: "companyName",
                        disableFilters: false,
                        disableSortBy: true,
                        accessor: ({ companyName }) => companyName,
                        Cell: ({ row: { original } }) => {
                            return (
                                <div>
                                    <Typography className={classes.masterName}>{original?.employer?.companyName}</Typography>
                                </div>
                            )
                        }
                    },
                    {
                        Header: "Location",
                        id: "city",
                        disableFilters: false,
                        disableSortBy: true,
                        accessor: ({ employer }) => employer?.city,
                        Cell: ({ row: { original } }) => {
                            return (
                                <div className={classes.groups}>
                                    {original?.employer?.city ? <Chip label={`${original?.employer?.city}`} /> : '-'}
                                </div>
                            )
                        },
                        Filter: LocationFilter
                    },
                    {
                        Header: "Work Type",
                        id: "label",
                        disableFilters: false,
                        disableSortBy: true,
                        accessor: ({ workType }) => workType?.label,
                        Cell: ({ row: { original } }) => {
                            return (
                                <div className={classes.groups}>
                                    <Chip label={original?.workType?.label || '-'} />
                                </div>
                            )
                        }
                    },
                    {
                        Header: <span style={{ whiteSpace: 'nowrap', paddingRight: 16 }}>Is Mandatory</span>,
                        id: "isMandatory",
                        disableFilters: true,
                        disableSortBy: true,
                        accessor: ({ isMandatory }) => isMandatory,
                        Cell: ({ row: { original } }) => {
                            return (
                                <div>
                                    {original.isMandatory ? "Yes" : "No"}
                                </div>
                            );
                        },
                        collapse: Boolean(data?.length),
                    },
                    {
                        Header: "Created On",
                        id: "createdAt",
                        disableFilters: true,
                        disableSortBy: true,
                        accessor: ({ createdAt }) => createdAt,
                        Cell: ({ row: { original } }) => <Typography className={classes.summary}>{moment(original?.createdAt).format("DD MMM, 'YY")}</Typography>
                    },
                    {
                        Header: "Validity",
                        id: "validity",
                        disableFilters: true,
                        disableSortBy: true,
                        accessor: ({ validityValue }) => validityValue,
                        Cell: ({ row: { original } }) => <Typography className={classes.summary}>{original?.validityType === 'date' ? moment(original?.validityValue).format("DD/MM/YYYY") : original?.validityValue + (original?.validityValue <= 1 ? ' day' : ' days')}</Typography>
                    },
                    {
                        Header: "Actions",
                        id: "actions",
                        disableSortBy: true,
                        disableFilters: true,
                        collapse: true,
                        Cell: ({ row: { original } }) => {
                            const { id } = original;
                            return (
                                <ColumnActionMenu id={id} />
                            );
                        },
                    },
                ]}
                fetchData={fetchData}
                data={data}
                loading={PaginationData.isLoading}
                pageCount={PaginationData.totalCount}
                currentPage={PaginationData.currentPage}
                defaultPageSize={PaginationData.pageSize}
                defaultFilter={pageFilter}
                defaultSort={pageSort}
            />
        </div>
    )
}
