/* eslint-disable no-constant-condition */
import React from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { FormControl, Grid, Switch, TextField } from '@mui/material'
import WYSIWYGEditor from 'views/AdditionalRequirements/components/Editor';
import FormRow from 'views/AdditionalRequirements/components/FormRow';
import GroupsDropdown from 'views/AdditionalRequirements/components/GroupsDropdown';
import { makeStyles } from '@mui/styles';
import { selectors as Selectors, actions as Actions } from 'store/modules/additionalRequirements';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: 'fit-content',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: ({ isEditing }) => !isEditing ? '#F5F5F5' : '#FFF',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    },
    root: {
        '& .MuiInputBase-input.Mui-disabled': {
            background: '#F5F5F5',
        },
        '& label': {
            color: '#000000 !important',
        },
        '& input': {
            '&::placeholder': {
                color: '#000000 !important',
                webkitTextFillColor: '#000000 !important',
            },
            WebkitTextFillColor: '#000000 !important',
            color: '#000000 !important',
            textOverflow: 'ellipsis'
        }
    },
    GroupsDropdown: {
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
            background: ({ isEditing }) => !isEditing ? '#F5F5F5' : null,
        }
    }
}));

export default function DocumentDetails() {
    const isEditing = useSelector(Selectors.getIsEditing);
    const classes = useStyles({ isEditing });
    const formData = useSelector(Selectors.getFormData);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { target: { value, name } } = event;
        if (name === 'isConsent') {
            const { target: { checked } } = event;
            dispatch(Actions.setAdditionalRequirementForm(name, checked));
            return;
        }
        dispatch(Actions.setAdditionalRequirementForm(name, value?.slice(0, 250)));
    };

    const handleChangeExpiry = (event) => {
        const { target: { name, checked } } = event;
        dispatch(Actions.setAdditionalRequirementForm(name, checked));
    };

    const handleGroupChange = (value) => {
        dispatch(Actions.setAdditionalRequirementForm('groupNames', value));
    };

    return (
        <div className={classes.root}>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} item xs={12}>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                disabled={!isEditing}
                                className={classes.fullWidth}
                                id="name"
                                name="name"
                                label="Document Name - English (For Talent)*"
                                placeholder="Document name"
                                margin="none"
                                fullWidth
                                value={formData?.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                disabled={!isEditing}
                                className={classes.fullWidth}
                                id="frenchName"
                                name="frenchName"
                                label="Document Name - French (For Talent)*"
                                placeholder="Nom du document"
                                margin="none"
                                fullWidth
                                value={formData?.frenchName}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20, marginBottom: 8, zIndex: 1051, position: 'relative' }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Add this Validation in the Group (For Internal use)</div>
                    <GroupsDropdown
                        fullWidth
                        variant="outlined"
                        disabled={!isEditing}
                        id="groupNames"
                        className={classes.GroupsDropdown}
                        name="groupNames"
                        placeholder="Select or Type & enter to add new"
                        value={formData?.groupNames}
                        onChange={handleGroupChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <TextField
                        disabled={!isEditing}
                        className={classes.fullWidth}
                        id="description"
                        name="description"
                        label="Document Description - English (For Talent)*"
                        placeholder="Use this section to add description"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={formData?.description}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 40 }}>
                <FormControl fullWidth>
                    <TextField
                        disabled={!isEditing}
                        className={classes.fullWidth}
                        id="frenchDescription"
                        name="frenchDescription"
                        label="Document Description - French (For Talent)*"
                        placeholder="Utilisez cette section pour ajouter une description"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={formData?.frenchDescription}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 20 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>
                        Does the document have an expiry date?
                    </div>
                    <div className={classes.box}>
                        <div style={{ display: 'flex', alignItems: 'center' }} className={classes.label}>
                            <span style={{ fontWeight: !formData?.isDocHaveExpiry ? 'bold' : 'normal' }}>No </span>
                            <Switch
                                name={'isDocHaveExpiry'}
                                id={'isDocHaveExpiry'}
                                disabled={!isEditing}
                                onChange={handleChangeExpiry}
                                checked={formData?.isDocHaveExpiry}
                                inputProps={{ 'aria-label': 'Switch demo' }} />
                            <span style={{ fontWeight: formData?.isDocHaveExpiry ? 'bold' : 'normal' }}>Yes</span>
                        </div>
                    </div>
                </FormControl>
            </FormRow>
        </div>
    )
}
