import moment from "moment-timezone";
import { find, cloneDeep } from 'lodash';
import _moment from "moment";
import { bcHolidays } from "./constant";

export function calcIsNextDayEnd(startMoment, endMoment) {
    // prettier-ignore
    const startMinutes = (startMoment.hour() * 60) + startMoment.minute();
    // prettier-ignore
    const endMinutes = (endMoment.hour() * 60) + endMoment.minute();
    return endMinutes < startMinutes;
}

export function formattedStartDate(start) {
    return moment(start).format("dddd, MMMM Do, YYYY");
}

export function formattedTimeSpan(start, end) {
    return `${moment(start).format("h:mm a")} - ${moment(end).format("h:mm a")}`;
}

export function jobRequiresBreak(start, end) {
    const startMoment = moment.isMoment(start) ? start.clone() : moment(start);
    const endMoment = moment.isMoment(end) ? end.clone() : moment(end);
    // Greater than 5 hours *require break
    return endMoment.diff(startMoment, "minutes") > 60 * 5;
}

export function isWithinSuspensionTimeframe(start) {
    const startMoment = moment.isMoment(start) ? start.clone() : moment(start);
    const diff = startMoment.diff(moment(), "minutes");
    return diff < 60 * 10 && diff > 0;
}

export function formatDurationFromMins(mins, options = {}) {
    const opts = {
        hourLabel: "hours",
        minuteLabel: "minutes",
        separator: ",",
        ...options,
    };

    if (mins === 0) {
        return `0 ${opts.minuteLabel}`;
    }

    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;

    let formatted = "";
    if (hours > 0) {
        formatted += `${Math.round(hours * 100) / 100} ${opts.hourLabel}`;
    }

    if (minutes > 0) {
        if (hours > 0) {
            formatted += `${opts.separator} `;
        }
        formatted += `${Math.round(minutes * 100) / 100} ${opts.minuteLabel}`;
    }

    return formatted;
}
export function getTimezoneAbbreviation(timezone) {
    return moment()
        .tz(timezone)
        .format("z");
}
export function getUTCOffset(timezone) {
    var now = moment.utc();
    var job_tz_offset = moment(now)
        .tz(timezone)
        .utcOffset();
    var selected_tz_offset = moment(now)
        .tz(getTimeZone())
        .utcOffset();
    const diff_tz = (job_tz_offset - selected_tz_offset) / 60;
    // const offset = diff_tz === 0 ? '' : ` (${(diff_tz<0?diff_tz:"+"+diff_tz)})`;
    return diff_tz;
}
export function formattedTimeWithOffset(start, end, timezone) {
    timezone = timezone || getTimeZone();
    const offset = getUTCOffset(timezone);
    if (offset === 0) {
        return `${moment
            .utc(start, null)
            .tz(timezone)
            .format("h:mm a")} - ${moment
                .utc(end, null)
                .tz(timezone)
                .format("h:mm a")} ${moment(start)
                    .tz(timezone)
                    .format("z")}`;
    }
    return `${moment
        .utc(start, null)
        .tz(timezone)
        .format("h:mm a")} - ${moment
            .utc(end, null)
            .tz(timezone)
            .format("h:mm a")} ${moment(start)
                .tz(timezone)
                .format("z")} (${moment
                    .utc(start, null)
                    .tz(getTimeZone())
                    .format("h:mm a")} - ${moment
                        .utc(end, null)
                        .tz(getTimeZone())
                        .format("h:mm a")} ${moment(start)
                            .tz(getTimeZone())
                            .format("z")})`;
}
export function convertUTCToTimezoneWithOffset(utcDate, timezone, format = "MMM Do h:mm a", hideTimezone = false) {
    timezone = timezone || getTimeZone();
    if (utcDate) {
        return `${moment
            .utc(utcDate, null)
            .tz(timezone)
            .format(format)} ${hideTimezone ? "" : moment(utcDate)
                .tz(timezone)
                .format("z")}`;
    }
    return "--";
}
export function convertUTCToLocal(utcDate, format = null) {
    // const local = _moment(utcDate).local().format(format);
    if (format) {
        return moment
            .utc(utcDate, null)
            .tz(getTimeZone())
            .format(format);
    }
    return moment.utc(utcDate, null).tz(getTimeZone());
}
export function convertUTCToTimezone(utcDate, timezone, format = null) {
    timezone = timezone || getTimeZone();
    // const local = moment.utc(utcDate, null).tz(timezone).format(format);
    // return local;
    if (format) {
        return moment
            .utc(utcDate, null)
            .tz(timezone)
            .format(format);
    }
    return moment.utc(utcDate, null).tz(timezone);
}
export function displayLocalDateTime(utcDate, timezone, format = null,extraDate="") {
    timezone = timezone || getTimeZone();
    if (format) {
        if (
            moment
                .utc(utcDate, null)
                .tz(timezone)
                .format(format) ===
            moment
                .utc(utcDate, null)
                .tz(getTimeZone())
                .format(format)
        ) {
            return "";
        }
        return (
            "(" +
            moment
                .utc(utcDate, null)
                .tz(getTimeZone())
                .format(format) +
            " " +
            (extraDate?` - ${extraDate} `:"") +
            getTimezoneAbbreviation(getTimeZone()) +
            ")"
        );
    }
    return "";
}
export function convertLocalToUTC(localDate) {
    const date = _moment.utc(localDate);
    // const date = moment.tz(localDate, "America/Los_Angeles");
    return date.utc();
}

export function convertLocalToTimezone(localDate, timezone) {
    const date = _moment.utc(localDate);
    // const date = moment.tz(localDate, "America/Los_Angeles");
    return convertUTCToTimezone(date.utc(), timezone);
}

export function getTimeZone() {
    // if (localStorage.getItem('timezone')) {
    //   return localStorage.getItem('timezone');
    // }
    return moment.tz.guess();
}
export function setTimeZone(value) {
    return localStorage.setItem("timezone", value);
}

export const isHoliday = (week) => {
    const holiday = find(bcHolidays, n => moment().week(week + 0).isSame(n.date, "week"))
    if (holiday) {
        return `${holiday.name[0].text} - ${moment(holiday.date).format(
            "dddd MMM Do, YYYY"
        )}`;
    }
    return false;
}

export const getTotalTime = (now, then) => {
    const ms = moment(moment(now).format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss").diff(moment(moment(then).format("DD/MM/YYYY HH:mm:ss"), "DD/MM/YYYY HH:mm:ss"));
    const d = moment.duration(ms);
    return Math.floor(d.asHours());
};

export const getDaysOfWeekBetweenDates = (sDate, eDate) => {
    const deepClonedStartDate = cloneDeep(sDate)
    const deepClonedEndDate = cloneDeep(eDate)

    if (!deepClonedStartDate || !deepClonedEndDate) return;
    const startDate = moment(moment(deepClonedStartDate).format("YYYY/MM/DD"))
    const endDate = moment(moment(deepClonedEndDate).format("YYYY/MM/DD"));

    const daysOfWeek = [];

    let i = 1;

    while (i <= 7 && startDate <= endDate) {
        daysOfWeek.push(startDate.day() + 1);
        startDate.add(1, "day");
        i++;
    }

    return daysOfWeek;
};

export const getDaysOfMonthsBetweenDates = (sDate, eDate) => {
    const deepClonedStartDate = cloneDeep(sDate)
    const deepClonedEndDate = cloneDeep(eDate)
    if (!deepClonedStartDate || !deepClonedEndDate) return;
    const startDate = moment(deepClonedStartDate)
    const endDate = moment(deepClonedEndDate);

    const daysOfMonths = [];

    let i = 1;

    while (i <= 31 && startDate < endDate) {
        daysOfMonths.push(startDate.date());
        startDate.add(1, "day");
        i++;
    }

    return daysOfMonths;
};

export const getDaysInTwoDates = (fromDate, toDate) => moment(toDate).diff(moment(fromDate), "days") + 1;

export const getWeeksInTwoDates = (fromDate, toDate) => {
    const diff = toDate.diff(fromDate);
    const diffInWeeks = moment.duration(diff).as('weeks');
    return Math.ceil(diffInWeeks) + 0;
}

export const getMonthsInTwoDates = (fromDate, toDate) => {
    const diff = toDate.diff(fromDate);
    const diffInMonths = moment.duration(diff).as('months')
    return Math.ceil(diffInMonths) + 0;
}

export function formattedStartEndTimeWithOffset(start, end, timezone) {
    timezone = timezone || getTimeZone();
    const offset = getUTCOffset(timezone);
    if (offset === 0) {
        return `${moment
            .utc(start, null)
            .tz(timezone)
            .format("h:mm a")} - ${moment
                .utc(end, null)
                .tz(timezone)
                .format("h:mm a")} ${moment()
                    .tz(timezone)
                    .format("z")}`;
    }
    return `${moment
        .utc(start, null)
        .tz(timezone)
        .format("h:mm a")} - ${moment
            .utc(end, null)
            .tz(timezone)
            .format("h:mm a")} ${moment()
                .tz(timezone)
                .format("z")}`;
}

export const convertMinutesIntoHoures = (minutes) => {
    minutes = parseInt(minutes);
    const min = minutes % 60;
    return `${Math.floor(minutes / 60)} hrs ${min ? min + " min" : ''}`;
}

export const convertTotalMinutesIntoHrAndMin = (minutes, type) => {
    minutes = parseInt(minutes);
    const data = {
        hour: Math.floor(minutes / 60),
        min: minutes % 60
    };
    if (type)
        return data[type];
    return `${Math.floor(minutes / 60)} hr${(minutes ? ` ${minutes % 60} min` : '')}`
};

export const convertHrAndMinIntoTotalMinutes = (hours, mins) => (parseInt(hours) * 60) + parseInt(mins);

export const getWeekDates = (week, year) => {
    var date = new Date(year, 0, 1);
    var day = date.getDay();

    var firstSaturday;
    if (day === 6) {
      firstSaturday = new Date(year, 0, 1);
    } else {
      firstSaturday = new Date(new Date(year, 0, 1).setDate(7 - day));
    }
    var weekStart, weekEnd;
    if (week == 1) {
      weekStart = date;
      weekEnd = firstSaturday;
    } else if (week == 53) {
      weekStart = new Date(
        firstSaturday.getTime() + (week - 2) * 7 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000,
      );
      weekEnd = new Date(year, 11, 31);
    } else {
      weekStart = new Date(
        firstSaturday.getTime() + (week - 2) * 7 * 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000,
      );
      weekEnd = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);
    }
    return { start: weekStart, end: weekEnd };
  };

export const SplitTime = numberOfHours => {
    var remainder = numberOfHours % 24;
    var hours = Math.floor(remainder);
    var minutes = Math.round(60 * (remainder - hours));
    return { Hours: hours || 0, Minutes: minutes || 0 };
};
