/* eslint-disable import-helpers/order-imports */
import React from 'react'
import Dialog from 'components/Dialog/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { actions as overTimeSpecActions, selectors as overTimeSpecSelectors } from "store/modules/overtimeSpecs";
import { DialogActions, Grid, Button, Switch, FormControlLabel, TextField, Divider, CircularProgress, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import Danger from 'components/Typography/Danger';
import { convertHrAndMinIntoTotalMinutes, convertTotalMinutesIntoHrAndMin } from 'utils/time';
import NumberFormat from "react-number-format";
import { weekdays } from "utils/constant";
import TaxInput from "./TaxInput";

const useStyles = makeStyles(({ spacing, ...theme }) => ({
    modal: {
        padding: spacing(6),
        paddingBottom: spacing(1),
        margin: spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },
    textField: {
        marginBottom: spacing(0),
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    durationInput: {
        marginTop: spacing(0),
    },
    durationInputGrid: {
        paddingTop: `${spacing(0)} !important`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        // overflowY: "auto",
        // overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
    switch: {
        padding: 0,
        width: 50,
        height: 26,
        marginLeft: spacing(1),
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(25px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    row: {
        textAlign: "left"
    },
    gridContainer: {
        marginTop: spacing(1)
    },
    formControlLabel: {
        marginLeft: spacing(0),
        "& .MuiTypography-root": {
            color: theme.palette.text.primary,
            fontWeight: "bold"
        }
    },
    divider: {
        marginTop: spacing(2)
    },
    warning: {
        color: theme.palette.warning.main
    }
}));

export default function EditDialog() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isOpenEditDialog = useSelector(overTimeSpecSelectors.getIsOpenEditDialog);
    const isLoading = useSelector(overTimeSpecSelectors.getIsUpdateOvertimeSpecsLoading);
    const data = useSelector(overTimeSpecSelectors.getOvertimeSpecsUpdateData);
    const ref = useRef();
    const keys = [
        "minDailyOTMinutes",
        "maxDailyOTMinutes",
        "minDailyDTMinutes",
        "maxDailyDTMinutes",
        "minWeeklyOTMinutes",
        "maxWeeklyOTMinutes",
    ];
    const handleClose = () => dispatch(overTimeSpecActions.colseEditDialog());

    const handleToggleOTRule = async (e, setFieldValue) => {
        const { currentTarget } = e;
        ref?.current?.handleChange?.(e);
        switch (currentTarget?.name) {
            case "isDailyOT": {
                if (!currentTarget.checked) {
                    await setFieldValue("isDailyDT", false, true);
                }
                await ref?.current?.setFieldTouched("dailyOTRate", currentTarget.checked, !currentTarget.checked);
                break;
            }
            case "isDailyDT": {
                if (currentTarget.checked) {
                    await setFieldValue("minDailyDTHours", ref?.current?.values?.maxDailyOTHours, true);
                    await setFieldValue("minDailyDTMinutes", ref?.current?.values?.maxDailyOTMinutes, true);

                }
                break;
            }
        }
    };

    const FieldErrors = ({ errors, touched, minDurationErrorKey, maxDurationErrorKey, rateErrorKey, label, maxLabel, customError, customMaxError }) => (
        <>
            {Boolean(errors[rateErrorKey] && touched[rateErrorKey] && errors[rateErrorKey]) && <Grid item xs={12} textAlign="left">
                <Danger>{errors[rateErrorKey] && touched[rateErrorKey] && errors[rateErrorKey]}</Danger>
            </Grid>}
            {[minDurationErrorKey, maxDurationErrorKey].map(key => (
                <>
                    {Boolean(errors[key + "Hours"] && touched[key + "Hours"] && errors[key + "Hours"]) && <Grid item xs={12} textAlign="left">
                        <Danger>{errors[key + "Hours"] && touched[key + "Hours"] && errors[key + "Hours"]}</Danger>
                    </Grid>}
                    {Boolean(errors[key + "Minutes"] && touched[key + "Minutes"] && errors[key + "Minutes"]) && <Grid item xs={12} textAlign="left">
                        <Danger>{errors[key + "Minutes"] && touched[key + "Minutes"] && errors[key + "Minutes"]}</Danger>
                    </Grid>}
                </>
            ))}
            {(customError || customMaxError) && <Grid item xs={12} textAlign="left">
                <Danger>{`Minimum ${label} time must be ${customMaxError ? "greater" : "less"} than Maximum ${customMaxError ? maxLabel : label} time`}</Danger>
            </Grid>}
        </>
    )

    const handleHoursChange = async (e) => {
        const { currentTarget: { value, name } } = e;
        ref?.current?.handleChange?.(e);
        if (name === "minDailyDTHours") await ref?.current?.setFieldValue(name.replace("minDailyDT", "maxDailyOT"), value, true);
        if (name === "maxDailyOTHours") await ref?.current?.setFieldValue(name.replace("maxDailyOT", "minDailyDT"), value, true);
        if (value >= 24) await ref?.current?.setFieldValue(name.replace("Hours", "Minutes"), 0, true);
    };

    const handleMinuteChange = async (e) => {
        const { currentTarget: { value, name } } = e;
        ref?.current?.handleChange?.(e);
        if (name === "minDailyDTMinutes") await ref?.current?.setFieldValue(name.replace("minDailyDT", "maxDailyOT"), value, true);
        if (name === "maxDailyOTMinutes") await ref?.current?.setFieldValue(name.replace("maxDailyOT", "minDailyDT"), value, true);
    };

    const handleGSTChange = async (gst) => {
        await ref?.current?.setFieldValue("gst", gst || 0, true);
    }

    const handleSubmit = (values) => {
        const data = {
            id: values.id,
            region: values.region,
            isDailyOT: values.isDailyOT,
            gst: values.gst,
            minimumWage: +values.minimumWage,
            dailyOTRate: values.dailyOTRate,
            isDailyDT: values.isDailyDT,
            dailyDTRate: values.dailyDTRate,
            isWeeklyOT: values.isWeeklyOT,
            weeklyOTRate: values.weeklyOTRate,
            firstDayOfWeek: values.firstDayOfWeek,
            ...keys.reduce((p, key) => {
                console.log(key, "key")
                if (!values.isDailyOT && key.includes("DailyOT")) return { ...p, [key]: 0 };
                if (!values.isDailyDT && key.includes("DailyDT")) return { ...p, [key]: 0 };
                if (!values.isWeeklyOT && key.includes("WeeklyOT")) return { ...p, [key]: 0 };

                return {
                    ...p,
                    [key]: convertHrAndMinIntoTotalMinutes(values[key.replace("Minutes", "Hours")], values[key])
                };
            }, {})
        };
        dispatch(overTimeSpecActions.updateOvertimeSpecs(data));
    }

    return (
        <Dialog
            open={isOpenEditDialog}
            title={`Edit ${data && (data?.region || "")} Province Settings `}
            description={<Typography className={classes.warning}>Be Careful! Any changes here could affect the calculations and reports.</Typography>}
            onClose={handleClose}
            onConfirm={console.log}
            onCancel={handleClose}
            alertType="form"
            size="sm"
            hideActions
            modalType="editEmployer"
        >
            <React.Fragment>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="flex-start"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <Formik
                        innerRef={ref}
                        initialValues={{
                            id: data.id,
                            region: data.region,
                            gst: data.gst || 0,
                            minimumWage: data.minimumWage || 0,
                            isDailyOT: Boolean(data.isDailyOT),
                            dailyOTRate: data.dailyOTRate || 0,
                            isDailyDT: Boolean(data.isDailyDT),
                            dailyDTRate: data.dailyDTRate || 0,
                            isWeeklyOT: Boolean(data.isWeeklyOT),
                            weeklyOTRate: data.weeklyOTRate || 0,
                            firstDayOfWeek: data.firstDayOfWeek,
                            ...keys.reduce((p, key) => ({
                                ...p,
                                [key.replace("Minutes", "Hours")]: convertTotalMinutesIntoHrAndMin(data[key] || 0, "hour"),
                                [key]: convertTotalMinutesIntoHrAndMin(data[key] || 0, "min")
                            }), {}),
                        }}
                        onSubmit={handleSubmit}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                handleChange,
                                handleBlur,
                                setFieldValue
                            } = props;
                            const customErrors = {
                                otError: values.isDailyOT && (
                                    convertHrAndMinIntoTotalMinutes(values.minDailyOTHours, values.minDailyOTMinutes) >= convertHrAndMinIntoTotalMinutes(values.maxDailyOTHours, values.maxDailyOTMinutes)
                                ),
                                dtError: values.isDailyDT && (
                                    convertHrAndMinIntoTotalMinutes(values.minDailyDTHours, values.minDailyDTMinutes) >= convertHrAndMinIntoTotalMinutes(values.maxDailyDTHours, values.maxDailyDTMinutes)
                                ),
                                maxDTError: values.isDailyDT && (convertHrAndMinIntoTotalMinutes(values.minDailyDTHours, values.minDailyDTMinutes) < convertHrAndMinIntoTotalMinutes(values.maxDailyOTHours, values.maxDailyOTMinutes)),
                                weeklyOTError: values.isWeeklyOT && (
                                    convertHrAndMinIntoTotalMinutes(values.minWeeklyOTHours, values.minWeeklyOTMinutes) >= convertHrAndMinIntoTotalMinutes(values.maxWeeklyOTHours, values.maxWeeklyOTMinutes)
                                ),
                            }
                            console.log(errors, customErrors)
                            return (
                                <Form style={{ width: "100%" }}>
                                    <Grid item container direction="row" xs={12} className={classes.content}>
                                        <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    className={classes.textField}
                                                    fullWidth
                                                    margin="normal"
                                                    select
                                                    label="First day of week"
                                                    name="firstDayOfWeek"
                                                    value={values.firstDayOfWeek}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.firstDayOfWeek && touched.firstDayOfWeek && errors.firstDayOfWeek}
                                                    helperText={errors.firstDayOfWeek && touched.firstDayOfWeek && errors.firstDayOfWeek}
                                                >
                                                    <MenuItem value={"Sun"}>{weekdays.Sun}</MenuItem>
                                                    <MenuItem value={"Mon"}>{weekdays.Mon}</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TaxInput
                                                    type={"PERCENTAGE"}
                                                    name="gst"
                                                    label={"Province GST"}
                                                    id={"gst"}
                                                    fixedDecimalScale
                                                    minValue={0}
                                                    decimalScale={2}
                                                    minValueHelperText={`Minimum value is: 0%`}
                                                    maxValue={100}
                                                    maxValueHelperText={`Maximum value is: 100%`}
                                                    value={values.gst}
                                                    onChange={handleGSTChange}
                                                />
                                            </Grid>
                                            <Grid item xs={4} >
                                                <NumberFormat
                                                    className={classes.textField}
                                                    fullWidth
                                                    margin="normal"
                                                    label="Min Wage"
                                                    name="minimumWage"
                                                    value={values.minimumWage}
                                                    customInput={TextField}
                                                    mask=""
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.divider} style={{ marginTop: 28 }}><Divider /></Grid>
                                        <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                            <Grid item xs={12} className={classes.row}>
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    checked={values.isDailyOT}
                                                    control={<Switch className={classes.switch} />}
                                                    label="Daily Overtime"
                                                    labelPlacement="start"
                                                    name='isDailyOT'
                                                    onChange={(e) => handleToggleOTRule(e, setFieldValue)}
                                                />
                                            </Grid>
                                            {values.isDailyOT && <>
                                                <Grid item xs={12 / 5} >
                                                    <TextField
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Daily OT Rate"
                                                        name="dailyOTRate"
                                                        type="number"
                                                        value={values.dailyOTRate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.dailyOTRate && touched.dailyOTRate && errors.dailyOTRate}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Min Hours"
                                                        name="minDailyOTHours"
                                                        value={values.minDailyOTHours}
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minDailyOTHours && touched.minDailyOTHours && errors.minDailyOTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        disabled={values.minDailyOTHours >= 24}
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Min Minutes"
                                                        name="minDailyOTMinutes"
                                                        value={values.minDailyOTMinutes}
                                                        onChange={handleMinuteChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minDailyOTMinutes && touched.minDailyOTMinutes && errors.minDailyOTMinutes}
                                                    />
                                                </Grid>

                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="maxDailyOTHours"
                                                        label="Max Hours"
                                                        value={values.maxDailyOTHours}
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxDailyOTHours && touched.maxDailyOTHours && errors.maxDailyOTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="maxDailyOTMinutes"
                                                        label="Max Minutes"
                                                        disabled={values.maxDailyOTHours >= 24}
                                                        value={values.maxDailyOTMinutes}
                                                        onChange={handleMinuteChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxDailyOTMinutes && touched.maxDailyOTMinutes && errors.maxDailyOTMinutes}
                                                    />
                                                </Grid>
                                                <FieldErrors
                                                    errors={errors}
                                                    customError={customErrors.otError}
                                                    touched={touched}
                                                    rateErrorKey={"dailyOTRate"}
                                                    maxDurationErrorKey={"maxDailyOT"}
                                                    minDurationErrorKey={"minDailyOT"}
                                                    label="Daily OT" />
                                            </>}
                                        </Grid>
                                        <Grid item xs={12} className={classes.divider}><Divider /></Grid>
                                        <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                            <Grid item xs={12} className={classes.row}>
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    checked={values.isDailyDT}
                                                    control={<Switch className={classes.switch} />}
                                                    label="Daily Double Time"
                                                    labelPlacement="start"
                                                    name='isDailyDT'
                                                    disabled={!values.isDailyOT}
                                                    onChange={(e) => handleToggleOTRule(e, setFieldValue)}
                                                />
                                            </Grid>
                                            {values.isDailyDT && <>
                                                <Grid item xs={12 / 5}>
                                                    <TextField
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Daily DT Rate"
                                                        name="dailyDTRate"
                                                        type="number"
                                                        value={values.dailyDTRate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.dailyDTRate && touched.dailyDTRate && errors.dailyDTRate}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="minDailyDTHours"
                                                        label="Min Hours"
                                                        value={values.minDailyDTHours}
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minDailyDTHours && touched.minDailyDTHours && errors.minDailyDTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="minDailyDTMinutes"
                                                        label="Min Minutes"
                                                        disabled={values.minDailyDTHours >= 24}
                                                        value={values.minDailyDTMinutes}
                                                        onChange={handleMinuteChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minDailyDTMinutes && touched.minDailyDTMinutes && errors.minDailyDTMinutes}
                                                    />
                                                </Grid>

                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        label="Max Hours"
                                                        margin="normal"
                                                        name="maxDailyDTHours"
                                                        value={values.maxDailyDTHours}
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxDailyDTHours && touched.maxDailyDTHours && errors.maxDailyDTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="maxDailyDTMinutes"
                                                        label="Min Minutes"
                                                        disabled={values.maxDailyDTHours >= 24}
                                                        value={values.maxDailyDTMinutes}
                                                        onChange={handleMinuteChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxDailyDTMinutes && touched.maxDailyDTMinutes && errors.maxDailyDTMinutes}
                                                    />
                                                </Grid>
                                                <FieldErrors
                                                    errors={errors}
                                                    touched={touched}
                                                    rateErrorKey={"dailyDTRate"}
                                                    maxDurationErrorKey={"maxDailyDT"}
                                                    minDurationErrorKey={"minDailyDT"}
                                                    customError={customErrors.dtError}
                                                    customMaxError={customErrors.maxDTError}
                                                    label="Daily DT"
                                                    maxLabel="Daily OT" />
                                            </>}
                                        </Grid>
                                        <Grid item xs={12} className={classes.divider}><Divider /></Grid>
                                        <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                            <Grid item xs={12} className={classes.row}>
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    checked={values.isWeeklyOT}
                                                    control={<Switch className={classes.switch} />}
                                                    label="Weekly Overt Time"
                                                    labelPlacement="start"
                                                    name='isWeeklyOT'
                                                    onChange={(e) => handleToggleOTRule(e, setFieldValue)}
                                                />
                                            </Grid>
                                            {values.isWeeklyOT && <>
                                                <Grid item xs={12 / 5}>
                                                    <TextField
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Weekly OT Rate"
                                                        name="weeklyOTRate"
                                                        type="number"
                                                        value={values.weeklyOTRate}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.weeklyOTRate && touched.weeklyOTRate && errors.weeklyOTRate}
                                                    />
                                                </Grid>

                                                <Grid item xs={12 / 5}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="###"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Min Hours"
                                                        name="minWeeklyOTHours"
                                                        value={values.minWeeklyOTHours}
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minWeeklyOTHours && touched.minWeeklyOTHours && errors.minWeeklyOTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        label="Min Minutes"
                                                        name="minWeeklyOTMinutes"
                                                        disabled={values.minWeeklyOTHours >= 168}
                                                        value={values.minWeeklyOTMinutes}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.minWeeklyOTMinutes && touched.minWeeklyOTMinutes && errors.minWeeklyOTMinutes}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="###"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="maxWeeklyOTHours"
                                                        label="Max Hours"
                                                        value={values.maxWeeklyOTHours}
                                                        onChange={handleHoursChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxWeeklyOTHours && touched.maxWeeklyOTHours && errors.maxWeeklyOTHours}
                                                    />
                                                </Grid>
                                                <Grid item xs={12 / 5} >
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        format="##"
                                                        mask=""
                                                        className={classes.textField}
                                                        fullWidth
                                                        margin="normal"
                                                        name="maxWeeklyOTMinutes"
                                                        label="Max Minutes"
                                                        disabled={values.maxWeeklyOTHours >= 168}
                                                        value={values.maxWeeklyOTMinutes}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.maxWeeklyOTMinutes && touched.maxWeeklyOTMinutes && errors.maxWeeklyOTMinutes}
                                                    />
                                                </Grid>
                                                <FieldErrors
                                                    errors={errors}
                                                    touched={touched}
                                                    rateErrorKey={"WeeklyOTRate"}
                                                    customError={customErrors.weeklyOTError}
                                                    label="Weekly OT"
                                                    maxDurationErrorKey={"maxWeeklyOT"}
                                                    minDurationErrorKey={"minWeeklyOT"} />
                                            </>}

                                        </Grid>
                                    </Grid>
                                    <DialogActions className={classes.footer}>
                                        <Button disabled={isLoading} color="error" type="button" className="outline" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="submit"
                                            disabled={
                                                !dirty ||
                                                !isEmpty(errors) ||
                                                isLoading ||
                                                Object.keys(customErrors).some(key => customErrors[key])
                                            }
                                        >
                                            {isLoading ? <CircularProgress size={20} /> : "Update"}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </React.Fragment>
        </Dialog>
    )
}

const validationSchema = yup.object().shape({
    isDailyOT: yup.boolean(),
    isDailyDT: yup.boolean(),
    isWeeklyOT: yup.boolean(),
    minDailyOTHours: yup
        .number()
        .when(['isDailyOT'], {
            is: (isDailyOT) => {
                return isDailyOT;
            },
            then: (schema) =>
                schema.typeError('Min Hours is Required field')
                    .min(0, "Invalid min hours")
                    .max(24, "Min Daily OT hours must be less then 24 hours")
                    .required("Min Hours is Required field"),
        }),
    gst: yup
        .number()
        .min(0, "Invalid min gst")
        .max(100, "Max gst must be less then 100%"),
    minimumWage: yup
        .number()
        .min(0, "Invalid minimumwage"),
    maxDailyOTHours: yup
        .number().when(['isDailyOT'], {
            is: (isDailyOT) => {
                return isDailyOT;
            },
            then: (schema) =>
                schema.typeError('Max Hours is Required field')
                    .min(0, "Invalid max hours")
                    .max(24, "Max Daily OT hours must be less then 24 hours")
                    .required("Max Hours is Required field"),
        }),
    dailyOTRate: yup
        .number()
        .when(['isDailyOT'], {
            is: (isDailyOT) => {
                return isDailyOT;
            },
            then: (schema) =>
                schema.typeError('Invalid Daily OT Rate')
                    .min(0.1, "Invalid Daily OT Rate")
                    .required("Invalid Daily OT Rate"),
        }),
    minDailyDTHours: yup
        .number()
        .when(['isDailyOT', 'isDailyDT'], {
            is: (isDailyOT, isDailyDT) => {
                return isDailyOT && isDailyDT;
            },
            then: (schema) =>
                schema.typeError('Min Hours is Required field')
                    .min(0, "Invalid min hours")
                    .max(24, "Min Daily DT hours must be less then 24 hours")
                    .required("Min Hours is Required field"),
        }),

    maxDailyDTHours: yup
        .number().when(['isDailyOT', 'isDailyDT'], {
            is: (isDailyOT, isDailyDT) => {
                return isDailyOT && isDailyDT;
            },
            then: (schema) =>
                schema.typeError('Max Hours is Required field')
                    .min(0, "Invalid max hours")
                    .max(24, "Max Daily DT hours must be less then 24 hours")
                    .required("Max Hours is Required field"),
        }),
    dailyDTRate: yup
        .number()
        .when(['isDailyOT', 'isDailyDT'], {
            is: (isDailyOT, isDailyDT) => {
                return isDailyOT && isDailyDT;
            },
            then: (schema) =>
                schema.test("dailyDTRate", "Daily DT Rate must be greater than Daily OT Rate", function (dailyDTRate) {
                    if (!this.parent.isDailyDT) return true;
                    return dailyDTRate > this.parent.dailyOTRate;
                }),
        }),
    minWeeklyOTHours: yup
        .number()
        .when(['isWeeklyOT'], {
            is: (isWeeklyOT) => {
                return isWeeklyOT;
            },
            then: (schema) =>
                schema.typeError('Min Hours is Required field')
                    .min(0, "Invalid min hours")
                    .max(168, "Min Weekly OT hours must be less then 168 hours")
                    .required("Min Hours is Required field"),
        }),
    maxWeeklyOTHours: yup
        .number()
        .when(['isWeeklyOT'], {
            is: (isWeeklyOT) => {
                return isWeeklyOT;
            },
            then: (schema) =>
                schema.typeError('Max Hours is Required field')
                    .min(0, "Invalid max hours")
                    .max(168, "Max Weekly OT hours must be less then 168 hours")
                    .required("Max Hours is Required field"),
        }),
    weeklyOTRate: yup
        .number()
        .when(['isWeeklyOT'], {
            is: (isWeeklyOT) => {
                return isWeeklyOT;
            },
            then: (schema) =>
                schema.typeError('Weekly OT Rate is Required field')
                    .min(0, "Invalid Weekly OT Rate")
                    .required("Weekly OT Rate is Required field"),
        }),

    minDailyOTMinutes: yup
        .number()
        .when(['isDailyOT'], {
            is: (isDailyOT) => {
                return isDailyOT;
            },
            then: (schema) =>
                schema.typeError('Min Minutes is Required field')
                    .min(0, "Invalid min minutes")
                    .max(60, "Min Daily OT minutes must be less then 60 minutes")
                    .required("Min Minutes is Required field"),
        }),
    maxDailyOTMinutes: yup
        .number()
        .when(['isDailyOT'], {
            is: (isDailyOT) => {
                return isDailyOT;
            },
            then: (schema) =>
                schema.typeError('Max Minutes is Required field')
                    .min(0, "Invalid max minutes")
                    .max(60, "Max Daily OT minutes must be less then 60 minutes")
                    .required("Max Minutes is Required field"),
        }),
    minDailyDTMinutes: yup
        .number().when(['isDailyOT', 'isDailyDT'], {
            is: (isDailyOT, isDailyDT) => {
                return isDailyOT && isDailyDT;
            },
            then: (schema) =>
                schema.typeError('Min Minutes is Required field')
                    .min(0, "Invalid min minutes")
                    .max(60, "Min Daily DT minutes must be less then 60 minutes")
                    .required("Min Minutes is Required field"),
        }),
    maxDailyDTMinutes: yup
        .number().when(['isDailyOT', 'isDailyDT'], {
            is: (isDailyOT, isDailyDT) => {
                return isDailyOT && isDailyDT;
            },
            then: (schema) =>
                schema.typeError('Max Minutes is Required field')
                    .min(0, "Invalid max minutes")
                    .max(60, "Max Daily DT minutes must be less then 60 minutes")
                    .required("Max Minutes is Required field"),
        }),
    minWeeklyOTMinutes: yup
        .number()
        .when(['isWeeklyOT'], {
            is: (isWeeklyOT) => {
                return isWeeklyOT;
            },
            then: (schema) =>
                schema.typeError('Min Minutes is Required field')
                    .min(0, "Invalid min minutes")
                    .max(60, "Min Weekly OT minutes must be less then 60 minutes")
                    .required("Min Minutes is Required field"),
        }),
    maxWeeklyOTMinutes: yup
        .number()
        .when(['isWeeklyOT'], {
            is: (isWeeklyOT) => {
                return isWeeklyOT;
            },
            then: (schema) =>
                schema.typeError('Max Minutes is Required field')
                    .min(0, "Invalid max minutes")
                    .max(60, "Max Weekly OT minutes must be less then 60 minutes")
                    .required("Max Minutes is Required field"),
        }),
    firstDayOfWeek: yup.string().required("First day of week is required"),
});
