import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import { PaymentConstants } from "helpers/constants/index";
import isEmpty from "lodash/isEmpty";
import Dialog from "components/Dialog/Dialog";
import { formatMoney } from "utils/stringUtils";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import CurrencyInput from "./CurrencyInput";

const useStyles = makeStyles(({ spacing }) => ({
  maxBonus: {
    marginBottom: spacing(4),
  },
  inputContainer: {
    height: 50,
  },
}));

function BonusDialog(props) {
  const { open, onClose, onCancel, position } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [bonus, setValue] = useState(position.bonus || 0);
  const updatedPosition = useSelector(JobDetailSelectors.getUpdatedPosition(position.id));
  if (isEmpty(updatedPosition)) {
    return null;
  }

  const onChange = value => {
    setValue(value);
  };

  const handleClose = () => {
    dispatch(JobDetailActions.resetPositionUpdates());
    onClose();
  };

  const handleCancel = () => {
    dispatch(JobDetailActions.resetPositionUpdates());
    onCancel();
  };

  const canSave = bonus >= PaymentConstants.MIN_BONUS;
  const handleAddBonus = () => {
    // toggleAddBonus();
    dispatch(JobDetailActions.addPositionBonus(position.id, bonus));
  };
  return (
    <Dialog
      open={open}
      title={`Give ${position.fullName} a bonus`}
      description={null}
      onClose={handleClose}
      onConfirm={handleAddBonus}
      onCancel={handleCancel}
      confirmText="Add"
      alertType="question"
      icon="attach_money"
      disableEscapeKeyDown
      disableRestoreFocus
      disabled={!canSave}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography align="center" variant="body1">
            The employer <b>will not</b> be invoiced for this change.
          </Typography>
          <Typography align="center" variant="body1" className={classes.maxBonus}>
            It will be paid for by Randstad
          </Typography>
          {/* <Typography align="center" variant="body1" className={classes.maxBonus}>
            Maximum bonus amount: <b>{`${formatMoney(PaymentConstants.MAX_BONUS)}`}</b>
          </Typography> */}
        </Grid>
        <Grid container item xs={12} justifyContent="center" className={classes.inputContainer}>
          <CurrencyInput
            label="Bonus amount"
            minValue={PaymentConstants.MIN_BONUS}
            value={bonus}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

BonusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default BonusDialog;
