import { gql } from "@apollo/client";

export const BulkApproveJobApplicationRequirements = gql`
mutation BulkApproveJobApplicationRequirements($data: BulkApproveJobApplicationRequirementsInput!) {
  bulkApproveJobApplicationRequirements(data: $data)
}
`;

export const approveRejectRequirement = gql`
mutation ApproveJobApplicationRequirement($data: ApproveJobApplicationRequirementInput!) {
  approveJobApplicationRequirement(data: $data) {
    id
    jobApplicationId
    employerRequirementId
    consent
    documentUrl
    documentDate
    isApproved
    rejectionReason
    approvedBy
    createdAt
    updatedAt
  }
}
`;
