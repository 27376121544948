import _ from "lodash";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { fetchWorkTypes } from "../../posting/actions";
import { paginationSettings } from "../constants";
import {
    setWorkerTransportationPreferencesMutation,
    setWorkerWorkTypePreferencesMutation,
    UpdateWorkerEmailMutation,
    UpdateWorkerProfileMutation,
    UploadDocumentForVerification,
    uploadFileToS3,
    workerCustomFileUploadToS3
} from "../graphql/mutations";
import ActivateWorkerMutation from "../graphql/mutations/activate-worker";
import AddWorkerNoteMutation from "../graphql/mutations/add-worker-note";
import AddWorkerTagsMutation from "../graphql/mutations/add-worker-tags";
import BanWorkerMutation from "../graphql/mutations/ban-worker";
import DeactivateWorkerMutation from "../graphql/mutations/deactivate-worker";
import PreferWorkerMutation from "../graphql/mutations/prefer-worker";
import RemoveWorkerMutation from "../graphql/mutations/remove-worker";
import RemoveWorkerTagsMutation from "../graphql/mutations/remove-worker-tags";
import SuspendWorkerMutation from "../graphql/mutations/suspend-worker";
import UnsuspendWorkerMutation from "../graphql/mutations/unsuspend-worker";
import UnverifyWorkerMutation from "../graphql/mutations/unverify-worker";
import VerifyWorkerMutation from "../graphql/mutations/verify-worker";
import { findWorkerEtransfersQuery, getTransportationListQuery } from "../graphql/queries";
import FetchWorkerBannedFromQuery from "../graphql/queries/fetch-employer-exceptions";
import FetchWorkerNotesQuery from "../graphql/queries/fetch-notes";
import FetchPositionsQuery from "../graphql/queries/fetch-positions";
import FetchWorkerQuery from "../graphql/queries/fetch-worker";
import FetchWorkerDCTrancation from "../graphql/queries/fetch-worker-dc-transaction";
import { getAlternateDocDialog, getApproveDocumentDilog, getApproveRequirementDilog, getPositionsPaginationData, getRejectConsentDilog, getRejectDocumentDilog, getRejectedAdditionalDocDialog, getRequirementWiseApproveAllDialog, getResubmissionDialog, getUploadApproveDialog, getWorkerBannedFromPaginationData, getWorkerDetails, getWorkerNotesPaginationData } from "../selectors";
import types from "../types";
import { sanitizePayload } from "./helpers";
import UploadWorkPermitDocMutation from "../graphql/mutations/upload-work-permit";
import UpdateWorkPermitDocMutation from "../graphql/mutations/update-work-permit";
import FetchBasicWorkerQuery from "../graphql/queries/fetch-basic-worker-data";
import FetchRequirementsData from "../graphql/queries/fetch-requirements";
import FetchRequirementsByEmployerData from "../graphql/queries/fetch-requirements-by-employers";
import moment from "moment";
import { approveRejectRequirement, BulkApproveJobApplicationRequirements } from "store/modules/verifications/graphql/mutations";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setTransportation = data => ({
    type: types.SET_TRANSPORTATION_TYPES,
    payload: data,
});
const setWorkerEtransfers = data => ({
    type: types.SET_WORKER_ETRANSFERS,
    payload: data,
});

const setDCTransactionList = data => ({
    type: types.SET_WORKER_DC_TRANSACTION_LIST,
    payload: data,
});

const setIsPageLoading = setLoadingState("page");
const setIsPositionsLoading = setLoadingState("positions");
const setIsWorkerNotesLoading = setLoadingState("workerNotes");
const setIsWorkerBannedFromLoading = setLoadingState("WorkerBannedFrom");
const setIsApplyingAction = setLoadingState("actions");
const setIsDCTransactionLoading = setLoadingState("DCTransaction");
const setIsUpdatingWorkPermit = setLoadingState("updatingWorkPermit");
const getPositionsPagingVars = pagination.pagingVarsFactory(paginationSettings.positions);
const getWorkerNotesPagingVars = pagination.pagingVarsFactory(paginationSettings.workerNotes);
const getBannedFromPagingVars = pagination.pagingVarsFactory(paginationSettings.workerBannedFrom);
const setPerRequirementTabelLoading = setLoadingState("perRequirement");
const setRequirementWiseApproveAllLoading = setLoadingState("requirementWiseApproveAll");
const setRequirementWiseApproveLoading = setLoadingState("requirementWiseApprove");
const setRequirementWiseRejectLoading = setLoadingState("requirementWiseReject");
const setRejectedAdditionalDocLoading = setLoadingState("rejectedAdditionalDocConsentLoading");
const setIsDocumentApproveLoading = setLoadingState("documentApproveLoading");
const setIsDocumentRejectLoading = setLoadingState("documentRejectLoading");
const setRejectedAlternateLoading = setLoadingState("alternateDocLoading");
const setRejectedResubmissionLoading = setLoadingState("resubmissionDocLoading");
const setUploadApproveLoading = setLoadingState("uploadApproveDocumentLoading");

export const findWorkerEtransfers = args => dispatch => {
    const payload = {
        type: args.type || null,
        amount: args.amount || null,
        customerNumber: args.customerNumber || null,
        maxExpiryDate: args.maxExpiryDate || null,
        maxDate: args.maxDate || null,
        minDate: args.minDate || null,
        minExpiryDate: args.minExpiryDate || null,
        transactionId: Number(args.transactionId) || null,
    };

    return ApolloClient.query({ query: findWorkerEtransfersQuery, variables: { ...payload } })
        .then(({ data }) => dispatch(setWorkerEtransfers(data.findEtransferTransactions)))
        .catch(() => dispatch(MetaActions.errorToast(`Failed find Etransfers`)));
};

export const clearWorkerUpdateData = () => ({
    type: types.CLEAR_UPDATE_UPDATE_WORKER_DATA,
    payload: {},
});

export const fetchTransportation = () => async dispatch => {
    return ApolloClient.query({
        query: getTransportationListQuery,
        variables: {},
    })
        .then(({ data: { transportation } }) => {
            //
            dispatch(setTransportation(transportation));
            // return transportation;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
        });
};

export const setUpdateWorkerData = (field, value) => {
    if (field === "phone" || field === "sin") {
        value = value.replace(/\D/g, "");
    }

    return {
        type: types.SET_UPDATE_UPDATE_WORKER_DATA,
        payload: { field, value },
    };
};

export const saveWorkerWorkTypes = data => (dispatch, getState) => {
    const { worker } = getState().workerDetails;

    const { workTypePreferences, transportationPreferences, timezone, travelRange, vaccinationStatus } = data;
    let workTypeIds = [];
    let transportationIds = [];
    workTypePreferences.map(e => workTypeIds.push(e.id ? e.id : e.value));
    transportationPreferences.map(e => transportationIds.push(e.id ? e.id : e.value));

    const payload = sanitizePayload({ timezone, travelRange, vaccinationStatus });

    return ApolloClient.mutate({
        mutation: setWorkerWorkTypePreferencesMutation,
        variables: { data: { workerId: worker.id, workTypeIds } },
    })
        .then(() =>
            ApolloClient.mutate({
                mutation: setWorkerTransportationPreferencesMutation,
                variables: { data: { workerId: worker.id, transportationIds } },
            })
                .then(() =>
                    ApolloClient.mutate({
                        mutation: UpdateWorkerProfileMutation,
                        variables: { data: { id: worker.id, ...payload } },
                    })
                        .then(() => {
                            dispatch(MetaActions.successToast(`${worker.firstName} ${worker.lastName}'s profile has been updated.`))
                            dispatch(fetchWorker(worker.id))
                        })
                        .catch(e =>
                            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
                        ),
                )
                .catch(e =>
                    dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
                ),
        )
        .catch(e => dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)));
};
export const saveWorkerSetting = data => (dispatch, getState) => {
    const { worker } = getState().workerDetails;

    const payload = sanitizePayload(data);

    if (_.isEmpty(data)) {
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }

    if (_.isEmpty(payload)) {
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }

    return ApolloClient.mutate({
        mutation: UpdateWorkerProfileMutation,
        variables: { data: { id: worker.id, ...payload } },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(
                MetaActions.successToast(
                    `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
                ),
            );
            return data.editWorker;
        })
        .catch(e => {
            dispatch(setUpdateWorkerData());
            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
        });
};
export const saveWorkerProfile = data => (dispatch, getState) => {
    const { worker, updateWorkerData } = getState().workerDetails;

    const payload = sanitizePayload(data, true);

    if (_.isEmpty(data)) {
        // dispatch(clearWorkerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }

    if (data.emails) {
        ApolloClient.mutate({
            mutation: UpdateWorkerEmailMutation,
            variables: { data: { workerId: worker.id, email: data.email } },
        })
            .then(({ data }) => console.log("UpdateWorkerEmailMutation", data))
            .catch(e =>
                dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
            );
    }
    if (_.isEmpty(payload)) {
        // dispatch(clearWorkerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }
    if (updateWorkerData.transType) {
        delete updateWorkerData.transType;
    }

    if (updateWorkerData.data) {
        delete updateWorkerData.data;
    }


    const formData = Object.keys(payload).reduce((p, key) => ({ ...p, ...(worker?.customFieldValues?.find(a => a?.id == key) ? {} : { [key]: payload[key] }) }), {});
    const customFieldValues = worker?.customFieldValues?.map(field => ({
        id: field?.id,
        value: (payload[`${field?.id}`] == " " || payload[`${field?.id}`] == "" || payload[`${field?.id}`] == "[]") ? null : payload[`${field?.id}`]
    }));

    return ApolloClient.mutate({
        mutation: UpdateWorkerProfileMutation,
        variables: {
            data: {
                id: worker.id,
                ...formData,
                customFieldValues
            }
        },
    })
        .then(({ data, errors }) => {
            //   dispatch(setUpdateWorkerData());
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchWorker(worker.id));
            dispatch(
                MetaActions.successToast(
                    `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
                ),
            );
            return data.editWorker;
        })
        .catch(e => {
            dispatch(setUpdateWorkerData());
            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e?.message || e}`));
        });
};

export const updateWorkerProfile = () => (dispatch, getState) => {
    const { worker, updateWorkerData } = getState().workerDetails;
    const payload = sanitizePayload(updateWorkerData);
    if (_.isEmpty(updateWorkerData)) {
        dispatch(clearWorkerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }

    if (updateWorkerData.email) {
        ApolloClient.mutate({
            mutation: UpdateWorkerEmailMutation,
            variables: { data: { workerId: worker.id, email: updateWorkerData.email } },
        })
            .then(({ data }) => console.log("UpdateWorkerEmailMutation", data))
            .catch(e =>
                dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
            );
    }
    if (_.isEmpty(payload)) {
        dispatch(clearWorkerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }
    if (updateWorkerData.transType) {
        delete updateWorkerData.transType;
    }

    if (updateWorkerData.data) {
        delete updateWorkerData.data;
    }

    return ApolloClient.mutate({
        mutation: UpdateWorkerProfileMutation,
        variables: { data: { id: worker.id, ...payload } },
    })
        .then(({ data }) => {
            dispatch(setUpdateWorkerData());
            dispatch(fetchWorker(worker.id));
            dispatch(
                MetaActions.successToast(
                    `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
                ),
            );
            return data.editWorker;
        })
        .catch(e => {
            dispatch(setUpdateWorkerData());
            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
        });
};

const setWorker = worker => ({ type: types.SET_WORKER, payload: { worker } });
const setPositionListData = (data, part) => ({
    type: types.SET_POSITIONS_LIST_DATA,
    payload: { data, part },
});
const setWorkerNotesListData = data => ({
    type: types.SET_WORKER_NOTES_LIST_DATA,
    payload: { data },
});
const setWorkerBannedFromListData = data => ({
    type: types.SET_WORKER_BANNED_FROM_LIST_DATA,
    payload: { data },
});

export const loadWorkerNotes = id => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerNotesPaginationData(state);
    const pagingVars = dispatch(getWorkerNotesPagingVars(pageInfo, 0));

    dispatch(setIsWorkerNotesLoading(true));
    return ApolloClient.query({
        query: FetchWorkerNotesQuery,
        variables: {
            workerId: id,
            ...pagingVars,
        },
    })
        .then(({ data: { workerNotes } }) => {
            dispatch(setWorkerNotesListData(flattenGraphQLArray(workerNotes), "data"));
            dispatch(
                pagination.updatePageInfo(paginationSettings.workerNotes, {
                    ...workerNotes.pageInfo,
                    totalCount: workerNotes.totalCount,
                }),
            );
            dispatch(pagination.doneLoading(paginationSettings.workerNotes));
            dispatch(setIsWorkerNotesLoading(false));
        })
        .catch(e => {
            dispatch(setIsWorkerNotesLoading(false));
            dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
        });
};

export const fetchWorkerNotes = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerNotesPaginationData(state);
    const pagingVars = dispatch(getWorkerNotesPagingVars(pageInfo, pageIndex));

    dispatch(setIsWorkerNotesLoading(true));
    return ApolloClient.query({
        query: FetchWorkerNotesQuery,
        variables: {
            workerId: state.workerDetails.worker.id,
            ...pagingVars,
        },
    })
        .then(({ data: { workerNotes } }) => {
            dispatch(setWorkerNotesListData(flattenGraphQLArray(workerNotes), "data"));

            dispatch(
                pagination.updatePageInfo(paginationSettings.workerNotes, {
                    ...workerNotes.pageInfo,
                    totalCount: workerNotes.totalCount,
                }),
            );
            dispatch(pagination.doneLoading(paginationSettings.workerNotes));
            dispatch(setIsWorkerNotesLoading(false));
        })
        .catch(e => {
            dispatch(setIsWorkerNotesLoading(false));
            dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
        });
};


export const fetchWorkerBannedFrom = (pageIndex, workerId) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerBannedFromPaginationData(state);
    const pagingVars = dispatch(getBannedFromPagingVars(pageInfo, pageIndex));
    dispatch(setIsWorkerBannedFromLoading(true));
    return ApolloClient.query({
        query: FetchWorkerBannedFromQuery,
        variables: {
            bannedWorkerId: workerId,
            ...pagingVars,
        },
    })
        .then(({ data: { employers } }) => {
            dispatch(setWorkerBannedFromListData(flattenGraphQLArray(employers), "data"));

            dispatch(
                pagination.updatePageInfo(paginationSettings.workerBannedFrom, {
                    ...employers.pageInfo,
                    totalCount: employers.totalCount,
                }),
            );
            dispatch(pagination.doneLoading(paginationSettings.workerBannedFrom));
            dispatch(setIsWorkerBannedFromLoading(false));
        })
        .catch(e => {
            dispatch(setIsWorkerBannedFromLoading(false));
            dispatch(MetaActions.errorToast(`Could not get employer information: ${e.message}`));
        });
};

export const fetchPositionsList = pageIndex => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    const pageInfo = getPositionsPaginationData(state);
    const pagingVars = dispatch(getPositionsPagingVars(pageInfo, pageIndex));
    dispatch(setIsPositionsLoading(true));
    return ApolloClient.query({
        query: FetchPositionsQuery,
        variables: {
            workerId: worker.id,
            ...pagingVars,
        },
    })
        .then(({ data: { positions } }) => {
            dispatch(setPositionListData(flattenGraphQLArray(positions), "data"));
            dispatch(
                pagination.updatePageInfo(paginationSettings.positions, {
                    ...positions.pageInfo,
                    totalCount: positions.totalCount,
                }),
            );
            dispatch(pagination.doneLoading(paginationSettings.positions));
            dispatch(setIsPositionsLoading(false));
        })
        .catch(e => {
            dispatch(setIsPositionsLoading(false));
            dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
        });
};

export const fetchWorker = id => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getPositionsPaginationData(state);
    const pagingVars = dispatch(getPositionsPagingVars(pageInfo, 0));
    // dispatch(setIsPageLoading(true));
    dispatch(setIsDCTransactionLoading(true));
    return ApolloClient.query({
        query: FetchWorkerQuery,
        variables: {
            id: id,
            ...pagingVars,
        },
    })
        .then(({ data: { worker, positions } }) => {
            const flattenedPositions = flattenGraphQLArray(positions);
            if (worker.dcCustomerNumber) {
                dispatch(fetchWorkerDCTransaction(worker.dcCustomerNumber))
            } else {
                dispatch(setIsDCTransactionLoading(false));
            }
            dispatch(setWorker(worker));
            dispatch(fetchWorkTypes());
            dispatch(fetchTransportation());
            dispatch(setPositionListData(flattenedPositions, "data"));
            dispatch(
                pagination.updatePageInfo(paginationSettings.positions, {
                    ...positions.pageInfo,
                    totalCount: positions.totalCount,
                }),
            );
            dispatch(pagination.doneLoading(paginationSettings.positions));
            dispatch(setIsPageLoading(false));
        })
        .catch(e => {
            dispatch(setIsPageLoading(false));
            dispatch(setIsDCTransactionLoading(false));
            dispatch(MetaActions.errorToast(`Could not get worker information: ${e.message}`));
        });
};

export const setPositionListSort = sort => dispatch => {
    dispatch({
        type: types.SET_POSITIONS_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });

    //   return dispatch(fetchPositionsList());
};

export const setPositionListFilter = filter => dispatch => {
    dispatch(setPositionListData(filter, "filter"));
    //   dispatch(fetchPositionsList());
};

export const updateWorkerNote = note => ({ type: types.SET_WORKER_NOTE, payload: note });
export const resetWorkerNote = () => ({ type: types.RESET_WORKER_NOTE });

export const saveWorkerNote = () => (dispatch, getState) => {
    const state = getState();
    const { workerNote, worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: AddWorkerNoteMutation,
        variables: {
            data: {
                workerId: worker.id,
                note: workerNote,
            },
        },
    })
        .then(() => {
            dispatch(fetchWorkerNotes());
            dispatch(
                MetaActions.successToast(`A custom note has been added to ${worker.firstName}'s profile.`),
            );
        })
        .catch(() => {
            dispatch(
                MetaActions.errorToast(`Could not add a custom note to ${worker.firstName}'s profile`),
            );
        })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(resetWorkerNote());
        });
};

export const updateWorkerTags = note => ({ type: types.SET_WORKER_TAGS, payload: note });
export const resetWorkerTags = () => ({ type: types.RESET_WORKER_TAGS });
export const saveWorkerTags = () => (dispatch, getState) => {
    const state = getState();
    const { workerTags, worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: AddWorkerTagsMutation,
        variables: {
            workerId: worker.id,
            tags: workerTags,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(fetchWorker(worker.id));
                dispatch(
                    MetaActions.successToast(`A tag has been added to ${worker.firstName}'s profile.`),
                );
            }
        })
        .catch((e) => {
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(resetWorkerNote());
        });
};
export const removeWorkerTags = (tag) => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: RemoveWorkerTagsMutation,
        variables: {
            workerId: worker.id,
            tags: [tag],
        },
    })
        .then(() => {
            dispatch(fetchWorker(worker.id));
            dispatch(
                MetaActions.successToast(`A tag has been remove from ${worker.firstName}'s profile.`),
            );
        })
        .catch(() => {
            dispatch(
                MetaActions.errorToast(`Could not remove a tags to ${worker.firstName}'s profile`),
            );
        })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(resetWorkerNote());
        });
};

export const onDeleteWorkerAccount = () => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: RemoveWorkerMutation,
        variables: {
            id: worker.id
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(
                    MetaActions.successToast(`${worker.firstName}'s account deleted successfully.`),
                );
                dispatch(setIsApplyingAction(false));
                return true;
            }
        })
        .catch((e) => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(e.message),
            );
        })
};

export const suspendWorker = (duration, reason) => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: SuspendWorkerMutation,
        variables: {
            data: {
                workerId: worker.id,
                duration,
                reason,
            },
        },
    })
        .then(({ data: { suspendWorker } }) => {
            dispatch(setWorker(suspendWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(
                MetaActions.successToast(`${worker.firstName} has been suspended for ${duration} days`),
            );
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not suspend ${worker.firstName} for ${duration} days: ${e.message}`,
                ),
            );
        });
};

export const unsuspendWorker = reason => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: UnsuspendWorkerMutation,
        variables: {
            data: { workerId: worker.id, reason },
        },
    })
        .then(({ data: { unsuspendWorker } }) => {
            dispatch(setWorker(unsuspendWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(MetaActions.successToast(`${worker.firstName}'s suspension has been removed`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(`Could not remove ${worker.firstName}'s suspension: ${e.message}`),
            );
        });
};

export const verifyWorker = () => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: VerifyWorkerMutation,
        variables: {
            data: { workerId: worker.id },
        },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) throw new Error(errors[0].message);
            const { verifyWorker } = data;
            dispatch(setWorker(verifyWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(MetaActions.successToast(`${worker.firstName} has been verified.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not verify ${worker.firstName}: ${e.message}`));
        });
};

export const unverifyWorker = reason => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: UnverifyWorkerMutation,
        variables: {
            data: { workerId: worker.id, reason },
        },
    })
        .then(({ data: { unverifyWorker } }) => {
            dispatch(setWorker(unverifyWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(MetaActions.successToast(`${worker.firstName}'s information has been unverified.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not unverify ${worker.firstName}: ${e.message}`));
        });
};

export const activateWorker = reason => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: ActivateWorkerMutation,
        variables: {
            data: { workerId: worker.id, reason },
        },
    })
        .then(({ data: { activateWorker } }) => {
            dispatch(setWorker(activateWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(
                MetaActions.successToast(
                    `${worker.firstName} has been activated. They are now able to work.`,
                ),
            );
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not activate ${worker.firstName}: ${e.message}`));
        });
};

export const deactivateWorker = reason => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: DeactivateWorkerMutation,
        variables: {
            data: { workerId: worker.id, reason },
        },
    })
        .then(({ data: { deactivateWorker } }) => {
            dispatch(setWorker(deactivateWorker));
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(MetaActions.successToast(`${worker.firstName} has been deactivated.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not deactivate ${worker.firstName}: ${e.message}`));
        });
};

export const preferWorker = (employerId, companyName) => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: PreferWorkerMutation,
        variables: {
            data: { workerId: worker.id, employerId },
        },
    })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(fetchWorkerNotes());
            dispatch(
                MetaActions.successToast(
                    `${worker.firstName} has been added to ${companyName}'s preferred list.`,
                ),
            );
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not add ${worker.firstName} to ${companyName}'s preferred list: ${e.message}`,
                ),
            );
        });
};

export const banWorker = (employerId, companyName, reason) => (dispatch, getState) => {
    const state = getState();
    const { worker } = getWorkerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: BanWorkerMutation,
        variables: {
            data: {
                workerId: worker.id,
                employerId,
                reason,
            },
        },
    })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.successToast(`${worker.firstName} has been banned from ${companyName}.`),
            );
            dispatch(fetchWorkerBannedFrom(0, worker.id));
            dispatch(loadWorkerNotes(worker.id));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(
                    `Could not add ${worker.firstName} to ${companyName}'s banned list: ${e.message}`,
                ),
            );
        });
};

export const uploadWorkerProfileImage = (file, isProfile = true) => (dispatch, getState) => {
    const { worker } = getState().workerDetails;

    return ApolloClient.mutate({
        mutation: uploadFileToS3,
        variables: {
            isProfile,
            data: { workerId: worker.id },
            file: file,
        },
    })
        .then(() => {
            dispatch(fetchWorker(worker.id));
            dispatch(MetaActions.successToast(`${worker.firstName} has been updated.`));
            return; // data.createCustomRate;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
        });
};


export const uploadCustomFieldFile = (file, callback) => (dispatch, getState) => {
    const { worker } = getState().workerDetails;

    return ApolloClient.mutate({
        mutation: workerCustomFileUploadToS3,
        variables: {
            workerId: worker.id,
            file,
        },
    })
        .then((res) => {
            dispatch(MetaActions.successToast(`Custom field file has been updated.`));
            callback(res?.data?.workerCustomFileUpload);
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Updating custom field file has failed. ${e}`));
        });
};


export const fetchWorkerDCTransaction = (dcCustomerNumber) => async dispatch => {
    dispatch(setIsDCTransactionLoading(true));
    return ApolloClient.query({
        query: FetchWorkerDCTrancation,
        variables: {
            dcCustomerNumber
        }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(setIsDCTransactionLoading(false));
            dispatch(setDCTransactionList(data.getWorkerDCTrancation));
        })
        .catch((error) => {
            dispatch(setIsDCTransactionLoading(false));
            console.log(error)
        });
};

export const uploadWorkPermit = (data, callback) => async (dispatch, getState) => {
    const { worker } = getState().workerDetails;
    let query = worker.workPermitUrl ? UpdateWorkPermitDocMutation : UploadWorkPermitDocMutation;
    dispatch(setIsUpdatingWorkPermit(true));
    return ApolloClient.mutate({
        mutation: query,
        variables: {
            ...data,
            workerId: worker.id
        }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(setIsUpdatingWorkPermit(false));
            dispatch(MetaActions.successToast(`${worker.firstName} has been updated.`));
            dispatch(fetchWorker(worker.id))
            callback && callback?.(data.updateWorkerPermitData);
        })
        .catch((error) => {
            dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
            dispatch(setIsUpdatingWorkPermit(false));
        });
};


// Requirements
export const setHighlightedRequirement = requirement => ({
    type: types.SELECTED_RECORD_FOR_HIGHLIGHT,
    payload: { requirement },
});

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setRequirementWiseApproveAllDialog = setDialogState("requirementWiseApproveAll");
export const setApproveRejectConfirmationDilog = setDialogState("approveRejectDialog");
export const setApproveRequirementDilog = setDialogState("approveRequirementDialog");
export const setRejectConsentDilog = setDialogState("rejectConsentDialog");
export const setRejectedInfoDialog = setDialogState("rejectedDialog");
export const setRejectedAdditionalDocDialog = setDialogState("rejectedAdditionalDocConsentDialog");
export const setApproveDocumentDilog = setDialogState("approveDocumentDialog");
export const setRejectDocumentDilog = setDialogState("rejectDocumentDialog");
export const setDocumentRejectedInfoDialog = setDialogState("rejectedDocumentInfoDialog");
export const setAlternateDocDialog = setDialogState("alternateDocumentDialog");
export const setResubmissionDialog = setDialogState("resubmissionDocumentDialog");
export const setUploadApproveDialog = setDialogState("uploadApproveDocumentDialog");

export const setRequirementFilters = (filters) => ({
    type: types.SET_REQUIREMENTS_FILTERS,
    payload: { filters }
});

export const setRequirementActiveTab = (activeTab) => ({
    type: types.SET_REQUIREMENT_ACTIVE_TAB,
    payload: { activeTab }
});

export const setPerRequirementsData = (perRequirements) => ({
    type: types.SET_PER_REQUIREMENTS,
    payload: { perRequirements },
});
export const setPerEmployersData = (perEmployers) => ({
    type: types.SET_PER_EMPLOYERS,
    payload: { perEmployers },
});

export const setWorkerBasicInfo = (workerBasicDetails) => ({
    type: types.SET_WORKER_BASIC_DETAILS,
    payload: { workerBasicDetails },
});

export const fetchBasicWorkerData = (id, indicateLoading = true) => dispatch => {
    if (indicateLoading) {
        dispatch(setIsPageLoading(true));
    }
    return ApolloClient.query({
        query: FetchBasicWorkerQuery,
        variables: {
            id: id,
        },
    }).then(({ data, errors }) => {
        if (!data && errors.length > 0) {
            throw new Error(errors[0].message);
        }
        dispatch(setWorkerBasicInfo(data.worker));
        if (indicateLoading) {
            dispatch(setIsPageLoading(false));
        }
    }).catch(e => {
        if (indicateLoading) {
            dispatch(setIsPageLoading(false));
        }
        dispatch(MetaActions.errorToast(`Could not get worker information: ${e.message}`));
    });
};

export const fetchPerRequirementsData = (request, isLoading = true) => (dispatch, getState) => {
    const { requirementsFilters } = getState().workerDetails;
    const searchTerm = requirementsFilters?.find(a => a.id === "searchTerm")?.value || '';

    isLoading && dispatch(setPerRequirementTabelLoading(true));
    return ApolloClient.query({
        query: request?.filterBy === "requirement" ? FetchRequirementsData : FetchRequirementsByEmployerData,
        variables: {
            data: {
                ...request,
                searchTerm,
                like: requirementsFilters?.filter(a => a.id !== "searchTerm")?.map(filter => {
                    switch (filter?.id) {
                        case 'expiryDate':
                            return {
                                field: filter?.id,
                                value: moment(filter?.value).format("YYYY-MM-DD"),
                                relation: "JobApplicationRequirements"
                            }
                        case 'employer': {
                            return {
                                field: 'companyName',
                                value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                                relation: "Employer"
                            }
                        }
                        case 'workType': {
                            return {
                                field: 'label',
                                value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                                relation: "WorkType"
                            }
                        }
                        case 'status': {
                            return {
                                field: 'applicationStatus',
                                value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                                relation: "JobApplicationRequirements"
                            }
                        }
                        case 'location': {
                            return {
                                field: 'city',
                                value: Array.isArray(filter?.value) ? filter?.value.map(a => a.label) : filter?.value,
                                relation: "Employer"
                            }
                        }
                    }
                }) || []
            }
        },
    }).then(({ data, errors }) => {
        if (!data && errors.length > 0) {
            throw new Error(errors[0].message);
        }
        if (request?.filterBy === "requirement") {
            dispatch(setPerRequirementsData(data.getRequirementsByWorkerId?.requirement));
        } else {
            dispatch(setPerEmployersData(data.getRequirementsByWorkerId?.employer));
        }
        isLoading && dispatch(setPerRequirementTabelLoading(false));
    }).catch(e => {
        isLoading && dispatch(setPerRequirementTabelLoading(false));
        dispatch(MetaActions.errorToast(`Could not get requirements: ${e.message}`));
    });
};


export const requirementsWiseBulkApprove = (callback) => (dispatch, getState) => {
    dispatch(setRequirementWiseApproveAllLoading(true));
    const selectedRequirements = getRequirementWiseApproveAllDialog(getState());

    return ApolloClient.mutate({
        mutation: BulkApproveJobApplicationRequirements,
        variables: {
            data: {
                ids: (selectedRequirements?.ModalType === "Employer" ?
                    selectedRequirements?.requirements?.flatMap(a => {
                        return a?.jobApplicationRequirements?.map(p => p?.id);
                    })?.filter(a => a)
                    : selectedRequirements?.requirementHasEmployer?.flatMap(a => {
                        return a?.jobApplicationRequirements?.map(p => p?.id);
                    })?.filter(a => a)) || [],
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setRequirementWiseApproveAllLoading(false));
        dispatch(setRequirementWiseApproveAllDialog(false));
        dispatch(MetaActions.successToast(`Requirement approved for the all employers.`));
        return data;
    }).catch(e => {
        dispatch(setRequirementWiseApproveAllLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const approveRequirement = (callback) => (dispatch, getState) => {
    dispatch(setRequirementWiseApproveLoading(true));
    const selectedRequirement = getApproveRequirementDilog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.id,
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setRequirementWiseApproveLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setApproveRequirementDilog(false));
        selectedRequirement.ModalType === 'Employer' ?
            dispatch(MetaActions.successToast(`${selectedRequirement?.data?.employerRequirement?.requirement?.name} requirement approved.`))
            : dispatch(MetaActions.successToast(`Requirement approved for the ${selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName}.`));
        return data;
    }).catch(e => {
        dispatch(setRequirementWiseApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const rejectConsentRequirement = (rejectionReason, callback) => (dispatch, getState) => {
    dispatch(setRequirementWiseRejectLoading(true));
    const selectedRequirement = getRejectConsentDilog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.id,
                isApproved: false,
                rejectionReason
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setRejectedInfoDialog(selectedRequirement));
        dispatch(setRequirementWiseRejectLoading(false));
        dispatch(setRejectConsentDilog(false));
        selectedRequirement.ModalType === 'Employer' ?
            dispatch(MetaActions.successToast(`${selectedRequirement?.data?.employerRequirement?.requirement?.name} requirement rejected.`))
            : dispatch(MetaActions.successToast(`Requirement rejected for the ${selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName}.`));
        return data;
    }).catch(e => {
        dispatch(setRequirementWiseRejectLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForAdditionalDocForConsent = (rejectionReason, employerRequirementId, callback) => (dispatch, getState) => {
    dispatch(setRejectedAdditionalDocLoading(true));
    const selectedRequirement = getRejectedAdditionalDocDialog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.id,
                isApproved: false,
                nextAction: "AlternateDocument",
                rejectionReason,
                employerRequirementId
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback();
        dispatch(setRejectedAdditionalDocLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setRejectedAdditionalDocDialog(null));
        dispatch(MetaActions.successToast(`Sent notification to worker for Alternate document.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedAdditionalDocLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};


export const approveDocumentRequirement = (callback) => (dispatch, getState) => {
    dispatch(setIsDocumentApproveLoading(true));
    const selectedRequirement = getApproveDocumentDilog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.applicationStatus === "NewDocument" ? selectedRequirement?.data?.childRequirements?.[selectedRequirement?.data?.childRequirements?.length - 1]?.id : selectedRequirement?.data?.id,
                isApproved: true
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setIsDocumentApproveLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setApproveDocumentDilog(null));
        selectedRequirement.ModalType === 'Employer' ?
            dispatch(MetaActions.successToast(`${selectedRequirement?.data?.employerRequirement?.requirement?.name} requirement approved.`))
            : dispatch(MetaActions.successToast(`Requirement approved for the ${selectedRequirement?.data?.requirementHasEmployer?.employer?.companyName}.`));
        return data;
    }).catch(e => {
        dispatch(setIsDocumentApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};


export const rejectDocumentRequirement = (rejectionReason, callback) => (dispatch, getState) => {
    dispatch(setIsDocumentRejectLoading(true));
    const selectedRequirement = getRejectDocumentDilog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.applicationStatus === "NewDocument" ? selectedRequirement?.data?.childRequirements?.[selectedRequirement?.data?.childRequirements?.length - 1]?.id : selectedRequirement?.data?.id,
                isApproved: false,
                rejectionReason
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setIsDocumentRejectLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setDocumentRejectedInfoDialog(selectedRequirement));
        dispatch(setRejectDocumentDilog(null));
        return data;
    }).catch(e => {
        dispatch(setIsDocumentRejectLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForAlternateDoc = (rejectionReason, employerRequirementId, callback) => (dispatch, getState) => {
    dispatch(setRejectedAlternateLoading(true));
    const selectedRequirement = getAlternateDocDialog(getState());
    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.childRequirements?.length ? selectedRequirement?.data?.childRequirements?.[selectedRequirement?.data?.childRequirements?.length - 1]?.id : selectedRequirement?.data?.id,
                isApproved: false,
                nextAction: "AlternateDocument",
                rejectionReason,
                employerRequirementId
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false)
        dispatch(setRejectedAlternateLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setAlternateDocDialog(null));
        dispatch(MetaActions.successToast(`Sent notification to worker for Alternate document.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedAlternateLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const requestForResubmission = (rejectionReason, callback) => (dispatch, getState) => {
    dispatch(setRejectedResubmissionLoading(true));
    const selectedRequirement = getResubmissionDialog(getState());

    return ApolloClient.mutate({
        mutation: approveRejectRequirement,
        variables: {
            data: {
                id: selectedRequirement?.data?.childRequirements?.length ? selectedRequirement?.data?.childRequirements?.[selectedRequirement?.data?.childRequirements?.length - 1]?.id : selectedRequirement?.data?.id,
                isApproved: false,
                nextAction: "ReSubmission",
                rejectionReason,
            }
        },
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setRejectedResubmissionLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setResubmissionDialog(null));
        dispatch(MetaActions.successToast(`Sent notification to worker for Document Resubmission.`));
        return data;
    }).catch(e => {
        dispatch(setRejectedResubmissionLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};

export const uploadAndApprove = (file, workerId, callback) => (dispatch, getState) => {
    dispatch(setUploadApproveLoading(true));
    const selectedRequirement = getUploadApproveDialog(getState());
    return ApolloClient.mutate({
        mutation: UploadDocumentForVerification,
        variables: {
            data: {
                workerId,
                employerRequirementId: selectedRequirement?.additionalDoc?.employerRequirement?.id,
                file
            }
        },
    }).then(async ({ data: res, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        return ApolloClient.mutate({
            mutation: approveRejectRequirement,
            variables: {
                data: {
                    id: selectedRequirement?.additionalDoc?.id,
                    isApproved: true,
                    documentUrl: res?.uploadRequirementDocument
                }
            },
        });
    }).then(async ({ data, errors }) => {
        if (errors && errors.length) {
            throw Error(errors[0].message);
        }
        await callback(false);
        dispatch(setUploadApproveLoading(false));
        dispatch(setHighlightedRequirement(selectedRequirement));
        dispatch(setUploadApproveDialog(null));
        dispatch(MetaActions.successToast(`Document Uploaded & Approved`));
        return data;
    }).catch(e => {
        dispatch(setUploadApproveLoading(false));
        dispatch(MetaActions.errorToast(e.message));
    });
};
