import { PictureAsPdf } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react'

export default function PDFRenderer({ document }) {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <PictureAsPdf style={{ fontSize: 44, marginBottom: 8 }} />
            <Typography>{document?.name || 'Click here to view'}</Typography>
        </div>
    )
};
