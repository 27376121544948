import { Add, ChevronLeftOutlined, EditOutlined } from '@mui/icons-material'
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import ConsentDetails from './ConsentDetails';
import HelpTextDetails from './HelpTextDetails';
import { useDispatch, useSelector } from 'react-redux';
import { actions as Actions, selectors as Selectors } from 'store/modules/additionalRequirements';
import DocumentDetails from './DocumentDetails';
const useStyles = makeStyles(theme => ({
    editButton: {
        borderRadius: 30,
        background: ({ isEditing }) => !isEditing && theme.palette.background.paper,
        marginLeft: 'auto'
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        backgroundColor: `${theme.palette.common.white}`,
        border: `1px solid rgba(0, 0, 0, 0.12)`,
        borderRadius: 5,
        padding: theme.spacing(3),
        marginTop: theme.spacing(2),
        position: 'relative'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
        color: '#3F454D'
    },
    divider: {
        marginTop: 20,
        marginBottom: 20,
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f5f5f58f',
        zIndex: 99999,
        borderRadius: 5,
    },
    helpTextTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
        }
    }
}));

export default function RequirementDetailTab() {
    const isEditing = useSelector(Selectors.getIsEditing);
    const classes = useStyles({ isEditing });
    const dispatch = useDispatch()
    const requirementDetails = useSelector(Selectors.getRequirementDetails);
    const isUpdating = useSelector(Selectors.getIsRequiremntUpdateLoading);
    const canUpdate = useSelector(Selectors.canPostValidation);
    const formData = useSelector(Selectors.getFormData);

    const toggleEdit = () => {
        if (isEditing) {
            dispatch(Actions.setEditFormData({ ...requirementDetails, groupNames: requirementDetails?.groups?.map(a => ({ label: a.name, value: a.id, data: a })) }));
        }
        dispatch(Actions.setIsEditing(!isEditing));
    }

    const handleSave = () => {
        dispatch(Actions.updateRequirement());
    }

    const addHelpText = () => {
        dispatch(Actions.increaseHelpText([...(formData?.helpTexts || [])].concat({
            question: '', frenchQuestion: '', answer: '', frenchAnswer: '',
        })));
        setTimeout(() => {
            const doc = document.getElementById(`HelpText-${formData?.helpTexts?.length + 1}`);
            doc?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }, 500);
    };

    return (
        <>
            <div className={classes.buttonsWrapper}>
                {isEditing ? <Button disabled={isUpdating} onClick={toggleEdit} variant="text" startIcon={<ChevronLeftOutlined />}>{`Don't Edit`}</Button> : null}
                {isEditing ? (
                    <Button disabled={isUpdating || !canUpdate} onClick={handleSave} className={classes.editButton} color="secondary" variant="contained">
                        Save Details
                        {isUpdating && <CircularProgress size={24} className={classes.progress} />}
                    </Button>
                ) : (
                    <Button onClick={toggleEdit} className={classes.editButton} color="secondary" variant="outlined" startIcon={<EditOutlined />}>
                        Edit Details
                    </Button>
                )}
            </div>
            <Grid container className={classes.container}>
                <Grid container columnSpacing={5}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>Basic Details</Typography>
                        <Divider className={classes.divider} />
                        {requirementDetails.type === 'Document' ? <DocumentDetails /> : <ConsentDetails />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.helpTextTitle}>
                            <Typography className={classes.title}>Help Text</Typography>
                            {isEditing && <Button size="small" color='secondary' onClick={addHelpText} variant="text" startIcon={<Add />}>{`Add More`}</Button>}
                        </div>
                        <Divider className={classes.divider} />
                        <HelpTextDetails />
                    </Grid>
                </Grid>
                {isUpdating ? <div className={classes.loader}>
                    <CircularProgress size={40} />
                </div> : null}
            </Grid>
        </>
    )
}
