import { makeStyles } from '@mui/styles';
import MainContent from 'components/Containers/MainContent';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { actions as Actions, selectors as Selectors } from 'store/modules/employerDetails';
import { actions as PostingActions } from 'store/modules/posting';
import Header from './Header';
import { Grid } from '@mui/material';
import Stage from 'views/AdditionalRequirements/components/Stage';
import RequirementSelection from './RequirementSelection';
import RequirementsGroupTable from './RequirementsGroupTable';

const useStyles = makeStyles(() => ({
    container: {
        padding: 0
    }
}));

export default function AssignRequirement() {
    const classes = useStyles();
    const params = useParams();
    const employerId = params?.id ? window.atob(params.id) : null;
    const dispatch = useDispatch();
    const isLoading = useSelector(Selectors.getIsPageLoading);
    const assignedData = useSelector(Selectors.getAssignedGroupOrRequirement);

    useEffect(() => {
        dispatch(Actions.fetchBasicEmployerData(employerId));
        dispatch(PostingActions.fetchWorkTypes());
        return () => {
            dispatch(Actions.clearRequirementForm());
        }
    }, []);

    return (
        <React.Fragment>
            <MainContent className={classes.container} isLoading={isLoading}>
                <Header />
                <MainContent>
                    <Grid>
                        <Stage
                            title="Select Requirement"
                            description="Choose the Consent, Document, or Tag you wish to assign to this employer."
                            contentSize={9}>
                            <RequirementSelection assignedData={assignedData} />
                        </Stage>
                    </Grid>
                    {Boolean(assignedData?.length) && <Grid>
                        <RequirementsGroupTable data={assignedData} />
                    </Grid>}
                </MainContent>
            </MainContent>
        </React.Fragment >
    );
}
