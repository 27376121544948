import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors as Selectors, actions as Actions } from "store/modules/employerDetails";
// import ColumnActionMenu from "./ColumnActionMenu";
import { Button as MUIButton, Checkbox, Chip, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactTable from "components/ReactTable";
import moment from "moment";
import { AddCircleOutlineOutlined, AddOutlined, DeleteOutlineOutlined, Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import history from "utils/history";
import Plus from "assets/img/BubblePlus.svg";
import Button from "components/Button/Button";

const useStyles = makeStyles(theme => ({
    ActiveChip: {
        background: "#4caf50",
        color: "#fff"
    },
    InactiveChip: {
        background: theme.customPalette.red[500],
        color: "#fff"
    },
    groups: {
        minWidth: 250,
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'nowrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
        marginRight: theme.spacing(2)
    },
    Consent: {
        backgroundColor: '#F1EEFF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Document: {
        backgroundColor: '#E5F1FF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Validation: {
        backgroundColor: '#FFE5F6',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    masterName: {
        fontSize: 12,
        color: '#3F454D',
        fontWeight: 500,
        lineHeight: '14.06px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        lineClamp: 1,
        WebkitBoxOrient: 'vertical'
    },
    masterDate: {
        color: '#6B6F75',
        fontSize: 10
    },
    summary: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        textAlign: 'left',
        color: '#6B6F75',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        lineClamp: 3,
        WebkitBoxOrient: 'vertical'
    },
    date: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '14.06px',
        textAlign: 'left',
        color: '#6B6F75',
        minWidth: 100
    },
    action: {
        display: 'flex',
        gap: theme.spacing(0.5)
    },
    add: {
        fontWeight: 500,
        padding: 0,
        '& .MuiButton-icon.MuiButton-startIcon': {
            marginRight: 0
        }
    },
    overlay: {
        padding: 16,
        margin: '0px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        fontEeight: 500,
        background: '#F3FFFF',
        fontSize: 20,
        border: '1px solid #009E9633'
    }
}));

function RequirementList() {

    const dispatch = useDispatch();
    const employer = useSelector(Selectors.getEmployer);
    const data = useSelector((state) => Selectors.getAdditionalRequirementsData(state, { part: "data" }));
    const pageFilter = useSelector((state) => Selectors.getAdditionalRequirementsData(state, { part: "filter" }));
    const pageSort = useSelector((state) => Selectors.getAdditionalRequirementsData(state, { part: "sort" }));
    const PaginationData = useSelector(Selectors.getAdditionalRequirementListPagingData);
    const classes = useStyles();

    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        dispatch(Actions.setAdditionalRequirementListSort(sortBy));
        dispatch(Actions.setAdditionalRequirementListFilter(filters));
        dispatch(Actions.changeAdditionalRequirementListPageSize(pageSize));
        if (employer && employer.id)
            dispatch(Actions.fetchAdditionalRequirementsTableData(pageIndex, employer.id));
    }, [employer && employer.id]);

    const renderChip = useCallback((type, label) => {
        switch (type) {
            case 'Document':
                return <Chip className={classes[type]} label={label} />
            case 'Validation':
                return <Chip className={classes[type]} label={label} />
            case 'Consent':
                return <Chip className={classes[type]} label={label} />
        }
    }, []);

    const handleRemove = (id) => {
        dispatch(Actions.setSelectedRecordForRemove(id));
    }

    return (
        <ReactTable
            classes="-highlight"
            columns={[
                {
                    Header: "Master Name",
                    id: "name",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ name }) => name,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Typography className={classes.masterName}>{original?.requirement?.name || '-'}</Typography>
                            </div>
                        )
                    }
                },
                {
                    Header: "Requirement Summary",
                    id: "summary",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ requirement }) => requirement?.summary,
                    Cell: ({ row: { original } }) => <Typography className={classes.summary}>{original?.requirement?.type === 'Document' ? original?.requirement?.description : original?.requirement?.summary}</Typography>
                },
                {
                    Header: "Type",
                    id: "type",
                    disableFilters: true,
                    disableSortBy: true,
                    width: 100,
                    maxWidth: 100,
                    style: {
                        width: 100,
                        maxWidth: 100,
                    },
                    collapse: Boolean(data?.length),
                    accessor: ({ requirement }) => requirement?.type,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {renderChip(original?.requirement?.type, original?.requirement?.type)}
                            </div>
                        );
                    }
                },
                {
                    Header: "Work Type",
                    id: "workType.type",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ workType }) => workType?.label,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                <Chip key={original?.workType?.id} label={original?.workType?.label} />
                            </div>
                        );
                    }
                },
                {
                    Header: <span style={{ whiteSpace: 'nowrap', paddingRight: 16 }}>Is Mandatory</span>,
                    id: "isMandatory",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ isMandatory }) => isMandatory,
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {original.isMandatory ? "Yes" : "No"}
                            </div>
                        );
                    },
                    collapse: Boolean(data?.length),
                },
                {
                    Header: "Assigned On",
                    id: "createdAt",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ createdAt }) => createdAt,
                    collapse: Boolean(data?.length),
                    Cell: ({ row: { original } }) => <Typography className={classes.date}>{moment(original?.createdAt).format("DD MMM 'YY")}</Typography>
                },
                {
                    Header: "Validity",
                    id: "validity",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ validityValue }) => validityValue,
                    Cell: ({ row: { original } }) => <Typography className={classes.date}>{original?.validityType === 'date' ? moment(original?.validityValue).format("DD MMM 'YY") : original?.validityValue + ' days'}</Typography>,
                    collapse: Boolean(data?.length),
                },
                {
                    Header: "Actions",
                    id: "actions",
                    disableSortBy: true,
                    disableFilters: true,
                    collapse: Boolean(data?.length),
                    Cell: ({ row: { original } }) => {
                        const { id, requirement } = original;
                        return (
                            <div className={classes.action}>
                                <IconButton onClick={() => history.push(`/additional-requirements/${window.btoa(requirement?.id)}`)}>
                                    <RemoveRedEyeOutlined color="primary" />
                                </IconButton>
                                {/* <IconButton onClick={() => history.push(`/additional-requirements/${window.btoa(requirement?.id)}?type=1`)}>
                                    <Edit color="primary" />
                                </IconButton> */}
                                <IconButton onClick={() => handleRemove(id)}>
                                    <DeleteOutlineOutlined color="primary" />
                                </IconButton>
                            </div>
                        )
                    },
                },
                {
                    Header: <MUIButton onClick={() => history.push(`/employers/assign-requirement/${window.btoa(employer.id)}`)} size="small" className={classes.add} variant="text" color="secondary" startIcon={data?.length ? <Edit /> : <AddOutlined />}>&nbsp;&nbsp;{data?.length ? 'Update' : ' Add'}</MUIButton>,
                    id: "addAction",
                    disableSortBy: true,
                    disableFilters: true,
                    collapse: true,
                    width: 50,
                    maxWidth: 50,
                    style: {
                        width: 50,
                        maxWidth: 50,
                    },
                    Cell: () => null
                },
            ]}
            fetchData={fetchData}
            data={data}
            loading={PaginationData.isLoading}
            pageCount={PaginationData.totalCount}
            currentPage={PaginationData.currentPage}
            defaultPageSize={PaginationData.pageSize}
            defaultFilter={pageFilter}
            defaultSort={pageSort}
            rowsPerPageOptions={{ rowsPerPageOptions: [5] }}
            customLayer={
                <div className={classes.overlay}>
                    <img src={Plus} alt='add' />
                    Add your first requirement
                    <Button onClick={() => history.push(`/employers/assign-requirement/${window.btoa(employer.id)}`)} label="Add Now" alignIcon='left' icon={<AddCircleOutlineOutlined />} />
                </div>
            }
        />
    )
}

export default RequirementList
