import React, { useEffect } from "react";
import { Container, Grid, Hidden } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AppStore from "assets/img/appstore.png";
import SuccessIcon from "assets/img/success-icon.png";
import PhoneWorker from "assets/img/grizzly_phone_workers.png";
import { actions as LoginActions } from "store/modules/login";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        backgroundColor: '#FFF',
        maxWidth: '1000px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    borderTop: {
        borderTop: '1px solid #cccccc',
    },
    link: {
        color: '#337ab7'
    },
    boldFont: {
        fontWeight: "bold"
    },
    handimg: {
        width: '100%'
    }
}));
export default function EmailVerifySuccess() {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LoginActions.consumeVerificationCode());
    }, []);

    return (
        <div className={classes.root}>
            <Container fixed className={classes.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 className={classes.boldFont}>
                            <Hidden xlDown>
                                <img src={SuccessIcon} width="80" />
                            </Hidden>
                                Welcome To The Randstad Team!
                        </h1>
                    </Grid>
                    <Grid item xs={12} className={classes.borderTop}>
                        <h4>You can close this tab/window and return to your app!</h4>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
