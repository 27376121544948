import { makeStyles } from '@mui/styles';
import Button from "components/Button/Button";
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors as AdditionalRequirementsSelectors, actions as AdditionalRequirementsActions } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    button: {
        marginTop: "8px",
    },
    popup: {
        bottom: 0,
        top: 'auto !important'
    }
}));

export default function SummaryFooter() {
    const classes = useStyles();
    const canPost = useSelector(AdditionalRequirementsSelectors.canAssignedEmployers);
    const isLoading = useSelector(AdditionalRequirementsSelectors.getIsRequiremntUpdateLoading);
    const dispatch = useDispatch();
    const params = useParams();

    const handlePost = () => {
        const id = window.atob(params?.id);
        dispatch(AdditionalRequirementsActions.assignEmployers(id));
    };

    return (
        <div className={classes.footer}>
            <Button
                className={classes.button}
                label="Confirm"
                alignIcon="right"
                onClick={handlePost}
                disabled={!canPost || isLoading}
                isLoading={isLoading}
            />
        </div>
    )
};
