import gql from "graphql-tag";

export default gql`
mutation CreateProvinceMinimumPay($data: CreateProvinceMinimumPayInput!) {
    createProvinceMinimumPay(data: $data) {
      id
      isActive
      provinceId
      minimumPay
      effectiveDateFrom
      effectiveDateTo
      minShiftLength
      maxShiftLength
    }
}
`
