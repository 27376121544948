import React from 'react';
import { FormControl, Grid, Switch, TextField, Tooltip } from '@mui/material';
import FormRow from './FormRow';
import { makeStyles } from '@mui/styles';
import GroupsDropdown from './GroupsDropdown';
import WYSIWYGEditor from './Editor';
import { InfoOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementAction } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: '65%',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: '#FFF',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75),
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    }
}));

const ValidationDetailsForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        name, title, frenchTitle, groupNames, summary, frenchSummary, isConsent
    } = useSelector((state) => AdditionalRequirementSelector.getFormData(state));

    const handleChange = (event) => {
        const { target: { value, name } } = event;
        if (name === 'isConsent') {
            const { target: { checked } } = event;
            dispatch(AdditionalRequirementAction.setAdditionalRequirementForm(name, checked));
            return;
        }
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm(name, value?.slice(0, 250)));
    };

    const handleChangeDesription = (value, name) => {
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm(name, value));
    };

    const handleGroupChange = (value) => {
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm('groupNames', value));
    };

    return (
        <>
            <FormRow style={{ marginTop: 24 }}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.fullWidth}
                        id="name"
                        name="name"
                        label="Validation Master Name (For Internal use)*"
                        placeholder="Validation Master Name"
                        fullWidth
                        margin="none"
                        value={name}
                        onChange={handleChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <Grid container spacing={2} item xs={12}>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                className={classes.fullWidth}
                                id="title"
                                name="title"
                                label="Validation Title - English (For Talent)*"
                                placeholder="Validation Title - English"
                                margin="none"
                                fullWidth
                                value={title}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid container item xs={6} style={{ zIndex: 100 }}>
                            <TextField
                                className={classes.fullWidth}
                                id="frenchTitle"
                                name="frenchTitle"
                                label="Validation Title - French (For Talent)*"
                                placeholder="Validation Title - French"
                                margin="none"
                                fullWidth
                                value={frenchTitle}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 12, marginBottom: 8, zIndex: 1051 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Add this Validation in the Group (For Internal use)</div>
                    <GroupsDropdown
                        fullWidth
                        variant="outlined"
                        id="groupNames"
                        name="groupNames"
                        placeholder="Select or Type & enter to add new"
                        value={groupNames}
                        onChange={handleGroupChange}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.fullWidth}
                        id="summary"
                        name="summary"
                        label="Validation Summary  - English (For Talent)*"
                        placeholder="Validation Summary  - English"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={summary}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.fullWidth}
                        id="frenchSummary"
                        name="frenchSummary"
                        label="Validation Summary - French (For Talent)*"
                        placeholder="Validation Summary - French"
                        fullWidth
                        margin="none"
                        onChange={handleChange}
                        value={frenchSummary}
                    />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 12 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Description - English (For Talent)*</div>
                    <WYSIWYGEditor onChange={handleChangeDesription} name="description" value={''} />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 12 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>Description - French (For Talent)*</div>
                    <WYSIWYGEditor onChange={handleChangeDesription} name="frenchDescription" value={''} />
                </FormControl>
            </FormRow>
            <FormRow style={{ marginTop: 12 }}>
                <FormControl fullWidth>
                    <div className={classes.box}>
                        <div className={classes.label}>
                            Is this a consent?
                            {/* <Tooltip title="Lorem ipsum">
                                <InfoOutlined />
                            </Tooltip> */}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} className={classes.label}>
                            <span style={{ fontWeight: !isConsent ? 'bold' : 'normal' }}>No</span>
                            <Switch
                                name={'isConsent'}
                                id={'isConsent'}
                                onChange={handleChange}
                                checked={isConsent}
                                inputProps={{ 'aria-label': 'Switch demo' }} />
                            <span style={{ fontWeight: isConsent ? 'bold' : 'normal' }}>Yes</span>
                        </div>
                    </div>
                </FormControl>
            </FormRow>
        </>
    )
};

export default ValidationDetailsForm;
