import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { BanReasonsLabel } from "helpers/constants/index";
import { employerDetailStyles } from "views/Employer/helpers";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import { convertUTCToLocal } from "utils/time";
import { actions as EmployerDetailActions, selectors as EmployerDetailSelectors } from "store/modules/employerDetails";
import BillingRateHistoryDialog from "./components/BillingRateHistoryDialog";
import EditEmployerDialog from "./components/EditEmployerDialog/EditEmployerDialog";
import EmployerActionMenu from "./components/EmployerActionMenu";
import EmployerBansTable from "./components/EmployerBansTable";
import EmployerFinanceCard from "./components/EmployerFinanceCard";
import EmployerJobsTable from "./components/EmployerJobsTable";
import EmployerManagerTable from "./components/EmployerManagerTable";
import EmployerPrefersTable from "./components/EmployerPrefersTable";
import EmployerProfileCard from "./components/EmployerProfileCard";
import EmployerStatsCard from "./components/EmployerStatsCard";
import EmployerTrendCard from "./components/EmployerTrendCard";
import WorkerPicker from "./components/WorkerPicker";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import RequirementList from "./components/RequirementList";
import SimpleDialog from "components/Dialog/SimpleDialog";
const tableProps = {
    breakLengthFilter: "all", // pageSize: 20, // page: 0,
    rowsPerPageOptions: { rowsPerPageOptions: [5, 10, 20, 25, 50, 100] },
    defaultPageSize: 5,
    // defaultFilterMethod: (filter, row) => String(row[filter.id]) === filter.value,
};
class EmployerDetail extends React.Component {
    state = {
        value: 0,
        canEdit: false,
        showWorkerPicker: false,
        showVerifyEmployer: false,
        showUnverifyEmployer: false,
        showActivateEmployer: false,
        showDeactivateEmployer: false,
        showAddEmployerNote: false,
        showPreferWorker: false,
        showBanWorker: false,
        showBillingRateHistory: false,
        showDeleteAccount: false,
        currentWorker: {},
        banWorkerReason: "NA",
        showClearException: false,
        clearExceptionType: null,
    };

    componentDidMount() {
        this.props.fetchEmployer();
        this.setState({ canEdit: false });
    }

    get currentWorkersList() {
        const { value } = this.state;
        if (value === 1) {
            return this.props.banned;
        }

        if (value === 2) {
            return this.props.preferred;
        }

        return [];
    }

    get clearExceptionDescription() {
        const { clearExceptionType, currentWorker } = this.state;
        const { companyName } = this.props;
        if (clearExceptionType === "banned") {
            return `${currentWorker.fullName} will be removed from the Ban list and they will be able to see ${companyName}'s jobs again.`;
        }

        if (clearExceptionType === "preferred") {
            return `${currentWorker.fullName} will be removed from the Preferred list and they will not have early access ${companyName}'s jobs.`;
        }

        return "";
    }

    handleChange = (event, value) => this.setState({ value, showWorkerPicker: false });
    closeWorkerPicker = () => this.setState({ showWorkerPicker: false });
    openWorkerPicker = () => {
        this.setState(
            {
                showWorkerPicker: true,
            },
            this.scrollToEnd
        );
    };

    scrollToEnd = () => this.scrollEnd.scrollIntoView({ behavior: "smooth" });
    setScrollEndRef = ref => (this.scrollEnd = ref);
    handlePostJob = () => {
        const { history, id } = this.props;
        history.push(`/jobs/post?employerId=${id}`);
    };

    showVerifyEmployer = () => this.setState({ showVerifyEmployer: true });
    hideVerifyEmployer = () => this.setState({ showVerifyEmployer: false });
    handleVerifyEmployer = () =>
        this.setState(
            {
                showVerifyEmployer: false,
            },
            this.props.onVerifyEmployer
        );

    showUnverifyEmployer = () => this.setState({ showUnverifyEmployer: true });
    hideUnverifyEmployer = () => this.setState({ showUnverifyEmployer: false });
    handleUnverifyEmployer = () => {
        this.setState(
            {
                showUnverifyEmployer: false,
            },
            this.props.onUnverifyEmployer
        );
    };

    showActivateEmployer = () => this.setState({ showActivateEmployer: true });
    hideActivateEmployer = () => this.setState({ showActivateEmployer: false });

    handleActivateEmployer = () => {
        this.setState(
            {
                showActivateEmployer: false,
            },
            this.props.onActivateEmployer
        );
    };

    showDeactivateEmployer = () => this.setState({ showDeactivateEmployer: true });
    hideDeactivateEmployer = () => this.setState({ showDeactivateEmployer: false });
    handleDeactivateEmployer = () => {
        this.setState(
            {
                showDeactivateEmployer: false,
            },
            this.props.onDeactivateEmployer
        );
    };

    showPreferWorker = currentWorker => this.setState({ currentWorker, showPreferWorker: true });
    hidePreferWorker = () => this.setState({ showPreferWorker: false });
    handlePreferWorker = () => {
        const { currentWorker } = this.state;
        this.setState(
            {
                showPreferWorker: false,
            },
            () => {
                this.props.onPreferWorker(currentWorker);
            }
        );
    };

    showBanWorker = currentWorker => this.setState({ currentWorker, showBanWorker: true });
    hideBanWorker = () => this.setState({ showBanWorker: false, banWorkerReason: "NA" });
    handleBanWorkerReason = event => {
        this.setState({
            banWorkerReason: event.target.value,
        });
    };

    handleBanWorker = () => {
        const { currentWorker, banWorkerReason } = this.state;
        this.setState(
            {
                showBanWorker: false,
                banWorkerReason: "NA",
            },
            () => {
                this.props.onBanWorker(currentWorker, banWorkerReason);
            }
        );
    };

    showClearException = clearExceptionType => currentWorker => {
        this.setState({
            currentWorker,
            clearExceptionType,
            showClearException: true,
        });
    };
    hideClearException = () => this.setState({ clearExceptionType: null, showClearException: false });
    handleClearException = () => {
        const { currentWorker, clearExceptionType } = this.state;
        this.setState(
            {
                clearExceptionType: null,
                showClearException: false,
            },
            () => {
                this.props.onClearException(currentWorker, clearExceptionType);
            }
        );
    };

    showDeleteManagerDialog = (employerId, managerId) => {
        this.setState({
            showDeleteManager: true,
            employerId,
            managerId
        });
    };
    hideDeleteManagerDialog = () => this.setState({ showDeleteManager: false });
    handleDeactivateManager = () => {
        this.props.deactivateManager(this.state.employerId, this.state.managerId)
        this.hideDeleteManagerDialog();
    }

    handleAddWorker = worker => {
        const { value } = this.state;
        if (value === 1) {
            return this.showBanWorker(worker);
        }

        if (value === 2) {
            return this.showPreferWorker(worker);
        }

        return;
    };

    handleRemoveWorker = worker => {
        const { value } = this.state;
        if (value === 1) {
            return this.showClearException("banned")(worker);
        }

        if (value === 2) {
            return this.showClearException("preferred")(worker);
        }

        return;
    };

    handleSetCanEdit = () => this.setState({ canEdit: !this.state.canEdit });

    handleUpdateEmployerProfile = () => {
        this.props.updateEmployerProfile();
        this.handleSetCanEdit(false);
        this.props.clearEmployerUpdateData();
    };
    showBillingRateHistory = () => {
        this.setState({
            showBillingRateHistory: true,
        });
    };

    hideBillingRateHistory = () => {
        this.setState(
            {
                showBillingRateHistory: false,
            }
        );
    };

    showAddEmployerNote = () => {
        this.setState({
            showAddEmployerNote: true,
        });
    };

    hideAddEmployerNote = () => {
        this.setState(
            {
                showAddEmployerNote: false,
            },
            () => this.props.resetEmployerNote()
        );
    };

    handleAddEmployerNote = () => {
        this.setState(
            {
                showAddEmployerNote: false,
            },
            () => this.props.onAddEmployerNote()
        );
    };

    onAddEmployerNoteChange = data => {
        this.props.updateEmployerNote(data.target.value);
    };

    showDeleteAccount = () => {
        this.setState({ showDeleteAccount: true });
    };

    hideDeleteAccount = () => {
        this.setState({ showDeleteAccount: false });
    };

    handleDeleteAccount = () => {
        this.setState({ showDeleteAccount: false });
        this.props.onDeleteEmployerAccount().then(res => {
            if (res) {
                this.props.history.push(`/employers/overview?employerframe=0`);
            }
        })
    };

    hideDeleteDialog = () => {
        this.props.setSelectedRecordForRemove(null);
    };

    handleRemove = () => {
        this.props.removeAssignedEmployer();
    };

    render() {
        const {
            classes,
            isLoading,
            companyName,
            createdAtUtc,
            approvedAtUtc,
            firstName,
            lastName,
            email,
            mobilePhone,
            street,
            city,
            region,
            country,
            postalCode,
            isActive,
            isApproved,
            websiteUrl,
            rating,
            hoursTrendData,
            positionCount,
            jobCount,
            hoursWorkedCount,
            billingRate,
            payRate,
            employer,
            employerNotes,
            isRemoveLoading,
            selectedRecordForRemove
        } = this.props;

        const { currentWorker } = this.state;
        const tabClass = {
            root: classes.tabRoot,
            selected: classes.tabSelected,
        };

        return (
            <React.Fragment>
                <EditEmployerDialog
                    openDialog={this.state.canEdit}
                    open={this.state.canEdit}
                    title={
                        <React.Fragment>
                            <Typography
                                component="span"
                                style={{ width: "100%", textAlign: "left", fontSize: 18 }}>
                                {`Manage Employer Settings`}
                                <Button
                                    style={{ float: "right", marginTop: -20, marginRight: -30 }}
                                    label="X"
                                    size="small"
                                    onClick={this.handleSetCanEdit}
                                    color="transparent"
                                />
                            </Typography>
                        </React.Fragment>
                    }
                    description={null}
                    confirmText="Update"
                    disableBackdropClick={true}
                    onClose={this.handleCancelUpdateEmployerProfile}
                    onConfirm={this.handleUpdateEmployerProfile}
                    onCancel={this.handleCancelUpdateEmployerProfile}
                    alertType="form"
                    setUpdateEmployerData={this.updateFormData}
                    updateFormData={this.updateFormData}
                    handleToggleChange={this.handleToggleChange}
                    handleSetCanEdit={this.handleSetCanEdit}
                    {...employer}
                />
                <MainContent isLoading={isLoading} noTopPadding>
                    <Grid container justifyContent="flex-end">
                        <EmployerActionMenu
                            canVerify={!isApproved}
                            employerId={this.props.id}
                            canUnverify={isApproved}
                            canActivate={!isActive}
                            canDeactivate={isActive}
                            onPostJob={this.handlePostJob}
                            onVerify={this.showVerifyEmployer}
                            onUnverify={this.showUnverifyEmployer}
                            onActivate={this.showActivateEmployer}
                            onDeactivate={this.showDeactivateEmployer}
                            setCanEdit={this.handleSetCanEdit}
                            onAddEmployerNote={this.showAddEmployerNote}
                            onDeleteAccount={this.showDeleteAccount}
                            requirements={this?.props?.requirements}
                        />
                    </Grid>
                    <Grid container>
                        <EmployerProfileCard
                            companyName={companyName}
                            firstName={firstName}
                            lastName={lastName}
                            websiteUrl={websiteUrl}
                            email={email}
                            phone={mobilePhone}
                            street={street}
                            city={city}
                            region={region}
                            country={country}
                            postalCode={postalCode}
                            isActive={isActive || false}
                            isApproved={isApproved}
                            createdAtUtc={createdAtUtc}
                            approvedAtUtc={approvedAtUtc}
                        />
                        <Grid container item xs={6}>
                            <EmployerTrendCard hoursTrendData={hoursTrendData} />
                            <Grid container item xs={12} className={classes.container}>
                                <EmployerStatsCard
                                    rating={rating}
                                    jobCount={jobCount}
                                    positionCount={positionCount}
                                />
                                <EmployerFinanceCard
                                    totalHoursWorked={hoursWorkedCount}
                                    billingRate={billingRate}
                                    payRate={payRate}
                                    showBillingRateHistory={this.showBillingRateHistory}
                                    employer={employer}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid
                        container
                        className={classes.tabbedTableContainer}
                        alignItems="center"
                        justifyContent="flex-end">
                        <Grid container item xs={12}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                classes={{ indicator: classes.tabIndicator }}>
                                <Tab label="Jobs" classes={tabClass} />
                                <Tab label="Banned Workers" classes={tabClass} />
                                <Tab label="Preferred Workers" classes={tabClass} />
                                <Tab label="Managers" classes={tabClass} />
                                <Tab label="Notes" classes={tabClass} />
                                <Tab label="Requirements" classes={tabClass} />
                            </Tabs>
                        </Grid>
                        <Grid container item xs={6} justifyContent="flex-end">
                            {/* <Button
                            buttonClassName={classes.documents}
                            color="transparent"
                            label="View Documents"
                            icon={<ChevronRight />}
                            alignIcon="right"
                            onClick={() => {}}
                        /> */}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.tabbedTableContainer}>
                        <SwipeableViews axis="x" index={this.state.value} className={classes.tableContainer}>
                            <EmployerJobsTable />
                            <EmployerBansTable
                                onChooseWorker={this.openWorkerPicker}
                                onRemoveWorker={this.showClearException("banned")}
                                scrollToEnd={this.scrollToEnd}
                            />
                            <EmployerPrefersTable
                                onChooseWorker={this.openWorkerPicker}
                                onRemoveWorker={this.showClearException("preferred")}
                                scrollToEnd={this.scrollToEnd}
                            />
                            <EmployerManagerTable onDeleteManager={this.showDeleteManagerDialog} handleCancelManager={this.hideDeleteManagerDialog} />
                            <div>
                                <ReactTableSimple
                                    data={employer ? (employer.employerNote || []) : []}
                                    classes="-highlight"
                                    loading={isLoading}
                                    {...tableProps}
                                    columns={[
                                        {
                                            Header: "Notes",
                                            id: "note",
                                            // eslint-disable-next-line react/display-name, react/prop-types
                                            accessor: ({ note }) => {
                                                return note;
                                            },
                                            disableSortBy: true,
                                            className: "-cursor-pointer",
                                        },
                                        {
                                            Header: "Added By Name",
                                            id: "adminName",
                                            // eslint-disable-next-line react/display-name, react/prop-types
                                            accessor: ({ adminName }) => {
                                                return adminName;
                                            },
                                            disableSortBy: true,
                                            className: "-cursor-pointer",
                                            styles: {
                                                width: 200,

                                            }
                                        },
                                        {
                                            Header: "Added By Email",
                                            id: "adminEmail",
                                            // eslint-disable-next-line react/display-name, react/prop-types
                                            accessor: ({ adminEmail }) => {
                                                return adminEmail;
                                            },
                                            disableSortBy: true,
                                            className: "-cursor-pointer",
                                            styles: {
                                                width: 200,
                                            }
                                        },
                                        {
                                            Header: "Date",
                                            id: "createdAt",
                                            // eslint-disable-next-line react/display-name, react/prop-types
                                            accessor: ({ createdAt }) => {
                                                const start = convertUTCToLocal(
                                                    createdAt,
                                                    "MMM Do, YYYY  h:mm a z"
                                                );

                                                return start;
                                            },
                                            disableSortBy: true,
                                            className: "-cursor-pointer",
                                            styles: { width: 200, }
                                        },
                                    ]}
                                />
                            </div>
                            <RequirementList />
                        </SwipeableViews>
                    </Grid>
                    <BillingRateHistoryDialog
                        openDialog={this.state.showBillingRateHistory}
                        title={`${companyName} billing rate history`}
                        employer={employer}
                        onClose={this.hideBillingRateHistory}
                        onCancel={this.hideBillingRateHistory}
                    />
                    <Dialog
                        open={this.state.showVerifyEmployer}
                        title={`Verify ${companyName}?`}
                        description={`You are about to confirm that all of ${companyName}'s information is correct. They will be able to post jobs.`}
                        confirmText="Verify"
                        onClose={this.hideVerifyEmployer}
                        onConfirm={this.handleVerifyEmployer}
                        onCancel={this.hideVerifyEmployer}
                        alertType="question"
                        icon="verified_user"
                    />
                    <Dialog
                        open={this.state.showUnverifyEmployer}
                        title={`Unverify ${companyName}?`}
                        description={`${companyName} will have to resubmit their information before they can post jobs.`}
                        confirmText="Unverify"
                        onClose={this.hideUnverifyEmployer}
                        onConfirm={this.handleUnverifyEmployer}
                        onCancel={this.hideUnverifyEmployer}
                        alertType="warning"
                        icon="security"
                    />
                    <Dialog
                        open={this.state.showActivateEmployer}
                        title={`Activate ${companyName}?`}
                        description={`This will allow ${companyName} to post jobs.`}
                        confirmText="Activate"
                        onClose={this.hideActivateEmployer}
                        onConfirm={this.handleActivateEmployer}
                        onCancel={this.hideActivateEmployer}
                        alertType="question"
                        icon="check_circle"
                    />
                    <Dialog
                        open={this.state.showDeactivateEmployer}
                        title={`Deactivate ${companyName}?`}
                        description={`${companyName} will no longer be able to post jobs. They will not be notified.`}
                        confirmText="Deactivate"
                        onClose={this.hideDeactivateEmployer}
                        onConfirm={this.handleDeactivateEmployer}
                        onCancel={this.hideDeactivateEmployer}
                        alertType="warning"
                        icon="cancel"
                    />
                    <Dialog
                        open={this.state.showPreferWorker}
                        title={`Prefer ${currentWorker.fullName}?`}
                        description={`You are about to add ${currentWorker.fullName} to ${companyName}'s list of preferred workers.`}
                        onClose={this.hidePreferWorker}
                        onConfirm={this.handlePreferWorker}
                        onCancel={this.hidePreferWorker}
                        alertType="question"
                        icon="person_add"
                    />
                    <Dialog
                        open={this.state.showBanWorker}
                        title={`Ban ${currentWorker.fullName}?`}
                        description={`You are about to ban ${currentWorker.fullName} from his job site.`}
                        onClose={this.hideBanWorker}
                        onConfirm={this.handleBanWorker}
                        onCancel={this.hideBanWorker}
                        alertType="warning"
                        icon="not_interested"
                        disabled={this.state.banWorkerReason === "NA"}>
                        <TextField
                            select
                            fullWidth
                            value={this.state.banWorkerReason}
                            onChange={this.handleBanWorkerReason}
                            margin="normal">
                            <MenuItem value="NA">Select a reason...</MenuItem>
                            {Object.keys(BanReasonsLabel).map(key => (
                                <MenuItem key={key} value={key}>
                                    {BanReasonsLabel[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Dialog>
                    <Dialog
                        open={this.state.showClearException}
                        title="Are you sure?"
                        description={this.clearExceptionDescription}
                        onClose={this.hideClearException}
                        onConfirm={this.handleClearException}
                        onCancel={this.hideClearException}
                        alertType="warning"
                        icon="remove_circle"
                    />
                    <Dialog
                        open={this.state.showDeleteManager}
                        title="Are you sure?"
                        onClose={this.hideDeleteManagerDialog}
                        onConfirm={this.handleDeactivateManager}
                        onCancel={this.hideDeleteManagerDialog}
                        alertType="warning"
                        icon="remove_circle"
                    />
                    <Dialog
                        open={this.state.showAddEmployerNote}
                        title={`Add a note to ${companyName}`}
                        description={`Add a freeform note to ${companyName}'s profile.`}
                        confirmText="Add Note"
                        // disabled={!employerNote.length}
                        onClose={this.hideAddEmployerNote}
                        onConfirm={this.handleAddEmployerNote}
                        onCancel={this.hideAddEmployerNote}
                        icon="note_add"
                        fullWidth={true}
                        alertType="question">
                        <TextField
                            multiline
                            rows={6}
                            fullWidth={true}
                            value={employerNotes}
                            onChange={this.onAddEmployerNoteChange}
                            margin="normal"
                            style={{ marginTop: 36 }}
                        />
                    </Dialog>
                    <Dialog
                        open={this.state.showDeleteAccount}
                        title={`Are you sure to delete account? `}
                        description={'Deleted account should not be recovered'}
                        confirmText="Delete"
                        onClose={this.hideDeleteAccount}
                        onConfirm={this.handleDeleteAccount}
                        onCancel={this.hideDeleteAccount}
                        alertType="warning"
                        icon="remove_circle"
                    />
                    <SimpleDialog
                        open={Boolean(selectedRecordForRemove)}
                        confirmText={"Yes, Remove"}
                        title="Are you sure you wan't to remove this item?"
                        onClose={this.hideDeleteDialog}
                        onConfirm={this.handleRemove}
                        onCancel={this.hideDeleteDialog}
                        isLoading={isRemoveLoading}
                        disabled={!selectedRecordForRemove || isRemoveLoading}
                    />
                </MainContent>
                <WorkerPicker
                    currenList={this.currentWorkersList}
                    open={this.state.showWorkerPicker}
                    onClose={this.closeWorkerPicker}
                    scrollToEnd={this.scrollToEnd}
                    onAddWorker={this.handleAddWorker}
                    onRemoveWorker={this.handleRemoveWorker}
                />
                <div className={classes.scrollEnd} ref={this.setScrollEndRef} />
            </React.Fragment>
        );
    }
}

const mapState = state => {
    const {
        employer,
        hoursTrendData,
        canUpdateEmployer,
        employerNote,
        employerNotes
    } = EmployerDetailSelectors.getEmployerDetails(state);
    return {
        isLoading: EmployerDetailSelectors.getIsPageLoading(state),
        isRemoveLoading: EmployerDetailSelectors.getIsRemoveAssignedEmployerLoading(state),
        selectedRecordForRemove: EmployerDetailSelectors.getIsSelectedRecordForRemove(state),
        hoursTrendData,
        canUpdateEmployer,
        employer,
        employerNote: employerNote,
        employerNotes: employerNotes,
        requirements: EmployerDetailSelectors.getAdditionalRequirementsData(state, { part: "data" }),
        ...employer,
    };
};

const mapAction = {
    fetchEmployer: EmployerDetailActions.fetchEmployer,
    onVerifyEmployer: EmployerDetailActions.verifyEmployer,
    onUnverifyEmployer: EmployerDetailActions.unverifyEmployer,
    onActivateEmployer: EmployerDetailActions.activateEmployer,
    onDeactivateEmployer: EmployerDetailActions.deactivateEmployer,
    onPreferWorker: EmployerDetailActions.preferWorker,
    onBanWorker: EmployerDetailActions.banWorker,
    onAddEmployerNote: EmployerDetailActions.saveEmployerNote,
    onDeleteEmployerAccount: EmployerDetailActions.onDeleteEmployerAccount,
    updateEmployerNote: EmployerDetailActions.updateEmployerNote,
    resetEmployerNote: EmployerDetailActions.resetEmployerNote,
    onClearException: EmployerDetailActions.clearException,
    setUpdateEmployerData: EmployerDetailActions.setUpdateEmployerData,
    updateEmployerProfile: EmployerDetailActions.updateEmployerProfile,
    clearEmployerUpdateData: EmployerDetailActions.clearEmployerUpdateData,
    deactivateManager: EmployerDetailActions.deactivateManager,
    setSelectedRecordForRemove: EmployerDetailActions.setSelectedRecordForRemove,
    removeAssignedEmployer: EmployerDetailActions.removeAssignedEmployer,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchEmployer } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        fetchEmployer: () => fetchEmployer(ownProps.match.params.id),
        ...ownProps,
    };
};

export default connect(mapState, mapAction, mergeProps)(employerDetailStyles(EmployerDetail));
