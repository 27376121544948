import { Button, CircularProgress, Dialog, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import UploadFileIcon from 'assets/img/uploadfile.svg';
import { CloseOutlined, TextSnippetOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/workerDetails';
import Dropzone from "components/Dropzone/Dropzone";
import { useParams } from 'react-router-dom';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5),
        minWidth: 400
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5)
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    approveRejectButton: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
    note: {
        background: 'rgba(227, 239, 255, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: theme.spacing(1.25, 2, 1.25, 2),
        margin: theme.spacing(1),
        marginTop: theme.spacing(0),
        '& p': {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '20px',
            color: '#000000'
        }
    },
    approveRejectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1.25),
        width: '80%'
    },
    dropzone: {
        background: 'white !important',
        height: '150px !important',
        minWidth: '330px !important',
        display: 'flex !important',
        padding: `${theme.spacing(2)} !important`,
        flexDirection: 'column !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        borderColor: '#2175D9 !important',
        marginTop: `${theme.spacing(5)} !important`,
        gap: 4,
        '& .title': {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '20px',
            color: '#202326'
        },
        '& .sub-title': {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#666666'
        }
    },
    activeDropzone: {
        background: 'white !important',
        height: '150px !important',
        minWidth: '330px !important',
        display: 'flex !important',
        padding: `${theme.spacing(2)} !important`,
        flexDirection: 'column !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        borderColor: '#009E96 !important',
        marginTop: `${theme.spacing(5)} !important`,
        gap: 4,
        '& .title': {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '20px',
            color: '#202326',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            WebkitLineClamp: 1,
            whiteSpace: 'nowrap',
            lineClamp: 1,
            maxWidth: '100%'
        },
        '& .sub-title': {
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#009E96',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            WebkitLineClamp: 1,
            whiteSpace: 'nowrap',
            lineClamp: 1,
            maxWidth: '100%'
        },
        '& $fileIcon': {
            background: '#E5F9F3',
            '& svg': {
                fill: '#009E96',
            }
        }
    },
    fileIcon: {
        minHeight: 52,
        width: 52,
        alignItems: 'center',
        justifyContent: 'center',
        background: '#E9F1FB',
        display: 'flex',
        borderRadius: '50%',
        marginBottom: theme.spacing(1),
        '& svg': {
            fill: '#2175D9',
            height: 24,
            width: 24,
        }
    }
}));

export default function UploadAndApproveDialog({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const isLoading = useSelector(Selectors.getUploadApproveLoading);
    const selectedRequirement = useSelector(Selectors.getUploadApproveDialog);
    const dispatch = useDispatch();
    const [file, setFile] = useState();
    const params = useParams()
    let workerId;
    const URLParams = new URLSearchParams(history.location.search);
    const type = parseInt(URLParams.get("type"), 10);
    try {
        workerId = params?.id ? window.atob(params.id) : null;
    } catch (er) {
        console.log(er);
    };

    const refreshData = async (param) => {
        return await dispatch(Actions.fetchPerRequirementsData({ workerId, filterBy: type === 0 ? "requirement" : "employer" }, param));
    };

    const handleApprove = () => {
        dispatch(Actions.uploadAndApprove(file, workerId, refreshData));
    };

    useEffect(() => {
        if (!open) {
            setFile(null);
        }
    }, [open]);

    return (
        <Dialog maxWidth={'xs'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={UploadFileIcon} className={classes.icon} />}
                    <IconButton
                        disabled={isLoading}
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                {selectedRequirement?.data?.applicationStatus === "AlternateDocument" ?
                    <Typography className={classes.title}>
                        <b>Upload alternate document({selectedRequirement?.additionalDoc?.employerRequirement?.requirement?.name}) for {
                            (selectedRequirement.ModalType === 'Employer' ?
                                selectedRequirement?.data?.employerRequirement?.requirement?.name :
                                selectedRequirement?.name) || '-'}</b>
                    </Typography>
                    : <Typography className={classes.title}>
                        <b>Upload {
                            (selectedRequirement.ModalType === 'Employer' ?
                                selectedRequirement?.data?.employerRequirement?.requirement?.name :
                                selectedRequirement?.name) || '-'}</b>
                    </Typography>}
                <div>
                    <Dropzone
                        disabled={isLoading}
                        className={file ? classes.activeDropzone : classes.dropzone}
                        CustomIcon={() => <div className={classes.fileIcon}><TextSnippetOutlined /></div>}
                        label={"Click here to upload"}
                        supportedFiles={"Supported formats: pdf, png, jpg"}
                        accept={"application/pdf, image/jpeg, image/jpeg, image/png"}
                        onFilesAdded={(files) => setFile(files[0])}
                        selectedFile={file}
                    />
                </div>
                <div className={classes.actionContainer}>
                    <div className={classes.approveRejectContainer}>
                        <Button
                            variant="contained"
                            className={classes.approveRejectButton}
                            color="secondary"
                            disabled={!file || isLoading}
                            onClick={handleApprove}
                        >
                            Submit & Approve
                            {isLoading && <CircularProgress size={24} className={classes.progress} />}
                        </Button>
                    </div>
                    <Button
                        onClick={onClose}
                        variant="text"
                        disabled={isLoading}
                        className={classes.button}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
