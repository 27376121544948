import {gql} from "@apollo/client";

export default gql`
  query findBreakRuleByShiftAndRegion($data: FindBreakRuleInput!) {
    findBreakRuleByShiftAndRegion(data: $data) {
        id
        provinceId
        breakType
        maxShiftLength
        minBreakMinutes
        minShiftLength
        isActive
        province{
            id
            name
            code
        }
    }
  }
`;
