import { Button, CircularProgress, Dialog, DialogTitle, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import RejectIcon from 'assets/img/reject.svg';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(2.5)
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: -20,
        top: -20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5),
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '80%',
        width: '80%'
    },
    outlined: {
        flex: 1,
        maxWidth: '90%',
        width: '90%',
        whiteSpace: 'nowrap',
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
            background: 'white !important'
        },
        borderRadius: 50,
        boxShadow: 'none'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function RejectedInfo({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedRequirement = useSelector(Selectors.getRejectedInfoDialog);

    const handleRequest = () => {
        dispatch(Actions.setRejectedAdditionalDocDialog(selectedRequirement));
        dispatch(Actions.setRejectedInfoDialog(false));
    };

    return (
        <Dialog maxWidth={'xs'} onClose={onClose} open={open} className={classes.dialog}>
            <div className={classes.container}>
                <div className={classes.modalIconContainer}>
                    {icon || <img src={RejectIcon} className={classes.icon} />}
                    <IconButton
                        className={classes.close}
                        onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
                <Typography className={classes.title}>
                    Requirement has been Rejected.
                </Typography>
                <div className={classes.actionContainer}>
                    <Button
                        variant="outlined"
                        className={classes.outlined}
                        onClick={handleRequest}
                    >
                        Request for Additional Document
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="text"
                        className={classes.button}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
