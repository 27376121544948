import { Button, Chip, CircularProgress, IconButton, Paper, Typography } from '@mui/material'
import classNames from 'classnames'
import ReactTable from 'components/ReactTable/ReactTableSimple'
import React, { useEffect } from 'react'
import SelectWorkType from './SelectWorkType';
import SelectValidity from './SelectValidity';
import { makeStyles } from '@mui/styles';
import { AddOutlined, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/employerDetails';
import { useParams } from 'react-router-dom';
import MandatoryField from './MandatoryField';

const useStyles = makeStyles(theme => ({
    Consent: {
        backgroundColor: '#F1EEFF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Document: {
        backgroundColor: '#E5F1FF',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    Validation: {
        backgroundColor: '#FFE5F6',
        color: '#6B6F75',
        marginRight: theme.spacing(2)
    },
    addMore: {
        borderRadius: 30,
        background: theme.palette.background.paper,
        padding: '7px 15px'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 4,
        minWidth: 185
    },
    noteRow: {
        '& div': {
            borderTop: '1px solid rgb(0 0 0 / 20%)',
            marginTop: 12,
            padding: 12,
            paddingLeft: 12,
        }
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'fixed',
        bottom: 12,
        right: 50
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function RequirementsGroupTable({ data }) {
    const classes = useStyles();
    const highlightedRecord = useSelector(Selectors.getHighlightedRecord);
    const existingRecords = useSelector(Selectors.getExistingRecord);
    const canAssign = useSelector(Selectors.getCanAssign);
    const isLoading = useSelector(Selectors.getIsRequiremntUpdateLoading);
    const dispatch = useDispatch();
    const params = useParams();
    const employerId = params?.id ? window.atob(params.id) : null;

    useEffect(() => {
        if (existingRecords?.length) {
            setTimeout(() => {
                dispatch(Actions.setExistingRecord([]));
            }, 5000);
        }
    }, [existingRecords?.length]);

    useEffect(() => {
        if (highlightedRecord) {
            const element = document.getElementById(`Requirement-${highlightedRecord.id}`);
            element.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center' });
            setTimeout(() => {
                dispatch(Actions.setHighlightedRecord(null));
            }, 2000);
        }
    }, [highlightedRecord]);

    const handleRemove = (rowIndex, requirementIndex) => {
        dispatch(Actions.handleRemoveRequirement(rowIndex, requirementIndex));
    }

    const handleAdd = (rowIndex, requirementIndex) => {
        dispatch(Actions.handleAddRequirement(rowIndex, requirementIndex));
    }

    const handleConfirm = () => {
        dispatch(Actions.handleAssign(employerId));
    }

    return (
        <div>
            <div style={{ position: "relative", width: "100%" }}>
                <Paper className="react-table-main">
                    <table className={classNames("table", "-highlight")}>
                        <thead>
                            <tr className="header-row">
                                <th>Requirement</th>
                                <th>Select Work Type</th>
                                <th>Valid till days/date</th>
                                <th>Is Mandatory</th>
                                <th>Type</th>
                                <th className='collapse'>Actions</th>
                            </tr>
                            <tr className="header-filter-row">
                                <th>
                                    <div>{null}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((row, ind) => {
                                return (
                                    <>
                                        {row.group && <tr key={row.group.id + ind} className="header-row">
                                            <th style={{ textAlign: 'left' }} colSpan={6}>{row.group.name}</th>
                                        </tr>}
                                        {row.requirements?.map((req, i) => {
                                            const isMulti = row?.requirements?.filter(a => a?.id === req?.id)?.length > 1;
                                            const firstRowIndex = row?.requirements?.findIndex(a => a?.id === req?.id);
                                            return (
                                                <tr id={`Requirement-${req.id}`} key={`${i}-${ind}`}
                                                    className={
                                                        `data-row ${highlightedRecord?.id === req.id ? 'highlighted' : ''} ${existingRecords?.find(e => e.workTypeId === req?.workType?.value && e.requirementId === req?.id) ? '-exist-highlight' : ''}`
                                                    }>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <Typography style={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 1,
                                                            lineClamp: 1,
                                                            WebkitBoxOrient: 'vertical'
                                                        }}>
                                                            {req.name}
                                                        </Typography>
                                                    </td>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <SelectWorkType requirements={data} requirementIndex={i} rowIndex={ind} requirement={req} />
                                                    </td>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <SelectValidity requirementIndex={i} rowIndex={ind} requirement={req} />
                                                    </td>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <MandatoryField requirementIndex={i} rowIndex={ind} requirement={req} />
                                                    </td>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <Chip className={classes[req.type]} label={req.type} />
                                                    </td>
                                                    <td style={{ paddingTop: 8, paddingBottom: 8 }} className='collapse'>
                                                        <div className={classes.actionContainer}>
                                                            {(!isMulti || (isMulti && firstRowIndex === i)) && (<Button
                                                                onClick={() => handleAdd(ind, i)}
                                                                size='small'
                                                                className={classes.addMore}
                                                                color="secondary"
                                                                variant="outlined"
                                                                startIcon={<AddOutlined />}>
                                                                Add Work Type
                                                            </Button>)}
                                                            <IconButton size='small' onClick={() => handleRemove(ind, i)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </tbody>
                        <tbody>
                            <tr className={classes.noteRow}>
                                <td colSpan={6}>
                                    <div>
                                        Note
                                        <ul style={{ paddingLeft: 24, marginBottom: 0 }}>
                                            <li>
                                                Deleting a requirement does not change the grouping.
                                            </li>
                                            <li>
                                                All the workers who apply for a job from this employer will be required to upload all these document(s).
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Paper>
            </div>
            <div className={classes.actionButton}>
                <Button
                    disabled={!canAssign || isLoading}
                    variant='contained'
                    color={"secondary"}
                    onClick={handleConfirm}
                    style={{ borderRadius: 50 }}>
                    Confirm
                    {isLoading && <CircularProgress size={24} className={classes.progress} />}
                </Button>
            </div>
        </div>
    )
}
