import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import _debounce from "lodash/debounce";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { sharedReactTableProps } from "../../../../utils/constant";
import ReactTableCustom from "components/ReactTable";

function WorkerJobCountTable() {
    const dispatch = useDispatch();
    const workerData = useSelector(ReportsSelectors.getWorkerJobCountReportData)
    const PaginationData = useSelector(ReportsSelectors.getWorkerJobCountReportPagingData);
    const defaultFilter = useSelector(ReportsSelectors.getWorkerJobCountReportFilter);

    const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
        dispatch(ReportsActions.changeWorkerJobCountPageSize(pageSize))
        dispatch(ReportsActions.changeWorkerJobCountFilter(filters))
        dispatch(ReportsActions.fetchWorkerJobCountTableData(pageIndex))
    }, []);

    const columns = [
        {
            Header: "First Name",
            disableSortBy: true,
            accessor: "firstName",
            Cell: ({ row: { original } }) => original.FirstName
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            accessor: "lastName",
            Cell: ({ row: { original } }) => original.LastName
        },
        {
            Header: "Email",
            disableSortBy: true,
            minWidth: 200,
            accessor: "email",
        },
        {
            Header: "Phone",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Phone",
        },
        {
            Header: "Talent ID",
            disableSortBy: true,
            disableFilters: true,
            accessor: "TalentID",
            Cell: ({ row: { original } }) => original.TalentID || "-"
        },
        {
            Header: "Job Count",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobCount",
        },
        {
            Header: "City",
            disableSortBy: true,
            accessor: "city",
            Cell: ({ row: { original } }) => original.City
        },
        {
            Header: "Province",
            disableSortBy: true,
            accessor: "region",
            Cell: ({ row: { original } }) => original.Province
        },
        {
            Header: "Last Company",
            disableSortBy: true,
            disableFilters: true,
            accessor: "LastCompany",
        },
        {
            Header: "Last Job Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "LastJob",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.LastJob, "", "MM/DD/YYYY h:mm a")
        },
        {
            Header: "Company JDE Number",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyJDENumber",
            Cell: ({ row: { original } }) => original?.CompanyJDENumber || "-",
        },
        {
            Header: "Invoicing JDE Number",
            disableSortBy: true,
            disableFilters: true,
            accessor: "InvoicingJDENumber",
            Cell: ({ row: { original } }) => original?.InvoicingJDENumber || "-",
        },
    ];

    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={workerData}
                hasFilter={true}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                defaultFilter={defaultFilter?.like || []}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WorkerJobCountTable;
