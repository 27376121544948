import { Close, KeyboardArrowDown } from '@mui/icons-material';
import { AppBar, Button, Chip, FormControl, Grid, IconButton, Menu, MenuItem, Paper, Tabs, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles'
import CustomizedEmployerAutocomplete from 'components/Autocomplete/CustomizedEmployerAutocomplete';
import RequirementsAutocomplete from 'components/Autocomplete/RequirementsAutocomplete';
import WorkerAutocomplete from 'components/Autocomplete/WorkerAutocomplete';
import React, { useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as Selectors, actions as Actions } from 'store/modules/verifications';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "24px 32px",
        paddingBottom: ({ filters }) => filters?.length ? 0 : 24,
        background: theme.palette.background.paper,
        borderBottom: 0,
        position: "relative",
        width: "100%",
        zIndex: 1029,
        color: "#555555",
        border: 0,
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        transition: "all 150ms ease 0s",
        minHeight: 80,
        display: "flex",
        alignItems: 'center',
        marginBottom: 0,
        flexDirection: 'row'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    autocomplete: {
        maxWidth: 250,
        '& p.MuiTypography-root': {
            whiteSpace: 'nowrap',
            maxWidth: '95%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        '& > div': {
            height: '100%',
            '& .MuiFormControl-root': {
                height: '100%',
                '& .MuiInputBase-root': {
                    height: '100%',
                }
            }
        }
    },
    dropdown: {
        width: '100%',
        maxWidth: 250,
        background: 'white',
        color: '#a4a7ab',
        border: "1px solid rgba(0, 0, 0, 0.23)",
        height: '100%',
        textTransform: 'capitalize',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2, 1, 1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': {
            background: 'white',
            border: "1px solid black",
        },
        '& .MuiTypography-root': {
            color: ({ dateFilter }) => dateFilter?.type ? 'black' : '#a4a7ab',
        },
        '& .MuiButton-icon.MuiButton-endIcon': {
            marginLeft: 0
        }
    },
    dateContainer: {
        '& .MuiPaper-root': {
            padding: theme.spacing(5, 3, 2, 3),
            width: 280
        }
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        marginTop: theme.spacing(3)
    },
    tabsContainer: {
        '& .MuiTabs-root': {
            display: 'flex',
            alignItems: 'center',
            '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                'display': 'none'
            },
            '& .MuiTabs-flexContainer': {
                display: 'flex',
                gap: theme.spacing(1)
            }
        }
    },
    clearButton: {
        marginLeft: 'auto',
        padding: 0,
        '& svg': {
            fontSize: 16
        }
    }
}));

export default function Filters() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElCustom, setAnchorElCustom] = useState(null);
    const open = Boolean(anchorEl);
    const openCustom = Boolean(anchorElCustom);
    const dispatch = useDispatch();
    const pageFilter = useSelector((state) => Selectors.getJobApplicationsData(state, { part: "filter" }));
    const employerFilters = pageFilter?.find(a => a?.id === "Employer_Id");
    const requirementFilters = pageFilter?.find(a => a?.id === "Requirement_Id");
    const workerFilters = pageFilter?.find(a => a?.id === "Worker_Id");
    const flatFilters = [...(employerFilters?.value || []), ...(requirementFilters?.value || []), ...(workerFilters?.value || [])];
    const appliedDateFilter = pageFilter?.find(a => a?.id === "date");
    const classes = useStyles({ filters: flatFilters, dateFilter: appliedDateFilter });
    const [dateRange, setDateRange] = useState({ start: moment(), end: moment().clone().add(1, 'week') });


    const handleChangeEmployer = (employer) => {
        const findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Employer_Id');
        if (findInd1 < 0 || !pageFilter[findInd1]?.value?.find(a => a?.value === employer.value)) {
            if (employer)
                if (findInd1 >= 0 && !pageFilter[findInd1]?.value?.find(a => a?.value === employer.value)) {
                    dispatch(Actions.setJobApplicationFilter(Object.assign([...pageFilter], {
                        [findInd1]: {
                            ...pageFilter[findInd1],
                            value: [...(pageFilter[findInd1]?.value || []), { label: employer.label, value: employer.value, addedTime: new Date(), type: 'employer' }]
                        }
                    })));
                } else {
                    dispatch(Actions.setJobApplicationFilter([...pageFilter, { id: 'Employer_Id', value: [{ label: employer.label, value: employer.value, addedTime: new Date(), type: 'employer' }] }]));
                }
            else
                dispatch(Actions.setJobApplicationFilter(pageFilter?.filter(a => a.id !== 'Employer_Id')));
            dispatch(Actions.fetchJobsApplications(0));
        }
    };

    const handleChangeRequirement = (req) => {
        const findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Requirement_Id');
        if (findInd1 < 0 || !pageFilter[findInd1]?.value?.find(a => a?.value === req.value)) {
            if (req)
                if (findInd1 >= 0 && !pageFilter[findInd1]?.value?.find(a => a?.value === req.value)) {
                    dispatch(Actions.setJobApplicationFilter(Object.assign([...pageFilter], {
                        [findInd1]: {
                            ...pageFilter[findInd1],
                            value: [...(pageFilter[findInd1]?.value || []), { label: req.label, value: req.value, addedTime: new Date(), type: 'requirement' }]
                        }
                    })));
                } else {
                    dispatch(Actions.setJobApplicationFilter([...pageFilter, { id: 'Requirement_Id', value: [{ label: req.label, value: req.value, addedTime: new Date(), type: 'requirement' }] }]));
                }
            else
                dispatch(Actions.setJobApplicationFilter(pageFilter?.filter(a => a.id !== 'Requirement_Id')));
            dispatch(Actions.fetchJobsApplications(0));
        }
    };

    const handleChangeWorker = (worker) => {
        const findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Worker_Id');
        if (findInd1 < 0 || !pageFilter[findInd1]?.value?.find(a => a?.value === worker.value)) {
            if (worker)
                if (findInd1 >= 0 && !pageFilter[findInd1]?.value?.find(a => a?.value === worker.value)) {
                    dispatch(Actions.setJobApplicationFilter(Object.assign([...pageFilter], {
                        [findInd1]: {
                            ...pageFilter[findInd1],
                            value: [...(pageFilter[findInd1]?.value || []), { label: worker.label, value: worker.value, addedTime: new Date(), type: 'worker' }]
                        }
                    })));
                } else {
                    dispatch(Actions.setJobApplicationFilter([...pageFilter, { id: 'Worker_Id', value: [{ label: worker.label, value: worker.value, addedTime: new Date(), type: 'worker' }] }]));
                }
            else
                dispatch(Actions.setJobApplicationFilter(pageFilter?.filter(a => a.id !== 'Worker_Id')));
            dispatch(Actions.fetchJobsApplications(0));
        }
    };

    const onDelete = (filter) => {
        let findInd1;
        switch (filter?.type) {
            case 'employer': {
                findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Employer_Id');
                break;
            }
            case 'worker': {
                findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Worker_Id');
                break;
            }
            case 'requirement': {
                findInd1 = pageFilter?.findIndex?.(a => a?.id === 'Requirement_Id');
                break;
            }
        };
        const data = Object.assign([...pageFilter], {
            [findInd1]: {
                ...pageFilter[findInd1],
                value: pageFilter[findInd1].value.filter(a => a.value !== filter.value)
            }
        });
        if (!data[findInd1]?.value?.length)
            delete data[findInd1];
        dispatch(Actions.setJobApplicationFilter(data?.filter(a => a)));
        dispatch(Actions.fetchJobsApplications(0));
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickCustom = (event) => {
        setAnchorElCustom(event.currentTarget);
    };

    const handleCloseCustom = () => {
        setAnchorElCustom(null);
    };

    const handleSelectDateFilter = (type) => {
        const now = moment();
        const start = now.clone().startOf('week');
        const end = now.clone().endOf('week');
        const TYPES = {
            "Today": [now.clone().format("YYYY-MM-DD"), now.clone().format("YYYY-MM-DD")],
            "Tomorrow": [now.clone().add(1, 'day').format("YYYY-MM-DD"), now.clone().add(1, 'day').format("YYYY-MM-DD")],
            "This Week": [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")],
        };

        const payload = {
            type,
            id: 'date',
            value: TYPES[type]
        };

        const findInd1 = pageFilter?.findIndex?.(a => a?.id === 'date');
        if (findInd1 >= 0) {
            dispatch(Actions.setJobApplicationFilter(Object.assign([...pageFilter], {
                [findInd1]: {
                    ...pageFilter[findInd1],
                    ...payload
                }
            })));
        } else {
            dispatch(Actions.setJobApplicationFilter([...pageFilter, payload]));
        }
        dispatch(Actions.fetchJobsApplications(0));
        handleClose();
    }

    const handleApplyDateRange = () => {
        const payload = {
            type: `${dateRange.start.format("DD-MM-YYYY")} - ${dateRange.end.format("DD-MM-YYYY")}`,
            id: 'date',
            value: [dateRange.start.format("YYYY-MM-DD"), dateRange.end.format("YYYY-MM-DD")]
        };

        const findInd1 = pageFilter?.findIndex?.(a => a?.id === 'date');
        if (findInd1 >= 0) {
            dispatch(Actions.setJobApplicationFilter(Object.assign([...pageFilter], {
                [findInd1]: {
                    ...pageFilter[findInd1],
                    ...payload
                }
            })));
        } else {
            dispatch(Actions.setJobApplicationFilter([...pageFilter, payload]));
        }
        dispatch(Actions.fetchJobsApplications(0));
        handleCloseCustom();
        handleClose();
    }

    const handleClearDateFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(Actions.setJobApplicationFilter(pageFilter?.filter(a => a.id !== 'date')));
        dispatch(Actions.fetchJobsApplications(0));
    };

    return (
        <AppBar className={classes.container}>
            <div className={classes.header}>
                <Grid container spacing={1}>
                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography fontWeight={700} marginTop={1}>Filter by</Typography>
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={1}>
                            <Grid item xs={3} className={classes.autocomplete}>
                                <Button
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    className={classes.dropdown}
                                    id="demo-customized-button"
                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<KeyboardArrowDown />}
                                >
                                    <Typography>
                                        {appliedDateFilter?.type || "Job's Start Date"}
                                    </Typography>
                                    {Boolean(appliedDateFilter?.type) && <IconButton onClick={handleClearDateFilter} className={classes.clearButton} size="small"><Close /></IconButton>}
                                </Button>
                                <Menu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                        sx: { width: anchorEl && anchorEl.offsetWidth } // <-- The line that does all
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem disabled={appliedDateFilter?.type === 'Today'} onClick={() => handleSelectDateFilter('Today')} disableRipple>
                                        Today
                                    </MenuItem>
                                    <MenuItem disabled={appliedDateFilter?.type === 'Tomorrow'} onClick={() => handleSelectDateFilter('Tomorrow')} disableRipple>
                                        Tomorrow
                                    </MenuItem>
                                    <MenuItem disabled={appliedDateFilter?.type === 'This Week'} onClick={() => handleSelectDateFilter('This Week')} disableRipple>
                                        This Week
                                    </MenuItem>
                                    <MenuItem
                                        disabled={appliedDateFilter?.type && appliedDateFilter?.type !== 'Today' && appliedDateFilter?.type !== 'Tomorrow' && appliedDateFilter?.type !== 'This Week'}
                                        id="demo-Custom-button"
                                        aria-controls={openCustom ? 'demo-Custom-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openCustom ? 'true' : undefined}
                                        variant="contained"
                                        onClick={handleClickCustom}
                                        disableRipple>
                                        Custom
                                    </MenuItem>
                                </Menu>
                                <Menu
                                    id="demo-Custom-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-Custom-button',
                                    }}
                                    anchorEl={anchorElCustom}
                                    open={openCustom}
                                    onClose={handleCloseCustom}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    className={classes.dateContainer}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <>
                                            <Grid container spacing={4.5}>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <MobileDatePicker
                                                            label={`Start Date`}
                                                            inputFormat='DD-MM-YYYY'
                                                            value={dateRange.start}
                                                            onChange={(start) => setDateRange(p => ({ ...p, start }))}
                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <MobileDatePicker
                                                            label={`End Date`}
                                                            inputFormat='DD-MM-YYYY'
                                                            minDate={dateRange.start}
                                                            value={dateRange.end}
                                                            onChange={(end) => setDateRange(p => ({ ...p, end }))}
                                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div className={classes.actionContainer}>
                                                <Button onClick={handleCloseCustom} color='error' variant='text'>
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleApplyDateRange} disabled={!dateRange?.start || !dateRange?.end || moment(dateRange.start).isAfter(dateRange.end)} color='secondary' variant='contained'>
                                                    Apply
                                                </Button>
                                            </div>
                                        </>
                                    </LocalizationProvider>
                                </Menu>
                            </Grid>
                            <Grid item xs={3} className={classes.autocomplete}>
                                <CustomizedEmployerAutocomplete onChange={handleChangeEmployer} value={null} menuPortalTarget={document.body} placeholder="Employer" />
                            </Grid>
                            <Grid item xs={3} className={classes.autocomplete}>
                                <RequirementsAutocomplete onChange={handleChangeRequirement} value={null} defaultOptions={false} menuPortalTarget={document.body} placeholder="Requirement" />
                            </Grid>
                            <Grid item xs={3} className={classes.autocomplete}>
                                <WorkerAutocomplete onChange={handleChangeWorker} value={null} label='' menuPortalTarget={document.body} placeholder="Worker" />
                            </Grid>
                        </Grid>
                        {Boolean(flatFilters && flatFilters?.length) && <div className={classes.tabsContainer}>
                            <Tabs
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                {flatFilters?.sort((a, b) => a.addedTime - b.addedTime)?.map(filter => (
                                    <Chip key={filter.value} label={filter.label} onDelete={() => onDelete(filter)} />
                                ))}
                            </Tabs>
                        </div>}
                    </Grid>
                </Grid>
            </div>
        </AppBar>
    )
};
