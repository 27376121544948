/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";

import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "moment-timezone";
import moment from "moment-timezone";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TextField } from '@mui/material';
import { ArrowDropDown, ArrowRight, CancelScheduleSend, MoreVert } from "@mui/icons-material";
import CancelTransferDialog from "views/Finance/FinanceOverview/components/Dialogs/CancelTransferDialog";
import ResendDialog from "views/Finance/FinanceOverview/components/Dialogs/ResendDialog";
import DcHeader from "views/Finance/FinanceOverview/components/helpers/DcTransfersHeader";
import { RenderTransferAvatar } from "views/Finance/FinanceOverview/components/helpers/WorkerAvatar";
import IndividualTransfersActions from "views/Finance/FinanceOverview/components/Menus/IndividualTransfersActions";
import ReversalsTabTableActions from "views/Finance/FinanceOverview/components/Menus/ReversalsTabTableActions";
import SubTransfersActions from "views/Finance/FinanceOverview/components/Menus/SubTransfersActions";
import {
    CustomTableCell,
    CustomTableRow,
    deductionTypes,
    earningTypes,
    statuses,
    transfersTabStyles,
    OverTimeIds,
    VacationPayId,
    TimeBasePremiumId,
} from "views/Finance/helpers";
import { DcTransferStatusBadge } from "components/Payments/DcBadge";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { convertUTCToLocal, convertUTCToTimezone, convertUTCToTimezoneWithOffset } from "../../../../../../utils/time";
import { getDeductionType } from "../../../../../Job/JobDetails/components/JobPositions/components/PositionTable/tables/columns/helpers";
import _debounce from "lodash/debounce";
import { transferStatuses } from "../../../../../../utils/constant";
import Datetime from "react-datetime";
import ReactTable from "components/ReactTable";
import { MenuItem, Select } from "@mui/material";
import classNames from "classnames";
import { downloadFileFromLink } from "utils/file";
import { actions as MetaActions } from "store/modules/meta";

export const sharedProps = {
    showPageSizeOptions: false,
    manual: true,
    disableSortBy: true,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    className: "-highlight",
    minRows: 0,
    pageSizeOptions: [10, 20, 40, 80, 100], // No more than this
};
function TransfersTab({ toggleOpenDcReversal, toggleResendStubDialog }) {
    const classes = transfersTabStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const dcTransfers = useSelector(FinanceSelectors.getDcTransfers);
    const PaginationData = useSelector(FinanceSelectors.getTransfersPaginationData);
    const isCancelLoading = useSelector(FinanceSelectors.getIsCancelTransaction);
    const Filters = useSelector(FinanceSelectors.getTransfersFilters);
    const [, setOpen] = React.useState(false);
    const [transferData, setTransferData] = useState({});
    const [pmt, setPmt] = useState({});
    const [pmtId, setPmtId] = useState(null);
    const [showAgo] = useState(true);
    const [, setOpenPayrollDialog] = useState(false);
    const [, setOpenConfirmDialog] = useState(false);
    const [openConfirmResendDialog, setOpenConfirmResendDialog] = useState(false);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false);
    const [openResolveReversalDialog, setOpenResolveReversalDialog] = useState(false);
    const [openResolveDialog, setOpenResolveDialog] = useState(false);
    const isResendPayment = useSelector(FinanceSelectors.getIsResendPayment);
    const isMarkingPaymentResolved = useSelector(FinanceSelectors.getIsMarkingPaymentResolved);

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        dispatch(FinanceActions.setTransferPageSize(pageSize));
        dispatch(FinanceActions.setTransferFilter(filters));
        dispatch(FinanceActions.fetchTransfersNew(pageIndex));
    }, []);


    const handleClose = () => {
        setOpen(false);
        setOpenPayrollDialog(false);
        setOpenConfirmDialog(false);
        setOpenConfirmCancelDialog(false);
        setOpenConfirmResendDialog(false);
        setOpenResolveDialog(false);
        dispatch(FinanceActions.setCancelTransferReason('Transaction cancel'));
    };

    const RenderTdProps = () => ({
        onClick: (e, handleOriginal) => {
            if (handleOriginal) {
                handleOriginal();
            }
        },
    });

    const onExpandedChange = () => { };

    const handleViewProfile = workerId => history.push(`/workers/${workerId}`);

    const handleResendTransfer = () => {
        dispatch(MetaActions.successToast(`Payment is being sent to ${(transferData && transferData.worker && typeof transferData.worker === 'object')
            ? transferData.worker.firstName + ' ' + transferData.worker.lastName
            : transferData && transferData.worker
                ? transferData.worker
                : ""} worker`));
        dispatch(FinanceActions.retryPayment(pmtId)).then(() => {
            dispatch(FinanceActions.fetchPaymentsOverview());
            dispatch(FinanceActions.fetchReversals({ status: null }));
            setOpenConfirmResendDialog(false);
        });
    };

    const handleClickOpenResendDialog = item => {
        setPmtId(item.id);
        setOpenConfirmResendDialog(true);
        setTransferData(item)
    };

    const handleClickOpenResendReversalDialog = item => {
        setPmtId(item.id);
        setOpenConfirmResendDialog(true);
    };

    const handleClickOpenResolveDialog = item => {
        setPmtId(item.id);
        setOpenResolveDialog(true);
    };

    const handleClickOpenResolveReversalDialog = item => {
        setPmtId(item.dcTransferId);
        setOpenResolveReversalDialog(true);
    };

    const handleSetTransferResolved = () => {
        dispatch(FinanceActions.markPaymentResolved(pmtId)).then(() => {
            dispatch(FinanceActions.fetchPaymentsOverview());
            dispatch(FinanceActions.fetchTransfersNew());
            dispatch(FinanceActions.fetchReversals({ status: null }));
            setOpenResolveDialog(false);
        });
    };

    const handleClickOpenCancelDialog = item => {
        setPmt({
            transactionId: parseInt(item.dcTransferId),
            customerNumber: item.dcCustomerNumber,
            reason: "Test transfer cancellation",
        });
        setTransferData({ ...item });

        setOpenConfirmCancelDialog(true);
    };

    const handleCancelTransfer = (reason, amount) => {
        const payload = {
            ...pmt,
            reason,
            amount,
            transactionId: Number(pmt.transactionId),
        };
        dispatch(FinanceActions.setIsCancelTransaction(true))
        dispatch(FinanceActions.cancelPaymentTransaction(payload)).then(() => {
            dispatch(FinanceActions.fetchPaymentsOverview());
            dispatch(FinanceActions.fetchReversals({ status: null }));
            setOpenConfirmCancelDialog(false);
            dispatch(FinanceActions.setIsCancelTransaction(false))
        });
        setOpenConfirmCancelDialog(false);
    };

    const RenderReversalsCard = ({ data }) => {
        return data && data.reversals && data.reversals.length ? (
            <Grid item xs={12} md={6} className={``}>
                <Grid item xs={12} className={`${classes.tableContainer} ${classes.rightContainer}`}>
                    <Grid container item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                        <Typography
                            variant="h6"
                            style={{ width: "100%" }}>{`Transfer Reversal Requests`}</Typography>
                        <Typography
                            variant="caption"
                            style={{ width: "100%" }}>{`Provider: DC Bank`}</Typography>
                    </Grid>
                    <Table size="small">
                        <TableHead>
                            <CustomTableRow>
                                <CustomTableCell>Transfer Id</CustomTableCell>
                                <CustomTableCell>Sent</CustomTableCell>
                                <CustomTableCell>Reason</CustomTableCell>
                                <CustomTableCell style={{ textAlign: "left" }}>Amount</CustomTableCell>
                                <CustomTableCell style={{ textAlign: "center" }}>Status</CustomTableCell>
                                <CustomTableCell style={{ textAlign: "right" }} />
                            </CustomTableRow>
                        </TableHead>
                        <TableBody>
                            {[...data.reversals].reverse().map((record, i) => {
                                const code = record ? statuses.find(c => c.id === record.paymentStatusId) : null;
                                return (
                                    code && (
                                        <CustomTableRow key={i}>
                                            <CustomTableCell>
                                                {record.dcTransferId || record.paymentProviderReferenceCode}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {moment(record.sentAt).format("MMM Do, YYYY h:mm a")}
                                            </CustomTableCell>
                                            <CustomTableCell>{"Reversal"}</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "left" }}>
                                                {formatMoney(record.amount)}
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "center" }}>
                                                <DcTransferStatusBadge code={code.dcCode} iconOnly />
                                            </CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                <ReversalsTabTableActions
                                                    reversal={data}
                                                    //   toggleReviewingPayroll={toggleReviewingPayroll}
                                                    //   setIsReviewingPayroll={setIsReviewingPayroll}
                                                    toggleOpenDcReversal={toggleOpenDcReversal}
                                                    showPayrollSummary={true}
                                                    handleClickOpenCancelDialog={() => handleClickOpenCancelDialog(data)}
                                                    handleClickOpenResendDialog={() => handleClickOpenResendDialog(data)}
                                                    handleClickOpenResendReversalDialog={handleClickOpenResendReversalDialog}
                                                    handleClickOpenResolveReversalDialog={() =>
                                                        handleClickOpenResolveReversalDialog(record)
                                                    }
                                                    handleViewProfile={handleViewProfile}
                                                    payment={data}
                                                    position={data}
                                                    record={{ ...record, reversedDcTransfer: data }}
                                                    transactionId={record.id}
                                                />
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    )
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        ) : null;
    };

    const RenderTimeBasePremiumRows = ({ data, earningType }) => {
        if (data?.position?.premiumPayEarnings && data?.position?.premiumPayEarnings?.length) {
            return data.position.premiumPayEarnings.map((premium, i) => (
                <CustomTableRow key={premium.id}>
                    <CustomTableCell>
                        {(earningType && (earningType.name + " " + (data.position.premiumPayEarnings.length > 1 ? i + 1 : ""))) || "-"}
                    </CustomTableCell>
                    <CustomTableCell>{premium.unit || "-"}</CustomTableCell>
                    <CustomTableCell>
                        {premium?.rate ? formatMoney(premium.rate) : "-"}
                    </CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                        {formatMoney(premium.amount) || "-"}
                    </CustomTableCell>
                </CustomTableRow>
            ));
        };

        return '';
    }

    const RenderPaymentDetailsCard = ({ data }) => {
        const { payroll } = data;
        let contributionsTotal = 0;
        let earningsTotal = 0;
        const deductionsTotal = payroll.payrollDeductions.reduce((a, b) => {
            const deductionType = deductionTypes.find(c => c.id === b.payrollDeductionsTypeId);
            if (deductionType && deductionType.code === "EE") {
                return a + b.amount;
            }
            return a;
        }, 0);

        payroll?.payrollEarnings?.sort((a, b) => a?.payrollEarningsTypeId - b?.payrollEarningsTypeId);

        const canMergeOTAndWOT = Object.keys(OverTimeIds).reduce((p, c) => {
            const earning = payroll?.payrollEarnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds[c]);
            return p && Boolean(earning);
        }, true);
        let canShowOTRow = canMergeOTAndWOT;

        const ind = payroll?.payrollEarnings?.findIndex(a => a?.payrollEarningsTypeId === VacationPayId);
        (payroll?.payrollEarnings && ind >= 0) && Object.assign(payroll?.payrollEarnings, { [ind]: payroll?.payrollEarnings[payroll?.payrollEarnings?.length - 1], [payroll.payrollEarnings.length - 1]: payroll?.payrollEarnings[ind] })

        return (
            <Grid item md={12} style={{ padding: 8, height: "100%" }}>
                <Grid container item xs={12} className={classes.tableContainer}>
                    <Grid container item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                        <Typography variant="h6" style={{ width: "100%" }}>
                            {`Payroll Details`}
                            <IconButton
                                style={{ marginRight: 8, float: "right" }}
                                onClick={() => toggleResendStubDialog(payroll)}
                                edge="end"
                                size="small"
                                aria-label="add to shopping cart">
                                <MoreVert />
                            </IconButton>
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{ width: "100%" }}>{`Information about the shift`}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                        <Table size="small" style={{ marginTop: 8 }}>
                            <TableHead>
                                <CustomTableRow>
                                    <CustomTableCell>Earnings</CustomTableCell>
                                    <CustomTableCell>Units</CustomTableCell>
                                    <CustomTableCell>Rate</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                </CustomTableRow>
                            </TableHead>
                            <TableBody>
                                {payroll &&
                                    payroll.payrollEarnings.map((earning, i) => {
                                        const earningType = earningTypes.find(
                                            c => c.id === earning?.payrollEarningsTypeId
                                        );
                                        earningsTotal = earningsTotal + earning.amount;

                                        if (earning?.payrollEarningsTypeId === VacationPayId) {
                                            if (earning.amount <= 0) {
                                                return;
                                            }

                                            return (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {(earningType && earningType.name) || "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{earning.units || "-"}</CustomTableCell>
                                                    <CustomTableCell>
                                                        {earning?.rate ? earning.rate : "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(earning.amount) || "-"}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            );
                                        };

                                        if (canMergeOTAndWOT && Object.values(OverTimeIds).includes(earning?.payrollEarningsTypeId)) {
                                            const DOT = payroll?.payrollEarnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds.DOT);
                                            const WOT = payroll?.payrollEarnings?.find((earning) => earning?.payrollEarningsTypeId === OverTimeIds.WOT);
                                            const row = (canShowOTRow && (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {"Overtime (1.5x)"}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{(DOT.units || WOT.units) ? (DOT.units || 0) + (WOT.units || 0) : "-"}</CustomTableCell>
                                                    <CustomTableCell>
                                                        {DOT?.rate ? formatMoney(DOT.rate) : "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(DOT.amount + WOT.amount) || "-"}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            ));
                                            canShowOTRow = false;
                                            return row;
                                        };

                                        if (earning?.payrollEarningsTypeId === TimeBasePremiumId) {
                                            return <RenderTimeBasePremiumRows key={earning?.payrollEarningsTypeId} data={data} earningType={earningType} />
                                        };

                                        return (
                                            <CustomTableRow key={i}>
                                                <CustomTableCell>
                                                    {(earningType && earningType.name) || "-"}
                                                </CustomTableCell>
                                                <CustomTableCell>{earning.units || "-"}</CustomTableCell>
                                                <CustomTableCell>
                                                    {earning?.rate ? formatMoney(earning.rate).replace(earningType?.id === 5 ? "$" : "", "") : "-"}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ textAlign: "right" }}>
                                                    {formatMoney(earning.amount) || "-"}
                                                </CustomTableCell>
                                            </CustomTableRow>
                                        );
                                    })}
                                <CustomTableRow>
                                    <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {formatMoney(earningsTotal)}
                                    </CustomTableCell>
                                </CustomTableRow>
                                <CustomTableRow>
                                    <CustomTableCell colSpan={3}>Net Pay</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {formatMoney(earningsTotal - deductionsTotal)}
                                    </CustomTableCell>
                                </CustomTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                        <Table size="small" style={{ marginTop: 8 }}>
                            <TableHead>
                                <CustomTableRow>
                                    <CustomTableCell>Deductions</CustomTableCell>
                                    <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                        Amount
                                    </CustomTableCell>
                                </CustomTableRow>
                            </TableHead>
                            <TableBody>
                                {payroll &&
                                    payroll.payrollDeductions.map((deduction, i) => {
                                        const deductionType = getDeductionType(deduction.payrollDeductionsTypeId);

                                        if (deduction.payrollDeductionsTypeId >= 9 && deduction.payrollDeductionsTypeId <= 12 && deduction.amount === 0) return "";


                                        return (
                                            deductionType &&
                                            deductionType.code === "EE" && (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {(deductionType && deductionType.longName) || "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell colSpan={3} style={{ textAlign: "right" }}>
                                                        {formatMoney(deduction.amount)}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            )
                                        );
                                    })}
                                <CustomTableRow>
                                    <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {formatMoney(deductionsTotal)}
                                    </CustomTableCell>
                                </CustomTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid container item xs={12} md={4} style={{ paddingBottom: 4 }}>
                        <Table size="small" style={{ marginTop: 8 }}>
                            <TableHead>
                                <CustomTableRow>
                                    <CustomTableCell colSpan={3}>Employer Contributions</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>Amount</CustomTableCell>
                                </CustomTableRow>
                            </TableHead>
                            <TableBody>
                                {payroll &&
                                    payroll.payrollDeductions.map((deduction, i) => {
                                        const deductionType = deductionTypes.find(
                                            c => c.id === deduction.payrollDeductionsTypeId
                                        );

                                        if (deductionType && deductionType.code === "ER")
                                            contributionsTotal = contributionsTotal + deduction.amount;

                                        if (deduction.payrollDeductionsTypeId >= 9 && deduction.payrollDeductionsTypeId <= 12 && deduction.amount === 0) return "";


                                        return (
                                            deductionType &&
                                            deductionType.code === "ER" && (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell colSpan={3}>
                                                        {(deductionType && deductionType.longName) || "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "right" }}>
                                                        {formatMoney(deduction.amount)}
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            )
                                        );
                                    })}
                                <CustomTableRow>
                                    <CustomTableCell colSpan={3}>Total</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "right" }}>
                                        {formatMoney(contributionsTotal)}
                                    </CustomTableCell>
                                </CustomTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const RenderWorkerDetailsCard = ({ data }) => {
        const { job, payrolls, position } = data;
        return (
            <Grid item md={12} style={{ padding: 8, paddingLeft: 4, paddingRight: 4, height: "100%" }}>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Grid item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                        <Typography variant="h6" style={{ width: "100%" }}>{`Worker Details`}</Typography>
                        <Typography
                            variant="caption"
                            style={{ width: "100%" }}>{`Information about the worker`}</Typography>
                    </Grid>

                    {position && (
                        <Grid item xs={12} md={12} style={{ paddingBottom: 4, paddingLeft: 4 }}>
                            <Table size="small">
                                <TableBody>
                                    <CustomTableRow>
                                        <CustomTableCell>Job Id</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {position.jobId || "-"}
                                        </CustomTableCell>
                                    </CustomTableRow>

                                    <CustomTableRow>
                                        <CustomTableCell>Job Date</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {convertUTCToTimezoneWithOffset(job.startUtc, job.timezone, "MMM Do, YYYY") ||
                                                "-"}
                                        </CustomTableCell>
                                    </CustomTableRow>

                                    <CustomTableRow>
                                        <CustomTableCell>Scheduled Shift</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {convertUTCToTimezone(job.startUtc || job.start, job.timezone, "h:mm a")}-
                                            {convertUTCToTimezoneWithOffset(
                                                job.endUtc || job.end,
                                                job.timezone,
                                                "h:mm a"
                                            )}
                                        </CustomTableCell>
                                    </CustomTableRow>

                                    {/* <CustomTableRow>
                    <CustomTableCell>Employer</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {job.employerId}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                    <CustomTableRow>
                                        <CustomTableCell>Location</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {job.street}, {job.city}, {job.region}
                                        </CustomTableCell>
                                    </CustomTableRow>

                                    {payrolls && payrolls.length && (
                                        <CustomTableRow>
                                            <CustomTableCell>Worked</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "right" }}>
                                                {convertUTCToTimezone(payrolls[0].checkIn, job.timezone, "h:mm a")} -
                                                {convertUTCToTimezoneWithOffset(
                                                    payrolls[0].checkOut,
                                                    job.timezone,
                                                    "h:mm a"
                                                )}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    )}
                                    {/* <CustomTableRow>
                    <CustomTableCell>checked Out</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {moment(position.payrolls[0].checkOut).format("h:mm a") || "-"}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                    {/* <CustomTableRow>
                    <CustomTableCell>Hourly Rate</CustomTableCell>
                    <CustomTableCell style={{ textAlign: "right" }}>
                      {formatMoney(position.payrolls[0].rate)}
                    </CustomTableCell>
                  </CustomTableRow> */}
                                    <CustomTableRow>
                                        <CustomTableCell>Break</CustomTableCell>
                                        <CustomTableCell style={{ textAlign: "right" }}>
                                            {payrolls && payrolls.length && payrolls[0].breakDeductionMins ? formatNumber(payrolls[0].breakDeductionMins) + ' mins' : 'No Break'}
                                        </CustomTableCell>
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    };

    const RenderPaymentHistoryCard = ({ data }) => {
        const dcPayrollByStatusReversals = [...data.reversals]
            //   .map(payment => payment.reversals)
            //   .flat()
            .reverse();

        return (
            <Grid container item md={12} direction="row" style={{ padding: 4 }}>
                <Grid item xs={12} md={dcPayrollByStatusReversals ? 6 : 12} className={``}>
                    <Grid item xs={12} className={`${classes.tableContainer} ${classes.leftContainer}`}>
                        <Grid container item xs={12} style={{ paddingLeft: 8, paddingBottom: 4, height: 50 }}>
                            <Typography variant="h6" style={{ width: "100%" }}>{`Transfer History`}</Typography>
                            <Typography
                                variant="caption"
                                style={{ width: "100%" }}>{`Provider: DC Bank`}</Typography>
                        </Grid>
                        <Table size="small">
                            <TableHead>
                                <CustomTableRow>
                                    <CustomTableCell>Transfer Id</CustomTableCell>
                                    <CustomTableCell>Sent</CustomTableCell>
                                    <CustomTableCell>Reason</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "left" }}>Amount</CustomTableCell>
                                    <CustomTableCell style={{ textAlign: "center" }}>Status</CustomTableCell>
                                    {/*<CustomTableCell style={{ textAlign: "right" }} />*/}
                                </CustomTableRow>
                            </TableHead>
                            <TableBody>
                                {data.positionPayments &&
                                    [...data.positionPayments].reverse().map((record, i) => {
                                        const code = record.paymentStatusId
                                            ? statuses.find(c => c.id === record.paymentStatusId)
                                            : null;
                                        return (
                                            code && (
                                                <CustomTableRow key={i}>
                                                    <CustomTableCell>
                                                        {record.dcTransferId || record.paymentProviderReferenceCode}
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        {convertUTCToLocal(record.sentAt, "MMM Do, YYYY h:mm a")}
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        {record.interacStatus && record.dcStatus ? "Adjustment" : "Payment"}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "left" }}>
                                                        {formatMoney(record.amount)}
                                                    </CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "center" }}>
                                                        <DcTransferStatusBadge code={code.dcCode} iconOnly />
                                                    </CustomTableCell>
                                                    {/*<CustomTableCell style={{ textAlign: "right" }}>
                            <SubTransfersActions
                              handleClickOpenCancelDialog={() => handleClickOpenCancelDialog(data)}
                              handleClickOpenResendDialog={() => handleClickOpenResendDialog(data)}
                              handleClickOpenResolveDialog={() =>
                                handleClickOpenResolveDialog(record.id)
                              }
                              handleViewProfile={handleViewProfile}
                              payment={data}
                              transactionId={record.id}
                              toggleOpenDcReversal={toggleOpenDcReversal}
                            />
                          </CustomTableCell>*/}
                                                </CustomTableRow>
                                            )
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <RenderReversalsCard data={data} />
            </Grid>
        );
    };

    const RenderSubComponent = ({ data }) => {
        return (
            <Grid container item xs={12}>
                <RenderPaymentHistoryCard
                    data={data}
                    handleClickOpenCancelDialog={() => handleClickOpenCancelDialog(data)}
                    handleClickOpenResendDialog={() => handleClickOpenResendDialog(data)}
                    handleClickOpenResolveDialog={() => handleClickOpenResolveDialog(data)}
                />
                <Grid item xs={12} md={3}>
                    <RenderWorkerDetailsCard data={data} />
                </Grid>
                <Grid item md={9}>
                    <RenderPaymentDetailsCard data={data} />
                </Grid>
            </Grid>
        );
    };

    const handleResendReversal = () => {
        dispatch(FinanceActions.retryReversal(pmtId));
        setOpenConfirmResendDialog(false);
    };

    const handleSetReversalResolved = () => {
        dispatch(FinanceActions.markReversalResolved(pmtId));
        setOpenResolveReversalDialog(false);
    };

    const toggleIsViewingPdf = (positionId, callback) => {
        dispatch(FinanceActions.downloadPaystubSummaryZip(positionId)).then(link => {
            if (link) {
                downloadFileFromLink(link);
            }
            setTimeout(() => {
                callback(false);
            }, 1000);
        }).catch(() => {
            callback(false);
        });
    };

    const columns = [
        {
            Header: "",
            accessor: ({ worker }) => worker.signedProfileImageUrl,
            id: "profileImage",
            Cell: ({ row: { original } }) => {
                return (
                    <RenderTransferAvatar
                        code={statuses.find(c => c.id === original.status.id).dcCode}
                        data={original}
                    />
                );
            },
            disableSortBy: true,
            disableFilters: true,
            width: 100,
        },
        {
            Header: "Job Id",
            disableSortBy: true,
            id: "id",
            width: 100,
            style: { cursor: "pointer" },
            accessor: ({ job }) => job.id,
            Cell: ({ row: { original: { job } } }) => job.id,
        },
        {
            Header: "First Name",
            disableSortBy: true,
            id: "firstName",
            width: 100,
            accessor: ({ worker }) => worker.firstName,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { worker } } }) => worker.firstName,
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            id: "lastName",
            width: 100,
            accessor: ({ worker }) => worker.lastName,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { worker } } }) => worker.lastName,
        },

        // {
        //     Header: "Customer Id",
        //     show: false,
        //     accessor: ({ dcCustomerNumber }) => dcCustomerNumber,
        //     disableSortBy: true,
        //     id: "CustomerNumber",
        //     width: 100,
        //     style: { cursor: "pointer" },
        //     Cell: ({ row: { original: { dcCustomerNumber } } }) => dcCustomerNumber,
        // },
        {
            Header: "Transfer Id",
            disableSortBy: true,
            id: "dcTransferId",
            width: 100,
            style: { cursor: "pointer" },
            accessor: ({ dcTransferId }) => dcTransferId,
            Cell: ({ row: { original: { dcTransferId } } }) => dcTransferId,
        },
        {
            Header: "Email",
            disableSortBy: true,
            id: "email",
            width: 100,
            accessor: ({ worker }) => worker.email,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { worker } } }) => worker.email,
        },
        {
            Header: "Status ",
            disableSortBy: true,
            accessor: ({ status }) => status,
            id: "paymentStatusId",
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { status } } }) => status.description,
            width: 120,
            Filter: ({ filter, setFilter, column, ...rest }) => {
                return (
                    <div>
                        <Select
                            disableUnderline={false}
                            variant="standard"
                            onChange={event => setFilter(column.id, event.target.value.trim())}
                            value={column.filterValue !== " " || column.filterValue !== undefined ? column.filterValue || " " : " "}
                            style={{ fontSize: 12 }}
                            fullWidth
                        >
                            <MenuItem value={" "}>All</MenuItem>
                            {transferStatuses.map(n => (
                                <MenuItem value={n.id.toString()} key={n.id}>{n.label}</MenuItem>
                            ))}
                        </Select>
                    </div>
                )
            },
        },
        // {
        //     Header: "Interac Status ",
        //     disableSortBy: true,
        //     show: false,
        //     accessor: ({ transfer }) => transfer[0],
        //     id: "interacStatus",
        //     width: 100,
        //     style: { cursor: "pointer" },
        //     Cell: ({ row: { original: { transfer } } }) =>
        //         (transfer && transfer.length > 0 && transfer[0].InteracStatusDescription) || "-",
        // },
        {
            Header: "Date Of Funds",
            disableSortBy: true,
            id: "sentAt",
            accessor: ({ sentAt }) => sentAt,
            width: 100,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { sentAt } } }) =>
                sentAt ? convertUTCToLocal(sentAt, "MMM Do, YYYY") : "-",
            Filter: ({ column, setFilter }) => {
                return (
                    <Datetime
                        timeFormat={false}
                        id="date"
                        input
                        dateFormat="MMM Do, YYYY"
                        onChange={(e) => {
                            const date = moment(e)
                            setFilter(column.id, date.isValid() ? date.format('YYYY-MM-DD') : '')
                        }}
                        renderInput={(rest) => <TextField  {...rest} variant="filled" className={classNames("filter-textfield", classes.dateInput)} />}
                        // className={classes.dateFilter}
                        value={column.filterValue ? moment(column.filterValue) : ""}
                    // isValidDate={isValidStartDate}
                    />
                );
            },
        },
        {
            Header: "Job Date",
            disableSortBy: true,
            id: "start",
            accessor: ({ job }) => job.start,
            width: 100,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { job } } }) =>
                job.start ? convertUTCToLocal(job.start, "MMM Do, YYYY") : "-",
            Filter: ({ column, setFilter }) => {
                return (
                    <Datetime
                        timeFormat={false}
                        id="date"
                        input
                        dateFormat="MMM Do, YYYY"
                        onChange={(e) => {
                            const date = moment(e)
                            setFilter(column.id, date.isValid() ? date.format('YYYY-MM-DD') : '')
                        }}
                        renderInput={(rest) => <TextField  {...rest} variant="filled" className={classNames("filter-textfield", classes.dateInput)} />}
                        // className={classes.dateFilter}
                        value={column.filterValue ? moment(column.filterValue) : ""}
                    // isValidDate={isValidStartDate}
                    />
                );
            },
        },
        {
            Header: "Expires",
            disableSortBy: true,
            disableFilters: true,
            id: "expires",
            minWidth: 120,
            accessor: ({ transfer }) => transfer,
            // style: { cursor: "pointer" },
            Cell: ({ row: { original: { transfer, status } } }) => {
                if (transfer && transfer.length > 0) {
                    const expiresAt = moment(transfer[0].MoneyReqExpDate);

                    if (status?.id === 5) return "-";

                    return (
                        <Tooltip
                            title={`This eTransfer expires on  ${convertUTCToLocal(
                                expiresAt,
                                "MMM Do, YYYY h:mm a"
                            )}`}>
                            <Typography
                                variant="caption"
                                // onClick={() => setShowAgo(v => !v)}
                                color={moment(expiresAt).isSameOrBefore() ? "error" : "inherit"}>
                                {showAgo ? <Moment fromNow>{moment(expiresAt).format()}</Moment> : expiresAt}
                            </Typography>
                        </Tooltip>
                    );
                }
                return "-";
            },
        },
        {
            Header: "Amount",
            disableFilters: true,
            disableSortBy: true,
            accessor: ({ amount }) => amount,
            id: "amount",
            width: 100,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { transfer, amount } } }) =>
                `${formatMoney((transfer && transfer.length > 0 && transfer[0].Amount) || amount)}`,
        },
        {
            Header: "Attempts",
            disableFilters: true,
            disableSortBy: true,
            accessor: ({ positionPayments }) => positionPayments,
            id: "Attempts",
            headerStyle: { textAlign: "center" },
            width: 100,
            style: { cursor: "pointer", textAlign: "center" },
            Cell: ({ row: { original: { positionPayments } } }) => positionPayments.length,
        },
        {
            Header: "Reversals",
            disableSortBy: true,
            disableFilters: true,
            accessor: ({ reversals }) => reversals.length,
            id: "reversals",
            headerStyle: { textAlign: "center" },
            width: 100,
            style: { cursor: "pointer", textAlign: "center" },
            Cell: ({ row: { original: { reversals } } }) => (reversals ? reversals.length : 0),
        },
        {
            Header: "Status",
            disableFilters: true,
            disableSortBy: true,
            id: "Status",
            width: 60,
            accessor: ({ status }) => status.id,
            style: { cursor: "pointer" },
            Cell: ({ row: { original: { status } } }) => (
                <DcTransferStatusBadge code={statuses.find(c => c.id === status.id).dcCode} iconOnly />
            ),
        },
        {
            Header: "",
            expander: true,
            width: 60,
            isNotClickable: true,
            disableFilters: true,
            id: "payment",
            Expander: ({ isExpanded, original }) => {
                const { transfer } = original;

                if (transfer) {
                    return isExpanded ? <ArrowDropDown /> : <ArrowRight />;
                }
                return null;
            },
            Cell: ({ row: { original, isExpanded, ...row }, column }) => {
                const code = statuses.find(c => c.id === original.status.id).dcCode;
                const { transfer } = original;

                return <>
                    {transfer && <IconButton {...row.getToggleRowExpandedProps()}>
                        {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                    </IconButton>}
                    {/* <IndividualTransfersActions
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        payment={original}
                        dcCode={code}
                    /> */}
                </>;
            },
        },
        {
            Header: "",
            id: "actions",
            accessor: "actions",
            disableSortBy: true,
            disableFilters: true,
            isNotClickable: true,
            collapse: true,
            style: { cursor: "pointer" },
            Cell: ({ row: { original } }) => {
                const code = statuses.find(c => c.id === original.status.id).dcCode;

                return (
                    <SubTransfersActions
                        handleClickOpenCancelDialog={() => handleClickOpenCancelDialog(original)}
                        handleClickOpenResendDialog={() => handleClickOpenResendDialog(original)}
                        handleClickOpenResolveDialog={() => handleClickOpenResolveDialog(original)}
                        toggleResendStubDialog={() => toggleResendStubDialog(original?.payroll)}
                        toggleIsViewingPdf={(callback) => toggleIsViewingPdf(original?.positionId, callback)}
                        handleViewProfile={handleViewProfile}
                        payment={original}
                        // jobId={original.position.jobId}
                        dcCode={code}
                        toggleOpenDcReversal={toggleOpenDcReversal}
                    />
                );
            },
            width: 50,
        },
    ];
    const RenderTable = () => {

        // return <ReactTable
        //     {...sharedProps}
        //     data={dcTransfers.data}
        //     loading={PaginationData.isLoading}
        //     // onPageSizeChange={onPageSizeChange}
        //     // onPageChange={onPageChange}
        //     // onFilteredChange={_debounce(onFilteredChange, 1200)}
        //     defaultFilter={Filters}
        //     pages={PaginationData.pageCount}
        //     page={PaginationData.currentPage}
        //     defaultPageSize={PaginationData.pageSize}
        //
        //     // onExpandedChange={onExpandedChange}
        //     // getTdProps={(state, row) => RenderTdProps(state, row)}
        //     // NoDataComponent={() => (
        //     //     <Typography
        //     //         variant="subtitle2"
        //     //         className="rt-noData"
        //     //         style={{
        //     //             margin: 30,
        //     //             width: "100%",
        //     //             textAlign: "center",
        //     //         }}>{`No Results Found`}</Typography>
        //     // )}
        //     // SubComponent={row => <RenderSubComponent data={row.original} />}
        //     columns={columns}
        // />
    };

    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <CancelTransferDialog
                    open={openConfirmCancelDialog}
                    title={`Cancel Pending Transfer?`}
                    description={`Are you sure you want to cancel this transfer?`}
                    confirmText="Cancel Transfer"
                    onClose={() => handleClose()}
                    onConfirm={handleCancelTransfer}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    alertType="save"
                    fullWidth={false}
                    pmt={pmt}
                    transferData={transferData}
                    iconFontSize={94}
                    isCancelLoading={isCancelLoading}
                    icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                    size="md"
                />

                <ResendDialog
                    open={openResolveReversalDialog}
                    title={`Resolve Payroll Reversal`}
                    description={`Resolve this reversal request?`}
                    confirmText="Resolve Reversal"
                    onClose={() => handleClose()}
                    onConfirm={handleSetReversalResolved}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                />
                <ResendDialog
                    open={openResolveDialog}
                    title={`Resolve Payroll`}
                    description={`Resolve this cancelled transfer to the worker?`}
                    confirmText="Resolve Transfer"
                    onClose={() => handleClose()}
                    onConfirm={handleSetTransferResolved}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                    disabled={isMarkingPaymentResolved}
                    isLoading={isMarkingPaymentResolved}
                // table={<Grid container item xs={12} style={{ width: 300, display: "block" }}></Grid>}
                />
                <ResendDialog
                    open={openConfirmResendDialog}
                    title={`Resend Payroll`}
                    description={`Resend this cancelled transfer to the worker?`}
                    confirmText="Resend Transfer"
                    onClose={() => handleClose()}
                    onConfirm={handleResendTransfer}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                    disabled={isResendPayment}
                    isLoading={isResendPayment}
                // table={<Grid container item xs={12} style={{ width: 300, display: "block" }}></Grid>}
                />
                <CancelTransferDialog
                    open={openConfirmCancelDialog}
                    title={`Cancel Transfer Request?`}
                    description={`Are you sure you want to cancel this request?`}
                    confirmText="Cancel Request"
                    onClose={() => handleClose()}
                    onConfirm={handleCancelTransfer}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    alertType="save"
                    fullWidth={false}
                    pmt={pmt}
                    transferData={transferData}
                    iconFontSize={94}
                    isCancelLoading={isCancelLoading}
                    icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                    size="md"
                />
                <ResendDialog
                    open={openResolveReversalDialog}
                    title={`Resend Reversal Request`}
                    description={`Resend this cancelled transfer reversal request?`}
                    confirmText="Resend Request"
                    onClose={() => handleClose()}
                    onConfirm={() => handleResendReversal()}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                />
                {/* <RenderHeader /> */}
                <DcHeader />
                <ReactTable
                    onRowClick={(row) => {
                        row.toggleRowExpanded();
                    }}
                    classes={classes.table}
                    columns={columns}
                    data={dcTransfers.data}
                    fetchData={fetchData}
                    defaultFilter={Filters}
                    currentPage={PaginationData.currentPage}
                    defaultPageSize={PaginationData.pageSize}
                    loading={PaginationData.isLoading}
                    pageCount={PaginationData.totalCount}
                    expandView={row => <RenderSubComponent data={row.original} />}
                />
            </Grid>
        </React.Fragment>
    );
}

export default TransfersTab;
