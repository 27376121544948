import moment from "moment";
import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: false,
    formDialogLoading: false,
    deleteLoading: false
};

const initialDialogState = {
    addProvinceWisePayDialog: false,
    deleteProvinceWisePayDialog: false,
    editProvinceWisePayDialog: false
};

const initialFormState = {
    isActive: true,
    province: null,
    minShiftLengthHours: 4,
    minShiftLengthMinutes: 0,
    maxShiftLengthHours: 8,
    maxShiftLengthMinutes: 0,
    minShiftHours: 0,
    minimumPayHours: 0,
    minimumPayMinutes: 30,
    effectiveDateFrom: moment().startOf('year'),
    effectiveDateTo: moment().endOf('year')
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    data: [],
    form: { ...initialFormState }
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.UPDATE_PROVINCE_WISE_PAY_FORM]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            form: {
                ...state.form,
                [key]: value,
            },
        };
    },
    [types.RESET_FORM]: (state) => {
        return {
            ...state,
            form: {
                ...initialFormState
            }
        };
    },
    [types.SET_PROVINCE_WISE_PAY_DATA]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            data,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    }
}

export default createReducer(initialState, actionsMap);
