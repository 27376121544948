import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import moment from "moment";
import types from "./types";

const initialListState = {
    sort: [],
    filter: [],
    data: [],
};

const initialLoadingState = {
    page: true,
    actions: false,
    employerNotes: false,
    timeBasePremiumList: false,
    addTimeBasePremium: false,
    removeTimeBasePremium: false,
    dynamicWorkTypeRateList: false,
    addDynamicWorkTypeRate: false,
    removeDynamicWorkTypeRate: false
};
const initialDialogState = {
    newTimeBasePremium: false,
    removeTimeBasePremium: false,
    newDynamicWorkTypeRate: false,
    removeDynamicWorkTypeRate: false
};
const initialTimeBasePremiumForm = {
    isForFullShift: false,
    startTime: moment(),
    endTime: moment().add(4, "hours"),
    type: "PERCENTAGE",
    value: 0,
    days: []
};

const initialDynamicWorkTypeRateForm = {
    workType: null,
    payRate: 0,
    billingRate: 0,
};

const initialState = {
    //   editProfile: { ...initialEditProfileState },
    loading: { ...initialLoadingState },
    employer: {},
    dialog: { ...initialDialogState },
    timeBasePremiumList: [],
    selectedTimeBasePremium: null,
    timeBasePremiumForm: { ...initialTimeBasePremiumForm },
    managers: [],
    updateEmployerData: {},
    updateEmployerCustomRateData: {},
    editEmployerCustomRateData: {},
    newEmployerCustomRate: {},
    canUpdateEmployer: false,
    hoursTrendData: [],
    employerCustomRates: [],
    jobs: { ...initialListState, sort: [{ id: "start", desc: true }] },
    workerExceptions: {
        banned: { ...initialListState },
        preferred: { ...initialListState },
    },
    employerNotes: "",
    workerPicker: { ...initialListState },
    dynamicWorkTypeRateList: [],
    selectedDynamicWorkTypeRate: null,
    dynamicWorkTypeRateForm: { ...initialDynamicWorkTypeRateForm },
    employerBasicInfo: null,
    selectedGroupOrRequirement: null,
    assignedGroupOrRequirement: [],
    requirementList: {
        ...initialListState, sort: [{ id: "name", desc: true }]
    },
    highlightedRecord: null,
    existingRecord: [],
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_SELECTED_TIMEBASE_PREMIUM]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            selectedTimeBasePremium: data,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_TIME_BASE_PREMIUM_LIST]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            timeBasePremiumList: data
        };
    },
    [types.SET_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
        const { key, value } = payload;
        if (key === "startTime" || key === "endTime") {
            return {
                ...state,
                timeBasePremiumForm: {
                    ...state.timeBasePremiumForm,
                    [key]: moment({
                        year: moment().year(),
                        month: moment().month(),
                        date: moment().date(),
                        hour: value.hour(),
                        minute: value.minute(),
                        second: value.second(),
                    })
                }
            }
        }
        return {
            ...state,
            timeBasePremiumForm: {
                ...state.timeBasePremiumForm,
                [key]: value
            }
        };
    },
    [types.CLEAR_TIME_BASE_PREMIUM_FORM]: (state) => {
        return {
            ...state,
            timeBasePremiumForm: { ...initialTimeBasePremiumForm },
            selectedTimeBasePremium: null,
        };
    },
    [types.SET_EDIT_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            timeBasePremiumForm: data ? {
                isForFullShift: data.isForFullShift,
                startTime: moment(data.startTime, "hh:mm:ss"),
                endTime: moment(data.endTime, "hh:mm:ss"),
                type: data.type,
                value: data.value,
                days: data.days
            } : { ...initialTimeBasePremiumForm }
        };
    },
    [types.RESET]: () => {
        return { ...initialState };
    },
    [types.SET_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return { ...state, updateEmployerData: { ...state.updateEmployerData, [field]: value } };
    },

    [types.SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return {
            ...state,
            editEmployerCustomRateData: { ...state.editEmployerCustomRateData, [field]: value },
        };
    },

    [types.SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return {
            ...state,
            updateEmployerCustomRateData: { ...state.updateEmployerCustomRateData, [field]: value },
        };
    },

    [types.SET_NEW_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return { ...state, newEmployerCustomRate: { ...state.newEmployerCustomRate, [field]: value } };
    },

    [types.CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
        return {
            ...state,

            newEmployerCustomRate: {},
        };
    },
    [types.CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
        return {
            ...state,
            updateEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
            editEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
        };
    },
    [types.CLEAR_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
        return {
            ...state,
            canUpdateEmployer: false,
            updateEmployerData: { ...payload },
        };
    },

    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },

    [types.ADD_CHANGE]: (state, { payload }) => {
        const newForm = { ...state.data };
        newForm[payload.fieldName] = payload.fieldValue;
        return {
            ...state,
            editProfile: { ...state.editProfile, changed: true, data: payload },
        };
    },

    [types.SET_UP_EDIT_FORM]: (state, { payload }) => {
        return {
            ...state,
            editProfile: { ...state.editProfile, changed: true, data: payload },
        };
    },

    [types.EDIT_FORM_PENDING]: state => {
        return {
            ...state,
            editProfile: { ...state.editProfile, status: types.EDIT_FORM_PENDING },
        };
    },

    [types.EDIT_FORM_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            editProfile: {
                ...state.editProfile,
                changed: false,
                data: payload.form,
                status: types.EDIT_FORM_SUCCESS,
            },
        };
    },

    [types.SET_EMPLOYER_CUSTOM_RATES]: (state, { payload }) => {
        return {
            ...state,
            employerCustomRates: [...payload],
        };
    },

    [types.SET_EMPLOYER]: (state, { payload }) => {
        const { employer } = payload;
        return {
            ...state,
            employer,
            //   updateEmployerData: { ...employer },
        };
    },
    [types.SET_MANAGER]: (state, { payload }) => {
        const { managers } = payload;
        return {
            ...state,
            managers,
        };
    },

    [types.SET_HOURS_TREND_DATA]: (state, { payload }) => {
        const { hoursTrendData } = payload;
        return {
            ...state,
            hoursTrendData,
        };
    },
    [types.SET_WORKER_EXCEPTIONS_LIST_DATA]: (state, { payload }) => {
        const { data, list, part } = payload;

        return {
            ...state,
            workerExceptions: {
                ...state.workerExceptions,
                [list]: {
                    ...state.workerExceptions[list],
                    [part]: data,
                },
            },
        };
    },
    [types.SET_JOBS_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;

        return {
            ...state,
            jobs: {
                ...state.jobs,
                [part]: data,
            },
        };
    },

    [types.SET_WORKER_PICKER_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;

        return {
            ...state,
            workerPicker: {
                ...state.workerPicker,
                [part]: data,
            },
        };
    },
    [types.SET_EMPLOYER_NOTE]: (state, { payload }) => {
        return {
            ...state,
            employerNotes: payload,
        };
    },
    [types.RESET_EMPLOYER_NOTE]: state => {
        return {
            ...state,
            employerNotes: "",
        };
    },
    [types.SET_SELECTED_DYNAMIC_WORK_TYPE_RATE]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            selectedDynamicWorkTypeRate: data,
        };
    },
    [types.SET_DYNAMIC_WORK_TYPE_RATE_LIST]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            dynamicWorkTypeRateList: data
        };
    },
    [types.SET_DYNAMIC_WORK_TYPE_RATE_FORM]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dynamicWorkTypeRateForm: {
                ...state.dynamicWorkTypeRateForm,
                [key]: value
            }
        };
    },
    [types.CLEAR_DYNAMIC_WORK_TYPE_RATE_FORM]: (state) => {
        return {
            ...state,
            dynamicWorkTypeRateForm: { ...initialDynamicWorkTypeRateForm },
            selectedDynamicWorkTypeRate: null,
        };
    },
    [types.SET_EDIT_DYNAMIC_WORK_TYPE_RATE_FORM]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            dynamicWorkTypeRateForm: data ? {
                id: data.id,
                workType: data.workType,
                payRate: data.payRate,
                billingRate: data.billingRate
            } : { ...initialDynamicWorkTypeRateForm }
        };
    },
    [types.SET_EMPLOYER_BASIC_INFO]: (state, { payload }) => {
        const { employer: employerBasicInfo } = payload;
        return {
            ...state,
            employerBasicInfo,
        };
    },
    [types.SET_SELECTED_GROUP_OR_REQUIREMENT]: (state, { payload }) => {
        const { selected } = payload;
        return {
            ...state,
            selectedGroupOrRequirement: selected,
        };
    },
    [types.SET_ASSIGNED_GROUP_OR_REQUIREMENT]: (state, { payload }) => {
        const { data: { requirements, selected } } = payload;
        const assignedGroupOrRequirement = JSON.parse(JSON.stringify(state.assignedGroupOrRequirement));
        const initialData = {
            workType: null,
            validity: {
                type: 'days',
                value: null
            },
            isMandatory: true
        }

        if (selected?.selectedType === 'group') {
            if (requirements.length) {
                const ind = state.assignedGroupOrRequirement.findIndex(a => a?.group?.id === selected?.data?.id);
                if (ind >= 0) {
                    assignedGroupOrRequirement[ind] = {
                        ...assignedGroupOrRequirement[ind],
                        requirements: [...assignedGroupOrRequirement[ind].requirements, ...requirements]
                    }
                } else {
                    assignedGroupOrRequirement.push({
                        group: {
                            ...selected.data
                        },
                        requirements
                    });
                };
                return {
                    ...state,
                    assignedGroupOrRequirement: assignedGroupOrRequirement.map(a => ({
                        ...a,
                        requirements: a.requirements.map(r => ({
                            ...initialData,
                            ...r
                        }))
                    }))
                }
            };
        };
        if (assignedGroupOrRequirement.flatMap(a => a.requirements).find(a => a.id === selected?.data?.id)) {
            return {
                ...state,
                highlightedRecord: selected.data,
                selectedGroupOrRequirement: null
            };
        };
        return {
            ...state,
            assignedGroupOrRequirement: [{ requirements: [{ ...initialData, ...selected.data }] }, ...assignedGroupOrRequirement],
            selectedGroupOrRequirement: null
        };
    },
    [types.SET_ADDITIONAL_REQUIREMENTS_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        return {
            ...state,
            requirementList: {
                ...state.requirementList,
                [part]: data
            },
        };
    },
    [types.SET_HIGHLIGHTED_RECORD_DATA]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            highlightedRecord: data,
        };
    },
    [types.SET_EXISTING_RECORD]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            existingRecord: data,
        };
    },
    [types.SET_ADD_REQUIREMENT]: (state, { payload }) => {
        const { rowIndex, requirementIndex } = payload;
        const assignedGroupOrRequirement = JSON.parse(JSON.stringify(state.assignedGroupOrRequirement));
        assignedGroupOrRequirement[rowIndex].requirements.push({
            ...assignedGroupOrRequirement[rowIndex].requirements[requirementIndex],
            workType: null,
            validity: {
                type: 'days',
                value: null
            },
            isMandatory: true
        });
        return {
            ...state,
            assignedGroupOrRequirement: assignedGroupOrRequirement?.filter(a => a?.requirements?.length)
        };
    },
    [types.SET_REMOVE_REQUIREMENT]: (state, { payload }) => {
        const { rowIndex, requirementIndex } = payload;
        const assignedGroupOrRequirement = JSON.parse(JSON.stringify(state.assignedGroupOrRequirement));
        assignedGroupOrRequirement[rowIndex].requirements.splice(requirementIndex, 1);
        return {
            ...state,
            assignedGroupOrRequirement: assignedGroupOrRequirement?.filter(a => a?.requirements?.length)
        };
    },
    [types.SET_CHANGE_REQUIREMENT]: (state, { payload }) => {
        const { record: { rowIndex, requirementIndex, data } } = payload;
        const assignedGroupOrRequirement = JSON.parse(JSON.stringify(state.assignedGroupOrRequirement));

        assignedGroupOrRequirement[rowIndex].requirements[requirementIndex] = {
            ...assignedGroupOrRequirement[rowIndex].requirements[requirementIndex],
            ...data
        };

        return {
            ...state,
            assignedGroupOrRequirement
        };
    },
    [types.CLEAR_REQUIREMENT_FORM]: (state) => {
        return {
            ...state,
            assignedGroupOrRequirement: []
        };
    },
    [types.SET_ALL_ASSSIGNED_REQUIREMENTS_BY_EMPLOYER_ID]: (state, { payload: { data } }) => {
        return {
            ...state,
            assignedGroupOrRequirement: data?.length ? data?.reduce((p, a) => {
                const dataObject = {
                    workType: {
                        value: a.workType?.id,
                        label: a.workType?.label
                    },
                    validity: {
                        type: a.validityType,
                        value: a.validityType === "date" ? moment(a.validityValue)?.isValid() ? moment(a.validityValue) : moment(a.validityValue, 'DD/MM/YYYY') : a.validityValue
                    },
                    isMandatory: a.isMandatory,
                    id: a?.requirement?.id,
                    name: a?.requirement?.name,
                    type: a?.requirement?.type,
                    groups: a?.requirement?.groups?.map?.(g => ({
                        id: g?.id,
                        name: g?.name,
                        slug: g?.slug
                    }))
                };
                const ind = p?.findIndex(f => f?.requirements?.find(r => r?.id === a?.requirement?.id))
                if (ind >= 0) {
                    p[ind]?.requirements?.push(dataObject);
                    return [...p];
                } else {
                    return [...p, {
                        requirements: [dataObject]
                    }]
                }
            }, []) : []
        };
    },
    ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
