import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import moment from "moment";

export function getFormView(state) {
    return state.form.view.data;
}

export function getFormEdit(state) {
    return state.form.edit.data;
}

export function getHasChanged(state) {
    return state.form.edit.changed;
}

export const getEmployerCustomRates = state => {
    return state.employerDetails.employerCustomRates || [];
};

export const getEmployerEditingCustomRates = state => {
    return { ...state.employerDetails.editEmployerCustomRateData } || {};
};

export const getEmployerDetails = state => {
    return state.employerDetails;
};
export const getEmployer = state => {
    return state.employerDetails.employer;
};

export const getPreferredListData = state => {
    return state.employerDetails.workerExceptions.preferred;
};

export const getBannedListData = state => {
    return state.employerDetails.workerExceptions.banned;
};

export const getJobsListData = state => {
    return state.employerDetails.jobs;
};
export const getManagersList = state => {
    return state.employerDetails.managers;
};

export const getWorkerPickerListData = state => {
    return state.employerDetails.workerPicker;
};

export const getWorkerExceptionsListData = (state, { list, part }) => {
    return state.employerDetails.workerExceptions[list][part];
};

export const getJobsPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.employerJobs)];
};

export const getPreferredWorkersPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.preferredWorkers)];
};

export const getBannedWorkersPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.bannedWorkers)];
};

export const getWorkerPickerPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.workerPicker)];
};


export const getTimeBasePremiumListData = (state) => {
    return state.employerDetails.timeBasePremiumList;
};

export const getSelectedTimeBasePremium = (state) => {
    return state.employerDetails.selectedTimeBasePremium;
};

export const getTimeBasePremiumForm = state => {
    return state.employerDetails.timeBasePremiumForm || {};
};

export const canAddUpdateTimeBasePremium = state => {
    const { startTime, endTime, type, value, days } = state.employerDetails.timeBasePremiumForm || {};
    return (
        startTime &&
        endTime &&
        moment(startTime).add(1, "hour").isSameOrBefore(moment(endTime)) &&
        ((type === "PERCENTAGE" && value > 0 && value <= 100) || (type === "VALUE" && value > 0)) &&
        days && days.length
    )
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.employerDetails.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsTimeBaseListLoading = getLoadingStateFactory("timeBasePremiumList");
export const getIsAddTimeBasePremiumListLoading = getLoadingStateFactory("addTimeBasePremium");
export const getIsRemoveTimeBasePremiumLoading = getLoadingStateFactory("removeTimeBasePremium");
export const getIsDynamicWorkTypeRateListLoading = getLoadingStateFactory("dynamicWorkTypeRateList");
export const getIsAddDynamicWorkTypeRateListLoading = getLoadingStateFactory("addDynamicWorkTypeRate");
export const getIsRemoveDynamicWorkTypeRateLoading = getLoadingStateFactory("removeDynamicWorkTypeRate");
export const getRequirementByGroup = getLoadingStateFactory("getRequirementByGroup");
export const getIsRequiremntUpdateLoading = getLoadingStateFactory("isRequiremntUpdateLoading");


// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.employerDetails.dialog[loader] || false;
};

export const GetNewTimeBasePremiumDialog = getDialogStateFactory("newTimeBasePremium");
export const GetRemoveTimeBasePremiumDialog = getDialogStateFactory("removeTimeBasePremium");

export const GetNewDynamicWorkTypeRateDialog = getDialogStateFactory("newDynamicWorkTypeRate");
export const GetRemoveDynamicWorkTypeRateDialog = getDialogStateFactory("removeDynamicWorkTypeRate");

export const getDynamicWorkTypeRateListData = (state) => {
    return state.employerDetails.dynamicWorkTypeRateList || [];
};

export const getSelectedDynamicWorkTypeRate = (state) => {
    return state.employerDetails.selectedDynamicWorkTypeRate;
};

export const getDynamicWorkTypeRateForm = state => {
    return state.employerDetails.dynamicWorkTypeRateForm || {};
};

export const canAddUpdateDynamicWorkTypeRate = state => {
    const { payRate, billingRate, workType } = state.employerDetails.dynamicWorkTypeRateForm || {};
    return (
        payRate &&
        billingRate &&
        workType
    )
};

// Assign Requirement
export const getIsRemoveAssignedEmployerLoading = getLoadingStateFactory("isRemoveAssignedEmployerLoading");
export const getAssignEmployerRequirementLoading = getLoadingStateFactory("getAssignEmployerRequirementLoading");

export const getIsSelectedRecordForRemove = getDialogStateFactory("selectedRecordForRemove");

export const getEmployerBasicData = state => {
    return state.employerDetails.employerBasicInfo;
};

export const getSelectedGroupOrRequirement = state => {
    return state.employerDetails.selectedGroupOrRequirement;
};

export const getAssignedGroupOrRequirement = state => {
    return state.employerDetails.assignedGroupOrRequirement;
};

export const getAdditionalRequirementsData = (state, { part }) => state?.employerDetails?.requirementList?.[part];

export const getAdditionalRequirementListPagingData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.requirementList)];
};

export const getHighlightedRecord = state => {
    return state.employerDetails.highlightedRecord;
};

export const getExistingRecord = state => {
    return state.employerDetails.existingRecord;
};

export const getCanAssign = state => {
    return state.employerDetails?.assignedGroupOrRequirement?.flatMap(a => a?.requirements)?.every(r => r.workType && r.validity && r.validity.type && (r.validity.type === 'days' ? +r.validity.value > 0 : r.validity.value));
};
