import PerfectScrollbar from 'perfect-scrollbar';
import React from 'react'
import cx from "classnames";
import withStyles from '@mui/styles/withStyles';
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

let ps;

class PerfectScrollWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    setSidebarWrapperRef = ref => (this.sidebarWrapper = ref);

    render() {
        const { classes, className, children, onScroll = () => 0, ...rest } = this.props;

        const sidebarWrapper = cx(classes.sidebarWrapper, {
            [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
        }, className);

        return (
            <div className={sidebarWrapper} ref={this.setSidebarWrapperRef} onScroll={(e) => onScroll(e, this.sidebarWrapper)} {...rest}>
                {children}
            </div>
        );
    }
};


export default withStyles(sidebarStyle)(PerfectScrollWrapper);
