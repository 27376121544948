import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

function FormRow({ children, helper, style }) {
    return (
        <Grid container item xs={12} style={style}>
            <Grid container item xs={12} direction="column">
                {children}
            </Grid>
            {/* <Grid container item xs={4}>
                {helper}
            </Grid> */}
        </Grid>
    );
}

FormRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    helper: PropTypes.node,
    style: PropTypes.object,
};

FormRow.defaultProps = {
    helper: null,
    style: null,
};

export default FormRow;
