import { Button, ButtonBase, CircularProgress, Dialog, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import ApproveIcon from 'assets/img/documentApprove.svg';
import { CloseOutlined } from '@mui/icons-material';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as Actions, selectors as Selectors } from 'store/modules/workerDetails';
import { actions as VerificationActions } from 'store/modules/verifications';
import { useParams } from 'react-router-dom';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
    dialog: {
        '& .MuiPaper-root': {
            borderRadius: 10
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    modalIconContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 60,
        width: 60
    },
    close: {
        position: 'absolute',
        right: 20,
        top: 20,
        background: '#E9F1FB'
    },
    title: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        marginTop: theme.spacing(2.5)
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(5)
    },
    button: {
        borderRadius: 50,
        boxShadow: 'none',
        flex: 1,
        maxWidth: '70%',
        width: '70%'
    },
    docContainer: {
        background: '#f7f7fb',
        minWidth: 335,
        width: 335,
        maxWidth: 335,
        height: 214,
        padding: theme.spacing(1.25),
        cursor: 'pointer',
        minHeight: 'min(580px, 90vh)',
        display: 'flex'
    },
    docCard: {
        display: 'flex',
        gap: theme.spacing(5),
        padding: theme.spacing(5),
        position: 'relative'
    },
    progress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg": {
            marginTop: "0 !important"
        }
    },
}));

export default function DocumentApproveDialog({ icon, onClose = () => 0, open = false }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(Selectors.getIsDocumentApproveLoading);
    const requirement = useSelector(Selectors.getApproveDocumentDilog);
    const additionalReq = requirement?.data?.childRequirements?.[requirement?.data?.childRequirements?.length - 1];
    const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
    const match = regex.exec(requirement?.data?.applicationStatus === "NewDocument" ? additionalReq?.signedDocumentUrl : requirement?.data?.signedDocumentUrl);
    const extension = match?.[1];
    const params = useParams()
    let workerId;
    const URLParams = new URLSearchParams(history.location.search);
    const type = parseInt(URLParams.get("type"), 10);
    try {
        workerId = params?.id ? window.atob(params.id) : null;
    } catch (er) {
        console.log(er);
    };

    const refreshData = async (param) => {
        return await dispatch(Actions.fetchPerRequirementsData({ workerId, filterBy: type === 0 ? "requirement" : "employer" }, param));
    };

    const openDoc = () => {
        if (extension === 'pdf') {
            window.open(requirement?.data?.applicationStatus === "NewDocument" ? additionalReq?.signedDocumentUrl : requirement?.data?.signedDocumentUrl, '_blank');
            return;
        };
        dispatch(VerificationActions.setDocumentPreviewDialog({ ...requirement, signedDocumentUrl: requirement?.data?.applicationStatus === "NewDocument" ? additionalReq?.signedDocumentUrl : requirement?.data?.signedDocumentUrl }));
    };

    const handleApprove = () => {
        dispatch(Actions.approveDocumentRequirement(refreshData));
    };

    return (
        <Dialog maxWidth={'md'} onClose={(e) => isLoading ? () => 0 : onClose(e)} open={open} className={classes.dialog}>
            <div className={classes.docCard}>
                <ButtonBase LinkComponent={'div'} className={classes.docContainer} onClick={openDoc}>
                    <DocumentViewer style={{ objectPosition: 'center' }} type={extension} document={requirement?.data?.applicationStatus === "NewDocument" ? additionalReq : requirement?.data} />
                </ButtonBase>
                <div className={classes.container}>
                    <div className={classes.modalIconContainer}>
                        {icon || <img src={ApproveIcon} className={classes.icon} />}
                    </div>
                    {requirement?.data?.applicationStatus === "NewDocument" ?
                        <Typography className={classes.title}>
                            Are you sure you want to approve <b>{
                                (requirement.ModalType === 'Employer' ?
                                    requirement?.data?.employerRequirement?.requirement?.name :
                                    requirement?.name) || '-'}</b> for <b>{
                                        requirement.ModalType === 'Employer' ?
                                            requirement.companyName :
                                            requirement?.data?.requirementHasEmployer?.employer?.companyName
                                    }</b>?
                        </Typography>
                        : <Typography className={classes.title}>
                            Are you sure you want to approve <b>{
                                (requirement.ModalType === 'Employer' ?
                                    requirement?.data?.employerRequirement?.requirement?.name :
                                    requirement?.name) || '-'}</b> for <b>{
                                        requirement.ModalType === 'Employer' ?
                                            requirement.companyName :
                                            requirement?.data?.requirementHasEmployer?.employer?.companyName
                                    }</b>?
                        </Typography>}
                    <div className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="secondary"
                            disabled={isLoading}
                            onClick={handleApprove}
                        >
                            Yes, Approve
                            {isLoading && <CircularProgress size={24} className={classes.progress} />}
                        </Button>
                        <Button
                            onClick={onClose}
                            variant="text"
                            className={classes.button}
                            disabled={isLoading}
                        >
                            Go Back
                        </Button>
                    </div>
                </div>
                <IconButton disabled={isLoading} className={classes.close} onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </div>
        </Dialog>
    )
}
