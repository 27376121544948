import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelect from 'components/Autocomplete/SimpleSelect';
import { actions as Actions } from "store/modules/employerDetails";
import { selectors as PostingSelectors } from 'store/modules/posting';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    autocomplete: {
        maxWidth: 250,
        width: 250,
        '& p.MuiTypography-root': {
            whiteSpace: 'nowrap',
            maxWidth: '95%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
}));

export default function SelectWorkType({ requirements, requirement, requirementIndex, rowIndex }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const jobTypeList = useSelector(PostingSelectors.getJobTypeList);
    const unique = requirements.flatMap(a => a?.requirements)?.map(b => {
        if (requirement?.id === b.id) return b?.workType?.value
        return null;
    }).filter(a => a);

    const onChange = (data) => {
        dispatch(Actions.handleChangeRequirement({
            requirementIndex,
            rowIndex,
            data: {
                workType: data
            }
        }));
    };

    return (
        <div>
            <SimpleSelect
                value={requirement?.workType}
                className={classes.autocomplete}
                onChange={onChange}
                options={jobTypeList.filter(a => !unique?.includes(a?.value))}
                label="Select or Enter"
                inputProps={{ label: null }}
                menuPortalTarget={document.body} />
        </div>
    );
};
