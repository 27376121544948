import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "assets/img/CircullerProgress.svg";

export const tabStyles = ({ spacing, shape, palette, customPalette }) => ({
    tabWrapper: {
        display: "flex",
        width: "70%",
        float: "left",
    },
    createWorkerWrapper: {
        width: "30%",
        float: "right",
        textAlign: "right",
    },
    tabHeader: {},
    tabLabel: {
        lineHeight: 1.5,
        marginRight: spacing(2),
        "& + span": {
            fontWeight: 500,
        },
    },
    tabRoot: {
        height: spacing(12),
        marginRight: spacing(3),
        padding: `${spacing(2)} ${spacing(3)}`,
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        minWidth: spacing(24),
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
            padding: `calc(${spacing(2)} - 1px) calc(${spacing(3)} - 1px)`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabSelected: {
        height: spacing(12),
        padding: `${spacing(2)} calc(${spacing(3)} - 2px)`,

        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
            padding: `${spacing(2)} calc(${spacing(3)} - 2px)`,
        },
        "& $tabHeader, & $tabLabel": {
            color: palette.secondary.main,
        },
    },
    tabTotalWrapper: {
        padding: `${spacing(2)} ${spacing(3)}`,
        borderLeft: `2px solid ${palette.action.disabled}`,
        maxWidth: spacing(26),
        "& span": {
            color: palette.text.primary,
        },
    },
    tabIndicator: {
        display: "none !important",
    },

    contentContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignitems: "flex-end",
        width: "99%",
        paddingTop: 32,
        paddingLeft: 0,
    },
    progress: {
        color: palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    tabFilterWrapper: {
        borderLeft: `0px solid ${palette.action.disabled}`,
        alignItems: "flex-end",
        float: "right",
        textAlign: "right",
    },
    profileImage: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    profileImageHide: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        visibility: "hidden",
    },
    checkboxLabel: { marginTop: -10 },
    checkbox: {
        paddingTop: 0,
    },
});

export const useLoaderStyels = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[400],
        backgroundImage: `url(${Loader})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: 44,
        width: 44,
        margin: "auto",
        cursor: "pointer",
        "& img": {
            objectPosition: "right"
        }
    },
    fallback: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        cursor: "default",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: 30,
            width: 30,
        }
    },
    fallbackPreview: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: 300,
            width: 300,
        }
    },
    closePreviewButton: {
        position: "absolute",
        zIndex: 1,
        background: alpha(theme.palette.common.white, 0.6),
        right: -30,
        top: -27
    },
    imageViewer: {
        "& .MuiPaper-root": {
            // overflow:"visible",
            "& .MuiAvatar-root": {
                borderRadius: 4
            }
        },
        "& .react-transform-wrapper": {
            maxWidth: "100%",
            maxHeight: "calc(100% - 64px)",
            height: "calc(600px - 64px)",
            width: "100%",
            // height:350
        },
        "& .MuiToolbar-root": {
            paddingLeft: "8px !important",
            paddingRight: "8px !important",
        },
        "& .image-viewer-wrapper": {
            height: "100%",
            width: "100%",
        },
        "& .react-viewer-mask": {
            // backgroundColor: "white !important"
        },
        "& .react-viewer-footer": {
            top: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            bottom: "unset",
            "& > div > ul": {
                display: "flex",
                alignItems: "center",
                marginBottom: "0 !important",
                justifyContent: "left"
            }
        },
        "& .react-viewer-canvas": {
            top: "60px !important",
            maxWidth: "100%",
            maxHeight: "calc(100% - 60px)",
            height: "calc(100% - 60px)",
            width: "100%",
        },
        "& .react-viewer-btn": {
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
            }
        },
        "&  .react-viewer-close": {
            borderRadius: "50%",
            height: 28,
            width: 28,
            top: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 16,
            "& > i": {
                top: "unset",
                left: "unset",
            }
        },
    },
    dialogPaper: {
        height: 600,
        maxHeight: "min(600px, 90%)",
        width: 900,
        maxWidth: "min(900px, 90%)",
        "& > div": {
            maxHeight: "100%",
            maxWidth: "100%",
        }
    },
    loader: {
        position: "absolute",
        top: 64,
        left: 0,
        height: "calc(100% - 64px)",
        width: "100%",
        background: "#c9c6c687",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));
