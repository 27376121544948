import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Datetime from "react-datetime";
import { Close, Edit, GetApp, Save, Search } from '@mui/icons-material';
import { FormControl, InputLabel } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginTop: 10,
        marginLeft: 5
    },
    EditWorkOrderNumberButton: {
        paddingRight: 0,
    }
}))
function WorkOrderFilter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const [csvURL, setCsvURL] = React.useState('');
    const { startDate, endDate } = useSelector(ReportsSelectors.getWorkOrderReportFilter);
    const isEditWorkOrderNumber = useSelector(ReportsSelectors.getIsEditableWorkOrderNumber);
    const canUpdateWorkOrderNumber = useSelector(ReportsSelectors.getCanUpdateWorkOrderNumber);
    const PaginationData = useSelector(ReportsSelectors.getWorkOrderReportPagingData);
    const isUpdating = useSelector(ReportsSelectors.getIsUpdateWorkOrderNumberLoading);

    const updateFormData = (field, value) => {
        const date = moment(value).tz('UTC').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        dispatch(ReportsActions.setWorkOrderFilter(field, date))
    };
    const onSearch = () => {
        dispatch(ReportsActions.fetchWorkOrderTableData(0));
    }
    const onDownload = () => {
        dispatch(ReportsActions.downloadWorkOrderTableData()).then(data => {
            if (data && data.url) {
                setCsvURL(data.url);
                aTag.current.click();
            }
        });
    };

    const handleToggle = () => {
        dispatch(ReportsActions.setIsEditWorkWorderNumber(!isEditWorkOrderNumber));
    };

    const updateWorkOrderNumber = () => {
        dispatch(ReportsActions.updateWorkOrderNumber());
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={12} display={"flex"}>
                    <FormControl margin="normal" style={{ zIndex: 110 }}>
                        <InputLabel htmlFor="startdate">Start Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="startdate"
                            input
                            disabled
                            dateFormat="MMMM Do YYYY"
                            closeOnSelect
                            onChange={(value) => updateFormData('startDate', value)}
                            value={startDate}
                        />
                    </FormControl>
                    &nbsp;&nbsp;
                    <FormControl margin="normal" style={{ zIndex: 110 }}>
                        <InputLabel htmlFor="date">End Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="date"
                            input
                            dateFormat="MMMM Do YYYY"
                            closeOnSelect
                            onChange={(value) => updateFormData('endDate', value)}
                            value={endDate}
                        />
                    </FormControl>
                    &nbsp;&nbsp;
                    <FormControl margin="normal" style={{ marginTop: 30 }}>
                        <Button
                            icon={<Search className={classes.buttonIcon} />}
                            alignIcon="left"
                            label="Search"
                            onClick={onSearch}
                        />
                    </FormControl>
                    <FormControl margin="normal" style={{ marginTop: 30 }}>
                        <Button
                            icon={<GetApp className={classes.buttonIcon} />}
                            label="Download CSV"
                            alignIcon="left"
                            onClick={onDownload}
                        />
                    </FormControl>
                    {isEditWorkOrderNumber ?
                        <>
                            <FormControl margin="normal" style={{ marginTop: 30, marginLeft: "auto" }}>
                                <Button
                                    className={classes.EditWorkOrderNumberButton}
                                    icon={<Close className={classes.buttonIcon} />}
                                    label="Cancel"
                                    alignIcon="left"
                                    color="error"
                                    onClick={handleToggle}
                                    disabled={isUpdating}
                                />
                            </FormControl>
                            <FormControl margin="normal" style={{ marginTop: 30 }}>
                                <Button
                                    className={classes.EditWorkOrderNumberButton}
                                    icon={<Save className={classes.buttonIcon} />}
                                    label="Save"
                                    alignIcon="left"
                                    onClick={updateWorkOrderNumber}
                                    disabled={canUpdateWorkOrderNumber || isUpdating}
                                    isLoading={isUpdating}
                                />
                            </FormControl>
                        </>
                        :
                        <FormControl margin="normal" style={{ marginTop: 30, marginLeft: "auto" }}>
                            <Button
                                className={classes.EditWorkOrderNumberButton}
                                icon={<Edit className={classes.buttonIcon} />}
                                label="Edit Work Order Number"
                                alignIcon="left"
                                onClick={handleToggle}
                                disabled={PaginationData.paging.isLoading}
                            />
                        </FormControl>
                    }
                </Grid>
            </Grid>
            <a ref={aTag} href={csvURL} target="_blank" rel="noreferrer" />
        </React.Fragment>
    );
}

export default WorkOrderFilter;
