/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import debounce from "debounce-promise";

import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";

import { fetchWorkers } from "./helpers/actions";
import styles from "./helpers/styles";
import LoadingMessage from "./components/LoadingMessage";

class WorkerAutocomplete extends React.Component {
    selectComponents = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        LoadingMessage,
        IndicatorSeparator: () => null,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    ...(this.props.style || {}),
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    height: '17px',
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
        };
    }

    render() {
        const { classes, tags, label = "Select Worker", ...passProps } = this.props;

        return (
            <AsyncSelect
                {...passProps}
                classes={classes}
                styles={{ ...this.selectStyles, ...(this.props.menuPortalTarget ? { menuPortal: (base) => ({ ...base, zIndex: 999999 }) } : {}) }}
                isClearable
                value={this.props.value}
                placeholder="Worker"
                loadOptions={debounce(e => fetchWorkers({ workerName: e, ...(tags ? { jobTags: tags } : {}) }), 1200)}
                onChange={this.props.onChange}
                components={this.selectComponents}
                textFieldProps={{
                    label,
                }}
            />
        );
    }
}

WorkerAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WorkerAutocomplete);
