import { AppBar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useSelector } from 'react-redux';
import { selectors as Selectors } from 'store/modules/employerDetails';

const useStyles = makeStyles(theme => ({
    container: {
        padding: "24px 32px 24px",
        background: theme.palette.background.paper,
        borderBottom: 0,
        position: "relative",
        width: "100%",
        zIndex: 1029,
        color: "#555555",
        border: 0,
        boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
        borderRadius: 0,
        transition: "all 150ms ease 0s",
        minHeight: 80,
        display: "block",
        marginBottom: 0
    },
    groups: {
        display: 'flex',
        gap: theme.spacing(0.5),
        flexWrap: 'nowrap',
        '& .MuiChip-label': {
            color: '#6B6F75'
        },
    },
    headerTitle: {
        color: '#3F454D',
        marginBottom: theme.spacing(0.5)
    },
    headerSubTitle: {
        color: '#6B6F75',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    img: {},
    employerWrapper: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        "& $img": {
            height: 55
        }
    }
}));

export default function Header() {
    const employer = useSelector(Selectors.getEmployerBasicData);
    const classes = useStyles();

    return (
        <>
            <AppBar className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.employerWrapper}>
                        <img className={classes.img} alt="img" src={employer?.signedLogoImageUrl} />
                        <div>
                            <Typography className={classes.headerTitle} variant="h5" letterSpacing={0.015}>{employer?.companyName}</Typography>
                            {employer?.city ? <Typography className={classes.headerSubTitle}>{employer?.city + ', ' + employer?.region}</Typography> : ''}
                        </div>
                    </div>
                    <div className={classes.groups}>
                        <Typography className={classes.headerSubTitle}>{employer?.email}</Typography>
                    </div>
                </div>
            </AppBar>
        </>
    );
};
