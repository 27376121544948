import { gql } from "@apollo/client";
import WorkerFields from "../fragments/worker-fragment";

export default gql`
  query getWorker($id: String!) {
    worker(id: $id) {
      ...WorkerFields
    }
  }
  ${WorkerFields}
`;
