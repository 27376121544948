import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import {
    getActiveEmployerReportFilter,
    getActiveEmployerReportPagingData,
    getDCBankTransactionReportFilter,
    getWorkerJobCountReportFilter,
    getWorkerJobCountReportPagingData,
    getWorkerJobReportFilter,
    getWorkerJobReportPagingData,
    getWorkerPayrollReportFilter,
    getWorkerPayrollReportPagingData,
    getWorkerWisePayrollReportFilter,
    getWorkerWisePayrollReportPagingData,
    getWSBCReportFilter,
    getWSBCReportPagingData,
    getWorkOrderReportFilter,
    getWorkOrderReportPagingData,
    getWeeklyBillingReportFilter,
    getWeeklyBillingReportPagingData,
    getHolidayPayrollReportFilter,
    getHolidayPayrollReportPagingData,
    getUpdatedWorkOrderNumberData,
} from "./selectors";

import types from "./types";
import {
    activeEmployerSummaryReportCSV,
    activeEmployerSummaryReportQuery,
    workerListBasedOnEmployeeReportCSV,
    workerListBasedOnEmployerReportQuery,
    workerPayrollReportCSV,
    workerPayrollReportQuery,
    workerWisePayrollReportCSV,
    workerWisePayrollReportQuery,
    workerJobReportQuery,
    workerJobReportCSV,
    WSBCReportQuery,
    WSBCReportCSV,
    WorkOrderReportQuery,
    WorkOrderReportCSV,
    weeklyBillingReportQuery,
    weeklyBillingReportCSV,
    holidayPayrollReportQuery,
    holidayPayrollReportCSV
} from "./graphql/queries";
import dcBankTransactionReportQuery, {
    dcBankTransactionReportCSV,
} from "./graphql/queries/dc-bank";
import moment from "moment";
import { updateWorkOrderNumberQuery } from "./graphql/mutations/";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsDCReportLoading = setLoadingState("dcReport");
export const setIsDCReportDownloadLoading = setLoadingState("dcReportDownload");
const setIsUpdatingWorkOrderNumberDataLoading = setLoadingState("updateWorkOrderNumber");
/*
Worker payroll report
*/
const setWorkerPayrollData = data => ({ type: types.SET_WORKER_PAYROLL_DATA, payload: { data } });
const setWorkerPayrollTotalCount = data => ({
    type: types.SET_WORKER_PAYROLL_TOTAL_COUNT,
    payload: { data },
});
export const setWorkerPayrollFilter = (field, value) => ({
    type: types.SET_WORKER_PAYROLL_FILTER,
    payload: { field, value },
});

export const fetchWorkerPayrollTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerPayrollReportPagingData(state).paging;
    const filter = getWorkerPayrollReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.workerPayrollReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: workerPayrollReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWorkerPayrollData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { workerPayrollReport } = data;
                const paginationData = {
                    ...workerPayrollReport.pageInfo,
                    totalCount: workerPayrollReport.totalCount,
                };

                dispatch(pagination.updatePageInfo(paginationSettings.workerPayrollReport, paginationData));
                dispatch(setWorkerPayrollData(flattenGraphQLArray(workerPayrollReport)));
                dispatch(setWorkerPayrollTotalCount(workerPayrollReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.workerPayrollReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.workerPayrollReport));
        });
};

export const downloadWorkerPayrollTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWorkerPayrollReportFilter(state);
    return ApolloClient.query({
        query: workerPayrollReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.workerPayrollReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changePageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerPayrollReport, { pageSize }));
    //   return dispatch(fetchWorkerPayrollTableData(0));
};

/*
Worker wise payroll report
*/
const setWorkerWisePayrollData = data => ({
    type: types.SET_WORKER_WISE_PAYROLL_DATA,
    payload: { data },
});
const setWorkerWisePayrollTotalCount = data => ({
    type: types.SET_WORKER_WISE_PAYROLL_TOTAL_COUNT,
    payload: { data },
});
export const setWorkerWisePayrollFilter = (field, value) => ({
    type: types.SET_WORKER_WISE_PAYROLL_FILTER,
    payload: { field, value },
});

export const fetchWorkerWisePayrollTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerWisePayrollReportPagingData(state).paging;
    const filter = getWorkerWisePayrollReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.workerWisePayrollReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: workerWisePayrollReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWorkerWisePayrollData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { workerWisePayrollReport } = data;
                const paginationData = {
                    ...workerWisePayrollReport.pageInfo,
                    totalCount: workerWisePayrollReport.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.workerWisePayrollReport, paginationData),
                );
                dispatch(setWorkerWisePayrollData(flattenGraphQLArray(workerWisePayrollReport)));
                dispatch(setWorkerWisePayrollTotalCount(workerWisePayrollReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.workerWisePayrollReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.workerWisePayrollReport));
        });
};

export const downloadWorkerWisePayrollTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWorkerWisePayrollReportFilter(state);
    return ApolloClient.query({
        query: workerWisePayrollReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.workerWisePayrollReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeWorkerWisePayrollTablePageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerWisePayrollReport, { pageSize }));
    //   return dispatch(fetchWorkerWisePayrollTableData(0));
};

const setWorkerJobData = data => ({ type: types.SET_WORKER_JOB_DATA, payload: { data } });
export const setWorkerJobFilter = (field, value) => ({
    type: types.SET_WORKER_JOB_FILTER,
    payload: { field, value },
});

export const fetchWorkerJobTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerJobReportPagingData(state).paging;
    const filter = getWorkerWisePayrollReportFilter(state);
    const jobFilter = getWorkerJobReportFilter(state);

    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.workerJobReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: workerJobReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
            ...jobFilter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWorkerJobData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { payrollReportByWorkerId } = data;
                const paginationData = {
                    ...payrollReportByWorkerId.pageInfo,
                    totalCount: payrollReportByWorkerId.totalCount,
                };

                dispatch(pagination.updatePageInfo(paginationSettings.workerJobReport, paginationData));
                dispatch(setWorkerJobData(flattenGraphQLArray(payrollReportByWorkerId)));
                dispatch(pagination.doneLoading(paginationSettings.workerJobReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.workerJobReport));
        });
};

export const downloadWorkerJobTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWorkerWisePayrollReportFilter(state);
    const jobFilter = getWorkerJobReportFilter(state);
    return ApolloClient.query({
        query: workerJobReportCSV,
        variables: {
            ...filter,
            ...jobFilter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.payrollReportByWorkerIdCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeWorkerJobTablePageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerJobReport, { pageSize }));
    //   return dispatch(fetchWorkerJobTableData(0));
};


/*
Weekly billing report
*/
const setWeeklyBillingReportsData = data => ({
    type: types.SET_WEEKLY_BILLING_REPORT_DATA,
    payload: { data },
});
const setWeeklyBillingReportsTotalCount = data => ({
    type: types.SET_WEEKLY_BILLING_REPORT_TOTAL_COUNT,
    payload: { data },
});
export const setWeeklyBillingReportsFilter = (field, value) => ({
    type: types.SET_WEEKLY_BILLING_REPORT_FILTER,
    payload: { field, value },
});

export const fetchWeeklyBillingReportsTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWeeklyBillingReportPagingData(state).paging;
    const filter = getWeeklyBillingReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.WeeklyBillingReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: weeklyBillingReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWeeklyBillingReportsData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { weeklyBillingReport } = data;
                const paginationData = {
                    ...weeklyBillingReport.pageInfo,
                    totalCount: weeklyBillingReport.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.WeeklyBillingReport, paginationData),
                );
                dispatch(setWeeklyBillingReportsData(flattenGraphQLArray(weeklyBillingReport)));
                dispatch(setWeeklyBillingReportsTotalCount(weeklyBillingReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.WeeklyBillingReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.WeeklyBillingReport));
        });
};

export const downloadWeeklyBillingReportTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWeeklyBillingReportFilter(state);
    return ApolloClient.query({
        query: weeklyBillingReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.weeklyBillingReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeWeeklyBillingReportTablePageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.WeeklyBillingReport, { pageSize }));
    //   return dispatch(fetchWeeklyBillingReportsTableData(0));
};

export const changeWeeklyBillingReportFilter = like => dispatch => {
    dispatch({
        type: types.SET_WEEKLY_BILLING_REPORT_FILTER,
        payload: { like },
    });
    //   return dispatch(fetchWeeklyBillingReportTableData(0));
};

/*
Active Employer report
*/
const setActiveEmployerData = data => ({ type: types.SET_ACTIVE_EMPLOYER_DATA, payload: { data } });
const setActiveEmployerTotalCount = data => ({
    type: types.SET_ACTIVE_EMPLOYER_TOTAL_COUNT,
    payload: { data },
});
export const setActiveEmployerFilter = (field, value) => ({
    type: types.SET_ACTIVE_EMPLOYER_FILTER,
    payload: { field, value },
});

export const fetchActiveEmployerTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getActiveEmployerReportPagingData(state).paging;
    const filter = getActiveEmployerReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.activeEmployerReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: activeEmployerSummaryReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setActiveEmployerData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { activeEmployerSummaryReport } = data;
                const paginationData = {
                    ...activeEmployerSummaryReport.pageInfo,
                    totalCount: activeEmployerSummaryReport.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.activeEmployerReport, paginationData),
                );
                dispatch(setActiveEmployerData(flattenGraphQLArray(activeEmployerSummaryReport)));
                dispatch(setActiveEmployerTotalCount(activeEmployerSummaryReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.activeEmployerReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.activeEmployerReport));
        });
};

export const downloadActiveEmployerTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getActiveEmployerReportFilter(state);
    return ApolloClient.query({
        query: activeEmployerSummaryReportCSV,
        variables: {
            ...filter
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.activeEmployerSummaryReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeActiveEmployerPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.activeEmployerReport, { pageSize }));
    //   return dispatch(fetchActiveEmployerTableData(0));
};

/*
WSBC report
*/
const setWSBCData = data => ({ type: types.SET_WSBC_DATA, payload: { data } });
const setWSBCTotalCount = data => ({ type: types.SET_WSBC_TOTAL_COUNT, payload: { data } });
export const setWSBCFilter = (field, value) => ({
    type: types.SET_WSBC_FILTER,
    payload: { field, value },
});

export const fetchWSBCTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWSBCReportPagingData(state).paging;
    const filter = getWSBCReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.WSBCReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: WSBCReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWSBCData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { wsbcReport } = data;
                const paginationData = {
                    ...wsbcReport.pageInfo,
                    totalCount: wsbcReport.totalCount,
                };

                dispatch(pagination.updatePageInfo(paginationSettings.WSBCReport, paginationData));
                dispatch(setWSBCData(flattenGraphQLArray(wsbcReport)));
                dispatch(setWSBCTotalCount(wsbcReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.WSBCReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.WSBCReport));
        });
};

export const downloadWSBCTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWSBCReportFilter(state);
    return ApolloClient.query({
        query: WSBCReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.wsbcReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeWSBCPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.WSBCReport, { pageSize }));
    //   return dispatch(fetchWSBCTableData(0));
};

/*
Worker job count report
*/
const setWorkerJobCountData = data => ({
    type: types.SET_WORKER_JOB_COUNT_DATA,
    payload: { data },
});
const setWorkerJobCountTotalCount = data => ({
    type: types.SET_WORKER_JOB_COUNT_TOTAL_COUNT,
    payload: { data },
});
export const setWorkerJobCountFilter = (field, value) => ({
    type: types.SET_WORKER_JOB_COUNT_FILTER,
    payload: { field, value },
});

export const fetchWorkerJobCountTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerJobCountReportPagingData(state).paging;
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.workerJobCountReport)(pageInfo, pageIndex),
    );
    const filter = getWorkerJobCountReportFilter(state);
    return ApolloClient.query({
        query: workerListBasedOnEmployerReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWorkerJobCountData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { workerListBasedOnEmployerReport } = data;
                const paginationData = {
                    ...workerListBasedOnEmployerReport.pageInfo,
                    totalCount: workerListBasedOnEmployerReport.totalCount,
                };
                dispatch(
                    pagination.updatePageInfo(paginationSettings.workerJobCountReport, paginationData),
                );
                dispatch(setWorkerJobCountData(flattenGraphQLArray(workerListBasedOnEmployerReport)));
                dispatch(setWorkerJobCountTotalCount(workerListBasedOnEmployerReport.totalCount));
            }
            dispatch(pagination.doneLoading(paginationSettings.workerJobCountReport));
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.workerJobCountReport));
        });
};

export const changeWorkerJobCountPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerJobCountReport, { pageSize }));
    //   return dispatch(fetchWorkerJobCountTableData(0));
};
export const changeWorkerJobCountFilter = like => dispatch => {
    dispatch({
        type: types.SET_WORKER_JOB_COUNT_FILTER,
        payload: { like },
    });
    //   return dispatch(fetchWorkerJobCountTableData(0));
};
export const downloadWorkerJobCountTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getWorkerJobCountReportFilter(state);
    return ApolloClient.query({
        query: workerListBasedOnEmployeeReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.workerListBasedOnEmployeeReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

const setDCBankTransactionData = data => ({
    type: types.SET_DC_BANK_TRANSACTION_DATA,
    payload: { data },
});
export const setDCBankTransactionFilter = (field, value) => ({
    type: types.SET_DC_BANK_TRANSACTION_FILTER,
    payload: { field, value },
});
export const fetchDCBankTransactionTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getDCBankTransactionReportFilter(state);
    dispatch(setIsDCReportLoading(true));
    return ApolloClient.query({
        query: dcBankTransactionReportQuery,
        variables: {
            ...(filter.ClientReferenceNumber
                ? { ClientReferenceNumber: filter.ClientReferenceNumber }
                : {}),
            ...(filter.CustomerNumber ? { CustomerNumber: filter.CustomerNumber } : {}),
            ...(filter.TransactionStatusCode
                ? { TransactionStatusCode: filter.TransactionStatusCode }
                : {}),
            ...(filter.TransactionId ? { TransactionId: Number(filter.TransactionId) } : {}),
            MinDateOfFunds: moment(filter.MinDateOfFunds).format("YYYY-MM-DD"),
            MaxDateOfFunds: moment(filter.MaxDateOfFunds).format("YYYY-MM-DD"),
            workerId: filter.worker ? filter.worker.value : "",
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            const { workerDcBankHistory } = data;
            dispatch(setDCBankTransactionData(workerDcBankHistory));
            dispatch(setIsDCReportLoading(false));
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsDCReportLoading(false));
        });
};
export const downloadDCBankTransactionTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getDCBankTransactionReportFilter(state);
    dispatch(setIsDCReportDownloadLoading(true));
    return ApolloClient.query({
        query: dcBankTransactionReportCSV,
        variables: {
            ...(filter.ClientReferenceNumber
                ? { ClientReferenceNumber: filter.ClientReferenceNumber }
                : {}),
            ...(filter.CustomerNumber ? { CustomerNumber: filter.CustomerNumber } : {}),
            ...(filter.TransactionStatusCode
                ? { TransactionStatusCode: filter.TransactionStatusCode }
                : {}),
            ...(filter.TransactionId ? { TransactionId: Number(filter.TransactionId) } : {}),
            MinDateOfFunds: moment(filter.MinDateOfFunds).format("YYYY-MM-DD"),
            MaxDateOfFunds: moment(filter.MaxDateOfFunds).format("YYYY-MM-DD"),
            workerId: filter.worker ? filter.worker.value : "",
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.workerDcBankHistoryCSV;
        })
        .catch(e => {
            dispatch(setIsDCReportDownloadLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};


/*
WorkOrder report
*/
const setWorkOrderData = data => ({ type: types.SET_WORK_ORDER_DATA, payload: { data } });
const setWorkOrderTotalCount = data => ({ type: types.SET_WORK_ORDER_TOTAL_COUNT, payload: { data } });
export const setWorkOrderFilter = (field, value) => ({
    type: types.SET_WORK_ORDER_FILTER,
    payload: { field, value },
});
export const setIsEditWorkWorderNumber = value => ({ type: types.SET_IS_EDIT_WORK_ORDER_NUMBER, payload: { isEdit: value } });
export const changeWorkOrderFilter = like => dispatch => {
    dispatch({
        type: types.SET_WORK_ORDER_FILTER,
        payload: { field: "like", value: like },
    });
    //   return dispatch(fetchWorkerJobCountTableData(0));
};
export const changeWorkOrderNumbers = ({ value, positionId }) => ({ type: types.SET_CHANGE_WORK_ORDER_NUMBER_BY_POSITION_ID, payload: { value, positionId } });

export const updateWorkOrderNumber = () => (dispatch, getState) => {
    const state = getState();
    const data = getUpdatedWorkOrderNumberData(state);
    const pageInfo = getWorkOrderReportPagingData(state).paging;
    dispatch(setIsUpdatingWorkOrderNumberDataLoading(true));

    return ApolloClient.query({
        query: updateWorkOrderNumberQuery,
        variables: {
            data
        },
    }).then(({ errors }) => {
        if (errors && errors.length) throw new Error(errors[0].message);
        dispatch(fetchWorkOrderTableData(pageInfo.currentPage))
        dispatch(setIsEditWorkWorderNumber(false))
        dispatch(setIsUpdatingWorkOrderNumberDataLoading(false));
    }).catch(er => {
        dispatch(MetaActions.errorToast(er))
        dispatch(setIsUpdatingWorkOrderNumberDataLoading(false));
    });
};

export const fetchWorkOrderTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkOrderReportPagingData(state).paging;
    let { like, ...filter } = getWorkOrderReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.WorkOrderReport)(pageInfo, pageIndex),
    );

    if (like && like.length) {
        like = like.map(({ id, value }) => {
            return { field: id, value }
        })
    }

    return ApolloClient.query({
        query: WorkOrderReportQuery,
        variables: {
            ...filter,
            like,
            startDate: moment(filter.startDate).format("MM/DD/YYYY"),
            endDate: moment(filter.endDate).format("MM/DD/YYYY"),
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setWorkOrderData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { workorderReport } = data;
                const paginationData = {
                    ...workorderReport.pageInfo,
                    totalCount: workorderReport.totalCount,
                };

                dispatch(pagination.updatePageInfo(paginationSettings.WorkOrderReport, paginationData));
                dispatch(setWorkOrderData(flattenGraphQLArray(workorderReport)));
                dispatch(setWorkOrderTotalCount(workorderReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.WorkOrderReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.WorkOrderReport));
        });
};

export const downloadWorkOrderTableData = () => (dispatch, getState) => {
    const state = getState();
    let { like, ...filter } = getWorkOrderReportFilter(state);
    if (like && like.length) {
        like = like.map(({ id, value }) => {
            return { field: id, value }
        })
    }
    return ApolloClient.query({
        query: WorkOrderReportCSV,
        variables: {
            like,
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.workorderReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeWorkOrderPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.WorkOrderReport, { pageSize }));
    //   return dispatch(fetchWorkOrderTableData(0));
};


/*
Holiday payroll report
*/
const setHolidayPayrollData = data => ({
    type: types.SET_HOLIDAY_PAYROLL_DATA,
    payload: { data },
});
const setHolidayPayrollTotalCount = data => ({
    type: types.SET_HOLIDAY_PAYROLL_TOTAL_COUNT,
    payload: { data },
});
export const setHolidayPayrollFilter = (field, value) => ({
    type: types.SET_HOLIDAY_PAYROLL_FILTER,
    payload: { field, value },
});

export const fetchHolidayPayrollTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getHolidayPayrollReportPagingData(state).paging;
    const filter = getHolidayPayrollReportFilter(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.holidayPayrollReport)(pageInfo, pageIndex),
    );
    return ApolloClient.query({
        query: holidayPayrollReportQuery,
        variables: {
            ...filter,
            ...pagingVars,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setHolidayPayrollData(flattenGraphQLArray([])));
                throw Error(errors[0].message);
            } else {
                const { holidayPayrollReport } = data;
                const paginationData = {
                    ...holidayPayrollReport.pageInfo,
                    totalCount: holidayPayrollReport.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.holidayPayrollReport, paginationData),
                );
                dispatch(setHolidayPayrollData(flattenGraphQLArray(holidayPayrollReport)));
                dispatch(setHolidayPayrollTotalCount(holidayPayrollReport.totalCount));
                dispatch(pagination.doneLoading(paginationSettings.holidayPayrollReport));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.holidayPayrollReport));
        });
};

export const downloadHolidayPayrollTableData = () => (dispatch, getState) => {
    const state = getState();
    const filter = getHolidayPayrollReportFilter(state);
    return ApolloClient.query({
        query: holidayPayrollReportCSV,
        variables: {
            ...filter,
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                throw Error(errors[0].message);
            }
            return data.holidayPayrollReportCSV;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const changeHolidayPayrollTablePageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.holidayPayrollReport, { pageSize }));
    //   return dispatch(fetchHolidayPayrollTableData(0));
};
