import { makeStyles } from '@mui/styles';
import MainContent from 'components/Containers/MainContent';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { actions as Actions, selectors as Selectors } from 'store/modules/workerDetails';
import { actions as VerificationsActions, selectors as VerificationsSelectors } from 'store/modules/verifications';
import Header from './components/Header';
import { Dialog, Grid, Slide } from '@mui/material';
import Stage from 'views/AdditionalRequirements/components/Stage';
import SwipeableViews from 'react-swipeable-views';
import PerRequirementsView from './PerRequirementsView';
import EmployerRequirements from './EmployerRequirements';
import history from 'utils/history';
import RequirementWiseApproveAll from './Dialogs/RequirementWiseApproveAll';
import ApproveRejectConfiramtion from './Dialogs/ApproveRejectConfiramtion';
import ApproveRequirement from './Dialogs/ApproveRequirement';
import RejectConsentDialog from './Dialogs/RejectConsent';
import RejectedConsentInfo from './Dialogs/RejectedConsentInfo';
import AdditionalDocForConsent from './Dialogs/AdditionalDocForConsent';
import DocumentApproveDialog from './Dialogs/DocumentApprove';
import ReactViewer from 'react-viewer';
import DocumentRejectDialog from './Dialogs/DocumentReject';
import RejectedDocumentInfoDialog from './Dialogs/RejectedDocumentInfoDialog';
import AlternateDocumentDialog from './Dialogs/AlternateDocumentDialog';
import ReSubmitDocumentDialog from './Dialogs/ResubmissionDocument';
import UploadAndApproveDialog from './Dialogs/UploadAndApproveDocument';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0
    },
    imageViewer: {
        "& .MuiPaper-root": {
            // overflow:"visible",
            "& .MuiAvatar-root": {
                borderRadius: 4
            }
        },
        "& .react-transform-wrapper": {
            maxWidth: "100%",
            maxHeight: "calc(100% - 64px)",
            height: "calc(600px - 64px)",
            width: "100%",
            // height:350
        },
        "& .MuiToolbar-root": {
            paddingLeft: "8px !important",
            paddingRight: "8px !important",
        },
        "& .image-viewer-wrapper": {
            height: "100%",
            width: "100%",
        },
        "& .react-viewer-mask": {
            // backgroundColor: "white !important"
        },
        "& .react-viewer-footer": {
            top: 0,
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            bottom: "unset",
            "& > div > ul": {
                display: "flex",
                alignItems: "center",
                marginBottom: "0 !important",
                justifyContent: "left"
            }
        },
        "& .react-viewer-canvas": {
            top: "60px !important",
            maxWidth: "100%",
            maxHeight: "calc(100% - 60px)",
            height: "calc(100% - 60px)",
            width: "100%",
            '& > div': {
                height: "100% !important",
            }
        },
        "& .react-viewer-btn": {
            backgroundColor: "transparent",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)"
            }
        },
        "&  .react-viewer-close": {
            borderRadius: "50%",
            height: 28,
            width: 28,
            top: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 16,
            "& > i": {
                top: "unset",
                left: "unset",
            }
        },
    },
    dialogPaper: {
        height: 867,
        maxHeight: "min(867px, 90%)",
        width: 1200,
        maxWidth: "min(1200px, 90%)",
        "& > div": {
            maxHeight: "100%",
            maxWidth: "100%",
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

export default function RequirementsOverview() {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(Selectors.getIsPageLoading);
    const URLParams = new URLSearchParams(history.location.search);
    const type = parseInt(URLParams.get("type"), 10);
    const activeTab = useSelector(Selectors.getRequirementActiveTab);
    const requirementWiseApproveOpen = useSelector(Selectors.getRequirementWiseApproveAllDialog);
    const approveRejectConfiramtion = useSelector(Selectors.getApproveRejectConfirmationDilog);
    const approveRequirementOpen = useSelector(Selectors.getApproveRequirementDilog);
    const rejectConsentDialog = useSelector(Selectors.getRejectConsentDilog);
    const rejectedConsentDialog = useSelector(Selectors.getRejectedInfoDialog);
    const rejectedAdditionalDocConsentDialog = useSelector(Selectors.getRejectedAdditionalDocDialog);
    const approveDocumentOpen = useSelector(Selectors.getApproveDocumentDilog);
    const rejectDocumentOpen = useSelector(Selectors.getRejectDocumentDilog);
    const rejectDocumentInfoOpen = useSelector(Selectors.getDocumentRejectedInfoDialog);
    const alternateDocumentOpen = useSelector(Selectors.getAlternateDocDialog);
    const resubmissionDocumentOpen = useSelector(Selectors.getResubmissionDialog);
    const uploadApproveDialog = useSelector(Selectors.getUploadApproveDialog);
    const [transformRef, setTransformRef] = useState({ current: null });
    const openPreview = useSelector(VerificationsSelectors.getDocumentPreviewDialog);
    let workerId;
    try {
        workerId = params?.id ? window.atob(params.id) : null;
    } catch (er) {
        console.log(er);
    };

    const transformRefNode = useCallback(node => {
        if (node !== null) {
            setTimeout(() => {
                setTransformRef({ current: node });
            }, 100);
        }
    }, [Boolean(openPreview)]);


    useEffect(() => {
        if (workerId) {
            dispatch(Actions.fetchBasicWorkerData(workerId));
        }
    }, [workerId]);


    const handleCloseRequirementWiseApproveDialog = () => {
        dispatch(Actions.setRequirementWiseApproveAllDialog(false));
    };

    const handleCloseApproveRejectDialog = () => {
        dispatch(Actions.setApproveRejectConfirmationDilog(false));
    };

    const handleCloseApproveRequirementDialog = () => {
        dispatch(Actions.setApproveRequirementDilog(false));
    };

    const handleCloseRejectConsentDialog = () => {
        dispatch(Actions.setRejectConsentDilog(false));
    };

    const handleCloseRejectedDialog = () => {
        dispatch(Actions.setRejectedInfoDialog(false));
    };

    const handleCloseRejectedAdditionalDocDialog = () => {
        dispatch(Actions.setRejectedAdditionalDocDialog(false));
    };

    const handleCloseApproveDocDialog = () => {
        dispatch(Actions.setApproveDocumentDilog(false));
    };

    const handleCloseRejectDocDialog = () => {
        dispatch(Actions.setRejectDocumentDilog(false));
    };

    const handleCloseRejectedDocInfoDialog = () => {
        dispatch(Actions.setDocumentRejectedInfoDialog(false));
    };

    const handleCloseAlternateDocDialog = () => {
        dispatch(Actions.setAlternateDocDialog(false));
    };

    const handleCloseResubmissionDocDialog = () => {
        dispatch(Actions.setResubmissionDialog(false));
    };
    
    const handleCloseUploadApproveDialog = () => {
        dispatch(Actions.setUploadApproveDialog(false));
    };

    const handleClose = () => {
        dispatch(VerificationsActions.setDocumentPreviewDialog(false));
    };

    const ImageViewer = () => {
        return transformRef.current ? <ReactViewer
            container={transformRef.current}
            className={classes.imageViewer}
            scalable={false}
            downloadable={false}
            loop={false}
            attribute={false}
            noImgDetails
            noNavbar
            noResetZoomAfterChange
            changeable={false}
            visible={Boolean(openPreview)}
            customToolbar={() => {
                return [
                    { actionType: 1, key: "zoomIn" },
                    { actionType: 2, key: "zoomOut" },
                    { actionType: 5, key: "rotateLeft" },
                    { actionType: 6, key: "rotateRight" },
                    { actionType: 7, key: "reset" }
                ];
            }}
            onClose={handleClose}
            images={[{ src: openPreview?.signedDocumentUrl }]}
        /> : '';
    };

    return (
        <React.Fragment>
            <MainContent className={classes.container} isLoading={isLoading}>
                <Header />
                <MainContent>
                    <SwipeableViews axis="x" index={activeTab}>
                        {type === 0 ? <PerRequirementsView /> : <></>}
                        {type === 1 ? <EmployerRequirements /> : <></>}
                    </SwipeableViews>
                </MainContent>
            </MainContent>
            <RequirementWiseApproveAll open={requirementWiseApproveOpen} onClose={handleCloseRequirementWiseApproveDialog} />
            <ApproveRejectConfiramtion open={approveRejectConfiramtion} onClose={handleCloseApproveRejectDialog} />
            <ApproveRequirement open={approveRequirementOpen} onClose={handleCloseApproveRequirementDialog} />
            <RejectConsentDialog open={rejectConsentDialog} onClose={handleCloseRejectConsentDialog} />
            <RejectedConsentInfo open={rejectedConsentDialog} onClose={handleCloseRejectedDialog} />
            <AdditionalDocForConsent open={rejectedAdditionalDocConsentDialog} onClose={handleCloseRejectedAdditionalDocDialog} />
            <DocumentApproveDialog open={approveDocumentOpen} onClose={handleCloseApproveDocDialog} />
            <DocumentRejectDialog open={rejectDocumentOpen} onClose={handleCloseRejectDocDialog} />
            <RejectedDocumentInfoDialog open={rejectDocumentInfoOpen} onClose={handleCloseRejectedDocInfoDialog} />
            <AlternateDocumentDialog open={alternateDocumentOpen} onClose={handleCloseAlternateDocDialog} />
            <ReSubmitDocumentDialog open={resubmissionDocumentOpen} onClose={handleCloseResubmissionDocDialog} />
            <UploadAndApproveDialog open={uploadApproveDialog} onClose={handleCloseUploadApproveDialog} />
            <Dialog
                maxWidth="lg"
                TransitionComponent={Transition}
                open={Boolean(openPreview)}
                onClose={handleClose}
                className={classes.imageViewer}
                classes={{ paper: classes.dialogPaper }}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <div ref={transformRefNode} className='image-viewer-wrapper'>
                    <ImageViewer />
                </div>
            </Dialog>
        </React.Fragment >
    );
};
