import { CircularProgress, DialogActions, FormControlLabel, Grid, Switch, TextField, Button } from '@mui/material'
import Dialog from 'components/Dialog/Dialog'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProvinceBreakRuleActions, selectors as ProvinceBreakRuleSelectors } from "store/modules/provinceBreakRule";
import ProvinceAutocomplete from 'components/Autocomplete/ProvinceAutocomplete';
import { Form, Formik } from 'formik';
import NumberFormat from 'react-number-format';
import * as yup from "yup";
import SimpleSelect from 'components/Autocomplete/SimpleSelect';

const useStyles = makeStyles(({ spacing, ...theme }) => ({
    container: {
        width: 450,
        maxWidth: "100%",
        marginTop: spacing(2)
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
        "& .MuiAlert-action": {
            alignItems: "center",
            marginTop: 0,
            paddingTop: 0
        }
    },
    selectedDocument: {
        marginTop: spacing(1.5),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: spacing(3),
        justifyContent: "center",
        alignItems: "center"
    },
    modal: {
        padding: spacing(6),
        paddingBottom: spacing(1),
        margin: spacing(2),

        alignItems: "center",
    },
    textField: {
        marginBottom: spacing(0),
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    durationInput: {
        marginTop: spacing(0),
    },
    durationInputGrid: {
        paddingTop: `${spacing(0)} !important`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "end",
        paddingTop: spacing(4)
    },
    switch: {
        padding: 0,
        width: 50,
        height: 26,
        marginLeft: spacing(1),
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(25px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.grey[400],
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    row: {
        textAlign: "left"
    },
    gridContainer: {
        marginTop: spacing(1)
    },
    formControlLabel: {
        marginLeft: spacing(0),
        "& .MuiTypography-root": {
            color: theme.palette.text.primary,
            fontWeight: "bold"
        }
    },
    divider: {
        marginTop: spacing(2)
    }
}));

export default function AddProvinceBreakRuleDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAdd = useSelector(ProvinceBreakRuleSelectors.getIsAddProvinceBreakRuleDialog);
    const isEdit = useSelector(ProvinceBreakRuleSelectors.getIsEditProvinceBreakRuleDialog);
    const openDialog = isAdd || isEdit;
    const form = useSelector(ProvinceBreakRuleSelectors.getProvinceBreakRuleForm);
    const isLoading = useSelector(ProvinceBreakRuleSelectors.getIsFormActionLoading);

    const handleClose = () => {
        dispatch(ProvinceBreakRuleActions.setIsAddProvinceBreakRuleDialog(false))
        dispatch(ProvinceBreakRuleActions.setIsEditProvinceBreakRuleDialog(false))
        dispatch(ProvinceBreakRuleActions.resetForm());
    };

    const handleSubmit = (values) => {
        dispatch(ProvinceBreakRuleActions.addProvinceBreakRule(values));
    };

    return (
        <Dialog
            open={openDialog}
            title={`${isEdit ? "Update" : "Add"} Province Wise Break Rule`}
            confirmText={isEdit ? "Update" : "Add"}
            disabled={isLoading}
            onClose={handleClose}
            alertType="form"
            hideActions
            modalType="editEmployer">
            <div className={classes.container}>
                <Formik
                    initialValues={{
                        id: form.id || null,
                        isActive: form.isActive || false,
                        province: form.province || null,
                        minShiftLength: form.minShiftLength || 0,
                        maxShiftLength: form.maxShiftLength || 0,
                        minBreakMinutes: form.minBreakMinutes || 0,
                        breakType: form.breakType || 0,
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            handleChange,
                            handleBlur,
                            setFieldValue
                        } = props;
                        return (
                            <Form style={{ width: "100%" }}>
                                <Grid item container direction="row" xs={12} className={classes.content}>
                                    <Grid className={classes.gridContainer} item container xs={12} spacing={1}>
                                        <Grid item xs={12} className={classes.row}>
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                checked={values.isActive}
                                                control={<Switch className={classes.switch} />}
                                                label="Is Active"
                                                labelPlacement="start"
                                                name='isActive'
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ position: "relative", zIndex: 9999, marginTop: 28 }}>
                                                <ProvinceAutocomplete
                                                    value={values?.province}
                                                    menuPortalTarget={document.body}
                                                    name='province'
                                                    onChange={(val) => setFieldValue("province", val)}
                                                    inputProps={{
                                                        error: errors.province && touched.province && errors.province,
                                                        helperText: errors.province && touched.province && errors.province,
                                                        onBlur: handleBlur
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <NumberFormat
                                                className={classes.textField}
                                                placeholder='0'
                                                fullWidth
                                                margin="normal"
                                                label="Min Break Minutes"
                                                name="minBreakMinutes"
                                                value={values.minBreakMinutes}
                                                customInput={TextField}
                                                onChange={handleChange}
                                                mask=""
                                                InputProps={{
                                                    endAdornment: (
                                                        values.minBreakMinutes > 59 ? <span style={{ whiteSpace: 'nowrap', color: '#81818' }}>{parseInt(values.minBreakMinutes / 60) + 'h ' + (values.minBreakMinutes % 60)?.toFixed?.() + 'm'}</span> : ''
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                                error={errors.minBreakMinutes && touched.minBreakMinutes && errors.minBreakMinutes}
                                                helperText={errors.minBreakMinutes && touched.minBreakMinutes && errors.minBreakMinutes}
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <NumberFormat
                                                className={classes.textField}
                                                placeholder='0'
                                                fullWidth
                                                margin="normal"
                                                label="Min Shift Hours"
                                                name="minShiftLength"
                                                value={values.minShiftLength}
                                                customInput={TextField}
                                                onChange={handleChange}
                                                mask=""
                                                onBlur={handleBlur}
                                                error={errors.minShiftLength && touched.minShiftLength && errors.minShiftLength}
                                                helperText={errors.minShiftLength && touched.minShiftLength && errors.minShiftLength}
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <NumberFormat
                                                className={classes.textField}
                                                placeholder='0'
                                                fullWidth
                                                margin="normal"
                                                label="Max Shift Hours"
                                                name="maxShiftLength"
                                                value={values.maxShiftLength}
                                                customInput={TextField}
                                                mask=""
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.maxShiftLength && touched.maxShiftLength && errors.maxShiftLength}
                                                helperText={errors.maxShiftLength && touched.maxShiftLength && errors.maxShiftLength}
                                            />
                                        </Grid>
                                        <Grid item xs={4} >
                                            <div style={{ position: "relative", zIndex: 9999, marginTop: 28 }}>
                                                <SimpleSelect
                                                    value={values?.breakType}
                                                    label="Break Type"
                                                    options={[{ label: 'Paid', value: 'Paid' }, { label: 'Unpaid', value: 'Unpaid' }]}
                                                    menuPortalTarget={document.body}
                                                    name='breakType'
                                                    onChange={(val) => setFieldValue("breakType", val)}
                                                    inputProps={{
                                                        error: errors.breakType && touched.breakType && errors.breakType,
                                                        helperText: errors.breakType && touched.breakType && errors.breakType,
                                                        onBlur: handleBlur
                                                    }} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <DialogActions className={classes.footer}>
                                    <Button disabled={isLoading} color="error" type="button" className="outline" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!dirty || isLoading}
                                    >
                                        {isLoading ? <CircularProgress size={20} /> : isEdit ? "Update" : "Add"}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Dialog>
    )
};


const validationSchema = yup.object().shape({
    isActive: yup.boolean(),
    province: yup.object().nullable().required("Province is required field"),
    breakType: yup.object().nullable().required("Break Type is required field"),
    minBreakMinutes: yup.number().min(0, "Enter valid Minutes").required('Min break minutes is requierd'),
    minShiftLength: yup
        .number()
        .min(0, "Enter valid hours")
        .max(23, "Enter valid hours")
        .required("Min Shift Hours is required field"),
    maxShiftLength: yup
        .number()
        .when(['minShiftLength'], {
            is: (minShiftLength) => {
                return minShiftLength;
            },
            then: (schema) =>
                schema.test("maxShiftLength", "Max shift hours must be greater than min shift hours", function (maxShiftLength) {
                    if (!this.parent.minShiftLength) return true;
                    return maxShiftLength > this.parent.minShiftLength;
                }),
        }).max(24, "Enter valid hours")
});
