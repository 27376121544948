import React from 'react';
import { FormControl, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormRow from './FormRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AdditionalRequirementSelector, actions as AdditionalRequirementAction } from 'store/modules/additionalRequirements';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    },
    label: {
        color: '#212a34',
        display: 'flex',
        fontSize: '14px',
        paddingBottom: '3px',
        fontWeight: 400,
        alignItems: 'center',
        gap: 4
    },
    box: {
        display: 'flex',
        maxWidth: 'fit-content',
        border: "1px solid #d5dde5",
        borderRadius: 8,
        background: '#FFF',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
        alignItems: 'center',
        '& $label': {
            paddingBottom: 0,
        }
    }
}));

const DocumentExpiryStage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        isDocHaveExpiry
    } = useSelector((state) => AdditionalRequirementSelector.getFormData(state));

    const handleChange = (event) => {
        const { target: { name, checked } } = event;
        dispatch(AdditionalRequirementAction.setAdditionalRequirementForm(name, checked));
    };

    return (
        <>
            <FormRow style={{ marginTop: 4 }}>
                <FormControl fullWidth>
                    <div className={classes.label}>
                        Does the document have an expiry date?
                    </div>
                    <div className={classes.box}>
                        <div style={{ display: 'flex', alignItems: 'center' }} className={classes.label}>
                            <span style={{ fontWeight: !isDocHaveExpiry ? 'bold' : 'normal' }}>No </span>
                            <Switch
                                name={'isDocHaveExpiry'}
                                id={'isDocHaveExpiry'}
                                onChange={handleChange}
                                checked={isDocHaveExpiry}
                                inputProps={{ 'aria-label': 'Switch demo' }} />
                            <span style={{ fontWeight: isDocHaveExpiry ? 'bold' : 'normal' }}>Yes</span>
                        </div>
                    </div>
                </FormControl>
            </FormRow>
        </>
    )
};

export default DocumentExpiryStage;
