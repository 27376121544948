export default {
    SET_LOADING_STATE: "verifications/SET_LOADING_STATE",
    SET_DIALOG_STATE: "verifications/SET_DIALOG_STATE",
    SET_JOB_APPLICATIONS_DATA: "verifications/SET_JOB_APPLICATIONS_DATA",
    SET_SELECTED_JOB: "verifications/SET_SELECTED_JOB",
    SET_JOB_POSITIONS: "verifications/SET_JOB_POSITIONS",
    SET_SELECTED_WORKER: "verifications/SET_SELECTED_WORKER",
    SET_SELECTED_REQUIREMENTS: "verifications/SET_SELECTED_REQUIREMENTS",
    SET_SELECT_REQUIREMENTS_FOR_BULK_ACTION: "verifications/SET_SELECT_REQUIREMENTS_FOR_BULK_ACTION",
    SET_INITIAL_STATE: "verifications/SET_INITIAL_STATE",
}
