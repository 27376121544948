import { gql } from "@apollo/client";

// $id: ID!
export const getEmployerBillingRatesQuery = gql`
  query employerBillingRates($employerId: String!) {
    employerBillingRates(employerId: $employerId) {
      id
      createdAt
      #   updatedAt
      employerId
      name
      billingLabel
      rateType
      description
      order
      isActive
      baseRate
      baseBillingRate
      wageMultiplier
      billingRateMultiplier
    }
  }
`;

export const GetRequirementsByGroup = gql`
query getRequirements(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [RequirementFilterInput!],
    $order: [RequirementOrderInput!],
    ) {
  getRequirements(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    edges {
      node {
        id
        name
        type
        groups{
            id
            name
            slug
        }
      }
    }
  }
}
`;

export const GetAssignedEmployers = gql`
query getEmployerRequirements(
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $like: [AssignmentFilterInput!],
    $order: [AssignmentOrderInput!],
    ) {
  getEmployerRequirements(
    first: $first
    last: $last
    after: $after
    before: $before
    like: $like
    order: $order
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    edges {
      node {
        id
        validityType
        validityValue
        createdAt
        isMandatory
        workType{
            id
            label
        }
        employer {
            id
            companyName
        }
        requirement{
            id
            type
            name
            title
            description
            summary
            groups{
                id
                name
                slug
            }
        }
      }
    }
  }
}
`;

export default getEmployerBillingRatesQuery;
