/* eslint-disable no-console */
import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import withStyles from '@mui/styles/withStyles';
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import ApolloClient from "utils/apollo";
import App from "./App";
import Notifier from "./Notifier";
import { store, persistor } from "./store";
import "./assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import Passwordless from 'supertokens-web-js/recipe/passwordless';

// Redirect if connection is not secure
if (window?.location?.hostname !== "localhost" && window?.location?.protocol === "http:") window?.location?.replace(window?.location?.href?.replace("http:", "https:"));

SuperTokens.init({
    appInfo: {
        apiDomain: process.env.REACT_APP_API_BASE_ENDPOINT || "https://6f34-103-165-71-122.in.ngrok.io",
        apiBasePath: "/auth",
        appName: process.env.REACT_APP_NAME || "RandstadDirect",
    },
    recipeList: [
        Session.init({
            onHandleEvent(e) {
                //   console.log(e, this)
                return e;
            }
        }),
        Passwordless.init({
            override: {
                functions(originalImplementation) {
                    return {
                        ...originalImplementation,
                        createCode(input) {
                            if (input.phoneNumber) input.phoneNumber = "+1" + input.phoneNumber;
                            return originalImplementation.createCode(input);
                        }
                    }
                }
            }
        }),
    ],
});

// const { store, persistor } = createStore();
const getFontSetting = (sizePx, fontWeight) => {
    return {
        fontWeight,
        color: "#212a34",
        fontSize: `${(sizePx / 14).toFixed(2)}rem`,
    };
};
const customPalette = {
    primary: {
        900: "#024a46",
        800: "#035759",
        700: "#066f6f",
        600: "#0b938b",
        500: "#11b2a8",
        400: "#10d5ca",
        300: "#65f6ed",
        200: "#d2fefb",
        100: "#f0ffff",
    },
    grey: {
        900: "#212a34",
        800: "#404b5a",
        700: "#6e7a8a",
        600: "#929fb0",
        500: "#aebecd",
        400: "#ccd4db",
        300: "#d5dde5",
        200: "#e1e8ec",
        100: "#f8f9fa",
    },
    red: {
        900: "#4d0100",
        800: "#870e0d",
        700: "#af2928",
        600: "#df2c2a",
        500: "#ef504e",
        400: "#f56361",
        300: "#f98786",
        200: "#fee1e1",
        100: "#fff0f0",
    },
    yellow: {
        900: "#664000",
        800: "#8d5901",
        700: "#c27b05",
        600: "#e48f01",
        500: "#f5a623",
        400: "#f9ba53",
        300: "#fcce82",
        200: "#fee0b3",
        100: "#fff5e6",
    },
    green: {
        900: "#155239",
        800: "#1e7142",
        700: "#268c52",
        600: "#2ea862",
        500: "#37c172",
        400: "#69dd9b",
        300: "#92edb9",
        200: "#b6f7cc",
        100: "#e3fdec",
    },
    blue: {
        900: "#000d33",
        800: "#011241",
        700: "#041649",
        600: "#071a50",
        500: "#0a1e55",
        400: "#0f3cb8",
        300: "#6a8ff6",
        200: "#c9d7fd",
        100: "#f5f8ff",
    },
};
const theme = createTheme({
    palette: {
        primary: {
            light: "#6e7a8a",
            main: "#404b5a",
            dark: "#212a34",
            contrastText: "#fff",
        },
        secondary: {
            light: "#11b2a8",
            main: "#0b938b",
            dark: "#024a46",
            contrastText: "#fff",
        },
        error: {
            light: "#F98786",
            main: "#df2c2a",
            dark: "#af2928",
            contrastText: "#fff",
        },
        grey: {
            50: "#fafafa",
            100: "#F8F9FA",
            200: "#E1E8EC",
            300: "#D5DDE5",
            400: "#CCD4DB",
            500: "#AEBECD",
            600: "#929FB0",
            700: "#6E7A8A",
            800: "#404B5A",
            900: "#212A34",
            A100: "#C6D1DC",
            A200: "#BDC8D1",
            A400: "#353E4B",
            A700: "#646E7D",
        },
        background: {
            default: "#f0ffff",
        },
        text: {
            primary: "#212a34",
            secondary: "#6e7a8a",
            disabled: "#8e8e93",
            hint: "#8e8e93",
        },
        action: {
            active: "#6e7a8a",
            disabled: "#aebecd",
            disabledBackground: "#e1e8ec",
            hover: "#f8f9fa",
            selected: "#e1e8ec",
        },
    },
    components: {
        MuiSwitch: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    // color: "#FFF",
                    // backgroundColor: "#212a34",
                },
                textColorPrimary: "#FFF",
                textColorSecondary: "#FFF",
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    "& legend": {
                        width: 0
                    }
                }
            }
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
                disableAnimation: true,
            },
            styleOverrides: {
                root: {
                    marginTop: "16 !important",
                    fontSize: "14px",
                    color: "#212a34",
                    "&$focused": {
                        fontSize: "14px",
                        color: "#212a34",
                    },
                    "& ~ div": {
                        "& input": {
                            marginTop: "0 !important"
                        }
                    }
                },
                shrink: {
                    transform: "translate(0, -22px)",
                },

            }
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
                size: "small"
            }
        },
        MuiFormControl: {
            styleOverrides: {
                marginNormal: {
                    marginTop: 30
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: "0 !important"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // border: "1px solid #aebecd",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    "&$focused": {
                        border: "1px solid #035759",
                    },
                    "&.MuiInputBase-adornedStart input": {
                        padding: "0",
                    },
                    "&.Mui-error": {
                        borderColor: customPalette.red[700],
                    },
                },
                input: {
                    display: "flex",
                    padding: "8px 15px 8px 8px !important",
                    height: "auto",
                },
                inputMultiline: {
                    padding: "0 0 !important",
                },
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    border: "1px solid #aebecd",
                    // backgroundColor: "transparent",
                    minHeight: "26px",
                    "&:active": {
                        boxShadow: "none",
                    },
                    "& span svg": {
                        marginTop: "6px",
                    },
                },
                extended: {
                    height: "36px",
                    textTransform: "none",
                    fontWeight: 500,
                    padding: "0 8px",
                    "& span svg": {
                        fontSize: "18px",
                    },
                },
                sizeSmall: {
                    width: "30px",
                    height: "30px",
                    "& span svg": {
                        marginTop: "0px !important",
                    },
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: "1px",
                    // paddingRight: "30px !important"
                    "& .MuiSelect-select": {
                        paddingRight: "30px !important",
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: "12px",
                    paddingBottom: "12px",
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {},
            }
        }
    },
    typography: {
        useNextVariants: true,
        h1: getFontSetting(72, 700),
        h2: getFontSetting(60, 700),
        h3: getFontSetting(48, 700),
        h4: getFontSetting(36, 700),
        h5: getFontSetting(24, 700),
        h6: {
            ...getFontSetting(20, 500),
            textTransform: "none",
        },
        subtitle1: {
            ...getFontSetting(16, 700),
            textTransform: "none",
        },
        subtitle2: {
            ...getFontSetting(16, 400),
            textTransform: "none",
        },
        body1Next: getFontSetting(14, 400),
        body2Next: getFontSetting(14, 700),
        buttonNext: getFontSetting(14, 500),
        captionNext: {
            ...getFontSetting(12, 400),
            lineHeight: 1,
        },
        caption: {
            ...getFontSetting(12, 400),
            lineHeight: 1,
        },
        overline: getFontSetting(10, 700),
    },
    customPalette,
});
//console.log(theme);
const ProvidedApp = withStyles(({ palette, customPalette, spacing }) => ({
    success: {
        backgroundColor: customPalette.green[100],
        border: `1px solid ${customPalette.green[400]}`,
        color: palette.text.primary,
    },
    error: {
        backgroundColor: customPalette.red[100],
        border: `1px solid ${customPalette.red[400]}`,
        color: palette.text.primary,
    },
    warning: {
        backgroundColor: customPalette.yellow[100],
        border: `1px solid ${customPalette.yellow[400]}`,
        color: palette.text.primary,
    },
    info: {
        backgroundColor: customPalette.blue[100],
        border: `1px solid ${customPalette.blue[400]}`,
        color: palette.text.primary,
    },
    successIcon: {
        color: "white",
        marginRight: spacing(1),
    },
    errorIcon: {
        color: "white",
        marginRight: spacing(1),
    },
    warningIcon: {
        color: "white",
        marginRight: spacing(1),
    },
    infoIcon: {
        color: "white",
        marginRight: spacing(1),
    },
}))(({ classes }) => {

    useEffect(() => {
        document.addEventListener('copy', (event) => {
            if (event && event.clipboardData && event.clipboardData.setData) {
                if (event.target?.nodeName?.toLowerCase() === "input" || event.target?.nodeName?.toLowerCase() === 'textarea') {
                    event.clipboardData.setData('text/plain', event.target.value.trim());
                } else {
                    const helper = document.createElement("div");
                    helper.appendChild(document.getSelection().getRangeAt(0).cloneContents());
                    event.clipboardData.setData('text/plain', helper.innerText.trim());
                }
                event.preventDefault();
            }
        });
        return () => document.removeEventListener("copy", () => 0);
    }, []);

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
            iconVariant={{
                success: <Icon className={classes.successIcon}>check_circle_outline</Icon>,
                error: <Icon className={classes.errorIcon}>error_outline</Icon>,
                warning: <Icon className={classes.warningIcon}>warning_outline</Icon>,
                info: <Icon className={classes.infoIcon}>info_outline</Icon>,
            }}>
            <CssBaseline />
            <Notifier />
            <App />
        </SnackbarProvider>
    )
});
ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ApolloProvider client={ApolloClient}>
                        <ProvidedApp />
                    </ApolloProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);
